import { Component, HostListener, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Resources } from 'src/core/constants/Resources';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Subscription, tap } from 'rxjs';
import { PaginationService } from 'src/app/services/pagination.service';
import { Constants, Numbers } from 'src/core/constants/Constants';
import { AccountsStatusService } from 'src/app/services/accounts-status.service';
import { CreditCardsStatusService } from 'src/app/services/credit-cards-status.service';
import { ModalService } from 'src/app/shared/modal.service';
import { LoaderService } from 'src/app/services/loader.service';
import { TabsService } from 'src/app/services/tabs.service';
import { Strings } from 'src/core/constants/Strings';

@Component({
  selector: 'app-modal-transactions',
  templateUrl: './modal-transactions.component.html',
  styleUrls: ['./modal-transactions.component.css']
})
export class ModalTransactionsComponent implements OnInit {
  modalInstance: NgbModalRef;
  frameClose: string = Resources.CLOSE_CIRCLE_BLUE;
  hideModalMovements: boolean = true;
  private readonly modalVisibleSubscription: Subscription;

  vm$ = this.responsiveService.observe.pipe(
    tap((breakpoints) => {
      if (!breakpoints.mobile) {
        this.close();
      }
    })
    );
    isLoading: boolean = true;

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly paginationService: PaginationService,
    private readonly accountStatusService: AccountsStatusService,
    private readonly creditCardStatus: CreditCardsStatusService,
    private readonly accountStatusServices: AccountsStatusService,
    private readonly creditCardsService: CreditCardsStatusService,
    private readonly modalService: ModalService,
    private readonly loaderService: LoaderService,
    private readonly tabsService: TabsService
  ) {
    this.modalVisibleSubscription = this.modalService.modalTransactionsVisible$.subscribe((isVisible) => {
      this.hideModalMovements = !isVisible;
    });
  }

  ngOnInit(): void {
    this.accountStatusServices.accountNumberCompare = this.accountStatusServices.status.value ? Numbers.Zero : this.accountStatusServices.accountNumberCompare;
    this.creditCardsService.cardNumberCompare = this.creditCardsService.status.value ? Numbers.Zero : this.creditCardsService.cardNumberCompare;
    this.loaderService.isLoading.subscribe(data => { this.isLoading = data; });
  }

  close(){
    this.modalInstance?.close();
    this.tabsService.tabChanged = false;
    this.tabsService.tabCustom = Strings.EMPTY;
  }

  ngOnDestroy() {
    this.paginationService.setInitialData([], Constants.PAGE_DEFAULT);
    this.accountStatusService.accountNumberCompare = Numbers.Zero;
    this.creditCardStatus.cardNumberCompare = Numbers.Zero;
  }

  @HostListener('window:popstate')
  goBack() {
    this.modalInstance?.close();
  }

}
