import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { Numbers, Position } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { AccountModel } from 'src/app/interface/AccountModel';

@Injectable({
  providedIn: 'root'
})
export class AccountCardService {
  /**
   * @deprecated The variable should not be used, use instead accountCard
   */
  selectedAccountCard: ICardAccount = {
    image: Strings.EMPTY,
    cardNumber: Strings.EMPTY,
    clabe: Strings.EMPTY,
    balance: Strings.EMPTY,
    selected: false,
    productId: Numbers.Zero,
    originAccount: Strings.EMPTY,
    accountType: Strings.EMPTY,
    source: Strings.EMPTY,
    expirationDate: Strings.EMPTY,
    account: Strings.EMPTY,
    category: Strings.EMPTY
  };
  accountCardList: ICardAccount[] = [];
  selected = false;

  private readonly _accountCard: BehaviorSubject<ICardAccount> =
    new BehaviorSubject<ICardAccount>({
      image: Strings.EMPTY,
      cardNumber: Strings.EMPTY,
      clabe: Strings.EMPTY,
      balance: Strings.EMPTY,
      selected: false,
      productId: Numbers.Zero,
      originAccount: Strings.EMPTY,
      accountType: Strings.EMPTY,
      source: Strings.EMPTY,
      expirationDate: Strings.EMPTY,
      account: Strings.EMPTY,
      category: Strings.EMPTY
    });

  private readonly _arrayAccountCard: BehaviorSubject<AccountModel[]> = new BehaviorSubject<AccountModel[]>([
  ]);

  private readonly onDestroySubject = new Subject<void>();

  private readonly _modalAccountOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  get accountCard$() {
    return this._accountCard.asObservable();
  }

  get accountCard() {
    return this._accountCard.getValue();
  }

  set accountCard(data: ICardAccount) {
    this._accountCard.next(data);
  }

  get arrayAccountCard$(){
    return this._arrayAccountCard.asObservable();
  }

  get arrayAccountCard(){
    return this._arrayAccountCard.getValue();
  }

  get onDestroy$(){
    return this.onDestroySubject.asObservable();
  }

  set arrayAccountCard(data: AccountModel[]){
    this._arrayAccountCard.next(data);
  }

  clearCardAccount() {
    this.selectedAccountCard = {
      image: Strings.EMPTY,
      cardNumber: Strings.EMPTY,
      clabe: Strings.EMPTY,
      balance: Strings.EMPTY,
      selected: false,
      productId: Numbers.Zero,
      originAccount:Strings.EMPTY,
      currency: Strings.EMPTY,
      source: Strings.EMPTY,
      expirationDate: Strings.EMPTY,
      account: Strings.EMPTY,
      category: Strings.EMPTY
    };
    this._accountCard.next(this.selectedAccountCard);
    this.selected = false;
  }

  clearArrayAccountCardAccount() {
    this.arrayAccountCard.splice(Position.Zero, this.arrayAccountCard.length);
  }

  emitDestroyEvent(){
    this.onDestroySubject.next();
  }

  get getModalAccountOpen() {
    return this._modalAccountOpen.getValue();
  }

  set setModalAccountOpen(value: boolean) {
    this._modalAccountOpen.next(value);
  }
}
