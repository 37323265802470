<ng-container *ngIf="vm$ | async as vm">
    <content-wrapper [useFlex]="!vm.breakpoints.mobile">
        <div content class="margin-transfer">
            <div>
                <app-header-transfer-card class="header-transfer" [title]="title" [subtitle]="subtitle"
                    [urlLocation]="preview" [returnFn]="modify">
                </app-header-transfer-card>
                <div *ngIf="vm.breakpoints.mobile">
                    <label for="" class="subtitle-transfer-card body-s">{{subtitle}}</label>
                </div>
            </div>
            <div id="form-container">
                <div id="account" class="w-row">
                    <div class="p-account">
                        <show-amount [useBreak]="true" label="Cantidad" color="var(--primary-1000)"></show-amount>
                    </div>
                    <div>
                        <form>
                            <label class="header-xs p-label-account">Desde tu cuenta</label>
                            <div [ngClass]="vm.breakpoints.mobile ? 'mt-2' : ''">
                                <div class="element-card flex-card-row"
                                    [ngClass]="vm.breakpoints.mobile ? 'p-card' : ''">
                                    <div class="icon-card-sh account-center">
                                        <img class="img-confirm"
                                            [src]="selectedCard?.image" alt="image" width="auto"
                                            height="auto" />
                                    </div>
                                    <div class="account-center flex-card-row" [class.p-2]="vm.breakpoints.mobile">
                                        <div class="flex-card-column">
                                            <span class="body-s">{{cardNumber}}</span>
                                            <span class="detail-s">{{selectedCard?.cardType}}</span>
                                        </div>
                                    </div>
                                </div>
                                <hr class="margin-hr">
                            </div>
                        </form>
                    </div>
                </div>
                <div id="info-operation" class="w-row">
                    <label class="header-xs p-label-account" [class.p-left]="vm.breakpoints.mobile">Información de la
                        operación</label>
                    <div class="p-label-account flex-card-column mt-info" [class.p-left]="vm.breakpoints.mobile">
                        <label class="body-s default-color">{{ addContributionsData?.typeContribution }}</label>
                        <label class="detail-s pt-1">Tipo de Aportación</label>
                    </div>
                    <hr class="margin-hr black-s">
                    <div class="p-label-account flex-card-column"
                        [ngClass]="vm.breakpoints.mobile ? 'mt-info p-left' : ''">
                        <label class="body-s pt-1 default-color">{{ addContributionsData?.policyNumber }}</label>
                        <label class="detail-s pt-1">No. de Póliza</label>
                    </div>
                    <hr class="margin-hr black-s">
                </div>
            </div>
            <div class="btn-container d-flex justify-content-center" [class.pt-2]="vm.breakpoints.mobile">
                <button class="button-m colored-btn btn-generic" (click)="continue()">
                    Confirmar y continuar
                    <img [src]="chevronRight | cdnImage" alt=">" width="auto" height="auto"
                        [ngClass]="vm.breakpoints.mobile ? 'img-next' : ''" />
                </button>
            </div>
            <div class="btn-container pt-3 d-flex justify-content-center">
                <button class="outline-btn btn-generic" [ngClass]="vm.breakpoints.mobile ? 'button-s' : 'button-m'"
                    (click)="modify()">
                    Modificar
                </button>
            </div>
        </div>
    </content-wrapper>
</ng-container>
