import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Resources } from 'src/core/constants/Resources';
import { DataCheckbookService } from 'src/app/services/data-checkbook.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ClassHTML, Constants} from 'src/core/constants/Constants';
import { OptionsModalToken, ModalOptions } from 'src/app/interface/modal-options';
import { AforeDataService } from 'src/app/services/afore-data.service';
import { Strings } from 'src/core/constants/Strings';
import { ModalBureauAlertComponent } from 'src/app/component/modal-bureau-alert/modal-bureau-alert.component';
import { ModalSuccessfulRequestComponent } from 'src/app/component/modal-successful-request/modal-successful-request.component';
import { merge } from 'rxjs';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CheckBookActivationService } from 'src/app/services/check-book-activation.service';
import { ModalConfirmCheckbookDetailsComponent } from 'src/app/home/components/modal-confirm-checkbook-details/modal-confirm-checkbook-details.component';
import { ModalChecksConfirmationComponent } from 'src/app/component/modal-checks-confirmation/modal-checks-confirmation.component';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { NotifyRetirementTypeService } from 'src/app/services/notify-retirement-type.service';
import { ModalVoluntaryWithdrawalComponent } from 'src/app/component/modal-voluntary-withdrawal/modal-voluntary-withdrawal.component';
import { StateVoluntaryWithdrawalService } from 'src/app/services/state-voluntary-withdrawal.service';
import { AforeService } from 'src/app/services/afore.service';
import { AccountCardService } from 'src/app/services/account-card.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';

@Component({
  selector: 'app-otp-card-replacement',
  templateUrl: './otp-card-replacement.component.html',
  styleUrls: ['./otp-card-replacement.component.css']
})
export class OtpCardReplacementComponent {
  removeOperation: string = Resources.CLOSE_CIRCLE_BLUE;
  @Output() confirmData?= new EventEmitter();
  @Output() closeModalOtp: EventEmitter<boolean> = new EventEmitter();
  @Input() nextView?: string;
  classToken: string = ClassHTML.OtpModal;
  @Input() showOtp: boolean = false;
  isAforeProcess: boolean = false;
  isAforeProcessT: boolean = false;
  isAforeProcessR: boolean = false;
  returnConfirmCheckBook: boolean = false;
  returnConfirmCheckActivation: boolean = false;
  @Input() optionsToken: OptionsModalToken;
  @Input() token :NgbModalRef;
  buttonClicked: boolean = false;
  modalTokenBackClass: ModalOptions;
  modalTokenNextClass: ModalOptions;
  optionsModalToken:OptionsModalToken;
  labelToken: string = Strings.PAGE_HEADER.Subtitle;
  tokenCodeLabel: string = Strings.SECURITY_CODE.TitleToken;
  modalInstance: NgbModalRef;
  openModal: boolean = true;
  @Input() autofocus: boolean = true;
  @Input() return: string = Strings.EMPTY;
  modalData: {
    showOtp: boolean;
    nextComponent: Function;
    nextModalOptions: {
      centered: boolean;
      modalDialogClass: string;
    }
  };
  components = [
    ModalBureauAlertComponent,
    ModalSuccessfulRequestComponent
  ];

  constructor(private readonly modalService: ModalService,
    private readonly aforeDataService: AforeDataService,
    private readonly isWithdrawals: AforeDataService,
    private readonly stateVoluntary: StateVoluntaryWithdrawalService,
    private readonly dataCheckBookService: DataCheckbookService,
    private readonly notifyretirementType: NotifyRetirementTypeService,
    private readonly activationCheckBook: CheckBookActivationService,
    private readonly aforeService: AforeService,
    private readonly accountCardService: AccountCardService,
    private readonly notifyAmountService: NotifyAmountService
  ) {
    this.aforeDataService.detailWPartialObservable.subscribe(
      (data) => {
        this.isAforeProcess = data.value;
        if (this.isAforeProcess) { this.showOtp = true; }
      }
    );
    this.isWithdrawals.detailWTotalObservable.subscribe(
      (data) => {
        this.isAforeProcessT = data.value;
        if (this.isAforeProcessT) {
          this.showOtp = true;
        }
      }
    );
    this.isWithdrawals.detailRightOrAdvancedAgeObservable.subscribe(
      (dataR) => {
        this.isAforeProcessR = dataR.value;
        if (this.isAforeProcessR) {
          this.showOtp = true;
        }
      }
    );
    this.notifyretirementType.retirementTypeObservable.subscribe(
      () => { this.showOtp = this.isAforeProcess = true; }
    );
  }

  getOtp($event: boolean) {
    if ($event === true) {
      this.modalComponents(this.optionsToken?.nextModal, this.optionsToken?.nextClass);
    } else { this.confirmData.emit($event); }
  }

  close() {
    this.buttonClicked = true;
    this.closeModalOtp.emit(true);
    if(this.token=== undefined){
      this.modalService.close();
    }else{
      this.token.close();
    }

    if (this.stateVoluntary.isVoluntaryProcess) {
      this.stateVoluntary.confirmVoluntary = true;
      this.stateVoluntary.successVoluntary = false;
      this.modalService.open(ModalVoluntaryWithdrawalComponent, {
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalVoluntaryWithdrawal, centered: true
      });
    }

    if (this.returnConfirmCheckBook) {
      this.dataCheckBookService.checkbookRequestProcess$.next(false);
      const modalOptions: ModalOptions = {
        size: Constants.MODAL_OPTIONS.SizeMd,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalConfirmCheckbookDetails,
        centered: true
      };
      this.modalService.open( ModalConfirmCheckbookDetailsComponent  , modalOptions);
    }

    if(this.returnConfirmCheckActivation) {
      this.activationCheckBook.statusActiveData = false;
      const modalOptions: ModalOptions = {
        size: Constants.MODAL_OPTIONS.SizeMd,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ChecksActivation,
        centered: true
      };
      this.modalService.open( ModalChecksConfirmationComponent  , modalOptions);
    }

    if ((this.aforeDataService) && this.optionsToken.backModal && this.openModal) {
      this.modalComponents(this.optionsToken.backModal, this.optionsToken.backClass);
    }
  }

  ngOnInit() {
    this.showOtp = this.modalData ? this.modalData.showOtp: this.showOtp;
    const statusConfirmObservable = merge(
      this.dataCheckBookService.statusConfirmOTP$
    );

    statusConfirmObservable.subscribe((value) => {
      if (value) {
        this.showOtp = true;
      }
    });

    this.dataCheckBookService.checkbookRequestProcess$.subscribe(value => {
      this.returnConfirmCheckBook = value;
    });

    this.activationCheckBook.statusActive$.subscribe(value => {
      this.returnConfirmCheckActivation = value;
    });
    this.dataCheckBookService.statusConfirmOTP$.subscribe((value) => {
      if (value) { this.showOtp = true; }
    });
  }

  modalComponents(modal, view: ModalOptions) {
    this.buttonClicked = true;
    this.modalService.close();
    this.modalService.open(modal, view);
  }

  ngOnDestroy() {
    if (this.stateVoluntary.isVoluntaryProcess || !this.buttonClicked) {
      this.aforeDataService.detailWPartialData = { value: false };
      this.notifyretirementType.retirementTypeData = null;
    }
  }

  @HostListener('window:popstate')
  onBack() {
    this.returnConfirmCheckActivation = false;
    this.dataCheckBookService.checkbookRequestProcess$.next(false);
    this.modalInstance?.close();
    this.stateVoluntary.isVoluntaryProcess = false;
    this.openModal = false;
    if(this.aforeService.aforeInformation){
      this.accountCardService.clearCardAccount();
      this.notifyAmountService.amount = { amount: Strings.EMPTY };
      this.aforeService.aforeInformation = null;
    }
    this.close();
    if(this.stateVoluntary.confirmVoluntary) {
      this.stateVoluntary.getVoluntaryWithdrawalReverse();
      this.stateVoluntary.resetVoluntaryWithdrawalProcessValues();
    }
  }
}
