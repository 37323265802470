import { Char, FormatDate, Months, Position, Regex } from 'src/core/constants/Constants';
import { CodeflexNumbers } from 'src/core/constants/CodeflexConstants';
import { FormGroup } from '@angular/forms';
import { Strings } from 'src/core/constants/Strings';
import { DatePipes } from 'src/app/pipes/date.pipe';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AforeNumbers } from 'src/core/constants/AforeStrings';
import { Numbers } from 'src/core/constants/Numbers';
import { MonthNumber } from 'src/core/constants/FormatDate';
import { ElementRef } from '@angular/core';

export class DateUtils {
  static differenceBetweenDates(firstDate: Date, secondDate: Date) {
    secondDate.setHours(Numbers.Zero, Numbers.Zero, Numbers.Zero, Numbers.Zero);
    return firstDate.getTime() - secondDate.getTime();
  }

  static millisecondsToDays(milliseconds: number) {
    const days = milliseconds / (CodeflexNumbers.Thousand * CodeflexNumbers.Sixty *
      CodeflexNumbers.Sixty * CodeflexNumbers.TwentyFour);
    return Math.ceil(days);
  }

  static formatDateInput(event: Event, formGroup: FormGroup, formControlName: string, calendarImage?: ElementRef, position?: number) {
    let cursorPosition = position;
    const valueInput = (event.target as HTMLInputElement).value.split(Char.Slash);
    if (valueInput.length > Numbers.One && !this.isNumber(valueInput[Numbers.One])) {
      cursorPosition = Numbers.Three;
    }
    event = event as KeyboardEvent;
    const inputName = event.target as HTMLInputElement;
    let formattedDate: string;
    const charactersPermitted = Regex.OnlyNumbersSlashDate;
    const input = (event.target as HTMLInputElement).value.replace(Regex.nonDigitsRegex, Strings.EMPTY);
    if (input.length <= Numbers.Two) {
      formattedDate = input;
      inputName.value = formattedDate;
    } else if (input.length <= Numbers.Four) {
      formattedDate = `${input.slice(Numbers.Zero, Numbers.Two)}${Char.Slash}${input.slice(Numbers.Two)}`;
      inputName.value = formattedDate;
    } else if (input.length <= Numbers.Eight) {
      formattedDate =
        `${input.slice(Numbers.Zero, Numbers.Two)}${Char.Slash}${input.slice(Numbers.Two, Numbers.Four)}${Char.Slash}${input.slice(Numbers.Four)}`;
      inputName.value = formattedDate;
    } else {
      formattedDate =
        `${input.slice(Numbers.Zero, Numbers.Two)}${Char.Slash}${input.slice(Numbers.Two, Numbers.Four)}
        ${Char.Slash}${input.slice(Numbers.Four, Numbers.Eight)}`;
      inputName.value = formattedDate;
    }
    calendarImage.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
    calendarImage.nativeElement.focus();
    if (!charactersPermitted.test(inputName.value)) {
      formGroup.patchValue(
        {
          [formControlName]: Strings.EMPTY
        }
      );
    }
  }

  static datePipe(value: string): string {
    const datePipe = new DatePipes();
    return datePipe.transform(value, FormatDate.BasicFormat);
  }

  static getTimeResponse(value: string) {
    const split = value.split(Char.WhiteSpace);
    const completeHour = split[Numbers.One];
    return {hour: completeHour.slice(Numbers.Zero, Numbers.Two), minutes: completeHour.slice(Numbers.Three, Numbers.Five) };
  }

  static resetCompleteDate(date: string): string {
    const months = Object.values(Months).map(month => month.toLowerCase());
    const [day, monthName, year] = date.split(Char.Slash);
    const monthNumber = months.indexOf(monthName) + Numbers.One;
    if (monthNumber === Numbers.Zero) {
      return date;
    }
    const monthNumberString = monthNumber < Numbers.Ten ? `${Numbers.Zero}${monthNumber}` : monthNumber;
    return `${day}${Char.Slash}${monthNumberString}${Char.Slash}${year}`;
  }

  static isNumber(value: string): boolean {
    return !isNaN(Number(value));
  }

  static isDate(value: string): boolean {
    if (value.includes(Char.Slash)) {
      const [d, m, y] = value.split(Char.Slash);
      const maxDay: number = new Date(Number(y), Number(m) - Numbers.One, Number(d)).getDate();
      if (!this.isNumber(d) || !this.isNumber(m) || !this.isNumber(y)) return false;
      return ((Number(d) <= maxDay && Number(d) > Numbers.Zero) && (Number(m) <= Numbers.Twelve && Number(m) > Numbers.Zero));
    }
    return false;
  }

  static calcDateEnd(startDate: string, days: number): string {
    const [day, month, year] = startDate.split(Char.Slash).map(Number);
    const date = new Date(year, month - Numbers.One, day);
    date.setDate(date.getDate() + days);
    const [endDay, endMonth, endYear] = [
      date.getDate().toString().padStart(Numbers.Two, Numbers.Zero.toString()),
      (date.getMonth() + 1).toString().padStart(Numbers.Two, Numbers.Zero.toString()),
      date.getFullYear().toString()];
    return `${endDay}/${endMonth}/${endYear}`;
  }

  static getNgbDateStructFromString(date: string): NgbDateStruct {
    const [eDay, eMonth, eYear] = date.split(Char.Slash);
    return {
      year: parseInt(eYear),
      month: parseInt(eMonth),
      day: parseInt(eDay)
    };
  }

  static getDateStringFromNgbDateStruct(date: NgbDateStruct): string {
    const day = date.day < Numbers.Ten ? Numbers.Zero.toString() + date.day.toString() : date.day.toString();
    const month = date.month < Numbers.Ten ? Numbers.Zero.toString() + date.month.toString() : date.month.toString();
    const year = date.year.toString();
    return `${day}/${month}/${year}`;
  }

  static calcDaysDifference(startDate: string, endDate: string): number {
    const [startDay, startMonth, startYear] = startDate.split(Char.Slash).map(Number);
    const [endDay, endMonth, endYear] = endDate.split(Char.Slash).map(Number);
    const initialDay = new Date(startYear, startMonth - 1, startDay);
    const finalDay = new Date(endYear, endMonth - 1, endDay);
    const difference = finalDay.getTime() - initialDay.getTime();
    const daysDifference = difference / (Numbers.OneThousand * Numbers.Sixty * Numbers.Sixty * Numbers.TwentyFour);
    return Math.round(daysDifference);
  }

  static getCurrentDay(): string {
    const date = new Date();
    const day = date.getDate().toString().padStart(Numbers.Two, Numbers.Zero.toString());
    const month = (date.getMonth() + Numbers.One).toString().padStart(Numbers.Two, Numbers.Zero.toString());
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  static formattedDateWithMonthName(date: string, separator = Char.MiddleDash, separatorReturn = Char.Slash): string {
    const [day, monthName, year] = date.split(separator);
    const month = MonthNumber[monthName.toLowerCase()].toString().padStart(Numbers.Two, Numbers.Zero.toString());
    const dayAux = day.padStart(Numbers.Two, Numbers.Zero.toString());
    return `${dayAux}${separatorReturn}${month}${separatorReturn}${year}`;
  }

  static isLeapYear(year: number): boolean{
    return (year % Numbers.Four === Numbers.Zero && year % Numbers.OneHundred !== Numbers.Zero) || year % AforeNumbers.FourHundred === Numbers.Zero;
  }

  static formattedDate(date: string) {
    const [year, month, day] = date.split(Char.Slash);
    return `${day}${Char.MiddleDash}${month}${Char.MiddleDash}${year}`;
  }

  static parseSimpleFormatDate(dateString: string) {
    const day = Number(dateString.slice(Position.Zero, Position.Two));
    const month = Number(dateString.slice(Position.Three, Position.Five)) - Numbers.One;
    const year = Number(dateString.slice(Numbers.Six, Numbers.Ten));
    return new Date(year, month, day);
  }

  static isDateLater(date1: NgbDateStruct, date2: NgbDateStruct): boolean {
    if (date1.year > date2.year) {
      return true;
    } else if (date1.year === date2.year) {
      if (date1.month > date2.month) {
        return true;
      } else if (date1.month === date2.month) {
        return date1.day >= date2.day;
      }
    }
    return false;
  }
  static getFullDateWithMonthName(date: string):string {
    if (date) {
      const months: string[] = Object.values(Months);
      return `${date.substring(Position.Eight, Position.Ten)}
         ${months[parseInt(date.substring(Position.Five, Position.Seven)) - Numbers.One]}
          ${date.substring(Position.Zero, Position.Four)}`;
    }
    return Strings.EMPTY;
  }

  static compareDates(maxDate: NgbDateStruct, date: NgbDateStruct, validation: number): boolean {
    const dateOne: Date = new Date(maxDate.year, maxDate.month - Numbers.One, maxDate.day);
    const dateTwo: Date = new Date(date.year, date.month - Numbers.One, date.day);
    switch (validation) {
      case Numbers.One:
        return (dateTwo.getTime() < dateOne.getTime());
      case Numbers.Two:
        return (dateTwo.getTime() === dateOne.getTime());
      case Numbers.Three:
        return (dateTwo.getTime() > dateOne.getTime());
      case Numbers.Four:
        return (dateTwo.getTime() <= dateOne.getTime());
      case Numbers.Five:
        return (dateTwo.getTime() >= dateOne.getTime());
      default:
        return false;
    }
  }
}
