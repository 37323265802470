import { Injectable } from '@angular/core';
import { InputConfig } from 'src/app/interface/IInputConfig';
import { InputTypes } from 'src/core/constants/Constants';
import { ControlCardStrings } from 'src/core/constants/ControlCardStrings';
import { ControlCardModel } from 'src/app/interface/ControlCardModel';
import { Numbers } from 'src/core/constants/Numbers';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ControlCardService {
  private _isRequestModal: boolean = false;
  private _isResponseModal: boolean = false;
  private _isRenewModal: boolean = false;
  private _isLowerCase: boolean = false;
  private _cancelOption: string;
  private _controlMap: boolean = false;
  private readonly _inputConfig: InputConfig[] = [
    {
      type: InputTypes.Select,
      label: ControlCardStrings.OPERATION_TYPE,
      name: ControlCardStrings.REASON
    }
  ];
  private readonly _cancelConfigOption: InputConfig[] = [
    {
      type: InputTypes.Select,
      label: ControlCardStrings.CANCEL_REASON_TITLE,
      name: ControlCardStrings.REASON,
      options: ControlCardStrings.CANCEL_OPTIONS
    }
  ];

  private readonly _additionalConfigOption: InputConfig[] = [
    {
      type: InputTypes.Select,
      label: ControlCardStrings.ADDITIONAL_GENDER_TITLE,
      name: ControlCardStrings.GENDER,
      options: ControlCardStrings.ADDITIONAL_GENDER_OPTIONS
    },
    {
      type: InputTypes.Select,
      label: ControlCardStrings.ADDITIONAL_CIVIL_STATUS,
      name: ControlCardStrings.CIVIL_STATUS,
      options: ControlCardStrings.ADDITIONAL_CIVIL_OPTIONS
    }
  ];

  private readonly _branchConfigOption: InputConfig[] = [
    {
      type: InputTypes.Select,
      label: ControlCardStrings.BRANCH_TITLE,
      name: ControlCardStrings.BRANCH,
      options: ControlCardStrings.BRANCH_OPTIONS
    }
  ];

  private readonly _dataControls: BehaviorSubject<ControlCardModel> = new BehaviorSubject<ControlCardModel>(
    {Monthly:{OriginAmount: Numbers.Zero, Amount: Numbers.Zero, Lock: false, MaximumLimit: Numbers.Zero, MinimumLimit: Numbers.Zero}});

  constructor() {}

  get isRequesModal() {
    return this._isRequestModal;
  }
  set isRequesModal(data: boolean) {
    this._isRequestModal = data;
  }

  get isResponseModal() {
    return this._isResponseModal;
  }

  set isResponseModal(data: boolean) {
    this._isResponseModal = data;
  }

  get isRenewModal() {
    return this._isRenewModal;
  }

  set isRenewModal(data: boolean) {
    this._isRenewModal = data;
  }

  get isLowerCase() {
    return this._isLowerCase;
  }

  set isLowerCase(data: boolean) {
    this._isLowerCase = data;
  }

  get cancelOption() {
    return this._cancelOption;
  }

  set cancelOption(data: string) {
    this._cancelOption = data;
  }

  get optionConfig() {
    return this._inputConfig;
  }

  get cancelOptionConfig() {
    return this._cancelConfigOption;
  }

  get additionalOtionConfig() {
    return this._additionalConfigOption;
  }

  get branchConfig() {
    return this._branchConfigOption;
  }

  get controlMapClose() {
    return this._controlMap;
  }

  set controlMapClose(data: boolean) {
    this._controlMap = data;
  }

  get dataControls$() {
    return this._dataControls.asObservable();
  }

  get dataControls(){
    return this._dataControls.getValue();
  }

  set dataControls(data: ControlCardModel) {
    this._dataControls.next(data);
  }
}
