export class ResponsiveConstants {
  static readonly MODAL_RESPONSIVE_ACCOUNT_INFORMATION = {
    Clabe: 'CLABE interbancaria',
    CardNumber: 'Número de tarjeta',
    SwiftCode: {
      Label: 'Código SWIFT',
      PlaceHolder: 'INBUMXMMXXX'
    },
    AliasInput: 'alias',
    LimitInput: 'limitInput',
    ExpirationDate: 'Vence',
    AccountNumber: 'Número de cuenta',
    BalanceAvailable: 'Saldo disponible',
    NumberSecurities: 'Número de títulos',
    PriceDay: 'Precio del Día',
    LastKnownPrice: 'Último precio conocido'
  };

  static readonly MODAL_RESPONSIVE_TDC_INFORMATION = {
    TotalBtnToltip: 'Incluye compras, disposiciones y accesorios, de la fecha de corte al día actual.',
    AltBtnToltip: 'Información'
  };

  static readonly MODAL_RESPONSIVE_TDC = {
    BalancePerDay: 'Saldo al día',
    TotalBalance: 'Saldo total',
    CutOffBalance: 'Saldo al corte',
    InstallmentBalance: 'Saldo a plazos',
    AvailableCredit: 'Crédito disponible',
    PaymentDueDate: 'Fecha límite de pago',
    PaymentToAvoidInterest: 'Pago para no generar intereses',
    MinimumPayment: 'Pago mínimo',
    MinimumPaymentMSI: 'Pago mínimo + meses sin intereses',
    CutOffDate: 'Fecha de corte'
  };
}
