<ng-container *ngIf="vm$ | async as vm">
  <div id="modal-alerts" [ngClass]="closeButtonClass">
    <div *ngIf="btnExit && !isControlCard && showButtonClose" class="d-flex flex-row-reverse"
      [ngClass]="{'close-icon': notifyRetirementType.retirementTypeBs.value}">
      <img alt="Close" width="24" height="24" role="button" [src]="closeIcon | cdnImage"
        (click)="closeModal()" (keydown.enter)="closeModal()"
        [style]="styleclose"
       [ngClass]="{'icon-close-success': modalSuccess, 'hiddenclose' :
        !showclose, 'showclose' : showclose, '' :!modalSuccess }"
      />
    </div>

    <div *ngIf="alertInfo?.header || alertInfo.imgHead" class="d-flex justify-content-center"
    [ngClass]="alertInfo.imgHead ?
      'flex-column':''" [class.my-3]="marginDiv" [class.my-2]="!marginDiv">
      <ng-container *ngIf="alertInfo.imgHead">
        <div class="d-flex justify-content-center align-content-center mb-2 m-top">
          <img width="auto" height="auto" alt="Icon" [src]="alertInfo.imgHead | cdnImage" class="info-icon" 
          [ngClass]="alertInfo?.imgClass">
        </div>
      </ng-container>
      <header class="mobile-style d-flex mb-1 justify-content-center title-color main-color-label text-align"
      [class.header-l]="useXs" [class.header-xs]="!useXs" [class.header-s]="useS"
      [class.text-align-center]="alertInfo.textCenter"
      [innerHTML]="alertInfo.header"
        [ngClass]="{'header-success': modalSuccess, 'sTitle' : !titlePatrimonial,
        'sTitleP':titlePatrimonial, '': !titlePatrimonial && !titlePatrimonial && !modalSuccess}"
        *ngIf="alertInfo.header!=''">
      </header>
      <header *ngIf="aditionalLabel" class="header-l margin-0" [innerHTML]="labelAdd"
        [ngStyle]="styleLabelAdd"></header>
    </div>
    <ng-container *ngIf="!showpatrimonial" >
      <main *ngIf="!isControlCard"
      class="text-secondary-800"
      [class.text-center]="justifyText" [class.text-justify]="!justifyText"
      [class.mb-4]="marginButton" [class.mb-3]="!marginButton"
      [style.text-align]="notifyRetirementType.retirementTypeBs.value ? 'justify':''"
      [style.w-t]="textAfore"
      [innerHTML]="alertInfo.message"
      [ngStyle]="styleMain"
      [ngClass]="{
        'add-margin': addPadding,
        'custom-style-alert' : customStyleAlert,
        'main-success detail-m' : modalSuccess,
        'delail-m' : !modalSuccess && !modalError && !modalTotalNotify,
        'detail-m main-error' : modalError, 'modal-total-retirement':modalTotalNotify}"></main>
      <main *ngIf="isControlCard" class="body-m text-align-center"
      [class.mb-4]="marginButton" [class.mb-3]="!marginButton">
        <span class="text-secondary-800 text-align-center mb-4 w-t body-m" [innerHTML]="alertInfo.message"></span>
      </main>
    </ng-container>
    <ng-container *ngIf="showpatrimonial">

      <div class="main-footer" id="main-patrimonial">

        <div class="body-m text-align-center mb-4 first" id="bodyf">
          <p [innerHTML]="patrimonialHead"></p>
        </div>

        <div class="body-m text-align-center mb-4 second" id="bodys">
          <p [innerHTML]="patrimonialFooter"></p>
        </div>
      </div>

    </ng-container>

    <footer class="d-flex justify-content-center">
      <ng-container *ngIf="horizontalButtons && !showpatrimonial && !this.alertInfo?.hiddenBtnAccept">
        <footer class="d-flex justify-content-center w-100"
          [ngClass]="hasCancel ? 'flex-column align-items-center' : ''">
          <button class="colored-btn modals-btn {{styleBtnAccept}}" *ngIf="!isControlCard" [ngClass]="
              vm.breakpoints.mobile || vm.breakpoints.tablet? 'button-m': 'button-s'"
            (keydown.enter)="$event.preventDefault()"
            (click)="action()">
            {{ alertInfo.btnLabel ?? btnLabel }}
            <img *ngIf="!isControlCard && alertInfo.imgButton" class="afore-icon" alt="afore-icon"
              [src]=" chevronright | cdnImage" width="auto" height="auto" />
          </button>

          <button class="colored-btn modals-btn" *ngIf="isControlCard" [ngClass]="
              vm.breakpoints.mobile || vm.breakpoints.tablet
                ? 'button-m': 'button-s'"
                (click)="action()"
                (keydown.enter)="$event.preventDefault()">
            {{ alertInfo.btnLabel ?? btnLabel }}
          </button>

          <ng-container *ngIf="hasCancel">
            <button class="outline-btn modals-btn mt-3" [ngClass]="
                vm.breakpoints.mobile || vm.breakpoints.tablet
                  ? 'button-m': 'button-s'"
                  (keydown.enter)="cancelAction()" (click)="cancelAction()">
              {{ alertInfo.btnCancelLabel ?? btnCancelLabel }}
            </button>
          </ng-container>
        </footer>
      </ng-container>

      <ng-container *ngIf="!horizontalButtons">
        <footer class="d-flex justify-content-center w-100">
          <button class="colored-btn modals-btn btn-w me-2" (click)="action()"
          (keydown.enter)="action()">
            {{ alertInfo.btnLabel ?? btnLabel }}
          </button>

          <button class="outline-btn modals-btn btn-w ms-2" (click)="cancelAction()"
          (keydown.enter)="cancelAction()">
            {{ alertInfo.btnCancelLabel ?? btnCancelLabel }}
          </button>

        </footer>
      </ng-container>

      <ng-container *ngIf="showpatrimonial">
        <div id="btns-show-patrimonial" class="patrimonial-ok-btn">
          <button class="colored-btn patrimonial-limit-btn modals-btn" [ngClass]="
          vm.breakpoints.mobile || vm.breakpoints.tablet
            ? 'button-m': 'button-s'" (click)="closeModal()" (keydown.enter)="cancelAction()">
            Si, continuar
          </button>


          <button class="outline-btn patrimonial-limit-btn modals-btn" [ngClass]="
          vm.breakpoints.mobile || vm.breakpoints.tablet
            ? 'button-m': 'button-s'" (keydown.enter)="$event.preventDefault()" (click)="action()">
            Modificar límite
          </button>
        </div>
      </ng-container>
    </footer>
  </div>
  <ng-template #otp>
    <app-otp-card-replacement [optionsToken]="optionsModalToken"></app-otp-card-replacement>
  </ng-template>
</ng-container>
