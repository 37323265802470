<content-wrapper
  *ngIf="vm$ | async as vm"
  [contentMargin]="contentMargin"
  [useFlex]="!vm.breakpoints.mobile"
>
  <div id="successful-operation" content class="width-content canvas"
    [ngClass]="vm.breakpoints.mobile || vm.breakpoints.desktop ? 'pt-3':'pt-4'"
    *ngIf="!errorMsg && vm.date else operationError">
    <div *ngIf="folio" class="d-flex flex-column justify-content-center align-items-center">
      <img
        [src]="check | cdnImage"
        class="icon-check"
        alt=""
        width="auto"
        height="auto"
      />
      <div class="pt-3 header-l default-color">{{ message }}</div>
      <div class="pt-2 body-m label mt-2 mb-2 default-color">Folio: {{ folio }}</div>
      <show-amount [useBreak]="vm.breakpoints.mobile" label="" color="var(--secondary-800)"></show-amount>
      <div class="pb-3 detail-m">{{ operationDate }}, {{ hoursDate }}h</div>
      <app-shared-buttons
        class="ignore-element"
        [captureImage]="captureImage"
        [shareCapture]="shareCapture"
        [hideMail]="true"
        [webCard]="true"
      ></app-shared-buttons>
    </div>
    <div
      id="main-content"
      class="pt-4"
      [ngClass]="vm.breakpoints.mobile ? 'mt-3' : 'mt-4'"
      *ngIf="folio">
      <div>
        <selected-card
          label="Cuenta origen"
          classes="ps-3"
          [cardImage]="account.directorioImagen"
          [cardName]="account.tipoCuenta"
          [cardNumber]="numberAccount"
          [showImage]="true"
          [dividerClass]="vm.breakpoints.mobile ? 'my-2' : 'm-2'"
        ></selected-card>
        <div class="mt-4">
          <selected-card
            classes="ps-3"
            [cardImage]="mainAccount.directorioImagen"
            [cardName]="mainAccount.descripcionCuenta"
            [cardNumber]="numberAccount"
            [showImage]="true"
            [dividerClass]="vm.breakpoints.mobile ? 'my-2' : 'm-2'"
          ></selected-card>
        </div>
      </div>
      <div>
        <div
          class="header-xs ps-3 default-color"
          [ngClass]="vm.breakpoints.mobile ? 'mt-4' : ''"
        >
          Fecha de depósito
        </div>
        <app-info-label
          classes="mt-3 ps-3"
          [head]="vm.date"
          body="Fecha"
          [margin]="vm.breakpoints.mobile ? 'my-2' : 'm-2'"
        ></app-info-label>
        <div
          class="detail-m text-align-justify mt-3 secondary-color"
          [ngClass]="!vm.breakpoints.mobile ? 'px-2' : ''"
          [class.pt-3]="!vm.breakpoints.desktop"
        >
          Los recursos solicitados se acreditarán en la cuenta asociada en la
          fecha señalada de acuerdo al termino de los plazos de inversión
        </div>
      </div>
    </div>
    <div
        class="btn-container d-flex justify-content-center ignore-element"
        [ngClass]="vm.breakpoints.mobile ? buttonsSpacing.mobile : buttonsSpacing.desktop"
        *ngIf="folio"
    >
      <button
        class="colored-btn btnGeneric"
        (click)="home()"
        [ngClass]="vm.breakpoints.mobile ? 'button-s' : 'button-m'"
      >
        Listo
      </button>
    </div>
    <div
      class="btn-container d-flex justify-content-center mt-3 ignore-element"
      *ngIf="folio"
    >
      <button
        class="outline-btn btnGeneric"
        (click)="otherOperationButton()"
        [ngClass]="vm.breakpoints.mobile ? 'button-s' : 'button-m'"
      >
        Otra operación
      </button>
    </div>
  </div>
</content-wrapper>

<ng-template #operationError>
  <app-error-exception [otherOperation]="otherOperation" [showButtons]="true"
    [errorMessage]="errorMsg"></app-error-exception>
</ng-template>
