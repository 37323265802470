import { Strings } from 'src/core/constants/Strings';

export class CodeflexStrings {

  static readonly CODEFLEX_TERMS = 'Los recursos solicitados pueden retirarse después de 31 días naturales, y se acreditarán en la cuenta asociada.';

  static readonly CODEFLEX_BUTTONS = {
    RequestWithdrawal:'Solicitar retiro',
    Manage: 'Administrar'
  };

  static readonly CODEFLEX = {
    Title: 'Retiro ',
    WithdrawalConfirm: 'Confirma los datos del retiro',
    DateTitleLabelResponsive: { mobile: 'Fecha de pago', tablet: Strings.DEPOSIT_DATE },
    DateLabelResponsive: { mobile: Strings.DEPOSIT_DATE, tablet: 'Fecha' },
    Subtitle: 'Ingresa los datos del retiro'
  };

  static readonly WAITING_TIME = (days: number) => `El depósito se verá reflejado en tu cuenta, después de ${days} días`;
  static readonly NO_BALANCE_AVAILABLE = 'Saldo insuficiente para realizar el retiro';
}
