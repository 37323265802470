import { ClassHTML } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';

export class Strings {
    static readonly EMPTY = '';
    static readonly ACTIVE = 'active';
    static readonly MSG_TIMEOUT_AGENDA_THIRD_PARTIES = (time: number) => `Para poder realizar operaciones con esta cuenta debes de esperar ${time} minutos.`;
    static readonly AMOUNT_TITLE = '¿Cuánto vas a pagar?';
    static readonly AMOUNT = 'amount';
    static readonly ENTER_PAYMENT_DETAILS = 'Ingresa los datos del pago';
    static readonly PLACEHOLDER_SEARCH_CP = 'Buscar por C.P.';
    static readonly CARD = 'Tarjeta';
    static readonly SUBTITLE = 'subtitle';
    static readonly OF = 'de';
    static readonly HOURS = ' hrs.';
    static readonly FEDERAL_TAXES_PAYMENT_TITTLE = 'Pago de Impuestos Federales a través de la Banca Mexicana.';
    static readonly FEDERAL_TAXES_PAYMENT_SUBTITTLE = 'PAGO DE DERECHOS, PRODUCTOS Y APROVECHAMIENTOS.';
    static readonly OPTIONS = 'options';
    static readonly HOURS_SIMPLE = ' h';
    static readonly CAPS_LOCK_ON_MESSAGE = 'Atención: Las mayúsculas están activas';
    static readonly CAPS_LOCK_OFF_MESSAGE = 'Atención: Las mayúsculas están desactivadas';
    static readonly WITHOUT_RESULTS = 'Sin resultados<br>No se encontraron resultados para tu búsqueda';
    static readonly TARGET_BLANK = '_blank';
    static readonly DELETE_IMAGE_SUCCESS = (type: string) => `Se eliminó correctamente la imagen de ${type}`;
    static readonly PAYMENT_CREDIT = 'Pago Crédito';
    static readonly HEAD_OPTION_BANK = 'Información para el Banco Beneficiario';
    static readonly PAYMENT_PURPOSE = 'Propósito del pago';
    static readonly TITLE_REFERECE = 'Referencia';
    static readonly RITGH_REQUEST_REJECTED = 'La solicitud del Derecho fue rechazada';
    static readonly ACCOUNT_NUMBER = 'Número de cuenta';
    // Error message
    static readonly GEOLOCATION_PERMISSIONS_MSG = 'Sin permisos de ubicación';
    static readonly GEOLOCATION_ERROR_MSG = 'No es posible obtener la ubicación en este momento';
    static readonly CARD_NUMBER_ERROR_MSG = 'No cuentas con tarjeta(s)';
    static readonly WITH_CARD_NUMBER = 'con número de tarjeta';
    static readonly WITH_ACCOUNT_NUMBER = 'con número de cuenta';
    static readonly ERROR_SERVICE_GENERIC = '¡Ups, algo va mal! Inténtalo más tarde';
    static readonly MAX_AMOUNT_ERROR_MSG = (amount: string) => `El monto máximo es ${amount}`;
    static readonly MIN_AMOUNT_MSG = (amount: string) => `El monto mínimo es ${amount}`;
    static readonly QR_FACEPASS_TIMEOUT_MSG = 'La validación de acceso vía Lector QR/Facepass ha excedido el tiempo límite permitido.';
    static readonly MAX_AMOUNT_EDOMEX_MSG = 'Se sobrepasa el límite de pago';
    static readonly AMOUNT_MINIMUM_MSG = 'El monto debe ser mayor a $1.00';
    static readonly EMPTY_SELECT_FILE_ERROR = 'No ha seleccionado ningún archivo';
    static readonly WITHOUT_BENEFICIARIES = 'No se encontraron beneficiarios para esta cuenta';
    static readonly SELECT_FILE_JPG_PNG_ERROR = 'Selecciona un archivo .jpg / .png';
    static readonly SELECT_ONE_FILE_ERROR = 'Solo seleccione un archivo';
    static readonly ERROR_UPLOAD_FILE = 'Contenido del archivo no válido';
    static readonly MAX_IMAGE_WEIGHT = 'Peso máximo : 256KB';
    static readonly RETAINED_BALANCE_TOOLTIP = 'Se muestra el acumulado de las operaciones por confirmar por el establecimiento/proveedor de servicio';
    static readonly CODEFLEX_TERMS = 'Los recursos solicitados pueden retirarse después de 31 días naturales, y se acreditarán en la cuenta asociada.';
    static readonly NO_FUTURE_TRANSACTIONS = 'No cuentas con operaciones programadas al momento.';
    static readonly ERROR_GET_TYPE_ACCOUNT = 'Error';
    static readonly EMAIL_PLACE_HOLDER = 'correo@correo.com';
    static readonly CONFIRM_OPERATION = 'Confirmación de la operación';

    // Messages
    static readonly ADD_ADDRESS = 'Agregar destinatario';
    static readonly FOLIO = 'Folio: ';
    static readonly APPLICATION_FOLIO = 'Folio de solicitud';
    static readonly OPERATION_SUCCESSFUL = 'Operación exitosa';
    static readonly MSG_POPUP_TITLE = 'Atención';
    static readonly MSG_POPUP_ACCEPT = 'Aceptar';
    static readonly MSG_SESSION_TIMEOUT = 'Por seguridad tu sesión ha finalizado, ingresa nuevamente.';
    static readonly MSG_SESSION_INACTIVITY_TIMEOUT = 'Por tu seguridad se ha cerrado la sesión, por falta de actividad.';
    static readonly MSG_TIMEOUT_AGENDA = `Por disposición oficial, tiene que esperar time min. a
    partir del momento que se dio de alta o se modificó esta agenda para continuar operando`;
  static readonly DELETE_TOKEN_MESSAGE = 'Se eliminó correctamente el token';
  static readonly MSG_AGENDAS_TRANSFER = 'No cuentas con agendas';
  static readonly MSG_AGENDAS_TRANSFER_DISCHARGE = 'Da de alta tu primer agenda';
  static readonly MSG_ACCOUNT_DELETE_DESCRIPTION = 'Se ha eliminado la cuenta:';
  static readonly MSG_NOTIFICATION_DELETE_DESCRIPTION = 'Se ha eliminado la notificación:';
  static readonly QR_LOGIN_TITLE = 'Escanea el código';
  static readonly HOME_TITLE = 'Ingresa a Inbursa en Línea';
  static readonly PAY_SERVICE_TITLE = 'Pagar servicio';
  static readonly ADD_TITLE = 'Agregar';
  static readonly INITIAL_ALERT = `<strong>Nunca solicitaremos datos confidenciales</strong> por email, SMS, redes sociales, vía telefónica o en sucursal,
    <strong>nunca proporciones datos</strong> de tu tarjeta,
    claves de acceso o código token.`;
  static readonly TIMEOUT_AGENDA = `Por disposición oficial, tiene que esperar time min. a
    partir del momento que se dio de alta o se modificó esta agenda para continuar operando`;
    static readonly CONTENT_LOYALTY = 'Tienes una nueva<br>recompensa';
    static readonly MESSAGE_LOYALTY = 'Gracias por enrolarte a Apple Pay';
    static readonly HEADER_PROMOTIONS_LOYALTY = 'despegar.com compra los martes, jueves y domingos';
    static readonly IMAGE_URL_LOYALTY = 'linkedin-mc.png';
    static readonly CONTENT_PROMOTIONS_LOTALTY = '30% de descuento pagando con tu tarjeta Inbursa Mastercard';
    static readonly CONDITIONS = 'Promoción válida hasta el 15/01/2023';
    static readonly TRANSFER_OPTION = 'Transferir';
    static readonly TRANSFER_MONEY_OPTION = 'Transferir Dinero';
    static readonly LIMITS_OPTION = 'Bloqueos y límites';
    static readonly DIRECT_DEBIT_OPTION = 'Domiciliación';
    static readonly CHECK_PROTECTION_OPTION = 'Protección de cheques';
    static readonly SCHEDULED_BANK_TRANSFERS_OPTION = 'Traspasos programados';
    static readonly WEEK_REFUND_OPTION = 'Reintegro de semanas';
    static readonly PARTIAL_WITHDRAWALS_OPTION = 'Retiros Parciales';
    static readonly TOTAL_WITHDRAWALS_OPTION = 'Retiros Totales';
    static readonly VOLUNTARY_CONTRIBUTIONS_OPTION = 'Retiro de Aportaciones Voluntarias';
    static readonly AMOUNT_LABEL = 'Monto máximo';
    static readonly ADDITIONAL_CONTRIBUTIONS_POLICY = 'Aportaciones Adicionales';
    static readonly BUREAU_ALERT = 'Alertas Buró';

    static readonly HEADER_UPLOAD_INFORMATION = '¡Casi listo!';
    static readonly MESSAGE_UPLOAD_INFORMATION = 'Estamos cargando tus datos.';
    static readonly VIEW_TRANSACTIONS = 'Ver movimientos';
    static readonly ACCOUNT_STATEMENTS = 'Estados de cuenta';
    static readonly CREDITS = 'Créditos';
    static readonly SAVINGS = 'Crear ahorros';
    static readonly BUY_FUNDS = 'Compra de Fondos de Inversión';
    static readonly SALE_FUNDS = 'Venta de Fondos de Inversión';
    static readonly AVAILABLE_BALANCE = 'Saldo disponible';
    static readonly WEB_CARD = 'Tarjeta Web';
    static readonly ACCOUNT_LIMIT_LABEL = 'Límite a Cuenta Eje';
    static readonly MINIMUM_CHARACTERS = (min: number) => `Mínimo ${min} caracteres`;
    static readonly ACCOUNT_BALANCE_INFORMATION = {
        PENDING_DEPOSITS: 'Depósitos pendientes',
        RETAINED_BALANCE: 'Saldo retenido',
        CURRENT_BALANCE: 'Saldo actual'
    };

    static readonly PAGE_HEADER = {
        Subtitle: 'Ingresa los datos de la operación'
    };
    static readonly FROM_YOUR_ACCOUNT = 'Desde tu cuenta';
    static readonly CHANGE_ACCOUNT = 'Cambiar de cuenta';
    static readonly ADD_NEW_PAYMENT = 'Agregar un nuevo pago';
    static readonly WITHOUT_PAYMENTS = 'Aún no tienes ningún pago registrado';
    static readonly ACCOUNT_LABEL = 'Número de cuenta';
    static readonly INVALID_EMAIL = 'El correo electrónico no es válido';
    static readonly DATE_LABEL = 'Fecha de pago';
    static readonly DEPOSIT_DATE = 'Fecha de depósito';

    static readonly SELECT_ACCOUNT = {
        From: 'Cuenta origen',
        FromYourAccount: 'Desde tu cuenta',
        SelectAccount: 'Selecciona una cuenta',
        ChangeAccount: Strings.CHANGE_ACCOUNT
    };

    static readonly SERVICE_PAYMENT = {
        Title: 'Pago de servicios',
        LeagueInformation: 'Consulta los servicios que puedes pagar',
        HeaderSubtitle: 'Confirmación del pago',
        MexicoState: 'EDOMÉX',
        SelectServiceToPay: 'Selecciona el servicio que quieres pagar',
        SipareTitle: 'Pago Sipare',
        Required: 'Campo requerido',
        InvalidLengthImploc: 'Longitud debe ser mayor que 16 y menor o igual a 20'
    };

    static readonly MEXICO_STATE_SERVICE = {
        Title: 'Pagos a Gobierno del Estado de México'
    };

    static readonly PAYMENT_INSURANCE_POLICIES = {
        Title: 'Pago Pólizas de Seguros',
        Label: 'Pago Recibo de póliza',
        LabelMSI: 'Pago de Póliza'
    };

    static readonly INTERNATIONAL_BENEFICIARY = {
        Title: 'Agregar nuevo destinatario internacional',
        Information: 'Consulta el manual para transferencias internacionales',
        DataConfirmation: 'Confirma los datos de la operación'
    };

    static readonly CT_PATRIMONIAL_TRANSFER_FORM = {
        Key: 'patrimonial',
        HeaderLimit: 'Has excedido el límite establecido de traspasos',
        LimitMessage: 'La operación se aplicará después de 48 horas|Puedes modificar el límite de tu operación para que el depósito aplique de manera inmediata|patrimonial'
    };

    static readonly MODAL_MODIFY_ACCOUNT = {
        Id: 'cancelAccount',
        Header: '<p>¿Estás seguro de que <br/>quieres cancelar<br/> esta cuenta?</p>',
        Label: '<p>Gerardo Martínez con <br/>número de tarjeta BBVA 0012711693</p>',
        Continue: 'Si',
        ConfirmId: 'confirmCancelAccount',
        ConfirmHeader: 'Cancelación Exitosa',
        ConfirmLabel: `<p>${Strings.MSG_ACCOUNT_DELETE_DESCRIPTION} <br>`
    };

    static readonly PAYMENT_SUA = {
        Title: 'Pago SUA'
    };

    static readonly AGENDA = {
        TitleNewAgenda: 'Agregar nuevo destinatario',
        ErrorAgendaNational: 'Agrega un destinatario.',
        ErrorAgendaExpress: 'Selecciona transferencia express ó agrega un destinatario.'
    };


    static readonly ACCOUNT_STATEMENT = {
        LabelTitle: 'Estado de cuenta',
        LabelBox: 'Periodo a consultar',
        LabelButton: 'Consultar',
        LabelOptionPeriod: 'Selecciona un periodo',
        LabelOptionAccount: 'Selecciona una cuenta',
        LabelOptionNotify: 'Últimos mes',
        WithoutPeriodsMessage: 'No cuentas con periodos para consultar',
        InProcess: 'Cargando...'
    };

    static readonly HOME = {
        Title: 'Hola,',
        TitleMyProducts: 'Mis productos',
        SubtitleMyProducts: 'Selecciona para ver más opciones',
        TitleCardControl: 'Control de tarjetas',
        TitleBeneficiaries: 'Beneficiarios',
        TitleFrequentlyQuestions: 'Preguntas frecuentes'
    };

    static readonly INVESTMENT = {
        LabelTitle: 'Alta de inversión',
        EnterInvestmentData: 'Ingresa los datos de la inversión',
        EnterAmountLabel: '¿Cuánto quieres invertir?',
        OperationLabel: 'Inicio y término de la inversión',
        InvestmentType: 'Tipo de inversión',
        Term: 'Plazo',
        ConfirmOperation: 'Confirmación de la operación',
        OptionElementOne: 'investment',
        OptionElementTwo: 'account',
        SelectInvestmentLabel:'Selecciona el tipo de inversión'
    };

    static readonly CODE_SECURITY = {
        Title: 'Ingresa el código seguridad',
        SubtitleToken: 'Código de seguridad'
    };

    static readonly SERVICES = {
        UnexpectedMsg: 'Servicio no disponible en este momento, inténtalo más tarde.',
        Title: 'Agregar servicio',
        AddNewPayment: Strings.ADD_NEW_PAYMENT,
        WithoutPayments: Strings.WITHOUT_PAYMENTS,
        IncompleteData: (response: string) => `No se cuenta con ${response}`,
        Quarter: 'bimestre',
        Concept: 'concepto',
        FiscalYear: 'ejercicio',
        ServiceToPay: 'Servicio a pagar',
        SiparePayment: 'Pago de SIPARE',
        Payment: 'Pago SIPARE'
    };

    static readonly PROPIETARY = {
        TelmexValue: 'Teléfonos de México',
        AmexValue: 'American Express MX',
        SanbornsValue: 'Tarjetas Sanborns',
        AmitelcValue: 'Paquete sin Límite',
        ContelcValue: 'Contrato Telcel',
        InbursaCardValue: 'Tarjeta de crédito Inbursa',
        CardValue: 'Tarjeta de crédito',
        LabelInbursa: 'Inbursa',
        LiverpoolValue: 'Liverpool',
        PalacioDeHierroValue: 'Palacio de Hierro',
        AmountProtection: 'Monto de protección',
        ComextText: 'COMERCIO EXTERIOR',
        TecinbValue: 'Tarjetas de Crédito Inbursa'
    };


    static readonly TRANSFER = {
        Head: 'Transferencia realizada',
        Body: 'Información de la operación',
        TitleInternationalTransfer: 'Transferencias internacionales',
        MainConfirm: 'Confirma los datos de la operación',
        ConfirmOperation: 'Confirma la operación',
        EnterAmountLabel: '¿Cuánto quieres enviar?',
        Quantity: 'Cantidad',
        Folio: 'Folio: ',
        ReceivedAmount: 'Cantidad recibida',
        SuccessfulWithdrawaL: 'Retiro exitoso',
        RetirementSubaccount:'Subcuenta de retiro'
    };

    static readonly ACCOUNT_INFO_MESSAGE = {
        Default: 'Información de tu cuenta',
        Insurances: 'Información de tu seguro de',
        DefaultInvestmentMessage: 'Información de tu inversión',
        DefaultInvestmentTitle:'Inburplazo CT'
    };

    static readonly CLARIFICATION = {
        SettlementOption: 'Dictamen: Transacción',
        Claim: 'Reclamación terminada a favor del cliente con reembolso de:',
        SortBy: 'fechaAcceso',
        TableHeaders: {
            From: Strings.FROM_YOUR_ACCOUNT,
            ClarificationReference: 'Folio Aclaración',
            ClarificationType: 'Tipo Aclaración',
            Status: 'Estatus',
            RegisterDate: 'Fecha de alta',
            RegisterForm: 'Medio de alta',
            Amount: 'Monto'
        },
        Filters: {
            Account: 'cuenta',
            Reference: 'expediente',
            ClarificationType: 'tipoAcl',
            Status: 'estatus',
            Date: 'fechaAlta',
            RegisterForm: 'medioAlta',
            Amount: 'importeReclamado'
        },
        TitleClarifications: 'Aclaraciones',
        TitleClarification: 'Aclaración',
        TitleOperationInformation: 'Información de la operación',
        MessageClarifications: 'Visualiza el estatus de tus aclaraciones',
        ClarificationDate: 'Fecha de aclaración',
        TypeClarification: 'Tipo de operación',
        TransactionNumber: 'Número de transacción',
        TransactionDate: 'Fecha de operación',
        Cause: 'Motivo'
    };

    static readonly PAYMENT_TAXES = {
        PayServiceTitle: 'Pagar Servicio',
        ConfirmPay: 'Confirmar el pago'
    };

    static readonly POLICIES = {
        SelectedPoliciesError: 'Para concluir el proceso debes seleccionar recibos consecutivos',
    };

    static readonly GENERIC_ERRORS = {
        IncorrectFormat: 'Formato no válido',
        Required: 'Este campo es requerido',
        InformationNotFound: 'Información no encontrada',
        InvalidLength: 'Longitud no válida',
        OtherOption: 'Seleccione otra opción',
        InvalidValue:'Valor no válido.',
        MaxLengthError: (length: number) => `Longitud máxima de ${length} caracteres`
    };

    static readonly DATE_ERRORS = {
        IncorrectMonth: 'El mes esta fuera de rango',
        IncorrectYear: 'El año no es válido',
        IncorrectDay: 'El día en el mes no es válido'
    };

    static readonly CARD_CONTROL = {
        CvvAlert: 'Mínimo 3 caracteres',
        NipAlert: 'Mínimo 4 caracteres',
        CancelError: 'Número de tarjeta no válido'
    };

    static readonly ACCESS_HISTORY_FILTER = {
        Status: 'estatus',
        Authentication: 'medioAutenticacion',
        AccessDate: Strings.CLARIFICATION.SortBy,
        Ip: 'ip',
        Device: 'dispositivo',
        Browser: 'Navegador',
        ErrorDescription: 'descripcionError'
    };

    static readonly RESIZE = {
        Landscape: 'landscape',
        Portrait: 'portrait'
    };

    static readonly MODAL_CONFIRM = {
        DesktopTitle: '¿Estás seguro de que quieres<br />cancelar la operación?',
        MobileTitle: '¿Estás seguro de que quieres cancelar la operación?'
    };

    static readonly DEDUCTIBLE = {
      Title: 'Pago deducible Autos',
      TitleOne: 'Pago deducible de autos',
      Subtitle: Strings.ENTER_PAYMENT_DETAILS,
      SelectCardAccount: Strings.CHANGE_ACCOUNT,
      EnterAmountLabel: Strings.AMOUNT_LABEL,
      LabelServiceToPay: 'Pago Deducible de autos',
      LabelOrderAdmission: 'Número de siniestro',
      Placeholder: 'XXXXXXXXX',
      IdService: 'deducible',
      Policy: 'Póliza',
      DataClaim:{
        Date: 'Fecha de siniestro',
        Status: 'Estatus',
        Name: 'Nombre del beneficiario',
        Vehicle: 'Vehículo',
        Model: 'Modelo'
      },
      ConfirmProcess:{
        Title: 'Pago de servicios',
        Subtitle: Strings.SERVICE_PAYMENT.HeaderSubtitle
      },
      AlertAmount: 'El monto máximo es $',
      ZeroAmount: 'El monto debe ser mayor a $',
      ErrorLength: 'El número de siniestro debe ser de 13 dígitos'
  };

    static readonly HIDDEN_CARD_DIGITS = {
        FourDigits: '****',
        ThreeDigits: '***',
        TwoDigits: '**',
        FifteenDigits: '***************'
    };

    static readonly ADD_SERVICE = {
        AliasLabel: 'Alias del servicio',
        AliasName: 'alias',
        AliasPlaceholder: 'Personalizar el servicio',
        AliasMsgError: 'Alias del servicio debe ser de 3 a 50 caracteres',
        LimitLabel: 'Límite máximo diario',
        LimitName: 'dailyLimit',
        LimitRequiredError: 'Límite máximo diario es requerido',
        LimitMaxError: 'El monto máximo es de $',
        LimitMinError: 'El monto mínimo es de $1.00',
        AccountLabel: this.ACCOUNT_NUMBER,
        AccountName: 'accountNumber',
        AccountPlaceholder: '123 123 123',
        AccountRequiredError: 'Número de cuenta es requerido',
        AccountPatternError: 'Número de cuenta debe ser de 12 dígitos',
        AccountAmexPatternError: 'Número de cuenta debe ser de 15 dígitos',
        AccountClabePatternError: 'Número de cuenta debe ser de 16 dígitos',
        SignaturePanelLabel: 'Panel de firma',
        SignaturePanelName: 'panelSignature',
        SignaturePanelImageAlt: '?',
        SignaturePanelMaxLength: 'Panel de firma debe ser de 9 dígitos',
        ServiceText: 'Servicio',
        ServiceName: 'service',
        TagNumberLabel: 'Número de tag',
        TagValue: 'Pase Urbano',
        TagNumberError: 'Número de tag es requerido',
        TagNumberPlaceholder: 'IMDM000000000',
        TelmexValue: 'Telmex/Tenor',
        AmexValue: 'American Express',
        MixupValue: 'Tarjeta Mixup',
        SanbornsValue: 'Tarjeta Sanborns',
        AmitelcValue: 'Paquetes Amigo Sin Límite Telcel',
        ContelcValue: 'Plan Tarifario Telcel',
        LiverpoolValue: 'Pago Tarjeta de Crédito Liverpool',
        PalacioDeHierroValue: 'Pago De Servicios Palacio de Hierro',
        CarnetValue: 'Tarjeta de crédito',
        CarnetPlaceholder: '15 ó 16 dígitos',
        ContractNumber: 'Número de Contrato',
        ContractNumberRequired: 'Número de Contrato es requerido',
        ContractNumberMinError: 'Ingresa número de contrato a 10 dígitos',
        CardNumber: 'Número de Tarjeta',
        CardNumberMinMaxError: 'Número de tarjeta debe ser de 15 o 16 dígitos',
        CreditNumberName: 'accountCreditNumber',
        BankNameLabel: 'Nombre del Banco',
        BankName: 'bankName',
        BankPlaceholder: '---- ---- ---- ---- ---- --',
        SignaturePanelPlaceholder: '---------',
        subtitleNewService: 'Ingresa los datos del servicio'
    };

    static readonly MODAL_MESSAGES = {
        DeleteFacePassHeader: 'Eliminar Facepass',
        DeleteFacePassMessage: 'Confirma que deseas eliminar <br/> tu registro de Facepass en este dispositivo',
        TokenCancellationHeader: 'Cancelación de token',
        TokenCancellationMessage: 'Confirma que deseas eliminar tu registro <br/> de Token en este dispositivo',
        HeaderMyCards: 'Cancelación de tarjeta',
        MyCardsID: 'Confirma que deseas cancelar tu tarjeta'
    };

    static readonly ENABLE_SLIDE_FACEPASS = {
        CardSlider: 'facepass-card-slider',
        MobileTokenCardSlider: 'token-card-slider'
    };

    static readonly ENABLE_SLIDE_ADDITIONAL_CARD = {
        SliderAdditional: 'CardsScroll'
    };

    static readonly ENABLE_SLIDE_HOME_TABS = {
        TabsSlider: 'Tabs'
    };

    static readonly ENABLE_SLIDE_HOME_OFFERS = 'OfferContainer';

    static readonly ENABLE_SLIDE_MODAL_PAYMENT_OPTIONS = 'ModalPaymentOptions';

    static readonly ENABLE_SLIDE_HOME_CARD_INFO = {
      CardsSlider: 'CardInfoContainer'
    };

    static readonly BUTTONS = {
      Close: 'Cerrar',
      Ready: 'Listo',
      OtherOperation: 'Otra operación',
      Cancel: 'Cancelar',
      Accept: Strings.MSG_POPUP_ACCEPT,
      Continue: 'Continuar',
      ToRequest: 'Solicitar',
      ConfirmAndContinue: 'Confirmar y continuar',
      Modify: 'Modificar',
      CloseSession: 'Cerrar sesión',
      GoOut: 'Salir',
      Send: 'Enviar',
      Yes: 'Sí',
      No: 'No'
  };

    static readonly GMM_MESSAGE = {
        Title: 'Seguro GMM',
        Description: 'GASTOS MÉDICOS MAYORES'
    };

    static readonly MESSAGES = {
      CurrencyFormatAddressee: '$1 ',
      CaptureLine: 'Línea de captura',
      Validate: 'CodeService',
      Select: 'Selecciona',
      LabelAccount: 'A tu cuenta',
      TotalToPay: 'Total a pagar',
      SelectDate: 'Selecciona fecha',
      UnrecognizedCharge: 'Cargo no reconocido',
      DataConfirmation: 'Confirmación de datos',
      PaymentCompleted: 'Pago realizado',
      EmailError: 'Formato de correo no válido',
      CompleteProcess: 'Trámite exitoso',
      AmountTotal:'Monto total',
      AddFile: 'Subir archivo',
      ProcedureToPerform: 'Trámite a realizar',
      WithdrawalAmount:'Cantidad de retiro',
      SuccessfulRequest: 'Solicitud Exitosa'
    };

    static readonly CHECK_PROTECTION = {
        Check: 'cheque',
        MainTitle: 'Protección',
        PaymentDetails: 'Ingresa los datos de pago',
        AmountProtection: '¿Cuál es el monto protección?',
        MaximumCheck: 'Protección cheques mayores al monto',
        Controls: {
            NumChecks: 'numChecks',
            BeneficiaryName: 'beneficiaryName'
        }
    };

    static readonly CHECK_ACTIVATION = {
        Filters: {
            Pending: 'Pendiente',
            Received: 'Recibida',
            Canceled: 'Cancelado',
            Active: 'Activo',
            Liquidated: 'Liquidado',
            Returned: 'Devuelto'
        },
        CheckNumber: {
            CheckNumberOne: '50 - 350',
            CheckNumberTwo: '05 - 500',
            CheckNumberThree: '25 - 200',
            CheckNumberFour: '10 - 300',
            CheckNumberFive: '50 - 300',
            CheckNumberSix: '351 - 400',
            CheckNumberThirtyFive: '35',
            CheckNumberThirtySix: '35',
            CheckNumberNinetyFour: '94',
            CheckNumberZeroFive: '05'
        }
    };

    static readonly MOCK_MESSAGE = {
        State: 'XXXX',
        Alias: 'Alias'
    };

    static readonly LOGIN = {
        InvalidPassword: 'La contraseña es inválida. Debes esperar 1 minuto para volver a ingresar.',
    };

    static readonly CREDIT_PAYMENT = {
        Title: 'Pago de Crédito',
        NextPayment: 'Próximo pago',
        TotalPayment: 'Pago total',
        ProgrammedPayment: 'Pago programado',
        OtherAmount: 'Otro importe',
        BalanceDueLabel: 'Saldo adeudado',
        StartingAmountLabel: 'Monto inicial',
        InterestRateLabel: 'Tasa',
        CurrentPayableBalanceLabel: 'Saldo a liquidar actual:',
        PastDueInterest: 'Intereses vencidos:',
        PastDueInterestVat: 'IVA intereses vencidos:',
        DefaultInterest: 'Intereses moratorios',
        LateInterestVat: 'IVA intereses moratorios',
        OriginAccount: 'Cuenta ordenante',
        CreditAccount: 'Con abono a tu cuenta',
        Description: 'Descripción'
    };

    static readonly TDC_PAYMENT = {
      PaymentNoInterest: 'Pago para no generar intereses',
      MinimumPayment: 'Pago Mínimo',
      MinimumPaymentPlusMSI: 'Pago Mínimo + meses sin intereses',
      TotalPayment: 'Total al corte',
      BalanceDay: 'Saldo al día',
      Other: 'Otro importe'
    };

    static readonly MSG_ERROR = 'Lo sentimos, estamos teniendo dificultades para acceder a tus tarjetas en este momento.';

    static readonly SEND_MAIL = {
        EmailSuccess: 'Correo enviado',
        EmailSuccessDescription: 'El correo se ha enviado',
    };

    static readonly PAYMENT_DETAILS = {
        Subtitle: 'Confirma los datos',
        CarnetID: 'Pago de Tarjeta de Crédito a Otros Bancos'
    };

    static readonly POP_UP_MESSAGE = {
        AccountStatusHeldPrinting: `Estimado cliente (a), le informamos que se detectaron inconsistencias entre sus datos registrados,
        por lo que es necesario acuda a una de nuestras sucursales para aclarar esta situación.`,
    DeleteFacePassMessage: 'Se eliminó correctamente el facepass',
    CardAdmin: 'Ingresa los 3 dígitos que se ubican en tu tarjeta Inbursa'
  };

  static readonly VALIDATION_MESSAGE_SERVICE = {
    Email: 'El correo electrónico no es válido',
    Phone: 'Número Telefónico es requerido',
    CardNumber: 'Número de tarjeta es requerido',
    Bank: 'Nombre del Banco requerido'
  };

  static readonly VALIDATION_MESSAGES = {
    LoyaltyAllowedPoints: 'La cantidad debe contener múltiplos de 1000',
    Portability: 'El tipo de portabilidad es requerido'
  };

  static readonly SECURITY_CODE = {
    TitleToken: 'Código Token',
    TokenHead: 'Ingresa el código Token'
  };

  static readonly INPUT_NAMES = {
    PhoneNumber: 'telephoneNumber'
  };

  static readonly INPUT_LABELS = {
    PhoneNumber: 'Número Telefónico',
    Phone: 'Teléfono'
  };

  static readonly CHECK_CANCEL = {
    Title: 'Confirma la cancelación',
    Subtitle: 'Confirma los datos de la cancelación',
    Controls: {
      CheckNumber: 'checkNumber',
      HeirName: 'heirName'
    }
  };

  static readonly CODEFLEX = {
    Title: 'Retiro CODEFLEX 31',
    WithdrawalConfirm: 'Confirma los datos del retiro',
    DateTitleLabelResponsive: { mobile: 'Fecha de pago', tablet: Strings.DEPOSIT_DATE },
    DateLabelResponsive: { mobile: Strings.DEPOSIT_DATE, tablet: 'Fecha' },
    Subtitle: 'Ingresa los datos del retiro',
    NoCard: '***0667',
    InterbankKey: '0361 8000 1257 9066 67',
    Balance: '4999800.49',
    TypeCard: 'NÓMINA prueba',
    AccountEfe: '/app-assets/accounts/tar_cuentaefe.png'
  };

  static readonly PORTABILITY = {
    Title: 'Portabilidad de nómina',
    ConfirmPaymentData: 'Confirma los datos del pago',
    Subtitle: 'Transferencia de nómina',
    TermsConditions: 'Acepto Términos y condiciones',
    MessagePortability: `El proceso de Portabilidad de Nómina será referenciado sobre el número de tarjeta solicitado,
        si la tarjeta es dada de baja el proceso de portabilidad quedará cancelado automáticamente`,
        HeaderSelectAccount : 'Enviar nómina de:',
        PortabilityRegistration : 'Alta portabilidad',
        Portability : 'Portabilidad',
        ToOtherBank : 'De inbursa a otro banco',
        ToInbursa : 'De otro banco a inbursa',
        ReceivingBank : 'Banco receptor',
        IssuingBank : 'Banco emisor',
        DateOfBirth : 'Fecha de nacimiento'
    };

    static readonly OPERATION_SCHEDULED = {
        TypeLabels: [
            'Otro banco / SPEI',
            'Tercero Inbursa / SPEI'
        ]
    };

    static readonly LABELS_MANAGE_CARDS = {
        Lock: 'bloqueo',
        Modify: 'modifica',
        DynamicCVV: 'cvv',
        CancelCard: 'cancela'
    };

    static readonly INBURSA_LOYALTY_POINTS = {
        Header: 'Eliminar programa',
        Message: 'Al eliminar no podrás transferir<br>tus puntos a este programa<br>¿Deseas eliminar?',
        TitleJustForYou: 'Solo para ti',
        TitleRewards: 'Recompensas',
        TitleInbursaPoints: 'Puntos Inbursa',
        TitlePromotions: 'Promociones',
        InputLabelAddProgram: 'Número de membresía',
        InputLabelAddTDC: 'Número de tarjeta',
        TitleAddProgram: 'Ingresa el número de membresía',
        ConfirmAddProgram: 'Confirma los datos',
        ConfirmedAddProgram: 'Programa registrado',
        AccumulatedPoints: 'Puntos acumulados:',
        PointsAvailable: 'Puntos disponibles:',
        SelectACard: 'Selecciona una tarjeta',
        PointsToRedeem: 'Puntos a canjear',
        PlaceholderPoints: 'Múltiplos de 1,000',
        MembershipLengthRange: (min: number, max: number) => `La longitud es de ${min} a ${max} caracteres`,
        MembershipLength: (length: number) => `La longitud es de ${length} caracteres`,
        MessageCurrentsPoints: 'No cuentas con puntos suficientes',
        MessageReddemPoints: 'La cantidad debe contener múltiplos de 1000',
        SelectProgram: 'Selecciona un programa',
        MobileSelectProgram: 'Selecciona el programa<br>que quieres agregar para<br>canjear tus puntos',
        TabletSelectProgram: 'Selecciona el programa que quieres<br>agregar para canjear tus puntos',
        DesktopSelectProgram: 'Selecciona el programa que quieres agregar para canjear<br>tus puntos',
    };

    static readonly INBURSA_LOYALTY_POINTS_PROMOTIONS = [
        {
            header: this.HEADER_PROMOTIONS_LOYALTY,
            imgUrl: this.IMAGE_URL_LOYALTY,
            content: this.CONTENT_PROMOTIONS_LOTALTY,
            conditions: this.CONDITIONS
        },
        {
            header: this.HEADER_PROMOTIONS_LOYALTY,
            imgUrl: this.IMAGE_URL_LOYALTY,
            content: this.CONTENT_PROMOTIONS_LOTALTY,
            conditions: this.CONDITIONS
        },
        {
            header: this.HEADER_PROMOTIONS_LOYALTY,
            imgUrl: this.IMAGE_URL_LOYALTY,
            content: this.CONTENT_PROMOTIONS_LOTALTY,
            conditions: this.CONDITIONS
        }
    ];

    static readonly HOME_CHECKS = {
        MyChecks: 'Mis cheques',
        Cancellation: 'Cancelación'
    };

    static readonly MODIFY_INVESTMENT = {
        Application: 'Tu solicitud ha sido registrada, en breve recibirás respuesta de tu trámite.'
    };

    static readonly NULL_TEXT = 'null';
    static readonly UNDEFINED_TEXT = 'undefined';
    static readonly IAVE_TEXT = 'Mínimo 13 caracteres';

    // Manage cards
    static readonly MSG_UPDATE_BLOCKING_AND_LIMITS = 'Bloqueos y/o límites actualizados correctamente';
    static readonly CONTINUE_BUTTON = 'Continuar';
    static readonly REGISTER_BUTTON = 'Registrar';
    static readonly MSG_POPUP_TITLE_CANCEL_CARD = 'Cancelación exitosa';
    static readonly CARD_REPLACEMENT = 'Reposición de tarjeta';
    static readonly MSG_DESCRIPTION_CANCEL_CARD = 'XXXXXXXX';
    static readonly MSG_BRANCH_ADDRESS = 'Cda. Orizaba 7, Roma Nte Cuauhtémoc, 06700 Ciudad de México, CDMX';
    static readonly MSG_BRANCH_TELEPHONE = '551 234 5678';
    static readonly MSG_CANCEL_CARD_TITLE = '¿Estás seguro de que quieres cancelar tu tarjeta?';
    static readonly MSG_CANCEL_CARD_DESCRIPTION = 'Tarjeta de débito (****77544)';
    static readonly MSG_CANCEL_CARD_CONTINUE_BUTTON = 'Sí, estoy seguro';
    static readonly MSG_SUCCESSFUL_PIN_MODIFICATION = 'Se realizó el cambio de NIP correctamente';

    //Nuevos servicios
    static readonly SERVICE_REQUIRED_MSG = 'Servicio es requerido';
    static readonly PHONE_NUMBER_MSG = 'Ingresa número Telefónico a 10 dígitos';
    static readonly PLACEHOLDER_PHONE_MSG = '10 dígitos';
    static readonly COPIED_MSG = (text: string) => `${text} copiado al portapapeles`;

    // General
    static readonly ACCOUNT_NAME = 'Cuenta';
    static readonly AFORE_NAME = 'Afore';

    //Investments
    static readonly LIMIT_DATE_LABEL = 'Fecha de inicio';
    static readonly DUE_DATE_LABEL = 'Fecha de vencimiento';
    static readonly INFORMATION_LABEL = 'Información de la inversión';
    static readonly TO_THE_EXPIRATION = 'Instrucción al vencimiento';
    static readonly RENEWAL_AT_EXPIRATION = 'Renovación al vencimiento';
    static readonly NET_YIELD = 'Rendimiento bruto';
    static readonly INTEREST_RATE = 'Tasa de interés';
    static readonly DAYS_INVESTMENT = 'días';
    static readonly SETTLEMENT_AT_MATURITY = 'Liquidación al vencimiento';
    static readonly BALANCE_ONLY_RENEWAL = 'Renovación solo del saldo';
    static readonly INVESTMENT_MODIFICATION = 'Modificación de inversión';
    static readonly SUCCESSFUL_MODIFICATION = 'Modificación exitosa';
    static readonly SERIAL_NUMBER = 'Número de serie';
    static readonly INCOMPLETE_SERIAL_NUMBER = 'Número de serie token incompleto';

    static readonly MODAL_EXCEPTIONS_POINTS = {
        Service: {
            Header: '¡Ups, algo va mal!',
            Message: 'El servicio no se encuentra disponible, favor de intentarlo más tarde.'
        },
        AllowedPoints: {
            Header: 'Canjear puntos',
            Message: 'Para canjear puntos deben ser cantidades en múltiplos de 1000'
        }
    };
    static readonly MSG_RFC_INVALID = 'RFC inválido';
    static readonly SELECT_OPTION = 'Selecciona una opción';
    static readonly CONFIRMATION_MESSAGE_ACCOUNT_STATUS = 'Cuenta Actualizada.';
    static readonly SERVICES_INPUT_TEXT = 'Número de cuenta, tarjeta o CLABE';
    static readonly TRANSFERS_INPUT_TEXT = 'Número de tarjeta, cuenta o CLABE';

    //payroll credit
    static readonly PAYROLL_CREDIT = {
        ModalTitle: 'Crédito en tu Nómina',
        ModalDetails: 'Selecciona el monto  y periodicidad de pago',
        LabelCreditLine: 'Línea de crédito',
        LabelAmount: 'Monto mensual',
        SmallLetters: '**CAT. Costo Anual Total. Sin IVA. <br/> Para fines informativos y de comparación exclusivamente',
        LabelCard: 'Se depositará en:',
        InvalidAmount: 'Cantidad no válida',
        CreditLimit: 'La cantidad excede su límite de crédito',
        CreditLimitAccount: 1500,
        AmountCredit: 37500,
        HigherThanZero: 'Debe ser una cantidad mayor a 0',
        Title: 'Solicitud crédito de nómina',
        TitleModalComplete: 'Solicitud de Crédito de Nómina'
    };

    static readonly AMOUNT_ERRORS = {
        MaxAmount: 'El monto máximo es $8000.00',
        ZeroAmount: 'El monto debe ser mayor a $0.00',
        HigherAmount: 'El monto debe ser menor al Pago Total',
        GreaterAmount: 'La inversión debe ser mayor o igual a $30,000'
    };

    static readonly PAYMENT_CARD = {
        TitlePaymentsTransfers: 'Pagos y traspasos',
        TitleMovements: 'Movimientos',
        TitleOperations: 'Operaciones',
        TitleInsurance: 'Aportaciones',
        TitleResponsive: 'Operaciones',
        TitleInvestment: 'Fondos de Inversión'
    };

    static readonly VALIDATE_TOKEN_FORM_MESSAGE = '<strong>Nunca solicitaremos datos confidenciales</strong> por email,' +
        'SMS,redes sociales, vía telefónica o en sucursal, <strong>nunca proporciones datos</strong> de tu tarjeta, claves de acceso o código token.';

    static readonly SERVICE_NOT_AVAILABLE = '<p id=delLabel>El servicio no se encuentra disponible, <br/> favor de intentarlo más tarde</p>';
    static readonly REFERENCE = '23459875 ';
    static readonly STYLE_ADD = 'display: flex; justify-content: center; align-items: center;';

    static readonly DateValidation = {
        INVALID_DATE: 'Invalid Date'
    };
    static readonly MAIN_TRANSFER = {
        Reference: 'referencia',
        Numeric: 'numeric',
        TwoDigit: '2-digit',
        AlertsMessage: 'Ahora puedes realizar transferencias internacionales',
        AlertMessage: 'Ingresa con <strong>Facepass</strong> y evita el código token en tus transferencias y pagos de servicios',
        Type: 'tipo',
        Concept: 'concepto',
        Express: 'express',
        Spei: 'SPEI'
    };

    static readonly TRANSFER_COMPLETED = {
        NumberInvoice: 'Número de Folio: 12345678',
        InvoiceNumber: 'Folio: 23459875'
    };

    static readonly MODAL_BRANCHES = {
        Title: 'Sucursales AFORE',
        Subtitle: 'Consulta una sucursal cercana AFORE Inbursa',
        ErrorTitle: 'Error de status',
        ButtonText: 'Listo',
        SearchPlaceholder: 'Buscar por C.P o dirección'
    };

    static readonly CONFIRM_DATA = {
        Policy: 'Póliza',
        NumberMain: '12890378651',
        Receipts: 'Recibos',
        DeductiblePaymentMade: 'pagodeduciblerealizado',
        CompleteInvestment: 'completeInvestment'
    };

    static readonly FORM_MODIFY = {
        Controls: {
            MaximumLimit: 'maximumLimit',
            AliasAgenda: 'aliasAgenda',
            Email: 'email',
            ServiceName: 'serviceName',
            CardNumber: 'cardNumber',
            Badge: 'badge',
            SignaturePanel: 'signaturePanel',
        },
        Errors: {
            ValidAmount: 'validAmount'
        }
    };

    static readonly CHECK_CONSULT = {
        Protected: 'protected',
        Filters: {
            Returned: 'Devuelto',
            Canceled: 'Cancelado',
            Active: 'Activo',
            Liquidated: 'Liquidado'
        }
    };

    static readonly CARD_BRANCHES = {
        ScrollProperty: 'scrollProperty',
        Address: 'Cda. Orizaba 7, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX.',
        Phone: '55 12 34 56 78'
    };

    static readonly ALERTS = {
      LoginAlert: `<strong>Nunca te solicitaremos datos confidenciales</strong> por email,
      SMS, redes sociales, vía telefónica o en sucursal, <strong>nunca proporciones datos</strong> de tu tarjeta, claves de acceso o código token.`,
      PortabilityAlert: 'Mínimo 10 dígitos máximo 18 dígitos'
    };

    static readonly REDEEM_LOYALTY_POINTS = {
        RedeemFormLabels: {
            Form: this.INBURSA_LOYALTY_POINTS.AccumulatedPoints,
            Confirm: this.INBURSA_LOYALTY_POINTS.PointsAvailable
        }
    };

    static readonly ADD_LOYALTY_PROGRAMS = {
        Titles: {
            Form: this.INBURSA_LOYALTY_POINTS.TitleAddProgram,
            Confirmed: this.INBURSA_LOYALTY_POINTS.ConfirmedAddProgram
        }
    };

    static readonly NOTIFICATIONS = {
        MsgNotifications: 'Notificaciones',
        MsgNewNotifications: 'nuevo',
        MsgNotShowScroll: 'auto'
    };

    static readonly SERVICES_CONTACT = {
        HeaderInsurance: 'Seguros',
        HeaderPayrollCredit: 'Crédito de Nómina',
        HeaderPayrollAdvance: 'Adelanto de Nómina'
    };

    static readonly PAYMENT_TYPE = {
        headPay: 'Tipo de pago',
        NextPay: '- Próximo pago',
        CurrentPay: '- Pago Total - Liquidar crédito'
    };

    static readonly NOTIFY_PORTABILITY = {
        Accounts: ['231232132131231123', '231232132131231456', '231232132131231789', '231232132131231799'],
        Folios: ['5728010', '5728011', '5728012'],
        Dates: ['04/05/2023', '02/05/2023', '01/05/2023'],
        Status: ['pending']
    };

    static readonly DATA_PRODUCTS_MODEL = {
        Img: '../../../assets/img/home/card.svg',
        Detail: 'Saldo al día'
    };

    static readonly RECEIVED_DATA_CARD_INFO_MODEL = {
        Card: '1234 5678 9012 3456'
    };

    static readonly MOVEMENTS_MODEL = {
        ConceptSPEI: 'Depósito SPEI',
        ConceptATM: 'Cajero Inbursa'
    };

    static readonly LOCATION_MODAL = {
        Alert: 'Es necesario activar tus permisos de ubicación',
        DetailAlert: 'Por disposición oficial es necesario determinar tu ubicación para ingresar a tu Banca Personal',
        HowDoIDoIt: '¿Cómo lo hago?'
    };
    static readonly LOAD_FILE = {
        HeaderModal: 'Atención'
    };

    static readonly ERROR_BENEFICIARY = 'No se encontraron beneficiarios para esta cuenta.';
    static readonly WITHOUT_MOVEMENTS_MSG = 'No cuentas con movimientos al momento.';
    static readonly SERVICES_PAYMENT_OPTION = 'Pago de Servicio';
    static readonly WITHOUT_NOTIFICATIONS_MSG = 'No cuentas con notificaciones';
    static readonly WITHOUT_CLARIFICATIONS_MSG = 'No cuentas con aclaraciones al momento.';

    static readonly SHARED_BUTTONS = {
        Share: 'Compartir',
        Download: 'Descargar',
        Mail: 'E-mail'
    };

    static readonly LABEL_ADD_PRODUCT = {
      ErrorProduct: 'errorProduct',
      idNumber: 'idNumber',
      idProduct: 'idProduct',
      OTP: 'otp'
    };

    static readonly DEDUCTIBLE_MEDICAL_EXPENSES = {
        MessageIncorrectName: 'Los datos del beneficiario son incorrectos, favor de verficar',
        MessageIncorrectAmountDeductible: 'Importe deducible incorrecto, favor de verficar',
        MessageIncorrectAmountCoinsurance: 'Importe coaseguro incorrecto, favor de verficar',
        MessageIncorrectAmount: 'Total a pagar incorrecto, favor de verficar'
    };

    static readonly ADDITIONAL_CONTRIBUTIONS = {
        Title: 'Aportaciones Adicionales',
        SubTitle: this.PAGE_HEADER.Subtitle,
        EnterAmountLabel: '¿Cuánto quieres aportar?',
        TypeContribution: 'Aportación Adicional',
        Policy: '',
        Info: `Las aportaciones adicionales que realizan los clientes
        a la póliza de vida individual, no garantizan una tasa
        de rendimiento, se envían a un Fondo de inversión,
        que está sujeto a la fluctuación de los mercados y
        tipo de cambio, por lo que el asegurado puede
        obtener ganancias o minusvalías.`,
    Modify: 'modify',
    ParameterOk: 'ok',
    PropertiesBtn: {
      classes: 'btn-generic'
    },
    Confirm: {
      SubTitle: this.INTERNATIONAL_BENEFICIARY.DataConfirmation
    },
    TitleOperation: 'Información de la aportación',
    TextDetail:'A la póliza',
    OperationType:'Tipo de Aportación'
  };

  static readonly INVESTMENT_FUNDS = {
    InvestmentFunds: 'investmentFunds',
    Alert: {
      class: ClassHTML.InvestmentAlert,
      type: 'information',
      iconHeader: Resources.INFO_WHITE,
      message: `La compra inicial mínima es de $100,000.00 y con este depósito puedes invertir
             en cualquiera de los dos Fondos y realizar compras subsecuentes desde 1 título.`,
      icon: Resources.SQUARE_WHITE_CROSS
    },
    FundsSale: {
      AccountDestination: 'Cuenta destino',
      ConfirmProcess: {
        CaseAccount: 'account',
        CaseAddressee: 'addresee',
        LabelDate: 'Fecha'
      },
    },
    PropertiesBtn: {
      classes: 'btn-generic'
    }
  };

  static readonly FILES = {
    Pdf: 'pdf'
  };

  static readonly ENABLE_SLIDE_ADDITIONAL_SERVICES = {
    OtherServicesSlider: 'otherServicesSlide'
  };


  static readonly TRANSACTIONS_DETAILS_LABELS = {
    AccountNumber: this.ACCOUNT_NUMBER,
    TypeOperation: 'Tipo de Operación'
  };

  static readonly PAYMENT_RECEIPT = {
    Authorized: 'Autorizado',
    Validated: 'Validado',
    Pending: 'pending',
    Refused: 'Rechazado',
    RefusedClass: 'refused'
  };

  static readonly GMM_MODAL_HELP = {
    IdBeneficiary: 'beneficiary-data',
    IdClainNumber: 'claimNumber',
    IdDeductible: 'deductible',
    IdAmount: 'amountCoinsurance',
    IdIssuerCode: 'issuerCode',
    LetterCode: 'letterCode',
    LetterNumber: 'letterNumber',
    ClaimNumber: 'Número de siniestro',
    DeductibleToPay: 'Deducible a pagar',
    CoinsuranceAmount: 'Importe coaseguro',
    IssuerCode: 'Código Emisor',
    LetterCodeText: 'Código Carta',
    LetterNumberText: 'Número de carta'
  };

  static readonly FAQS = [
    {
      id:1,
      question: '¿Qué es Facepass?',
      answer: `Facepass es una <strong>tecnología biométrica que valida la identidad de una persona</strong> a partir del análisis de
      las características físicas, únicas e intransferibles del rostro. 👩🏻<br/>
      Un algoritmo transforma esta información en un patrón matemático, y así, ingresas de la forma más segura a tu <strong>app Inbursa Móvil</strong>. 🔐`
    },
    {
      id:2,
      question: '¿Cómo activar, cancelar o desbloquear Facepass?',
      answer: `👉🏻 Puedes <strong>activar</strong> Facepass en máximo dos dispositivos móviles, ya sea smartphone o tablet<br/>
      👉🏻 Si cambias de dispositivo, <strong>cancela</strong> ese registro desde tu Perfil de Cliente en www.bancoinbursa.com<br/>
      💡 Si se bloquea tu acceso con Facepass, sólo ingresa con tus Claves de acceso habituales y Facepass se <strong>desbloqueará</strong> automáticamente.`
    },
    {
      id:3,
      question: '¿Cómo ingreso con Facepass a mi Inbursa en Línea?',
      answer: `Para ingresar con Facepass a Inbursa en Línea personal, sigue estos pasos:<br/>
      💻 En tu equipo de cómputo ingresa a www.bancoinbursa.com<br/>
      👩🏻 Da clic en Ingresa con Facepass<br/>
      🔲 Con tu celular escanea el código QR que se muestra<br/>
      🤳 Te solicitará validar tu Facepass con tu celular<br/>
      🔐 Una vez validado ingresa tu Clave de acceso<br/>
      ¡Listo!`
    },
    {
      id:4,
      question: 'Recomendaciones para ingresar con Facepass',
      answer: `Si tienes problemas para ingresar con Facepass, sigue estas recomendaciones:<br/>
      • No te muevas mientras realizas la validación del Facepass<br/>
      • Sostén tu celular con firmeza, de preferencia con ambas manos<br/>
      • Evita contraluces, es decir, mucha o poca iluminación<br/>
      • Asegúrate que tu rostro se encuentre centrado<br/>
      • Aleja o acerca tu celular según lo recomiende el lector<br/>
      💡 Si se bloquea tu acceso con Facepass, sólo ingresa con tus Claves de acceso habituales y Facepass se <strong>desbloqueará</strong> automáticamente.`
    },
    {
      id:5,
      question: '¿Cómo recupero mi Usuario o Contraseña de la app Inbursa Móvil?',
      answer: `Para recuperar tu Usuario o Contraseña sólo ingresa a tu app Inbursa Móvil y selecciona la opción <strong>¿Olvidaste tus claves de acceso?</strong>
      🔐 Ahí podrás restablecerlas.`
    },
    {
      id:6,
      question: '¿Cómo activo mi Token en Inbursa Móvil si cambié de dispositivo?',
      answer: `Para activar tu Token Móvil en otro dispositivo:<br/>
      • Ingresa con tu Usuario y Contraseña habituales<br/>
      • Realiza el proceso para activar el Token Móvil<br/>
      💡 También puedes activarlo sustituyendo el del dispositivo anterior.`
    },
    {
      id:7,
      question: '¿Cómo me registro en Inbursa Móvil?',
      answer: `Para registrarte en tu <strong>app Inbursa Móvil</strong> sólo necesitas:<br/>
      💳 Tener a la mano tu tarjeta de débito o crédito Inbursa <strong>activa</strong><br/>
      ✍🏻 Tus datos personales y de contacto deben estar <strong>actualizados</strong><br/>
      🔢 Tu <strong>NIP</strong> (4 dígitos) que utilizas en cajeros automáticos<br/>
      📱 <strong>¡Descargar tu app Inbursa Móvil!</strong> https://onelink.to/inbursamovil<br/>
      💡 Consulta el <strong>Manual de registro</strong> 👉🏻 https://bit.ly/manual-inbursamovil`
    },
    {
      id:8,
      question: '¿Puedo bloquear mi Tarjeta por tipo de operación?',
      answer: `🔐 Por seguridad, puedes bloquear tu tarjeta por tipo de operación.<br/>
      Activa o desactiva el bloqueo cada vez que lo requieras desde tu <strong>app Inbursa Móvil</strong>:<br/>
      • Inicia sesión<br/>
      • Elige la tarjeta<br/>
      • Selecciona Bloqueos y límites<br/>
      Tipos de operación:<br/>
      📦 Compras en línea y telefónicas<br/>
      🛒 Compras en comercios<br/>
      🏧 Cajeros automáticos<br/>
      🌍 Compras y retiros en el extranjero<br/>
      📱 Si aún no tienes tu app Inbursa Móvil, descárgala ahora 👉🏻 http://onelink.to/inbursamovil`
    },
    {
      id:9,
      question: '¿Qué servicio puedo pagar en Inbursa Móvil?',
      answer: `Paga tus servicios desde tu <strong>app Inbursa Móvil</strong>:<br/>
      • Tarjetas de crédito Inbursa<br/>
      • Tarjetas de crédito de otros bancos<br/>
      • Tarjetas departamentales<br/>
      • Pase Urbano (Tag)<br/>
      • Servicios (luz, gas, telefonía, televisión de paga)<br/>
      • Impuestos CDMX<br/>
      💡 Considera que debes tener una cuenta Inbursa.`
    },
    {
      id:10,
      question: '¿Cuál es y que uso tiene la CLABE Interbancaria?',
      answer: `La <strong>CLABE</strong> es un número único con el que es posible identificar tu cuenta o tarjeta de crédito.<br/>
      Con esta cadena de 18 dígitos puedes recibir transferencias interbancarias (SPEI o TEF), o pagos a tu tarjeta de crédito.<br/>
      Consulta tu CLABE en:<br/>
      • Tu app Inbursa Móvil, si aún no la tienes descárgala 👉🏻 https://onelink.to/inbursamovil<br/>
      • Banca en Línea personal www.bancoinbursa.com<br/>
      • Estado de cuenta<br/>
      • CAT 55 5447 8000 | 800 90 90000<br/>
      • Sucursal Inbursa`
    },
    {
      id:11,
      question: '¿Cómo puedo consultar mis Estado de Cuenta en la app?',
      answer: `Consulta, descarga y envía tus Estados de cuenta desde tu <strong>app Inbursa Móvil</strong>:<br/>
      • Ingresa a 'Movimientos' de la tarjeta<br/>
      • En la opción 'Estado de cuenta' elige el periodo que deseas consultar<br/>
      Desde la opción <strong>Mis tarjetas</strong><br/>
      • Selecciona la tarjeta de la que deseas consultar el Estado de Cuenta<br/>
      • En el botón 'Estado de cuenta' elige el periodo que quieras consultar`
    },
    {
      id:12,
      question: '¿Cómo reporto el robo o extravío de mi tarjeta?',
      answer: `Solicita la cancelación de tu Tarjeta por robo o extravío en:<br/>
      📱 App Inbursa Móvil 👉🏻 http://onelink.to/inbursamovil
      Ingresa y elige la tarjeta, selecciona la opción <strong>Cancelar tarjeta</strong><br/>
      📞 CAT 55 5447 8000 | 800 90 90000, opción 2, reportar robo o extravío<br/>
      📍 Tu sucursal Inbursa de preferencia, consulta horarios y ubicaciones en 👉🏻 http://bit.ly/Inbursa-cerca-de-ti`
    },
    {
      id:13,
      question: 'Tips de seguridad Inbursa Móvil',
      answer: `Administra la seguridad de tu tarjeta con tu <strong>app Inbursa Móvil</strong><br/>
      Te dejamos algunas recomendaciones para tus compras en línea<br/>
      Activa tus <strong>Compras en línea</strong> sólo cuando lo necesites<br/>
      Modifica el <strong>monto máximo diario</strong> en función a la compra que vas a realizar<br/>
      Utiliza un <strong>CVV dinámico</strong> que será válido sólo para tu compra<br/>
      💡 Conoce más <strong>Tips de Seguridad</strong> 👉🏻 https://bit.ly/tips-inbursamovil`
    },
    {
     id:14,
     question: '¿Dónde puedo pagar la póliza de mi seguro?',
      answer: `Realiza el pago de tu póliza de tu seguro en:<br/>
      📱 App Inbursa Móvil 👉🏻 http://onelink.to/inbursamovil<br/>
      👩🏻‍💻 En línea con cargo a la tarjeta bancaria que prefieras 👉🏻 https://bit.ly/pago-poliza<br/>
      💳 Cajas de tiendas y clubes de Grupo Walmart (pagos en efectivo o con tarjeta de débito para recibos vigentes de hasta $50,000 M.N.)<br/>
      📞 CAT 55 5447 8000 | 800 90 90000 con cargo a tarjeta de crédito o débito<br/>
      📍 Tu sucursal Inbursa de preferencia, consulta horarios y ubicaciones en 👉🏻 http://bit.ly/Inbursa-cerca-de-ti<br/>
      📍 Otros bancos: Banamex (PA:373101), Banorte (81752), HSBC (RAP:1670), Bancomer (CIE 939706)<br/>
      💡 Para tu pago ten a la mano la carátula de tu póliza.`
    },
    {
      id:15,
      question: '¿Cómo ingreso una Aclaración?',
      answer: `Solicita una <strong>Aclaración</strong>:<br/>
      📱  En tu app Inbursa Móvil ingresa a movimientos de tu tarjeta de crédito y consulta el detalle del que requieres aclaración y elige la opción Alta de Aclaración<br/>
      💻 Desde tu Banca en Línea personal despliega el menú Otras Operaciones, elige Aclaraciones y la opción Alta de Aclaración<br/>
      📞 CAT 55 5447 8000 | 800 90 90000, opción 9, Quejas y aclaraciones<br/>
      📍 Tu sucursal Inbursa de preferencia, consulta horarios y ubicaciones en 👉🏻 http://bit.ly/Inbursa-cerca-de-ti`
    },
    {
      id:16,
      question: '¿Dónde puedo ingresar una queja o sugerencia?',
      answer: `Tus comentarios son muy valiosos para nosotros, ponemos los siguientes medios para ingresar tus Dudas o Sugerencias:<br/>
      🤳 Redes sociales oficiales y verificadas vía Inbox o DM.<br/>
      Facebook 👉🏻 https://www.facebook.com/InbursaGfi/<br/>
      Twitter 👉🏻 https://twitter.com/BancoInbursa<br/>
      👩🏻💻 Nuestro sitio web: https://bit.ly/dudas-sugerencias<br/>
      📱 En tu app Inbursa Móvil en el menú inferior elige Contacto y la opción Quejas y sugerencias 👉🏻 https://onelink.to/inbursamovil<br/>
      📞 CAT 55 5447 8000 | 800 90 90000, opción 9 Quejas y Aclaraciones<br/>
      📍 Tu sucursal Inbursa de preferencia, consulta horarios y ubicaciones en 👉🏻 http://bit.ly/Inbursa-cerca-de-ti
      `
    },
    {
      id:17,
      question: '¿Cómo cambiar el NIP de mi tarjeta Inbursa?',
      answer: `Registra tu NIP o actualízalo<br/>
      📱 Tu app Inbursa Móvil 👉🏻 https://onelink.to/inbursamovil<br/>
      💻 Banca en Línea personal www.bancoinbursa.com<br/>
      💡 Tu tarjeta y token deben estar activos. Si no tienes token acude a tu sucursal Inbursa con una identificación oficial vigente y tu tarjeta.`
    },
    {
      id:18,
      question: '¿Cuál es el monto máximo de retiro en cajeros automáticos?',
      answer: `Retira efectivo y consulta tu saldo sin comisión en cajeros HSBC, Scotiabank, BanBajío, BanRegio, Mifel, Inbursa y Afirme.<br/>
      Si realizas el retiro en ATM Red considera cobro de comisión.<br/>
      💡 Monto máximo por operación y día es de $7,000, amplía el límite en tu app Inbursa Móvil https://onelink.to/inbursamovil  `
    },
    {
      id:19,
      question: '¿En cuánto tiempo se aplica el pago que realicé?',
      answer: `Para que el pago de tu tarjeta Inbursa se refleje de manera inmediata, realízalo en tu app Inbursa Móvil, Banca en Línea personal,
      cajeros automáticos, sucursales o cajas de tiendas y clubes de Grupo Walmart. 💳<br/>
      Al hacer tu pago en otros establecimientos o bancos, este se reflejará posteriormente,
      pero se conservará la fecha en que lo realizaste, del mismo modo, al realizar el pago de tarjetas de crédito de otros bancos, departamentales o servicios.`
    },
    {
      id:20,
      question: '¿Cómo solicitar una Tarjeta de Crédito adicional?',
      answer: `Solicita una tarjeta de crédito adicional, sólo acude a cualquiera de nuestras sucursales con la persona que designarás, considera llevar:<br/>
      Identificación oficial vigente de ambos<br/>
      Comprobante de domicilio vigente del titular<br/>
      💡 Te recomendamos llevar un recibo de telefonía fija o móvil en plan tarifario<br/>
      📍 Tu sucursal Inbursa de preferencia, consulta horarios y ubicaciones en 👉🏻 http://bit.ly/Inbursa-cerca-de-ti`
    },
    {
      id:21,
      question: '¿Cómo puedo usar los puntos recompensa de mi Tarjeta de Crédito?',
      answer: `Con el programa <strong>Recompensas Inbursa</strong> recibes un Punto Inbursa por cada dólar facturado con tu Tarjeta de Crédito.<br/>
      Canjea tus puntos por lo que prefieras:<br/>
      💵 <strong>Efectivo</strong>. Un peso por cada diez puntos Inbursa, el abono se realiza a tu tarjeta<br/>
      ⭐ <strong>Puntos Premier</strong>. Un Punto Premier por cada Punto Inbursa, los puedes usar para comprar boletos de avión,
      artículos de tienda en línea o participar en la subasta Premier<br/>
      ✈ <strong>Millas</strong>. Cada Punto Inbursa equivale a un Punto en el Programa de Lealtad que prefieras:
      Aeroplan, Flying Blue, MilleMiglia Program, Avianca LifeMiles, British Airways Executive Club, ConnectMiles, Etihad Guest, Iberia Plus y LATAM Pass<br/>
      🌜<strong>Noches de hotel</strong>. En las cadenas InterContinental Hotels y Accor Hotels<br/>
      💡 Conoce más en la sección Beneficios de tu Tarjeta de Crédito 👉🏻 http://bit.ly/inbursa-clasica`
    },
    {
      id:22,
      question: '¿Cómo consultar el saldo de mi tarjeta Inburvale de despensa?',
      answer: `Consulta el saldo de tu tarjeta <strong>Inburvale</strong> en:<br/>
      📱 Tu app Inbursa Móvil en el menú inicial<br/>
      💻 Banca en Línea personal si tienes una cuenta Inbursa<br/>
      🏧 Cajeros Inbursa, sólo necesitas el NIP de tu tarjeta Inburvale<br/>
      📞 CAT 55 5447 8000 | 800 90 90000 opción 6 vales de despensa`
    },
    {
      id:23,
      question: '¿Cómo actualizo mi número de teléfono?',
      answer: `Actualiza tu número telefónico en tu sucursal Inbursa, sólo necesitas:<br/>
      Identificación oficial vigente<br/>
      Comprobante de domicilio con antigüedad no mayor a tres meses<br/>
      💡 Te recomendamos llevar un recibo de telefonía fija o móvil en plan tarifario<br/>
      📍 Tu sucursal Inbursa de preferencia, consulta horarios y ubicaciones en 👉🏻 http://bit.ly/Inbursa-cerca-de-ti`
    },
    {
      id:24,
      question: '¿Cómo puedo solicitar el Retiro por Desempleo de mi Afore Inbursa?',
      answer: `Solicita el Retiro por Desempleo de tu Afore, consulta características y requisitos:<br/>
      Ingresa 👉🏻 https://bit.ly/afore-tramites<br/>
      Elige el Instituto al que perteneces: IMSS o ISSSTE<br/>
      Selecciona la opción Retiro Parcial (por desempleo)<br/>
      📞 CAT 55 5447 8000 | 800 90 90000 opción 4, Afore`
    },
    {
      id:25,
      question: '¿Cómo puedo consultar y descargar mi estado de cuenta de Afore?',
      answer: `Solicita tu estado de cuenta a través de tu app AforeMóvil Inbursa<br/>
      Elige Mi Cuenta<br/>
      Solicitud de documentos<br/>
      Estado de cuenta<br/>
      Tu último estado de cuenta cuatrimestral será enviado a tu correo electrónico.<br/>
      📞 CAT 55 5447 8000 | 800 90 90000 opción 4, Afore`
    },
    {
      id:26,
      question: '¿Cómo y dónde puedo realizar Ahorro Voluntario?',
      answer: `Con tu app AforeMóvil Inbursa y AforeWeb ahorra:<br/>
      💻 En Línea<br/>
      🤓 Programa transferencias automáticas a tu Afore desde tu tarjeta de débito o crédito, tú determinas monto y periodicidad.<br/>
      Necesitarás estos datos:<br/>
      💳 Número de tarjeta o cuenta CLABE<br/>
      🔒 Código de valor de verificación (CVV, son tres dígitos)<br/>
      📅 Fecha de vencimiento de la tarjeta (MM/AA)<br/>
      💵 Efectivo<br/>
       Sin cobro de comisión realiza tu depósito en más de 15,000 tiendas como 7-Eleven, Circle K, Extra, Farmacias del Ahorro, Superama,
       Walmart, Bodega Aurrera, Sams Club, Red de la Gente, Yastás, Chedraui, Punto Recarga, sucursales Telecomm y Banco del Bienestar a nivel nacional.<br/>
      📍 Proporciona tu CURP o número telefónico.<br/>
      💡 Considera que te deberán entregar un recibo para comprobar tu depósito.`
    },
    {
      id:27,
      question: '¿En dónde puedo consultar mi saldo de Afore? ',
      answer: `🧾 Consulta el saldo de tu Afore<br/>
      📲 Ingresa a tu app AforeMóvil Inbursa y selecciona en la pantalla principal "Mi Ahorro"<br/>
      📍 En nuestros cajeros INBURSA, elige Otros servicios Afore e ingresa tu NSS y código postal<br/>
      📞 CAT 55 5447 8000 | 800 90 90000 opción 4, Afore`
    },
    {
      id:28,
      question: '¿En dónde puedo pagar mi Tarjeta de Crédito Inbursa?',
      answer: `💳 Paga tu tarjeta de crédito en:<br/>
      Si tienes una cuenta Inbursa ingresa a:<br/>
      📱 Tu app Inbursa Móvil<br/>
      💻 Tu Banca en Línea personal<br/>
      En efectivo:<br/>
      🏧 Cajeros Inbursa<br/>
      📍 Sucursales Inbursa<br/>
      🛒 Establecimientos en convenio: Sanborns, Sears, Telcel, Oxxo, Telecomm, tiendas y clubes Walmart http://bit.ly/corresponsales-Inbursa<br/>
      💡 Te recomendamos hacer el pago para no generar intereses o el pago mínimo + Meses sin intereses, antes de la fecha límite de pago, así cuidas
      tu historial crediticio y evitas gastos por cobranza.`
    },
    {
      id:29,
      question: '¿Cuál es el horario de atención en las sucursales?',
      answer: `Estos son nuestros horarios de atención en sucursales:<br/>
      🏦 <strong>Sucursales convencionales</strong><br/>
      Lunes a viernes de 8:30 a 17:30 hrs. Excepto días festivos.<br/>
      🦉 <strong>Sucursales Sanborns</strong><br/>
      Lunes a viernes de 8:30 a 17:30 hrs y sábados de 9:00 a 15:00 hrs. Excepto días festivos.<br/>
      🛒 <strong>Sucursales Walmart</strong><br/>
      Lunes a domingo de 11:30 a 19:30 hrs. Abierto en días festivos.<br/>
      📍 Consulta ubicaciones en 👉🏻 http://bit.ly/Inbursa-cerca-de-ti`
    },
    {
      id:30,
      question: 'Tengo un adeudo pendiente, ¿cómo puedo pagarlo?',
      answer: `Si tienes un adeudo pendiente consulta tu saldo y conoce las opciones de pago llamando a nuestro CAT 📞 55 5345 9902 | 800 640 5060<br/>
      💡 Mantener y mejorar tu historial crediticio te permitirá acceder a diferentes productos financieros.`
    },
    {
      id:31,
      question: '¿Cómo puedo cancelar definitivamente una Tarjeta de Crédito Inbursa?',
      answer: `Para cancelar la línea de crédito de tu tarjeta de crédito llama a nuestro CAT 📞 55 5447 8000 | 800 90 90000<br/>
      💡 Tu tarjeta no debe tener ningún saldo pendiente, deudor ni acreedor.`
    },
    {
      id:32,
      question: '¿En dónde puedo realizar depósitos y retiros de mi cuenta o tarjeta?',
      answer: `Realiza retiros o depósitos a tus cuentas y tarjetas Inbursa en:<br/>
      🏧 Cajeros automáticos Inbursa, así como en ATM HSBC, Scotiabank, BanBajío, BanRegio, Mifel y Afirme. Scotiabank, BanBajío,
      Banregio y Afirme. Consulta ubicaciones 👉🏻 http://bit.ly/Inbursa-cerca-de-ti<br/>
      📍 Tu sucursal Inbursa de preferencia, consulta horarios y ubicaciones en 👉🏻 http://bit.ly/Inbursa-cerca-de-ti<br/>
      🛒 Establecimientos en convenio: Sanborns, Sears, Telcel, Oxxo, Telecomm, tiendas y clubes Walmart. Consulta límites, montos
      y horarios 👉🏻 http://bit.ly/corresponsales-Inbursa`
    },
    {
      id:33,
      question: '¿Cuál es el horario para realizar traspasos a otros bancos (SPEI)?',
      answer: `Realiza traspasos a otros bancos hasta por $8,000 pesos las 24 horas, los 365 días del año.<br/>
      👉🏻 Si el monto es mayor a $8,000, puedes realizarlo de 6:00 a 17:30 hrs. de lunes a viernes, excepto días festivos.`
    },
    {
      id:34,
      question: '¿Cómo contrato una cuenta Inbursa?',
      answer: `Conoce los beneficios y requisitos de las cuentas Inbursa:<br/>
      <strong>Cuenta efe</strong><br/>
      - Atractivos rendimientos<br/>
      - Contratación a partir de $15,000<br/>
      - Si domicilias un producto Inbursa y mantienes $20,000 de saldo promedio mensual no se genera comisión por manejo de cuenta<br/>
      - Conócela 👉🏻 http://bit.ly/cuenta-efe<br/>
      <strong>Cuenta con Walmart</strong><br/>
      - Te reembolsa el 3% de tus compras en tiendas y clubes de Grupo Walmart<br/>
      - La puedes contratar a partir de $1,000<br/>
      - Si domicilias un producto Inbursa la comisión por manejo de cuenta es de $52 mensuales<br/>
      - Conócela 👉🏻 http://bit.ly/cuenta-con-walmart<br/>
      📊 Realiza una simulación de rendimientos de cada cuenta en la sección ¿Cuánto podría ganar?<br/>
      Para solicitar:<br/>
      📍 Visita la sucursal Inbursa que prefieras.<br/>
      👩🏻‍💼 Escribe <strong>Asesor</strong> para enlazarte con un Asesor Financiero Inbursa.`
    },
    {
      id:35,
      question: 'Quiero solicitar una Tarjeta de Crédito Inbursa',
      answer: `Solicita una de nuestras tarjetas de crédito 🤩💳<br/>
      Sólo necesitas:<br/>
     • Buen historial crediticio<br/>
     • Identificación oficial vigente<br/>
     • Comprobante de domicilio<br/>
     • Antigüedad laboral mínima de un año<br/>
     Conoce algunas de nuestras <strong>tarjetas de crédito</strong>:<br/>
     • Inbursa Clásica 👉🏽 http://bit.ly/inbursa-clasica<br/>
     • Walmart Inbursa 👉🏽 http://bit.ly/inbursa-walmart<br/>
     <strong>Solicita tu tarjeta</strong>:<br/>
     👩🏻💼 Con un Asesor Financiero http://bit.ly/contacta-asesor<br/>
     📍 Tu sucursal Inbursa de preferencia, consulta horarios y ubicaciones en 👉🏻 http://bit.ly/Inbursa-cerca-de-ti`
    },
    {
      id:36,
      question: '¿Cómo solicito un incremento en la línea de crédito de mi tarjeta?',
      answer: `Solicita un incremento en tu línea de crédito si tienes al menos un año desde la fecha de activación de tu tarjeta,
      sólo acude a la sucursal que prefieras con estos documentos:<br/>
      🪪 Identificación oficial vigente<br/>
      💳 Tarjeta de Crédito<br/>
      📍 Tu sucursal Inbursa de preferencia, consulta horarios y ubicaciones en 👉🏻 http://bit.ly/Inbursa-cerca-de-ti `
    },
    {
      id:37,
      question: '¿Dónde puedo consultar mi número de cliente?',
      answer: `Consulta tu <strong>Número de cliente</strong> en:<br/>
      • Tu estado de cuenta<br/>
      • Sucursales Inbursa<br/>
      • App Inbursa Móvil / Inbursa en Línea personal<br/>
      • Contrato / Carta de Bienvenida<br/>
      • Póliza de seguro<br/>
      • CAT 55 5447 8000 | 800 90 90000`
    },
    {
      id:38,
      question: '¿La tarjeta Inbursa Óptima es de crédito o débito? ',
      answer: `Es una tarjeta de crédito respaldada por <strong>American Express</strong>. Te brinda la flexibilidad de<br/>
      realizar compras a nivel nacional e internacional y la puedes utilizar en establecimientos o
      realizar compras en línea. 💳💰`
    },
    {
      id:39,
      question: '¿Cuál es la tasa de interés de la tarjeta Inbursa Óptima? ',
      answer: `La tasa de interés es fija y se personaliza según el perfil de cada cliente.
      Esta tasa puede variar y va desde el 23% al 39%. Nuestro objetivo es ofrecerte una tasa competitiva que se ajuste a tus necesidades y circunstancias individuales. 💯💼`
    },
    {
      id:40,
      question: '¿Cuál es el límite de crédito de la tarjeta Inbursa Óptima? ',
      answer: `El límite de crédito se asigna de manera personalizada, teniendo en cuenta el perfil de
      cada cliente. Esto significa que el límite de crédito puede variar y se determina en función
      de varios factores, como tus ingresos, historial crediticio y capacidad de pago. Nuestro
      objetivo es ofrecerte un límite de crédito que se ajuste a tus necesidades y te permita
      disfrutar de la flexibilidad financiera que deseas. 💯💸`
    },
    {
      id:41,
      question: '¿La tarjeta Inbursa Óptima se puede utilizar en terminales Visa y Mastercard, o solamente en terminales que acepten tarjetas American Express?',
      answer: `Se puede utilizar en los establecimientos de toda la red de <strong>American Express</strong> a nivel
      nacional e internacional. Tendrás la comodidad y conveniencia de utilizar tu tarjeta en una
      amplia variedad de establecimientos en todo el mundo. 🌍🏪💳`
    },
    {
      id:42,
      question: 'Si tengo la tarjeta Inbursa Black American Express, ¿Puedo solicitar la tarjeta Inbursa Óptima?',
      answer: `Sí, puedes tener ambas tarjetas y disfrutar de los beneficios y características distintivas de
      cada una. Esto te brinda mayor flexibilidad y opciones para adaptar tus necesidades
      financieras a diferentes situaciones. 💪🎉💳`
    },
    {
      id:43,
      question: 'Si tengo una tarjeta de crédito Inbursa Clásica, Oro o Platino, ¿Es posible que pueda obtener también la tarjeta Inbursa Óptima?',
      answer: `Sí, la tarjeta Inbursa Óptima ofrece beneficios adicionales y características que complementarán tu experiencia financiera.
      Puedes disfrutar de los beneficios de tus tarjetas simultáneamente, adaptando tus opciones según tus necesidades y preferencias. 😊. `
    },
    {
      id:44,
      question: '¿El check up que ofrece la tarjeta Inbursa Óptima tiene costo?',
      answer: `Como cliente titular de la tarjeta Inbursa Óptima, tienes derecho a un check up básico que incluye QS6, BH y EGO sin ningún costo adicional.
      Consulta más información en https://bit.ly/MedicallHomeOptima 👨‍⚕️`
    },
    {
      id:45,
      question: '¿Qué significan las siglas QS6, BH, EGO?',
      answer: `Corresponden a diferentes pruebas médicas:<br/>
      <strong>QS6</strong>: Química sanguínea de 6 elementos. Es un análisis de laboratorio que evalúa diversos elementos
      presentes en la sangre, como glucosa, colesterol, triglicéridos, entre otros. 💉<br/>
      <strong>BH</strong>: Biométrica hemática. Es un estudio que analiza los componentes de la sangre, como los glóbulos rojos, glóbulos blancos y plaquetas. 🩸<br/>
      <strong>EGO</strong>: Examen general de orina. Es un análisis que evalúa diferentes aspectos de la orina, como la presencia de
      proteínas, glucosa, células sanguíneas, entre otros. 🌊<br/>
      Estas pruebas forman parte del check up básico sin costo al que tienes derecho como cliente titular de la tarjeta <strong>Inbursa Óptima</strong>. 👍
      `
    },
    {
      id:46,
      question: '¿La tarjeta Inbursa Óptima incluye la membresía Medicall Home o tiene algún costo adicional?',
      answer: `Como titular de la tarjeta de crédito Inbursa Óptima, tienes acceso a servicios sin costo administrados por Medicall Home para ti y tus beneficiarios.
      Para poder disfrutar de estos beneficios es necesario que te registres en la app de Medicall Home, descárgala utilizando el QR proporcionado. Para obtener más
      información sobre los servicios incluidos, consulta https://bit.ly/MedicallHomeOptima 🏥`
    },
    {
      id:47,
      question: '¿Cómo funciona y cuál es el proceso para utilizar la asistencia médica?',
      answer: `La <strong>asistencia médica</strong> está disponible para el titular de la tarjeta Inbursa Óptima y hasta 4 beneficiarios, que incluyen al cónyuge, hijos
      y dependientes económicos de hasta 25 años.<br/>El primer paso para acceder a este beneficio es registrarse descargando la app de Medicall Home utilizando el código
      QR proporcionado. Una vez registrado, podrás utilizar los servicios de asistencia médica incluidos en tu membresía. Consulta más información en
      https://bit.ly/MedicallHomeOptima 🩺📱`
    },
    {
      id:48,
      question: '¿Cómo funciona la devolución del 20% de los intereses de la tarjeta Inbursa Óptima?',
      answer: `Es un <strong>beneficio exclusivo de tu tarjeta Inbursa Óptima</strong> y aplica si realizaste el pago de tu tarjeta puntualmente, mínimo durante 3 meses
      consecutivos. Al cumplir con este requisito, podrás recibir una devolución equivalente al 20% de:<br/>
      ● Los <strong>intereses ordinarios</strong> pagados durante los 3 meses del período. 💰💸<br/>
      El abono de la devolución de los intereses <strong>se reflejará en el estado de cuenta del siguiente mes</strong>. Es importante tener en cuenta que, una vez
      recibida la devolución, se iniciará un nuevo período de 3 meses para volver a disfrutar de este beneficio. 🔁<br/>
      Considera que, en caso de atraso en el pago de la tarjeta, el período de 3 meses se reiniciará. ⏰⚠️`
    },
    {
      id:49,
      question: '¿Existen comisiones asociadas a la tarjeta de crédito Inbursa Óptima?',
      answer: `Sí, la tarjeta tiene las siguientes comisiones:<br/>
      ● Anualidad: $650 más IVA, la cual se realizará en 3 cobros mensuales. 💳💲<br/>
      ● Disposición de crédito en efectivo: 6% sobre el monto de la disposición por evento a través de sucursales de Banco Inbursa, cajeros automáticos de Inbursa,
      cajeros automáticos en el extranjero o de otra institución. 💸🏧💵<br/>
      ● Gastos de cobranza: $399.00 M.N. por evento. 💼❗️<br/>
      Emisión de estado de cuenta adicional y/o impresión de movimientos en sucursal: $30.00 M.N. 📋💸`
    },
    {
      id:50,
      question: '¿Cuáles son los servicios de asistencia que ofrece la tarjeta Inbursa Óptima?',
      answer: `● <strong>Atención al cliente las 24 horas</strong>: Puedes comunicarte las 24 horas del día, los 365 días del año a nuestro Centro de Atención
      Telefónica Inbursa al 55 5447 8000 | 800 90 90000. ☎️📞<br/>
      ● <strong>Correo electrónico y redes sociales</strong>: Estaremos encantados de atender tus consultas y brindarte la ayuda que necesites. 📧🌐<br/>
      ● <strong>App Inbursa Móvil</strong>: Para tu comodidad, puedes consultar tu saldo, revisar movimientos y acceder a información relevante sobre tu tarjeta
      de crédito sin costo. 📱💳<br/>
      ● <strong>Tecnología contactless</strong>: Te permite realizar compras sin contacto en establecimientos habilitados. Solo necesitas acercar tu tarjeta al
      terminal de pago para completar la transacción de forma rápida y segura. 💳🚫🤝<br/>
      ● <strong>Alianza MULTIRED</strong>: En colaboración con HSBC, Scotiabank, BanBajío, Banregio y Banca Mifel, te brindamos acceso a más de 9,000
      cajeros automáticos en todo el país, donde puedes realizar retiros de efectivo y consultar tu saldo sin ninguna comisión adicional. 🏧🔁🌍`
    },
    {
      id:51,
      question: '¿Qué beneficios adicionales ofrece la tarjeta de crédito Inbursa Óptima?',
      answer: `Además de sus características principales, te ofrece una serie de beneficios adicionales para hacer tu experiencia aún más gratificante:<br/>
      ● Campañas de meses sin intereses: Podrás disfrutar de promociones especiales con opciones a meses sin intereses. 💳🎁<br/>
      <strong>Beneficios en Viajes Amex</strong>:<br/>
      ● Loungebuddy: A través de este servicio digital, podrás descubrir, reservar y acceder a salones VIP
      en aeropuertos de todo el mundo. Disfruta de más de 170 salones VIP en 58 países, con un costo a partir de $25 USD. ✈️🍾<br/>
      ● Booking.com: Como tarjetahabiente, obtendrás hasta un 30% de descuento en reservas seleccionadas en más de 400,000 listados a través de la
      Plataforma de Beneficios. 🏨💰<br/>
      ● Renta de Autos: Accede a beneficios exclusivos con nuestros socios de renta de autos.<br/>
      ● Sixt Rent a Car: Disfruta de acceso de cortesía al programa de fidelidad SIXT renta car, con beneficios exclusivos, descuentos y ascensos de categoría. 🚗🌟<br/>
      ● Hertz: Obtén un 10% de descuento y un proceso de check-out más rápido. 🚘💨<br/>
      ● Avis: Disfruta de un 15% de descuento y un upgrade de voucher. 🚙🔝<br/>
      ● Rentalcars.com: Obtén un 10% de descuento al utilizar el código promoamex10. 🚗💸<br/>
      <strong>Beneficios Experiencias y Ofertas Amex</strong>:<br/>
      ● #AMEXFORFOODIES: Accede a experiencias culinarias de autor de la mano de chefs reconocidos a nivel mundial. Obtén hasta un 20% de descuento en la cuenta total
      o un aperitivo gratis en restaurantes participantes. 🍽️🍷<br/>
      ● #AMEXGLOBALDESIGNERS: Accede a las últimas tendencias en moda y disfruta de beneficios exclusivos con los mejores diseñadores del mundo. 👗🌍<br/>
      ● PREVENTAS – ENTRETENIMIENTO: Accede de manera anticipada a entradas para conciertos, festivales, eventos deportivos, espectáculos y más en las principales ciudades
      alrededor del mundo. 🎵🎉<br/>
      ● Hole19: Accede a una comunidad de golfistas con la aplicación móvil más completa para golfistas de todos los niveles. Obtén una suscripción
      de un año a la aplicación de funciones premium de Hole19. ⛳📱`
    },
    {
      id:52,
      question: '¿La tarjeta Inbursa Óptima ofrece seguros?',
      answer: `Sí, te brinda protección adicional ante las siguientes eventualidades:<br/>
      ● Accidente en viajes: Cobertura de hasta $250,000 USD. 🚑✈️<br/>
      ● Renta de autos: Cobertura de hasta $50,000 USD en Estados Unidos y Canadá al rentar un automóvil. 🚗🛡️<br/>
      ● Protección de compra: Reembolso de hasta $500 USD por evento o $2,500 USD por año en caso de robo o daño accidental de artículos comprados con la tarjeta. 💳🛍️<br/>
      ● Garantía extendida: Extensión de la garantía original del fabricante hasta $5,000 USD por evento o por año en artículos comprados con la tarjeta. 🔒🛠️<br/>
      ● Protección de precios: Reembolso de la diferencia de precio de hasta $250 USD por evento o $1,000 USD por año si encuentras un precio más bajo en un artículo
      adquirido con la tarjeta. 💰📉<br/>
      ● Seguro de liberación de saldos por fallecimiento. ⚖️📃<br/>
      ● Muerte accidental: Cobertura de $100,000 MXN en caso de fallecimiento accidental. ☠️💔<br/>
      ● Gastos funerarios por accidente: Cobertura de $30,000 MXN. ⚰️💐<br/>
      ● Pérdida de miembros escala "B": Cobertura de $30,000 MXN. 🤕💼<br/>
      Consulta más información en https://bit.ly/tdc-optima.`
    },
    {
      id:53,
      question: '¿La tarjeta Inbursa Óptima ofrece descuentos en establecimientos?',
      answer: `Sí, puedes disfrutar de descuentos especiales en una amplia variedad de establecimientos. A través de la membresía TDConsentido, tendrás acceso a
      descuentos en más de 5,400 establecimientos en diferentes categorías, entre las que se incluyen:<br/>
      🍽️ Restaurantes<br/>
      💪 Fitness<br/>
      🎉 Entretenimiento<br/>
      🏬 Departamentales<br/>
      Consulta más información en https://bit.ly/tdc-optima. 💳💰`
    },
    {
      id:54,
      question: '¿En cuánto tiempo recibiré mi tarjeta de crédito Inbursa Óptima?',
      answer: `Una vez que tu solicitud de tarjeta de crédito sea aprobada, el tiempo estimado para recibir la tarjeta en la sucursal seleccionada es de aproximadamente
      5 días hábiles. Sin embargo, ten en cuenta que este tiempo puede variar dependiendo de la ubicación geográfica y la disponibilidad de los servicios de entrega en
      tu área. ⏳📬`
    },
    {
      id:55,
      question: '¿La tarjeta Inbursa Óptima es segura?',
      answer: `Sí, tenemos servicios a tu disposición para garantizar la seguridad de tu tarjeta:<br/>
      ● Alertas Inbursa: Recibe notificaciones de los movimientos realizados con tu tarjeta de crédito a través de WhatsApp, correo electrónico y SMS. De esta manera,
      estarás al tanto de cualquier operación realizada y podrás identificar cualquier actividad sospechosa. 🔒📲🔔<br/>● Control desde tu app Inbursa Móvil: Tienes la
      posibilidad de activar y desactivar tu tarjeta para mayor seguridad. Además, puedes utilizar funciones de reconocimiento facial (Facepass) para acceder a la app
      y asegurarte de que solo tú tengas acceso a la información de tu tarjeta. 📱👤<br/>
      ● CVV dinámico: es un código de seguridad único generado a través de tu app Inbursa Móvil, que te permitirá hacer compras en línea, protegiendo los datos de tu tarjeta. 🔐💳`
    },
    {
      id:56,
      question: '¿La tarjeta Inbursa Óptima genera puntos?',
      answer: `Sí, te otorga puntos Inbursa por cada dólar facturado de acuerdo a lo siguiente:<br/>
      ● 2 Puntos Inbursa por compras en el extranjero y boletos de avión.<br/>
      ● 1.5 Puntos Inbursa por pagos realizados en restaurantes y hoteles.<br/>
      ● 1 Punto Inbursa por el resto de las compras realizadas.<br/>
      Estos puntos acumulados pueden ser canjeados por una variedad de recompensas y beneficios, como productos, servicios, descuentos, viajes, entre otros. Consulta detalles,
      términos y condiciones del Programa de Recompensas Inbursa en https://bit.ly/tdc-optima. 🎁🛍️✈️`
    },
    {
      id:57,
      question: '¿Los puntos de la tarjeta Inbursa Óptima se pueden redimir en efectivo?',
      answer: `No, los puntos acumulados solo se pueden utilizar dentro de los programas de lealtad o recompensas asociados con la tarjeta. Estos programas suelen ofrecer una
      variedad de opciones para canjear los puntos, como descuentos en compras, productos, servicios, viajes u otras recompensas.<br/>
      Consulta más información en https://bit.ly/tdc-optima. 💰`
    },
    {
      id:58,
      question: '¿Dónde se pueden redimir los puntos acumulados de la tarjeta Inbursa Óptima?',
      answer: `Los puntos acumulados se pueden redimir en diferentes programas de lealtad y recompensas, como el Programa de Lealtad Star Médica (2x1) y el Programa de
      Recompensas de Viajes y Hoteles.<br/>En el Programa de Lealtad Star Médica (2x1), los puntos pueden ser utilizados para obtener beneficios y descuentos en servicios
      médicos y hospitalarios en la Red de Hospitales Star Médica. 🌟🏥<br/>El Programa de Recompensas de Viajes y Hoteles ofrece la posibilidad de canjear los puntos acumulados
      por descuentos y beneficios al reservar vuelos, hospedaje y otros servicios relacionados con viajes. ✈️🏨<br/>
      Consulta más información en https://bit.ly/tdc-optima. 📲🌐`
    },
    {
      id:59,
      question: '¿Cómo puedo realizar el pago de la tarjeta de crédito Óptima?',
      answer: `Es muy fácil pagar tu Tarjeta de Crédito Óptima Inbursa, tú eliges:<br/>
      ● Cargo automático a cuenta de cheques Inbursa<br/>
      ● A través de tu app Inbursa Móvil o en tu Banca en Línea Personal<br/>
      ● En sucursales y oficinas de Inbursa a Nivel Nacional, incluyendo las ubicadas en tiendas Sanborns y en tiendas y clubes de Grupo Walmart. Consulta ubicaciones en
      https://bit.ly/gfi-localizanos 🏦📱💻<br/>
      ● En cadenas comerciales dentro del horario de servicio<br/>
      ● En oficinas Telecomm<br/>
      ● En cualquier sucursal de otro Banco<br/>
      ● En los cajeros automáticos de Banco Inbursa<br/>
      Consulta más información en https://bit.ly/tdc-optima. 💳💸💼`
    },
    {
      id:60,
      question: '¿Cuáles son los requisitos para contratar la tarjeta Inbursa Óptima?',
      answer: `● Debes tener entre 30 y 65 años de edad.<br/>
      ● Tener nacionalidad mexicana o residir en México.<br/>
      ● Identificación oficial vigente: Credencial de Elector, Pasaporte, Cédula Profesional con vigencia a partir del año 2002 o FM2 para extranjeros.<br/>
      ● Contar con tu Registro Federal de Contribuyentes (RFC). 📋👤🔖`
    },
    {
      id:61,
      question: '¿En dónde puedo solicitar la tarjeta de crédito Inbursa Óptima?',
      answer: `● En línea: Solicítala en nuestro sitio web (liga) 🌐💻<br/>
      ● App Inbursa Móvil: Si ya eres cliente Inbursa, solicítala de forma rápida y sencilla a través de tu app en la sección "Cotiza y contrata" 📱📲<br/>
      ● Invitación en la app Inbursa Móvil: Algunos clientes podrían recibir una invitación para solicitarla a través de su app. Si tienes esta invitación,
      sigue las indicaciones para iniciar el trámite. 📩📲<br/>
      ● Sucursales Inbursa: Acude a la sucursal de tu preferencia, consulta ubicaciones en https://bit.ly/gfi-localizanos 🏦📍<br/>
      ● Fuerza de ventas: Si cuentas con un asesor financiero de Inbursa, contáctalo para realizar la solicitud. 🤝💼`
    },
  ];

  static readonly FAQ_TEXT_TO_REMOVE = {
    LinkWhithoutEmoji: 'https://onelink.to/inbursamovil',
    LinkWhithEmoji: '👉🏻 https://onelink.to/inbursamovil',
    LinkWhithEmojiNoSecure: '👉🏻 http://onelink.to/inbursamovil'
  };

  static readonly TRANSACTION_DETAILS = {
    Help: `Usted puede obtener un comprobante de un pago realizado a través del SPEI, expedido por la institución receptora del pago,
    y que contiene información acerca de la transferencia realizada. El CEP contiene el número de serie del certificado de seguridad,
    la cadena original y un sello digital que permite tanto validar su autenticidad como darle certeza al usuario acerca de su pago.
    Lo podrá descargar tanto en formato de impresión (PDF) como de archivo de datos (XML).`
  };

  static readonly AFORE = {
    ModalTitleWithdrawal: 'Validación de Expediente para solicitud de Retiro',
    TitleProcessAlert: '¿Estás seguro de que <br/>quieres cancelar?',
    DetailProcessAlert: 'Se perderá el avance de tu trámite',
    Close: 'close',
    SelectFile: 'Debes seleccionar un archivo',
    H: ' h',
    SuccessfulRequest: 'Solicitud exitosa',
    FormValidate: 'form-validate',
    HrValidate: 'hr-validate',
    StyleTs: 'style-ts',
    ModalVoluntaryContributions: 'modal-voluntary-contributions',
    ModalRetirementAdvanceAge: 'modal-retirement-advanced-age',
    ModalGenericClassNoBorder: 'modal-generic-class no-border',
    RequestSuccess: 'requestSuccess',
    RequestError: 'requestError',
    ModalSuccesRetirement: 'modal-succes-retirement',
    ModalErrorRetirement: 'modal-error-retirement',
    ModalErrorTotalRetirement: 'modal-error-total-retirement',
    ImgClose: 'imgClose',
    PatrimonialStyleOne: 'patrimonial-style-one',
    PatrimonialStyleTwo: 'patrimonial-style-two',
    StyleCloseOne: 'style-close-one',
    StyleCloseTwo: 'style-close-two',
    Retirement: 'retirement',
    RetirementPartial :{
      Title: Strings.PARTIAL_WITHDRAWALS_OPTION,
      Subtitle : 'Estimado trabajador, de acuerdo a los salarios notificados por el IMSS, usted podrá elegir la modalidad de pago que más le convenga',
      Unemployment: 'unemployment',
      LabelSelectOne : 'Elige uno:',
      UnemploymentRetirement :'Retiro por Desempleo',
      UnemploymentRetirementTwo :'Retiro por desempleo',
      UnemploymentRetirementPartial :'Retiro parcial por Desempleo',
      ListRequeriments: [
        'Tener por lo menos 46 días desempleado',
        'No haber efectuado este retiro en los últimos 5 años'
      ],
      Modality: 'Modalidad ',
      ModalityA: 'Modalidad A',
      ModalityB: 'Modalidad B',
      ModalityHeader: 'Se entrega en una sola exhibición',
      ContentA: 'Para cuentas individuales que tienen al menos tres años de haber sido abiertas y tienen un mínimo de doce' +
      ' bimestres de cotización al Instituto.<br/> Monto igual a 30 días de su último salario base de' +
      ' cotización, y de acuerdo con los límites establecidos en la ley del Seguro Social y demás normatividad aplicable.',
      ContentB: 'Para cuentas individuales que tienen cinco años o más de haber sido abiertas.<br/>' +
      ' Monto que resulte menor entre 90 días de su último salario base de cotización, o el 11.5% del saldo de la' +
      ' Subcuenta de Retiro, Cesantía en Edad Avanzada y Vejez.<br/><br/>En caso, de no tener derecho a la Modalidad B elegida' +
      ' acepto que se realice el trámite de retiro parcial por desempleo por la Modalidad A, en caso, de tener derecho a esta.',
      AllowedStatus: 'Estatus permitido',
      Requirements: 'Requisitos',
      Modalities: 'Modalidades',
      Application :'Su solicitud de Derecho para Retiro por Desempleo fue aceptada por el IMSS, favor de seleccionar el botón “Continuar” para seguir con el trámite',
      ErrorStatus : '<label class="text-header">Error de status</label>',
      ActiveWorker: 'El trabajador no se encuentra Inactivo',
      NonActiveWorker : 'El trabajador no cumple con las semanas cotizadas necesarias para el trámite',
      TitleModalModality: 'Solicitud de Certificado de Derecho para Retiro por Desempleo',
      TitleModalModalityMobile:'Solicitud de Certificado de <br> Derecho para Retiro <br> por Desempleo',
      RequestCertificate : 'Estimado Cliente: En esta pantalla se realizará una consulta al Instituto Mexicano del'+
      ' Seguro Social respecto a los requisitos previamente mencionados para emitir el Certificado '+
      ' del Derecho para el retiro solicitado. Si la consulta es exitosa se continuará con el trámite. '+
      'En caso de no cumplir con algún requisito la solicitud será rechazada y deberá aclararse'+
      ' la situación del trabajador con el IMSS.',
      SelectModality: 'Selecciona la modalidad del monto del Retiro',
      ReportedSalaryA: '$258.58',
      TotalPaymentA: '$6,780.00',
      Tool: 'Se entrega en una sola exhibición. Para cuentas individuales que tienen al menos tres años de haber sido abiertas y tienen un mínimo de doce bimestres de cotización' +
        ' al Instituto. Monto igual a 30 días de su último salario base de cotización, y de acuerdo con los límites establecidos en la ley del Seguro Social ' +
        'y de más normatividad aplicable.',
      ReportedSalaryB: '$375.00',
      TotalPaymentB: '$11,950.00',
      ToolB: 'Se entrega en una sola exhibición. Para cuentas individuales que tienen cinco años o más de haber sido abiertas. ' +
      'Monto que resulte menor entre 90 días de su último salario base de cotización, o el 11.5% del saldo de la Subcuenta de Retiro, ' +
      'Cesantía en Edad Avanzada y Vejez. En caso, de no tener derecho a la Modalidad B elegida acepto que se realice el trámite de ' +
      'retiro parcial por desempleo por la Modalidad A, en caso, de tener derecho a esta.',
      ReportedSalary: 'Salario notificado: ',
      AmountToPay: 'Cantidad estimada a pagar:',
      TemplateA : 'Se entrega en una sola exhibición. <br> Para cuentas individuales que <br>tienen al menos tres años<br>de haber sido abiertas y tienen un<br>'+
      'mínimo de doce bimestres de <br>cotización al Instituto.<br>Monto igual a 30 días de su último <br>salario base de cotización, y de <br>'+
      'acuerdo con los límites<br>establecidos en <br>la ley del Seguro Social y demás<br>normatividad aplicable.',
      TemplateB: 'Se entrega en una sola exhibición. <br>Para cuentas individuales que tienen<br>cinco años o más de haber sido<br>abiertas.<br>'+
      'Monto que resulte menor entre 90 días<br>de su último salario base de<br>cotización, o el 11.5% del saldo de la<br>Subcuenta de Retiro, Cesantía en<br>'+
      'Edad Avanzada y Vejez.<br><br>En caso, de no tener derecho a la<br>Modalidad B elegida acepto que se<br>realice el trámite de retiro parcial por<br>'+
      'desempleo por la Modalidad A, en <br>caso, de tener derecho a esta.',
      ModalityAlert:'Debe seleccionar una modalidad',
      UnemploymentApplication: 'Solicitud de Retiros por Desempleo',
      Procedure: 'Trámite',
      StartDate:'Fecha de inicio de trámite',
      StartProcess:'Inicio de trámite',
      Conditions: 'Su solicitud será enviada a la Empresa Operadora y en caso de ser aceptada, le pagaremos a la cuenta referida en máximo 1 día hábil',
      ModalPartail: 'modal-partial',
      ModalViewUnemployement: 'modal-view-unemployment',
      TitlePartial: 'title-partial',
      HeaderMTitleModalConsult: 'header-m title-modal-consult',
      TitleModal: 'title-modal',
      JustifyTitleConsult: 'justify-title-consult',
      CloseIconMB8CloseConsult: 'close-icon m-b-8 close-consult',
      CrossBUttonModalRetirementUnemployement: 'crossButtonModalRetirementUnemployment'
    },
    RetirementMarriage:{
      Title: 'Retiro parcial por Matrimonio' ,
      TitleFormMarriage: 'Adjuntar Acta de Matrimonio',
      SubtitleFormMarriage: 'Adjuntar una copia digitalizada del documento de la Resolución de Pensión con un peso mínimo de 256 KB.',
      Marriage: 'marriage',
      MarriageRetirement :'Retiro por Matrimonio',
      Application: 'Su solicitud de Derecho para Retiro por Matrimonio fue aceptada por el IMSS, favor de seleccionar el botón “Continuar” para seguir con el trámite',
      TitleModalModality: 'Solicitud de Certificado de Derecho para Retiro por Matrimonio',
      TitleModalModalityMobile:'Solicitud de Certificado de <br> Derecho para Retiro <br> por Matrimonio',
      MarriageApplication: 'Solicitud de Retiros por Matrimonio',
      TotalWeeks: 'Tener al menos 150 semanas de cotización',
      Conditions: 'No haber efectuado este retiro anteriormente',
      AddConditions: 'Tener una copia de tu Acta de Matrimonio en archivo con formato',
      Format: '(JPEG, TIFF y PDF)',
      Process: 'Asegurarte de capturar correctamente la fecha de matrimonio, ya que si no coincide con que indique el Acta, si rechazará el trámite.',
      DetailsSuccess: 'Servicio registrado exitosamente.',
      Details: 'Detalles acerca del Retiro por  Matrimonio',
      DetailsError: 'El archivo no se cargó correctamente',
      UMA: `El retiro por matrimonio equivale a 30 veces el valor de la UMA. Si continuas con este trámite, aceptas que se reducirá el monto de tu ahorro para el retiro,
       lo que puede impactar en tu pensión futura`,
      ErrorMessage: 'Asegúrese de que cumple con los requisitos de formato y tamaño antes de intentar nuevamente la carga.',
      MaxFile: 256000,
      Files: 'files',
      AlertSize: 'El tamaño del archivo es mayor al establecido.',
      FormatInvalid: 'El formato del archivo no es válido',
      File: 'file',
      Certificate: 'certificate',
      CertificateFileName: 'certificateFileName',
      Upload: 'upload',
      RequiredCertificate: 'Debe seleccionar un archivo',
      BirthCertificate: 'Adjuntar Acta de nacimiento digitalizada',
      PensionResolution: 'Adjuntar Resolución de pensión',
      ValidFormat: 'Formatos: .jpg, .tif y .pdf<br>Peso mínimo : 256KB hasta 1MB',
      MinimumWeight: 'Peso mínimo: 256KB hasta 1MB',
      DateMarriage: 'marriageDate',
      WeddingDate: 'Fecha de matrimonio',
      NameSpouse: 'Nombre (s) de Cónyuge',
      Name: 'spouseName',
      FirstName: 'Primer Apellido de Cónyuge',
      Pattern: 'spousePatternalSurname',
      LastName: 'Segundo Apellido de Cónyuge',
      Maternal: 'spouseMaternalSurname',
      GenderSpouse: 'Género del Cónyuge',
      Gender: 'spouseGender',
      Masculine:'Masculino',
      Feminine: 'Femenino',
      MarriageState: 'Entidad Federativa del matrimonio',
      State:'marriageState',
      DataSpouse: 'Datos de Cónyuge',
      ConfirmInformation: 'Confirma tu información',
      Classes: {
        CloseButton: {
          ModalEndProcess: 'modal-alert-end-process',
          Classes: 'crossButtonModalMarriageCertificate marriage-requiremet-margin',
          Requirement: 'crossButtonModalRequirementsMarriage marriage-requiremet-margin'
        },
        ContinueButton: {
          Classes: 'button-marriage-certificate modals-btn',
          CardModalClass: 'modalretirementunemployment',
          ErrorModal: 'modal-validate-error',
          MarriageCertificateModal: 'modalmarriagecertificate'
        }
      },
      CrossButtonModalConfirmSpouseData: 'crossButtonModalConfirmSpouseData',
      ConfirmSpouseButtonModalBtn: 'confirmSpouseButton modals-btn',
      ModalConfirmSpouseData: 'modalconfirmspousedata',
      ModalSpouseData: 'modal-spuose-data',
      WebKitFillAvailable: '-webkit-fill-available',
      ButtonMargin: '1.625rem 1.125rem 0 0',
      ModalRequirementsMarriage: 'modalrequirementsmarriage',
      ModalsBtnMarriageRequirementsButton: 'modals-btn marriage-requirement-button',
      ModalRetirementMarriage: 'modalretirementmarriage',
      ModalError: 'modalerror',
      CrossBUttonError: 'cross-button-error',
      RetirementUnemployementModalsBtnMarriageRequirementButton: 'retirementunemployment modals-btn marriage-requirement-button',
      ModalsBtn: 'modals-btn',
      SpouseButtonModalsBtnMarriageSpouseData: 'spuose-button modals-btn marriage-spouse-data',
      ButtonmSpoueseButtonModalsBtnMarriageSpouseData: 'button-m spuose-button modals-btn marriage-spouse-data',
      CrossButtonModalSpuse: 'crossButtonModalSpouse',
      NgbDpWeekDaySmall: 'ngb-dp-weekday small',
      NgbDpDay: 'ngb-dp-day',
      NgbDatePicker: 'ngb-datepicker',
      BodyM: 'body-m',
      ModalSpouse: 'modal-spouse',
      TransferDatepicker: 'transfer-datepicker',
    },
    RetirementTotal:{
    Header: `<label class="header-xs-new">${Strings.RITGH_REQUEST_REJECTED} </label>`,
      Total: 'total',
      Title: 'Retiro por Edad Avanzada',
      Alert: 'Usted cuenta con la edad requerida para solicitar el trámite de Retiro Total por Amparo a la Edad Avanzada.'+
      '<br> Esta modalidad de Retiro contemplar el pago de las subcuentas Sar 92 e INFONAVIT 92.',
      AgeAlert: 'Usted cuenta con la edad requerida para solicitar el trámite de Retiro Total por Amparo a la EdadAvanzada.'+
      '<br> Esta modalidad de Retiro contemplar el pago de las subcuentas Sar 92 e<br>INFONAVIT 92.',
      VALID_RESOLUTION: {
        HEADER: 'Sin registro de resolución de pensión',
        MESSAGE: 'No cuenta con una resolución de pensión registrada. Favor de acudir a su instituto emisor para solicitar más información',
      },
      VALID_AGE: {
        HEADER: 'Error',
        MESSAGE: 'No cuenta con 65 años cumplidos para un Retiro por Edad',
        TITLE: 'Validación de Derecho Cargado o Edad Avanzada',
        CONDITIONS: 'A continuación el Portal Bancario validará que usted cuente con una Resolución de Pensión o Negativa de Pensión registrada en Afore'+
        ' Inbursa; o bien que cumpla con los 65 años para el Retiro por Edad Avanzada.'
      },
      SpecificResolution: 'Usted cuenta con una resolución cargada con las siguientes especificaciones:',
      Type: '73 Tipo',
      Regime: 'Régimen',
      Zero: '00-',
      Benefit : 'Prestación',
      PensionPaid:'Pensión Pagada',
      IVType: 'IV - IV Tipo',
      InsuranceType: 'Tipo Seguro',
      TotalAmount: '$150.00',
      DepositAmount: 'Monto Depósito',
      TotalType: '02 - Retiro Total IMSS',
      WithdrawalType: 'Tipo Retiro',
      Date: '16/06/2023',
      ResolutionDate: 'Fecha Resolución',
      StartDate: 'Fecha Inicio de Pensión',
      CardNumber: '***2007',
      CardName: 'Nómina Efe',
      VeVe: 'Ve - Ve',
      Pension: 'Pensión',
      WithdrawalTotal: '022 - Retito Total IMSS',
      Conditions :'Te notificaremos del estatus de tu solicitud vía correo electrónico.<br><br> En caso de que tu solicitud sea exitosa,'+
      'el depósito se realizará en un máximo de 5 días hábiles.<br><br>Si tienes algún problema con esta solicitud comunícate con nosotros al:'+
      '<br><br>800 90 90000<br><br>',
      Classes: {
        ModalDialogClass: 'modal-alert-end-process'
      }
    },
    RefundsWeeks:{
      ModalTitleRefund: Strings.WEEK_REFUND_OPTION,
      TITLE: 'Reintegro Semanas de Cotización',
      HTML_TITLE: 'Información del Tipo de Pago',
      WEEKS_TO_PAYMENT: 'Semanas a Pagar',
      SUBTITLE: 'Ingresa los datos del Reintegro',
      RefundType: 'Parcial',
      Week: '12',
      Amount: '$4,797.24',
      AmountToPay: 'Monto Disponible a Pagar',
      Portrait: 'portrait',
      Landscape: 'landscape',
      refund_type: 'Tipo de Reintegro',
      LabelWeek: 'semana',
      LabelAmount: 'monto',
      LabelRefund: 'reintegro',
      CardList : {
        FirstCard: 'card1',
        FirstAmount: '$ 37,582.80',
        FirstCost: '399.77',
        SecondCard: 'card1',
        SecondAmount: '$ 42,124.80',
        SecondCost: '210.26',
        ThirdCard: 'card3',
      },
      OptionRight: 'Selecciona el Evento a reintegrar',
      DiscountedWeeks: 'Semanas Descontadas',
      WeekToTecover : 'Costo por semana <br> a Recuperar',
      TypeBeneficit :'Tipo de prestación',
      Withdrawal : 'Retiro',
      AmountPay : 'Monto Pagado',
      EventDate: 'Fecha del evento',
      ValueEventDate : '14/06/2023',
      Total : 'Total ',
      Weeks: ['1 semana', '2 semanas', '3 semanas', '4 semanas', '5 semanas', '6 semanas', '7 semanas',
                '8 semanas','9 semanas','10 semanas', '11 semanas','12 semanas'],
      WeeksToReintegrate: 'Semanas a Reintegrar',
      Alert :'No cuentas con retiros a los cuales aplicar el reintegro de semanas cotizadas',
      Reintegrate: 'Reintegro de Semanas de Cotización',
      OnlineReintegrate: 'Reintegro Semanas de Cotización en Línea',
      DataConfirm: 'Confirma los datos <br> de la operación',
      ModalAddProduct: 'modal-add-product'
    }
  };

  static readonly MODAL_BRANCH = {
    OptionBranch : 'Estimado Trabajador, usted no cuenta con “Enrolamiento Biométrico”, favor de acudir a la <strong> Sucursal</strong> más cercana que brinde '+
    '<strong>Servicios de Afore</strong> a conformarlo, llevando consigo los siguientes documentos:',
    RequerimentOne : ' Identificación oficial vigente (INE, IFE, Pasaporte, Documento Migratorio)',
    RequerimentTwo : 'Comprobante de domicilio vigente 3 meses (Agua, Luz, Predial, EDC Bancario, TV Paga)',
    ConsultBranch : 'Consulta las sucursales que brindan servicios <strong>AFORE</strong>:',
    Proceedings: 'Expediente de Identidad',
    Enrollment: 'Enrolamiento biométrico'
  };

  static readonly ADDITIONAL_MANAGEMENT = {
    AssociatedCardTitle: 'PLÁSTICO ASOCIADO'
  };

    static readonly VALIDATION_TYPE = 'validationType';
    static readonly VALUE = 'value';
    static readonly CONFIRM = 'confirm';
    static readonly COMPLETE = 'complete';

    static readonly DEFAULT_VALIDATIONS_MESSAGES_FORM = {
        required: 'Este campo es requerido',
        email: Strings.INVALID_EMAIL
    };
    static readonly MINIMUM_AND_MAXIMUM_LENGTH = (min: number, max: number) => `Longitud debe ser mayor que ${min} y menor o igual a ${max}6`;
    static readonly FIELD_REQUIRED = 'Campo es requerido';
    static readonly CARD_NUMBER_LABEL = 'Número de la tarjeta';
    static readonly ALIAS_ACCOUNT_LABEL = 'Alias de la cuenta';
    static readonly CREDIT_LIMIT_LABEL = 'Límite de crédito';
    static readonly OTHER_OPERATION = 'Otra operación';
    static readonly CREDIT = 'Crédito';
    static readonly DELETE_PROGRAM = 'Eliminar programa';
    static readonly ADVERTISEMENT_POINTS = 'Al eliminar no podrás transferir<br>tus puntos a este programa<br>¿Deseas eliminar?';
    static readonly CANCEL = 'Cancelar';
    static readonly GO_BACK = 'Regresar';
    static readonly TransferMoney = 'Transferir Dinero';
    static readonly LocksAndLimits = 'Bloqueos y límites';
    static readonly ProgramedTransfers = 'Traspasos programados';
    static readonly Domiciliation = 'Domiciliación';
    static readonly Transfer = 'Transferir';
    static readonly RefundWeeks = 'Reintegro de semanas';
    static readonly PartialWithdrawals = 'Retiros Parciales';
    static readonly TotalWithdrawals = 'Retiros Totales';
    static readonly Debit = 'Debit';
    static readonly Credit = 'Credit';
    static readonly Credits = 'Credits';
    static readonly Product = 'Product';
    static readonly Patrimonial = 'Patrimonial';
    static readonly Account = 'Cuenta';
    static readonly Tdc = 'TDC';
    static readonly Insurances = 'Seguros';
    static readonly Afore = 'Afore';
    static readonly week = 'semana';
    static readonly amount = 'monto';
    static readonly refund = 'reintegro';
    static readonly BALANCE_PER_DAY = 'Saldo por día';
    static readonly BALANCE_DUE = 'Saldo adeudado';
    static readonly EXPIRATION = 'Vencimiento';
    static readonly MODIFY = 'Modificar';
    static readonly INFO_WEB = 'Consulta la información de tu tarjeta web';
    static readonly CVV = 'CVV: XXX';
    static readonly DUE_DATE = 'Fecha de vencimiento: dd/mm';

    static readonly BTN_EDIT_STYLES = {
      'background': 'var(--primary-1000)',
      'border-radius': 'var(--border-radius-s)',
      'border': 'none',
      'color': 'var(--White)'
    };

    static readonly BTN_EDIT_STYLES_BACK = {
      'background': 'var(--secondary-1500)',
      'border-radius': 'var(--border-radius-s)',
      'border': 'none',
      'color': 'var(--secondary-300)'
    };
}
