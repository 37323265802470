import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerComponent } from 'src/app/component/container/container.component';
import { EnterSecurityCodeComponent } from 'src/app/component/enter-security-code/enter-security-code.component';
import { SessionGuard } from 'src/app/shared/guards/session.guard';
import { FederalTaxesPaymentComponent } from 'src/app/component/federal-taxes-payment/federal-taxes-payment.component';
import { MaintenanceComponent } from 'src/app/component/maintenance/maintenance.component';
import { InvestmentComponent } from 'src/app/component/invesment/investment.component';
import { ConfirmInvesmentComponent } from 'src/app/component/confirm-invesment/confirm-invesment.component';
import { CompleteInvestmentComponent } from 'src/app/component/complete-investment/complete-investment.component';
import { SeriesTokenComponent } from 'src/app/component/series-token/series-token.component';
import { EnterSecurityCodeSerialComponent } from 'src/app/component/enter-security-code-serial/enter-security-code-serial.component';
import { CompleteSuaPaymentComponent } from 'src/app/component/complete-sua-payment/complete-sua-payment.component';
import { CodeflexTieComponent } from 'src/app/component/codeflex-tie/codeflex-tie.component';
import { ConfirmDataCodeflexComponent } from 'src/app/component/confirm-data-codeflex/confirm-data-codeflex.component';
import { OperationSuccessComponent } from 'src/app/component/operation-success/operation-success.component';
import { AppLoginComponent } from 'src/app/component/app-login/app-login.component';
import { MobileAppGuard } from 'src/app/shared/guards/mobile-app.guard';
import { PATH } from 'src/core/constants/Path';
import { Utils } from 'src/core/utils/utils';
import { Strings } from 'src/core/constants/Strings';
import { ServicesContractComponent } from 'src/app/component/services-contract/services-contract.component';
import { AdditionalContributionsComponent } from 'src/app/component/additional-contributions/additional-contributions.component';
import { AdditionalContributionsConfirmComponent } from 'src/app/component/additional-contributions-confirm/additional-contributions-confirm.component';
import { AdditionalContributionsCompleteComponent } from 'src/app/component/additional-contributions-complete/additional-contributions-complete.component';
import { HomeGuard } from 'src/app/shared/guards/home.guard';
import { WebCardDataComponent } from 'src/app/component/web-card-data/web-card-data.component';
import { WebCardDataConfirmationComponent } from 'src/app/component/web-card-data-confirmation/web-card-data-confirmation.component';
import { ExternalNavigateApp } from 'src/app/shared/guards/external-navigate-app.guard';
import { LoginAppGuard } from 'src/app/shared/guards/login-app.guard';
import { UserPasswordComponent } from 'src/app/component/user-password/user-password.component';

const routes: Routes = [
  {
    path: Strings.EMPTY, redirectTo: Utils.gfiToRoute(PATH.Login), pathMatch: 'full'
  },
  {
    path: Utils.gfiToRoute(PATH.Maintenance), component: MaintenanceComponent
  },
  {
    path: Utils.gfiToRoute(PATH.Login), canActivate: [LoginAppGuard], component: ContainerComponent
  },
  {
    path: Utils.gfiToRoute(PATH.UserLogin), canActivate: [HomeGuard,LoginAppGuard], component: UserPasswordComponent
  },
  {
    path: PATH.AppValidation, component: AppLoginComponent, canActivate: [MobileAppGuard]
  },
  {
    path: Strings.EMPTY, canActivate: [SessionGuard], children: [
      {
        path: Utils.gfiToRoute(PATH.Login), loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
      },
      {
        path: Utils.gfiToRoute(PATH.Home),  canActivate: [ExternalNavigateApp],loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
      },
      {
        path: Utils.gfiToRoute(PATH.Transfers), loadChildren: () => import('./transfers/transfers.module').then(m => m.TransfersModule)
      },
      {
        path: Utils.gfiToRoute(PATH.InternationalTransfers),
        loadChildren: () => import('./international-transfers/international-transfers.module').then(m => m.InternationalTransfersModule)
      },
      {
        path: Utils.gfiToRoute(PATH.TransferCTPatrimonial),
        loadChildren: () => import('./ct-patrimonial-transfers/ct-patrimonial-transfers.module').then(m => m.CtPatrimonialTransfersModule)
      },
      {
        path: Utils.gfiToRoute(PATH.NewAddressee),
        loadChildren: () => import('./new-addressee/new-addressee.module').then(m => m.NewAddresseeModule)
      },
      {
        path: Utils.gfiToRoute(PATH.BeneficiaryData),
        loadChildren: () => import('./new-international-addressee/new-international-addressee.module').then(m => m.NewInternationalAddresseeModule)
      },
      {
        path: Utils.gfiToRoute(PATH.SecurityCode), component: EnterSecurityCodeComponent
      },
      {
        path: Utils.gfiToRoute(PATH.SerialNumber), component: EnterSecurityCodeSerialComponent
      },
      {
        path: Utils.gfiToRoute(PATH.GeneralInformation), loadChildren: () => import('./general-information/general-information.module').then(m => m.GeneralInformationModule)
      },
      {
        path: Utils.gfiToRoute(PATH.AccessHistory), loadChildren: () => import('./access-history/access-history.module').then(m => m.AccessHistoryModule)
      },
      {
        path: Utils.gfiToRoute(PATH.Control), loadChildren: () => import('./control-card/control-card.module').then(m => m.ControlCardModule)
      },
      {
        path: Utils.gfiToRoute(PATH.FrequentlyQuestions), loadChildren: () => import('./faqs/faqs.module').then(m => m.FaqsModule)
      },
      {
        path: Utils.gfiToRoute(PATH.Portability), loadChildren: () => import('./portability/portability.module').then(m => m.PortabilityModule)
      },
      {
        path: Utils.gfiToRoute(PATH.Services), loadChildren: () => import('./services-module/services-module.module').then(m => m.ServicesModuleModule)
      },
      {
        path: Utils.gfiToRoute(PATH.AddService, true),
        loadChildren: () => import('./new-schedulable-service/new-schedulable-service.module').then(m => m.NewSchedulableServiceModule)
      },
      {
        path: Utils.gfiToRoute(PATH.PaymentSAT), component: FederalTaxesPaymentComponent
      },
      {
        path: Utils.gfiToRoute(PATH.Beneficiaries), loadChildren: () => import('./beneficiaries/beneficiaries.module').then(m => m.BeneficiariesModule)
      },
      {
        path: Utils.gfiToRoute(PATH.Investment), component: InvestmentComponent
      },
      {
        path:  Utils.gfiToRoute(PATH.ConfirmInvestment), component: ConfirmInvesmentComponent
      },
      {
        path: Utils.gfiToRoute(PATH.CompleteInvestmentRegistration), component: CompleteInvestmentComponent
      },
      {
        path: Utils.gfiToRoute(PATH.TokenNumber), component: SeriesTokenComponent
      },
      {
        path: Utils.gfiToRoute(PATH.Cancellations),loadChildren: () => import('./checks-cancel/cancel.module').then(m => m.CheckCancelModule)
      },
      {
        path: Utils.gfiToRoute(PATH.CheckingAccountProtection),  loadChildren: () => import('./checks-protection/protection.module').then(m => m.ProtectionModule)
      },
      {
        path: Utils.gfiToRoute(PATH.SuaPaymentCompleted), component: CompleteSuaPaymentComponent
      },
      {
        path: Utils.gfiToRoute(PATH.Codeflex), component: CodeflexTieComponent
      },
      {
        path: Utils.gfiToRoute(PATH.CodeflexConfirmWithdrawal), component: ConfirmDataCodeflexComponent
      },
      {
        path: Utils.gfiToRoute(PATH.CodeflexCompletedWithdrawal), component: OperationSuccessComponent
      },
      {
        path: Utils.gfiToRoute(PATH.LoyaltyPoints), loadChildren: () => import('./inbursa-points/inbursa-points.module').then(m => m.InbursaPointsModule)
      },
      {
        path: Utils.gfiToRoute(PATH.Clarification), loadChildren: () => import('./clarifications/clarifications.module').then(m => m.ClarificationsModule)
      },
      {
        path: Utils.gfiToRoute(PATH.Domiciliation),
        loadChildren: () => import('./domiciliation/domiciliation.module').then(m => m.DomiciliationModule)
      },
      {
        path: Utils.gfiToRoute(PATH.ProgrammedOperations),
        loadChildren: () => import('./programmed-operations/programmed-operations.module').then(m => m.ProgrammedOperationsModule)
      },
      {
        path: Utils.gfiToRoute(PATH.Hiring), component: ServicesContractComponent
      },
      {
        path: Utils.gfiToRoute(PATH.AdditionalContributions), component: AdditionalContributionsComponent
      },
      {
        path: Utils.gfiToRoute(PATH.AdditionalContributionsConfirm), component: AdditionalContributionsConfirmComponent
      },
      {
        path: Utils.gfiToRoute(PATH.AdditionalContributionsComplete), component: AdditionalContributionsCompleteComponent
      },
      {
        path: Utils.gfiToRoute(PATH.InvestmentFundsBuy),
        loadChildren: () => import('./investment-funds/investment-fund-buy/investment-fund-buy.module').then(m => m.InvestmentFundsBuy)
      },
      {
        path: Utils.gfiToRoute(PATH.InvestmentFundsSale),
        loadChildren: () => import('./investment-funds/investment-fund-sale/investment-fund-sale.module').then(m => m.InvestmentFundsSale)
      },
      {
        path: Utils.gfiToRoute(PATH.WebCardRequest), component: WebCardDataComponent
      },
      {
        path: Utils.gfiToRoute(PATH.WebCardRequestConfirmation), component: WebCardDataConfirmationComponent
      },
      {
        path: Utils.gfiToRoute(PATH.CreditPayment), loadChildren: () => import('./credits/credits.module').then(m => m.CreditsModule)
      },
      {
        path: Utils.gfiToRoute(PATH.VoluntarySavingsContributions),
        loadChildren: () => import('./afore/voluntary-savings-contributions/voluntary-savings-contributions.module').then(m => m.VoluntarySavingsContributionsModule)
      },
      {
        path: Utils.gfiToRoute(PATH.DirectDebitAfore),
        loadChildren: () => import('./afore/domiciliation-voluntary-contributions/direct-debit-afore.module').
        then(m => m.DirectDebitAforeModule)
      },{
        path: Utils.gfiToRoute(PATH.InsuranceExpress), loadChildren: () => import('./insurance-plan/insurance-plan.module').then(m => m.InsurancePlanModule)
      },
      {
        path: '**', redirectTo: PATH.Home
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes) ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
