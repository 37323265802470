import { ExchangeRateService } from 'src/app/services/exchange-rate.service';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { AccountCardService } from 'src/app/services/account-card.service';
import { StorageService } from 'src/app/services/storage.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { combineLatestWith, map, tap } from 'rxjs';
import { Utils } from 'src/core/utils/utils';
import { AccountType, AddresseTabs, ClassHTML, CoinType, Constants, Length, Numbers, Position, Regex as RegexConstants, Tab } from 'src/core/constants/Constants';
import { CardAddresseeService } from 'src/app/services/card-addressee.service';
import { AlertService } from 'src/app/services/alert.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InvestmentRegisterService } from 'src/app/services/investment-register.service';
import { Router } from '@angular/router';
import { ConsultSubAccountsRequest } from 'src/app/interface/dto/ConsultSubAccountsRequest';
import { ConsultSubAccountsResponse } from 'src/app/interface/dto/ConsultSubAccountsResponse';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { Resources } from 'src/core/constants/Resources';
import { ProductsService } from 'src/app/services/products.service';
import { AccountModel } from 'src/app/interface/AccountModel';
import { ExpressTransferService } from 'src/app/services/express-transfer.service';
import { PatrimonialStatusService } from 'src/app/services/patrimonial-status.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { CheckCreditCardResponse } from 'src/app/interface/ICheckCreditCardResponse';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { AccountTypeMax, ProductConstants } from 'src/core/constants/ProductConstants';
import { LoaderService } from 'src/app/services/loader.service';
import { ModalOptions } from 'src/app/interface/modal-options';
import { NotifyRetirementTypeService } from 'src/app/services/notify-retirement-type.service';
import { ModalService } from 'src/app/shared/modal.service';
import { RetirementType } from 'src/core/constants/AforeConstants';
import { ModalUnemploymentWithdrawalRequestComponent } from 'src/app/home/components/modal-unemployment-withdrawal-request/modal-unemployment-withdrawal-request.component';
import { AforeStrings } from 'src/core/constants/AforeStrings';
import { ModalEndProcessAlertComponent } from 'src/app/shared/components/modal-end-process-alert/modal-end-process-alert.component';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { NavigationConstants } from 'src/core/constants/NavigationConstants';
import { DirectDebitServicesService } from 'src/app/services/services-direct-debit.service';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { GetCardsDirectDebitRequest } from 'src/app/interface/dto/GetCardsDirectDebitRequest';
import { AforeService } from 'src/app/services/afore.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { DomiciliationStrings } from 'src/core/constants/DomiciliationStrings';
import { ProductUtils } from 'src/core/utils/ProductUtils';
@Component({
  selector: 'app-card-modal',
  templateUrl: './card-modal.component.html',
  styleUrls: ['./card-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PaginationService]
})
export class CardModalComponent implements OnInit {
  @Output() cardSelect = new EventEmitter<{cardAccount: ICardAccount, enableAccountChange: boolean}>();
  @ViewChild('modalContainer') modalContainer: ElementRef;
  @ViewChild('header') header: ElementRef;
  @ViewChild('footer') footer: ElementRef;
  @Input('modal') modal!: NgbModalRef;
  @Input() portability: boolean;
  @Input() getCreditCards: boolean = false;
  @Input() onlyCreditCards:boolean = false;
  @Output() savedAccount = new EventEmitter();
  @Input() filterCreditCards:string = Strings.EMPTY;
  @Input() filter: string[] = [];
  @Input() filterSkip: string[] = [];
  @Input() skipSubAccounts: boolean = false;
  @Input() filterAmexAccount: boolean = false;
  regexSearch: RegExp = RegexConstants.OnlyText;
  cardSelected: ICardAccount = {
    image: Strings.EMPTY,
    cardNumber: Strings.EMPTY,
    clabe: Strings.EMPTY,
    balance: Strings.EMPTY,
    selected: false
  };
  currency: string;
  badge: string;
  isInvestment: boolean;
  account: string = Strings.EMPTY;
  modalInstance: NgbModalRef;
  @Input() modalData: { transactionType: string, operation?: string };
  one: number = Numbers.One;
  accountInfo: AccountModel = this.accountInfoService.account;
  enableAccountChange: boolean = true;
  private readonly pageSize: number = Constants.PAGE_SIZE_ACCOUNT;
  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(
      this.paginationService.pagination,
      this.responsiveService.customObserve('(min-width: 2100px)'),
      this.loaderService.isLoading
    ),
    map(([breakpoints, pagination, extraPadding, isLoading]) => ({ breakpoints, cards: pagination.data, extraPadding: extraPadding.matches, isLoading})),
    tap((pagination) => {
      this.enableAccountChange = pagination.cards.length !== Length.One;
    })
  );
  aforeSelectCard: boolean = false;
  options: ModalOptions = {
    centered:true,
    size: Constants.MODAL_OPTIONS.SizeMd,
    modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAlertEndProcess
  };
  optionsUnem: ModalOptions = {
    size: Constants.MODAL_OPTIONS.SizeXl,
    modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal,
    centered:true
  };
  buttonClicked: boolean = false;
  chevronright: string = Resources.SHAPE_CONTINUE;
  searchIcon: string = Resources.SEARCH;
  SELECT_ACCOUNT = {
    selectAccount: Strings.SELECT_ACCOUNT.SelectAccount,
    select: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.SELECT
  };
  cardInformation(event: ICardAccount) {
    this.cardSelected = event;
    this.modifyCardAccountArray(event);
    this.cardSelect.emit({cardAccount: event, enableAccountChange: this.enableAccountChange});
  }
  modalCloseIcon: string = Resources.CLOSE_CIRCLE_BLUE;
  shapeContinueIcon: string = Resources.SHAPE_CONTINUE;
  constructor(
    private readonly accountCardService: AccountCardService,
    private readonly storageService: StorageService,
    private readonly baseService: IBaseService,
    private readonly paginationService: PaginationService,
    private readonly cardAddresseeService: CardAddresseeService,
    private readonly alertService: AlertService,
    private readonly patrimonialService: PatrimonialStatusService,
    private readonly exchangeRateService: ExchangeRateService,
    private readonly investmentRegisterService: InvestmentRegisterService,
    private readonly router: Router,
    private readonly productsService: ProductsService,
    private readonly expressTransferService: ExpressTransferService,
    private readonly responsiveService: ResponsiveService,
    private readonly productService: ProductsService,
    private readonly loaderService: LoaderService,
    private readonly notifyRetirementType: NotifyRetirementTypeService,
    private readonly modalService: ModalService,
    private readonly aforeService: AforeService,
    private readonly directDebitServicesService:DirectDebitServicesService,
    private readonly accountInfoService: AccountInfoStatusService,
    private readonly notifyAmountService: NotifyAmountService
  ) {if(this.notifyRetirementType.retirementTypeBs.value !== null){this.aforeSelectCard = true;}}
  async ngOnInit() {
    ProductConstants.ORIGIN_CONSULT_DATA = ProductConstants.ORIGIN_CONSULT.Button;
    if (this.router.url === PATH.Investment) {
      this.isInvestment = true;
    }
    this.cardAccountArray = [];
    await this.validateAccounts();
    this.modalService.modalInstance.update({
      ariaLabelledBy: Constants.MODAL_OPTIONS.AriaLabel,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.CardModal,
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeMd
    });
    setTimeout(() => {
          this.calculate();
    });
  }
  setMaxContent() {
    setTimeout(() => {
      this.modalContainer.nativeElement.style.maxHeight = ClassHTML.MaxContent;
    }, Numbers.OneHundred);
  }
  @HostListener('window:resize', ['$event'])
  calculate() {
    const cardHeight = Constants.CARD_MODAL_HEIGHT;
    const modalContainer = document.getElementsByClassName(ModalConstants.MODAL_OPTIONS.CardModal).item(Position.Zero);
    const modalContainerHeight = modalContainer.clientHeight;
    const containerPadding = Utils.getElementPadding(this.modalContainer.nativeElement);
    const availableHeight = modalContainerHeight - this.header.nativeElement.offsetHeight - this.footer.nativeElement.offsetHeight - containerPadding;
    let elementsPerPage = (Math.floor(availableHeight / cardHeight)) - this.one;
    elementsPerPage = elementsPerPage > Constants.MIN_ELEMENTS_PER_PAGE ? elementsPerPage : Constants.MIN_ELEMENTS_PER_PAGE;
    this.paginationService.setPageSize(elementsPerPage ?? this.pageSize);
    this.setMaxContent();
  }
  get cardAccountArray(): ICardAccount[] {
    return this.accountCardService.accountCardList;
  }
  set cardAccountArray(value: ICardAccount[]) {
    this.accountCardService.accountCardList = value;
  }
  modifyCardAccountArray(cardAccount: ICardAccount) {
    this.cardAccountArray.forEach((element) => {
      if (element.cardNumber === cardAccount.cardNumber) {
        element.selected = true;
      } else {
        element.selected = false;
      }
    });
  }
  async cardSelectedAccount(modal: NgbModalRef) {
    this.buttonClicked = true;
    this.accountCardService.selectedAccountCard = this.cardSelected;
    this.accountCardService.accountCard = this.cardSelected;
    if (this.isInvestment) {
      if (this.investmentRegisterService.accountNumber.NumeroCuenta !== Strings.EMPTY) {
        if (this.cardSelected.selected) {
          this.account = this.cardSelected.accountNumber;
        } else {
          this.account = this.investmentRegisterService.accountNumber.NumeroCuenta;
        }
      } else {
        this.account = this.cardSelected.accountNumber;
      }
      this.consultSubAccounts(this.account);
    }
    await this.selectAccount();
    this.savedAccount.emit(true);
    modal.close(Constants.MODAL_OPTIONS.SaveClick);
    this.accountCardService.setModalAccountOpen = false;
  }
  async selectAccount() {
    const pageOn = window.location.toString();
    if (this.cardSelected.accountType === AccountType.PAT || this.cardSelected.accountType === AccountTypeMax.PATMX
      && this.router.url === PATH.TransferData) {
      try {
        await this.patrimonialService.checkAccountLimitCT(this.cardSelected.accountNumber);
        this.router.navigate([PATH.TransferCTPatrimonial]);
      } catch (error) {
        const errorDetails = Utils.getErrorMsg(error);
        this.alertService.showPopupAlert({
          message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
          header: Strings.MSG_POPUP_TITLE,
          btnLabel: Strings.MSG_POPUP_ACCEPT,
          imgHead: Resources.CLOSE_ICON_RED,
          btnExit: false
        });
      }
      return;
    }
    if (this.cardSelected.clabe !== Strings.EMPTY) {
      this.accountCardService.selectedAccountCard = this.cardSelected;
      this.accountCardService.selected = true;
      this.currency = this.accountCardService.selectedAccountCard.currency;
      this.badge = this.cardAddresseeService.selectedCardAddressee.badge;
      if (this.currency === CoinType.USD && this.badge === CoinType.USD ||
        this.currency === CoinType.EUR && this.badge === CoinType.EUR) {
        this.exchangeRateService.exchange = { exchangeRateMXP: Constants.EXCHANGE_RATE_EMPTY };
        this.currency = Strings.EMPTY;
        this.badge = Strings.EMPTY;
      }
      this.aforeProcess(pageOn);
    }
  }
  private async checkingAccount() {
    try {
      const checkingAccounts = await this.productsService.getAccounts({forceOsb: true, reload: true, accountOptions: {
        transactionType:this.modalData?.transactionType || Strings.EMPTY,
        operation:this.modalData?.operation || Strings.EMPTY
      }, skipSubAccounts: this.skipSubAccounts});
      if (checkingAccounts) {
        this.cardAccountArray = [];
        checkingAccounts.forEach(item => {
          if (this.isInvestment) {
            this.investment(item);
          } else if (this.portability) {
            this.payrollAccount(item);
          } else if (this.filter.length > Length.Empty){
            this.filterAccounts(item);
          } else if(this.filterSkip.length > Length.Empty){
            this.filterSkipAccounts(item);
          } else {
            this.patrimonialValidate(item);
          }
        });
      }
      this.paginationService.setInitialData(Utils.listMajorToMinor(this.cardAccountArray, Constants.PAGINATION), this.pageSize);
    } catch (error) {
      this.accountCardService.setModalAccountOpen = false;
      return Utils.getErrorMsg(error);
    }
    return null;
  }
  aforeProcess(page: string) {
    if (page.includes(NavigationConstants.HOME) && (RetirementType.unemployment || RetirementType.marriage)) {
      this.modalService.close();
      this.modalService.open(ModalUnemploymentWithdrawalRequestComponent, this.optionsUnem);
    }
  }
  patrimonialValidate(item: AccountModel){
    if((this.cardAddresseeService.selectedCardAddressee.tipoCuenta === AccountType.PAT ||
      this.cardAddresseeService.selectedCardAddressee.tipoCuenta === AccountTypeMax.PATMX ) &&
      this.cardAddresseeService.selectedCardAddressee.cuentaDestino === item.numeroCuenta && item.category === AccountType.Eje){
      this.cardAccountArray.push(this.setAccountDetails(item));
    } else if ((this.cardAddresseeService.selectedCardAddressee.tipoCuenta !== AccountType.PAT &&
      this.cardAddresseeService.selectedCardAddressee.tipoCuenta !== AccountTypeMax.PATMX) &&
      this.cardAddresseeService.selectedCardAddressee.idCuentaProductoDestino !== item.idCuentaProducto && this.canAccountTransaction(item)) {
      this.cardAccountArray.push(this.setAccountDetails(item));
    }
  }
  private async creditCards(){
    try {
      if (this.getCreditCards || this.onlyCreditCards) {
        const creditCardIFM: CheckCreditCardResponse = await this.productService.getCreditCards(false, this.filterCreditCards);
        if (creditCardIFM) {
          this.getCreditCardsService(creditCardIFM);
        }
      }
    } catch (error) {
      this.accountCardService.setModalAccountOpen = false;
      return Utils.getErrorMsg(error);
    }
    return null;
  }
   getCreditCardsService(data:CheckCreditCardResponse){
    if (data?.TDC?.length > Length.Empty) {
      data.TDC.forEach(item => {
        item.CardFrontImageUrl = item.CardFrontImageUrl.replace(Resources.ASSETS_IMAGES_OSB,Strings.EMPTY);
        if (item.CardFrontImageUrl == null || item.CardFrontImageUrl.indexOf(Resources.NULL_IMAGE_URL) !== Constants.NOT_FOUND_ITEM) {
          item.CardFrontImageUrl = Resources.ASSETS_GENERIC_CREDIT_CARD();
        }
        if((item.CardNumber.length !== Numbers.Fifteen && this.filterAmexAccount) || !this.filterAmexAccount){
          this.cardAccountArray.push({
            cardNumber : item.CardNumber,
            cardType: item.TipoCuenta?.toUpperCase(),
            image: item.CardFrontImageUrl,
            clabe: item.CuentaClabe,
            balance: item.SaldoActual.toString(),
            source: item.Source,
            accountNumber: item.CardNumber,
            productId: Number(item.CustomerId),
            expirationDate: item.ExpirationDate,
            account: item.Account
          });
        }
        this.paginationService.setInitialData(Utils.listMajorToMinor(this.cardAccountArray, Constants.PAGINATION), this.pageSize);
      });
    }
  }
  private async validateAccounts(){
    try {
      if (this.onlyCreditCards) {
        await this.getOnlyCreditCards();
        return;
      }

      if (this.directDebitServicesService.isDirectDebit){
        await this.associatedCards();
        return;
      }
        const accountsError = await this.checkingAccount();
        const creditCardsError =  await this.creditCards();

      if ((accountsError && !this.getCreditCards) || (accountsError && creditCardsError)) {
        throw new BusinessError(accountsError?.msg || creditCardsError?.msg, accountsError?.code || creditCardsError?.code);
      }
      } catch (error) {
        this.onValidareError(error);
      }
   }

   private async getOnlyCreditCards(){
    const result = await this.creditCards();
    if(result) {
      throw new BusinessError(result.msg, result.code);
    }

    if (this.filterCreditCards && this.cardAccountArray.length === Length.Empty){
      throw new BusinessError(DomiciliationStrings.WITHOUT_PRODUCTS, Constants.UNEXPECTED_CODE);
    }
  }

   private onValidareError(error) {
    this.modal?.dismiss();
    this.accountCardService.setModalAccountOpen = false;
    if (this.aforeService.aforeInformation) {
      this.modalInstance?.close();
      this.aforeService.errorAforeService = false;
      }
    const errorDetails = Utils.getErrorMsg(error);
    this.alertService.showPopupAlert({
      message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
      header: Strings.MSG_POPUP_TITLE,
      imgHead: Resources.CLOSE_ICON_RED,
      btnLabel: Strings.MSG_POPUP_ACCEPT,
      btnExit: false
    });
   }

  private async associatedCards(){
    try {
      const cards = await this.directDebitServicesService.getCardsDirectDebitService(new GetCardsDirectDebitRequest({ numeroCuenta: this.accountInfo.numeroCuenta }));
      cards.datos.forEach(card=>{
        this.cardAccountArray.push({
          image: this.accountInfo.directorioImagen,
          cardNumber: card.numeroTarjeta,
          clabe: this.accountInfo.cuentaClabe,
          balance: this.accountInfo.saldos?.disponible?.toString(),
          selected: false,
          productId: card.idInventarioTarjeta,
          accountNumber: this.accountInfo.numeroCuenta,
          accountNumberExpress: this.accountInfo.numeroCuenta,
          cardType: this.accountInfo.descripcionCuenta,
          currency: this.accountInfo.currency,
          accountType: this.accountInfo.tipoCuenta
        });
      });
      this.paginationService.setInitialData(Utils.listMajorToMinor(this.cardAccountArray, Constants.PAGINATION), this.pageSize);
    } catch(error){
      this.modal.dismiss();
      this.accountCardService.setModalAccountOpen = false;
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg,errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        imgHead: Resources.CLOSE_ICON_RED,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        btnExit: false
      },{
        onSuccess:() => {
          this.router.navigate([PATH.Domiciliation]);
        }
      });
    }
  }

  showWebAccount(product: AccountModel) {
    if (this.hiddenWebAccount(product)) {
      return false;
    }
    const accountType = this.cardAddresseeService.selectedCardAddressee.tipoCuenta;
    return !this.cardAddresseeService.selectedCardAddressee?.idCuentaProductoDestino ||
      (accountType === AccountType.WEB && product.tipoCuenta === AccountType.NOM) ||
      (accountType !== AccountType.WEB && product.tipoCuenta !== AccountType.WEB) ||
      (this.cardAddresseeService.selectedCardAddressee.category === Constants.ACCOUNT_CATEGORY && product.tipoCuenta === AccountType.WEB);
  }
  hiddenWebAccount(product: AccountModel) {
    const accountCode = this.cardAddresseeService.selectedCardAddressee.type;
    return ((product.tipoCuenta === AccountType.WEB && this.router.url !== PATH.Transfers) ||
      (accountCode !== null && accountCode !== AddresseTabs.OwnAccounts && product.tipoCuenta === AccountType.WEB) ||
      (this.expressTransferService.isExpressTransfer && product.tipoCuenta === AccountType.WEB));
  }
  canAccountTransaction(item: AccountModel): boolean {
    return item.tipoCuenta !== AccountType.GroceryCoupon && this.isCt(item) &&
      item.tipoCuenta !== AccountType.CODEFLEX91 &&
      item.tipoCuenta !== AccountType.CODEFLEX && item.tipoCuenta !== AccountType.CODE91 && this.showWebAccount(item);
  }
  isCt(item: AccountModel) {
    return (item.tipoCuenta !== AccountType.PAT && item.tipoCuenta !== AccountTypeMax.PATMX ) ||
    ((item.tipoCuenta === AccountType.PAT || item.tipoCuenta === AccountTypeMax.PATMX ) && this.router.url === PATH.TransferData);
  }
  investment(item: AccountModel) {
    if (this.cardAddresseeService.selectedCardAddressee.clabe !== item.cuentaClabe &&
       ProductUtils.investmentAccountFilter(item)) {
      this.cardAccountArray.push(this.setAccountDetails(item));
    }
  }
  payrollAccount(item: AccountModel) {
    if (this.cardAddresseeService.selectedCardAddressee.clabe !== item.cuentaClabe &&
      item.tipoCuenta === AccountType.NOM) {
      this.cardAccountArray.push(this.setAccountDetails(item));
    }
  }
  filterAccounts(item: AccountModel) {
    if (this.filter.some(element => item.tipoCuenta === element)){
      this.cardAccountArray.push(this.setAccountDetails(item));
    }
  }
  filterSkipAccounts(item: AccountModel) {
    if (!this.filterSkip.some(element => item.tipoCuenta === element)){
      this.cardAccountArray.push(this.setAccountDetails(item));
    }
  }
  private setAccountDetails(item: AccountModel) {
    return {
      image: item.directorioImagen,
      cardNumber: item.numeroCuenta,
      clabe: item.cuentaClabe,
      balance: item.saldos?.disponible?.toString(),
      selected: false,
      productId: item.idCuentaProducto,
      accountNumber: item.numeroCuenta,
      accountNumberExpress: item.numeroCuenta,
      cardType: item.descripcionCuenta,
      currency: item.currency,
      accountType: item.tipoCuenta
    };
  }
  setSearch(value: string) {
    this.paginationService.search(this.search, value);
  }
  search(data: ICardAccount[], value: string) {
    value = value.toUpperCase();
    if (value === Strings.EMPTY) return data;
    return data.filter(
      (information) =>
        information.cardType?.toUpperCase().includes(value ?? Strings.EMPTY) ||
        information.clabe?.includes(value ?? Strings.EMPTY) ||
        information.cardNumber?.includes(value ?? Strings.EMPTY) ||
        information.balance?.includes(value ?? Strings.EMPTY) ||
        information.accountNumber?.includes(value ?? Strings.EMPTY)
    );
  }
  async consultSubAccounts(account: string) {
    const request = new ConsultSubAccountsRequest({
      IdSession: this.storageService.getSession(),
      Cuenta: account
    });
    try {
      const response =
        await this.baseService.genericPost<ConsultSubAccountsResponse>(request, {
          isSOA: true
        });
      this.investmentRegisterService.investment = response;
      this.investmentRegisterService.accountNumber = {
        NumeroCuenta: account,
        Saldo: { Moneda: this.cardSelected.currency },
        IdCuentaProducto: this.cardSelected.productId
      };
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      });
      this.router.navigate([PATH.Investment]);
      this.accountCardService.clearCardAccount();
      this.investmentRegisterService.clearInvestmentObservable();
    }
  }
  close() {
    this.accountCardService.setModalAccountOpen = false;
    if (this.aforeSelectCard && this.notifyRetirementType.retirementTypeBs.value !== RetirementType.total && this.notifyRetirementType.getIsAfore) {
      this.modalService.close();
      const modalOptions: ModalOptions = {
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeLg,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal
      };
      this.modal = this.modalService.open(ModalEndProcessAlertComponent, this.options);
        this.modal.result.then((e) => {
          this.notifyRetirementType.setIsAfore = true;
          this.modalService.open(CardModalComponent, modalOptions);
      });
    } else {
      this.modalService.close();
    }
    this.aforeSelectCard = false;
  }
  ngOnDestroy() {
    this.notifyRetirementType.setIsAfore = false;
    ProductConstants.ORIGIN_CONSULT_DATA = ProductConstants.ORIGIN_CONSULT.BackHome;
  }


  @HostListener('window:popstate')
  onBack() {
    this.modalInstance?.close();
    if(this.aforeService.aforeInformation){
      this.accountCardService.clearCardAccount();
      this.notifyAmountService.amount = { amount: Strings.EMPTY };
      this.aforeService.aforeInformation = null;
    }
  }
}
