<ng-container *ngIf="vm$ | async as vm">
  <ng-container>
    <content-wrapper [contentMargin]="contentMargin" [useFlex]="!vm.breakpoints.mobile">
      <div content [class.mb-2rem]="!vm.breakpoints.mobile">
        <ng-content *ngTemplateOutlet="statusProcess === 'confirm' ? confirmation:
        statusProcess  === 'error'? screenError : complete"></ng-content>
      </div>
    </content-wrapper>
  </ng-container>

  <ng-template #confirmation>
  <ng-container *ngIf="this.dynamicFormService.formBS.value">
    <app-header-transfer-card [title]="title" [subtitle]="subtitle" [urlLocation]="pathWebCardRequest"
      (onActionClick)="modify()">
    </app-header-transfer-card>
    <basic-grid-template [classes]="'confirm-data-grid'">
      <div id="first-col">
        <label class="header-xs from-account">{{sourceAccount}}</label>
        <div class="d-flex align-items-center imgCardAccount img-card-account">
          <img [src]="vm.selectedAccount?.directorioImagen" alt="Bank Card"
            width="auto" height="auto" class="ms-3 mt-2">
          <div class="ml-32">
            <label class="body-s custom-color-text web-card-account-confirm">
              {{ vm.selectedAccount?.numeroCuenta | maskAccount: 4 }}
            </label>
            <label class="detail-s col-12 web-card-account-confirm">{{vm.selectedAccount?.tipoCuenta}}</label>
          </div>
        </div>
        <hr class="mb-3 mt-1-69">
        <label class="header-xs mb-2">{{applicantInformation}}</label>
        <div class="">
          <text-ellipsis [text]="dynamicFormService.formBS.value.controls[firstName].value" classes="body-s col-12"
          [lines]="numberOfLines" [styles]="styles">
          </text-ellipsis>
          <label class="col-12 detail-s">{{firstNameLabel}}</label>
          <hr class="mt-2 mb-0">
          <ng-container *ngIf="dynamicFormService.formBS.value.controls[secondName].value">
            <text-ellipsis [text]="dynamicFormService.formBS.value.controls[secondName].value"
            classes="body-s col-12 mt-2" [lines]="numberOfLines" [styles]="styles"></text-ellipsis>
            <label class="col-12 detail-s">{{secondNameLabel}}</label>
            <hr class="mt-2 mb-0">
          </ng-container>
        </div>
      </div>
      <div id="second-col" [class.mt-0-75]="vm.breakpoints.mobile">
        <div class="">
          <ng-container *ngIf="dynamicFormService.formBS.value.controls[lastName].value">
            <text-ellipsis [text]="dynamicFormService.formBS.value.controls[lastName].value" classes="body-s col-12"
            [lines]="numberOfLines" [styles]="styles"></text-ellipsis>
            <label class="col-12 detail-s">{{lastNameLabel}}</label>
            <hr class="mt-2 mb-0">
          </ng-container>
          <ng-container *ngIf="dynamicFormService.formBS.value.controls[secondLastName].value">
            <text-ellipsis [text]="dynamicFormService.formBS.value.controls[secondLastName].value"
            classes="body-s col-12 mt-2" [lines]="numberOfLines" [styles]="styles"></text-ellipsis>
            <label class="col-12 detail-s">{{secondLastNameLabel}}</label>
            <hr class="mt-2 mb-0">
          </ng-container>
          <label class="col-12 body-s mt-2">{{dynamicFormService.formBS.value.controls[gender].value}}</label>
          <label class="col-12 detail-s">{{genderLabel}} </label>
          <hr class="mt-2 mb-0">
          <label class="detail-m informative-message">
            {{cardUsageMessage}}
          </label>
        </div>
      </div>
      <div class="btn-container">
        <app-template-primary-button [ngClass]="!vm.breakpoints.mobile ? 'custom-width' : '' "
        [properties]="btnContinue" [showIcon]="true">
          {{btnConfirm}}
        </app-template-primary-button>
        <app-template-secondary-button [ngClass]="!vm.breakpoints.mobile ? 'custom-width' : '' "
        [properties]="btnModify">
          {{btnModifyLabel}}
        </app-template-secondary-button>
      </div>
    </basic-grid-template>
  </ng-container>
  </ng-template>

  <ng-template #screenError>
    <app-error-exception [showButtons]="true" [otherOperation]="pathWebCardRequest" [errorMessage]="errorMessage">
    </app-error-exception>
  </ng-template>

  <ng-template #complete>
    <div id="saveImageContainer" #saveImageContainer class="canvas p-3 saveImageContainer" 
      *ngIf="this.dynamicFormService.formBS.value">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img id="ignoreElement" [src]="checkFill | cdnImage" class="icon-check" alt="" width="auto" height="auto">
        <div class="pt-3 titulo-transfer header-l">{{successfulRequest}}</div>
        <div class="my-2 subtitulo-transfer body-m">{{folioLabel}} {{folio}}</div>
        <div class="pb-3 detail-m">
          {{completeDate}}h
        </div>
        <div *ngIf="!webCardDownload">
          <app-shared-buttons id="ignoreElement"  [captureImage]="captureImage" [shareCapture]="shareCapture"
          [hideMail]="true" [webCard]="true" [hideDownload]="false" >
          </app-shared-buttons>
        </div>
      </div>
      <basic-grid-template [classes]="'complete-data-grid'">
        <div id="first-col">
          <label class="header-xs mb-4">{{sourceAccount}}</label>
          <div class="d-flex align-items-center imgCardAccount img-card-account ">
            <img id="ignoreElement" [src]="vm.selectedAccount?.directorioImagen" alt="Bank Card"
              width="auto" height="auto"
              class="ms-3 mt-2">
            <div class="ml-32">
              <label class="body-s custom-color-text">{{vm.selectedAccount?.numeroCuenta | maskAccount: 4 }}</label>
              <label class="detail-s col-12">{{vm.selectedAccount?.tipoCuenta}}</label>
            </div>
          </div>
          <hr class="mb-3 mt-1-69">
          <label class="header-xs mb-10">{{applicantInformation}}</label>
          <text-ellipsis [text]="dynamicFormService.formBS.value.controls[firstName].value"
          classes="body-s col-12" [lines]="numberOfLines" [styles]="styles"></text-ellipsis>
          <label class="col-12 detail-s">{{firstNameLabel}}</label>
          <hr class="mt-2 mb-0">
          <ng-container *ngIf="dynamicFormService.formBS.value.controls[secondName].value">
            <text-ellipsis [text]="dynamicFormService.formBS.value.controls[secondName].value"
            classes="body-s col-12 mt-10" [lines]="numberOfLines" [styles]="styles"></text-ellipsis>
            <label class="col-12 detail-s">{{secondNameLabel}}</label>
            <hr class="mt-2 mb-0">
          </ng-container>
        </div>
        <div id="second-col">
          <ng-container *ngIf="dynamicFormService.formBS.value.controls[lastName].value">
            <text-ellipsis [text]="dynamicFormService.formBS.value.controls[lastName].value" classes="body-s col-12"
            [lines]="numberOfLines" [styles]="styles"></text-ellipsis>
            <label class="col-12 detail-s">{{lastNameLabel}}</label>
            <hr class="mt-2 mb-0">
          </ng-container>
          <ng-container *ngIf="dynamicFormService.formBS.value.controls[secondLastName].value">
            <text-ellipsis [text]="dynamicFormService.formBS.value.controls[secondLastName].value"
            classes="body-s col-12 mt-10" [lines]="numberOfLines" [styles]="styles"></text-ellipsis>
            <label class="col-12 detail-s">{{secondLastNameLabel}}</label>
            <hr class="mt-2 mb-0">
          </ng-container>
          <label class="col-12 body-s mt-10">{{dynamicFormService.formBS.value.controls[gender].value}}</label>
          <label class="col-12 detail-s">{{genderLabel}} </label>
          <hr class="mt-2 mb-3">
        </div>
        <div class="message-information text-center" [ngClass]="vm.breakpoints.mobile ? 'body-s':'body-m'">
          {{infoMessage}}
        </div>
        <div *ngIf="!vm.breakpoints.mobile" class="btn-container btn-container-complete hide-buttons">
          <app-template-primary-button class="custom-width" [properties]="btnDone">
            {{btnReady}}
          </app-template-primary-button>
        </div>
      </basic-grid-template>
    </div>
    <div *ngIf="vm.breakpoints.mobile" class="btn-container btn-container-complete hide-buttons">
      <app-template-primary-button [properties]="btnDone">
        {{btnReady}}
      </app-template-primary-button>
    </div>
  </ng-template>
</ng-container>
