export const environment = {
  production: true,
  OsbMongoServices: 1,
  emailMsEnabled: false,
  globalPathname: '/inbursabanco',
  HostCdn: 'https://inbcdnappimages.azureedge.net/app-assets',
  hostPayrollCredit: 'https://serviciosgfi.inbursa.com:4538',
  hostSliderPromotions: 'https://inbursaapp.inbursa.com:2224',
  pathOnlyForYou: '/SliderNativa/Home/Index/',	
  storageKey: '7790bb3d-8d9a-4236-8e7f-3ec58d6ae4f2',
  limitCT: 100000,
  beta: false,
  policyPaymentInterestFreeMonths: true,
  playAudio: false,
  minCreditCardAmount: 300,
  urlInsuranceExpress : '',
  legendTransferFee: 'Comisión: $4.50 + IVA',
  replaceServiceImagePath: true,
  amexCards:[
  'Inbursa Black American Express',
  'Inbursa Óptima'
  ],
   hiddenComponents: {
    downloadMovementsInvestmentCompanies: true,
    alertFormInvestmentCompanies: true,
    history: true,
    programmedOperations: false,
    clarifications: false,
    downloadTransactionHistory: true,
    webCardDownload: false,
    financialHealth: true,
    investmentFunds: false,
    expressLife: true,
    directDebit: false,
    tabInvestmentCompanies: false,
    betaChecks: false,
    paymentSat: false,
    historyCardService: true,
    controlCardBeta: true,
    domiciliationRegistration: false,
    federalTaxesHistory: false,
    letterDirectDebit: true,
    transferFee: true,
    payrollAdvance: false,
    payrollCredit: false,
    associatedCards: true,
    visibilityAccountBalance: false,
    afore:{  
    voluntarySavingsContribution: false,
    voluntaryWithdrawal: true,
    retirementPartial: true,
    voluntaryRetirement: true,
    directDebitAfore: false,
    editDirectDebitAfore: true,
    limitAlertDirectDebit: true,
    weekRefound: true,
    totalWithdrawals: true 
  		},
    insurance : {
    paymentPoliciesMSI: true,
    internationalTransfersLetter: true,
    claimTracking: true,
    requestAssistance: false,
    assistanceGmm:true, 
    assistanceHabitt:false,
    assistanceCar:false
    
    },
    federalTaxPaymentByConcept: false,
    betaChecksCancelation: false,
    betaChecksProtection: false,
    betaChecksActivation: false,
    betaChecksSelection: false,
    betaChecksImage: false,
    activatedCard: true,
    investmentHomePromotions: false,
    brokerageHouse: false,
    cancelCardBeta: false,   
    variableInvestmentFunds: true,	   
    homeCodi: true,
    homeDimo: true,
    expressLifePwa:true,
    digitalChecks: true,
    monthlyLimitCredit: false,
    visibilityAccountBalanceModal: true,
    curpAdditionalCreditCard: false,
    activateJustForYouSliderTablet: true,
    activateJustForYouRewards: true,
    activateJustForYouSliderPromotions: true,
    activateJustForYouPromotions: true,
    monthlyLimitDebit: false,
    assistanceInsurance: false,
    isHiddenTransferDebt: false
  },
    resources: {
    	banxico: 'https://www.banxico.org.mx/'
  	},
        ElementsPerPage: {
		Agendas: {
			small: 4, //max-width: 767.98px
			medium: 6, //max-width: 1349px
			large: 12, //max-width: 1600px
			extraLarge: 15 //max-width: 1710px
		},
	registeredServices: {
			forceElements: false,
			mobileElements: 4
		}
	},
   facepass: {
		qrFacepassAttempts: 240,
		qrFacepassTimer: 500
			},
  NonAgendableService: {
    TELMEX: {
      id: 1,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    CARNET: {
      id: 2,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    SKY: {
      id: 3,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    AMEX: {
      id: 4,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    MIXUP: {
      id: 5,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    SANBORN: {
      id: 6,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    AMITELC: {
      id: 7,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    CONTELC: {
      id: 8,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    TCINB: {
      id: 9,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    PAGLIVER: {
      id: 10,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    PAGOPH: {
      id: 11,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    IAVE: {
      id: 12,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    CFE: {
      id: 13,
      referenceLength: 30,
      showBarcodeScanner: false
    },
    IMPLOC: {
      id: 14,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    SIPARE: {
      id: 15,
      referenceLength: 53,
      showBarcodeScanner: true
    },
    PAGSEARS: {
      id: 16,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    GASNATUR: {
      id: 17,
      referenceLength: 28,
      showBarcodeScanner: false
    },
    IMPGEM: {
      id: 18,
      referenceLength: 27,
      showBarcodeScanner: true
    },
    SAKS: {
      id: 19,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    IMPLOCTDC: {
      id: 20,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    PAGDEDU: {
      id: 21,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    IMPFEDR: {
      id: 22,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    PSUAARCH: {
      id: 23,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    IMPFED: {
      id: 24,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    PSEGNAL: {
      id: 25,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    GASNA: {
      id: 26,
      referenceLength: 10,
      showBarcodeScanner: true
    },
    COMEXT: {
      id:27,
      referenceLength:10,
      showBarcodeScanner: true
    },
    PAGGMM: {
      id:28,
      referenceLength:10,
      showBarcodeScanner: true
    }
  }
};

export const services = {
  Environment : '',
  EnvironmentDev: '',
  Version : '40.0.0',
  CdnServices : '/app-assets/services-pay/',
  CdnAccounts : '/app-assets/accounts/',
  LoginOrq : '/inbursa/v1.0/appweb/accesos/orq/login',
  Logout : '/inbursa-sii/app-movil/v1/sesion/cerrar',
  ValidateOTP : '/inbursa/v1.0/appweb/accesos/orq/token',
  KeyValidateMF2 : '/inbursa/v1.0/appweb/accesos/orq/claves/banco',
  GetCheckAccounts :'/inbursa-sii/app-movil/v2/cuentas/cheques',
  StockProducts :'/inbursa-sii/app-movil/productos/existencias',
  GetFacepassTransaction : '/inbursa/v1.0/facepass/facectes/autenticaciones/portal/transacciones/get',
  DebitCardMovements : '/inbursa-sii/app-movil/v2/movimientos/debito/consultar',
  CustomerCards : '/inbursa-sii/app-movil/v1/tarjetas/getCustomerCards',
  PrintStatus : '/inbursa-sii/app-movil/v1/estadoscuenta/impresiones/estatus/get',
  SetPrintStatus : '/inbursa-sii/app-movil/v1/estadoscuenta/suspension/envio/edc',
  StatementsAccount :'/inbursa-sii/app-movil/v1/estadoscuenta/periodos/consultar',
  StatementsAccountPDF :'/inbursa-sii/app-movil/v1/estadoscuenta/pdf/consultar',
  ValidateBank : '/inbursa-sii/app-movil/v1/agendas/validaciones/tipoAgenda',
  AddNationalAgenda : '/inbursa-sii/app-movil/v1/agendas/nacionales/agregar',
  NationalTransfer :'/inbursa-sii/app-movil/v1/pagosyservicio/transferencianacional/realizar',
  TransactionReceiptPDF :'/inbursa-sii/app-movil/v1/movimientos/debito/comprobante/pdf',
  TransferThirdParty :'/inbursa-sii/app-movil/v1/pagosyservicio/terceros/realizar',
  PayService : '/inbursa-sii/app-movil/v1/pagosyservicio/servicio/pagar',
  UserInfo : '/inbursa-sii/app-movil/v1/tarjadic/consultas/userinfo',
  ConsultAgenda : '/inbursa-sii/app-movil/v1/agendas/nacionales/consultar',
  InsurancePolicy : '/inbursa-sii/app-movil/v1/seguros/polizas',
  InsurancePolicyInfo : '/inbursa-sii/app-movil/v1/seguros/polizas/info',
  AccessConsultation : '/inbursa/v1.0/appweb/historiales/accesos/agrupados/get',
  Afore : '/inbursa-sii/app-movil/v1/afore/saldos',
  GetMovementDetail : '/inbursa-sii/app-movil/v1/movimientos/detalles/consultar',
  TokenOverview : '/inbursa-sii/app-movil/v1/token/acceso/tokensHistory',
  FacepassOverview : '/inbursa-sii/app-movil/v1/facepass/consultas/dispositivos',
  ReactiveSession :'/inbursa-sii/app-movil/v1/sesion/actualizar',
  PaymentScheduledServices :'inbursa-sii/app-movil/v1/agendas/pagservicios/consultas/agendables/portal',
  PaymentNoScheduledServices : '/inbursa-sii/app-movil/v1/agendas/pagservicios/consultas/noagendables/portal',
  AddThirdPartyAgenda :'/inbursa-sii/app-movil/v1/agendas/terceros/agregar',
  GetThirdAgendas : '/inbursa-sii/app-movil/v1/agendas/terceros/consultar',
  CancelToken : '/inbursa-sii/app-movil/v1/token/registro/cancelarToken',
  CancelFacepass : '/inbursa-sii/app-movil/v1/facepass/registro/actualizaDispositivos',
  TransferOwnAccounts :'/inbursa-sii/app-movil/v1/pagosyservicio/cuentaspropias/traspasar',
  SendMessageMS : '/inbursa/v1.0/appweb/correos',
  SendMessage : '/inbursa-sii/app-movil/v1/portal/email/send',
  PinModification :'/inbursa-sii/app-movil/v1/tarjetadebito/cambiarnip',
  ValidateCaptureLine : '/inbursa-sii/app-movil/v1/validacionespagos/lineaCaptura/{0}/{1}/{2}',
  GetDataCard :'/inbursa-sii/app-movil/v1/tarjetas/getDataCard',
  GetLocksAndLimits :'/inbursa-sii/app-movil/v1/tarjetas/getLocksAndLimits',
  UpdateLockLimitsCard :'/inbursa-sii/app-movil/v1/wallet/operacion/{0}/{1}/UPDATEPARAMETER/{2}',
  LockGeneralCard :'/inbursa-sii/app-movil/v1/wallet/consulta/{0}/{1}/CARD/{2}',
  ValidPayEdoMex : '/inbursa-sii/app-movil/v1/validacionespagos/lineaCaptura/edomex/{0}/{1}/{2}/{3}',
  PayEdoMex : '/inbursa-sii/app-movil/v1/pagoimpuestos/edomex',
  ReceiptEdoMex : '/inbursa-sii/app-movil/v1/consultasimpuestos/edomex/recibo/{0}/{1}',
  LocalTaxesCDMX :'/inbursa-sii/app-movil/v1/pagoimpuestos/impuestolocalcdmx',
  ReceiptCDMX : '/inbursa-sii/app-movil/v1/consultasimpuestos/recibo/{0}/{1}/{2}',
  GetCredits : '/inbursa-sii/app-movil/v2/cuentas/creditos',
  GetCreditAmortization : '/inbursa-sii/app-movil/v1/creditos/consultas/get/amortizaciones',
  CreditDetails : '/inbursa-sii/app-movil/v1/creditos/consultas/detalleCredito',
  ServiceDetails : '/inbursa/v1.0/appweb/catalogos/servicios/{0}',
  GetInternationalAgendas : '/inbursa-sii/app-movil/v1/agendas/internacionales/consulta/AgendaInternacional',
  InsuranceReceipts : '/inbursa-sii/app-movil/v1/seguros/recibos',
  PayPolicies : '/inbursa-sii/app-movil/v1/seguros/pagos/cargos/recibos',
  ModifyAgentService : '/inbursa-sii/app-movil/v1/agendas/pagservicios/modificar/Agendables',
  CreditPayment : '/inbursa-sii/app-movil/v1/creditos/pagos/realizarPago',
  ModifyInternationalAgendas : '/inbursa-sii/app-movil/v1/agendas/internacionales/modificacion/AgendaInternacional',
  ModifyOthersBanks : '/inbursa-sii/app-movil/v1/agendas/nacionales/modificar',
  CancelInternationalAgendas :'/inbursa-sii/app-movil/v1/agendas/internacionales/cancelacion/AgendaInternacional',
  CancelAgendasService : '/inbursa-sii/app-movil/v1/mf2/agendas/pagosservicios/cancelar',
  UserImage : '/inbursa/v1.0/appweb/imagenes/usuarios/get',
  SaveUserImage : '/inbursa/v1.0/appweb/imagenes/usuarios',
  UpdateUserImage : '/inbursa/v1.0/appweb/imagenes/usuarios/put',
  GetUserAccounts : '/inbursa/v1.0/appweb/productos/orq',
  NewInternationalAddress : '/inbursa-sii/app-movil/v1/agendas/internacionales/alta/AgendaInternacional',
  VerifyBIC : '/inbursa-sii/app-movil/v1/agendas/internacionales/consulta/bic',
  RegisterCVV : '/inbursa-sii/app-movil/v1/wallet/registro/{0}/{1}/REGISTERCARD/{2}/',
  DynamicCVV : '/inbursa-sii/app-movil/v1/wallet/operacion/{0}/{1}/DCVV2/{2}/',
  VerifyAccounts :'/inbursa/v1.0/appweb/productos/orq/existencias',
  AddPayService : '/inbursa-sii/app-movil/v1/agendas/pagservicios/agregar',
  GetExchangeRate :'/inbursa-sii/app-movil/v1/agendas/internacionales/consulta/tiposCambio',
  InternationalTransfer :'/inbursa-sii/app-movil/v1/agendas/internacionales/operacion/AgendaInternacional',
  CancelOthersBanks : '/inbursa-sii/app-movil/v1/mf2/agendas/transferencias/cancelar',
  ConsultSubAccounts : '/inbursa-sii/app-movil/v1/pagares/tipos/subcuentas',
  ConsultPerformance : '/inbursa-sii/app-movil/v1/pagares/rendimientos/inversiones',
  ModifyThirdAgenda : '/inbursa-sii/app-movil/v1/agendas/terceros/modificar',
  CancelThirdAgenda : '/inbursa-sii/app-movil/v1/mf2/agendas/pagosservicios/cancelar',
  DepositAppliesPromissoryNote : '/inbursa-sii/app-movil/v1/pagares/apertura',
  GetPDFPromissoryNote : '/inbursa-sii/app-movil/v1/pagares/obtener/caratula',
  CheckAccountLimitCT :'/inbursa-sii/app-movil/v1/cuentasv2/limiteCTS',
  ModifyLimitCTS :'/inbursa-sii/app-movil/v1/cuentasmodificar/limiteCTS',
  GetBeneficiaries : '/inbursa/v1.0/appweb/cuentas/beneficiarios/get',
  InvestmentModification : '/inbursa-sii/app-movil/v1/pagares/',
  CheckCreditCard : '/inbursa-sii/app-movil/v2/cuentas/infinitus',
  ConsultInvestments :'/inbursa-sii/app-movil/v1/pagares/detalle/obtenerDetallePagare',
  ConsultPoints : '/inbursa-sii/app-movil/v1/promociones/obtenerPuntos',
  ConsultRetained : '/inbursa-sii/app-movil/v1/movimientos/retenciones/consultar',
  ConsultRedemptions : '/inbursa-sii/app-movil/v1/promociones/listadoRedenciones',
  RedemptionMovements : '/inbursa-sii/app-movil/v1/promociones/obtenerMovimientos',
  ConsultPDFCreditCard : '/inbursa-sii/app-movil/v1/estadoscuenta/{0}/{1}/GETPDFEDC/{2}',
  CreditCardMovements : '/inbursa-sii/app-movil/v1/tarjetas/periodos/balancesAndMovements',
  RedemptionPoints : '/inbursa-sii/app-movil/v1/promociones/redencionPuntos',
  ConsultPrograms : '/inbursa-sii/app-movil/v1/promociones/catalogoProgramas',
  RegisterAccountPoints : '/inbursa-sii/app-movil/v1/promociones/altaCuentaPuntos',
  ConsultDeductibleMedicalExpenses: '/inbursa/v1.0/appweb/siniestros/orq/deducibles/gmm',
  PayDeductibleMedicalExpenses: '/inbursa/v1.0/appweb/siniestros/orq/deducibles/pagos',
  TotalNotifications : '/inbursa-sii/app-movil/v1/notificaciones/push/consulta/conteo/mensajes',
  NotificationInformation : '/inbursa-sii/app-movil/v1/notificaciones/push/consulta/mensajes',
  UpdateNotifications : '/inbursa-sii/app-movil/v1/notificaciones/push/registro/marca/mensajes',
  PortabilityPayroll : '/inbursa/v1.0/appweb/nominas/orq/portabilidades/solicitudes',
  SipareCaptureLine: '/inbursa/v1.0/appweb/sipare/orq/lineas/validaciones',
  ConsultInsurancePolicyCover : 'inbursa-sii/app-movil/v1/seguros/polizas/pdf',
  GetPortability :     '/inbursa/v1.0/appweb/nominas/orq/portabilidades/solicitudes/get',
  SipareServicePayment: '/inbursa/v1.0/appweb/sipare/orq/pagos',
  ValidateClaimNumber: '/inbursa/v1.0/appweb/siniestros/orq/deducibles/autos',
  ClaimPayment: '/inbursa/v1.0/appweb/siniestros/orq/deducibles/pagos',
  ClarificationsFiles: 'inbursa-sii/app-movil/v1/aclaraciones/consultas/expedientes',
  ClarificationFileDetails: 'inbursa-sii/app-movil/v1/aclaraciones/consultas/detalle',
  CancelPortability :  '/inbursa/v1.0/appweb/nominas/orq/portabilidades/solicitudes/put',
  GetSipareReceipt: '/inbursa/v1.0/appweb/sipare/orq/comprobantes/get',
  GetSiparePayments: '/inbursa/v1.0/appweb/sipare/orq/pagos/get',
  PayAdditionalContributions: '/inbursa/v1.0/appweb/aportaciones/orq/adicionales/vida',
  GetScheduledPayments:'/inbursa/v1.0/appweb/pagos/programados',
  CancelScheduledPayment: '/inbursa/v1.0/appweb/pagos/programados/cancelaciones',
  EquivalentPoints: '/inbursa-sii/app-movil/v1/promociones/puntosgfi',
  DeleteUserImage : '/inbursa/v1.0/appweb/imagenes/usuarios/delete',
  InvestmentPurchase: '/inbursa/v1.0/appweb/sociedades/inversiones/compras',
  InvestmentSale: '/inbursa/v1.0/appweb/sociedades/inversiones/ventas',
  GetInvestmentAccounts: '/inbursa/v1.0/appweb/sociedades/inversiones/cuentas',
  ModifyAgentServiceNonAgendable: '/inbursa-sii/app-movil/v1/agendas/pagservicios/modificar/NoAgendables',
  PendingWithdrawalsCodeFlex: '/inbursa/v1.0/appweb/codeflex/retiros/pendientes/get',
  CancellationWithdrawalsCodeFlex: '/inbursa/v1.0/appweb/codeflex/retiros/cancelaciones',
  WithdrawalsCodeFlex:'/inbursa/v1.0/appweb/codeflex/retiros',
  GetTransactionHistory: '/inbursa-sii/app-movil/v1/estadoscuenta/movimientos/pdf',
  WebCardApplication: '/inbursa/v1.0/appweb/tarjetas/orq/web/solicitudes',
  MonthlyPromotions: '/inbursa-sii/app-movil/v1/seguros/pagos/promociones/meses',
  GetTransactionHistoryXls: '/inbursa-sii/app-movil/v1/estadoscuenta/movimientos/excel',
  PayPoliciesIFM:'/inbursa-sii/app-movil/v1/seguros/pagos/cargos/recibos/msi',
  CreateAdditionalUser: '/inbursa-sii/app-movil/v1/tarjadic/reg/createAditionalUser',
  GetAdditionalCards: '/inbursa-sii/app-movil/v1/tarjadic/consultas/getAditionalCards',
  UpdateAdditionalCards: '/inbursa-sii/app-movil/v1/tarjadic/reg/updateAditionalCards',
  GetAdditionalCardsByUser: '/inbursa-sii/app-movil/v1/tarjadic/consultas/getAditionalCardsByUser',
  ValidateUserCreation: '/inbursa-sii/app-movil/v1/tarjadic/valida/validateUserCreation',
  GetAdditionalLocksNLimits: '/inbursa-sii/app-movil/v1/tarjadic/consultas/getAditionalLocksNLimits',
  UpdateAdditionalCard: '/inbursa-sii/app-movil/v1/tarjadic/reg/updateAditionalCard',
  GetAdditionalSession:'/inbursa-sii/app-movil/v1/tarjadic/consultas/getAditionalSession',
  AddProductAfore: '/inbursa/v1.0/appweb/afore/orq/asociaciones',
  ValidateProductAfore: '/inbursa/v1.0/appweb/afore/orq/asociaciones/put',
  RSLocations: '/RSLocations/ServiceAppWeb.svc/',
  AforeDeductibility: '/inbursa/v1.0/appweb/afore/aportaciones/orq/voluntarias/validaciones/get',
  AforeContribution: '/inbursa/v1.0/appweb/afore/aportaciones/orq/voluntarias/solicitudes',
  AforeConfirmationContribution: '/inbursa/v1.0/appweb/afore/aportaciones/orq/voluntarias/solicitudes/confirmaciones',
  VoucherAforeVoluntaryContributions: '/inbursa/v1.0/appweb/documentos/afore/comprobantes/aportaciones/voluntarias',
  GetAforeBalancesCertificate: '/inbursa/v1.0/appweb/documentos/afore/certificaciones/saldos',
  AforeMovements: '/inbursa/v1.0/appweb/documentos/afore/movimientos',
  GetCausesClarification: '/inbursa-sii/app-movil/v1/aclaraciones/consultas/causas',
  SaveClarification: '/inbursa-sii/app-movil/v1/aclaraciones/registro/',
  GetDirectDebitServicesTDC: '/inbursa/v1.0/appweb/domiciliaciones/orq/servicios/tdc/get',
  GetCardsDirectDebitService: '/inbursa/v1.0/appweb/domiciliaciones/orq/tarjetas/clientes/get',
  GetListDirectDebitService: '/inbursa/v1.0/appweb/domiciliaciones/orq/servicios/codigos',
  CancelDirectDebitServiceDebitCard: '/inbursa/v1.0/appweb/domiciliaciones/orq/servicios/tdd/delete',
  CancelDirectDebitServiceCreditCard: '/inbursa/v1.0/appweb/domiciliaciones/orq/servicios/tdc/delete',
  GetDirectDebitServicesTDCOwn: '/inbursa/v1.0/appweb/domiciliaciones/orq/tdc/get',
  CancelDirectDebitServiceOwnCreditCard: '/inbursa/v1.0/appweb/domiciliaciones/orq/tdc/delete',
  AforeAssociation: '/inbursa/v1.0/appweb/afore/orq/asociaciones/get',
  AforeVoluntaryValidations: '/inbursa/v1.0/appweb/afore/retiros/orq/voluntarios/validaciones/get',
  AforeVoluntaryWithdrawal: '/inbursa/v1.0/appweb/afore/retiros/orq/voluntarios/solicitudes',
  AforeVoluntaryWithdrawalReverse: '/inbursa/v1.0/appweb/afore/retiros/orq/voluntarios/solicitudes/reversos',
  AforeVoluntaryWithdrawalConfirmation: '/inbursa/v1.0/appweb/afore/retiros/orq/voluntarios/solicitudes/confirmaciones',
  AforeVoluntaryAccountAssociation: '/inbursa/v1.0/appweb/afore/orq/asociaciones/cuentas/clabe',
  VoucherAforeVoluntaryWithdrawal: '/inbursa/v1.0/appweb/documentos/afore/comprobantes/retiros/voluntarios',
  CheckbookRequest: '/inbursa/v1.0/appweb/chequeras/solicitudes',
  VariableInvestmentFunds: '/inbursa/v1.0/appweb/sociedades/inversiones/variables/compras',
  VariableInvestmentSale: '/inbursa/v1.0/appweb/sociedades/inversiones/variables/ventas',
  FederalTaxPayment: '/inbursa/v1.0/appweb/impuestos/orq/federales/pagos',
  FederalTaxPaymentVoucher: '/inbursa-sii/app-movil/v1/consultasimpuestos/consultaPagosSat',
  GetFederalTaxPayment: '/inbursa/v1.0/appweb/impuestos/orq/federales/pagos/get',
  CancelFederalTaxPayment: '/inbursa/v1.0/appweb/impuestos/orq/federales/pagos/cancelaciones',
  GetCheckBooks: '/inbursa/v1.0/appweb/chequeras/get',
  GetChecks: '/inbursa/v1.0/appweb/cheques/get',
  GetChecksOtherBanksRequest: '/inbursa/v1.0/appweb/cheques/sbc/get',
  ProtectedChecks: '/inbursa/v1.0/appweb/cheques/protecciones',
  ActivationCheckBooks: '/inbursa/v1.0/appweb/chequeras/activaciones',
  GetImageOtherBanksRequest: '/inbursa/v1.0/appweb/cheques/imagenes',
  ProtectedChecksAmounts: '/inbursa/v1.0/appweb/cheques/protecciones/montos',
  CancelCheckbooks: '/inbursa/v1.0/appweb/chequeras/cancelaciones',
  CancelChecks: '/inbursa/v1.0/appweb/cheques/cancelaciones',
  ConsultSatPayments: '/inbursa-sii/app-movil/v1/consultasimpuestos/consultaPagos/realizados/sat',
  AddCreditOtherBank: '/inbursa-sii/app-movil/v1/agendas/nacionales/agregar',
  PaySatCaptureLine : '/inbursa-sii/app-movil/v1/pagoimpuestos/LineaCaptura',
  GetVoucherSat: '/inbursa-sii/app-movil/v1/consultasimpuestos/comprobante/sat',
  GetIFMPromotionCDMX: '/inbursa-sii/app-movil/v1/consultasimpuestos/consultaActivaMSICDMX',
  CatalogAvailableBanks: '/inbursa-sii/app-movil/v1/catalogos/bancosagendas/consultar',
  GetDirectDebitCard: '/inbursa/v1.0/appweb/domiciliaciones/orq/servicios/tdd/get',
  AddDirectDebitServiceDebitCard: '/inbursa/v1.0/appweb/domiciliaciones/orq/servicios/tdd',
  AddDirectDebitServiceCreditCard: '/inbursa/v1.0/appweb/domiciliaciones/orq/servicios/tdc',
  UpdateDirectDebitCard: '/inbursa/v1.0/appweb/domiciliaciones/orq/servicios/tdd/put',
  SuaUploadFile: '/inbursa/v1.0/appweb/documentos/sua/pagos',
  SuaConsultRegistries: '/inbursa/v1.0/appweb/sua/orq/pagos',
  SuaPayment: '/inbursa/v1.0/appweb/sua/orq/pagos',
  SuaGetReceipt: '/inbursa-sii/app-movil/v1/estadoscuenta/comprobantes/sua',
  GetDirectDebitAfore: '/inbursa/v1.0/appweb/afore/domiciliaciones/get',
  DirectDebitAfore: '/inbursa/v1.0/appweb/afore/domiciliaciones',
  DirectDebitAforeConfirmation: '/inbursa/v1.0/appweb/afore/domiciliaciones/confirmaciones/put',
  DirectDebitAforeVoucher: '/inbursa/v1.0/appweb/documentos/afore/comprobantes/domiciliaciones',
  ScheduleTransferOtherBankRequest: '/inbursa/v1.0/appweb/pagos/programados/transferencias',
  ScheduleTransferRequest: '/inbursa/v1.0/appweb/pagos/programados/traspasos',
  AddDirectDebitServiceCreditCardOwn: '/inbursa/v1.0/appweb/domiciliaciones/orq/tdc',
  AforePartialValidateInformation: '/inbursa/v1.0/appweb/afore/retiros/orq/parciales/validaciones/get',
  AforePartialWithdrawalFolios: '/inbursa/v1.0/appweb/afore/retiros/orq/parciales/folios/get',
  AforePartialWithdrawalCertificates: '/inbursa/v1.0/appweb/afore/retiros/orq/parciales/certificados',
  AforePartialWithdrawalRegistration: '/inbursa/v1.0/appweb/afore/retiros/orq/parciales/solicitudes',
  AforePartialWithdrawalSendMarriageCertificate:'/inbursa/v1.0/appweb/documentos/afore/actas/matrimonios',
  AforePartialWithdrawalMarriageCertificate:'/inbursa/v1.0/appweb/documentos/afore/comprobantes/retiros/matrimonios',
  AforePartialWithdrawalCalculations: '/inbursa/v1.0/appweb/afore/retiros/orq/parciales/calculos/get',
  VoucherAforePartialWithdrawalUnemployment: '/inbursa/v1.0/appweb/documentos/afore/comprobantes/retiros/desempleos',
  PayrollCreditAdvance: '/inbursa-sii/app-movil/v1/creditos/consultas/consulta/credito/anticipos/nomina',
  BalancesPayrollCredit: '/inbursa/v1.0/appweb/nominas/orq/creditos/determinaciones/get',
  CardCancellation: '/inbursa-sii/app-movil/v1/tarjetas/reportes/eventos',
  CardReposition: '/inbursa-sii/app-movil/v1/wallet/registro/{0}/{1}/DEFINITIVELOCK/{2}/', 
  AdditionalCreditCard: '/inbursa-sii/app-movil/v1/adicionales/solicitudes/tarjetas',
  BiometricData: '/inbursa-sii/app-movil/v1/personas/consultarEnrolamiento',
  ValidateTranscript: '/inbursa-sii/app-movil/v1/agendas/internacionales/validarExpediente',
  GetBrokerageHouseAgendas: '/inbursa-sii/app-movil/v1/cb/contratos',
  BrokerageHouseTransfers:'/inbursa-sii/app-movil/v1/cb/contratos/traspasos',
  UpdateDirectDebitCardOwn: '/inbursa/v1.0/appweb/domiciliaciones/orq/tdc/put',
  CardActivation: '/inbursa-sii/app-movil/v1/tarjetas/getAllPendingCards',
  ActiveCard: '/inbursa-sii/app-movil/v1/tarjetas/activePendingCard',
  ModifyDebitAccountAlias: '/inbursa-sii/app-movil/v1/cuentas/modificar/alias',
  ModifyCreditCardAlias : '/inbursa-sii/app-movil/v1/wallet/operacion/0/0/UPDATENICKNAME/{0}/',
  AssociateFundingAccount: '/inbursa-sii/app-movil/v2/fondeos/claropay',
  UpdateFundingAccount: '/inbursa-sii/app-movil/v2/fondeos/claropay/put',
  DisassociateFundingAccount: '/inbursa-sii/app-movil/v2/fondeos/claropay/delete',
  EditUserRFC:'/inbursa-sii/app-movil/v1/personas/rfc',
  EditUserTaxData: '/inbursa-sii/app-movil/v1/personas/registro/datos/fiscales',
  EditUserEmail: '/inbursa-sii/app-movil/v1/personas/registroLocalizador',
  GetTaxRegimes: '/inbursa-sii/app-movil/v1/personas/consulta{0}/{1}',
  NewsCarouselRequest:'/app-assets/json/Noticias.json',
  GetAccountBalanceStatusVisibility: '/inbursa-sii/app-movil/v1/personas/configuraciones/obtener',
  UpdateAccountBalanceStatusVisibility: '/inbursa-sii/app-movil/v1/personas/configuraciones/actualizar',
  ModifyCreditLimit: '/inbursa-sii/app-movil/v1/tarjetacredito/actualizarlimite/creditolimite',
  CardPromotions:'/app-assets/json/PromocionesTarjetas.json',
  GetPromotionsRewards: '/inbursa-sii/app-movil/v1/promociones/obtenerRecompensa',
  CheckPromotions: '/inbursa-sii/app-movil/v1/promosconsultas/checkPromotions'
	
  };
