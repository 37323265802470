<ng-container *ngIf="vm$ | async as vm">
    <div class="d-flex justify-content-center ">
        <div id="container-balance-held" class="modal-balance-held"
        [ngClass]="{'modal-balance-desktop':vm.breakpoints.desktop && !isiPad,
                    'modal-balance-tablet':(vm.breakpoints.tablet || isiPad) && !isiPhone,
                    'modal-balance-mobile':vm.breakpoints.mobile || isiPhone}">
            <div class="col-12">
                <div class="pt-4"></div>
                <div class="d-flex flex-column justify-content-start">
                    <span class="header-s always-dark"
                    [ngClass]="{'title-size-mobile':vm.breakpoints.mobile}">Operaciones
                        en trámite</span>
                </div>
            </div>
            <ng-container>
                <div class="col-12 card-held"
                [ngClass]="{'p-held-cero':vm.breakpoints.mobile || isiPhone}">
                    <div class="responsive flex-nowrap moz-scroll"
                        [ngClass]="{'cardSlider':vm.breakpoints.desktop && !isiPad,
                        'cardSlider-mobile':(vm.breakpoints.tablet|| vm.breakpoints.mobile),
                        'cardSlider-tablet':isiPad}">
                        <div *ngFor="let operations of dataMovements">
                            <div class="col-12 detail-m primary-a">
                                {{operations.fechaRetencion | customDate}}
                            </div>
                            <div class="padding-top-16"></div>
                            <div class="row d-flex m-0 p-0"
                            (click)="vm.breakpoints.mobile? openModalWithHoldings(detailTransactionSpei, operations)
                            : null " >
                                <div *ngIf="(vm.breakpoints.desktop || vm.breakpoints.tablet) && !isiPhone"
                                    class="circle-bgrd"> <img class="close" width="auto" height="auto" alt="fileLocked"
                                    src="{{ imageCDN | cdnImage }}"
                                    (click)="openModalWithHoldings(detailTransactionSpei, operations)">
                                </div>
                                <div class="detail-m-highlight always-dark"
                                [ngClass]="{'col-10': vm.breakpoints.desktop,
                                'custom-col-tablet': vm.breakpoints.tablet,  'custom-col-tablet-ios': isiPad ,
                                'p-0 custom-col-mobile': vm.breakpoints.mobile || isiPhone}">
                                    {{operations.descripcion}}
                                </div>
                                <div class="d-flex flex-row-reverse direction-end header-xs primary-a p-0
                                ml-held" [ngClass]="{'col-1': vm.breakpoints.desktop,
                                'col-4': vm.breakpoints.tablet, 'custom-col-amount-ios': isiPad,
                                'custom-col-amount': vm.breakpoints.mobile || isiPhone }">
                                {{operations.monto | currency}}
                                </div>
                            </div>
                            <div class="pt-4"></div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="pt-4"></div>
                        <div class="d-flex flex-row-reverse col-12 direction-end header-xs primary-a "
                            [ngClass]="{'p-held-cero':vm.breakpoints.mobile || isiPhone}">
                            Total: {{totalRetainedBalance | currency}}
                        </div>
                        <div class="pt-4"></div>
                        <div class="col-12 d-flex justify-content-center">
                            <button class="colored-btn" (click)="close()"
                            [ngClass]="{'btnDesktop':vm.breakpoints.desktop,
                            'btnTablet':vm.breakpoints.tablet,'btnMobile':vm.breakpoints.mobile || isiPhone}">
                                <span class="button-m"> Cerrar</span></button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-template #detailTransactionSpei let-modal>
  <app-detail-transaction-spei-modal [modal]="modal" [movementRetained]="movementDetails"
  [movementSelected]="movementSelected" ></app-detail-transaction-spei-modal>
</ng-template>
