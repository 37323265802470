import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PaginationModel } from '../models/PaginationModel';
import { Utils } from 'src/core/utils/utils';
import { AlertService } from './alert.service';
import { Strings } from 'src/core/constants/Strings';
import { Constants, Numbers } from 'src/core/constants/Constants';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {

  private readonly _hasResults: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  private readonly pagination$: BehaviorSubject<PaginationModel> =
    new BehaviorSubject<PaginationModel>({
      data: [],
      totalPages: Numbers.Zero,
      currentPage: Constants.PAGE_DEFAULT,
      pages: [],
    });

  private initialData: Array<any>;
  private currentPage = Constants.PAGE_DEFAULT;
  private pageSize = Constants.PAGE_DEFAULT;
  private totalPages = Constants.PAGE_DEFAULT;
  private pages = [];
  private currentData: Array<any>;
  private remoteData: Array<PaginationModel>;
  private getRemoteData: (page: number) => Promise <PaginationModel>;
  private isRemote = false;
  private backPage = Constants.PAGE_DEFAULT;

  constructor(private readonly alertService: AlertService) { }

  get pagination() {
    return this.pagination$.asObservable();
  }

  /**
   * Inicializa la paginación
   * @param data Información a paginar
   * @param pageSize número de elementos a mostrar por página
   */

  setInitialData<T>(data: Array<T>, pageSize: number) {
    this.isRemote = false;
    this.initialData = data;
    this.currentData = [...data];
    this.pageSize = pageSize;
    this.totalPages = Math.ceil(this.initialData.length / this.pageSize);
    this.pages = Array(this.totalPages)
      .fill(Numbers.One)
      .map((_, i) => i + Numbers.One);
    this.paginate();
  }

  setRemoteData<T>(model: PaginationModel, getRemoteData: (page: number) => Promise <PaginationModel>) {
    this.clear();
    this.isRemote = true;
    this.getRemoteData = getRemoteData;
    this.totalPages = model.totalPages;
    this.pages = Array(model.totalPages).fill(Numbers.One)
    .map((_, i) => i +  Numbers.One);

    this.remoteData = [{ page: Constants.PAGE_DEFAULT, totalPages: model.totalPages, data: model.data }];
    this.pagination$.next({
      data: model.data,
      totalPages: model.totalPages,
      currentPage: Constants.PAGE_DEFAULT,
      pages: this.pages
    });
  }

  async updateRemoteData(page: number) {
    let pageData = this.remoteData.find(item => item.page === page);
    const currentPage = this.pagination$.getValue();

    if(!pageData) {
      const remoteData = await this.getRemoteData(page - Numbers.One);
      pageData = {
        page,
        totalPages: currentPage.totalPages,
        data: remoteData.data
      };
      this.remoteData.push(pageData);
    }

    this.pagination$.next({
      data: pageData.data,
      totalPages: currentPage.totalPages,
      currentPage: page,
      pages: currentPage.pages
    });
  }

  private paginate() {
    this.pagination$.next({
      data: [...this.currentData].slice(
        (this.currentPage - Numbers.One) * this.pageSize,
        this.currentPage * this.pageSize
      ),
      totalPages: this.totalPages,
      currentPage: this.currentPage,
      pages: this.pages
    });
    this.goToPage(Numbers.One);
    this.hasResults();
  }

  private async switchPage() {
    try {
      if(this.isRemote) {
        await this.updateRemoteData(this.currentPage);
      }
      else {
        this.pagination$.next({
          data: [...this.currentData].slice(
            (this.currentPage - Numbers.One) * this.pageSize,
            this.currentPage * this.pageSize
          ),
          totalPages: this.totalPages,
          currentPage: this.currentPage,
          pages: this.pages
        });
      }
    } catch (error) {
      this.goToPage(this.backPage);
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg,errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT
      });
    }

  }

  goToPage(page: number) {
    this.backPage = this.currentPage;
    this.currentPage = page;
    this.switchPage();
  }

  nextPage() {
    if (this.currentPage === this.totalPages) return;
    this.backPage = this.currentPage;
    this.currentPage++;
    this.switchPage();
  }

  previousPage() {
    if (this.currentPage === Constants.PAGE_DEFAULT) return;
    this.backPage = this.currentPage;
    this.currentPage--;
    this.switchPage();
  }

  /**
   * Filtra la información de la paginación
   * @param filterFun funcion de filtro para la información
   * @param filterFunArgs argumentos necesarios para la función de filtro
   */
  filter<T>(filterFun: (data: Array<T|PaginationModel>, ...args: any) => Array<any>, filterFunArgs?: any) {
    let filteredData = null;
    if(this.isRemote){
      filteredData = [...filterFun([...this.pagination$.value.data], filterFunArgs)];
    } else {
      filteredData = [...filterFun([...this.initialData], filterFunArgs)];
    }

    if(!this.isRemote){
      this.currentPage = 1;
      this.currentData = [...filteredData];
      this.calcPages(filteredData.length);
      filteredData = filteredData.slice(
      (this.currentPage - Numbers.One) * this.pageSize,
        this.currentPage * this.pageSize
      );
    }

    this.pagination$.next({
      data: filteredData,
      totalPages: this.totalPages,
      currentPage: this.currentPage,
      pages: this.pages
    });
    this.hasResults();
  }

  /**
   * Filtra la información de la paginación
   * @param filterFun funcion de búsqueda para la información
   * @param filterFunArgs argumentos necesarios para la función de busqueda
   */
  search<T>(searchFun: (data: Array<T>, ...args: any) => Array<any>, searchFunArgs?: any) {
    if(this.initialData && !this.isRemote){
      this.currentPage = Constants.PAGE_DEFAULT;
      let filteredData = [...searchFun([...this.initialData], searchFunArgs)];
      this.currentData = [...filteredData];

      this.calcPages(filteredData.length);
      filteredData = filteredData.slice(
        (this.currentPage - Numbers.One) * this.pageSize,
        this.currentPage * this.pageSize
      );
      this.pagination$.next({
        data: filteredData,
        totalPages: this.totalPages,
        currentPage: this.currentPage,
        pages: this.pages
      });
      this.hasResults();
    }
  }

  /**
   * Busca y filtra la información de la paginación
   * @param fn funcion de filtro y busqueda para la información
   * @param filterFunArgs argumentos necesarios para la función (filtro y busqueda)
   */
  searchAndFilter<T>(fn: (data: Array<T>, ...args: any) => Array<T>, filterFunArgs?: any) {
    if (!this.isRemote) {
      let filteredData = [...fn([...this.initialData], filterFunArgs)];
      this.currentPage = Constants.PAGE_DEFAULT;
      this.currentData = [...filteredData];
      this.calcPages(filteredData.length);
      filteredData = filteredData.slice(
      (this.currentPage - Numbers.One) * this.pageSize,
        this.currentPage * this.pageSize
      );
      this.pagination$.next({
        data: filteredData,
        totalPages: this.totalPages,
        currentPage: this.currentPage,
        pages: this.pages
      });
      this.hasResults();
    }
  }

  setPageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.calcPages();
    this.paginate();
    this.goToPage(Constants.PAGE_DEFAULT);
  }

  private calcPages(dataLength?: number) {
    this.totalPages = Math.ceil((dataLength ?? this.initialData.length) / this.pageSize);
    this.pages = Array(this.totalPages)
      .fill(1)
      .map((_, i) => i + Numbers.One);
  }

  clear(){
    this.pagination$.next({
      data: [],
      totalPages: Constants.PAGE_DEFAULT,
      currentPage: Constants.PAGE_DEFAULT,
      pages: []
    });
    this.currentPage = Constants.PAGE_DEFAULT;
  }

  get hasPreviousPage() {
    return this.currentPage > Numbers.One;
  }

  get hasNextPage() {
    return this.currentPage < this.totalPages;
  }

  private hasResults() {
    this._hasResults.next(this.currentData.length > Numbers.Zero);
  }

  get hasResults$() {
    return this._hasResults.asObservable();
  }

  get getPaginationData(){
    return this.pagination$.getValue();
  }
}
