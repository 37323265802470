import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionLike, combineLatestWith, map } from 'rxjs';
import { AccountCardService } from 'src/app/services/account-card.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { NotifyClearFormService } from 'src/app/services/notify-clear-form.service';
import { NotifyDateService } from 'src/app/services/notify-date.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { ElementsHTML, Numbers, Position } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { Resources } from 'src/core/constants/Resources';
import { AccountModel } from 'src/app/interface/AccountModel';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { AlertService } from 'src/app/services/alert.service';
import { ButtonsSpacing } from 'src/app/models/ButtonsSpacing';
import { MaskAccountPipe } from 'src/app/pipes/mask-account.pipe';
import { AccountsStatusService } from 'src/app/services/accounts-status.service';
import { CodeflexConstants, CodeflexElementsHTML } from 'src/core/constants/CodeflexConstants';

@Component({
  selector: 'app-confirm-data-codeflex',
  templateUrl: './confirm-data-codeflex.component.html',
  styleUrls: ['./confirm-data-codeflex.component.css']
})
export class ConfirmDataCodeflexComponent {
  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(
      this.accountCardService.accountCard$,
      this.notifyDateService.dateNgbStruct
    ),
    map(([breakpoints, selectedCard ]) => ({
      breakpoints,
      selectedCard,
      maskedCardNumber: Utils.maskNumber(selectedCard.cardNumber),
      date: this.notifyDateService.dateStructData ? this.notifyDateService.dateFormat : Strings.EMPTY
    }))
  );

  contentMargin: ElementsHTML = ElementsHTML.ContentMarginBreakpoints;
  title: string = Strings.EMPTY;
  subtitle: string = Strings.CODEFLEX.WithdrawalConfirm;
  titleDateLabel: {mobile: string, tablet: string}= Strings.CODEFLEX.DateTitleLabelResponsive;
  dateLabel: {mobile: string, tablet: string} = Strings.CODEFLEX.DateLabelResponsive;
  account: AccountModel;
  dateSuscription: SubscriptionLike;
  customDate: Strings;
  accountType: string = Strings.EMPTY;
  mainAccount: AccountModel;
  numberAccount : string = Strings.EMPTY;
  codeflexPath: string = PATH.Codeflex;
  completedWithdrawalPath: string = PATH.CodeflexCompletedWithdrawal;
  chevronRightIcon: string = Resources.SHAPE_CONTINUE;
  buttonsSpacing: ButtonsSpacing = {
    mobile:  CodeflexElementsHTML.ButtonsSpacingMobile,
    desktop: CodeflexElementsHTML.ButtonsSpacingDesktop
  };

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly accountCardService: AccountCardService,
    private readonly notifyDateService: NotifyDateService,
    private readonly router: Router,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly notifyClearFormService: NotifyClearFormService,
    private readonly modalService: ModalService,
    private readonly accountInfoService: AccountInfoStatusService,
    private readonly alertService: AlertService,
    private readonly accountsStatusService: AccountsStatusService
  ) {}

  ngOnInit(){
    try{
      if(this.notifyAmountService.amount.amount===Strings.EMPTY){
        this.router.navigate([PATH.Codeflex]);
      }else{
        this.account = this.accountInfoService.account;
        this.accountType = this.account.tipoCuenta;
        this.numberAccount = new MaskAccountPipe().transform(this.account.numeroCuenta, Numbers.Four);
        this.title += this.account.descripcionCuenta;
        this.mainAccount = this.accountCardService.arrayAccountCard.filter(item => !item.tipoCuenta.toLowerCase().includes(CodeflexConstants.CODEFLEX_ROOT))[Position.Zero];
        this.mainAccount.numeroCuenta = new MaskAccountPipe().transform(this.mainAccount.numeroCuenta, Numbers.Four);
      }
    }catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg,errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT
      },{
        onClose:() => {
          this.resetStatus();
          this.router.navigate([PATH.Home]);
        },
        onSuccess:() => {
          this.resetStatus();
          this.router.navigate([PATH.Home]);
        }
      });
    }
  }

  resetStatus(){
    this.notifyDateService.dateStructData = undefined;
    this.accountsStatusService.status = {value: false, isProductSelected: Strings.EMPTY, id: Numbers.Zero};
  }

  return() {
    this.notifyClearFormService.clearFormData = { value: false };
    if (this.notifyDateService.dateStruct.value !== undefined) {
      this.notifyDateService.dateStructData = {
        year: this.notifyDateService.dateStruct.value.year,
        month: this.notifyDateService.dateStruct.value.month,
        day: this.notifyDateService.dateStruct.value.day
      };
    }
    this.router.navigate([PATH.Codeflex]);
  }

  ngOnDestroy() {
    this.modalService.close();
  }
}
