<ng-container *ngIf="vm$ | async as vm">
    <content-wrapper [useFlex]="!vm.breakpoints.mobile">
        <div content class="margin-transfer">
            <div *ngIf="vm.isComponentLoaded">
                <ng-container *ngIf="errorMessage === '' else operationError">
                    <div id="form-container">
                        <div class="header d-flex-column-center">
                            <img [src]="check | cdnImage" class="icon-check" width="56" height="56" alt="">
                            <div class="header d-flex-column-center">
                                <label [ngClass]="!vm.breakpoints.mobile ? 'header-l' : 'header-m'">
                                    Aportación realizada</label>
                                <label class="body-m">Folio: {{reference}}</label>
                            </div>
                            <div class="d-flex-column-center" id="amount">
                                <show-amount [useBreak]="false" label="" color="var(--secondary-800)"></show-amount>
                                <label class="detail-m">{{dateSuccess}}h</label>
                            </div>
                            <div class="pt-shared">
                                <app-shared-buttons [showButtons]="{email: false, download: true, 
                                    share: true, print: false}" [fileName]="'Aportaciones Adicionales'"
                                    [data]="sharedData"></app-shared-buttons>
                            </div>
                        </div>
                        <div id="account-operation">
                            <div class="w-column">
                                <label class="header-xs p-label">Desde tu cuenta</label>
                                <div class="p-2">
                                    <div class="element-card flex-card-row">
                                        <div class="icon-card-sh account-center">
                                            <img class="card-image" [src]="selectedCard?.image" alt="image" width="auto"
                                                height="auto" />
                                        </div>
                                        <div class="account-center flex-card-row">
                                            <div class="flex-card-column">
                                                <span class="body-s">{{cardNumber}}</span>
                                                <span class="detail-s">{{selectedCard?.cardType}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="margin-hr black-s">
                                </div>
                            </div>
                            <div class="w-column">
                                <label class="header-xs p-label">Información de la operación</label>
                                <div class="p-2">
                                    <div class="flex-card-column px-2 pb-2">
                                        <label class="body-s">{{ addContributionsData?.typeContribution }}</label>
                                        <label class="detail-s pt-1">Tipo de Aportación</label>
                                    </div>
                                    <hr class="margin-hr black-s">
                                </div>
                                <div class="p-2">
                                    <div class="flex-card-column p-2">
                                        <label class="body-s">{{ addContributionsData?.policyNumber }}</label>
                                        <label class="detail-s pt-1">No. de Póliza</label>
                                    </div>
                                    <hr class="margin-hr black-s">
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="vm.breakpoints.desktop ? 'pt-3' : 'pt-2'">
                            <div class="btn-container d-flex justify-content-center">
                                <button class="button-m colored-btn btn-generic" (click)="goPage(homePage)">
                                    Listo
                                </button>
                            </div>
                            <div class="btn-container pt-3 d-flex justify-content-center">
                                <button class="outline-btn btn-generic"
                                    [ngClass]="vm.breakpoints.mobile ? 'button-s' : 'button-m'"
                                    (click)="goPage(additionalContributionsPage)">
                                    Otra operación
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </content-wrapper>
</ng-container>

<ng-template #operationError>
    <app-error-exception [tab]="tab" [homePage]="homePage" [otherOperation]="additionalContributionsPage"
        [showButtons]="true" [errorMessage]="errorMessage"></app-error-exception>
</ng-template>