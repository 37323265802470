import { AccountType, Constants } from 'src/core/constants/Constants';

export class ProductConstants {

    static readonly ORIGIN_CONSULT = {
        Login: 'LOGIN',
        BackHome: 'BACKHOME',
        Button: 'BUTTON'
    };

    static ORIGIN_CONSULT_DATA = this.ORIGIN_CONSULT.BackHome;
    static readonly INVESTMENT_DECIMALS_AMOUNT= 2;
    static readonly INVESTMENT_MAX_DIGITS_AMOUNT= 11;
    static readonly INVESTMENT_MAX_LENGTH_AMOUNT= 13;
    static readonly SERVICES_ERROR_PARAMETER= 'Error';

    static readonly INVESTMENT_ACCOUNTS = [
        AccountType.Dinbur,
        AccountType.INBUMEX1,
        AccountType.INBUMEX2,
        AccountType.INBUMEX3,
        'INBURSA1',
        'INBURSA2',
        'INBURSA3'
    ];

    static readonly INVESTMENT_CODE_DINBUR = 'DINBUR';
    static readonly CARD_NUMBER_UPPER_CASE = 'NumeroTarjeta';
    static readonly CARD_NUMBER_LOWER_CASE  = 'numeroTarjeta';

    static readonly MOVEMENTS_RETAINED = [
      { key: 'Tarjeta', name: 'Tarjeta', format: Constants.MOVEMENTS_FORMAT.Card },
      { key: 'originalDate', name: 'Fecha de retención', format: Constants.MOVEMENTS_FORMAT.Date},
      { key: 'Folio', name: 'Número de operación' },
      { key: 'Descripcion', name: 'Descripción' },
      { key: 'tipoOperacion', name: 'Tipo de retención'}
    ];

    static readonly RESOLUTIONS = {
      PhoneMinWidth: 412
    };

    static readonly NAME_PDF_ACCOUNT_STATEMENTS = 'consultar-cuenta';

    static readonly EXCLUDE_TRANSACTION_ACCOUNT_TYPE = [
        AccountType.GroceryCoupon,
        AccountType.WEB,
        AccountType.PromissoryNote,
        AccountType.PAT,
        AccountType.CODEFLEX,
        AccountType.CODE91,
        AccountType.CODEFLEX91,
        AccountType.ClaroPay,
        AccountType.Dinbur,
        AccountType.INBUMEX1,
        AccountType.INBUMEX2,
        AccountType.INBUMEX3,
        'INBURSA1',
        'INBURSA2',
        'INBURSA3'
    ];

    static readonly PAYMENT_OPTIONS = {
      InvestmentCompaniesManagement : 'InvestmentCompaniesManagement',
      InsuranceLife : 'InsuranceLife',
      InsuranceManagement : 'InsuranceManagement',
      ResponsiveProduct : 'ResponsiveProduct',
      ResponsiveProductOtherOptions : 'ResponsiveProductOtherOptions',
      ResponsiveInvestmentCompanies : 'ResponsiveInvestmentCompanies',
      AforeManagement : 'AforeManagement',
      CreditsOtherOptions : 'CreditsOtherOptions',
      Product : 'Product',
      Credits : 'Credits',
      Credit : 'Credit',
      Debit : 'Debit',
      Patrimonial : 'Patrimonial',
      Insurance : 'Insurance',
      Responsive : 'Responsive',
      AccountManagement : 'AccountManagement',
      Web : 'Web',
      Investment : 'Investment',
      InvestmentCompanies : 'InvestmentCompanies'
    };
  }

export enum AccountTypeMax {
   PATMX = 'PATMX'
}
