import { SessionManagerService } from 'src/app/services/session-manager.service';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
} from '@angular/core';
import { combineLatestWith, filter, map, tap } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NotifyRouteService } from 'src/app/services/notify-route.service';
import { BreakpointState, ResponsiveService } from './shared/responsive.service';
import { AlertService } from 'src/app/services/alert.service';
import { Char, Constants, Numbers } from 'src/core/constants/Constants';
import { StorageService } from 'src/app/services/storage.service';
import { AppUtils } from 'src/core/utils/AppUtils';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalTechnicalRequirements } from 'src/app/component/modal-technical-requirements/modal-technical-requirements.component';
import { Resources } from 'src/core/constants/Resources';
import { PATH } from 'src/core/constants/Path';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { SentinelService } from 'src/app/services/sentinel.service';
import { FileUtils } from 'src/core/utils/FileUtils';
import { StateProfileService } from 'src/app/services/save-profile.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NavigationConstants } from 'src/core/constants/NavigationConstants';
import { Strings } from 'src/core/constants/Strings';
import { ConfigurationApp } from 'src/app/services/servicesApp/configuration-app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(
      this.notifyRouteService.switchRouteObservable,
      this.alertService.isClose$,
      this.stateProfileService.profile$),
    map(([breakpoints, switchRoute, alertState, profile]) => ({
      breakpoints,
      switchRoute: switchRoute?.value,
      alertState,
      profile
    }))
  );

  pageError = false;
  parentMessage = Numbers.One;
  isApp = false;
  isXamarin = false;
  links = Constants.EXTERNAL_LINKS;

  mobile02 = Resources.MOBILE_WHITE;
  phoneVoice = Resources.PHONE_WHITE;
  lock = Resources.LOCK_WHITE;
  monitor = Resources.WHITE_MONITOR;
  icoWarning = Resources.WARNING_ICON;
  warningBrowser = Resources.BROWSER_WARNING;
  locationIcon: string = Resources.LOCATION_WHITE;
  showIdClient : boolean = true;

  constructor(
    private readonly _router: Router,
    private readonly notifyRouteService: NotifyRouteService,
    private readonly sessionManager: SessionManagerService,
    private readonly responsiveService: ResponsiveService,
    private readonly alertService: AlertService,
    private readonly storageService: StorageService,
    private readonly modalService: ModalService,
    private readonly httpClient: HttpClient,
    private readonly sentinelService: SentinelService,
    private readonly stateProfileService: StateProfileService,
    private readonly configurationApp: ConfigurationApp,
    private readonly loaderService: LoaderService
  ) {
    this.sessionManager.onloadHandler();
  }

  ngOnInit() {
    this._router.events
      .pipe(tap((event)=> {
          this.validateLazyLoading(event);
      }))
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const actualRoute = event.url;
        this.notifyRouteService.switchRouteData = {
          value: actualRoute.includes(PATH.Login) || actualRoute === Char.Slash,
        };
      });
    this.contractValidation();
    this.sentinel();
    setTimeout(() => {
      this.isApp = AppUtils.platform.isApp;
      this.isXamarin = AppUtils.platform.isXamarin;
    });
  }

  validateLazyLoading(event) {
    let url = Strings.EMPTY;
    if (event instanceof NavigationStart) {
      url = event.url;
      if (url && NavigationConstants.LAZY_PATHS.some(path => path === url)) {
        this.loaderService.showLoader();
      }
    }
    if (event instanceof NavigationEnd) {
      url = event.url;
      if (url && NavigationConstants.LAZY_PATHS.some(path => path === url)) {
        this.loaderService.hideLoader();
      }
    }
  }

  sentinel() {
    this.httpClient
      .get('/favicon.ico', { observe: 'response', responseType: 'text'})
      .subscribe((response: HttpResponse<unknown>) => {
        this.sentinelService.sentinel = {
          ip: response.headers.get('x-forwarded-for') ?? Constants.DEFAULT_IP
        };
      });
  }

  @HostListener('window:pagehide')
  unloadHandler() {
    this.sessionManager.unloadHandler();
  }

  async onShowRequirements(){
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeSm,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.TechnicalRequirements,
    };
    this.modalService.open(ModalTechnicalRequirements, modalOptions);
  }

  get userId(){
    return this.storageService.getUser()?.personId;
  }

  showGeolocationManual(breakpoints: BreakpointState) {
    window.open(FileUtils.showGeolocationManual(breakpoints));
  }

  contractValidation(){
    this._router.events
    .pipe(filter((event) => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      const actualRoute = event.url;
      this.showIdClient = !actualRoute.includes(PATH.InsuranceExpress);
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleTab(event: KeyboardEvent) {
    if(event.key !== 'Tab') return;

    const activeElement = document.activeElement;
    const modal = document.querySelector('ngb-modal-window');
    if(this.modalService.disableTabNavigation && !modal?.contains(activeElement)){
      event.preventDefault();
    }
  }
  get isExternalApp(){
    return this.configurationApp?.configurationApp.isExternal;
  }
}
