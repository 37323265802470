import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { combineLatestWith, map } from 'rxjs';
import { AccountModifyService } from 'src/app/services/info-accounts-edit.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { AgendaType, Constants, FormatDate } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { ServiceConstants } from 'src/core/constants/ServiceConstants';
import { Strings } from 'src/core/constants/Strings';
import { ProductUtils } from 'src/core/utils/ProductUtils';

@Component({
  selector: 'app-complete-modify-account',
  templateUrl: './complete-modify-account.component.html',
  styleUrls: ['./complete-modify-account.component.css']
})
export class CompleteModifyAccountComponent implements OnInit {

  completeDate!: string;
  codeType: number = AgendaType.OthersBanks;
  invoice: string = Strings.EMPTY;
  agendaInternationalID: number = AgendaType.International;
  agendaOthersBanksID: number = AgendaType.OthersBanks;
  agendaThirdPartiesID: number = AgendaType.ThirdParties;
  nonAgendableService: number = AgendaType.NonAgendableService;
  serviceAgendaCode: number = AgendaType.AccountAddress;
  accountLabel = Strings.ADD_SERVICE.CardNumber;

  vm$ = this.accountModifyService.account$.pipe(
    combineLatestWith(this.responsiveService.observe),
    map(([accountModify, breakpoints]) => ({
      accountModify,
      breakpoints
    }))
  );

  checkIcon:string = Resources.GREEN_CHECK_FILL;
  nonAgendableType: number = ServiceConstants.NON_AGENDABLE_TYPE;

  constructor(private readonly accountModifyService: AccountModifyService, private readonly responsiveService: ResponsiveService) { }

  ngOnInit(): void {
    const date: Date = new Date();
    this.accountLabel = ProductUtils.getAccountLabel(this.accountModifyService.account.type, this.serviceAgendaCode,
      this.accountModifyService.account.codeServices, Strings.ADD_SERVICE.CardNumber);
    this.completeDate = formatDate(date, FormatDate.DayMonthYearComplete, Constants.LOCALE_ES_MX);

    this.accountModifyService.account$.subscribe(data => {
      this.codeType = data.type;
      this.invoice = data.invoice;
    });
  }

}
