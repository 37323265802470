<ng-container *ngIf="vm$ | async as vm">
  <div *ngIf="isInternational">
    <label class="title-select-account my-3 header-xs">{{ label }}</label>
    <div id="local-taxes" class="contain drop-shadow-m">
      <div class="d-flex flex-column p-0">
        <div *ngIf="!vm.breakpoints.mobile">
          <div [ngClass]=" isInternational ? largeAmount ?  'filled-input':'normal-input':''">
            <input
            class="enter-amount-select-account custom-input header-xxxl"
            (input)="changeStyle()"
            (currencyConversion) = currencyConversion($event)
            [ngStyle]="{'font-size.px':fontSize}" (getMessageError)=isValidAmount() [maxAmount]="maxAmount"
            [internationalBadge]="badge"
            [(ngModel)]="amount"
            [placeholder]="hiddenPlaceholder? '': isUSD? '$0.00': '€0.00'"
            [ngClass]="changeAmountColor ? 'error': ''"
            [ngStyle]="{'font-size.px':fontSize}"
            numeric [decimals]="decimals" [maxLength]="maxLength" [currency] = "true"
            (input)="validEntry(amountData.value)"
            inputmode="decimal" #amountData
            cleanInput [cleanRegex]="onlyNumbers"
            [maxAmount] = "maxAmount" [internationalBadge]="badge"
            (input)="validEntry(amountData.value)"
            (focus)="hiddenPlaceholder= true" (blur)="hiddenPlaceholder= false"
            autocomplete="off"/>
            <span class="currency header-s"
            [ngClass]="changeAmountColor ? 'error': ''">{{badge}}</span>
          </div>
        </div>
        <div *ngIf="vm.breakpoints.mobile">
          <div [ngClass]=" isInternational ? largeAmount ?  'filled-input':'normal-input':''">
            <input
            class="enter-amount-select-account custom-input header-xxxl"
            (input)="changeStyle()"
              [ngStyle]="{'font-size.px':fontSize}"
              (getMessageError)=isValidAmount() [maxAmount]="maxAmount"
              numeric [decimals]="decimals" [maxLength]="maxLength" [currency] = "true"
            [(ngModel)]="amount"
            [placeholder]="hiddenPlaceholder? '':isUSD? '$0.00': '€0.00'"
            [ngClass]="changeAmountColor ? 'error': ''"
            [ngStyle]="{'font-size.px':fontSize}"
            numeric [decimals]="decimals" [maxLength]="maxLength" [currency] = "true"
            cleanInput [cleanRegex]="onlyNumbers"
            [minAmount]="minAmount" [internationalBadge]="badge"
            (currencyConversion)=currencyConversion($event) #amountData (input)="validEntry(amountData.value)"
            inputmode="decimal"
            (input)="validEntry(amountData.value)"
            (focus)="hiddenPlaceholder= true" (blur)="hiddenPlaceholder= false"
            autocomplete="off"/>
            <span class="currency header-s">{{badge}}</span>
          </div>
        </div>
        <div class="d-flex gap-2">
          <label class="enter-amount-label-select-account"
            [ngClass]=" coinModeResponsiveMobile ?  'margin-responsive':''">{{quantityLabel}}</label>
          <label class="enter-amount-label-select-account error" *ngIf="vm.amountError.isError">
            {{vm.amountError.errorMsg}}
          </label>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column detail-m pt-2 gap-1">
      <span>Tipo de cambio: {{exchangeRate | currency: 'USD' : 'symbol-narrow' : '1.2-10' }}</span>
      <span>Cantidad en pesos: {{vm.amount.internationalAmount | currency}}</span>
    </div>
  </div>

  <div *ngIf="!isInternational">
    <label class="header-xs" for="titleAmount"
      [ngClass]="label=='¿Cuánto quieres invertir?' ? '' : 'title-select-account '"
      [ngClass]="{'my-3':label}">{{ label }}
    </label>
    <div id="local-taxes" class="contain drop-shadow-m">
      <div [ngClass]="fullWidth ? 'usd-container w-100':'usd-container'">
        <div class="usd-label"
        [ngbTooltip]="placeDots && (amount !== '$0.00' && amount !== '$0.000000') && amount.length > tooltipAmount
          ? amount: ''">
          <input [class]="styleInsurance!=='' && !vm.breakpoints.desktop?
          'enter-amount-select-account custom-input header-xxl':'enter-amount-select-account custom-input header-xxxl'"
          id="titleAmount"
            [disabled]="disabledAmount"
            [ngClass]="{'text-overflow-ellipsis' : placeDots, 'error' : changeAmountColor}" type="text"
            minlength="1" [(ngModel)]="amount" [placeholder]="hiddenPlaceholder? '': '$0.00'"
            numeric [decimals]="decimals" [maxLength]="maxLength" [currency] = "true"
            [maxDigits]="maxDigits"
            (getMessageError) = isValidAmount()
            [maxAmount]="maxAmount" [minAmount]="minAmount"
            cleanInput [cleanRegex]="onlyNumbers"
          numeric [decimals]="decimalsAmount" [currency] = "true"
            inputmode="decimal" (focus)="hiddenPlaceholder= true" (blur)="hiddenPlaceholder= false"
            autocomplete="off"
            [tabIndex]="tabIndex"
            />
            <span class="currency header-s">{{badge}}</span>
        </div>
        <div class="d-flex gap-2">
          <label class="enter-amount-label-select-account"
          [ngClass]="{'label-select-account-investment':classLabel}">{{quantityLabel}}</label>
          <label class="enter-amount-label-select-account error" *ngIf="vm.amountError.isError">
            {{vm.amountError.errorMsg}}
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-container>
