import { environment } from 'src/environments/environment';
import { DebitCardType } from 'src/core/constants/Constants';
export class ControlCardStrings{
    static readonly TITLE_REPLACEMENT = 'Reposición de tarjeta';
    static readonly TITLE_RECEPTION = 'Recepción de tarjeta';
    static readonly TITLE_RENEWAL = 'Renovación de tarjeta de crédito';
    static readonly TITLE_RENEWAL_TDD = 'Renovación de tarjeta';
    static readonly START_DATA = '000';
    static readonly NOCARD = 'No TypeCard';
    static readonly ERRORDATE = 'Debes ser mayor de edad para este trámite';
    static readonly GENERIC_ERROR_DATE = 'Selecciona una fecha válida';
    static readonly GENERIC_ERROR_EMPTY_DATE = 'Selecciona una fecha';
    static readonly ERROR_INCOMPLETE = 'Completa este campo';
    static readonly ERROR_RFC = 'Completa este campo con un RFC válido';
    static readonly ERROR_CURP = 'Completa este campo con una CURP válida';
    static readonly ERROR_LENGHT_RFC = 'La longitud minima es de 10 caracteres';
    static readonly CREDIT = 'Crédito';
    static readonly INITPATH = 'http';
    static readonly ANOTHER_OPERATION = 'Otra operación';
    static readonly NOT_NOW = 'En otro momento';

    static readonly CONTROL_CARD = {
        InternationalPurchases: 'Compras y retiros en el extranjero',
        ApplePay:'Pagos Apple Pay',
        CODI:'Pagos Codi',
        SmartWatch:'Pagos Smartwatch',
        Android:'Pagos Google Wallet',
        HeadPercentage: 'Asigna el porcentaje de tu línea de crédito que podrá utilizar este adicional',
        HeadUnlocked: 'Desbloquea en el momento que desees solo los canales que utilices',
        ShoppingTitle: 'Compras en el extranjero',
        HeadUnlockedAdditional:'Asigna los bloqueos según el tipo de operacion en tu adicional'
    };
    static readonly BUTTONS = {
        No: 'No',
        Agree:'Sí, estoy seguro'
    };

    static readonly LABELS_MANAGE_CARDS = {
        CancelCard: 'cancela',
        Additional: 'adicional',
        Status: 'estatus'
    };
    static readonly OTHER_BUTTON = 'Cerrar';
    static readonly MSG_POPUP_TITLE_CVV = 'Cambio de NIP';
    static readonly MSG_POPUP_TITLE_CVVD = 'CVV Dinámico';
    static readonly MSG_POPUP_TITLE_CANCEL_CARD = 'Cancelación exitosa';
    static readonly CARD_REPLACEMENT_DESK = 'Solicitar Reposición';
    static readonly CARD_CVV_ACEPT = 'Aceptar';
    static readonly MSG_DESCRIPTION_CANCEL_CARD = `Hemos cancelado tu tarjeta. Ahora debes solicitar la
    reposición de tu tarjeta. Por favor, realiza la solicitud lo antes
    posible para evitar problemas en tus transacciones`;
    static readonly MSG_DESCRIPTION_CVV = 'Para concluir el proceso debes realizar una consulta de saldo en un cajero automático Inbursa';
    static readonly MSG_DESCRIPTION_CVVD = 'Registro exitoso';
    static readonly MSG_SUCCESSFUL_PIN_MODIFICATION = 'Para concluir el proceso debes realizar una consulta de saldo en un cajero automático Inbursa';
    static readonly MSG_DEACTIVATE = 'Has DESACTIVADO tu tarjeta para';
    static readonly MSG_ACTIVATE = 'Has ACTIVADO tu tarjeta para';
    static readonly BTN_ACCEPT = 'Aceptar';
    static readonly CARD_REPLACEMENT = 'Solicitar reposición';
    static readonly MSG_DIGIT_PANEL = 'Ubica los 9 dígitos que se encuentra en el panel de firma al reverso de tu tarjeta';
    static readonly MSG_CVV_INPUT = 'Ubica los 3 dígitos del código de seguridad que se encuentra al reverso de tu tarjeta';

    static readonly CONTROL_CARDS ={
        TitleProcessCancelCard: '¿Estás seguro que  </br>quieres cancelar tu tarjeta?',
        Card:'Tarjeta de'
      };
    static readonly ERROR_SELECT_DATE = 'Seleccionar una fecha';
    static readonly ERROR_SELECT_VALID_DATE = 'Selecciona una fecha válida.';
    static readonly OPERATION_TYPE = 'Selecciona el tipo de operación';
    static readonly CANCEL_REASON_TITLE = 'Motivo por el que deseas cancelar tu tarjeta';
    static readonly BRANCH_TITLE = 'Sucursal o domicilio';
    static readonly ADDITIONAL_GENDER_TITLE = 'Sexo';
    static readonly ADDITIONAL_CIVIL_STATUS = 'Estado Civil';
    static readonly REASON = 'reason';
    static readonly GENDER = 'gender';
    static readonly BRANCH = 'branch';
    static readonly CIVIL_STATUS = 'civilStatus';
    static readonly MAP_SUBTITLE = 'Selecciona una sucursal cercana para recoger tu tarjeta';
    static readonly CONTINUE='Continuar';
    static readonly OPTION_ERROR='Este campo es requerido';
    static readonly VALUE_DATA='data';
    static readonly ADDITIONAL_CREDIT_CARD='Solicitud de tarjeta de crédito adicional';
    static readonly ADDITIONAL_DEBIT_CARD='Solicitud de tarjeta de débito adicional';
    static readonly PERCENT_DEBIT_CARD='Porcentaje de línea de débito que tendrá la tarjeta adicional';
    static readonly PERCENT_CREDIT_CARD='Porcentaje de línea de crédito que tendrá la tarjeta adicional';
    static readonly MSG_ERROR_LENGHT = (digit: number) => `Este campo debe contener ${digit} dígitos`;

    static readonly OPERATION_OPTIONS={
        OPTIONS:[
            {
                text:'Bloqueos y límites',
                value:'bloqueo',
                selected:true,
                disabled:false,
                beta: true
            },
            {
                text:'Modificación de NIP',
                value:'modifica',
                selected:false,
                disabled:false,
                beta: true
            },
            {
                text:'CVV dinámico',
                value:'cvv',
                selected:false,
                disabled:false,
                beta: true
            },
            {
                text:'Cancelar tarjeta',
                value:'cancela',
                selected:false,
                disabled:false,
                beta: !environment.hiddenComponents.cancelCardBeta
            },
            {
                text:'Tarjeta adicional',
                value:'adicional',
                selected:false,
                disabled:false,
                beta:true
            },
            {
                text:'Consulta estatus de tarjetas',
                value:'estatus',
                selected:false,
                disabled:false,
                beta:!environment.hiddenComponents.controlCardBeta
            },
        ],
        EXCLUDED_OPTIONS:[
            {
              type:DebitCardType.WebCard,
              values:['modifica','adicional']
            }
        ]
    };
    static readonly CANCEL_OPTIONS=[
            {
                label:'Robo',
                value:'Robo'
            },
            {
                label:'Extravío',
                value:'Extravío'
            },
            {
                label:'Daño',
                value:'Daño'
            },
    ];
    static readonly ADDITIONAL_GENDER_OPTIONS=[
        {
            label:'Masculino',
            value:'Masculino'
        },
        {
            label:'Femenino',
            value:'Femenino'
        }
    ];
    static readonly ADDITIONAL_CIVIL_OPTIONS=[
        {
            label:'Soltero(a)',
            value:'Soltero(a)'
        },
        {
            label:'Casado(a)',
            value:'Casado(a)'
        },
        {
            label:'Divorciado(a)',
            value:'Divorciado(a)'
        },
        {
            label:'Separado(a)',
            value:'Separado(a)'
        },
        {
            label:'Unión libre',
            value:'Unión libre'
        },
        {
            label:'Viudo(a)',
            value:'Viudo(a)'
        }
    ];
    static readonly BRANCH_OPTIONS=[
        {
            label:'Sucursal',
            value:'sucursal'
        },
        {
            label:'Domicilio',
            value:'domicilio'
        }
    ];

    static readonly STARTS_WITH = '00';
    static readonly BUTTONS_PROPERTIES = 'btn-generic btn-activation modals-btn';
    static readonly CREDIT_CANCELLATION = {
      Information:{
        PlasticReminder: 'Recuerda que se genera únicamente la cancelación del plástico.',
        CreditLine: 'Si deseas efectuar la cancelación de la línea de crédito deberás acudir a la sucursal de preferencia con la cuenta en "0" e identificación oficial.'
      }
    };
}
