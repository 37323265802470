import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { InputConfig } from 'src/app/interface/IInputConfig';
import { Regex, Char, Numbers, Constants, Language, InputTypes, FormValidations, Keys } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { AforeConstants } from 'src/core/constants/AforeConstants';

export class FormUtils {
  static onlyTextInput($event: Event, name: string, formGroup: FormGroup) {
    let data = $event ? ($event.target as HTMLInputElement).value : formGroup.get(name).value;
    const regex = Regex.OnlyText;
    const regexSpace=Regex.ValidateSpace;
    data = data.trimStart();
    data = data.replace(regex, Strings.EMPTY);
    data = data.replace(regexSpace, Char.WhiteSpace);
    formGroup.patchValue({
      [name]: data
    });
  }

  static reformatToEditInput($event: Event, name: string, formGroup: FormGroup) {
    const target = $event.target as HTMLInputElement;
    const value = target.value;

    if (target.value.includes(Char.CurrencySymbol)) {
      const start = target.selectionStart - Numbers.One;
      const end = target.selectionEnd - Numbers.One;

      const index = value.lastIndexOf(Char.Comma);

      let count = Number(Numbers.Zero);

      target.value.split(Strings.EMPTY).forEach((el: string) => {
        if (el === Char.Comma) count++;
      });

      const regex = Regex.Reformat;

      const formated = target.value.replace(regex, Strings.EMPTY);

      formGroup?.patchValue({
        [name]: formated
      });

      const spacesToRemove = count === Numbers.One ? Numbers.One : count;

      if (index === Constants.NOT_FOUND_ITEM) {
        target.setSelectionRange(start, end);
      } else {
        if (start > index) {
          target.setSelectionRange(start - spacesToRemove, end - spacesToRemove);
        } else {
          target.setSelectionRange(start, end);
        }
      }
    }
  }

  static currencyInput($event: Event, name: string, formGroup: FormGroup) {
    let data = ($event.target as HTMLInputElement).value;

    const regex = Regex.DifferentFromDecimals;
    data = data.replace(regex, Strings.EMPTY);

    let ocurrences = Numbers.Zero;

    data.split(Strings.EMPTY).forEach(el => {
      if (el === Char.Dot) ocurrences++;
    });

    if (ocurrences !== Numbers.Zero) {
      let dotIndex = Numbers.Zero;
      const splited = data.split(Strings.EMPTY);

      for (let index = Numbers.Zero; index < splited.length; index++) {
        if (splited[index] === Char.Dot) {
          dotIndex = index;
          break;
        }
      }

      const firtsBeforeDots = data.substring(Numbers.Zero, dotIndex + Numbers.One);
      const afterDots = data.substring(dotIndex + Numbers.One).replace(Char.Dot, Strings.EMPTY);

      data = `${firtsBeforeDots}${afterDots}`;
    }

    formGroup.patchValue({
      [name]: data
    });
  }

  static currencyInputWhitDecimals($event: Event, name: string, formGroup: FormGroup) {
    let data = ($event.target as HTMLInputElement).value;

    const regex = Regex.DifferentFromNumbersDecimals;
    data = data.replace(regex, Strings.EMPTY);

    let ocurrences = Numbers.Zero;

    data.split(Strings.EMPTY).forEach(el => {
      if (el === Char.Dot) ocurrences++;
    });

    if (ocurrences !== Numbers.Zero) {
      let dotIndex = Numbers.Zero;
      const splited = data.split(Strings.EMPTY);

      for (let index = Numbers.Zero; index < splited.length; index++) {
        if (splited[index] === Char.Dot) {
          dotIndex = index;
          break;
        }
      }

      const firtsBeforeDots = data.substring(Numbers.Zero, dotIndex + Numbers.One);
      let afterDots = data.substring(dotIndex + Numbers.One).replace(Char.Dot, Strings.EMPTY);

      afterDots = afterDots.substring(Numbers.Zero, Numbers.Two);

      data = `${firtsBeforeDots}${afterDots}`;
    }

    formGroup.patchValue({
      [name]: data
    });
  }

  static parseAmount(amount: number): string {
    return parseFloat(`${amount}`).toLocaleString(Language.EnglishUS, {
      maximumFractionDigits: Constants.TOTAL_DECIMALS, minimumFractionDigits: Constants.TOTAL_DECIMALS
    }).replace(Regex.Slash, Char.Dot).replace(Regex.MatchComma, Char.Comma);
  }

  static onlyNumbersInput($event: Event, name: string, formGroup: FormGroup) {
    let data = $event ? ($event.target as HTMLInputElement).value : formGroup.get(name).value;
    const regex = Regex.DifferentFromDigits;
    data = data.replace(regex, Strings.EMPTY);
    const regexExtra = Regex.OnlyLetters;
    data = data.replace(regexExtra, Strings.EMPTY);

    formGroup.patchValue({
      [name]: data
    });
  }

  static alphaNumericInput($event: Event, inputName: string, formGroup: FormGroup, regexp?: RegExp) {
    let data = $event ? ($event.target as HTMLInputElement).value : formGroup.get(inputName).value;
    let regex = regexp;
    if (regexp === undefined) {
      regex = Regex.DifferentFromAlphanumeric;
    }
    data = data.trimStart();
    data = data.replace(regex, (match: string) =>{
      if(match === Char.WhiteSpace){
        return match;
      } else {
        return Char.WhiteSpace;
      }
    });
    formGroup.patchValue({
      [inputName]: data
    });
  }

  static alphaNumericOneSpaceInput($event: Event, name: string, formGroup: FormGroup){
    let data = $event ? ($event.target as HTMLInputElement).value : formGroup.get(name).value;
    const regex = AforeConstants.NUMBERS_LETTERS_ONE_SPACE;
    data = data.replace(regex, Strings.EMPTY);
    data = data.trimStart();
    formGroup.patchValue({
      [name]: data
    });
  }

  static getServiceNameConfig(value: string, validationMessage?: string, skipValidations = false): InputConfig {
    const config: InputConfig = {
      type: InputTypes.Text,
      label: Strings.ADD_SERVICE.ServiceText,
      name: Strings.ADD_SERVICE.ServiceName,
      value,
      placeholder: Strings.ADD_SERVICE.ServiceText,
      validations: [],
      readonly: true
    };

    if (!skipValidations) {
      const validation: { name: string, validator: ValidatorFn, message?: string } = {
        name: FormValidations.Required,
        validator: Validators.required
      };

      if (validationMessage) {
        validation.message = validationMessage;
      }

      config.validations.push(validation);
    }

    return config;
  }
  static onKeyPress(event: KeyboardEvent) {
    const charCode = event.key;
    if(charCode === Char.WhiteSpace) {
      event.preventDefault();
      return false;
    }
    if (!isNaN(Number(charCode)) || event.ctrlKey || event.metaKey || event.key === Keys.Backspace || event.key === Keys.Delete) {
        return true;
    } else {
        event.preventDefault();
        return false;
    }
  }
}
