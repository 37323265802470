import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISearch } from '../interface/ISearch';

@Injectable({
  providedIn: 'root'
})
export class SearchMovementsService {


  private readonly filteredMovement: BehaviorSubject<ISearch> = new BehaviorSubject<ISearch>({
    concept: null,
    date: null, 
    amount: null
  });

  constructor() { }

  get filteredMovementObservable() {
    return this.filteredMovement.asObservable();
  }

  set filteredMovementData(data: ISearch) {
    this.filteredMovement.next(data);
  }
}
