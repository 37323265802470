import { AccountType } from 'src/core/constants/Constants';

export class AddressConstants {
  static readonly POSTAL_CODE = 'C.P.';
  static readonly COLONY = 'Col.';
  static readonly FORMAT_REGEX = /, $/;
  static readonly TAB_INDEX_BROKERAGE_HOUSE = 4;
  static readonly WITHOUT_AGENDAS_BROKERAGE_HOUSE_CODE = '20020';
  static readonly FILTER_CHECK_ACCOUNTS = [AccountType.GroceryCoupon, AccountType.WEB];
  static readonly CARD_HEIGHT = 208;
  static readonly EXTRA_SPACE_MOBILE = 169;

  static readonly NORMALIZE_CHARACTERS = {
    'á': 'a', 'à': 'a', 'ä': 'a', 'â': 'a', 'ã': 'a',
    'é': 'e', 'è': 'e', 'ë': 'e', 'ê': 'e',
    'í': 'i', 'ì': 'i', 'ï': 'i', 'î': 'i',
    'ó': 'o', 'ò': 'o', 'ö': 'o', 'ô': 'o', 'õ': 'o',
    'ú': 'u', 'ù': 'u', 'ü': 'u', 'û': 'u',
    'ñ': 'n'
  };
}
