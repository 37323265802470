import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CodeflexLayoutService {
  private readonly _isCodeflex: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  get isCodeflex$(): Observable<boolean> {
    return this._isCodeflex.asObservable();
  }

  set isCodeflex(isCodeflex: boolean) {
    this._isCodeflex.next(isCodeflex);
  }
}
