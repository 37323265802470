<ng-container *ngIf="vm$ | async as vm">
  <div
    #cardContainer
    (click)="selectedCardButton(card)"
    (keydown.enter)="selectedCardButton(card)"
    class="p-3 drop-shadow-m"
    [ngClass]="wInvestment && vm.breakpoints.mobile ? 'card-account-investment':
    isDirectDebit && vm.breakpoints.mobile ? 'card-account-image' : 'card-account-b'"
    [class.selected]="card.selected"
    [class.w-100]="vm.breakpoints.mobile"
    [class]="style"
  >
    <img
      *ngIf="!vm.breakpoints.mobile || isDirectDebit"
      class="mt-2"
      width="54"
      height="34"
      [src]="card.image.includes(Resources.GET_URL_IMAGE) ? card.image : (card.image)"
      [alt]="card.cardType"
    />
    <section class="d-flex flex-column">
      <text-ellipsis [text]="card.cardType" classes="detail-s default-color"></text-ellipsis>
      <span class="header-xs">{{ card.cardNumber | maskAccount : 4 }}</span>
    </section>
    <section  [ngClass]="{'box-account-balance': !vm.showAccountBalance && !showAccountBalance }"
    *ngIf="!isDirectDebit" class="d-flex flex-column">
      <text-ellipsis [text]="card.balance | currency" [classes]="styleCard==5&& card.balance.length > 10?'
      font-size-card-insurance header-xs text-end d-block':' header-xs text-end d-block'"></text-ellipsis>
      <span class="detail-s text-end default-color" [ngClass]="vm.breakpoints.tablet? 'me-1':''"> Disponible </span>
    </section>
    <section *ngIf="!hideKey && !isDirectDebit" id="Clabe" class="d-flex flex-column">
      <span class="detail-s default-color"> CLABE </span>
      <span class="detail-s default-color">{{
        card.clabe | numberFormat : 4
        }}</span>
    </section>
  </div>
</ng-container>
