<div *ngIf="vm$ | async as vm" class="container">
  <div class="row">
    <div class="col-12 align-center mt-2">
      <img [src]="checkIcon | cdnImage" class="icon-check" alt="Operación Correcta" width="auto" height="auto">
      <div class="d-flex flex-row pt-3 pl-2 justify-content-center header-l main-color-label">
        Modificación exitosa
      </div>
      <div class="d-flex flex-row pt-3 pl-2 justify-content-center subtitle-modal body-m">
        {{codeType == agendaInternationalID ? 'Folio:' + vm.accountModify.invoice:''}}
      </div>
      <div class="pb-3"
        [ngClass]="{'nonagendable-padding':(vm.accountModify.type == nonAgendableType)}">{{completeDate}}h
      </div>
    </div>
  </div>
</div>

<div *ngIf="vm$ | async as vm">
  <div *ngIf="vm.accountModify.type != nonAgendableService">
    <div id="date-application">
      <label class="body-m header-xs mt-3 mb-3 col-12 h-0">Datos del destinatario</label>
      <div>
        <label class="body-s">{{vm.accountModify.account}}</label>
        <label class="detail-s col-12">{{accountLabel}}</label>
        <hr class="my-2">
      </div>
      <div *ngIf="vm.accountModify.alias && vm.accountModify.alias.length > 0">
        <label class="body-s word-wrap main-color-label">{{vm.accountModify.alias}}</label>
        <label class="detail-s col-12"> Alias </label>
        <hr class="my-2">
      </div>
    </div>
  </div>
  <div *ngIf="vm.accountModify.type == nonAgendableService" id="date-application">
    <label class="body-m header-xs mt-3 mb-3 col-12"
      [ngClass]="{'nonagendable-margin':(vm.accountModify.type == nonAgendableType)}">Datos del destinatario
    </label>
    <div>
      <label class="body-s main-color-label">{{vm.accountModify.name}}</label>
      <label class="detail-s mt-1 col-12">Nombre del servicio</label>
      <hr class="my-2">
    </div>
  </div>
  <div>
    <div id="date-application">
      <div>
        <label class="body-s main-color-label">{{vm.accountModify.maximumLimit|currency}}</label>
        <label class="detail-s col-12">Límite diario máximo</label>
        <hr class="my-2">
      </div>
    </div>
    <div *ngIf="codeType == agendaInternationalID || codeType == agendaOthersBanksID ||
    codeType == agendaThirdPartiesID ">
      <div id="date-application" *ngIf="vm.accountModify.email && vm.accountModify.email.length > 0">
        <label class="body-m header-xs mt-1 mb-1 col-12">
          Contacto para notificaciones</label>
        <div class="word-wrap">
          <label class="body-s main-color-label">{{vm.accountModify.email}}</label>
          <label class="detail-s col-12"> Email </label>
          <hr class="my-2">
        </div>
      </div>
    </div>
  </div>
</div>
