
export class CodeflexConstants {
  static readonly CODEFLEX_ROOT = 'codefl';
  static readonly CODEFLEX_DAYS = 10;
  static readonly CODEFLEX91_DAYS = 91;
  static readonly CODEFLEX_DAYS_31 = 31;
  static readonly CODEFLEX_MONTH = 12;
  static readonly CODEFLEX_YEAR = 9999;
  static readonly CODEFLEX_ACCOUNT = (accountType: string, accountNumber: string) => `${accountType} (${accountNumber})`;
  static readonly CODEFLEX_KEYS = [
  'codefl91',
  'code91',
  'codeflex'
  ];

}

export enum CodeflexNumbers {
  TwentyFour = 24,
  Thirty = 30,
  Sixty = 60,
  Forty = 40,
  Thousand = 1000
}

export enum CodeflexFormatDate {
  HoursMinutesSeconds = 'hh:mm:ss',
  YearMonthDayTime= 'yyyyMMddhhmmss'
}

export enum CodeflexElementsHTML{
  ButtonsSpacingMobile = 'pt-3 mt-2',
  ButtonsSpacingDesktop = 'pt-4 mt-3',
  ColoredButton = 'colored-btn',
  OutlineButton = 'outline-btn',
  SuccessfulOperation = 'successful-operation'
}
