import { ScheduleTransferRequest, TScheduleTransferRequest } from 'src/app/interface/dto/ScheduleTransferDataRequest';
import { Injectable, WritableSignal, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Strings } from 'src/core/constants/Strings';
import { SendAnotherDateService } from 'src/app/services/send-another-date.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { ScheduleTransferOtherBankRequest, TScheduleTransferOtherBankRequest } from 'src/app/interface/dto/ScheduleTransferOtherBankDataRequest';
import { TBaseTransferRequest } from 'src/app/interface/TransferData';
import { Numbers } from 'src/core/constants/Numbers';
import { Char, Constants, Length, Months, Position } from 'src/core/constants/Constants';
import { Utils } from 'src/core/utils/utils';
import { AccountCardService } from 'src/app/services/account-card.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { FormGroup } from '@angular/forms';
import { DateFormat } from 'src/app/interface/Date';
import { TransferStrings } from 'src/core/constants/TransferStrings';
import { IAddresseeAccount } from 'src/app/interface/IAddresseeAccount';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


export interface ScheduledTransferData {
  periodicity: string;
  duration: string;
  durationQuantity: string;
  initialDate: string;
  finalDate: string;
  valuePeriodicity: string;
}

@Injectable({
  providedIn: 'root'
})
export class ScheduledTransferService {
  private readonly DEFAULT_DATA = {
    periodicity: Strings.EMPTY,
    duration: Strings.EMPTY,
    durationQuantity: Strings.EMPTY,
    initialDate: Strings.EMPTY,
    finalDate: Strings.EMPTY,
    valuePeriodicity: Strings.EMPTY
  };
  private readonly scheduledTransferBase: TBaseTransferRequest = {
    alias: Strings.EMPTY,
    fechaInicio : Strings.EMPTY,
    fechaFin: Strings.EMPTY,
    frecuencia: Numbers.Zero,
    idAgenda: Numbers.Zero,
    importe: Numbers.Zero,
    numeroCuentaOrigen: Strings.EMPTY,
    nombreTitular: Strings.EMPTY,
    tipoFrecuencia: Strings.EMPTY,
    tipoTransferencia:Strings.EMPTY,
    otp: Strings.EMPTY,
    referenciaAlfanumerica: Strings.EMPTY,
    referenciaNumerica: Numbers.Zero
  };
  private readonly scheduledTransferOtherBankData:TScheduleTransferOtherBankRequest = {
    ...this.scheduledTransferBase,
    banco: Strings.EMPTY,
    numeroCuentaExterna: Strings.EMPTY,
    referenciaAlfanumerica:  Strings.EMPTY,
    referenciaNumerica: Numbers.Zero,
    idCuentaProducto: Numbers.Zero
  };

  private readonly scheduledTransferData:TScheduleTransferRequest = {
    ...this.scheduledTransferBase,
    numeroCuentaDestino: Strings.EMPTY,
    idCuentaProductoOrigen:Numbers.Zero
  };
  private readonly _isScheduledProcess: WritableSignal<boolean> = signal<boolean>(false);
  private readonly _scheduledTransferData: BehaviorSubject<ScheduledTransferData> = new BehaviorSubject<ScheduledTransferData>(this.DEFAULT_DATA);
  private readonly _scheduledTransferOwnBanks: BehaviorSubject<TScheduleTransferRequest> = 
  new BehaviorSubject<TScheduleTransferRequest>(this.scheduledTransferData);
  private readonly _scheduledTransferOtherBankData: BehaviorSubject<TScheduleTransferOtherBankRequest> =
  new BehaviorSubject<TScheduleTransferOtherBankRequest>(this.scheduledTransferOtherBankData);
  private readonly _validateForm: WritableSignal<boolean> = signal<boolean>(false);
  private readonly _isFormError: WritableSignal<boolean> = signal<boolean>(false);
  private readonly _isClearScheduled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _isSelectedDate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _isErrorDates: BehaviorSubject<{initDate:boolean,endDate:boolean}> 
  = new BehaviorSubject<{initDate:boolean,endDate:boolean}>({initDate:false,endDate:false});
  private readonly _isFutureTransfer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _dateFutureTransfer: BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);
  constructor(private readonly sendAnotherDateService: SendAnotherDateService,
    readonly accountCardService: AccountCardService,
    readonly notifyAmountService: NotifyAmountService,
    private readonly baseService: IBaseService) { }

  get isFormError() {

    return this._isFormError();
  }

  set isFormError(data: boolean) {

    this._isFormError.set(data);
  }

  get validateForm() {
    return this._validateForm();
  }

  set validateForm(data: boolean) {
    this._validateForm.set(data);
  }

  get isScheduledProcess() {
    return this._isScheduledProcess();
  }

  set isScheduledProcess(data: boolean) {
    this._isScheduledProcess.set(data);
  }

  get scheduledTransfer$() {
    return this._scheduledTransferData.asObservable();
  }

  set scheduledTransfer(data: ScheduledTransferData) {
    this._scheduledTransferData.next(data);
  }

  get scheduledTransfer(): ScheduledTransferData {
    return this._scheduledTransferData.getValue();
  }

  get scheduleTransferOtherBankData$(){
    return this._scheduledTransferOtherBankData.asObservable();
  }
  set scheduleTransferOtherBankData(data:TScheduleTransferOtherBankRequest){
    this._scheduledTransferOtherBankData.next(data);
  }
  get scheduleTransferOtherBankData():TScheduleTransferOtherBankRequest{
    return this._scheduledTransferOtherBankData.getValue();
  }

  get scheduledTransferOwnBanks$() {
    return this._scheduledTransferOwnBanks.asObservable();
  }
  get scheduledTransferOwnBanks():TScheduleTransferRequest{
    return this._scheduledTransferOwnBanks.getValue();
  }
  set scheduledTransferOwnBanks(data:TScheduleTransferRequest){
    this._scheduledTransferOwnBanks.next(data);
  }
  
  get isSelectedDate$(){
    return this._isSelectedDate.asObservable();
  }
  get isSelectedDate():boolean{
    return this._isSelectedDate.getValue();
  }
  set isSelectedDate(flag:boolean){
    this._isSelectedDate.next(flag);
  }

  get isErrorDates$(){
    return this._isErrorDates.asObservable();
  }
  get isErrorDates():{initDate:boolean,endDate:boolean}{
    return this._isErrorDates.getValue();
  }
  set isErrorDates(flags:{initDate:boolean,endDate:boolean}){
    this._isErrorDates.next(flags);
  }

  clearScheduledTransfer() {
    this.isScheduledProcess = false;
    this.isFutureTransfer = false;
    this._scheduledTransferData.next(this.DEFAULT_DATA);
    this.sendAnotherDateService.isOtherDate.value = false;
    this.isSelectedDate = false;
  }

  clearScheduleDataRequest(){
    this.scheduleTransferOtherBankData = this.scheduledTransferOtherBankData;
    this.scheduledTransferOwnBanks = this.scheduledTransferData;
  }
  set isClearScheduled(data: boolean) {
    this._isClearScheduled.next(data);
  }

  get isClearScheduled(){
    return this._isClearScheduled.getValue();
  }

  setScheduleTransferBaseDataObject(addressee:IAddresseeAccount, transferDataForm:FormGroup, 
    startDate:DateFormat, isOwnTransfer:boolean): TBaseTransferRequest{
    let dateEnd = Strings.EMPTY;
    const initDay = String(startDate.day).padStart(Length.Two,String(Numbers.Zero));
    const initMonth =  String(startDate.month).padStart(Length.Two,String(Numbers.Zero));
    const dateInit = `${initDay}${Char.Slash}${initMonth}${Char.Slash}${startDate.year}`;
    let [day, month, year] = this.scheduledTransfer.finalDate.split(Char.Slash);
      const monthValues = Object.values(Months);
      month = month.charAt(Position.Zero).toUpperCase() + month.slice(Position.One);
      const monthNumber = (monthValues.indexOf(month as Months) + Numbers.One).toString().padStart(Length.Two, String(Numbers.Zero));
      if(monthNumber){
        dateEnd = `${day}${Char.Slash}${monthNumber}${Char.Slash}${year}`;
      }

     let typeScheduleTransfer = TransferStrings.TYPE_SCHEDULE_TRANSFER[Position.Zero];
     if(transferDataForm.controls[Constants.FORM_CONTROLS.Type].value !== Strings.EMPTY 
      && transferDataForm.controls[Constants.FORM_CONTROLS.Type].value !== Constants.FORM_CONTROLS.Spei){
      typeScheduleTransfer = TransferStrings.TYPE_SCHEDULE_TRANSFER[Position.One];
     }
     
      return  {
       alias: addressee.nombre_alias ?? Strings.EMPTY,
       fechaInicio : dateInit,
       fechaFin: dateEnd,
       frecuencia: Number(this.scheduledTransfer.durationQuantity),
       idAgenda: isOwnTransfer? undefined : addressee?.idAgenda,
       importe: Number(Utils.transformAmount(String(this.notifyAmountService.amount.amount))),
       numeroCuentaOrigen: this.accountCardService.accountCard.accountNumber,
       nombreTitular: addressee?.nombre,
       tipoFrecuencia: this.scheduledTransfer.valuePeriodicity,
       tipoTransferencia:  typeScheduleTransfer,
       otp: Strings.EMPTY,
       referenciaAlfanumerica: transferDataForm.controls[Constants.FORM_CONTROLS.Concept].value ?? Strings.EMPTY,
      referenciaNumerica: transferDataForm.controls[Constants.FORM_CONTROLS.Reference].value ?? Numbers.Zero
    };
  }
  setScheduleTransferDataOtherBankObject(addressee:IAddresseeAccount,baseTransferRequest:TBaseTransferRequest):TScheduleTransferOtherBankRequest{
    return {
      ...baseTransferRequest,
      banco: addressee.banco,
      numeroCuentaExterna: addressee?.clabe,
      idCuentaProducto: this.accountCardService.accountCard.productId
    };
  }
  setScheduleTransferDataOwnBankObject(addressee:IAddresseeAccount,baseTransferRequest:TBaseTransferRequest,isOwnTransfer:boolean):TScheduleTransferRequest{
    return {
      ...baseTransferRequest,
      numeroCuentaDestino: isOwnTransfer ? addressee.cuentaDestino : addressee.clabe,
      idCuentaProductoOrigen: this.accountCardService.accountCard.productId,
      idCuentaProductoDestino: isOwnTransfer ? addressee.idCuentaProductoDestino : undefined
    };
  }

  scheduleTransferPost(isTransferOtherBank: boolean, dataTransfer?: TScheduleTransferRequest, 
    dataTransferOtherBank?: TScheduleTransferOtherBankRequest): Promise<GenericResponse<number>> {
    const request = isTransferOtherBank ? new ScheduleTransferOtherBankRequest(dataTransferOtherBank) 
    : new ScheduleTransferRequest(dataTransfer);
    return this.baseService.genericPost<GenericResponse<number>>(request, { addSession: true, isSecure: true });
  }

  set isFutureTransfer(flag:boolean){
    this._isFutureTransfer.next(flag);
  }

  get isFutureTransfer$(){
    return this._isFutureTransfer.asObservable();
  }

  get isFutureTransfer():boolean{
    return this._isFutureTransfer.getValue();
  }

  set dateFutureTransfer(date:string){
    this._dateFutureTransfer.next(date);
  }

  get dateFutureTransfer$(){
    return this._dateFutureTransfer.asObservable();
  }

  get dateFutureTransfer():string{
    return this._dateFutureTransfer.getValue();
  }

  setDateFuture(date:NgbDateStruct):string{
    const day = String(date.day).padStart(Length.Two,String(Numbers.Zero));
    const month = String(date.month).padStart(Length.Two,String(Numbers.Zero));
    const year = date.year;
    return `${day}${Char.Slash}${month}${Char.Slash}${year}`;
  }
  showTokenScheduledOwnTransfer(isFacePass:boolean, amount: number):boolean{
    return !isFacePass || amount >= Constants.MAXIMUM_AMOUNT_TRANSFER_EXPRESS_FACEPASS;
  }
}
