<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="!isPatrimonialTransfer && !viewDeductible">
    <div [class]="completeInsurance?'main-content class-insurance ':'main-content'" [ngClass]="{'mt-5':
    isCreditPaymentCompleted == true,
     'main-content-padding':!vm.breakpoints.mobile && isInternationalTransfer,
      'main-deductible': viewDeductible}">
      <div [ngClass]="viewDeductible?'w-deductible top-responsive':''">
        <div *ngIf="!isCreditPaymentCompleted ">
          <div *ngIf="toPayDeductible " [ngClass]="viewDeductible ? 'margin-deductible' : ''">
            <div>
              <div [class]="viewInsurance?'data-payment-msi':''"
              [ngClass]="viewDeductible ? '' : 'min-content-main'"
              [ngClass]="!viewDeductible && vm.breakpoints.mobile ? 'mt-3 d-flex' : 'd-flex'">
                <div>
                  <show-amount [useBreak]="true" [label]="!isInternationalTransfer ? labelAmount : ''"
                    color="var(--primary-1000)"></show-amount>
                  <div *ngIf="isInternationalTransfer">
                    <span class="currency header-s">
                      {{ badge }}
                    </span>
                  </div>
                </div>
              </div>
              <div *ngIf="isInternationalTransfer || policyPayment">
                <label class="detail-s">Tipo de cambio: {{exchangeRateFixed |
                  currency: 'USD' : 'symbol-narrow' : '1.2-10'}}</label>
                <div class="detail-s">Cantidad en pesos: {{amountInternational | currency}}</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div id="data-payment" *ngIf="viewData" [class]="viewInsurance?'margin-top-msi pt-2':''"
            [ngClass]="viewDeductible ? 'm-to-pay' : ''">
            <div class="header-xs"
              [ngClass]="viewDeductible ? 'title-pay' : completeInsurance ?
              viewInvestment ? 'mt-3 mb-2' : 'pb-3 mb-3' : 'pb-3 mb-3 pt-4 mt-3'">
              {{title}}</div>
            <ng-container *ngIf="!viewInsurance">
              <div class="body-s" [ngClass]="viewDeductible ? 'label-deductible' : ''">{{labelServiceToPay}}</div>
              <app-info-label [head]="dataPolice" headClass="detail-s" bodyClass="body-s" [body]="labelPolicy"
                classes="" margin="my-2 hr-deductible"></app-info-label>
            </ng-container>
            <ng-container *ngIf="viewInsurance">
              <div class="detail-s" [ngClass]="viewDeductible ? 'label-deductible' : ''">{{labelServiceToPay}}
              </div>
              <app-info-label [head]="" headClass="detail-s" bodyClass="body-s" [body]="labelPolicy" classes=""
                [margin]="paymentOtherBank?'my-2 hr-deductible margin-msi margin-left-msi':
              'my-2 hr-deductible margin-left-msi'"></app-info-label>
            </ng-container>



            <div *ngIf="paymentOtherBank" class="row">
              <app-info-label [head]="'Moneda'" headClass="detail-s" bodyClass="body-s" [body]="'Nacional'"
                classes="mt-2" margin="my-2 hr-deductible margin-msi  margin-left-msi"></app-info-label>
              <app-info-label [head]="'Tipo de Cambio'" headClass="detail-s" bodyClass="body-s" [body]="'1'"
                classes="mt-2" margin="my-2 hr-deductible margin-msi  margin-left-msi"></app-info-label>
            </div>
          </div>
          <div [ngClass]="vm.breakpoints.mobile ? 'mt-3' : 'mt-4'" *ngIf="viewFirst && !isInternationalTransfer">
            <label class="header-xs pb-3" [ngClass]="viewInsurance ? 'style-color ' : ''">Desde tu
              cuenta</label>
            <div class="from-your-account">
              <div class="align-self-center">
                <img *ngIf="cardAddresseeService.selectedCardAddressee.banco === ''
                  && !showCreditPayment; else elseBlock" width="auto" height="auto" src={{selectedCard.image}}
                  alt="Bank Card" class="card-img-confirm">
                <ng-template #elseBlock>
                  <img src={{selectedCard.image}} class="card-img-confirm" alt="Bank Card" width="auto" height="auto">
                </ng-template>
              </div>
              <div *ngIf="cardAddresseeService.selectedCardAddressee.banco ===''; else whitCardImage">
                <label class="input-type-addressee-form-confirm-addressee main-color-label pt-2">
                  {{selectedCard.cardNumber | maskAccount: 4}}
                </label>
                <label class="detail-s col-12 detail-s-color">{{selectedCard.cardType}}</label>
              </div>
              <ng-template #whitCardImage>
                <div>
                  <div class="style-bottom">
                    <label class="detail-s col-12 detail-s-color">{{selectedCard.cardType}}</label>
                  </div>
                  <div>
                    <label class="input-type-addressee-form-confirm-addressee main-color-label pt-2">
                      {{selectedCard.cardNumber | maskAccount: 4}}
                    </label>
                  </div>
                </div>
              </ng-template>
            </div>
            <hr class="mt-2">
          </div>
          <div *ngIf="dataLast" [ngClass]="viewDeductible ? 'm-to-pay  ' : ''">
            <div class="mt-4" [ngClass]="{'mb-4' : viewInsurance, 'm-data-right': viewDeductible}">
              <span class="header-xs">{{otherLabelAccount}}</span>
            </div>
            <ng-container *ngIf="!viewInsurance">
              <div [ngClass]="viewDeductible ? 'aling-card' : 'plus pt-3'">
                <img class="col-product-img card-img-confirm movil tarjet_img" src={{selectedCard.image}} alt="image"
                  width="auto" height="auto" [ngClass]="viewDeductible ? 'none-img': ''" />
                <div class="movil" [ngClass]="viewDeductible ? 'space-deductible' : 'space-blank'"></div>
                <div [ngClass]="viewDeductible ? 'minus-card' : ''">

                  <div><span class="detail-m-highlight-hypertext">{{account | maskAccount: 4}}</span></div>
                  <div><span class="detail-s">{{selectedCard.cardType}}</span></div>
                </div>
              </div>
              <hr class="my-2 mb-3" [ngClass]="{'hr-investment': viewInvestment}">
            </ng-container>
            <ng-container *ngIf="viewInsurance">
              <div [ngClass]="vm.breakpoints.mobile?'plus pt-2':'plus pt-2 ps-3'" [class]="!paymentOtherBank?'pb-3':''">
                <img *ngIf="paymentOtherBank" class="col-product-img tarjet_img margin-img-msi-other"
                  [src]="iconCard | cdnImage" alt="image" width="40px" height="40px" />
                <img *ngIf="!paymentOtherBank" class="col-product-img card-img-confirm  tarjet_img margin-img-msi"
                  src={{selectedCard.image}} alt="image" width="auto" height="auto" />
                <div class="space-blank"></div>
                <div>
                  <div *ngIf="!paymentOtherBank">
                    <span class="detail-m-highlight-hypertext">{{account | maskAccount: 4}}</span>
                  </div>
                  <div *ngIf="!paymentOtherBank"><span class="detail-s">{{selectedCard.cardType}}</span></div>

                  <div *ngIf="paymentOtherBank"><span class="detail-m-highlight-hypertext">
                      {{cardOtherBank| maskAccount: 4}}</span></div>
                  <div *ngIf="paymentOtherBank"><span class="detail-s">{{cardSelected}}</span></div>
                </div>
              </div>
              <hr class="my-2 mb-3" [ngClass]="viewInsurance?paymentOtherBank?
'mb-3 me-2 ':'mb-3 margin-left-msi  ':''">
            </ng-container>
            <div class="row detail-left mt-2" *ngIf="conditions">
              <div *ngIf="!paymentOtherBank && !viewInvestment" class="class-div-msi">
                <label class="body-m header-xs mb-3 col-12 mt-3 rem-receipt style-color">
                  Información de la operación</label>
                <ng-container *ngIf="showTerm">
                  <label class=" rem-receipt body-s text-secondary-800 mt-2">{{monthsMSI}} MSI</label>
                  <label class="detail-s justify-content-right rem-receipt"> Plazo</label>
                  <hr class="my-2 ms-1 margin-left-msi">
                </ng-container>
                <label class=" rem-receipt body-s text-secondary-800 mt-2">
                  {{operationDate  | customDate:'dd/Month/yyyy'}}</label>
                <label class="detail-s justify-content-right rem-receipt">Fecha de operación</label>
                <hr class="my-2 ms-1 margin-left-msi">
              </div>
              <div *ngIf="paymentOtherBank" class="class-div-msi">
                <label class=" rem-receipt body-s text-secondary-800 mt-2 class-wrap">
                  {{dataPaymentOtherBank.name}}</label>
                <label class="detail-s justify-content-right rem-receipt">{{labelHolder}}</label>
                <hr class="my-2 hr-deductible margin-left-msi margin-msi line-insurance-msi"
                  [ngClass]="vm.breakpoints.mobile?'width-msi':''">
                <label class=" rem-receipt body-s text-secondary-800 mt-2 class-wrap">
                  {{dataPaymentOtherBank.email}}</label>
                <label class="detail-s justify-content-right rem-receipt">{{labelEmail}}</label>
                <hr class="my-2 hr-deductible line-insurance-msi margin-left-msi margin-msi"
                  [ngClass]="vm.breakpoints.mobile?'width-msi':''">
                <label class=" rem-receipt body-s text-secondary-800 mt-2">
                  {{dataPaymentOtherBank.phone}}</label>
                <label class="detail-s justify-content-right rem-receipt"> Télefono</label>
                <hr class="my-2 hr-deductible line-insurance-msi margin-left-msi margin-msi"
                  [ngClass]="vm.breakpoints.mobile?'width-msi':''">
                <label class="rem-receipt body-s text-secondary-800 mt-2 class-wrap">
                  {{dataPaymentOtherBank.address}}</label>
                <label class="detail-s justify-content-right rem-receipt">{{labelAddress}}</label>
                <hr class="my-2 hr-deductible line-insurance-msi margin-left-msi margin-msi"
                  [ngClass]="vm.breakpoints.mobile?'width-msi':''">
                <label class="rem-receipt body-s text-secondary-800 mt-2">
                  {{dataPaymentOtherBank.code}}</label>
                <label class="detail-s justify-content-right rem-receipt">{{labelZipCode}}</label>
                <hr class="my-2 hr-deductible line-insurance-msi margin-left-msi margin-msi"
                  [ngClass]="vm.breakpoints.mobile?'width-msi':''">
              </div>
            </div>
            <div class="row detail-left mt-2" *ngIf="!conditions">
              <div *ngIf="!paymentOtherBank" class="class-div-msi">
                <ng-container *ngIf="showTerm">
                  <label class="body-m header-xs mb-3 col-12 mt-3 rem-receipt style-color">
                    Información de la operación</label>
                  <label class=" rem-receipt body-s text-secondary-800 mt-2">{{monthsMSI}} MSI</label>
                  <label class="detail-s justify-content-right rem-receipt"> Plazo</label>
                  <hr class="my-2 hr-deductible margin-left-msi">
                </ng-container>
              </div>
              <div *ngIf="paymentOtherBank" class="class-div-msi">
                <label class=" rem-receipt body-s text-secondary-800 mt-2 class-wrap">
                  {{dataPaymentOtherBank.name}}</label>
                <label class="detail-s justify-content-right rem-receipt">{{labelHolder}}</label>
                <hr class="my-2 hr-deductible line-insurance-msi margin-left-msi margin-msi"
                  [ngClass]="vm.breakpoints.mobile?'width-msi':''">
                <label class=" rem-receipt body-s text-secondary-800 mt-2 class-wrap">
                  {{dataPaymentOtherBank.email}}</label>
                <label class="detail-s justify-content-right rem-receipt">{{labelEmail}}</label>
                <hr class="my-2 hr-deductible line-insurance-msi margin-left-msi margin-msi"
                  [ngClass]="vm.breakpoints.mobile?'width-msi':''">
                <label class=" rem-receipt body-s text-secondary-800 mt-2">
                  {{dataPaymentOtherBank.phone}}</label>
                <label class="detail-s justify-content-right rem-receipt"> Télefono</label>
                <hr class="my-2 hr-deductible line-insurance-msi margin-left-msi margin-msi"
                  [ngClass]="vm.breakpoints.mobile?'width-msi':''">
                <label class="rem-receipt body-s text-secondary-800 mt-2 class-wrap">
                  {{dataPaymentOtherBank.address}}</label>
                <label class="detail-s justify-content-right rem-receipt">{{labelAddress}}</label>
                <hr class="my-2 hr-deductible line-insurance-msi margin-left-msi margin-msi"
                  [ngClass]="vm.breakpoints.mobile?'width-msi':''">
                <label class="rem-receipt body-s text-secondary-800 mt-2">
                  {{dataPaymentOtherBank.code}}</label>
                <label class="detail-s justify-content-right rem-receipt">{{labelZipCode}}</label>
                <hr class="my-2 hr-deductible line-insurance-msi margin-left-msi margin-msi"
                  [ngClass]="vm.breakpoints.mobile?'width-msi':''">
              </div>
            </div>
          </div>
          <!-- INICIO para tipo de PAGO DE CRÉDITO Solo se muestra para esta opción -->
          <ng-container *ngIf="showCreditPayment">
            <div>
              <div [ngClass]="isCreditPaymentCompleted == true ? '':'mt-4'">
                <span class="body-s">Pago
                  de Crédito</span>
              </div>
              <app-info-label head="Número de crédito" headClass="detail-s" bodyClass="body-s" [body]="creditPayment"
                classes="" margin="my-2"></app-info-label>
            </div>
            <div class="mt-4">
              <span class="header-xs">Desde tu cuenta</span>
            </div>
            <div class="from-your-account mt-4 pt-2">
              <div class="align-self-center justify-self-center">
                <img class="card-img-confirm" [src]="selectedCard.image" alt="image" width="auto" height="auto" />
              </div>
              <div class="d-flex flex-column">
                <span class="body-s">{{cardTransferInternational | maskAccount: 4}}</span>
                <span class="detail-s">{{selectedCard.cardType}}</span>
              </div>
            </div>
            <hr class="my-2">
          </ng-container>
          <!-- FIN para tipo de PAGO DE CRÉDITO Solo se muestra para esta opción -->
          <!-- INICIO para tipo de TRANSFERENCIA INTERNACIONAL Solo se muestra para esta opción -->
          <ng-container *ngIf="isInternationalTransfer">
            <div>
            </div>
            <div class="mt-4">
              <span class="header-xs">Desde tu cuenta</span>
            </div>
            <div class="from-your-account-international mt-4">
              <div>
                <img [src]="bankCard | cdnImage" alt="image" width="auto" height="auto" />
              </div>
              <div>
                <div>
                  <span class="body-s">{{cardTransferInternational}}</span>
                </div>
                <div>
                  <span class="detail-s">{{selectedCard.cardType}}</span>
                </div>
              </div>
            </div>
            <hr class="my-2">
          </ng-container>
          <!-- FIN para tipo de  TRANSFERENCIA INTERNACIONAL Solo se muestra para esta opción -->
        </div>
        <!-- INICIO para tipo de TRANSFERENCIAS INTERNACIONALES Solo se muestra para esta opción -->
        <ng-container *ngIf="isInternationalTransfer">
          <div id="addressee" class="ps-2 mt-3 pt-3" *ngIf="!viewData || showCreditPayment">
            <label class="header-xs mb-3 pb-3">
              Destinatario
            </label>
            <div class="with-icon-form">
              <div>
                <img [src]="pillsForms | cdnImage" alt="Bank Card" width="auto" height="auto">
              </div>
              <div>
                <text-ellipsis
                      [text]="selectedAddressCard.nombre"
                      classes="body-s default-color"
                      [styles]="styles"></text-ellipsis>
                <label class="detail-s col-12">Banco {{selectedAddressCard.banco}}</label>
                <label class="detail-s col-12">CLABE {{selectedAddressCard.clabe}}</label>
              </div>
            </div>
            <hr class="mt-2">
          </div>
        </ng-container>
        <!-- FIN para tipo de TRANSFERENCIAS INTERNACIONALES Solo se muestra para esta opción -->
        <div *ngIf="!isInternationalTransfer">
          <div *ngIf="!showCreditPayment">
            <div id="addressee" *ngIf="(!viewData || showCreditPayment) && !isBrokerageHouseTransfer">
              <label class="header-xs pt-2 mb-1 pb-3"> Destinatario </label>
              <div *ngIf="cardAddresseeService.selectedCardAddressee.banco ===''; else destinatarioSeleccionado">
                <text-ellipsis [text]="expressTransferData.destinatario" [styles]="styles"></text-ellipsis>
                <label class="detail-s col-12">Banco {{expressTransferData.banco}}</label>
                <label *ngIf="isApp" class="detail-s col-12" >
                  CLABE {{expressTransferData.clabe | maskAccount: 4}}</label>
                <label *ngIf="!isApp" class="detail-s col-12" >CLABE {{expressTransferData.clabe}}</label>
              </div>
              <ng-template #destinatarioSeleccionado>
                <div class="row gap-1">
                  <div class="col-1">
                    <img [src]="pillsForms | cdnImage" alt="Bank Card" width="auto" height="auto">
                  </div>
                  <div class="col-10 ps-4">
                    <ng-container>
                      <text-ellipsis
                      [text]="selectedAddressCard.nombre"
                      classes="input-type-addressee-form-confirm-addressee"
                      [styles]="styles"></text-ellipsis>
                    </ng-container>
                    <ng-container>
                      <text-ellipsis
                      [text]="selectedAddressCard.nombre_alias"
                      classes="detail-s col-12 pt-2"
                      [styles]="styles"></text-ellipsis>
                    </ng-container>
                    <label class="detail-s col-12">Banco {{selectedAddressCard.banco}}</label>
                    <label class="detail-s col-12">CLABE {{selectedAddressCard.clabe | maskAccount: 4}}</label>
                  </div>
                </div>
              </ng-template>
              <hr class="my-2">
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showCreditPayment && !isInternationalTransfer"
        [ngClass]="viewDeductible ? 'w-deductible data-deductible' : ''">
        <ng-container  *ngIf="isBrokerageHouseTransfer" >
          <div id="addressee">
            <label class="header-xs pt-2 mb-1 pb-3"> Destinatario </label>
              <div class="row gap-1">
                <div class="col-1">
                  <img [src]="pillsForms | cdnImage" alt="Bank Card" width="auto" height="auto">
                </div>
                <div class="col-10 ps-4">
                  <ng-container>
                    <text-ellipsis
                    [text]="selectedAddressCard.nombre"
                    classes="input-type-addressee-form-confirm-addressee"
                    [styles]="styles"></text-ellipsis>
                  </ng-container>
                  <ng-container>
                    <text-ellipsis
                    [text]="selectedAddressCard.nombre_alias"
                    classes="detail-s col-12 pt-2"
                    [styles]="styles"></text-ellipsis>
                  </ng-container>
                  <label
                      class="detail-s col-12">Número de contrato {{selectedAddressCard.contrato}}</label>
                </div>
              </div>
            <hr class="my-2">
          </div>
        </ng-container>
        <div id="data-application" *ngIf="!viewData">
          <label class="body-m header-xs col-12 confirm-height"
          [ngClass]="vm.breakpoints.mobile ? 'mt-3' : 'mt-2'" [class.mb-5]="!isScheduledTransfer">
            {{
              validateExpressThirdTrasfer((expressTransfer$ | async)?.banco) || isThirdTransfer
              || isBrokerageHouseTransfer ?
              'Fecha de aplicación' : 'Tipo y fecha de aplicación'
            }}
          </label>
          <div
            *ngIf="!validateExpressThirdTrasfer((expressTransfer$ | async)?.banco) && !isThirdTransfer
            && !isBrokerageHouseTransfer">
            <label class="body-s main-color-label mt-3">
              {{(expressTransferExtraData$ | async)?.tipo}}
            </label>
            <label class="detail-s col-12">Tipo de transferencia</label>
            <hr class="my-2">
          </div>
          <ng-container *ngIf="!isScheduledTransfer(); else ScheduleTransfer">
            <ng-container *ngIf="(isDateFutureTransfer$ | async)">
              <label class="body-s col-12 main-color-label">
                {{(dateFutureTransfer$ | async) | customDate:'dd/Month/yyyy'}}</label>
              <label class="detail-s col-12 main-color-label">{{'Fecha de aplicación'}}</label>
            </ng-container>
            <ng-container *ngIf="!(isDateFutureTransfer$ | async)">
            <label class="body-s col-12 main-color-label " >
              {{((expressTransferExtraData$ | async)?.tipo === 'TEF'
              && !isAnotherDateTransfer() ? '24 hrs'
              : today | customDate:'dd/Month/yyyy')}}</label>
            <label class="detail-s col-12 main-color-label">{{(expressTransferExtraData$ | async)?.tipo === 'TEF'
              && !isAnotherDateTransfer() ? 'Tiempo de aplicación' : 'Fecha de aplicación'}}</label>
            </ng-container>
            <hr class="mt-2">
          </ng-container>
        </div>
        <div id="operation-data">
          <ng-container *ngIf="(expressTransferExtraData$ | async)?.concepto.length > 0 ||
            (expressTransferExtraData$ | async)?.referencia.length > 0">
            <label class="main-color-label"
             [ngClass]="viewDeductible ? 'header-xs ps-2 operation-deductible' : 'header-xs mb-2 pb-2 mt-3'">
              {{labelOperationInformation}}</label>
            <div id="data-express" *ngIf="!viewData">
              <ng-container *ngIf="(expressTransferExtraData$ | async)?.concepto.length > 0">
                <text-ellipsis
                  [text]="(expressTransferExtraData$ | async)?.concepto"
                  classes="body-s text-wrap main-color-label"
                  [styles]="styles">
                </text-ellipsis>
                <label class="detail-s col-12">Concepto </label>
                <hr [ngClass]="viewDeductible ? 'my-2 hr-deductible' : 'my-2'">
              </ng-container>
              <ng-container *ngIf="(expressTransferExtraData$ | async)?.referencia.length > 0">
                <label class="body-s text-wrap main-color-label">
                  {{(expressTransferExtraData$ | async)?.referencia}}</label>
                <label class="detail-s col-12">Referencia numérica</label>
                <hr class="mt-2">
              </ng-container>
            </div>
          </ng-container>
          <div *ngIf="viewData">
            <div id="dataInsurance" *ngIf="viewInsurance">
              <label class="header-xs mb-3 mt-3"> Recibos</label>
              <div *ngFor="let dataI of listInsurance">
                <div id={{dataI.id}} class="card-policy mb-2" [ngClass]="completeProcess?'mt-2':''">
                  <div class="row detail-left mt-3">
                    <label class="body-s justify-content-right color rem-receipt">Recibo: {{dataI.receipt}}</label>
                      <div class="policy">
                        <label id=amount-total class="button-m justify-content-right color rem-receipt">{{dataI.total |
                          currency}}</label>
                        <label class="badge-policy">{{badgePolicy}}</label>
                      </div>
                    <label class="detail-s justify-content-right rem-receipt">Límite de pago:
                      {{dataI.paymentDate | customDate:'dd/Month/yyyy'}}</label>
                    <hr class="mt-2 mb-3 ms-1" [ngClass]="viewInsurance ? 'width-msi' :''">
                  </div>
                </div>
              </div>
              <div *ngIf="paymentOtherBank&&conditions" class="row">
                <label class="body-m header-xs mb-3 col-12 mt-2 rem-receipt top-5 style-color">
                  Información de la operación</label>
                <label class=" rem-receipt body-s text-secondary-800 mt-2">
                  {{operationDate  | customDate:'dd/Month/yyyy'}}</label>
                <label class="detail-s justify-content-right rem-receipt">Fecha de operación</label>
                <hr class="my-2 width-msi ms-1">
              </div>
            </div>
            <div id="dataInvestment" *ngIf="viewInvestment">
              <label class="header-xs mb-3 pb-1">Inicio y término de la inversión</label>
              <app-info-label [head]="dataToConfirm[0]?.limitDate | customDate:'dd/Month/yyyy'"
              body="Fecha de inicio" classes=""
                margin="my-2"></app-info-label>
              <app-info-label [head]="dataToConfirm[0]?.dueDate| customDate:'dd/Month/yyyy'"
              body="Fecha de vencimiento" classes=""
                margin="my-2"></app-info-label>
              <div class="mt-4 pt-3">
                <label class="header-xs mb-3 pb-1">Información de la inversión</label>
                <app-info-label [head]="dataToConfirm[0]?.toTheExpiration" body="Instrucción al vencimiento" classes=""
                margin="my-2"></app-info-label>
                <app-info-label [head]="dataToConfirm[0]?.netReturn" body="Rendimiento bruto" classes=""
                  [head]="dataToConfirm[0]?.netReturn | currency" margin="my-2" [specialFont]="specialFont">
                </app-info-label>
                <app-info-label [head]="dataToConfirm[0]?.interestRate + '%'" body="Tasa de interés" classes=""
                  margin="my-2"></app-info-label>
              </div>
            </div>
            <div *ngIf="!viewInvestment && !viewInsurance" [ngClass]="viewDeductible ? 'margin-two' :''">
              <app-fee-info [timeToApply]="timeToApply" [fee]="paymentCommission"></app-fee-info>
            </div>
          </div>
        </div>
      </div>
      <!-- INICIO para tipo de TRANSFERENCIAS INTERNACIONALES Solo se muestra para esta opción -->
      <ng-container *ngIf="isInternationalTransfer">
        <div id="date-application">
          <label class="header-s mb-3 main-color-label" [ngClass]="{'mt-3':vm.breakpoints.mobile,
                      'padding-t':!vm.breakpoints.mobile, 'pb-3': !vm.breakpoints.mobile,
                      'pb-1': vm.breakpoints.mobile}">Información de la operación</label>
          <app-info-label [head]="badge" body="Divisa" classes="" margin="my-2"></app-info-label>
          <app-info-label *ngIf="purposePayment.length > 0" [head]="purposePayment" body="Propósito del pago"
            classes="mt-3" margin="my-2"></app-info-label>
          <app-info-label [head]="today | customDate:'dd/Month/yyyy'"
          body="Fecha de aplicación" classes="mt-3" margin="my-2"></app-info-label>
          <app-info-label [head]="warranty" body="Garantía del pago" classes="mt-3" margin="my-2"></app-info-label>
          <div class="mt-4">
            <app-fee-info></app-fee-info>
          </div>
        </div>
      </ng-container>
      <!-- FIN para tipo de TRANSFERENCIAS INTERNACIONALES Solo se muestra para esta opción -->
      <!-- INICIO para tipo de PAGO DE CRÉDITO Solo se muestra para esta opción -->
      <div id="date-application" *ngIf="showCreditPayment" class="ps-2">
        <ng-container *ngIf="!isCreditPaymentCompleted">
          <label class="header-xs mt-3 pt-3">Información de la operación</label>
        </ng-container>
        <div>
          <div class="mt-4">
            <app-info-label head={{referenceNumber}} body="Referencia" classes="" margin="my-2"></app-info-label>
          </div>
          <div class="mt-1">
            <app-info-label head={{type}} body="Tipo de pago" classes="" margin="my-2"></app-info-label>
          </div>
          <div class="mt-3"><app-fee-info [timeToApply]="timeToApply"></app-fee-info></div>
        </div>
      </div>
      <!-- FIN para tipo de PAGO DE CRÉDITO Solo se muestra para esta opción -->
    </div>
    <ng-container *ngIf="toPayDeductible">
      <ng-container *ngIf="viewDeductible; else aditionalBtn">
        <div class="col-12 mt-btn justify-content-center d-flex">
          <div class="container-btns">
            <app-template-primary-button [properties]="continueProperties" [showIcon]="false">
              {{btnConfirm}}
            </app-template-primary-button>
            <app-template-secondary-button [properties]="modifyProperties">
              {{btnModify}}
            </app-template-secondary-button>
          </div>
        </div>
      </ng-container>
      <ng-template #aditionalBtn>
        <div class="btn-container" [ngClass]="vm.breakpoints.mobile ? 'mt-4' : 'd-flex align-items-center flex-column'">
          <button class="main-confirm-data-btn mb-3 button-m btn-generic wrap" [ngClass]="{
            'insurance-btn form-response-style': viewInsurance, 'mt-5': !vm.breakpoints.mobile,
            'only-investment btn-investment': viewInvestment, 'mt-2': vm.breakpoints.mobile}" (click)="nextPage()"
            (keydown.enter)="$event.preventDefault()">
            {{btnConfirm}}
            <img alt="Next Icon" [src]="continueIcon | cdnImage" width="auto" height="auto">
          </button>
          <button class="form-modify-main-confirm-data-btn mb-5 button-m btn-generic" [ngClass]="{
              'only-investment form-response-style': viewInvestment,'insurance-btn': viewInsurance}"
            (click)="navigateBack()" (keydown.enter)="$event.preventDefault()">
            Modificar
          </button>
        </div>

      </ng-template>
    </ng-container>
    <ng-container *ngIf="isCreditPaymentCompleted">
      <div class="container-btn" [ngClass]="vm.breakpoints.mobile ? '' : 'd-flex align-items-center flex-column'">
        <button class="main-confirm-data-btn mb-3 button-m mt-deductible btn-generic" 
        (click)="nextPageConfirm()"
        (keydown.enter)="$event.preventDefault()"> Listo
        </button>
        <button class="form-modify-main-confirm-data-btn mb-3 button-m btn-generic" 
        (keydown.enter)="$event.preventDefault()"(click)="otherOperation()"> Otra
          operación
        </button>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isPatrimonialTransfer">
    <div class="main-content main-content-pat cards"
      [ngClass]="{'mt-5': isCreditPaymentCompleted == true,'mt-3': viewInvestment}">
      <div class="div-first" class="padding-top-25">
        <div *ngIf="!isCreditPaymentCompleted ">
          <div *ngIf="toPayDeductible " class="ps-2">
            <div>
              <label *ngIf="isInternationalTransfer" class="label-select-account mt-4 pt-3">{{ labelAmount }}</label>
              <show-amount [useBreak]="true" [label]="!isInternationalTransfer ? labelAmount : ''"
                color="var(--primary-1000)"></show-amount>
              <div *ngIf="isInternationalTransfer">
                <span [ngStyle]="{'width.%':width}" *ngIf="!vm.breakpoints.mobile" class="currency header-s">
                  EUR
                </span>
                <span [ngStyle]="{'width.%':90}" *ngIf="vm.breakpoints.mobile" class="currency header-s">
                  EUR
                </span>
                <label class="detail-s">Tipo de cambio: $0.00</label>
                <ng-container *ngIf="vm.breakpoints.desktop">
                  <div class="detail-s">Cantidad en pesos: $0.00</div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="third-div">
        <div class="mt-4"><span class="header-xs">A tu cuenta</span></div>
        <div class="from-your-account mt-4 pt-2">
          <div class="align-self-center justify-self-center" id="final-picture">
            <img class="card-img-confirm" [src]="card | cdnImage" alt="image" width="auto" height="auto" />
          </div>
          <div class="d-flex flex-column">
            <span class="body-s">{{toAccountTransfer | maskAccount: 4}}</span>
            <span class="detail-s">{{toCardTransfer}}</span>
          </div>
        </div>
        <hr class="my-2">
      </div>
      <div class="second-div">
        <div class="mt-4"><span class="header-xs">Cuenta origen</span></div>
        <div class="from-your-account mt-4 pt-2">
          <div class="align-self-center justify-self-center" id="source-image">
            <img class="card-img-confirm" [src]="rootImgTransfer" alt="image" width="auto" height="auto" />
          </div>
          <div class="d-flex flex-column">
            <span class="body-s">{{rootAccountTransfer | maskAccount: 4}}</span>
            <span class="detail-s">{{rootCardTransfer}}</span>
          </div>
        </div>
        <hr class="my-2">
      </div>
      <div class="cards-info col-12 fourth">
        <div class="col-6 width-column"></div>
        <div class="col-6 width-column">
          <div class="detail-m div-p">
            <p *ngIf="isPatrimonialFutureTransaction">La operación se aplicará en las próximas 48 horas</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-btn" [ngClass]="vm.breakpoints.mobile ? '' : 'd-flex align-items-center flex-column'">
      <button class="main-confirm-data-btn mb-3 button-m mt-5 btn-generic wrap"
        [ngClass]="viewInsurance ? 'insurance-btn form-response-style' : ''" [ngClass]="{
        'insurance-btn': viewInsurance,
        'only-investment btn-investment': viewInvestment,
        'border-deductible':viewDeductible}" (click)="nextPage()"
        (keydown.enter)="$event.preventDefault()">{{btnConfirm}}
        <img *ngIf="!isInternationalTransfer && !viewDeductible" alt="" [src]="shapeContinue | cdnImage" width="auto"
          height="auto">
      </button>
      <button class="form-modify-main-confirm-data-btn mb-3 button-m btn-generic" [ngClass]="{
          'only-investment form-response-style': viewInvestment,
          'border-deductible':viewDeductible}" (click)="navigateBack()"
          (keydown.enter)="$event.preventDefault()"> Modificar
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="viewDeductible">
    <div id="new" class="main-deductible" [ngClass]="completePayment ? 'main-deductible-complete' : ''">
      <div id="show-deductible" class="py-3 px-3" [ngClass]="completePayment ? 'complete-show-amount' : ''">
        <show-amount [useBreak]="true" [label]="!isInternationalTransfer ? labelAmount : ''"
          color="#000957"></show-amount>
      </div>
      <div id="data-account" [ngClass]="completePayment ? 'complete-account' : ''">
        <div id="data-payment" class="mx-3" [ngClass]="completePayment ? '' : 'mt-3'">
          <div class="header-xs py-2">{{title}}</div>
          <div>
            <div class="body-s pt-2 mt-3">{{labelServiceToPay}}</div>
            <app-info-label [head]="dataLabelDeductible.Policy" headClass="detail-s" bodyClass="body-s"
              [body]="deductibleInformation.policy" classes="" margin="my-2 hr-deductible hr-deductible-mobile">
            </app-info-label>
          </div>
        </div>
        <div class="mx-3 mt-3">
          <div class="margin-label py-2">
            <span [class.ps-2]="vm.breakpoints.mobile" class="header-xs">{{labelAccount}}</span>
          </div>
          <div class="aling-card">
            <div class="card-style">
              <img class="none-img" src={{selectedCard.image}} alt="image" width="auto" height="auto" />
            </div>
            <div>
              <div><span class="body-s">{{account | maskAccount: 4}}</span></div>
              <div><span class="detail-s">{{selectedCard.cardType}}</span></div>
            </div>
          </div>
          <hr class="my-2 mb-3 hr-deductible hr-deductible-mobile">
        </div>
      </div>
      <div id="first-data" [ngClass]="completePayment ? 'complete-first-data' : 'mt-2 ms-2'">
        <div [ngClass]="completePayment ? 'ps-3' : 'ps-2'">
          <label class="header-xs op-info">{{labelOperationInformation}}</label>
          <div id="data-deductible" class="mf-data">
            <div>
              <label class="body-s default-color">{{numberPayment}}</label><br>
              <label class="detail-s">{{dataLabelDeductible.LabelOrderAdmission}}</label><br>
              <hr class="my-2 hr-deductible margin-mobile-hr">
            </div>
            <div>
              <label class="body-s default-color">
                {{deductibleInformation.claimDate | date: 'dd/MM/yyyy' | customDate:'dd/Month/yyyy'}}
              </label><br>
              <label class="detail-s">{{dataLabelDeductible.DataClaim.Date}}</label><br>
              <hr class="my-2 hr-deductible margin-mobile-hr">
            </div>
          </div>
        </div>
      </div>
      <div id="second-data" [ngClass]="completePayment ? 'complete-second-data' : 'ms-2'">
        <div [ngClass]="completePayment ? '' : 'ps-2'">
          <div *ngIf="deductibleInformation.status" id="data-deductible" class="mf-data">
            <label class="body-s default-color">{{deductibleInformation.status}}</label><br>
            <label class="detail-s  ">{{dataLabelDeductible.DataClaim.Status}}</label><br>
            <hr class="my-2 hr-deductible margin-mobile-hr">
          </div>
          <div>
            <label class="body-s default-color">{{deductibleInformation.beneficiaryName}}</label><br>
            <label class="detail-s">{{dataLabelDeductible.DataClaim.Name}}</label><br>
            <hr class="my-2 hr-deductible margin-mobile-hr">
          </div>
        </div>
      </div>
      <div id="third-data" [ngClass]="completePayment ? 'complete-third-data' : 'ms-2 mb-3'">
        <div [ngClass]="completePayment ? 'ps-3 mt-2' : 'ps-2'">
          <div id="data-deductible">
            <div id="third-data">
              <div>
                <label class="body-s default-color">{{deductibleInformation.vehicle}}</label><br>
                <label class="detail-s">{{dataLabelDeductible.DataClaim.Vehicle}}</label><br>
                <hr class="my-2 hr-deductible margin-mobile-hr">
              </div>
              <div>
                <label class="body-s default-color">{{deductibleInformation.model}}</label><br>
                <label class="detail-s">{{dataLabelDeductible.DataClaim.Model}}</label><br>
                <hr class="my-2 hr-deductible margin-mobile-hr">
              </div>
            </div>
          </div>
          <div class="margin-fee container pe-0">
            <app-fee-info [timeToApply]="timeToApply" [fee]="paymentCommission"></app-fee-info>
          </div>
        </div>
      </div>
    </div>
    <div id="template-btn"
      [ngClass]="completePayment ? 'complete-show-amount' : 'col-12 mt-btn justify-content-center d-flex'">
      <div class="container-btns">
        <app-template-primary-button [properties]="continueProperties" [showIcon]="false">
          {{btnConfirm}}
        </app-template-primary-button>
        <app-template-secondary-button [properties]="modifyProperties">
          {{btnModify}}
        </app-template-secondary-button>
      </div>
    </div>
  </ng-container>
  <ng-template #ScheduleTransfer>
    <ng-container *ngIf="scheduledTransferData$ | async as scheduleData">
      <app-schedule-transfer-data [scheduleData]="scheduleData"
      [isTransferComplete]="false"></app-schedule-transfer-data>
    </ng-container>
  </ng-template>
</ng-container>
