import { NoAgendableService } from 'src/app/interface/dto/NoAgendableServiceResponse';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike, fromEvent, map, tap } from 'rxjs';
import { PaginationService } from 'src/app/services/pagination.service';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalOptions } from 'src/app/interface/modal-options';
import { AgendaType, ClassHTML, Constants, HtmlEvent, Keys, Regex } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { environment } from 'src/environments/environment';
import { ModalModifyAccountComponent } from 'src/app/component/modal-modify-account/modal-modify-account.component';
import { AlertService } from 'src/app/services/alert.service';
import { Utils } from 'src/core/utils/utils';
import { AccountModifyService } from 'src/app/services/info-accounts-edit.service';
import { ServiceConstants } from 'src/core/constants/ServiceConstants';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { PaymentSATStrings } from 'src/core/constants/PaymentSATStrings';
import { SatResources } from 'src/core/constants/PaymentSATResources';
import { SuaService } from 'src/app/services/sua.service';
import { ServiceService } from 'src/app/services/services.service';
import { CreditResources } from 'src/core/constants/CreditResources';

@Component({
  selector: 'app-modal-services',
  templateUrl: './modal-services.component.html',
  styleUrls: ['./modal-services.component.css'],
  providers: [PaginationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalServicesComponent implements OnDestroy {
  selectedService: number = null;
  isSUA: boolean = false;
  regexSearch: RegExp = Regex.OnlyText;
  modalInstance: NgbModalRef;
  inputValue: string = Strings.EMPTY;
  frameClose: string = Resources.CLOSE_CIRCLE_BLUE;
  searchIcon: string = Resources.SEARCH;
  chevronRight: string = Resources.BTN_CHEVRON_RIGHT;
  serviceCodeFederalTax: string = Constants.SERVICE_CODE.Impfed;
  serviceCodeSat: string = Constants.SERVICE_CODE.Impfedr;
  modalData: NoAgendableService[];
  logoSat: string = CreditResources.LOGO_SAT;
  key: SubscriptionLike;
  chevronRightBlueIcon: string = Resources.CHEVRON_RIGHT_LIGHT_BLUE;
  editBlue: string = CreditResources.EDITH_BLUE;
  fileBlue: string = CreditResources.FILE_BLUE;

  styles: Partial<CSSStyleDeclaration> = {
    display: ClassHTML.WebkitBox,
    webkitBoxOrient: ClassHTML.BoxOrientVertical,
    overflowWrap : ClassHTML.AnyWhere
  };

  services$ = this.paginationService.pagination.pipe(
    map(pagination => pagination.data)
  );

  vm$ = this.responsiveService.observe.pipe(
    tap((breakpoints) => {
      this.isDesktop = breakpoints.desktop;
      if(breakpoints.mobile) {
        this.close();
      }
    }),
    map((breakpoints) => ({ breakpoints }))
  );

  pageSize: number = Constants.PAGE_SIZE_MODAL_SERVICE;
  beta: boolean = environment.beta;
  serviceCodePsegnal: string = Constants.SERVICE_CODE.Psegnal ;
  serviceCodePaggmm: string = Constants.SERVICE_CODE.Paggm;
  satIcon: string = SatResources.LOGO_SAT;
  labelPaymentSat: string = PaymentSATStrings.IMPFED.Description;
  labelPaymentReferenced: string = ServiceConstants.SERVICES_LABEL[Constants.SERVICE_CODE.Impfedr];
  isDesktop: boolean = false;

  constructor(
    private readonly modalService: ModalService,
    private readonly paginationService: PaginationService,
    private readonly responsiveService: ResponsiveService,
    private readonly stateServicePayIdService: StateServicePayIdService,
    private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly accountModifyService: AccountModifyService,
    private readonly suaService: SuaService,
    private readonly serviceService: ServiceService
  ) { }

  ngOnInit(){
    this.paginationService.setInitialData(this.modalData || this.serviceService.dataBackup, this.pageSize);
    this.initComponent();
  }

  initComponent(){
    this.key = fromEvent<KeyboardEvent>(document, HtmlEvent.Keydown).subscribe(x => {
      if (x.key === Keys.Tab) {
        x.preventDefault();
      }
    });
  }


  select() {
    if (this.selectedService != null) {
      this.modalService.close();
      if (this.isSUA) {
        this.suaConsultRegistries();
      } else {
        this.close();
        this.router.navigate([PATH.NoSchedulableServices]);
      }
    }
  }

  close() {
    this.modalService.close();
  }

  selectService(service: NoAgendableService, index: number) {
    this.selectedService = index;
    this.stateServicePayIdService.service = {
      id: service.codigoServicio,
      params: { dailyLimit: service.limiteDiario }
    };
    this.isSUA = this.stateServicePayIdService.service.id === Constants.SERVICE_CODE.SuaPayment;
    this.select();
  }

  searchTerm(term: string) {
    if (term !== Strings.EMPTY) {
      this.paginationService.search(this.search, term);
    } else {
      this.paginationService.setInitialData(this.modalData,this.pageSize);
    }
  }

  search(data: NoAgendableService[], search: string) {
    search = search.toUpperCase();
    if (search === Strings.EMPTY) return data;
    return data.filter((service) =>
      service.descripcionServicio.toUpperCase().includes(search ?? Strings.EMPTY)
    );
  }

  ngOnDestroy() {
    this.modalService.close();
    this.key?.unsubscribe();
  }

  serviceWithoutImage(value: string) {
    return Constants.SERVICES_WITHOUT_IMAGE.includes(value);
  }

  showModalModifyAccount(sendName: string, sendMaximumLimit: number, sendIdAgent: number,
    sendCodeServices: string, waitTime: number, sendOldLimit: number) {
      this.modalInstance.close();
      setTimeout(() => {
        this.validateModalModifyAccount(waitTime,sendName,sendMaximumLimit,sendIdAgent,sendCodeServices,
          sendOldLimit);
      });
  }

  validateModalModifyAccount(waitTime: number, sendName: string, sendMaximumLimit: number,
    sendIdAgent: number, sendCodeServices: string, sendOldLimit: number){
    if (waitTime === Constants.WAIT_TIME_ZERO) {
      const modalOptions: ModalOptions = {
        centered: true,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalModifyAccountFixed
      };
      this.modalService.open(ModalModifyAccountComponent, modalOptions, Constants.MODAL_OPTIONS.FormModify);
      this.accountModifyService.account = {
        alias: null,
        account: null,
        name: sendName,
        type: AgendaType.NonAgendableService,
        maximumLimit: sendMaximumLimit.toString(),
        idAgent: sendIdAgent,
        agendaType: Constants.AGENDA_SERVICES,
        codeServices: sendCodeServices,
        limitOld: sendOldLimit.toString()
      };
      this.accountModifyService.originData = {
        limit: sendMaximumLimit.toString(),
        alias: null
      };
    }
    else {
      const errorDetails = Strings.MSG_TIMEOUT_AGENDA.replace(Regex.Time, waitTime.toString());
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails, Constants.SIXTY_MINUTE_CODE),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT
      });
    }
  }

  serviceWithoutLimit(value: string) {
    return !ServiceConstants.SERVICES_WITHOUT_LIMIT.includes(value);
  }

  serviceShowHistory(value: string) {
    return ServiceConstants.SERVICES_HISTORY.includes(value);
  }

  async suaConsultRegistries() {
    try{
      await this.suaService.validateModalToDisplay();
    } catch (error) {
      this.modalService.close();
      const errorDetail = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetail.msg,errorDetail.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      },
      {
        onSuccess: () => this.close()
      });
    }
  }

  navigationHistory(serviceCode: string) {
    this.modalInstance.close();
    this.accountModifyService.account.codeServices = serviceCode;
    this.serviceService.navigationHistory();
  }
}
