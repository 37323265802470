import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Char, Constants, Keys, Length, Numbers, Regex } from 'src/core/constants/Constants';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalOptions } from 'src/app/interface/modal-options';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Utils } from 'src/core/utils/utils';
import { AppBridge } from 'src/core/utils/AppBridge';
import { AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { AlertService } from 'src/app/services/alert.service';
import { AppNavigation } from 'src/app/models/AppNavigation';
import { Strings } from 'src/core/constants/Strings';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { AppUtils } from 'src/core/utils/AppUtils';


@Component({
  selector: 'app-form-code-security-serie',
  templateUrl: './form-code-security-serie.component.html',
  styleUrls: ['./form-code-security-serie.component.css']
})
export class FormCodeSecuritySerieComponent implements OnInit, OnDestroy {

  @Input() nextView?: string;
  transferComplete: number | string;
  @Input() dataForm: Array<string | boolean>;
  @Input() head: string = Strings.CODE_SECURITY.Title;
  @Input() dataInternational: Array<string>;
  @Input() serialNumber: string;
  @ViewChild('modalToken', { static: true }) modalToken: ElementRef;
  modalHelpToken: NgbModalRef;
  token1: string = Strings.EMPTY;
  token2: string = Strings.EMPTY;
  token3: string = Strings.EMPTY;
  token4: string = Strings.EMPTY;
  token5: string = Strings.EMPTY;
  token6: string = Strings.EMPTY;
  token7: string = Strings.EMPTY;
  token8: string = Strings.EMPTY;
  alert: boolean = true;
  tokenError: boolean = false;
  tokenError1: boolean = false;
  tokenError2: boolean = false;
  tokenError3: boolean = false;
  tokenError4: boolean = false;
  tokenError5: boolean = false;
  tokenError6: boolean = false;
  tokenError7: boolean = false;
  tokenError8: boolean = false;
  regexT: RegExp = Regex.OneMoreNumbers;
  helpIcon: string = Resources.CIRCLE_BLUE_QUESTION;
  closeIcon: string = Resources.SQUARE_WHITE_CROSS;
  chevronRight: string = Resources.SHAPE_CONTINUE;

  constructor(
    private readonly _router: Router,
    private readonly modalService: ModalService,
    private readonly alertService: AlertService
  ) { }

  async ngOnDestroy() {
    if (AppUtils.platform.isApp) {
      try {
        await AppBridge.invoke<boolean>(AppMethod.GetToken, Constants.FALSE);
      } catch (error) {
        Utils.printLogError(error);
      }
    }
  }

  async ngOnInit() {
    if (AppUtils.platform.isApp) {
      try {
        await AppBridge.invoke<boolean>(AppMethod.GetToken, Constants.TRUE);
      } catch (error) {
        const message = Utils.getErrorMsg(error);
        this.alertService.showPopupAlert({
          header: Strings.MSG_POPUP_TITLE,
          message: Utils.SERVICE_ERROR_MSG(message.msg, message.code)
        }, {
          onSuccess: async () => {
            if (AppUtils.platform.isXamarin) {
              const navigationParams: AppNavigation = {
                Data: AppScreen.Home
              };
              await AppBridge.invoke<string>(AppMethod.SendNativeScreen, navigationParams);
            }
          },
        });
      }
    }
    if (this.transferComplete === Strings.EMPTY) {
      this.transferComplete = Constants.TRANSFER_COMPLETE_ZERO;
    }
  }

  async showConfirmAddress(inputsToken: { token1: string, token2: string, token3: string, token4: string, token5: string, token6: string, token7: string, token8: string }) {
    this.tokenError1 = inputsToken.token1.length === Length.Empty;
    this.tokenError2 = inputsToken.token2.length === Length.Empty;
    this.tokenError3 = inputsToken.token3.length === Length.Empty;
    this.tokenError4 = inputsToken.token4.length === Length.Empty;
    this.tokenError5 = inputsToken.token5.length === Length.Empty;
    this.tokenError6 = inputsToken.token6.length === Length.Empty;
    this.tokenError7 = inputsToken.token7.length === Length.Empty;
    this.tokenError8 = inputsToken.token8.length === Length.Empty;
    const token: string = inputsToken.token1 + inputsToken.token2 + inputsToken.token3 +
      inputsToken.token4 + inputsToken.token5 + inputsToken.token6 + inputsToken.token7 + inputsToken.token8;
    if (token.length < Constants.LENGTH_TOKEN) {
      this.tokenError = true;
    } else {
      this.getRouteNavigate(token);
    }
  }

  getRouteNavigate(token: string) {
    if (!this.nextView) {
      this.navigateNewAgenda(token);
    } else {
      if (this.dataInternational) {
        this.navigateInternationalAgenda(token);
      } else {
        this.navigate(token);
      }
    }
  }

  navigateNewAgenda(token: string) {
    this.dataForm[Constants.DATA_AGENDA_NATIONAL.ShowConfirmAgenda] = true;
    this.dataForm[Constants.DATA_AGENDA_NATIONAL.ShowToken] = false;
    this.dataForm[Constants.DATA_AGENDA_NATIONAL.IdAgenda] = true;
    this.dataForm[Constants.DATA_AGENDA_NATIONAL.Otp] = token;
    this._router.navigate([PATH.NewAddressee], {
      state: {
        serialNumber: this.serialNumber,
        next: PATH.NewAddressee,
        otp: token,
        service: this.transferComplete,
        dataForm: this.dataForm
      }, replaceUrl: true
    });
  }

  navigateInternationalAgenda(token: string) {
    this._router.navigate([this.nextView], {
      state: {
        otp: token,
        dataInternational: this.dataInternational,
        serialNumber: this.serialNumber
      }, replaceUrl: true
    });
  }

  closeAlert() {
    this.alert = false;
  }

  navigate(token: string) {
    this._router.navigate([this.nextView], {
      state: {
        otp: token,
        serialNumber: this.serialNumber
      }, replaceUrl: true
    });
  }

  validateInputs() {
    if (this.tokenError) {
      this.tokenError =
        this.tokenError8 ||
        this.tokenError7 ||
        this.tokenError6 ||
        this.tokenError5 ||
        this.tokenError4 ||
        this.tokenError3 ||
        this.tokenError2 ||
        this.tokenError1;
    }
  }

  inputToken(number: number) {
    const currentControl: HTMLElement = document.getElementById(Constants.TOKEN + number.toString());
    const nextControl: HTMLElement = document.getElementById(Constants.TOKEN + (number + Numbers.One).toString());

    const currentValue: string = currentControl[Constants.FORM_CONTROLS.Value];
    const valueInput = currentValue.replace(Regex.nonDigitsRegex, Strings.EMPTY);

    if (currentValue.length > Length.One) {
      currentControl[Constants.FORM_CONTROLS.Value] = valueInput.slice(Numbers.Zero, Constants.INPUT_TOKEN_MAX_LENGTH);
      if (nextControl) {
        nextControl[Constants.FORM_CONTROLS.Value] = valueInput.slice(Constants.INPUT_TOKEN_MAX_LENGTH, Numbers.Two);
        nextControl.focus();
      }
    }
  }

  keyToken(event: KeyboardEvent, index: number, inputsToken: {
    token1: string, token2: string, token3: string,
    token4: string, token5: string, token6: string, token7: string, token8: string
  }) {
    if (this.validateInputNumber(event)) {
      this.validateInputs();
      const previousControl: HTMLElement = document.getElementById(Constants.TOKEN + (index - Numbers.One).toString());
      if (event.key === Keys.Backspace) {
        previousControl.focus();
      } else if (event.key === Keys.Enter) {
        this.showConfirmAddress(inputsToken);
      }
    }
  }

  valueToken1(token1: string) {
    this.tokenError1 = token1.length == null;
  }
  valueToken2(token2: string) {
    this.tokenError2 = token2.length == null;
  }
  valueToken3(token3: string) {
    this.tokenError3 = token3.length == null;
  }
  valueToken4(token4: string) {
    this.tokenError4 = token4.length == null;
  }
  valueToken5(token5: string) {
    this.tokenError5 = token5.length == null;
  }
  valueToken6(token6: string) {
    this.tokenError6 = token6.length == null;
  }
  valueToken7(token7: string) {
    this.tokenError7 = token7.length == null;
  }
  valueToken8(token8: string) {
    this.tokenError8 = token8.length == null;
    if (token8.length === Length.One && this.tokenError) {
      this.tokenError = false;
    }
    this.validateInputs();
  }

  showModalHelpToken() {
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalHelpToken
    };

    this.modalHelpToken = this.modalService.open(this.modalToken, modalOptions);
  }

  validateInputNumber(event: KeyboardEvent) {
    let isValid = true;
    const stringNumber = (event.target as HTMLInputElement).value;
    if (event.code === Keys.Space || (event.target as HTMLInputElement).value === Char.WhiteSpace) {
      isValid = false;
      (event.target as HTMLInputElement).value = Strings.EMPTY;
    } else {
      if (stringNumber.length > Length.Empty && (event.key !== Keys.Backspace) && (event.key !== Keys.Enter)
        && (!Regex.NumbersSpace.test((event.target as HTMLInputElement).value))) {
        isValid = false;
        (event.target as HTMLInputElement).value = stringNumber.slice(Numbers.Zero, Constants.NOT_FOUND_ITEM);
      }

    }
    return isValid;
  }
}
