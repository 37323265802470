<ng-container *ngIf="vm$ | async as vm">
  <content-wrapper [contentMargin]="contentMargin" [useFlex]="!vm.breakpoints.mobile">
    <div content content class="width-content" *ngIf="accountType">
      <app-header-transfer-card [title]="title" [subtitle]="subtitle" [urlLocation]="codeflexPath"
        [returnFn]="return"></app-header-transfer-card>

      <div id="main-content" class="mt-4">
        <div>
          <div class="ps-3" [ngClass]="!vm.breakpoints.mobile ? 'mt-4' : ''">
            <show-amount [useBreak]="true" label="Cantidad" color="var(--primary-1000)"></show-amount>
          </div>
          <selected-card [classes]="
              vm.breakpoints.mobile ? 'pt-4 mt-3 ps-2' : ' pt-4 mt-3 ps-3'
            "
            label="Cuenta origen"
            [cardImage]="account.directorioImagen"
            [cardName]="account.tipoCuenta"
            [cardNumber]="numberAccount"
          ></selected-card>
        </div>
        <div class="mt-4">
          <selected-card
            [classes]="vm.breakpoints.mobile ? 'ps-2' : 'ps-3 pb-4'"
            [cardImage]="mainAccount.directorioImagen"
            [cardName]="mainAccount.descripcionCuenta"
            [cardNumber]="mainAccount.numeroCuenta"
          ></selected-card>
          <div class="header-xs mt-4 ps-3 default-color">
            {{
            vm.breakpoints.mobile
            ? titleDateLabel.mobile
            : titleDateLabel.tablet
            }}
          </div>
          <app-info-label classes="mt-3 ps-3" [head]="vm.date"
            [body]="vm.breakpoints.mobile ? dateLabel.mobile : dateLabel.tablet"></app-info-label>
          <div class="detail-m text-align-justify mt-3 secondary-color"
            [ngClass]="!vm.breakpoints.mobile ? 'px-2' : ''">
            Los recursos solicitados se acreditarán en la cuenta asociada en la
            fecha señalada de acuerdo al termino de los plazos de inversión
          </div>
        </div>
      </div>
      <div
        class="btn-container d-flex justify-content-center"
        [ngClass]="vm.breakpoints.mobile ? buttonsSpacing.mobile : buttonsSpacing.desktop"
      >
        <button
          class="colored-btn btnGeneric"
          [routerLink]="completedWithdrawalPath"
          [ngClass]="vm.breakpoints.mobile ? 'button-s' : 'button-m'"
        >
          Confirmar y continuar
          <img [src]="chevronRightIcon | cdnImage" alt=">" width="auto" height="auto"/>
        </button>
      </div>
      <div
        class="btn-container d-flex justify-content-center mt-3">
        <button
          class="outline-btn btnGeneric"
          (click)="return()"
          [ngClass]="vm.breakpoints.mobile ? 'button-s' : 'button-m'"
        >
          Modificar
        </button>
      </div>
    </div>
  </content-wrapper>
</ng-container>
