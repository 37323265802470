<div [ngClass]="tokenContainerClass" id="otp-card-replacement">
  <label class="title-token mb-1 pt-2"  *ngIf="headerToken">{{headerToken}}</label>
  <label  class="body-s secondary-color width-insert" *ngIf="headerToken" [class.pb-2]="alert">Ingresa los datos de la
    operación</label>
    <ng-container *ngIf="!isApp">
      <div [class]="classAlert" *ngIf="alert">
        <div class="col-10" [ngClass]="ngClassAlert">
          <span class="detail-m color-white">
            Utiliza el token móvil de tu app Inbursa Móvil para realizar esta validación
          </span>
        </div>
        <div class="col-2 d-flex  justify-content-end align-content-end" [ngClass]="ngClassIcon">
          <a role="button" (click)="closeAlert()" (keydown.enter)="closeAlert()">
            <img [alt]="close" width="auto" height="auto" [src]="close| cdnImage" [ngClass]="styleIcon">
          </a>
        </div>
      </div>
    </ng-container>
  <div role="alert" [ngClass]="!alert && !modalDelete && !removeClass ? 'pt-4' :
  !alert && !modalDelete && removeClass ? 'pt-0' : modalDelete ? 'pt-2' : 'pt-3'">
    <div class="d-flex align-items-center gap-1 mb-3" [ngClass]="divLabelToken">
      <label class="button-s text-secondary-800" [ngClass]="colorHead">{{head}}</label>
      <div class="d-flex justify-content-center align-items-center"
      *ngIf="!isApp">
        <img
          class=" icon-image-security"
          tooltipClass="my-custom-class"
          [ngClass]="headerImageClass" width="auto" height="auto"
          [src]="questionMark | cdnImage" alt="help" (click)="showModalHelpToken()" 
          (keydown.enter)="$event.preventDefault()">
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="d-flex flex-column token-input-container">
        <div class="mt-2 d-flex justify-content-center input-border">
          <input
            (input)="inputToken(1)" type="text" required id="token1" name="token1" #token1
            (keyup)="keyToken($event,1, {token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})"
            onpaste="return false" [class]="classInput" maxlength="2" autocomplete="off"
            [ngClass]="errorToken1? 'border-error':''"
            (input)="valueToken1(token1.value)" inputmode="numeric" [appValidateInputRegex]="regexT">
          <input
            (input)="inputToken(2)" type="text" required id="token2" name="token2" #token2
            (keyup)="keyToken($event, 2, {token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})"
            onpaste="return false" [class]="classInput" maxlength="2" autocomplete="off"
            [ngClass]="errorToken2? 'border-error':''"
            (input)="valueToken2(token2.value)" inputmode="numeric" [appValidateInputRegex]="regexT">
          <input
            (input)="inputToken(3)" type="text" required id="token3" name="token3" #token3
            (keyup)="keyToken($event, 3, {token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})"
            onpaste="return false" [class]="classInput" maxlength="2" autocomplete="off"
            [ngClass]="errorToken3? 'border-error':''"
            (input)="valueToken3(token3.value)" inputmode="numeric" [appValidateInputRegex]="regexT">
          <input
            (input)="inputToken(4)" type="text" required id="token4" name="token4" #token4
            (keyup)="keyToken($event, 4, {token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})"
            onpaste="return false" [class]="classInput" maxlength="2" autocomplete="off"
            [ngClass]="errorToken4? 'border-error':''"
            (input)="valueToken4(token4.value)" inputmode="numeric" [appValidateInputRegex]="regexT">
          <input
            (input)="inputToken(5)" type="text" required id="token5" name="token5" #token5
            (keyup)="keyToken($event, 5, {token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})"
            onpaste="return false" [class]="classInput" maxlength="2" autocomplete="off"
            [ngClass]="errorToken5? 'border-error':''"
            (input)="valueToken5(token5.value)" inputmode="numeric" [appValidateInputRegex]="regexT">
          <input
            (input)="inputToken(6)" type="text" required id="token6" name="token6" [class]="classInput"
            maxlength="2" #token6 (keyup)="keyToken($event, 6, {token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})"
            onpaste="return false" autocomplete="off" [ngClass]="errorToken6? 'border-error':''"
            (input)="valueToken6(token6.value)" inputmode="numeric" [appValidateInputRegex]="regexT">
          <input
            (input)="inputToken(7)" type="text" required id="token7" name="token7" #token7
            (keyup)="keyToken($event, 7,{token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})"
            onpaste="return false" [class]="classInput" maxlength="2" autocomplete="off"
            [ngClass]="errorToken7? 'border-error':''"
            (input)="valueToken7(token7.value)" inputmode="numeric" [appValidateInputRegex]="regexT">
          <input
            (input)="inputToken(8)" type="text" required id="token8" name="token8" #token8
            (keyup)="keyToken($event, 8, {token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})"
            onpaste="return false" [class]="classInput" maxlength="2" autocomplete="off"
            [ngClass]="errorToken8? 'border-error':''"
            (input)="valueToken8(token8.value)" inputmode="numeric" [appValidateInputRegex]="regexT"
            (blur)="valueToken8(token8.value)">
        </div>
        <span class="detail-s label-error label-error-a" [ngClass]="labelModal"
        *ngIf="errorToken"> Este campo es requerido </span>
      </div>
    </div>
    <div class="pt-2 d-flex justify-content-center mt-3" >
      <button
        class="button-m"
        [ngClass]="btnClass" (click)="validateTokenCode({token1: token1.value, token2: token2.value,
        token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
        token7: token7.value, token8: token8.value})"
        (keydown.enter)="$event.preventDefault()">
        Continuar <img [alt]="chevronRight" [src]="chevronRight | cdnImage" width="auto" height="auto" />
      </button>
    </div>
  </div>
</div>
<ng-template #ModalToken>
  <app-modal-help-token [modalId]="modalHelpToken"></app-modal-help-token>
</ng-template>
