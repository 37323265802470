export class HomeOptionStrings {
  static readonly CODI_OPTION = 'CoDi';
  static readonly DIMO_OPTION = 'Pagos con el celular';
  static readonly REQUEST_ASSISTANCE_INSURANCE_OPTION = 'Solicitar asistencia';
  static readonly OPERATION_CODI = 'CODI';
  static readonly OPERATION_DIMO = 'DIMO';

  static readonly LABEL_OPTION_CODI = 'CoDi';
  static readonly LABEL_OPTION_DIMO = 'DiMo';
  
  static readonly LABEL_TRANSFER_DEBT = 'Traspasa tu deuda';
}
