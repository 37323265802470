import { Resources } from 'src/core/constants/Resources';
import { Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { map, tap } from 'rxjs';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { RetainedBalanceService } from 'src/app/services/retained-balance.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Constants, Length, Numbers } from 'src/core/constants/Constants';
import { ModalOptions } from 'src/app/interface/modal-options';
import { TRetained } from 'src/app/interface/dto/ConsultRetainedResponse';
import { DetailTransactionSpeiModalComponent } from 'src/app/home/components/detail-movementspei-modal/detail-transaction-spei-modal.component';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { TDebitCardsMovements } from 'src/app/interface/DebitCardMovementsResponse';
import { ProductStrings } from 'src/core/constants/ProductStrings';
import { ProductConstants } from 'src/core/constants/ProductConstants';
import { AlertService } from 'src/app/services/alert.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalConstants } from 'src/core/constants/ModalConstants';

@Component({
  selector: 'app-modal-balance-held',
  templateUrl: './modal-balance-held.component.html',
  styleUrls: ['./modal-balance-held.component.css']
})

export class ModalBalanceHeldComponent implements OnInit {
  totalRetainedBalance: number = Numbers.Zero;
  isiPad: boolean = false;
  isiPhone: boolean = false;
  imageCDN: string = Resources.IMAGE_BALANCE_HELD;
  closeCircle: string = Resources.CLOSE_CIRCLE_BLUE;
  completeDate!: string;
  movementSelected?: TDebitCardsMovements;
  movementDetails: Array<{ key: string, name: string, value: string }> = null;
  dataMovements: Array<TRetained> = [];
  modalInstance: NgbModalRef;
  closedModal: boolean = false;
  isMobile: boolean = false;

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly retainedBalanceService: RetainedBalanceService,
    private readonly accountInfoService: AccountInfoStatusService,
    private readonly alertService: AlertService
  ) { }

  vm$ = this.responsiveService.observe.pipe(
    tap((breakpoints) =>
    breakpoints.mobile
  ),
  map((breakpoints) => {
    this.isMobile = breakpoints.mobile;
    return { breakpoints };
  })
);

  async ngOnInit() {
    await this.getRetainedBalance();
    this.isTabletIos();
    this.isMobileIos();
    this.totalRetainedBalance = this.accountInfoService.account.saldos.retenido;
  }

  async getRetainedBalance() {
    try {
      this.dataMovements = [];
      this.dataMovements = await this.retainedBalanceService.getRetainedBalance();
      this.getDataMovements();
      if (this.closedModal) return;
    } catch (error) {
      if (this.closedModal) return;
      this.modalService.close();
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      });
    }
  }

  private async getDataMovements() {
    const dataMovementsDate = Utils.dateMovements(Numbers.Zero);
    const dataDay = Utils.dateMovements(Numbers.Zero, -Numbers.One);
    this.dataMovements.sort((firstElement, secondElement) => Utils.orderByDate(firstElement.fechaRetencion, secondElement.fechaRetencion));
    this.dataMovements.forEach(element => {
      element.originalDate = element.fechaRetencion;
      if (element.fechaRetencion === dataMovementsDate) {
        element.fechaRetencion = Constants.MOMENTS_OF_DAY;
      }
      else if (element.fechaRetencion === dataDay) {
        element.fechaRetencion = Constants.MOMENTS_YESTERDAY;
      }
    });
  }

  close() {
    this.modalService.close();
  }

  openModalWithHoldings(content: TemplateRef<DetailTransactionSpeiModalComponent>, movement: TRetained) {
    this.modalService.close();
    this.movementSelected = {
      fecha: movement.fechaRetencion != null ? movement.fechaRetencion : Strings.EMPTY,
      monto: movement.monto != null ? movement.monto.toString() : Strings.EMPTY,
      Descripcion: movement.descripcion != null ? movement.descripcion : Strings.EMPTY,
      tipoOperacion: movement.tipoRetencion != null ? movement.tipoRetencion : Strings.EMPTY,
      Tarjeta: movement.NumeroTarjeta != null ? movement.NumeroTarjeta : Strings.EMPTY,
      Folio: movement.IdTransaccion != null ? movement.IdTransaccion.toString() : Strings.EMPTY,
      Aclaracion: movement.aclaracion != null ? movement.aclaracion : Strings.EMPTY,
      Canal: movement.canal != null ? movement.canal : Strings.EMPTY,
      Tipo: movement.tipoSolicitud != null ? movement.tipoSolicitud : Strings.EMPTY,
      concepto: ProductStrings.CONCEPT_RETAINED_DETAIL,
      originalDate: movement.originalDate
    };
    this.mappingRetained();
    const modalOptions: ModalOptions = {
      centered: true,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.DetailMovements,
      size: Constants.MODAL_OPTIONS.SizeXl
    };
    this.modalService.open(content, modalOptions);
  }

  mappingRetained() {
    this.movementDetails = new Array();
    const key = ProductConstants.MOVEMENTS_RETAINED;
    key.forEach(keyMapping => {
      let valueTmp = this.movementSelected[keyMapping.key];
      if (valueTmp !== null && valueTmp !== Strings.EMPTY) {
        valueTmp = Utils.formatValue(valueTmp, keyMapping.format);
        this.movementDetails.push(
          {
            key: keyMapping.key,
            name: keyMapping.name,
            value: valueTmp
          });
      }
    });
    if (!this.isMobile && this.movementDetails.length > Length.Empty && this.movementDetails.length % Numbers.Two !== Length.Empty) {
      this.movementDetails.push({ key: Strings.EMPTY, name: Strings.EMPTY, value: Strings.EMPTY });
    }
  }

  isMobileIos() {
    const agent = navigator.userAgent;
    const maxWidth = Constants.RESOLUTIONS.PhoneMaxWidth;
    const minWidth = ProductConstants.RESOLUTIONS.PhoneMinWidth;
    if (((window.screen.width <= maxWidth) && (window.screen.width >= minWidth))
      && agent.match(Constants.USER_AGENT.MobileIos)) {
      this.isiPhone = true;
    } else {
      this.isiPhone = false;
    }
  }

  isTabletIos() {
    const agent = navigator.userAgent;
    const iPadMinWidth = Constants.RESOLUTIONS.IpadMinWidth;
    const iPadMaxWidth = Constants.WINDOW_NUMBER;
    if (((window.screen.width <= iPadMinWidth) && (window.screen.width >= iPadMaxWidth))
      && agent.match(Constants.USER_AGENT.TabletIos)) {
      this.isiPad = true;
    } else {
      this.isiPad = false;
    }
  }

  @HostListener('window:popstate')
  onBack() {
    this.closedModal = true;
    this.modalInstance.close();
  }
}
