import { Injectable } from '@angular/core';
import { ConsultInsurancePolicyCoverRequest } from 'src/app/interface/dto/TConsultInsurancePolicyCoverRequest';
import { TConsultInsurancePolicyCoverResponse } from 'src/app/interface/dto/TConsultInsurancePolicyCoverResponse';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { InsurancePolicyInfoStatusService } from 'src/app/services/insurance-policy-info-status.service';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class InsurancePolicyCoverService {

  constructor(
    private readonly stateIInsurancePolicyPlInfo: InsurancePolicyInfoStatusService,
    private readonly baseService: IBaseService,
    private readonly storage: StorageService
  ) { }

  async consultInsurancePolicyCover(): Promise<string>{
    const currentPolicy = this.stateIInsurancePolicyPlInfo.insurancePolicy;
    const request = new ConsultInsurancePolicyCoverRequest({
      Emisor: currentPolicy.Emisor,
      Carpeta: currentPolicy.Carpeta,
      Poliza: currentPolicy.Cis,
      Ramo: currentPolicy.Ramo?.toUpperCase(),
      IdSession: this.storage.getSession()
    });
    const response = await this.baseService.genericPost<TConsultInsurancePolicyCoverResponse>(request, {isSOA: true});
    return response.PolizaB64;
  }
}
