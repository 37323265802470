import { NonAgendableServiceDetails } from 'src/app/interface/NonAgendableServiceDetails';
import { environment } from 'src/environments/environment';
import { Resources } from 'src/core/constants/Resources';
export class Constants {
  public static IS_PRODUCTION = environment.production;
  public static LOCALE_EN_US = 'en-US';
  public static LOCALE_ES_MX = 'es-MX';
  static readonly MAXQUOTE = 999999;
  public static MONGO_SERVICES_ENABLED = 0;
  public static EMAIL_MS_ENABLED = environment.emailMsEnabled;

  // Códigos de error
  static readonly NULL_CODE = 777001;
  static readonly CATCH_CODE = 777002;
  static readonly UNEXPECTED_CODE = 777003;
  static readonly UNAUTHORIZED_CODE = 777004;
  static readonly AGENDAS_TRANSFER_CODE = '2090001';
  static readonly AGENDAS_THIRD_TRANSFER_CODE = '2080014';
  static readonly AGENDAS_INTERNATIONAL_TRANSFER_CODE = '382500';
  static readonly SIXTY_MINUTE_CODE = 2145;
  static readonly NOT_FOUND_ITEM = -1;
  static readonly NOT_FOUND_CLARIFICATIONS = 4;
  static readonly EDC_NOT_AVAILABLE = 1;

  // Generales
  static readonly PASSWORD_LENGTH = 15;
  static readonly KEY_LENGTH = 20;
  static readonly BANK = 'BANCO';
  static readonly SERVICES_PAY_TYPE = 'S';
  static readonly MOBILE_APP = 'MOVIL';
  static readonly AUTHENTICATION_TYPE = 'UserNip';
  static readonly FACEPASS_AUTHENTICATION = 'UserFacePass';
  static readonly ACCESS_METHOD_PORTAL = 'PORTAL';
  static readonly ACCESS_CHANNEL = 'ESCRITORIO';
  static readonly AMOUNT_ZERO = 0;
  static readonly TOKEN_EMPTY = Constants.AMOUNT_ZERO.toString();
  static readonly DEFAULT_IP = '0.0.0.0';
  static readonly SESSION_TIMER = 1_000;
  static readonly SESSION_TIMEOUT = 1_200_000;
  static readonly SESSION_INACTIVITY_TIMEOUT = 360_000;
  static readonly SESSION_INACTIVITY_ALERT_TIMEOUT = 300_000;
  static readonly QR_FACEPASS_ATTEMPTS = environment.facepass.qrFacepassAttempts;
  static readonly QR_FACEPASS_TIMER = environment.facepass.qrFacepassTimer;
  static readonly MAXIMUM_LENGTH_EMAIL = 150;
  static readonly LENGTH_EMAIL_COPIES = 500;
  static readonly MINIMUM_LENGTH_REFERENCE = 0;
  static readonly MAXIMUM_LENGTH_ACCOUNT = 50;
  static readonly LENGTH_EMAIL_MESSAGE = 75;
  static readonly MAXIMUM_LENGTH_EMAIL_INTERNATIONAL = 50;
  static readonly MAXIMUM_LENGTH_EMAIL_AGENDAS = 80;
  static readonly MAXIMUM_LENGTH_ALIAS_INTERNATIONAL = 50;
  static readonly MAXIMUM_LENGTH_OWNER_INTERNATIONAL = 100;
  static readonly MAXIMUM_LENGTH_ADDRESS = 200;
  static readonly MAXIMUM_LENGTH_OWNER = 50;
  static readonly MAXIMUM_LENGTH_CARD = 22;
  static readonly MINIMUM_LENGTH_CARD = 1;
  static readonly MAXIMUM_LENGTH_BIC = 13;
  static readonly LENGTH_INDIVIDUAL_RFC = 13;
  static readonly LENGTH_LEGAL_RFC = 12;
  static readonly MAXIMUM_LENGTH_STATE = 30;
  static readonly MINIMUM_LENGTH_LIMIT_INTERNATIONAL = 100;
  static readonly MINIMUM_LENGTH_AMOUNT_INTERNATIONAL = 250;
  static readonly DAILY_LENGTH_LIMIT = 15;
  static readonly LENGTH_BIC = 8;
  static readonly MINIMUM_LENGTH_INTERNATIONAL = '1';
  static readonly NUMERIC_REFRESH_TIME = 30;
  static readonly INVESTMENT_ACCOUNT_SIZE = 20;
  static readonly MAXIMUM_AMOUNT_INTERNATIONAL = 30000000;
  static readonly MAXIMUM_OPERATION_AMOUNT = 999_999_999_999_999.90;
  static readonly CT_MAX_PROTECTED_AMOUNT = 100_000.00;
  static readonly CREATE_PDF = (image: string, type: string, base64: string, code: string) => `${image}:${type};${base64},${code}`;
  static readonly PIPE_ACCOUNT_NUMBER = 'numCuenta';
  static readonly PIPE_MAIN_CONFIRM = 'maskAccount';
  static readonly PIPE_QUANTITY_FORMAT = 'quantityFormat';
  static readonly PIPE_CUSTOM_DATE = 'customDate';
  static readonly MINIMUM_INVESTMENT_AMOUNT = 30000;
  static readonly INSURANCE_COMPLETE = 'insuranceComplete';
  static readonly VALUE_YES = 'Sí';
  static readonly PROMISSORY_NOTE = 'PAGARE';
  static readonly TRANSFER_PERFORMANCE = 'TRANSFER';
  static readonly RENEW_BALANCE_PERFORMANCE = 'RENEWBALANCE';
  static readonly RENEW_PERFORMANCE = 'RENEW';
  static readonly TRANSFER_REGISTER = 'Transfer';
  static readonly RENEW_BALANCE_REGISTER = 'Renewbalance';
  static readonly RENEW_REGISTER = 'Renew';
  static readonly ZERO_VALUE_$ = '$0.00';
  static readonly DATE = 'date';
  static readonly FALSE = 'false';
  static readonly TRUE = 'true';
  static readonly CLASS_ERROR_EXCEPTION_COMPONENT = 'modal-error';
  static readonly CLASS_ERROR_EXCEPTION_COMPONENT_AUTOSIZE = 'modal-error-autosize';
  static readonly CLASS_STYLE_ENTER_AMOUNT = 'font-size-default';
  static readonly AUDIO_VOLUME = 0.4;
  static readonly APPLICATION_DATE = 'Fecha de aplicación';
  static readonly START_PAGE = 0;
  static readonly MAX_AMOUNT_NOM_WEB = 5000;
  static readonly PROFILE_MESSAGE = 'perfil';
  static readonly CODE_NFD = 'NFD';
  static readonly ACCOUNT_CATEGORY = 'Deposit';
  static readonly SPINNER_Z_INDEX_BEHIND = '1054';

  //TipoConsultaEDC
  static readonly TYPE_OF_QUERY_ACCOUNT_PERIODS = 'E';
  static readonly ZERO_DATA_REQUEST_CREDIT_CARD = 0;
  static readonly STATUS_REQUEST_CREDIT_CARD = 1;
  static readonly WINDOW_NUMBER = 768;
  static readonly MAX_SCREEN_WIDTH = 380;

  //PDF-periodosEDC
  static readonly NAME_PDF_ACCOUNT_STATEMENTS = 'consultar-cuenta.pdf';
  static readonly ORIGIN_PDF_ACCOUNT_STATEMENTS = 2;

  static readonly NAME_PDF_TDC_PAYMENT = 'Comprobante de pago';
  static readonly NAME_PDF_SERVICE_PAYMENT = 'PagoServicios';

  //Indicador que señala si se imprime o no el EDC.
  static readonly YES_PRINT_ACCOUNT_STATEMENT = 'S';
  static readonly NO_PRINT_ACCOUNT_STATEMENT = 'N';

  //Indicador que señala si se retiene o no el EDC.
  static readonly RETAIN_ACCOUNT_STATEMENT = Constants.YES_PRINT_ACCOUNT_STATEMENT;

  //Tipo accesso
  static readonly ACCESS_TOKEN_TYPE = 'TOKEN';
  static readonly ACCESS_FACEPASS_TYPE = 'FACEPASS';
  static readonly SIGNATURE_VALIDATION = 'Purse';

  //Tipo numero de cuenta
  static readonly KEY_ACCOUNT = '40';
  static readonly CARD = '03';

  //destinatario
  static readonly NATIONAL_ADDRESSEE = '1';
  static readonly INTERNATIONAL_ADDRESSEE = '2';
  static readonly CHANGE_FORMAT = 2;

  //Plaza bancaria
  static readonly BANKING_SQUARE = 101;

  //longitud de la cuenta
  static readonly CLABE_LENGTH = 18;
  static readonly MIN_CARD_LENGTH = 16;
  static readonly MIN_CARD_PORTABILITY_LENGTH = 10;
  static readonly CARD_AMEX_LENGTH = 15;
  static readonly ACCOUNT_NUMBER_LENGTH = 11;
  static readonly MINIMUM_CAPTURE_LENGTH_CDMX = 16;
  static readonly MAXIMUM_CAPTURE_LENGTH_CDMX = 22;
  static readonly GROUP_ACCOUNT = 4;

  //Transferencias Express
  static readonly ID_AGENDA_EXPRESS_TRANSFER = 0;
  static readonly EXPRESS_TRANSFER_TO_THE_FUTURE = 0;
  static readonly ID_ADDRESSEE_TRANSFER = Constants.AMOUNT_ZERO.toString();
  static readonly MINIMUM_LENGTH_FORM_TYPE_ACCOUNT = 13;
  static readonly INBURSA_DEFAULT_BANK = 'BANCO INBURSA';
  static readonly CODE_INBURSA_BANK = '036';
  static readonly INBURSA_BANK = 'Inbursa';
  static readonly TRANSFER_COMPLETE_ZERO = 0;
  static readonly CLAIM_MAX_LENGTH = 13;

  static readonly TRANSACTION_TYPE = 'DoTransferNow';

  static readonly TYPE_TRANSFER_NAME = {
    Express: 'express',
    National: 'normal'
  };

  static readonly CODE_AGENDA = {
    National: 1,
    International: 2
  };

  //Pago de creditos
  static readonly EXCESS_AMOUNT_PP = 'PP';
  static readonly EXCESS_AMOUNT_PR = 'PR';

  // Pago de servicios
  static readonly SERVICE_PAYMENT_ZERO = 0;
  static readonly APP_TYPE = 'App';
  static readonly PAYMENT_TYPE_SERVICES = 'PayBillNow';
  static readonly NON_AGENDABLE_SERVICES_ID: { [key: string]: NonAgendableServiceDetails } = environment.NonAgendableService;
  static readonly OPERATION_ID = 'newService';
  static readonly MODAL_ID = 'noService';
  static readonly INVESTMENT = 'investment';
  static readonly IS_AGENDA = '1';

  // Agregar nuevos servicios.
  static readonly MAXIMUM_AMOUNT_NEW_SERVICE = 100000;

  static readonly EXTERNAL_LINKS = {
    SecurityTips: 'https://www.inbursa.com/Portal/?page=Document/doc_view_section.asp&id_document=1442&id_category=44',
    App: 'https://www.inbursa.com/Portal/?page=Document/doc_view_section.asp&id_document=13650&id_category=44',
    Condusef: 'https://www.condusef.gob.mx/',
    NoticeOfPrivacy: 'https://www.inbursa.com/storage/Aviso_Privacidad.pdf',
    LegalWarning: 'https://www.bancoinbursa.com/aviso_legal.htm ',
    Documentation: 'https://www.inbursa.com/Portal/?page=Document/doc_view_section.asp&id_document=4934&id_category=93'
  };


  //Movimientos

  static readonly MOMENTS_OF_DAY = 'Hoy';
  static readonly MOMENTS_YESTERDAY = 'Ayer';

  static readonly MOZILLA = 'Mozilla/5.0';
  static readonly MAC_OS = 'like Mac OS X';
  static readonly DEFAULT_DEVICE = 'Build';
  static readonly DEFAULT_WINDOWS_DEVICE = 'Win64';
  static readonly DEVICE_PROCESSOR = 'CPU';

  //ACCESOS
  static readonly SUCCESS_ACCESS = 'Exitoso';
  static readonly UNSUCCESS_ACCESS = 'No exitoso';

  //PROCEDENTE
  static readonly FAIR = 'Procedente';
  static readonly UNFAIR = 'Improcedente';

  //Portabilidad
  static readonly PORTABILITY = {
    Send: 'E',
    Receive: 'R',
    IdPath: 'portability',
    Active: 'T',
    OtpCode: 'otpCode',
    AddAccount: 'account',
    Data: 'Portabilidad',
  };



  static readonly STATUS_PORTABILITY = {
    Active: 'ACTIVO',
    Pending: 'PENDIENTE',
    Sent: 'ENVIADA',
    Authorize: 'POR AUTORIZAR',
    Rejected: 'RECHAZADA',
    Canceled: 'CANCELADA'
  };

  static readonly RECEIVE_PAYROLL_IN = 'Recibir nómina en';
  static readonly SEND_PAYROLL = 'Enviar nómina de';
  static readonly MSG_INBURSA_ANOTHER_BANK = 'De Inbursa a otro banco';
  static readonly MSG_OTHER_BANK_TO_INBURSA = 'De otro banco a Inbursa';
  static readonly PREPAID = 'Prepago';
  static readonly LIQUIDATE_CREDIT = 'Liquidar crédito';
  static readonly DESCRIPTION = 'PAGO CREDITO';

  static readonly COLOR_CLASS = {
    Green: 'pending-green',
    Orange: 'pending-orange',
    Red: 'pending-red'
  };

  //Info Gral Facepass & Token
  static readonly INFO_DEVICE_ANDROID = 'Android';
  static readonly INFO_DEVICE_IOS = 'iOS';
  static readonly INFO_DEVICE_TXT_STATUS = 'Activo';
  static readonly MAXIMUM_AMOUNT_TRANSFER_EXPRESS_FACEPASS = 50000;
  static readonly MAXIMUM_AMOUNT = 8000;
  static readonly FACEPASS_ACTIVE_STATUS = 'Dispositivo Activo';
  static readonly ACTIVE_TOKEN_STATUS = 'Active';
  static readonly PHYSICAL_TOKEN_SERIAL_LENGTH = 10;
  static readonly DIGITAL_TOKEN_SERIAL_LENGTH = 12;
  static readonly OTP_POSITION = 14;
  static readonly SERVICE_NOT_AVAILABLE = 'INB-2003';
  static readonly SERVICE_SLOW_TO_RESPOND = 'INB-1001';

  //Administracion Tarjetas
  static readonly MINUTES_DYNAMIC_CVV = 5;
  static readonly SECONDS_DYNAMIC_CVV = 0;
  static readonly SECONDS_INITIAL_DYNAMIC_CVV = 59;
  static readonly DYNAMIC_CVV_TIME = 1000;
  static readonly MSG_SERVICE_RESPONSE_SUCCESSFUL = 0;
  static readonly UPDATE_ATM_LABEL = 'ATM';
  static readonly UPDATE_POS_LABEL = 'POS';
  static readonly UPDATE_OPI_LABEL = 'OPI';
  static readonly UPDATE_INT_LABEL = 'INT';
  static readonly UPDATE_AMOUNT = 'AMOUNT';
  static readonly UPDATE_MOVEMENTS = 'MOVEMENTS';
  static readonly UPDATE_LOCK = 'LOCK';
  static readonly MINIMUM_ATM_AMOUNT = 50;
  static readonly MINIMUM_COMMERCE_AMOUNT = 1;
  static readonly MINIMUM_ONLINE_AMOUNT = 1;
  static readonly MAXIMUM_ATM_AMOUNT = 25000;
  static readonly MAXIMUM_COMMERCE_AMOUNT = 50000;
  static readonly MAXIMUM_ONLINE_AMOUNT = 50000;
  static readonly MOVEMENTS_ZERO = 0;

  //CODIGOS DE SERVICIOS
  static readonly INTERNATIONAL_AGENDAS = 'EXTINT';

  static readonly FOLIO_SUCCESSFUL = '123456789';
  static readonly SERVICE_CARD = 'DOMICFE . DOMI CFE';

  static readonly SERVICE_CODE = {
    Paggm: 'PAGGMM',
    Psegdp: 'PSEGDP',
    ExternalTrade: 'COMEXT',
    Sipare: 'SIPARE',
    MexicoState: 'IMPGEM',
    SuaPayment: 'PSUAARCH',
    Carnet: 'CARNET',
    CreditPayment: 'PAGOCREDITO',
    International: 'INTERNATIONAL',
    Impfedr: 'IMPFEDR',
    Impfed: 'IMPFED',
    SearsPayment: 'PAGSEARS',
    Psegnal: 'PSEGNAL',
    Imploc: 'IMPLOC',
    GasNatural: 'GASNATUR',
    Cfe: 'CFE',
    CiaLuz: 'CIALUZ',
    Pagvesem: 'PAGVESEM',
    Pagvesfv: 'PAGVESFV',
    Portability: 'portabilities'
  };

  static readonly AGENDABLE_SERVICE_CODE = {
    Telmex: 'TELMEX',
    Sky: 'SKY',
    Amex: 'AMEX',
    Mixup: 'MIXUP',
    Sanborns: 'SANBORN',
    Amitelc: 'AMITELC',
    Contelc: 'CONTELC',
    Tcinb: 'TCINB',
    Pagliver: 'PAGLIVER',
    Pagoph: 'PAGOPH',
    Iave: 'IAVE'
  };


  static readonly SERVICES_WITHOUT_IMAGE = [
    this.SERVICE_CODE.Sipare,
    this.SERVICE_CODE.Psegnal,
    this.SERVICE_CODE.SuaPayment,
    this.SERVICE_CODE.Paggm,
    this.SERVICE_CODE.ExternalTrade
  ];

  static readonly AGENDABLE_SERVICE_ID = [
    this.AGENDABLE_SERVICE_CODE.Telmex,
    this.SERVICE_CODE.Carnet,
    this.AGENDABLE_SERVICE_CODE.Sky,
    this.AGENDABLE_SERVICE_CODE.Amex,
    this.AGENDABLE_SERVICE_CODE.Mixup,
    this.AGENDABLE_SERVICE_CODE.Sanborns,
    this.AGENDABLE_SERVICE_CODE.Amitelc,
    this.AGENDABLE_SERVICE_CODE.Contelc,
    this.AGENDABLE_SERVICE_CODE.Tcinb,
    this.AGENDABLE_SERVICE_CODE.Pagliver,
    this.AGENDABLE_SERVICE_CODE.Pagoph,
    this.AGENDABLE_SERVICE_CODE.Iave
  ];

  static readonly SERVICE_ID = {
    InsurancePayment: 100,
    TaxPayment: 22,
    Investment: 200,
    Deductible: 25,
    EdoMex: 18,
    Sipare: 15,
    DefaultService: 13,
    ExpressTransfer: 0,
    SUA: 23
  };

  static readonly INFORMATION_BIC_NEW_INTERNATIONAL_AGENDA = {
    Bic: 0,
    SignaturePanel: 1,
    BankName: 2,
    Country: 3,
    City: 4,
    State: 6
  };

  static readonly INFORMATION_ACCOUNT_NEW_INTERNATIONAL_AGENDA = {
    Account: 0,
    DailyLimit: 1,
    HolderName: 2,
    Address: 3,
    Email: 4,
    Currency: 5,
    Beneficiary: 6,
    Alias: 7
  };

  static readonly AGENDA_SERVICES = 'SERV';
  static readonly THIRD_AGENDAS = 'CTAINT';
  static readonly NATIONAL_AGENDAS = 'CTAEXT';
  static readonly MINIMUM_AMOUNT = 1.00;
  static readonly MAX_LENGTH_CARNET = 15;
  static readonly PATH_IMAGE_CARNET = 'VisaMasterCard';
  static readonly FUTURE_EXPRESS_TRANSFER = 0;

  static readonly NUMBERS_DATE = {
    Zero: 0,
    One: 1,
    Two: 2,
    Four: 4
  };

  //ALTA AGENDA INTERACIONAL
  static readonly INTERNATIONAL = 'Internacional';
  static readonly CONFIRM_STATE = 'Confirmación';
  static readonly TYPE_CURRENCY_DOLLAR = 'Dolar';
  static readonly TYPE_CURRENCY_EURO = 'Euro';
  static readonly BENEFICIARY_PHYSICAL = 'Fisica';
  static readonly BENEFICIARY_MORAL = 'Moral';
  static readonly BENEFICIARY_PHYSICAL_ID = 'F';
  static readonly BENEFICIARY_MORAL_ID = 'M';
  static readonly BENEFICIARY_UNKNOWN_ID = 'D';
  static readonly BENEFICIARY_UNKNOWN = 'Desconocido';
  static readonly INTERNATIONAL_GUIDE_LINK = 'https://www.inbursa.com/storage/manual-transferencias-internacionales-banca.pdf';

  // Images
  static readonly IMAGE_DATA = 'data';
  static readonly IMAGE_NOT_FOUND = 'La imagen no existe.';
  static readonly CANCEL_BUTTON = 'Cancelar';
  static readonly POPUP_OK_BUTTON = 'OK';
  static readonly POPUP_CONTINUE_BUTTON = 'Continuar';
  static readonly CODEFLEX_DESCRIPTION = 'codeflex';
  static readonly WEBCARD_DESCRIPTION = 'web';
  static readonly CT_PATRIMONIAL_DESCRIPTION = 'pat';
  static readonly SALARY_ACCOUNT_DESCRIPTION = 'efe';
  //International Transfers
  static readonly MXP_EXCHANGE_RATE = 'MXP_USD';
  static readonly EUR_EXCHANGE_RATE = 'EUR_USD';
  static readonly DISABLED_WARRANTY_CODE = Constants.NO_PRINT_ACCOUNT_STATEMENT;
  static readonly ENABLED_WARRANTY_CODE = Constants.YES_PRINT_ACCOUNT_STATEMENT;
  static readonly DISABLED_WARRANTY = 'No';
  static readonly ENABLED_WARRANTY = 'Si';
  static readonly WARRANTY = 'warranty';
  static readonly NOT_WARRANTY = 'noWarranty';

  //Calendarios
  static readonly MONTH = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4
  };

  static readonly MIN_DATE_PORTABILITY = { year: 1945, month: 1, day: 1 };
  static readonly MAX_DATE_PORTABILITY = { year: new Date().getFullYear(), month: 12, day: 31 };
  static readonly MIN_DATE_SERVICES_PAYMENT = { year: new Date().getFullYear(), month: new Date().getMonth() + Constants.MONTH.ONE, day: new Date().getDate() };
  static readonly MAX_DATE_SERVICES_PAYMENT = { year: new Date().getFullYear(), month: new Date().getMonth() + Constants.MONTH.FOUR, day: new Date().getDate() };
  static readonly MAX_DATE_CODEFLEX = { year: 2023, month: new Date().getMonth() + Constants.MONTH.THREE, day: new Date().getDate() + 1 };
  static readonly MIN_DATE_DEFAULT = { year: new Date().getFullYear(), month: new Date().getMonth() + Constants.MONTH.ONE, day: new Date().getDate() };
  static readonly MAX_DATE_DEFAULT = { year: new Date().getFullYear(), month: new Date().getMonth() + Constants.MONTH.TWO, day: new Date().getDate() };
  static readonly DATE_NULL = null;
  static readonly MIN_DATE_VOLUNTARY_CONTRIBUTIONS = { year: 1945, month: 1, day: 1 };
  static readonly MAX_DATE_VOLUNTARY_CONTRIBUTIONS = { year: new Date().getFullYear(), month: new Date().getMonth() + Constants.MONTH.ONE, day: new Date().getDate() };

  static readonly PADDING_TOP = 'padding-top';
  static readonly PADDING_BOTTOM = 'padding-bottom';
  static readonly MARGIN_TOP = 'margin-top';
  static readonly MARGIN_BOTTOM = 'margin-bottom';
  static readonly WIDTH = 'width';

  static readonly LOYALTY_REDEEM_TYPE = 'Redencion aplicada';
  static readonly MIN_POINTS_TO_REDEEM = 1000;

  static readonly NOT_CHECK = 'noCheques';
  static readonly BENEFICIARY_NAME = 'beneficiaryName';

  //Directive
  static readonly DECIMALS = 'decimals';
  static readonly MAXIMUM_LENGTH = 'maxLength';
  static readonly CURRENCY = 'currency';
  static readonly FORMAT = 'format';
  static readonly DECIMALS_AMOUNT = 2;
  static readonly MAXIMUM_LENGTH_AMOUNT = 15;

  static readonly VERSION = '1.2-2';

  static readonly INPUT_LIMIT_ID = 'limit';

  static readonly CLOSE_BUTTON = 'closeBtn';
  static readonly PATRIMONIAL = 'patrimonial';
  static readonly CLABE = 'CLABE: ';
  static readonly INITIAL_HOUR = 'h';

  static readonly CONDITIONS = 'conditions';
  static readonly CONSULT = 'consult';
  static readonly ID = 'id';
  static readonly CHECK = 'cheque';
  static readonly ERROR_ZERO = Constants.AMOUNT_ZERO.toString();
  static readonly ERROR_LOGIN = 'error';
  static readonly ALERT_LOGIN = 'alert';
  static readonly ALERT_TRANSFER_CARD = 'alert-transfer-card';
  static readonly PERMISSIONS_GEOLOCATION = 'geolocation';
  static readonly ANCHOR = 'A';
  static readonly ANCHOR_TARGET = '_blank';
  static readonly FORMAT_DATE_TAXES = 'numeric';
  static readonly FORMAT_DATE_TAXES_DIGIT = '2-digit';
  static readonly TAXES_FORM = 'waterAccountNumber';
  static readonly WAIT_TIME_ZERO = 0;
  static readonly MAX_IMAGE_SIZE = 262144;
  static readonly ID_POLICY_DOLLARS = '02';

  static readonly RESOLUTIONS = {
    DesktopMinWidth: 800,
    WidthMinIphone: 321,
    WidthMaxIphone: 328,
    WidthIphone: 414,
    IpadMinWidth: 1024,
    IpadMaxWidth: 321,
    MacWidth: 1920,
    PhoneMaxWidth: 850,
    PhoneMinWidth: 374,
    TabletMinWidth: 768
  };

  static readonly USER_AGENT = {
    Mac: /mac|macintosh/,
    Iphone: /iphone|ipod/i,
    Ipad: /ipad/i,
    MobileApple: /ipad|iphone|ipod/i,
    Mobile: /ipad|android|iphone|webos|ipod|blackberry|iemobile|opera mini/i,
    TabletIos: /Macintosh|iPad/i,
    MobileIos: /iPhone|Android/i,
    MacOption: /mac/,
    MacIphone: /mac|macintosh|iPhone/,
    MacIpad: /macintosh|ipad/
  };

  static readonly ACCESS_PER_PAGE = 10;
  static readonly MINIMUM_FILE_SIZE = 0;

  static readonly ONLINE_BANK_APP_ID = 1;
  static readonly ONLINE_BANK_ID = 2;

  static readonly MIN_AMOUNT_LENGTH = 15;
  static readonly HAC_CODE_LENGTH = 4;
  static readonly MAX_LENGTH_LIMIT = 50;
  static readonly MAX_LENGTH_CVV = 3;
  static readonly MAX_LENGTH_NIP = 4;
  static readonly LENGTH_TOKEN = 8;

  static readonly TITLES_ACCESS_HISTORY = [
    { id: 1, title: 'ACCESO' },
    { id: 2, title: 'TIPO DE ACCESO' },
    { id: 3, title: 'FECHA Y HORA' },
    { id: 4, title: 'IP DE ACCESO' },
    { id: 5, title: 'DISPOSITIVO' },
    { id: 6, title: 'NAVEGADOR' },
    { id: 7, title: 'DESCRIPCIÓN' }];

  static readonly OPERATION_LOCK = {
    ATM: 0,
    POS: 1,
    OPI: 2,
    INT: 3
  };

  static readonly STYLE_IMAGE = 'style';
  static readonly TRANSFER_EXPRESS_CODE = 0;
  static readonly PAYMENT_DETAILS_URL = 'https://www.finanzas.cdmx.gob.mx';
  static readonly ZERO_CURRENCY = 0.00;
  static readonly FONT_SIZE_DEFAULT = 48;
  static readonly AMOUNT_LENGTH_DEFAULT = 3;
  static readonly TOTAL_DECIMALS = 2;
  static readonly BADAGE_MIN_LENGTH = 4;
  static readonly MOVEMENTS_ACCOUNT_LENGTH = 10;
  static readonly LENGTH_IMSS = 11;
  static readonly LENGTH_ISSSTE = 18;
  static readonly IMSS = 'IMSS';
  static readonly ISSSTE = 'ISSSTE';
  static readonly INACTIVITY_TIME_ZERO = 0;

  //Headers
  static readonly HEADER_USER_ID = 'idUsuario';
  static readonly HEADER_DESTINATION_ID = 'idDestino';
  static readonly HEADER_CONSUMER_ID = 'idConsumidor';

  //Events
  static readonly TOKEN = 'token';
  static readonly CURRENT_VALUE_ONE = 1;
  static readonly CURRENT_VALUE_TWO = 2;

  //AddProducts
  static readonly ACCOUNT_ID_ZERO = Constants.AMOUNT_ZERO.toString();
  static readonly GENERIC_RFC = 'XAXX010101000';

  //Service Image
  static readonly SERVICE_IMAGE = 'serviceImage';
  static readonly DEFAULT_BTN_LABEL = 'Aceptar';

  //Checks
  static readonly APPLICATION = 'application';
  static readonly ACTIVATION = 'activation';

  static readonly FONT_SIZE = {
    Small: 36,
    Normal: 48
  };

  static readonly SERVICE_LAYOUT = {
    Others: 4
  };

  static readonly MEXICAN_CURRENCY = 'MXN';
  static readonly USER_AGENT_OPERA = 'opr';
  static readonly USER_AGENT_FIREFOX = 'firefox';
  static readonly RETURN_BROWSER = 'popstate';

  //Path Model
  static readonly ENDPOINT_PATH_MODEL = 'endpoint';

  //Payroll
  static readonly VIEW_ALERT_PAYROLL = Constants.AMOUNT_ZERO.toString();
  static readonly MEMBER_SHIP_NUMBER = 'membershipNumber';

  static readonly SERVICES_SCHEDULED = 'imgRoute';
  static readonly CASH = 'Efectivo';
  static readonly NOT = Constants.DISABLED_WARRANTY;
  static readonly NOTIFICATIONS = Constants.MINUTES_DYNAMIC_CVV;
  static readonly MOVEMENTS_INVESTMENT_PDF = '';

  static readonly FORM_CONTROLS = {
    MaximumLimit: 'maximumLimit',
    Alias: 'alias',
    Email: 'email',
    Value: 'value',
    Concept: 'concepto',
    Badge: 'badge',
    ValidAmount: 'validAmount',
    Reference: 'referencia',
    Type: 'tipo',
    Spei: 'SPEI',
    Date: 'date',
    CheckNumber: 'checkNumber',
    HeirName: 'heirName',
    BankName: 'bankName',
    AccountCreditNumber: 'accountCreditNumber',
    AccountNumber: 'accountNumber',
    TelephoneNumber: 'telephoneNumber',
    DailyLimit: 'dailyLimit',
    Option: 'option',
    Select: 'select',
    EmailCopy: 'emailCopy',
    EmailMessage: 'emailMsg',
    CopyToMe: 'copyToMe',
    LimitData: 'limitData',
    CVV: 'cvv',
    MaximumLength: Constants.MAXIMUM_LENGTH,
    NIP: 'nip',
    MinimumLength: 'minlength',
    Cancel: 'cancel',
    Clabe: 'clabe',
    Addressee: 'destinatario',
    Bank: 'banco',
    PanelSignature: 'panelSignature',
    Amount: 'amount',
    Account: 'account',
    CaptureLine: 'captureLine',
    ClaimValue: 'claimValue'
  };

  static readonly NAVIGATION_PARAMETERS = {
    IsInternationalTransfer: 'isInternationalTransfer',
    IsTransfer: 'isTransfer',
    SendPatrimonial: 'sendPatrimonial',
    OtpCode: 'otpCode',
    Service: 'service',
    ListInsurance: 'listInsurance',
    DataAmount: 'dataAmount',
    RemoveCurrencyFormat: 'removeCurrencyFormat',
    OTP: 'otp',
    SerialNumber: 'serialNumber',
    Next: 'next',
    IdOperation: 'idOperation',
    Title: 'titulo',
    Subtitle: 'subtitulo',
    ListSelectInsurance: 'listSelectInsurance',
    Amount: 'amount',
    DataInternational: 'dataInternational',
    DataInvestment: 'dataInvestment',
    AmountInvestment: 'amountInvestment',
    DataBIC: 'dataBIC',
    DataAccount: 'dataAccount',
    ListInvestment: 'listInvestment',
    DataConfirmPatrimonial: 'dataConfirmPatrimonial',
    DataForm: 'dataForm',
    Codeflex: 'codeflex',
    DataConfirm: 'dataConfirm',
    NextView: 'nextView',
    BeforeView: 'beforeView',
    DataSelect: 'dataSelect',
    Id: 'id',
    HasAccountSelected: 'hasAccountSelected',
    NavigationBack: 'navigationBack',
    LabelTitle: 'title',
    LabelSubtitle: 'subtitle',
    Alert: 'alert',
    ValidNavigation: 'validNavigation'
  };

  static readonly SELECTED_STATUS: 'selected';
  static readonly EXTRA_DATE_FORMAT: '$3/$2/$1';
  static readonly PIPE_ACCOUNT_NUMBER_EXPRESS = 'expressTransferAccountNumber';

  static readonly MODAL_OPTIONS = {
    FormValidateClass: 'form-validate',
    StylesTs: 'style-ts',
    SizeMd: 'md',
    SizeSm: 'sm',
    SizeXl: 'xl',
    SizeLg: 'lg',
    LabelConfirm: 'Confirmar',
    CloseModal: 'CerrarModal',
    CancelAccount: 'cancelAccount',
    FrameGeneralException: 'frame-general-exception',
    AriaLabel: 'modal-basic-title',
    BorderNone: 'border-none',
    CancelCard: 'cancelCard',
    AdditionalAttention: 'aditionalAttention',
    ContainerPopup: 'container-popup',
    CompleteModify: 'complete-modify',
    FormModify: 'form-modify',
    SuaModal: 'suaPayment',
    NoBorder: 'no-border',
    HiddenContainer: 'hidden-container',
    SaveClick: 'Save click',
    Card: 'card',
    PointsToRedeem: 'pointsToRedeem',
    CurrentPoints: 'currentPoints',
    AllowedPoints: 'allowedPoints',
    NotFileId: 'modalNotFile',
    idStyleModalModifyConfirm: 'confirm-modify',
    idStyleModalModify: 'form-modify',
    DeleteFacepass: 'deleteFacepass',
    CancelToken: 'cancelToken',
    DeleteNotification: 'deleteNotificationModal',
    CancelTokenConfirm: 'cancelTokenConfirm',
    DeleteFacepassConfirm: 'deleteFacepassConfirm',
    ErrorModal: 'error',
    CancelAccountConfirm: 'confirmCancelAccount',
    CancelCardModal: 'cancelCardModal',
    SelectedCancelCard: 'selectedCancelCard',
    SelectedCardReplenishment: 'selectedCardReplenishment',
    Attention: 'attention',
    SendEmail: 'sendEmail',
    CancelAction: 'cancelAction',
    ModalBureauAlertComponent: 'ModalBureauAlertComponent',
    ModalSuccessfulRequestComponent: 'ModalSuccessfulRequestComponent',
    ModalHoursOperationIphone: 'modal-hours-operation-iphone',
    ClipboardData: 'clipboardData',
    ContentIphone: 'content-iphone'
  };

  static readonly DATA_AGENDA_NATIONAL = {
    SignaturePanel: 'signaturePanel',
    Account: 1,
    BankName: 2,
    Headline: 3,
    Alias: 4,
    Rfc: 5,
    Limit: 6,
    Email: 7,
    Email2: 8,
    ShowConfirmAgenda: 9,
    ShowToken: 10,
    CodeBank: 12,
    IdAgenda: 13,
    Otp: 14

  };

  static readonly DATA_AGENDA_INTERNATIONAL = {
    Clabe: 0,
    SignaturePanel: 1,
    Bank: 2,
    Country: 3,
    TypeAgenda: 4,
    Account: 5,
    Currency: 6,
    Limit: 7,
    Beneficiary: 8,
    Headline: 9,
    Address: 10,
    Email: 11,
    Confirm: 12,
    City: 13,
    State: 14,
    Alias: 15
  };

  static readonly NAVIGATION_SWIPER = {
    SlidesView: 'auto',
    NextElementForYou: '.swiper-btn-next-foryou',
    PreviousElementForYou: '.swiper-btn-prev-foryou',
    NextElementRewards: '.swiper-btn-next-rewards',
    PreviousElementRewards: '.swiper-btn-prev-rewards',
    NextElementPromotion: '.swiper-btn-next-promotion',
    PreviousElementPromotion: '.swiper-btn-prev-promotion',
    NextElement: '.swiper-btn-next',
    PreviousElement: '.swiper-btn-prev'
  };
  static readonly PORTABILITY_NOT_EXISTS_ID = 1000;
  static readonly AUTOFOCUS_TIMEOUT = 300;
  static readonly CARD_MODAL_HEIGHT = 129;
  static readonly TIMEOUT_SCROLL = 100;
  static readonly GENERIC_TIMEOUT = 50;
  static readonly TIME_TO_APPLY = 24;
  static readonly LOCAL_TAXES_MAX_LENGTH = 20;
  static readonly ACCOUNT_MIN_LENGTH = 12;
  static readonly PAGINATION_SIZE = 10;
  static readonly MAX_INVESTMENT_LENGTH = 10;
  static readonly MAX_PHONE_LENGTH = 10;
  static readonly MAX_ALIAS_LENGTH = 50;
  static readonly SIGNATURE_PANEL_LENGTH = 9;
  static readonly AMOUNT_LENGTH = 9;
  static readonly DIGITS_REMOVED = 7;
  static readonly MAX_GROSS_YIELD = 7;
  static readonly PAGE_SIZE_MODAL_SERVICE = 6;
  static readonly POLICY_MAX_LENGTH = 5;
  static readonly START_ACCOUNT_LENGTH = 5;
  static readonly REMOVE_DIGITS_TO_MASK_ACCOUNT = 4;
  static readonly NUMBER_INPUTS_FIST_CARD = 4;
  static readonly LOGIN_FACEPASS_ID = 3;
  static readonly PAGE_SIZE_ACCOUNT = 3;
  static readonly INPUT_SIGNATURE_PANEL_POSITION = 3;
  static readonly PAGE_SIZE_POLICIES = 3;
  static readonly PORTABILITY_INPUT_KEYUP = 2;
  static readonly PORTABILITY_INPUT_BLUR = 1;
  static readonly INPUT_ACCOUNT_CARNET_DETAILS_SCHEDULE = 2;
  static readonly INPUT_CARNET_DETAILS_SCHEDULE = 2;
  static readonly INPUT_ACCOUNT_DETAILS_SCHEDULE = 1;
  static readonly TOTAL_DIGITS_DATE = 2;
  static readonly MIN_ELEMENTS_PER_PAGE = 3;
  static readonly ID_AUTHORIZATION_NULL = 0;
  static readonly DECIMALS_EMPTY = 0;
  static readonly AUTO_FOCUS_STARTUP = 0;
  static readonly CARD_SELECTED_DEFAULT = 0;
  static readonly CVV_TIME_EXPIRED = 0;
  static readonly PAGE_DEFAULT = 1;
  static readonly EXCHANGE_RATE_EMPTY = 1;
  static readonly DOWNLOAD_INDEX = 1;
  static readonly IS_DECIMAL = 1;
  static readonly INVESTMENT_STYLE_ID = 1;
  static readonly SUBSCRIBE_ONLY_ONCE = 1;
  static readonly MAX_FILE_IMPORT = 1;
  static readonly NAME_MIN_LENGTH = 1;
  static readonly CLAIM_MIN_LENGTH = 1;
  static readonly DEDUCTIBLE_MIN_LENGTH = 1;
  static readonly COINSURANCE_MIN_LENGTH = 1;
  static readonly ISSUR_CODE_MIN_LENGTH = 1;
  static readonly NOTIFY_LETTER_MIN_LENGTH = 1;
  static readonly INPUT_TOKEN_MAX_LENGTH = 1;
  static readonly WATER_ACCOUNT_MIN_LENGTH = 2;
  static readonly IS_ALERT_PRODUCT = 'product';
  static readonly IS_ALERT_NUMBER = 'number';
  static readonly ENABLE_SLIDE = 'enableSlide';
  static readonly ELLIPSIS = '...';
  static readonly SEARCH_LABEL = 'Buscar';
  static readonly REFERENCE_LENGTH = 100;
  static readonly WIDTH_SEARCH_ID = 'widthBuscador';
  static readonly DEFAULT_SEARCH_ID = 1;
  static readonly PAGE_SIZE_MOVEMENTS = 4;
  static readonly CAPTURE_LINE_LENGTH = 16;
  static readonly PAGE_SIZE_PROGRAMS = 5;
  static readonly PAGE_SIZE_EMPTY = 0;
  static readonly LOYALTY_MOVEMENT_PAYMENT = 'ABONO';
  static readonly MIN_LENGTH_MEMBERSHIP = 1;
  static readonly MAX_LENGTH_MEMBERSHIP = 20;
  static readonly LENGTH_MEMBERSHIP_AF = 10;
  static readonly LENGTH_MEMBERSHIP_BA = 5;
  static readonly LENGTH_MEMBERSHIP_EG = 12;
  static readonly LENGTH_MEMBERSHIP_IHG = 9;
  static readonly LENGTH_MEMBERSHIP_ALC = 16;
  static readonly LENGTH_MEMBERSHIP_COPA = 9;
  static readonly LENGTH_MEMBERSHIP_WR = 10;
  static readonly LENGTH_MEMBERSHIP_SM = 13;
  static readonly MIN_LENGTH_MEMBERSHIP_PP = 9;
  static readonly MIN_LENGTH_MEMBERSHIP_AC = 9;
  static readonly MAX_LENGTH_MEMBERSHIP_AC = 16;
  static readonly MIN_LENGTH_MEMBERSHIP_IP = 8;
  static readonly MAX_LENGTH_MEMBERSHIP_IP = 9;
  static readonly MIN_LENGTH_MEMBERSHIP_ALM = 2;
  static readonly MAX_LENGTH_MEMBERSHIP_ALM = 12;
  static readonly MIN_LENGTH_MEMBERSHIP_LAM = 1;
  static readonly MAX_LENGTH_MEMBERSHIP_LAM = 16;
  static readonly MIN_LENGTH_MEMBERSHIP_MB = 2;
  static readonly MAX_LENGTH_MEMBERSHIP_MB = 9;
  static readonly MIN_LENGTH_REDEEM_POINT = 1;
  static readonly MAX_LENGTH_REDEEM_POINT = 20;
  static readonly REDEEM_PROGRAM_AGREEMENT = 3;
  static readonly CLOSE_MODAL_ACTION = 'close';
  static readonly CANCEL_MODAL_ACTION = 'cancel';
  static readonly EMAIL_LABEL = 'Email';
  static readonly PROGRAMMED_OPERATIONS_TRANSFERENCE = 'Transferencia';
  static readonly LENGTH_CLAIM_NUMBER = 7;
  static readonly LENGTH_ISSUER_CODE = 5;
  static readonly LENGTH_LETTER_CODE = 5;
  static readonly LENGTH_LETTER_NUMBER = 7;
  static readonly AMOUNT_WIDTHS = {
    xs: { length: 0, width: 100 },
    sm: { length: 5, width: 200 },
    md: { length: 10, width: 300 },
    l: { length: 15, width: 400 },
    xl: { length: 20, width: 500 },
    xxl: { length: 25, width: 600 },
  };

  static readonly PAGINATION = 'tipoTarjeta';

  static readonly IS_VALID_DATA_ADDITIONAL_CARD = {
    IsValidLimit: 'isValidLimit',
    IsValidCvv: 'isValidCvv',
    IsValidNip: 'isValidNip',
    IsValidCancel: 'isValidCancel'
  };
  static readonly INSURANCE_ID = 'insurance';
  static readonly POLICY_PAYMENT_COMMISSION = 0;
  static readonly MAX_LIMIT_AMOUNT_CT = environment.limitCT;
  static readonly ID_DEDUCTIBLE_PAY = 'deducible';

  static readonly BENEFICIARY_ACCOUNT_NUMBER = 'numeroCuentaBeneficiario';
  static readonly DESCRIPTION_OPERATION = 'descripcionOperacion';
  static readonly TRANSFER_ACCOUNT_NUMBER = 'numeroCuenta';
  static readonly DESCRIPTION_CONCEPT = 'descripcionConcepto';
  static readonly CONCEPT = 'Concepto';
  static readonly STATUS = 'estatus';
  static readonly TRANSACTIONS_DATE = 'fechaAplicacion';
  static readonly LENGTH_BASIC_DATE = 10;
  static readonly SPLIT_SIPARE_LINE = 24;
  static readonly HOUR_LIMIT_INVESTMENT_COMPANIES = 15;
  static readonly DEFAULT_ACCOUNT= '00000000000000000000';

  static readonly TRANSACTIONS_CONCEPT = {
    InburedDeposit: 'DEPOSITO INBURED',
    AtmWithdrawal: 'RETIROS EN CAJEROS AUTOMATICO RED',
    TransferMovements: 'TRANSFERENCIA'
  };

  static readonly MOVEMENTS_FORMAT = {
    Currency: 0,
    Date: 1,
    Card: 2,
    TransactionsDate: 3
  };

  static readonly MOVEMENTS_CARD_CREDIT = [
    { key: 'Tarjeta', name: 'Tarjeta', format: Constants.MOVEMENTS_FORMAT.Card },
    { key: 'Folio', name: 'Número de operación' },
    { key: 'fecha', name: Constants.APPLICATION_DATE, format: Constants.MOVEMENTS_FORMAT.Date },
    { key: 'concepto', name: 'Descripción de operación' }
  ];


  static readonly PAYMENT = 'Payment';
  static readonly CHARGE = 'Charge';

  static readonly AMOUNT_WIDTH = {
    xs: { size: 6, width: 35 },
    sm: { size: 7, width: 45 },
    md: { size: 8, width: 55 },
    l: { size: 10, width: 60 },
    xl: { size: 11, width: 70 },
    xxl: { size: 13, width: 80 }
  };

  static readonly BUTTONS_MODIFY = {
    ID_BUTTON_MODIFY: 'buttonModificar',
    ID_BUTTON_MODIFY_MOBILE: 'buttonModificarMobile'
  };

  static readonly NOTIFICATION_ICONS = {
    success: Resources.CHECK_GREEN_FILL,
    confirmation: Resources.CHECK_BLUE_FILL,
    warning: Resources.WARNING_YELLOW_FILL,
    promo: Resources.GREEN_SHOP_TAG,
    error: Resources.CROSS_RED_FILL,
    info: Resources.INFO_BLUE_FILL
  };

  static readonly INVESTMENT_DATE = {
    startDate: 'startDate',
    endDate: 'endDate'
  };

  static readonly SUA_FILE_SIZE = 1024;
  static readonly PAYMENT_TYPE_STYLE = 'display: flex; justify-content: center; align-items: center;';
  static readonly SLIDER_DESKTOP_STYLE = `
    @media screen and (min-width: 1024px) {
      .swiper-pagination-bullets {
        display: none !important;
      }
    }`;
  static readonly INSURANCE_OPTION_LIFE = 'vida';
  static readonly INVESTMENT_SHADOW_STYLE = '.investment-shadow-margin{margin-bottom: 1rem!important;}';

  static readonly STATES_NOTIFICATIONS = {
    Read: 'read',
    Unread: 'unread',
    Delete: 'delete',
    Selected: 'selected'
  };

  static readonly ID_DROPDOWN_NOTIFICATIONS = 'myDropdownNotifications';
  static readonly URL_CREDIT_HELP = 'https://appassetsgfi.blob.core.windows.net/app-assets-test/helps/credito_nomina/terminos_condiciones_ACNom.html';
  static readonly CAR_POLICIES = 'autos';
  static readonly DANO_POLICIES = 'daños';
  static readonly INVESTMENT_ACCOUNT_CATEGORY = 'DepositIcf';

  static readonly INPUT_AMOUNT = {
    maxAmount: 'maxAmount',
    minAmount: 'minAmount',
    updateAmount: 'updateAmount',
    internationalBadge: 'internationalBadge'
  };
  static readonly BANNER_COMMON_STYLES = {
    borderRadius: '0.5rem',
    padding: '1rem',
  };

  static readonly PAYMENT_POLICIES_MSI = {
    IdShowMSI: 'select-msi',
    IdShowOtherBank: 'show-other-bank',
    IdSelectMonths: 'select-payment-months',
    IdIconRight: 'right',
    IdIconDown: 'down',
    CheckMonths: 'payment-months',
    LabelType: 'Tipo de tarjeta',
    NameType: 'card-type',
    LabelNumber: 'Número de la tarjeta',
    NameNumber: 'number-card',
    LabelHolder: 'Nombre del titular de la tarjeta',
    NameHolder: 'holder-card',
    LabelEmail: 'Email',
    NameEmail: 'email',
    NamePhone: 'phone',
    LabelAddress: 'Dirección de Facturación',
    NameAddress: 'address',
    LabelZipCode: 'Código Postal de la Facturación',
    NameZipeCode: 'zip-code',
    LabelExpiration: 'Expiración',
    NameExpiration: 'expiration',
    NameMonths: 'months',
    NameCheck: 'check',
    PlaceholderExpiration: 'MM / AAAA',
    LabelCVV: 'CVV',
    PlaceholderScripts: '----',
    PlaceholderScriptsThree: '---',
    PlaceholderScriptsFive: '-----',
    LabelMessageExpiration: 'La fecha es incorrecta (Formato MM/AAAA)',
    IdStyleInsurance: 5,
    IdAccountInsurances: '00124846089',
    IdProduct: 3181409,
    NameShowMonths: 'show-error-months',
    ShowError: 'show-error',
    ErrorPolicies: 'No cuentas con recibos disponibles',
    PoliciesRequired: 'Debes seleccionar un recibo',
    CodeError: 20019
  };

  static readonly FINANCIAL_LINK = 'https://www.finanzas.cdmx.gob.mx';
  static readonly RETAINED: number = 1316.00;
  static readonly AVAILABLE: number = 230;
}

export enum LoyaltyProgram {
  CreditCard = 3,
  Aeromexico = 5,
  AirCanada  = 7,
  AirFrance  = 8,
  British    = 10,
  Iberia     = 11,
  Etihad     = 12,
  IHG        = 13,
  Accor      = 14,
  COPA       = 15,
  Avianca    = 16,
  Latam      = 17,
  Marriott   = 19,
  Wyndham    = 20,
  StarMedica = 21
}

export enum Orientation {
  Landscape = 'landscape',
  Portrait = 'portrait'
}

export enum PagSize {
  Large = 6,
  Small = 3
}

export enum AgendaType {
  OthersBanks,
  OwnAccounts,
  ThirdParties,
  International,
  AccountAddress,
  NonAgendableService
}

export enum AssetsType {
  ServicesNa,
  Accounts
}

export enum FileFormats {
  Png = 'png',
  Svg = 'svg',
  Pdf = '.pdf',
  CreatePDF = 'data:application/pdf;base64,',
  TypePDF = 'application/pdf',
  JPEGImage = 'image/jpeg',
  PNGImage = 'image/png',
  DataImage = 'data:',
  Base64Image = ';base64',
  TextPlain = 'text/plain'
}


export enum GeolocationPermission {
  Denied = 'denied',
  Granted = 'granted',
  Prompt = 'prompt'
}

export enum UserImageType {
  Profile = 1,
  Thumbnail = 2,
  Security = 3
}

export enum AccountType {
  CT = 'CT',
  CTC = 'CTC',
  CTS = 'CTS',
  PAT = 'PAT',
  WEB = 'WEB',
  CODEFLEX91 = 'CODEFL91',
  CODE91 = 'CODE91',
  CODEFLEX = 'CODEFLEX',
  EFE = 'EFE',
  NOM = 'NOM',
  NWM = 'NWM',
  EFED = 'EFED',
  EFEN = 'EFEN',
  EFEINB = 'EFEINB',
  Walmart = 'WALMART',
  Sanborns = 'SANBORNS',
  INBUMEX1 = 'INBUMEX1',
  INBUMEX2 = 'INBUMEX2',
  INBUMEX3 = 'INBUMEX3',
  PromissoryNote = 'PFPAGARE',
  GroceryCoupon = 'VALEDESP',
  AccountCTPatrimonial = 'CT PATRIMONIAL',
  Dinbur = 'SOCINV',
  ClaroPay= 'CLARO',
  Eje= 'Deposit'
}

export enum PageEventType {
  Previous = 'popstate'
}

export enum Sorts {
  Ascending = 'asc',
  Descending = 'desc'
}

export enum DataType {
  Object = 'object',
  String = 'string',
  Number = 'number'
}

export enum IdElements {
  ModalPartial = 'modal-partial',
  TitlePartial = 'title-partial',
  TitleModal = 'title-modal',
  IconModal = 'icon-modal',
  ModalClass = 'modal-class',
  HrElement = 'hr-validate'
}

export enum AddresseTabs {
  Others = 0,
  OwnAccounts = 1,
  ThirdParty = 2,
  International = 3
}

export enum Position {
  Last = -1,
  Zero = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Seven = 7,
  Eight = 8,
  Ten = 10,
  Eleven = 11,
  Sixteen = 16,
  Nineteen = 19,
  Forty = 40
}

export enum Length {
  Empty = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Thirteen = 13
}

export enum Char {
  WhiteSpace = ' ',
  Slash = '/',
  Pipe = '|',
  CurrencySymbol = '$',
  PercentSymbol = '%',
  Comma = ',',
  Asterisk = '*',
  MiddleDash = '-',
  Underscore = '_',
  Cat = '#',
  Colon = ':',
  OpeningParenthesis = '(',
  ClosingParenthesis = ')',
  Semicolon = ';',
  Dot = '.',
  CurrencyEuro = '€',
  QuestionMark = '?',
  Ampersand = '&',
  PlusSign = '+',
  WordT = 't'
}

export enum Files {
  Base64 = 'base64',
  InbursaFile = 'fileInbursa'
}

export enum HtmlEvent {
  MouseMove = 'mousemove',
  MouseDown = 'mousedown',
  MouseUp = 'mouseup',
  MouseLeave = 'mouseleave',
  Input = 'input',
  Keydown = 'keydown'
}

export enum ElementsHTML {
  Canvas = 'canvas',
  Canvas2d = '2d',
  LabelA = 'a',
  Hidden = 'hidden',
  ContentMargin = 'mb-5 mx-3',
  ContentMarginBreakpoints = 'mb-4 mx-3',
  ContentMarginCodeflexTablet = 'my-4 mx-5 px-3',
  BasicContentDesktopMargin = 'mb-4 mx-4',
  BasicContentMobileMargin = 'mb-3 mx-3',
  PortabilityContentMargin = 'my-4 mx-4',
  Symbol = 'symbol',
  ContentDeductible = 'mx-3',
  LineBreak = '<br>',
  CodeSecurity = 'mt-5',
  ButtonError = 'div-m-btn-error',
  ButtonAlign = 'button-align',
  ResponsiveServiceStyle = '(min-width: 1600px)',
  HomeMargin = 'mb-4 mx-4',
  HomeResponsiveMargin = 'mb-3 mx-3',
  AmountXXXL = 'amountR-XXXL',
  AmountXXXM = 'amountR-XXXM',
  AmountXXXS = 'amountR-XXXS',
  ContentMarginPortability = 'mx-3 mt-3 mb-4',
  DefaultPadding = 'px-5',
  SwiperContainer = 'swiper-container',
  Span = 'span',
  ResponsiveServiceStatementAccountStyle = '(min-width: 1024px) and (max-width: 1350px)',
  AdditionalDesktopMargin = 'mx-5 mb-4'
}

export enum Language {
  Spanish = 'es',
  SpanishSpain = 'es-ES',
  SpanishLatinAmerica = 'es-419',
  English = 'en',
  EnglishUS = 'en-US',
  Portuguese = 'pt-BR',
  UnitedKingdom = 'en-GB'
}

export enum CoinType {
  MXP = 'MXP',
  USD = 'USD',
  EUR = 'EUR'
}

export enum Numbers {
  Zero = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Eight = 8,
  Nine = 9,
  Ten = 10,
  Eleven = 11,
  Twelve = 12,
  Thirteen = 13,
  Fourteen = 14,
  FourteenPointEight = 14.8,
  Fifteen = 15,
  Sixteen = 16,
  Seventeen = 17,
  Eighteen = 18,
  TwentyThree = 23,
  TwentyFive = 25,
  TwentySix = 26,
  Fifty = 50,
  FortyFive = 45,
  Ninety = 90,
  TwoHundred = 200,
  NinetySix = 96,
  OneHundred = 100,
  ThreeHundred = 300,
  SixHundredSixtySeven = 667,
}

export enum FormatDate {
  DayMonthYearComplete = 'dd MMMM Y, HH:mm:ss',
  DayMonthYear = 'dd-MMMM-YYYY',
  YearMonthDay = 'yyyy/MM/dd',
  DayMonthNumberYear = 'dd/MM/y, hh:mm:ss',
  DayMonthYearOneDigit = 'dd/MM/Y',
  BasicFormat = 'dd/MM/yyyy',
  BasicFormatWeek = 'dd/MM/YYYY',
  SimpleYear = 'Y-MM-ddd',
  SimpleDay = 'Y-MM-dd',
  Investment = 'dd/mm/yyyy',
  InvestmentToday = 'dd/MM/yyyy',
  ZeroHours = '00:00:00',
  FormatDateTransactionsCard = 'dd MMMM Y',
  FormatAccountStatement = 'yyyyMMdd',
  FormatNewDate = 'YYYY-MM-dd hh:mm:ss',
  FullMonthYearSpace = 'MMMM yyyy'
}

export enum PositionDate {
  Zero = 0,
  One = 1,
  Two = 2,
  Three = 3
}

export const Regex = {
  FormatUrl: /\{\s*([^}\s]+)\s*\}/g,
  MatchComma: /,/g,
  AllDigits: /^\d+$/,
  Letters: /^[A-Za-z]+$/,
  DirectiveTwo: '^\\s*((\\d+(\\.\\d{0,',
  DirectiveThree: '})?)|((\\d*(\\.\\d{1,',
  DirectiveFour: '}))))\\s*$',
  OnlyNumbers: /[0-9]|Arrow(Up|Down|Left|Right)|F[0-9]{1,2}/,
  OneMoreNumbers: /^[0-9]+$/,
  OnlyLettersAccents: /^[a-záéíóúA-ZÁÉÍÓÚñN\s]+$/,
  OnlyLettersSpace: /^[a-zA-Z\s]+$/,
  Currency: /^[0-9.,]+$/,
  OnlyNumbersSlash: /^([0-9]|\/)*$/,
  ValidateEmail: /^[a-zA-Z0-9._@-]+$/,
  ValidateDate: /^[0-9]{2}\/[0-9]{2}\/\d{4}$/,
  Date: /^(0[1-9]|1[0-2])$/,
  MatchYear: /^(\d){4}$/,
  NumbersSpace: /^[0-9\s]+$/,
  DifferentFromDigits: /[^\d]/g,
  MatchFourDigits: /\d(?=\d{4})/g,
  DifferentFromNumbersUtils: /[^0-9.-]+/g,
  MatchSixDigits: /^[0-9]{6}$/,
  Units: /[(px)|(rem)|(em)|(%)]+/g,
  OnlyLetters: '^[a-zA-Z ]+$',
  MatchFourCharacters: /([\w*]{4})/g,
  Space: /\s+/g,
  LeadingSpaces: /^\s+/,
  MatchCurrencySymbol: /\$/g,
  DifferentFromNumbersDecimals: /[^\d.]/g,
  SplitClabe: '$1 ',
  Slash: /\./,
  Time: /time/g,
  SocialSecurityNumber: /^(\d{11})$/,
  NumbersForAccounts: /^[0-9]{15,16}$/,
  OnlyNumbersDecimals: /^[0-9.]+$/,
  FourDigitsClabe: /(.{4})/g,
  Euro: /\€/g,
  NumbersCurrency: /^[0-9.,$]+$/,
  ValidateAlias: /^[a-zA-Z0-9\s,.-_"]{3,50}/,
  ValidateAccountMaxFifteen: /^[0-9]{15}$/,
  ValidateAccountMaxSixteen: /^[0-9]{16}$/,
  ValidateSignaturePanel: /^[0-9]{9}$/,
  ValidateAccountMaxTwelve: /^[0-9]{12}$/,
  DifferentFromAlphanumeric: /[^a-zA-Z0-9 ]+| {2,}/g,
  CurrencyService: /[a-zA-Z$&+,:;=?@#|'<>\/^*()%!\-{}¿\{\}\[\]_]+/g,
  AnyString: /.{1,4}/g,
  ValidateDateYearFirst: /(\d{4})-(\d{2})-(\d{2})$/g,
  ValidateDateFormat: /^\d{2}\/\d{2}\/\d{4}$/,
  ValidateRFC: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
  ValidateNoGenericRFC: /^(?!(XAXX|XEXX)\d{6}000|(XAX|XEX)\d{6}000).+$/,
  ValidateAmount: /^['$']?[0-9]+([,]\d{3})*([.]\d+)?$/,
  CURP: new RegExp(/^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])/.source +
    /[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/.source),
  DifferentFromNumbers: /[^0-9]+/g,
  DifferentFromDecimals: /[^0-9.]/g,
  HideAccountNumber: /\d(?=\d{3})/g,
  Reformat: /[$,]/g,
  Alphanumeric: /^[a-zA-Z0-9]+$/,
  EmailPattern: '^[A-Za-z0-9](([_.-]?[a-zA-Z0-9_.-]+)*)@(([A-Za-z0-9]+)(([.-]?[a-zA-Z0-9]+)*))\\.([A-Za-z]{2,})$',
  OnlyText: /[^a-zA-ZáéíóúüÁÉÍÓÚñÑÜ\s]+/g,
  Letter: /^([\d]*[.]*)+$/,
  ValidateLettersNumbers: /[A-Za-z-0-9, ]/i,
  RegexAllNumber: /^[0-9]+$/i,
  nonDigitsRegex: /\D/g,
  CaseInsensitive: 'i',
  OnlyNumbersSlashDate: /^[0-9/]+$/,
  MatchAccent: /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
  MatchFiveCharacters: /([\w*]{5})/,
  SplitMiddleDash: '$1-',
  MatchMiddleDash: /-/g,
  OnlyNumbersMiddleDash: /([^0-9\-])/g,
  Address: /[^a-zA-Z0-9\s,#.-]+/g,
  DateFormatExpiration: /^(0[1-9]|10|11|12){1}\/\d{4}$/,
  EvaluateAmount: /[^0-9]/g,
  NoAccents: /[^a-zA-Z0-9ñÑ ]*$/,
  DenyOnlyLettersNoAccents: /[^A-Za-zñÑ ]+/g,
  DenyOnlyLettersAccents: /[^a-záéíóúA-ZÁÉÍÓÚñÑ\s]+/g,
  DenyNoAccents: /[^a-zA-Z0-9ñÑ ]+/g,
  DenyValidateEmail: /[^a-zA-Z0-9._@-]+/g,
  Email: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/g,
  AliasInternational: /[^A-Za-z]+/g,
  DenySpecialCharacters: /[^a-zA-Z\s]+/g,
  DenyNoAccentsWithSpaces: /[^a-zA-Z0-9\s]+/g,
  OnlyZero: /^[0]+$/,
  ValidateDigitsPanelCVV: /^[0-9]{3}$/,
  ValidateDigitsTeen: /^[0-9]{10}$/,
  ValidateDigitsFive: /^[0-9]{5}$/,
  ValidateAccountMSI: /^[0-9]{15,16}$/,
  ValidateHolderMSI: /^[a-zA-ZáéíóúüÁÉÍÓÚñÑÜ\s]{10,200}$/,
  ValidateSpace: / {2,}/,
  CharRegex: /[$&+,:;=?@#|'<>./^*()%!-]+/g,
  AlphaNumericSpecialCharactersTwo: /[´\{\}¡¿\[\]_]+/g,
  Accents: /^[a-záéíóúA-ZÁÉÍÓÚñN]+$/,
  NumbersRegex: /^[0-9/]+$/,
  Iphone: /iphone/i,
  MarriageCertificate: /.(pdf|png|jpg)$/i,
  superIndexText: /\((\d+)\)/g,
  LineBreak: /\n/g,
  NotNumbersLetters: /[^a-zA-Z0-9]+/g,
  SetAlphabetsLetters: /[a-zA-Z]+/g
};

export enum Keys {
  Backspace = 'Backspace',
  Enter = 'Enter',
  V = 'v',
  Unidentified = 'Unidentified',
  Space = 'Space',
  Tab = 'Tab',
  CapsLock = 'CapsLock',
  Delete = 'Delete',
  Nine = 9,
  Eight = 8,
  Seven = 7,
  Six = 6,
  Five = 5,
  Four = 4,
  Three = 3,
  Two = 2,
  Zero = 0,
  One = 1
}

export enum Months {
  January = 'Enero',
  February = 'Febrero',
  March = 'Marzo',
  April = 'Abril',
  May = 'Mayo',
  June = 'Junio',
  July = 'Julio',
  August = 'Agosto',
  September = 'Septiembre',
  October = 'Octubre',
  November = 'Noviembre',
  December = 'Diciembre'
}

export enum ClassHTML {
  Login = 'd-flex fade show align-items-center',
  CardInfo = 'fw-bold',
  Document = 'bi-calendar3',
  LabelDays = 'ngb-dp-weekday small',
  NumberDays = 'ngb-dp-day',
  BodyClass = 'body-m',
  Calendar = 'ngb-datepicker',
  TransferPicker = 'transfer-datepicker',
  WebkitTextSecurity = '-webkit-text-security: disc;',
  AlertClass = 'warning-alert d-flex',
  DefaultAlertClass = 'warning-alert d-flex align-items-center',
  ErrorAlertClass = 'd-flex fade show',
  TransferCards = 'd-flex fade show align-items-center alertTransferCard',
  MeasuresLabelOriginal = 'measures-label-original',
  ClassAlert = 'form-code-security-alert',
  ButtonCodeSecurity = 'btn-form-code-security btn-generic',
  MeasuresOriginal = 'measures-original',
  LabelToken = 'label-token',
  InputToken = 'input-token-code number-token-code text-center form-control input-otp',
  NeedsValidation = 'needs-validation',
  WasValidated = 'was-validated',
  SidebarToggled = 'sb-sidenav-toggled',
  ModalBranches = 'modal-branches drop-shadow-m',
  CardBranch = 'card-branch',
  OtpModal = 'label-modal-otp',
  FlexOtp = 'flex-otp',
  ScrollMap = 'scroll-map',
  SendButton = 'btn-send button-m btn-c',
  Subtitle = 'm-subtitle',
  LabelAfore = 'label-afore',
  EndForce = 'col-12 end-force m-b-26',
  StyleAddStart = 'display: flex; justify-content: center; align-items: start;',
  StyleAddCenter = 'display: flex; justify-content: center; align-items: center;',
  ClassModalModifyAccount = 'modal-modify-account',
  ClassModalModifyConfirm = 'modal-modify-adr-confirm',
  Relative = 'relative',
  MaxContent = 'max-content',
  WebkitBox = '-webkit-box',
  BoxOrientVertical = 'vertical',
  DetailOne = 'button-detail-one',
  InvestmentAlert = 'd-flex fade show align-items-center detail-m-highlight',
  ButtonGeneric = 'btn-generic',
  BoxShadownInvestmentCompanies = 'box-shadown-investment-companies',
  ClassScrollProductsUl = 'scroll-ul-products',
  ClassScrollProductsUlTablet = 'scroll-ul-products-tablet',
  SwiperPagination = '.swiper-pagination',
  InvestmentShadowMargin = 'investment-shadow-margin',
  AnyWhere = 'anywhere',
  ModalConfirmAddProduct = 'modal-confirm-add-product',
  TitleCurrent = 'title-current',
  HeaderMTitleModalConfirm = 'header-m title-modal-confirm',
  TitleModal = 'title-modal',
  DvTitleConfirm = 'dv-title-confirm',
  IconModal = 'icon-modal',
  FormIconButtonCloseConfirm = 'close-icon-confirm',
  ModalClass = 'modal-class',
  ContainerConfirm = 'container-confirm',
  ModalCompleteAddProduct = 'modal-complete-add-product',
  FormIconButtonCloseComplete = 'close-icon-complete',
  HeaderMTitleModalComplete = 'header-m title-modal-complete',
  ModalRequirementsMarriage = 'modalrequirementsmarriage',
  ModalSuccesRetirement = 'modal-succes-retirement',
  ModalErrorRetirement = 'modal-error-retirement',
  ModalViewUnemployment = 'modal-view-unemployment',
  HeaderTitleModalConsult = 'header-m title-modal-consult',
  JustifyTitleConsult = 'justify-title-consult',
  CloseIconConsult = 'close-icon m-b-8 close-consult',
  ContainerAddConsult = 'container-add add-consult',
  OtpCardReplaCheckBook = 'otpCardReplaCheckBook',
  AforeSuccessfulProcess = 'afore-successful-process',
  ModalVoluntaryContributions = 'modal-voluntary-contributions',
  ModalRetirementAdvancedAge = 'modal-retirement-advanced-age'
}

export enum CardsType {
  Debit = 'Debit',
  Credit = 'Credit'
}

export enum PaymentOptionsId {
  Debit = 0,
  Credit = 1,
  Credits = 2,
  Afore = 3,
  Patrimonial = 4,
  Insurance = 5,
  Investment=6,
  InvestmentCompanies=7,
  Responsive = 8,
  AccountManagment = 9,
  Web=10
}

export enum EnableSlideId {
  ProtectionOptions = 'protectionOptions',
  CardProgrammedOperations = 'tbl-1',
  CardOperations = 'tbl-2'
}

export enum FormatDatePosition {
  Day = 0,
  Month = 1,
  Year = 2
}

export const SortsByMapping = {
  1: 'account',
  2: 'addressee',
  3: 'type',
  4: 'beneficiary',
  5: 'amount',
  6: 'date',
  7: 'service',
  8: 'numberService',
  9: 'headline'
};

export enum FormValidations {
  Required = 'required',
  Max = 'max',
  Min = 'min',
  Pattern = 'pattern',
  MinLength = 'minlength'
}

export enum InputTypes {
  Text = 'text',
  Phone = 'tel',
  Password = 'password',
  Select = 'select',
  Email = 'email',
  CardNumber = 'card-number',
  Bank = 'bank',
  ClabeNumber = 'key-number',
  LabelHolder = 'label-title',
  Holder = 'div-holder',
  Limit = 'div-limit',
  Contact = 'div-limit',
  InternationalBank = 'div-bank-internacional',
  Block = 'block',
  None = 'none',
  ContainerBranch = 'container-modal-branches',
  ModalTop = 'src-modal-top',
  ContactId = 'div-contact',
  listSearch = 'listSearch',
  searchContainer = 'searchContainer',
  searchInput = 'searchInput',
  Number = 'number'
}

export enum InputModes {
  Numeric = 'numeric'
}

export enum KeyPressEvents {
  IsNumericInput = 'isNumericInput',
  CurrencyFormat = 'currencyFormat',
  RemoveCurrencyFormat = 'removeCurrencyFormat',
  InputToken = 'inputToken',
  KeyToken = 'keyToken'
}

export enum EventType {
  KeyPress = 'keypress',
  Blur = 'blur',
  Click = 'click'
}

export enum Source {
  Mf2 = 'MF2',
  Infinitus = 'INFI',
  All = 'ALL'
}
export enum InvestmentType {
  LIQUITOT = 'LIQUITOT',
  REINVTOT = 'REINVTOT',
  REINVSAL = 'REINVSAL'
}

export enum Browser {
  Firefox = 'Firefox'
}

export enum ResetCat {
  Twelve = '12',
  TwentyFour = '24',
  ThirtySix = '36',
  FortyEight = '48'
}

export enum AccessHistoryTitleId {
  Status = 1,
  Authentication = 2,
  AccessDate = 3,
  Ip = 4,
  Device = 5,
  Browser = 6,
  ErrorDetails = 7
}

export enum ClarificationsTitleId {
  From = 1,
  Reference = 2,
  Type = 3,
  Status = 4,
  RegisterDate = 5,
  RegisterForm = 6,
  Amount = 7
}

export enum AccessStatus {
  Successful = 1,
  NotSuccessful = 2
}

export enum OperationStatus {
  Unlocked = 0,
  Blocked = 1
}

export enum ClarificationStatus {
  Fair = 'PROCEDENTE',
  Unfair = 'IMPROCEDENTE',
  PendingListClarification = 'PENDIENTE',
  PendingDetailClarification = 'En proceso'
}
export enum RegisterTypeProgram {
  Add = 0,
  Update = 1,
  Delete = 2
}

export enum TypeRedemption {
  None = 0,
  Automatic = 1
}
export enum TypeMovementRedemption {
  Debit = 'C',
  Credit = 'A',
  Reversal = 'R'
}

export enum SearchFilters {
  Filter = 'Filtrar'
}

export enum Tab {
  Account = 'Account',
  Investment = 'Investment',
  CreditsPayment = 'Credit',
  CreditCard = 'CreditCard',
  Insurance = 'Insurance',
  Afore = 'Afore',
  InvestmentCompanies = 'InvestmentCompanies'
}

export enum Format {
  Currency = Constants.MOVEMENTS_FORMAT.Currency,
  Date = Constants.MOVEMENTS_FORMAT.Date,
  Card = Constants.MOVEMENTS_FORMAT.Card,
  TransactionsDate = Constants.MOVEMENTS_FORMAT.TransactionsDate
}

export enum ScrollOptions {
  Smooth = 'smooth',
  TableScroll = 'table-scroll'
}

export enum HeadersTable {
  ServiceNumber = 'No. de servicio',
  Service = 'Servicio',
  RootAccount = 'Cuenta origen',
  amount = 'Monto',
  Holder = 'Titular'
}

export enum AforeBalance {
  saldoFovissste = 'Total FOVISSSTE',
  saldoImss = 'Total IMSS',
  saldoInfonavit = 'Total INFONAVIT',
  saldoIssste = 'Total ISSSTE',
  saldoTotal = 'saldoTotal',
  saldoVoluntario = 'Total ahorro voluntario'
}
export enum IdInputsMedicalExpenses {
  firstName = 'firstName',
  secondName = 'secondName',
  lastName = 'lastName',
  secondLastName = 'secondLastName'
}
export enum FacepassStatusCode {
  Waiting = 138,
  Validating = 139
}

export enum ScheduledOperationsEventType {
  Transfers = 'Traspasos',
  Transference = 'Transferencias',
  ServicesPay = 'PagoServicios',
  All = 'Todos'
}

export enum ScheduledOperationsSearchType {
  Futures = 'F',
  Scheduled = 'P'
}

export enum CardType {
  Debit = 'Débito',
  Credit = 'Crédito',
}

export enum DebitCardType {
  GroceryVoucherCard = 'TARJETA VALE DESPENSA',
  WalmartGroceryVoucherCard = 'TARJETA VALE DESPENSA WALMART',
  WebCard = 'TARJETA WEB'
}

export enum TransactionCode {
  Spei = 'AB005',
  OwnAccounts = 'AB097'
}

export enum RetirementType {
  unemployment = 'unemployment',
  marriage = 'marriage',
  total = 'total'
}

export enum Responsive {
  maxTablet = 767
}
