<section class="px-3 pt-4 pb-3">
  <header class="header-s text-secondary-1200 text-center">Has excedido el límite establecido de traspasos</header>
  <main class="detail-m secondary-color pt-1 text-center">
    La operación se aplicará después de 48 horas.
    <br /><br />
    Puedes modificar tu límite de operación para que el depósito aplique de manera inmediata.
  </main>
  <footer class="mt-4">
    <button class="colored-btn modals-btn button-m w-100" (click)="setFutureTransaction()">Sí, continuar</button>
    <button class="outline-btn modals-btn button-m w-100" (click)="modifyLimit()">Modificar límite</button>
  </footer>
</section>
