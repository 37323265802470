import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICreditsData } from 'src/app/interface/ICreditsData';
import { ICreditsEvent } from 'src/app/interface/ICreditsEvent';
import { Strings } from 'src/core/constants/Strings';
import { CreditDetailsResponse } from 'src/app/interface/dto/CreditDetailsResponse';
import { Char, Constants, FormatDate, Position } from 'src/core/constants/Constants';
import { Numbers } from 'src/core/constants/Numbers';
import { CreditStrings } from 'src/core/constants/CreditStrings';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class CreditsStatusService {
  private readonly _status: BehaviorSubject<ICreditsEvent> = new BehaviorSubject<ICreditsEvent>({
    value: false
  });

  private readonly emptyModel: ICreditsData = {
    Producto: Strings.EMPTY,
    NumeroCredito: Strings.EMPTY
  };

  private readonly _credit: BehaviorSubject<ICreditsData> = new BehaviorSubject<ICreditsData>(
    this.emptyModel
  );

  constructor() { }

  get status$() {
    return this._status.asObservable();
  }

  get status(): ICreditsEvent {
    return this._status.getValue();
  }

  set status(data: ICreditsEvent) {
    this._status.next(data);
  }

  get credit$() {
    return this._credit.asObservable();
  }

  get credit(): ICreditsData {
    return this._credit.getValue();
  }

  set credit(data: ICreditsData) {
    this._credit.next(data);
  }

  empty() {
    this.credit = this.emptyModel;
  }

  getItems(response: CreditDetailsResponse, isModal: boolean, screenWidth: number): Array<{ label: string, value: string, textBroken? : boolean}>{
    const items = [
      { label: Strings.CREDIT_PAYMENT.NextPayment, value: this.changeFormatCurrency(response.ProximoPago?.toString(), screenWidth) },
      { label: Strings.LIMIT_DATE_LABEL, value: this.changeFormatDate(response.FechaLimitePago), textBroken: false },
      { label: Strings.CREDIT_PAYMENT.BalanceDueLabel, value: this.changeFormatCurrency(response.SaldoAdeudado?.toString(), screenWidth) },
      { label: Strings.CREDIT_PAYMENT.StartingAmountLabel, value: this.changeFormatCurrency(response.MontoInicial?.toString(), screenWidth) },
      { label: Strings.CREDIT_PAYMENT.InterestRateLabel, value: this.changeFormatPercent(response.Tasa.toString()) },
      { label: Strings.CREDIT_PAYMENT.CurrentPayableBalanceLabel, value: this.changeFormatCurrency(response.SaldoLiquidarActual?.toString(), screenWidth) },
      { label: Strings.CREDIT_PAYMENT.PastDueInterest, value: this.changeFormatCurrency(response.InteresesVencidos?.toString(), screenWidth) },
      { label: Strings.CREDIT_PAYMENT.PastDueInterestVat, value: this.changeFormatCurrency(response.IVAInteresesVencidos?.toString(), screenWidth) },
      { label: Strings.CREDIT_PAYMENT.DefaultInterest, value: this.changeFormatCurrency(response.InteresesMoratorios?.toString(), screenWidth) },
      { label: Strings.CREDIT_PAYMENT.LateInterestVat, value: this.changeFormatCurrency(response.IvaInteresMora?.toString(), screenWidth) }
    ];
    if(isModal){
      items[items.length - Numbers.Nine] = { label: CreditStrings.CREDITS_DETAILS_MOVEMENTS.LimitDateLabelMobile,
        value: this.changeFormatDate(response.FechaLimitePago), textBroken:true  };
      items[items.length - Numbers.Five] = { label: CreditStrings.CREDITS_DETAILS_MOVEMENTS.CurrentPayableBalanceLabelMobile,
        value: this.changeFormatCurrency(response.SaldoLiquidarActual?.toString(), screenWidth) };
      items[items.length - Numbers.Four] = { label: CreditStrings.CREDITS_DETAILS_MOVEMENTS.PastDueInterestMobile,
        value: this.changeFormatCurrency(response.InteresesVencidos?.toString(), screenWidth) };
      items[items.length - Numbers.Three] = { label: CreditStrings.CREDITS_DETAILS_MOVEMENTS.PastDueInterestVatMobile,
        value: this.changeFormatCurrency(response.IVAInteresesVencidos?.toString(), screenWidth) };
      items[items.length - Numbers.One] = { label: CreditStrings.CREDITS_DETAILS_MOVEMENTS.LateInterestVatMobile,
        value: this.changeFormatCurrency(response.IvaInteresMora?.toString(), screenWidth) };
    }
    return items;
  }

  changeFormatCurrency(amount: string,  screenWidth: number) : string{
    const formatAmountLength: number = Numbers.Ten;
    const maxScreenWidth: number = Constants.MAX_SCREEN_WIDTH;
    if (amount){
      return amount.length > formatAmountLength && screenWidth <=  maxScreenWidth
        ? amount.slice(Position.Zero, formatAmountLength) + Constants.ELLIPSIS: amount;
    }
    return amount;
  }

  changeFormatDate(date: string) {
    return formatDate(date, FormatDate.BasicFormat, Constants.LOCALE_ES_MX);
  }

  changeFormatPercent(amount: string) {
    return `${parseFloat(amount).toFixed(Numbers.One)}${Char.PercentSymbol}`;
  }

}
