export class ClarificationsConstants {
    public static readonly IDENTIFIER_CREDIT_CARD = 'CON';
    public static readonly MOVEMENT_APPLIED = 'Aplicado';
    public static readonly POSITION_POS = 1;

    static readonly TYPE_MOVEMENT = [
       'ATM',
       'POS',
       'TEF',
       'SPEI'
    ];

    static readonly TYPE_MOVEMENT_CREDIT_CARD = {
        Applied: 'C',
        ToApplied: 'CR'
    };

    static readonly COMPANY = {
        Bank: 7000,
        Credit: 5003
    };
}

export enum FormatPhonePosition {
    Six = 6
}
