<div class="d-flex flex-column text-center modal-content delete-agenda" *ngIf="!closeModal">
  <div class="headder w-100 mb-4">
    <img [src]="questionMarkCrIcon | cdnImage" width="auto" height="auto" alt="question">
  </div>
  <div class="title header-s mb-2">
    ¿Estás seguro de que quieres eliminar esta cuenta?
  </div>
  <div class="body detail-m mb-3">
    <span [ngbTooltip]="nameAccount">{{nameAccountEllipsis}}</span>
    {{labelMain}}
  </div>
  <div class="footer d-flex row justify-content-center mb-1 btn-gap">
    <button class="btn-done col-6 button-m " (click)="agendaCancel()">
      Sí</button>
    <button class="btn-cancel col-6 button-m" (click)="close()">
      No</button>
  </div>
</div>

<div class="d-flex flex-column text-center modal-content delete-agenda" *ngIf="showModalComplete">
  <div class="headder mb-4">
    <img [src]="checkFillIcon | cdnImage" width="auto" height="auto" alt="Success">
  </div>
  <div class="title header-s mb-2">
    Eliminación exitosa
  </div>
  <div class="body detail-m mb-4">
    {{labelMainConfirm}}
  </div>
  <div class="footer d-flex-row mb-1">
    <button class="btn-accept button-m" (click)="dismissModals()">
      Aceptar</button>
  </div>
</div>

<ng-template #cancelAccount>
  <app-modal-form-code-security [tokenContainerClass]="tokenContainerClass" [headerToken]="headerToken"
    (showConfirmData)="getOtpDelete($event)" (exitModal)="showModal()" [dataForm]="[]"
    classInput="input-token-code-check-book number-token-code text-center form-control input-otp"
    btnClass="btn-form-code-security btn-form-code-security-modal btn-generic">
  </app-modal-form-code-security>
</ng-template>
