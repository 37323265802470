export class InsuranceConstants {
  static readonly COVER = 'Carátula';
  static readonly GENERAL_CONDITIONS = 'Condiciones generales';
  static readonly PAYMENT_POLICIES = 'Pago de Pólizas MSI';
  static readonly INSURANCES_HEADER = 'Información de tu seguro';
  static readonly POLICY_NUMBER = 'Núm. de Póliza';
  static readonly INSURED_PRODUCT = 'Objeto asegurado';
  static readonly ISSUER = 'Emisor';
  static readonly CIS = 'CIS';
  static readonly EXPIRATION_DATE = 'Vence';
  static readonly INFORMATION_POLICIES = 'Información';
  static readonly REQUEST_ASSISTANCE = 'Solicitar asistencia';
  static readonly CLAIM_TRACKING = 'Seguimiento de siniestro';
}
