import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { SidebarComponent } from 'src/app/component/sidebar/sidebar.component';
import { NgbAlertModule, NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContainerComponent } from 'src/app/component/container/container.component';
import { FooterCardLoginComponentComponent } from 'src/app/component/footer-card-login-component/footer-card-login-component.component';
import { FormCardLoginComponent } from 'src/app/component/form-card-login/form-card-login.component';
import { UserPasswordComponent } from 'src/app/component/user-password/user-password.component';
import { EnterPasswordComponent } from 'src/app/component/enter-password/enter-password.component';
import { HttpClientModule } from '@angular/common/http';
import 'src/core/utils/StringPrototypes';
import 'src/core/utils/ArrayPrototypes';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { CurrencyPipe, PercentPipe, registerLocaleData } from '@angular/common';
import { EnterSecurityCodeComponent } from 'src/app/component/enter-security-code/enter-security-code.component';
import { ConfigService } from 'src/app/services/config.service';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import localeMX from '@angular/common/locales/es-MX';
registerLocaleData(localeMX);
import { LayoutModule } from '@angular/cdk/layout';
import { ModalAdditionalProfileComponent } from 'src/app/component/modal-additional-profile/modal-additional-profile.component';
import { ModalSendEmailComponent } from 'src/app/component/modal-send-email/modal-send-email.component';
import { FederalTaxesPaymentComponent } from 'src/app/component/federal-taxes-payment/federal-taxes-payment.component';
import { ConfirmInsurancePoliciesComponent } from 'src/app/no-schedulable-services/components/confirm-insurance-policies/confirm-insurance-policies.component';
import { ModalModifyAccountComponent } from 'src/app/component/modal-modify-account/modal-modify-account.component';
import { FormModifyAccountComponent } from 'src/app/component/form-modify-account/form-modify-account.component';
import { ConfirmModifyAccountComponent } from 'src/app/component/confirm-modify-account/confirm-modify-account.component';
import { CompleteModifyAccountComponent } from 'src/app/component/complete-modify-account/complete-modify-account.component';
import { MaintenanceComponent } from 'src/app/component/maintenance/maintenance.component';
import { InvestmentComponent } from 'src/app/component/invesment/investment.component';
import { InvestmentInformationComponent } from 'src/app/component/invesment-information/investment-information.component';
import { TypeInvestmentComponent } from 'src/app/component/type-investment/type-investment.component';
import { InvestmentDateComponent } from 'src/app/component/invesment-date/investment-date.component';
import { ConfirmInvesmentComponent } from 'src/app/component/confirm-invesment/confirm-invesment.component';
import { CompleteInvestmentComponent } from 'src/app/component/complete-investment/complete-investment.component';
import { ModalHelpSeriesTokenComponent } from 'src/app/component/modal-help-series-token/modal-help-series-token.component';
import { BranchMapModalComponent } from 'src/app/component/branch-map-modal/branch-map-modal.component';
import { RequestedReplacementModalComponent } from 'src/app/component/requested-replacement-modal/requested-replacement-modal.component';
import { ModalChecksRequestComponent } from 'src/app/component/modal-checks-request/modal-checks-request.component';
import { ModalChecksActivationComponent } from 'src/app/component/modal-checks-activation/modal-checks-activation.component';
import { ModalHelpSignaturePanelComponent } from 'src/app/component/modal-help-signature-panel/modal-help-signature-panel.component';
import { EnterSecurityCodeSerialComponent } from 'src/app/component/enter-security-code-serial/enter-security-code-serial.component';
import { ModalPayrollCreditComponent } from 'src/app/component/modal-payroll-credit/modal-payroll-credit.component';
import { ModalConfirmPayrollCreditComponent } from 'src/app/component/modal-confirm-payroll-credit/modal-confirm-payroll-credit.component';
import { ModalCompletePayrollCreditComponent } from 'src/app/component/modal-complete-payroll-credit/modal-complete-payroll-credit.component';
import { ModalPaymentReceiptComponent } from 'src/app/component/modal-payment-receipt/modal-payment-receipt.component';
import { CompleteSuaPaymentComponent } from 'src/app/component/complete-sua-payment/complete-sua-payment.component';
import { CodeflexTieComponent } from 'src/app/component/codeflex-tie/codeflex-tie.component';
import { ConfirmDataCodeflexComponent } from 'src/app/component/confirm-data-codeflex/confirm-data-codeflex.component';
import { OperationSuccessComponent } from 'src/app/component/operation-success/operation-success.component';
import { ModalModifyInvestmentComponent } from 'src/app/component/modal-modify-investment/modal-modify-investment.component';
import { ModalBranchesComponent } from 'src/app/component/modal-branches/modal-branches.component';
import { ModalChecksRequestConfirmDataComponent } from 'src/app/component/modal-checks-request-confirmdata/modal-checks-request-confirmdata.component';
import { ModalChecksRequestDataComponent } from 'src/app/component/modal-checks-request-data/modal-checks-request-data.component';
import { ModalChecksConfirmationComponent } from 'src/app/component/modal-checks-confirmation/modal-checks-confirmation.component';
import { ModalDirectDebitConfirmComponent } from 'src/app/component/modal-direct-debit-confirm/modal-direct-debit-confirm.component';
import { ModalSuccessfulModificationComponent } from 'src/app/component/modal-successful-modification/modal-successful-modification.component';
import { ModalDeleteAddresseeComponent } from 'src/app/services-module/components/modal-delete-addressee/modal-delete-addressee.component';
import { ModalLoadFilesComponent } from 'src/app/component/modal-load-files/modal-load-files.component';
import { LoadFilesComponent } from 'src/app/component/load-files/load-files.component';
import { ModalSentFilesComponent } from 'src/app/component/modal-sent-files/modal-sent-files.component';

import { register } from 'swiper/element/bundle';
register();
import { ServicesContractComponent } from 'src/app/component/services-contract/services-contract.component';
import { ModalFreeBureauAlertComponent } from 'src/app/component/modal-free-bureau-alert/modal-free-bureau-alert.component';
import { ModalBureauAlertComponent } from 'src/app/component/modal-bureau-alert/modal-bureau-alert.component';
import { ModalSuccessfulRequestComponent } from 'src/app/component/modal-successful-request/modal-successful-request.component';
import { ModalTechnicalRequirements } from 'src/app/component/modal-technical-requirements/modal-technical-requirements.component';
import { ModalModifyDirectDebitComponent } from 'src/app/component/modal-modify-direct-debit/modal-modify-direct-debit.component';
import { ModalDirectDebitCancelationComponent } from 'src/app/component/modal-direct-debit-cancelation/modal-direct-debit-cancelation.component';
import { AdditionalContributionsComponent } from 'src/app/component/additional-contributions/additional-contributions.component';
import { AdditionalContributionsConfirmComponent } from 'src/app/component/additional-contributions-confirm/additional-contributions-confirm.component';
import { AdditionalContributionsCompleteComponent } from 'src/app/component/additional-contributions-complete/additional-contributions-complete.component';
import { ModalCancelationCodeflexWithdrawalsComponent } from 'src/app/component/modal-cancelation-codeflex-withdrawals/modal-cancelation-codeflex-withdrawals.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModalHoursOperationComponent } from 'src/app/component/modal-hours-operation/modal-hours-operation.component';
import { ModalDownloadMovementsComponent } from 'src/app/component/modal-download-movements/modal-download-movements.component';
import { ModalRefundDoesNotApplyComponent } from 'src/app/component/modal-refund-does-not-apply/modal-refund-does-not-apply.component';
import { ModalVoluntaryWithdrawalInfoComponent } from 'src/app/component/modal-voluntary-withdrawal-info/modal-voluntary-withdrawal-info.component';
import { ModalConfirmWithdrawalComponent } from 'src/app/component/modal-confirm-withdrawal/modal-confirm-withdrawal.component';
import { ModalWebCardComponent } from 'src/app/component/modal-web-card/modal-web-card.component';
import { WebCardDataComponent } from 'src/app/component/web-card-data/web-card-data.component';
import { WebCardDataConfirmationComponent } from 'src/app/component/web-card-data-confirmation/web-card-data-confirmation.component';
import { ModalQueryDirectDebitComponent } from 'src/app/modal-query-direct-debit/modal-query-direct-debit.component';
import { ModalAdditionalCardDelete } from 'src/app/control-card/components/modal-additional-card-delete/modal-additional-card-delete.component';
import { ModalPwaPayrollCreditComponent } from './component/modal-pwa-payroll-credit/modal-pwa-payroll-credit.component';

const appConfig = (config: ConfigService) => {
  return () => { return config.loadConfig(); };
};

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    ContainerComponent,
    FooterCardLoginComponentComponent,
    FormCardLoginComponent,
    UserPasswordComponent,
    EnterPasswordComponent,
    EnterSecurityCodeComponent,
    ModalAdditionalProfileComponent,
    ModalSendEmailComponent,
    FederalTaxesPaymentComponent,
    ConfirmInsurancePoliciesComponent,
    ModalModifyAccountComponent,
    FormModifyAccountComponent,
    ConfirmModifyAccountComponent,
    CompleteModifyAccountComponent,
    MaintenanceComponent,
    InvestmentComponent,
    InvestmentInformationComponent,
    TypeInvestmentComponent,
    InvestmentDateComponent,
    ConfirmInvesmentComponent,
    CompleteInvestmentComponent,
    ModalHelpSeriesTokenComponent,
    BranchMapModalComponent,
    RequestedReplacementModalComponent,
    ModalChecksRequestComponent,
    ModalChecksActivationComponent,
    ModalDeleteAddresseeComponent,
    ModalHelpSignaturePanelComponent,
    EnterSecurityCodeSerialComponent,
    ModalPayrollCreditComponent,
    ModalConfirmPayrollCreditComponent,
    ModalCompletePayrollCreditComponent,
    ModalLoadFilesComponent,
    LoadFilesComponent,
    ModalSentFilesComponent,
    ModalPaymentReceiptComponent,
    CompleteSuaPaymentComponent,
    CodeflexTieComponent,
    ConfirmDataCodeflexComponent,
    OperationSuccessComponent,
    ModalModifyInvestmentComponent,
    ModalModifyInvestmentComponent,
    ModalBranchesComponent,
    ModalChecksRequestConfirmDataComponent,
    ModalChecksRequestDataComponent,
    ModalChecksConfirmationComponent,
    ModalDirectDebitConfirmComponent,
    ModalModifyDirectDebitComponent,
    ModalSuccessfulModificationComponent,
    ModalDirectDebitCancelationComponent,
    ServicesContractComponent,
    ModalTechnicalRequirements,
    AdditionalContributionsComponent,
    AdditionalContributionsConfirmComponent,
    AdditionalContributionsCompleteComponent,
    ModalCancelationCodeflexWithdrawalsComponent,
    ModalHoursOperationComponent,
    ModalCancelationCodeflexWithdrawalsComponent,
    ModalDownloadMovementsComponent,
    ModalRefundDoesNotApplyComponent,
    ModalVoluntaryWithdrawalInfoComponent,
    ModalConfirmWithdrawalComponent,
    ModalWebCardComponent,
    WebCardDataComponent,
    WebCardDataConfirmationComponent,
    ModalFreeBureauAlertComponent,
    ModalBureauAlertComponent,
    ModalSuccessfulRequestComponent,
    ModalQueryDirectDebitComponent,
    ModalAdditionalCardDelete,
    ModalPwaPayrollCreditComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    NoopAnimationsModule,
    LayoutModule,
    NgbDatepickerModule,
    NgbAlertModule,
    NgbModule,
    SharedModule
  ],
  providers: [
    CurrencyPipe,
    ConfigService,
    PercentPipe,
    [{
      provide: IBaseService,
      useClass: BaseServiceService
    }],
    {
      provide: APP_INITIALIZER,
      useFactory: appConfig,
      multi: true,
      deps: [ConfigService]
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
