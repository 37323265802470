import { Injectable } from '@angular/core';
import { AssetsType, Constants, Source } from 'src/core/constants/Constants';
import { GetDataCardRequest } from 'src/app/interface/dto/GetDataCardRequest';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { StorageService } from 'src/app/services/storage.service';
import { GetDataCardResponse, TCardModel } from 'src/app/interface/dto/GetDataCardResponse';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { Resources } from 'src/core/constants/Resources';
import { GetLocksAndLimits } from 'src/app/interface/dto/GetLocksAndLimitsRequest';
import { GetLocksAndLimitsResponse } from 'src/app/interface/dto/GetLocksAndLimitsResponse';
import { UpdateLockLimitsCardResponse } from 'src/app/interface/dto/UpdateLockLimitsCardResponse';
import { UpdateLockLimitsCardRequest } from 'src/app/interface/dto/UpdateLockLimitsCardRequest';
import { PinModificationResponse } from 'src/app/interface/dto/IPinModificationResponse';
import { PinModificationRequest } from 'src/app/interface/dto/IPinModificationRequest';
import { RegisterCvvRequest } from 'src/app/interface/dto/IRegisterCvvRequest';
import { RegisterCvvResponse } from 'src/app/interface/dto/IRegisterCvvResponse';
import { DynamicCvvResponse } from 'src/app/interface/dto/IDynamicCvvResponse';
import { DynamicCvvRequest } from 'src/app/interface/dto/IDynamicCvvRequest';
import { CdnImagePipe } from 'src/app/pipes/cdn-image.pipe';
import { LockCardValueModel } from 'src/app/interface/LockCardValueModel';
import { SentinelService } from 'src/app/services/sentinel.service';
import { ModifyCreditLimitRequest } from 'src/app/interface/dto/ModifyCreditLimitRequest';
import { ModifyCreditLimitResponse } from 'src/app/interface/dto/ModifyCreditLimitResponse';
import { OperationType } from 'src/core/constants/ControlCardConstants';

@Injectable({
  providedIn: 'root'
})

export class CardsAdminService {
  constructor(
    private readonly baseService: IBaseService,
    private readonly storageService: StorageService,
    private readonly sentinelService: SentinelService
  ) { }

  async getDataCards(source: string, cardNumber: string): Promise<GetDataCardResponse> {
    const request: GetDataCardRequest = new GetDataCardRequest({
      CardNumber: cardNumber,
      IdSession: this.storageService.getSession(),
      IsAditional: false,
      Source: source
    });
    return this.baseService.genericPost<GetDataCardResponse>(request, { isSOA: true });
  }
  getPictureCard(card: TCardModel) {
    let pictureCard: string;
    if (!card.CardFrontImageUrl || card.CardFrontImageUrl === Strings.NULL_TEXT) {
      pictureCard =
        this.baseService.getUrlAssets(AssetsType.Accounts) +
        Utils.getUrlCardImage(card.CardFrontImageUrl);
    } else {
      pictureCard = new CdnImagePipe().transform(Resources.DEFAULT_IMAGE_CARD);
    }
    return pictureCard;
  }

  async getLocksLimits(source: string, cardNumber: string): Promise<GetLocksAndLimitsResponse> {
    const request: GetLocksAndLimits = new GetLocksAndLimits({
      IdSession: this.storageService.getSession(),
      CardNumber: cardNumber,
      IsAditional: false,
      Source: source
    });
    return this.baseService.genericPost<GetLocksAndLimitsResponse>(request, { isSOA: true });
  }

  async updateLockLimitsCard(account: string, cardNumber: string,
    changes: LockCardValueModel[],
    source: string): Promise<UpdateLockLimitsCardResponse> {
    const geolocation = this.storageService.getGeolocation();
    const request: UpdateLockLimitsCardRequest = new UpdateLockLimitsCardRequest({
      Account: account,
      CardNumber: cardNumber,
      Changes: changes.filter((name) => {
        return name != null && (name.Name !== OperationType.UPDATE_LIMIT_MONTHLY || source !== Source.Infinitus);
      }),
      medioAcceso: Constants.ACCESS_METHOD_PORTAL,
      UserAgent: navigator.userAgent,
      Ip: this.sentinelService.sentinel.ip,
      IsAditional: false,
      Source: source,
      IdSession: this.storageService.getSession(),
      IdsPersona: [this.storageService.getUser().personId.toString()]
    });
    return this.baseService.genericPost<UpdateLockLimitsCardResponse>(request,
      {
        isSOA: true, urlOptions: [geolocation.latitude, geolocation.longitude, this.storageService.getSession()]
      }
    );
  }

  async updateCreditLimit(limit: string, cardNumber: string){
    const request: ModifyCreditLimitRequest = new ModifyCreditLimitRequest({
      IdSession: this.storageService.getSession(),
      LimiteMensual: Number(limit),
      NumeroCuenta: cardNumber
    });
    return this.baseService.genericPost<ModifyCreditLimitResponse>(request,{isSOA: true});
  }

  async nipModificationService(cardNumber: string, PinValue: string, otpCode: string): Promise<PinModificationResponse> {
    const request = new PinModificationRequest({
      IdSesion: this.storageService.getSession(),
      Tarjeta: {
        Numero: cardNumber,
        Nip: PinValue
      },
      Token: {
        OTP: otpCode,
        HAC: null
      },
      tipoAcceso: Constants.ACCESS_TOKEN_TYPE,
      nombreAplicacion: Constants.ACCESS_METHOD_PORTAL
    });
    return this.baseService.genericPost<PinModificationResponse>(request, { isSOA: true });
  }

  async registerCvvService(cvvValue?: string, getDataCardsInfo?: GetDataCardResponse)
    : Promise<RegisterCvvResponse> {
    const geolocation = await Utils.getGeolocation();
    const request = new RegisterCvvRequest({
      CVV2: cvvValue,
      CustomerId: getDataCardsInfo.Card.CustomerId,
      IdPersona: getDataCardsInfo.Card.IdPersona,
      ExpirationDate: getDataCardsInfo.Card.ExpirationDate,
      CardNumber: getDataCardsInfo.Card.CardNumber,
      Registered: getDataCardsInfo.Card.Registered,
      Source: getDataCardsInfo.Card.Source,
      CardType: getDataCardsInfo.Card.CardType,
      ChipPin: getDataCardsInfo.Card.ChipPin,
      DCVV: null
    });
    return this.baseService.genericPost<RegisterCvvResponse>(request, {
      isSOA: true, urlOptions: [
        geolocation.latitude,
        geolocation.longitude,
        this.storageService.getSession()
      ]
    });
  }
  async dynamicCvvService(getDataCardsInfo: GetDataCardResponse) {
    const geolocation = await Utils.getGeolocation();
    const request = new DynamicCvvRequest({
      Account: getDataCardsInfo.Card.Account,
      CardFrontImageUrl: getDataCardsInfo.Card.CardFrontImageUrl,
      CardHolder: getDataCardsInfo.Card.CardHolder,
      CardNumber: getDataCardsInfo.Card.CardNumber,
      CardType: getDataCardsInfo.Card.CardType,
      ChipPin: getDataCardsInfo.Card.ChipPin,
      CustomerId: getDataCardsInfo.Card.CustomerId,
      ExpirationDate: getDataCardsInfo.Card.ExpirationDate,
      Source: getDataCardsInfo.Card.Source,
      IdPersona: getDataCardsInfo.Card.IdPersona
    });
    return this.baseService.genericPost<DynamicCvvResponse>(request, {
      isSOA: true, urlOptions: [
        geolocation.latitude, geolocation.longitude, this.storageService.getSession()]
    });
  }
}
