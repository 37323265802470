import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Observable, Subscription, SubscriptionLike, combineLatestWith, map, tap } from 'rxjs';
import { IExpressTransferExtraData } from 'src/app/interface/IExpressTransferExtraData';
import { IServicePayId } from 'src/app/interface/IServicePayId';
import { ISimpleValue } from 'src/app/interface/ISimpleValue';
import { ExpressTransferExtraDataService } from 'src/app/services/express-transfer-extra-data.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { NotifyServicePaymentService } from 'src/app/services/notify-service-payment.service';
import { ServiceTransferIdService } from 'src/app/services/service-transfer-id.service';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { VoluntaryContributionsService } from 'src/app/services/voluntary-contributions.services';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { ChecksConstants } from 'src/core/constants/ChecksConstants';
import { ClassHTML, Constants, DataType, ElementsHTML, Numbers } from 'src/core/constants/Constants';
import { CreditConstants } from 'src/core/constants/CreditConstants';
import { NavigationConstants } from 'src/core/constants/NavigationConstants';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { TransferStrings } from 'src/core/constants/TransferStrings';
import { DomiciliationStrings } from 'src/core/constants/DomiciliationStrings';

@Component({
  selector: 'app-enter-security-code',
  templateUrl: './enter-security-code.component.html',
  styleUrls: ['./enter-security-code.component.css']
})
export class EnterSecurityCodeComponent implements OnInit, OnDestroy {

  contentMargin: string = ElementsHTML.ContentMarginBreakpoints;
  titleToken: string = Strings.SECURITY_CODE.TitleToken;
  captionToken: string = Strings.PAGE_HEADER.Subtitle;
  paymentComplete: string = PATH.PaymentCompleted;
  confirmPayment: string = PATH.ServicesConfirmPayment;
  transferCompleted: string = PATH.TransferComplete;
  dataConfirm: {}[] = new Array<{}>();
  navigationBack: () => void;

  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(this.stateServicePayIdService.service$.pipe(
      tap(data => this.configPayment(data))
    )),
    map(([breakpoints, _]) => ({ breakpoints }))
  );


  private configPayment(service: IServicePayId) {
    this.idService = service.id;
    this.timeToApply = service.applyTime;
    this.paymentCommission = service.commission;
    this.referenceLength = service.referenceLength;
  }

  @Input() title: string;
  @Input() subtitle: string;
  @Input() nextView: string;
  @Input() beforeView: string;
  transferComplete: number;
  isServicePayment$: Observable<ISimpleValue>;
  expressTransferExtraData$: Observable<IExpressTransferExtraData>;
  otpInfo: IExpressTransferExtraData;
  otpInfoSubscription: SubscriptionLike;
  service: number = Numbers.Zero;
  titleService: string;
  subtitleService: string;
  today: string;
  timeToApply: number = Numbers.Zero;
  paymentCommission: number = Numbers.Zero;
  referenceLength: number = Numbers.Zero;
  idService: string;
  idOperation: string;
  head: string = Strings.CODE_SECURITY.Title;
  option: string = Strings.EMPTY;
  sendPaymentInsurance: string;
  dataAmountInsurance: string;
  dataInvestment: Array<{}>;
  dataAmountInvestment: string;
  dataInternational: Array<string>;
  isAddresseeInternational: boolean | null = null;
  isInternationalTransfer: boolean | null = null;
  isTransfer: boolean | null = null;
  showSubtitileMSI: boolean = false;
  arrayServices: string[] = [
    Constants.AGENDABLE_SERVICE_CODE.Telmex,
    Constants.SERVICE_CODE.Carnet,
    Constants.AGENDABLE_SERVICE_CODE.Sky,
    Constants.AGENDABLE_SERVICE_CODE.Amex,
    Constants.AGENDABLE_SERVICE_CODE.Mixup,
    Constants.AGENDABLE_SERVICE_CODE.Sanborns,
    Constants.AGENDABLE_SERVICE_CODE.Amitelc,
    Constants.AGENDABLE_SERVICE_CODE.Contelc,
    Constants.AGENDABLE_SERVICE_CODE.Tcinb,
    Constants.AGENDABLE_SERVICE_CODE.Pagliver,
    Constants.AGENDABLE_SERVICE_CODE.Pagoph,
    Constants.AGENDABLE_SERVICE_CODE.Iave
  ];
  state: Pick<NavigationExtras, 'state'>;
  titleCodeToken: string = Strings.SECURITY_CODE.TokenHead;
  domiciliationcompleted: boolean = false;
  classError: string = ClassHTML.MeasuresLabelOriginal;
  isDomiciliationTDC: boolean = false;
  constructor(
    readonly notifyServicePayment: NotifyServicePaymentService,
    private readonly modalService: ModalService,
    private readonly expressTransferService: ExpressTransferExtraDataService,
    private readonly router: Router,
    private readonly stateServicePayIdService: StateServicePayIdService,
    private readonly responsiveService: ResponsiveService,
    private readonly serviceTransferId: ServiceTransferIdService,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly voluntarySvc: VoluntaryContributionsService
  ) {
    this.isServicePayment$ = notifyServicePayment.servicePaymentData$;
    this.expressTransferExtraData$ = expressTransferService.expressTransferExtraDataObservable;
    this.initParams();
  }

  private initParams() {
    if (this.router.getCurrentNavigation()?.extras?.state) {
      this.dataConfirm = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.DataConfirm];
      if (this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.Codeflex]
        || this.router.getCurrentNavigation().extras.state[NavigationConstants.NAVIGATION_PARAMETERS.VoluntarySavingContributions] ||
        this.router.getCurrentNavigation().extras.state[NavigationConstants.NAVIGATION_PARAMETERS.PaymentGeneralContributionsByConcept]) {
        this.state = this.router.getCurrentNavigation().extras.state;
        return;
      }
      if(this.router.getCurrentNavigation().extras.state[NavigationConstants.NAVIGATION_PARAMETERS.DomiciliationVoluntaryContributions]){
        this.state = this.router.getCurrentNavigation().extras.state;
        return;
      }
      else if (this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.NavigationBack]) {
        this.navigationBack = () => this.router.navigate([this.beforeView]);
      }
      this.navigation();
    }
  }

  navigation() {
    this.isAddresseeInternational = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.Next];
    this.isInternationalTransfer = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.IsInternationalTransfer];
    this.isTransfer = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.IsTransfer];
    this.idOperation = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.IdOperation];
    this.service = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.Service];
    this.titleService = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.LabelTitle];
    this.subtitleService = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.LabelSubtitle];
    if (this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.ListSelectInsurance] !== Strings.EMPTY) {
      this.sendPaymentInsurance = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.ListSelectInsurance];
      this.dataAmountInsurance = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.Amount];
    } else {
      this.sendPaymentInsurance = this.dataAmountInsurance = Strings.EMPTY;
    }
    if (this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.DataInternational] !== Strings.EMPTY) {
      this.dataInternational = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.DataInternational];
    } else {
      this.dataInternational = [];
    }
    if (this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.DataInvestment] !== Strings.EMPTY) {
      this.dataInvestment = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.DataInvestment];
      this.dataAmountInvestment = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.AmountInvestment];
    }
    if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation()?.extras?.state) {
      this.domiciliationcompleted = this.router.getCurrentNavigation().extras.state[DomiciliationStrings.DOMICILIATION_REGISTER.domiciliationcompleted];
      this.isDomiciliationTDC = this.router.getCurrentNavigation().extras.state[DomiciliationStrings.DOMICILIATION_REGISTER.isCreditCardPayment];
    }
  }

  ngOnInit(): void {
    if (this.sendPaymentInsurance !== undefined) {
      if (this.sendPaymentInsurance.length > Numbers.Zero) {
        this.showSubtitileMSI = true;
      }else{
        this.showSubtitileMSI = false;
      }
    }
    if (!this.isValidOperation()) {
      this.router.navigate([PATH.Home]);
      return;
    }
    if (this.idOperation === Constants.OPERATION_ID) {
      this.title = this.titleToken;
      this.subtitle = this.captionToken;
      this.nextView = PATH.RegisteredService;
      this.beforeView = PATH.ConfirmServiceDetails;
      this.transferComplete = this.service;
    } else if (this.idOperation === Constants.PORTABILITY.IdPath) {
      this.title = this.titleToken;
      this.subtitle = this.captionToken;
      this.nextView = PATH.PortabilityCompleted;
      this.beforeView = PATH.ConfirmPortability;
    }else if (this.idOperation === CreditConstants.TYPE_OPERATIONS.credit) {
      this.isNewCredit();
      return;
    }else if (this.idOperation === CreditConstants.TYPE_OPERATIONS.paymentCredit) {
      this.isPaymentCredit();
      return;
    }
    this.isServicePayment$.subscribe(e => {
      if (e.value) {
        this.title = this.titleToken;
        this.nextView = this.paymentComplete;
        this.beforeView = this.confirmPayment;
        this.stateServicePayIdService.service$.subscribe(data => {
          this.idService = data.id;
          if (this.idService === Constants.SERVICE_CODE.Sipare) {
            this.nextView = this.transferCompleted;
          }
        });
      }
    });
    this.validateCheckbook(this.idOperation);
    this.payIdServiceId();
    this.codeflex();
    this.transfer();
    this.internationalTransfer();
    this.voluntarySavingsContributions();
    this.domiciliation();
    this.updateViews(NavigationConstants.NAVIGATION_PARAMETERS.DomiciliationVoluntaryContributions, true);
    this.updateViews(NavigationConstants.NAVIGATION_PARAMETERS.PaymentGeneralContributionsByConcept);
  }

  private isValidOperation() {
    if(this.idOperation === ChecksConstants.CHECK_CANCEL.CheckbookCancel || this.isDomiciliationTDC){
      return true;
    }
    return !(this.idOperation !== Constants.PORTABILITY.IdPath && !this.notifyAmountService.amount.amount);
  }

  validateCheckbook(idOperation: string) {
    if (idOperation === ChecksConstants.CHECK_CANCEL.CheckCancel || idOperation === ChecksConstants.CHECK_CANCEL.CheckbookCancel) {
      this.title = this.titleToken;
      this.subtitle = this.captionToken;
      this.nextView = PATH.CompleteCancelation;
      this.beforeView = PATH.ConfirmCancellation;
      return;
    } else {
      if (idOperation === ChecksConstants.CHECK_CANCEL.CheckProtection || idOperation === ChecksConstants.CHECK_CANCEL.CheckbookProtection) {
        this.title = this.titleToken;
        this.subtitle = this.captionToken;
        this.nextView = PATH.CheckProtectionCompleted;
        this.beforeView = PATH.CheckConfirm;
      }
    }
  }

  isNewCredit(){
    this.title = this.titleToken;
    this.subtitle = this.captionToken;
    this.nextView = PATH.CreditRegister;
    this.beforeView = PATH.CreditConfirm;
  }

  isPaymentCredit(){
    this.title = this.titleToken;
    this.subtitle = this.captionToken;
    this.nextView = PATH.CreditRegister;
    this.beforeView = PATH.ConfirmPaymentCredit;
  }

  payIdServiceId() {
    this.stateServicePayIdService.service$.pipe(
      tap(data => {
        this.idService = data.id;
        if (typeof this.service === DataType.Number) {
          switch (this.service) {
            case Constants.SERVICE_ID.ExpressTransfer:
              this.title = this.titleToken;
              this.subtitle = this.captionToken;
              this.nextView = this.transferCompleted;
              this.beforeView = PATH.ConfirmExpressTransfer;
              break;
            case Constants.SERVICE_ID.Investment:
              this.title = this.titleToken;
              this.head = this.titleCodeToken;
              this.subtitle = Strings.EMPTY;
              this.nextView = PATH.CompleteInvestmentRegistration;
              this.beforeView = PATH.ConfirmInvestment;
              break;
            case Constants.SERVICE_ID.Sipare:
              this.title = this.titleService;
              this.subtitle = this.subtitleService;
              this.nextView = this.transferCompleted;
              this.beforeView = this.confirmPayment;
              this.transferComplete = this.service;
              break;
            case Constants.SERVICE_ID.Deductible:
              this.title = this.titleService;
              this.subtitle = this.subtitleService;
              this.nextView = PATH.PaymentCompleted;
              this.beforeView = PATH.ServicesConfirmPayment;
              this.transferComplete = this.service;
              break;

            case Constants.SERVICE_ID.TaxPayment:
              this.title = this.titleService;
              this.subtitle = this.subtitleService;
              this.nextView = this.paymentComplete;
              this.beforeView = this.confirmPayment;
              this.transferComplete = this.service;
              break;
            case Constants.SERVICE_ID.InsurancePayment:
              this.title = this.titleService;
              this.subtitle = this.subtitleService;
              this.nextView = this.paymentComplete;
              this.beforeView = PATH.ConfirmPolicyPayment;
              this.transferComplete = this.service;
              this.head = this.titleCodeToken;
              break;
            default:
              break;
          }
        }
        this.payIdServiceName();
        this.head = this.titleCodeToken;
      })
    ).subscribe();
  }

  payIdServiceName() {
    if (this.arrayServices.includes(this.idService)) {
      this.title = this.titleToken;
      this.subtitle = this.captionToken;
      this.head = this.captionToken;
      this.nextView = PATH.CompletePayment;
      this.beforeView = PATH.ConfirmAgendableService;
      return;
    }

    const options = {
      [Constants.SERVICE_CODE.Cfe]: this.common.bind(this),
      [Constants.SERVICE_CODE.MexicoState]: this.common.bind(this),
      [Constants.SERVICE_CODE.SearsPayment]: this.common.bind(this),
      [Constants.SERVICE_CODE.GasNatural]: this.common.bind(this),
      [Constants.SERVICE_CODE.ExternalTrade]: this.common.bind(this),
      [Constants.SERVICE_CODE.Impfedr]: this.common.bind(this),
      [Constants.SERVICE_CODE.SuaPayment]: this.sua.bind(this),
      [Constants.SERVICE_CODE.CreditPayment]: this.credit.bind(this),
      [Constants.SERVICE_CODE.Paggm]: this.gmm.bind(this)
    };

    const selectedService = options[this.idService];
    this.otpInfoSubscription = this.expressTransferService.expressTransferExtraDataObservable.subscribe(async e => { this.otpInfo = e; });
    selectedService?.();
    this.serviceTransferId.stateTransferIdObservable.subscribe(data => {
      if (data.id === Constants.SERVICE_CODE.International) {
        this.beforeView = PATH.ConfirmInternationalTransfer;
        this.nextView = this.transferCompleted;
        this.head = Strings.SECURITY_CODE.TokenHead;
      }
    });
    if (this.isAddresseeInternational) {
      this.title = this.titleToken;
      this.subtitle = this.captionToken;
      this.nextView = PATH.CompleteInternationalAgendaRegistration;
      this.beforeView = PATH.ConfirmNewInternationalAgenda;
    }
  }

  gmm() {
    this.title = this.titleToken;
    this.subtitle = this.captionToken;
    this.nextView = PATH.ConfirmPaymentGMM;
    this.beforeView = PATH.ConfirmGMM;
  }

  credit() {
    this.head = this.titleCodeToken;
    this.beforeView = PATH.ConfirmCreditPayment;
    this.nextView = PATH.CompleteCreditPayment;
  }

  sua() {
    this.title = Strings.SECURITY_CODE.TitleToken;
    this.subtitle = Strings.PAGE_HEADER.Subtitle;
    this.nextView = PATH.SuaPaymentCompleted;
    this.beforeView = PATH.ServicesConfirmPayment;
  }

  common() {
    this.title = this.titleToken;
    this.subtitle = this.captionToken;
    this.nextView = this.paymentComplete;
    this.beforeView = PATH.ConfirmPayment;
  }

  carnet() {
    this.title = this.titleToken;
    this.subtitle = Strings.EMPTY;
    this.head = this.titleCodeToken;
    this.nextView = PATH.CompletePayment;
    this.beforeView = this.confirmPayment;
  }

  codeflex() {
    if (this.state?.[Constants.NAVIGATION_PARAMETERS.Codeflex]) {
      const next = this.state[Constants.NAVIGATION_PARAMETERS.NextView];
      const before = this.state[Constants.NAVIGATION_PARAMETERS.BeforeView];
      this.title = this.titleToken;
      this.subtitle = this.captionToken;
      this.nextView = next;
      this.beforeView = before;
    }
  }

  voluntarySavingsContributions() {
    if (this.state?.[NavigationConstants.NAVIGATION_PARAMETERS.VoluntarySavingContributions]) {
      const next = this.state[Constants.NAVIGATION_PARAMETERS.NextView];
      const before = this.state[Constants.NAVIGATION_PARAMETERS.BeforeView];
      this.title = this.titleToken;
      this.subtitle = this.captionToken;
      this.nextView = next;
      this.beforeView = before;
    }
  }


  internationalTransfer() {
    if (this.isInternationalTransfer && this.isTransfer) {
      this.title = this.titleToken;
      this.subtitle = this.captionToken;
      this.nextView = PATH.TransferComplete;
      this.beforeView = PATH.ConfirmInternationalTransfer;
    }
  }
  transfer() {
    if (this.isTransfer) {
      this.title = this.titleToken;
      this.subtitle = this.captionToken;
      this.nextView = PATH.TransferComplete;
      this.beforeView = PATH.ConfirmExpressTransfer;
      this.classError = TransferStrings.TRANSFER_ERROR_TOKEN;
    }
  }

  domiciliation() {
    if (this.domiciliationcompleted) {
      this.title = this.titleToken;
      this.subtitle = Strings.PAGE_HEADER.Subtitle;
      this.nextView = PATH.DomiciliationCompleted;
      this.beforeView = PATH.VerificationRegister;
    }
  }

  getOtp(event: Event) {
    if(this.otpInfo){
      this.otpInfo.otp = event[Constants.DATA_AGENDA_NATIONAL.Otp];
      this.expressTransferService.expressTransferExtraDataData = this.otpInfo;
    }
  }

  ngOnDestroy() {
    this.modalService.close();
  }

  updateViews(parameter: string, navigationBack: boolean = false) {
    if (this.state?.[parameter]) {
      const next = this.state[Constants.NAVIGATION_PARAMETERS.NextView];
      const before = this.state[Constants.NAVIGATION_PARAMETERS.BeforeView];
      this.title = this.titleToken;
      this.subtitle = this.captionToken;
      this.nextView = next;
      this.beforeView = before;
      if (navigationBack) {
        this.navigationBack = () => this.router.navigate([this.beforeView]);
      }
    }
  }
}
