import { AppConstants, AppMethod, RateApp } from 'src/core/constants/AppConstants';
import { Strings } from 'src/core/constants/Strings';
import { AppBridge } from 'src/core/utils/AppBridge';
import { Constants } from 'src/core/constants/Constants';

export class AppUtils {
  private static isFromApp: boolean = undefined;
  private static isXamarin: boolean = undefined;


  static get platform() {

    if (this.isFromApp === undefined) {
      this.validateAppAccess();
    }
    if (this.isXamarin === undefined) {
      this.validateAppAccessOrigin();
    }

    return {
      isXamarin: this.isXamarin,
      isApp: this.isFromApp,
      android: window[AppConstants.ANDROID_CONTEXT],
      iOS: window[AppConstants.IOS_CONTEXT]?.messageHandlers
    };
  }

  private static validateAppAccess() {
    try {
      if (window[AppConstants.ANDROID_CONTEXT]) {
        window[AppConstants.ANDROID_CONTEXT][AppMethod.SendNativeScreen](Strings.EMPTY, Strings.EMPTY);
      }
      else {
        window[AppConstants.IOS_CONTEXT].messageHandlers[AppMethod.SendNativeScreen].postMessage(Strings.EMPTY);
      }
      this.isFromApp = true;
    } catch (error) {
      this.isFromApp = false;
    }
  }

  private static validateAppAccessOrigin() {
    try {
      if (window[AppConstants.ANDROID_CONTEXT]) {
        window[AppConstants.ANDROID_CONTEXT][AppMethod.GetInfoPlatform]();
      } else {
        window[AppConstants.IOS_CONTEXT].messageHandlers[AppMethod.GetInfoPlatform].postMessage(Strings.EMPTY);
      }
      this.isXamarin = true;
    } catch (error) {
      this.isXamarin = false;
    }
  }

  public static async hideSpinner() {
    await AppBridge.invoke<string>(AppMethod.ShowSpinner, Constants.FALSE);
  }

  public static ProcessedSuccessfully(url) {
    if (this.platform.isApp && RateApp.TRANSFERS_RATE.some(service => service === url)) {
      AppBridge.invoke<string>(AppMethod.PortalSuccess, null);
    }
  }

}
