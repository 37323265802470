import { Regex } from 'src/core/constants/RegexConstants';
import { HostnameType } from 'src/core/constants/HTMLConstants';
import { Constants } from 'src/core/constants/Constants';

export class DeviceUtils{

  static isApple() {
    const agent = window.navigator.userAgent.toLowerCase();
    if((window.screen.width <= Constants.RESOLUTIONS.IpadMinWidth && agent.match(Constants.USER_AGENT.MacOption)) || (agent.match(Constants.USER_AGENT.Mobile))) {
      return true;
    } else {
      return false;
    }
  }

  static get isLocalhost(): boolean{
    return window?.location?.hostname && (window.location.hostname === HostnameType.IPv4 ||
    window.location.hostname === HostnameType.IPv6);
  }

}
