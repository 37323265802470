import { StorageService } from 'src/app/services/storage.service';
import { PathModel } from 'src/app/models/PathModel';
import { SessionManagerService } from 'src/app/services/session-manager.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { map } from 'rxjs/operators';
import { AssetsType, Char, Constants, Regex } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { TError } from 'src/app/interface/TError';
import { ProductConstants } from 'src/core/constants/ProductConstants';
import { AppUtils } from 'src/core/utils/AppUtils';
@Injectable({
  providedIn: 'root'
})
export class BaseServiceService implements IBaseService {

  constructor(
    private readonly http: HttpClient,
    private readonly configService: ConfigService,
    private readonly sessionManager: SessionManagerService,
    private readonly storage: StorageService
  ) { }

  async genericPost<T>(request: { [key: string]: unknown } | { data: unknown }, config: ServiceOptions = {}): Promise<T> {
    let url = this.getUrl(request);
    if (config.urlOptions) {
      url = this.formatUrl(url, config.urlOptions);
    }
    if (!url) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }

    const options = { headers: await this.getHeaders(config) };

    return new Promise((resolve, reject) => {
      this.sessionManager.restartInactivityTracking();
      this.http.post<T>(url, request.data, options)
        .pipe(
          map((response: T) => {
            const error = response?.[ProductConstants.SERVICES_ERROR_PARAMETER] as TError;
            if (error != null && error?.No && String(error.No) !== String(Constants.MSG_SERVICE_RESPONSE_SUCCESSFUL)) {
              const errorService = error.Description || error.Descripcion || Strings.SERVICES.UnexpectedMsg;
                reject(new BusinessError(errorService, error.No));
            }
            AppUtils.ProcessedSuccessfully(url);
            return response;
          })
        )
        .subscribe((response) => resolve(response), (error) => reject(error));
    });
  }

  async genericPut<T>(request: { [key: string]: unknown } | { data: unknown }, config: ServiceOptions = {}): Promise<T> {
    let url = this.getUrl(request);
    if (config.urlOptions) {
      url = this.formatUrl(url, config.urlOptions);
    }
    if (!url) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }

    const options = { headers: await this.getHeaders(config) };

    return new Promise((resolve, reject) => {
      this.sessionManager.restartInactivityTracking();
      this.http.put<T>(url, request.data, options)
        .pipe(
          map((response: T) => {
            const error = response?.[ProductConstants.SERVICES_ERROR_PARAMETER] as TError;
            this.getErrorServices(error, reject);
            return response;
          })
        )
        .subscribe((response) => resolve(response), (error) => reject(error));
    });
  }

  public async genericGet<T>(request: { [key: string]: unknown } | { data: unknown }, config: ServiceOptions): Promise<T> {
    let url = this.getUrl(request);
    if (config.urlOptions) {
      url = this.formatUrl(url, config.urlOptions);
    }
    if (!url) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    url += this.convertObjectToQueryString(request);

    const options = { headers: await this.getHeaders(config) };

    return new Promise((resolve, reject) => {
      this.sessionManager.restartInactivityTracking();
      this.http.get<T>(url, options)
        .pipe(
          map((response: T) => {
            const errorService = response?.[ProductConstants.SERVICES_ERROR_PARAMETER] as TError;
            this.getErrorServices(errorService, reject);
            return response;
          })
        )
        .subscribe((response) => resolve(response), (error) => reject(error));
    });
  }

  public getUrlAssets(type: AssetsType) {
    const config = this.configService.getConfig();
    switch (type) {
      case AssetsType.ServicesNa:
        return config.CdnServices;
      case AssetsType.Accounts:
      default:
        return config.CdnAccounts;
    }
  }


  /**
   * Obtiene headers para consumo de servicio
   * @param config Configuración de consumo
   */
  private async getHeaders(config: ServiceOptions) {
    const options: {
      idUsuario?: string, idDestino?: string, idConsumidor?: string,
      Authorization?: string, userAgent?: string, genericClaim1?: string, autorizacion?: string, userAgent64?: string
    } = {};

    if (config.isSOA) {
      options.idUsuario = Strings.EMPTY;
      options.idDestino = Strings.EMPTY;
      options.idConsumidor = Strings.EMPTY;
    }

    if (config.isSecure) {
      if (config.isSOA) {
        options.autorizacion = this.storage.getSession(true);
      } else {
        options.Authorization = this.storage.getSession(true);
      }
    }

    if (config.addUserAgent) {
      options.userAgent = navigator.userAgent;
    }

    if (config.addSession) {
      options.genericClaim1 = this.storage.getSession();
    }

    if (config.addUserAgent64) {
      options.userAgent64 = Strings.EMPTY;
    }

    return new HttpHeaders(options);
  }

  //#region ENDPOINTS

  /**
     * Obtiene URL asociada a request
     * @param request Petición a servicio
     */
  private getUrl(request: object): string {
    const config = this.configService.getConfig();
    return PathModel[request[Constants.ENDPOINT_PATH_MODEL]] ? config[PathModel[request[Constants.ENDPOINT_PATH_MODEL]]] : null;
  }


  private formatUrl(endpoint: string, optionsUrl: Array<string | number>): string {
    return endpoint.replace(Regex.FormatUrl, (_, index) => optionsUrl[index]?.toString());
  }

  private convertObjectToQueryString(object): string {
    if (object?.data) {
      object = object.data;
    }
    return !Object.keys(object).length ? Strings.EMPTY : (Char.QuestionMark + Object.keys(object).map(key => `${key}=${object[key]}`).join(Char.Ampersand));
  }

  getErrorServices(error: TError, reject) {
    if (error != null && String(error?.No) !== String(Constants.MSG_SERVICE_RESPONSE_SUCCESSFUL)) {
      const errorService = error?.Description || error?.Descripcion || Strings.SERVICES.UnexpectedMsg;
      reject(new BusinessError(errorService, error?.No));
    }
  }

  //#endregion



}

export type ServiceOptions = {
  isSecure?: boolean,
  isSOA?: boolean,
  addUserAgent?: boolean,
  urlOptions?: Array<(string | number)>,
  addSession?: boolean,
  addUserAgent64?: boolean
};
