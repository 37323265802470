<div class="position-relative">
  <ng-content select="[input]"></ng-content>
  <img
    role="button"
    class="position-absolute eye"
    [src]="show ? (eye | cdnImage) : (eyeOff | cdnImage)"
    alt="Mostrar"
    width="24"
    height="24"
    (click)="showValue()"
  />
</div>
