import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SubscriptionLike, combineLatestWith, map } from 'rxjs';
import { IAddresseeAccount } from 'src/app/interface/IAddresseeAccount';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalModifyAccountComponent } from 'src/app/component/modal-modify-account/modal-modify-account.component';
import { AccountModifyService } from 'src/app/services/info-accounts-edit.service';
import { AgendaType, ClassHTML, Constants, Numbers } from 'src/core/constants/Constants';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';
import { HTMLConstants } from 'src/core/constants/HTMLConstants';
import { Router } from '@angular/router';
import { PATH } from 'src/core/constants/Path';
import { TransferStrings } from 'src/core/constants/TransferStrings';
import { TransfersResources } from 'src/core/constants/TransferResources';
import { ModalDeleteAddresseeComponent } from 'src/app/services-module/components/modal-delete-addressee/modal-delete-addressee.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Resources } from 'src/core/constants/Resources';
import { ModalModifyLimitComponent } from 'src/app/shared/components/modal-modify-limit/modal-modify-limit.component';
import { SendAnotherDateService } from 'src/app/services/send-another-date.service';
import { CardAddresseeService } from 'src/app/services/card-addressee.service';
import { ModalTransferHistoryComponent } from 'src/app/transfers/components/modal-transfer-history/modal-transfer-history.component';
import { environment } from 'src/environments/environment';
import { CustomResponsiveService } from 'src/app/shared/custom-responsive.service';
import { PatrimonialStatusService } from 'src/app/services/patrimonial-status.service';
import { AddressConstants } from 'src/core/constants/AddressConstants';

@Component({
  selector: 'app-card-addressee',
  templateUrl: './card-addressee.component.html',
  styleUrls: ['./card-addressee.component.css']
})
export class CardAddresseeComponent implements OnInit,OnDestroy {

  @Input('addressee') addressee!: IAddresseeAccount | {
    profile_image: string,
    nombre: string,
    nombre_alias: string,
    banco: string,
    clabe: string,
    limite_diario?: string,
    seleccionado: boolean,
    idAgenda: number,
    tipoCuenta: string,
    badge: string,
    email: string,
    agendaType: string,
    tokenType: string,
    beneficiaryType: string,
    headline: string,
    type: number,
    accountNumber: string,
    code: number,
    exchangeRate: number,
    cuentaDestino?: string,
    contrato?: string
  };
  @Input() id: number = Numbers.Zero;
  @Input() redirectToHome: boolean = false;
  @Input() selected: boolean = false;
  @Input() showMargin?: boolean= true;
  @Output() informationAddressee: EventEmitter<IAddresseeAccount> = new EventEmitter<IAddresseeAccount>();
  @Output() navigateCard: EventEmitter<void> = new EventEmitter<void>();
  codeType: number;
  accountModify: SubscriptionLike;
  ownAccountType: number = AgendaType.OwnAccounts;
  brokerageHouseType: number = AddressConstants.TAB_INDEX_BROKERAGE_HOUSE;
  styleCard: boolean = false;
  titleModal:string=TransferStrings.TITLE_MODAL;
  resizeCard: boolean = false;
  hideOptions: boolean = false;
  patrimonialDescription: string = Constants.CT_PATRIMONIAL_DESCRIPTION;
  fileViewBlue: string = TransfersResources.FILE_VIEW_BLUE;
  modalInstance: NgbModalRef;
  trash: string = Resources.TRASH;
  edit: string = TransfersResources.EDIT_BLUE;
  chevronBlue: string = Resources.CHEVRON_RIGHT_LIGHT_BLUE;
  beta: boolean = environment.beta;

  styles: Partial<CSSStyleDeclaration> = {
    display: ClassHTML.WebkitBox,
    webkitBoxOrient: ClassHTML.BoxOrientVertical,
    overflowWrap : ClassHTML.AnyWhere
  };

  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith (
      this.customResponsiveService.observe
    ),
    map(([breakpoints, breakpointsCard]) => ({ breakpoints, breakpointsCard }))
  );

  constructor(private readonly accountModifyService: AccountModifyService,
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly router: Router,
    private readonly sendAnotherDate: SendAnotherDateService,
    private readonly cardAddresseeService: CardAddresseeService,
    private readonly customResponsiveService: CustomResponsiveService,
    private readonly patrimonialService: PatrimonialStatusService) { }

  ngOnDestroy(): void {
    this.accountModify?.unsubscribe();
  }

  ngOnInit(): void {
    this.accountModify =  this.accountModifyService.account$.subscribe(response=>{
      this.codeType = response.type;
    });
    if (this.router.url === PATH.Transfers) {
      this.hideOptions = true;
    } else if (this.router.url === PATH.Transfers || this.router.url === PATH.InternationalTransfers || this.router.url === PATH.ConfirmExpressTransfer) {
      this.resizeCard = true;
    }
    this.styleCard = this.router.url === PATH.TransferData || this.router.url === PATH.InternationalTransfers;
  }

  selectedAddressee(addressee: IAddresseeAccount) {
    this.informationAddressee.emit(addressee);
  }

  showModalModifyAccount() {
    const modalOptions: ModalOptions = {
      centered: true,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalModifyAccount
    };
    const modalRef = this.modalService.open(ModalModifyAccountComponent, modalOptions, Constants.MODAL_OPTIONS.FormModify);
    const container = document.getElementById(HTMLConstants.ID_MODAL.ModalContainerAddressee);
    NavigationUtils.navigateAndEnsureSingleModalDisplay(container, modalRef, true, true);

    this.accountModifyService.account = {
      account: this.addressee.clabe,
      alias: this.addressee.nombre_alias,
      maximumLimit: this.addressee.limite_diario,
      badge: this.addressee.badge,
      email: this.addressee.email,
      type: this.addressee.type,
      agendaType: this.addressee.agendaType,
      tokenType: this.addressee.tokenType,
      recipientType: this.addressee.beneficiaryType,
      idAgent: this.addressee.idAgenda,
      headline: this.addressee.headline,
      bank: this.addressee.banco,
      name: this.addressee.nombre,
      limitOld: this.addressee.limite_diario
    };
    this.accountModifyService.originData ={
      alias: this.addressee.nombre_alias,
      limit: this.addressee.limite_diario,
      email: this.addressee.email
    };
    if(!(this.codeType === AgendaType.OthersBanks || this.codeType === AgendaType.International || this.codeType === AgendaType.ThirdParties)){
      this.accountModifyService.clearAccountModify();
    }
    this.accountModifyService.setModifyType = TransferStrings.ACCOUNT_MODIFY;
  }

  async closeModal() {
    this.modalService.close();
  }

  showModalTransferHistory() {
    this.setAddressee();
    const modalOptions: ModalOptions = {
      size: Constants.MODAL_OPTIONS.SizeMd,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalTransferHistory,
      centered: true
    };
    this.modalService.open(ModalTransferHistoryComponent, modalOptions);
  }

  setAddressee() {
    this.accountModifyService.account = {
      account: this.addressee.clabe,
      alias: this.addressee.nombre_alias,
      maximumLimit: this.addressee.limite_diario,
      badge: this.addressee.badge,
      email: this.addressee.email,
      type: this.addressee.type,
      agendaType: this.addressee.agendaType,
      tokenType: this.addressee.tokenType,
      recipientType: this.addressee.beneficiaryType,
      idAgent: this.addressee.idAgenda,
      headline: this.addressee.headline,
      bank: this.addressee.banco,
      name: this.addressee.nombre,
      limitOld: this.addressee.limite_diario
    };
  }

  deleteSchedule() {
    this.setAddressee();
    const modalOptions: ModalOptions = {
      modalDialogClass: ModalConstants.MODAL_OPTIONS.DeleteAgenda,
      size: Constants.MODAL_OPTIONS.SizeXl,
      centered: true
    };
    this.modalService.open(ModalDeleteAddresseeComponent, modalOptions, {
      formInstance: this.modalInstance,
      modifiyModal: false
    });
  }

  showModalModifyLimit() {
    this.patrimonialService.transferData = {
      no_tarjetaCT: this.addressee.cuentaDestino,
      noTarjetaCTS: this.addressee.cuentaDestino
    };
    this.patrimonialService.accountLimit.getLimit = Number(this.addressee.limite_diario);
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeMd,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.modalModifyLimit
     };
     this.modalService.open(ModalModifyLimitComponent, modalOptions);

  }

  addresseefunction() {
    this.navigateCard.emit();
    this.sendAnotherDate.ReturnData ={ isReturn: false };
    this.cardAddresseeService.cardAddressee = this.addressee;
    this.cardAddresseeService.cardProcess = true;
  }
}
