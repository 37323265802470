import { BreakpointState } from 'src/app/shared/responsive.service';
import { ElementsHTML, Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { MimeTypes, FileExtensions } from 'src/core/constants/TransactionConstants';
import { LocationConstants } from 'src/core/constants/LocationConstants';
import { Resources } from 'src/core/constants/Resources';
import { AppUtils } from 'src/core/utils/AppUtils';
import { AppBridge } from 'src/core/utils/AppBridge';
import { AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { AppNavigation } from 'src/app/models/AppNavigation';
import { Utils } from 'src/core/utils/utils';

export class FileUtils {
  static downloadPdf(pdfBase64: string, name: string = 'File', type = 'application/octet-stream') {
    if (AppUtils.platform.isApp) {
      const navigationParams: AppNavigation = {
        Data: AppScreen.ShowPDF,
        DataParams: pdfBase64
      };
      AppBridge.invoke<string>(AppMethod.SendNativeScreen, navigationParams).catch(error => {
      Utils.printLogError(error);
      });
    } else {
      const url = this.convertToPdf(pdfBase64, type);
      const link = document.createElement(ElementsHTML.LabelA);
      link.href = url;
      link.download = `${name}.pdf`;
      link.click();
      URL.revokeObjectURL(url);
    }
  }

  static downloadExcelDocument(base64Response: string, fileName: string) {
    const byteCharacters = atob(base64Response);
    const byteArray = new Uint8Array(byteCharacters.split(Strings.EMPTY).map(char => char.charCodeAt(Numbers.Zero)));
    const blob = new Blob([byteArray], { type: MimeTypes.Excel});
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement(ElementsHTML.LabelA);
    document.body.appendChild(link);
    link.href = url;
    link.download = fileName + FileExtensions.Xls;
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }

  static showGeolocationManual(breakpoints: BreakpointState) {
    const name = breakpoints.desktop
      ? LocationConstants.LOCATION_MODAL.DesktopPDF
      : breakpoints.tablet
      ? LocationConstants.LOCATION_MODAL.TabletPDF
      : LocationConstants.LOCATION_MODAL.MobilePDF;
    return Resources.CDN_URL(name);
  }

  static convertToPdf(pdfBase64: string, type: string){
    const byteCharacters = atob(pdfBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = Numbers.Zero; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type });
    return URL.createObjectURL(blob);
  }

  static getBase64File(file:Blob): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      try {
        const pdfLoad = new FileReader();
        pdfLoad.onload = function (fileLoadedEvent) {
          try {
            resolve(fileLoadedEvent.target.result);
          } catch (error) {
            reject(null);
          }
        };
        pdfLoad.readAsDataURL(file);
      } catch (error) {
        reject(null);
      }
    });
  }
}
