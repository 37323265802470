import { Injectable } from '@angular/core';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { StorageService } from 'src/app/services/storage.service';
import { AccountModel } from 'src/app/interface/AccountModel';
import { environment } from 'src/environments/environment';
import { AssetsType, Constants, Length, AccountType } from 'src/core/constants/Constants';
import { ProductAccountResponse } from 'src/app/interface/dto/ProductAccountResponse';
import { CheckingAccountsRequest } from 'src/app/interface/ICheckingAccountsRequest';
import { Utils } from 'src/core/utils/utils';
import { CheckingAccountModel, ICheckingAccountsResponse } from 'src/app/interface/ICheckingAccountsResponse';
import { GetCheckAccountsRequest } from 'src/app/interface/IGetCheckAccountsRequest';
import { GetCreditsPLRequest } from 'src/app/interface/dto/GetCreditsPLRequest';
import { GetCreditsPLResponse } from 'src/app/interface/dto/GetCreditsPLResponse';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { Strings } from 'src/core/constants/Strings';
import { AforePLRequest } from 'src/app/interface/IAforePLRequest';
import { IAforePLResponse } from 'src/app/interface/IAforePLResponse';
import { ProductsPLRequest } from 'src/app/interface/IProductsPLRequest';
import { IProductsPLResponse } from 'src/app/interface/IProductsPLResponse';
import { CheckInvestmentsRequest } from 'src/app/interface/dto/ICheckInvestmentsRequest';
import { CheckInvestmentsResponse } from 'src/app/interface/dto/ICheckInvestmentsResponse';
import { CheckCreditCardRequest } from 'src/app/interface/ICheckCreditCardRequest';
import { CheckCreditCardResponse } from 'src/app/interface/ICheckCreditCardResponse';
import { InsurancePolicyPlRequest } from 'src/app/interface/dto/InsurancePolicyPlRequest';
import { InsurancePolicyPlResponse } from 'src/app/interface/dto/InsurancePolicyPlResponse';
import { IUserProductsConfig } from 'src/app/interface/IUserProductsConfig';
import { Resources } from 'src/core/constants/Resources';
import { GetInvestmentAccountsRequest } from 'src/app/interface/dto/GetInvestmentAccountsRequest';
import { AccountsStatusService } from 'src/app/services/accounts-status.service';
import { PayrollDataService } from 'src/app/services/payroll-data.service';
import { CreditCardsStatusService } from 'src/app/services/credit-cards-status.service';
import { AforeStatusService } from 'src/app/services/afore-status.service';
import { InsurancesStatusService } from 'src/app/services/insurances-status.service';
import { CreditsStatusService } from 'src/app/services/credits-status.service';
import { InvestmentService } from 'src/app/services/investment.service';
import { InvestmentCompaniesInfoStatusService } from 'src/app/services/investment-companies-info-status.service';
import { AforeDataService } from 'src/app/services/afore-data.service';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { VerifyAccountModel, VerifyAccountResponse } from 'src/app/interface/dto/VerifyClientAccountResponse';
import { VerifyAccountsRequest } from 'src/app/interface/dto/VerifyAccountRequest';
import { CreditCardStatusService } from 'src/app/services/credit-card-status.service';
import { SentinelService } from 'src/app/services/sentinel.service';
import { ProductConstants } from 'src/core/constants/ProductConstants';
import { ProductsInfoService } from 'src/app/services/products-info.service';
import { ProductUtils } from 'src/core/utils/ProductUtils';
import { GetVariableIncomeInvestmentResponse } from 'src/app/interface/dto/GetVariableIncomeInvestmentResponse';
import { GetVariableIncomeInvestmentRequest } from 'src/app/interface/dto/GetVariableIncomeInvestmentRequest';
import { GetInvestmentAccountsModel, InvestmentAccountsData } from 'src/app/interface/GetInvestmentAccountsModel';
import { PayrollCreditConstants } from 'src/core/constants/PayrollCreditConstants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends ProductsInfoService {
  private productsResponseMS: ProductAccountResponse;
  private productsResponse: ICheckingAccountsResponse;
  private productsPLResponse: IProductsPLResponse;
  private checkInvestmentsResponse: CheckInvestmentsResponse;
  private verifyResponse: VerifyAccountResponse;
  private investmentAccountsData: Array<InvestmentAccountsData>;
  private readonly isMongo: boolean = environment.OsbMongoServices === Constants.MONGO_SERVICES_ENABLED;
  private firstAccountConsultValue: boolean = false;
  private isOtherOperationValue: boolean = false;
  private _isCatchOnTabs: boolean = false;
  dataAccountItem: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  dataAccountItem$ = this.dataAccountItem.asObservable();
  constructor(
    private readonly baseService: IBaseService,
    private readonly storageService: StorageService,
    private readonly accountsStatusService: AccountsStatusService,
    private readonly payrollDataService: PayrollDataService,
    private readonly creditCardsStatusService: CreditCardsStatusService,
    private readonly stateAforeService: AforeStatusService,
    private readonly insurancesStatusService: InsurancesStatusService,
    private readonly creditsStatusService: CreditsStatusService,
    private readonly investmentService: InvestmentService,
    private readonly investmentCompaniesInfoStatusService: InvestmentCompaniesInfoStatusService,
    private readonly aforeDataService: AforeDataService,
    private readonly loyaltyService: LoyaltyService,
    private readonly creditCardStatusService: CreditCardStatusService,
    private readonly sentinelService: SentinelService) {
    super();
  }

  async getAccounts(config: IUserProductsConfig = { reload: true }): Promise<Array<AccountModel>> {
    await this.updateProducts(config);
    if (this.isMongo && !config?.forceOsb) {
      return this.productsResponseMS?.datos?.cuentas;
    }
    else {
      let accounts = this.productsResponse?.Debito?.CuentaCheques || [];
      if (this.productsResponse.Debito?.segCuentaDepositoInf && this.productsResponse.Debito.segCuentaDepositoInf.length > Length.Empty && !config.skipSubAccounts) {
        accounts = accounts.concat(this.productsResponse.Debito.segCuentaDepositoInf);
      }
      if (config?.addInvestmentAccounts && this.productsResponse?.Debito?.Inversiones &&
        this.productsResponse.Debito.Inversiones.length > Length.Empty) {
        accounts = accounts.concat(this.productsResponse.Debito.Inversiones);
      }
      accounts = this.filterAccounts(config, accounts);
      const accountsData = accounts.map<AccountModel>(item => ProductUtils.convertToAccountModel(item));
      return accountsData.sort((a, b) => Utils.sortAccounts(a.tipoCuenta, a.category) - Utils.sortAccounts(b.tipoCuenta, b.category));
    }
  }

  filterAccounts(config: IUserProductsConfig, accounts: CheckingAccountModel[]) {
    if (config?.bureauAlerts) {
      accounts = accounts.map<CheckingAccountModel>(item => {
        item.AplicaAlertaBuro = this.productsResponse.Debito.AplicaAlertaBuro;
        return item;
      });
    }
    if (config?.filterInvestmentAccounts) {
      accounts = accounts.filter(account => !ProductConstants.INVESTMENT_ACCOUNTS.includes(account.TipoCuenta as AccountType));
    }

    if(config?.accountOptions?.transactionType === PayrollCreditConstants.PAYROLL_CREDIT_ID) {
      accounts = accounts.filter(account => account.CreditoNomina.BndMovimientosNomina === true);
    }else if(config?.accountOptions?.transactionType === PayrollCreditConstants.PAYROLL_CREDIT_ADVANCE_ID) {
      accounts = accounts.filter(account => account.CreditoNomina.BndPermiteAnticipo === true);
    }
    const data = accounts.some(item=>item.IdCuentaProducto && item.CuentaFondeo);
    this.dataAccountItem.next(data);
    return accounts;
  }

  async getProducts(): Promise<ProductAccountResponse> {
    await this.getAccounts();
    return this.productsResponseMS;
  }

  clearUserProducts() {
    this.productsResponseMS = null;
    this.productsResponse = null;
    this.productsPLResponse = null;
    this.checkInvestmentsResponse = null;
    this.investmentAccountsData = null;
    this.verifyResponse = null;
    this.clearTabs();
  }

  async getCredits() {
    const creditsRequest = new GetCreditsPLRequest({
      IdSession: this.storageService.getSession(),
      MedioAcceso: Constants.ACCESS_METHOD_PORTAL,
      OrigenConsulta: ProductConstants.ORIGIN_CONSULT_DATA,
      IpCliente: this.sentinelService.sentinel.ip
    });
    const creditsResponse = await this.baseService.genericPost<GetCreditsPLResponse>(creditsRequest, { isSOA: true });
    if (!creditsResponse?.Creditos?.length) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
    }
    return creditsResponse;
  }

  async aforeBalances() {
    const request: AforePLRequest = new AforePLRequest({
      idSesion: this.storageService.getSession(),
      MedioAcceso: Constants.ACCESS_METHOD_PORTAL,
      OrigenConsulta: ProductConstants.ORIGIN_CONSULT_DATA,
      IpCliente: this.sentinelService.sentinel.ip
    });
    const aforeBalances = await this.baseService.genericPost<IAforePLResponse>(request, { isSOA: true });
    if (aforeBalances == null || aforeBalances.cuenta == null) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
    } else {
      return aforeBalances;
    }
  }

  async stockProducts() {
    const request: ProductsPLRequest = new ProductsPLRequest({
      IdSession: this.storageService.getSession()
    });
    return this.baseService.genericPost<IProductsPLResponse>(request, { isSOA: true });
  }

  async getInvestments(config: IUserProductsConfig = { reload: true }, accountList: ProductAccountResponse) {
    if (!this.checkInvestmentsResponse) {
      if (this.isMongo && !config.forceOsb) {
        const accounts = await this.baseService.genericGet<ProductAccountResponse>(new CheckingAccountsRequest(), { isSecure: true, addSession: true });
        accountList.datos.plazos = accounts.datos.plazos.map(this.formatInvestment);
        if (!accounts?.datos?.plazos?.length) {
          throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
        }
      } else {
        const request = new CheckInvestmentsRequest({
          IdSession : this.storageService.getSession(),
          MedioAcceso: Constants.ACCESS_METHOD_PORTAL,
          OrigenConsulta: ProductConstants.ORIGIN_CONSULT_DATA,
          IpCliente: this.sentinelService.sentinel.ip
        });
        this.checkInvestmentsResponse = await this.baseService.genericPost<CheckInvestmentsResponse>(request, { isSOA: true });
      }
    }
    if (!this.isMongo && this.checkInvestmentsResponse?.PagareInfo?.length > Length.Empty){
        this.fillInvestmentAccountsData(accountList, this.checkInvestmentsResponse);
    }
  }

  async checkInvestmentsCompanies() {
    if (!this.investmentAccountsData) {
      this.investmentAccountsData = [];
      await this.getInvestmentAccount();
      await this.getVariableInvestmentAccount();
    }
    return this.investmentAccountsData;
  }

  async getInvestmentAccount(){
    try{
      const response = await this.baseService.genericGet<GenericResponse<GetInvestmentAccountsModel>>(new GetInvestmentAccountsRequest(), { isSecure: true, addSession: true });
      this.investmentAccountsData = response?.datos.cuentas;
      this.investmentCompaniesInfoStatusService.timeData = response.datos.horario;
    }catch(error){
      Utils.printLogError(error);
    }
  }

  async getVariableInvestmentAccount(){
    try{
      const variableResponse = await this.baseService.genericGet<GetVariableIncomeInvestmentResponse>(new GetVariableIncomeInvestmentRequest(),
      {isSecure: true, addSession: true });
      if(variableResponse?.datos){
        variableResponse.datos.cuentas.forEach((item) => item.fondos.forEach((it) => this.investmentAccountsData.push(ProductUtils.convertToAccount(it, item.numeroCuenta))));
        this.investmentCompaniesInfoStatusService.variableInvestmentTimeData = variableResponse.datos.horario;
      }
    }catch(error){
      Utils.printLogError(error);
    }
  }

  async getCreditCards(isHome: boolean = false, filter:string = Strings.EMPTY) {
    let request: CheckCreditCardRequest;
    if(isHome) {
      request = new CheckCreditCardRequest({
        IdSession: this.storageService.getSession(),
        MedioAcceso: Constants.ACCESS_METHOD_PORTAL,
        OrigenConsulta: ProductConstants.ORIGIN_CONSULT_DATA,
        IpCliente: this.sentinelService.sentinel.ip
      });
    } else {
      request = new CheckCreditCardRequest({
        IdSession: this.storageService.getSession(),
        MedioAcceso: Constants.ACCESS_METHOD_PORTAL,
        IpCliente: this.sentinelService.sentinel.ip
      });
    }
    const response = await this.baseService.genericPost<CheckCreditCardResponse>(request, { isSOA: true });
    if (filter){
      const directDebitFilter = response.TDC.filter(value => value.TipoCuenta === filter);
      response.TDC = directDebitFilter;
    }
    response.TDC.forEach(item => {
      item.CardFrontImageUrl = super.getCardImage(item.CardFrontImageUrl);
    });

    return response;
  }

  async getInsurancePolicy() {
    const request = new InsurancePolicyPlRequest({
      idSession: this.storageService.getSession(),
      medioAcceso: Constants.ACCESS_METHOD_PORTAL,
      origenConsulta: ProductConstants.ORIGIN_CONSULT_DATA,
      ipCliente: this.sentinelService.sentinel.ip
    });
    const insuranceProduct = await this.baseService.genericGet<InsurancePolicyPlResponse>(request, { isSOA: true });
    const policies = insuranceProduct?.Polizas;
    if (!policies?.Auto?.length && !policies?.Danos?.length && !policies?.GMM?.length && !policies?.Vida?.length) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
    } else {
      return insuranceProduct;
    }
  }

  async getInvestmentsCompanies() {
    const accounts = await this.baseService.genericGet<ProductAccountResponse>(new CheckingAccountsRequest(), { isSecure: true, addSession: true });
    if (!accounts?.datos?.inversiones?.length) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
    } else {
      return accounts;
    }
  }

  clearData() {
    this.accountsStatusService.status = { value: false };
    this.payrollDataService.isProcessPayrollData = { value: false };
    this.creditCardsStatusService.status = { value: false };
    this.stateAforeService.status = { value: false };
    this.insurancesStatusService.status = { value: false };
    this.creditsStatusService.status = { value: false };
    this.investmentService.status = { value: false };
    this.insurancesStatusService.statusLife = false;
    this.insurancesStatusService.showOptionsInsurances = false;
    this.investmentCompaniesInfoStatusService.status = { value: false };
}

  //#region Private methods
  private async updateProducts(config?: IUserProductsConfig) {
    if (this.isMongo && !config.forceOsb) {
      await this.getUserAccountsMS(config);
    }
    else {
      await this.getUserAccounts(config);
    }
  }

  private async getUserAccountsMS(config?: IUserProductsConfig) {
    if (config?.reload) {
      this.productsResponseMS = await this.baseService.genericGet<ProductAccountResponse>(new CheckingAccountsRequest(), { isSecure: true, addSession: true });

      if (this.productsResponseMS?.datos?.cuentas) {
        this.productsResponseMS.datos.cuentas.forEach(item => {
          let image = Utils.getUrlCardImage(item.directorioImagen);
          if (image) {
            image = `${this.baseService.getUrlAssets(AssetsType.Accounts)}${image}`;
          }
          item.directorioImagen = Utils.getAccountImage(image);
        });
      }
    }
  }

  private async getUserAccounts(config?: IUserProductsConfig) {
    if (config?.reload) {
      let request: GetCheckAccountsRequest;
      if(config?.isHome) {
        request = new GetCheckAccountsRequest({
          IdSession: this.storageService.getSession(),
          MedioAcceso: Constants.ACCESS_METHOD_PORTAL,
          OrigenConsulta: ProductConstants.ORIGIN_CONSULT_DATA,
          IpCliente: this.sentinelService.sentinel.ip
        });
      } else {
        request = new GetCheckAccountsRequest({
          IdSession: this.storageService.getSession(),
          MedioAcceso: Constants.ACCESS_METHOD_PORTAL,
          IpCliente: this.sentinelService.sentinel.ip
        });
      }
      if (config?.accountOptions?.operation && config?.accountOptions?.transactionType) {
        request.data.TipoTransaccion = config.accountOptions.transactionType;
        request.data.Transaccion = config.accountOptions.operation;
      }
      this.productsResponse = await this.baseService.genericPost<ICheckingAccountsResponse>(request, { isSOA: true });
      if (this.productsResponse?.Debito?.CuentaCheques) {
        this.productsResponse.Debito.CuentaCheques.forEach(item => item.CardFrontImageUrl = Utils.getAccountImage(item.CardFrontImageUrl));
      }

      if (this.productsResponse?.Debito?.segCuentaDepositoInf) {
        this.productsResponse.Debito.segCuentaDepositoInf.forEach(item => item.CardFrontImageUrl = Utils.getAccountImage(item.CardFrontImageUrl));
      }

      if (this.productsResponse?.Debito?.Inversiones) {
        this.productsResponse.Debito.Inversiones.forEach(item => item.CardFrontImageUrl = Utils.getAccountImage(item.CardFrontImageUrl));
      }

      if (this.productsResponse?.Debito) {
        this.pointsDebitCard(this.productsResponse, this.loyaltyService);
      }

      this.validateCreditAccess();
    }
  }

  private validateCreditAccess() {
    const payrollExistAdvance = (environment.hiddenComponents.payrollAdvance || this.productsResponse?.Debito?.TieneAnticipoNomina);
    const payrollExistCredit = (environment.hiddenComponents.payrollCredit || this.productsResponse?.Debito?.TieneCreditoNomina);
    this.payrollDataService.payrollAdvanceData = { ...this.payrollDataService.payrollAdvanceData, payrollExist: payrollExistAdvance };
    this.payrollDataService.payrollCreditData = { ...this.payrollDataService.payrollCreditData, payrollExist: payrollExistCredit };
  }

  async checkAvailableProducts(config: IUserProductsConfig = { reload: true }) {
    if (config?.reload || !this.productsPLResponse) {
      const request: ProductsPLRequest = new ProductsPLRequest({ IdSession: this.storageService.getSession() });
      this.productsPLResponse = await this.baseService.genericPost<IProductsPLResponse>(request, { isSOA: true, isSecure: true });
      this.aforeDataService.showAddProduct = !(this.productsPLResponse?.TieneAfore);
      this.loyaltyService.isAvailable = this.loyaltyService.isAvailable || this.productsPLResponse.TieneTDC;
    }
    return this.productsPLResponse;
  }

  async verifyAccountClient() {
    if (!this.verifyResponse) {
      const response = await this.baseService.genericGet<GenericResponse<VerifyAccountModel>>(new VerifyAccountsRequest(), { isSecure: true, addSession: true });
      this.verifyResponse = new VerifyAccountResponse(response.datos);
      this.loyaltyService.isAvailable = this.loyaltyService.isAvailable || this.verifyResponse.hasCreditCards;
      return this.verifyResponse;
    }
    return null;
  }

  async getUserProducts(accountCheckInfo: ProductAccountResponse, verifyAccount: VerifyAccountResponse) {
    this.creditCardStatusService.account = { tdc: accountCheckInfo.datos.tdc };
    if (verifyAccount?.hasInvestments && accountCheckInfo.datos?.plazos?.length) {
      accountCheckInfo.datos.plazos = accountCheckInfo.datos.plazos.map(this.formatInvestment);
    }
    if (verifyAccount?.hasAccountInvestments && accountCheckInfo.datos?.inversiones?.length) {
      accountCheckInfo.datos.cuentas = [...accountCheckInfo.datos.cuentas, ...accountCheckInfo.datos.inversiones];
    }
    accountCheckInfo.datos.cuentas.forEach(item => {
      item.directorioImagen = `${this.baseService.getUrlAssets(AssetsType.Accounts)}${Utils.getUrlCardImage(item.directorioImagen)}`;
      if (item.directorioImagen == null || item.directorioImagen.indexOf(Resources.NULL_IMAGE_URL) !== Constants.NOT_FOUND_ITEM) {
        item.directorioImagen = Resources.ASSETS_GENERIC_DEBIT_CARD();
      } else {
        item.directorioImagen = `${Resources.PATH_CDN}${item.directorioImagen}`;
      }
    });

    if (accountCheckInfo.datos.tdc) {
      accountCheckInfo.datos.tdc.forEach(item => {
        if (item.directorioImagen == null || item.directorioImagen.indexOf(Resources.NULL_IMAGE_URL) !== Constants.NOT_FOUND_ITEM) {
          item.directorioImagen = Resources.ASSETS_GENERIC_CREDIT_CARD();
        } else {
          item.directorioImagen = `${Resources.PATH_CDN}${item.directorioImagen}`;
        }
      });
    }
  }
  public set investmentAccounts(data: Array<InvestmentAccountsData>) {
    this.investmentAccountsData  = data;
  }

  public get investmentAccounts() {
    return this.investmentAccountsData;
  }

  public set checkInvestments(data: CheckInvestmentsResponse) {
    this.checkInvestmentsResponse  = data;
  }

  public get checkInvestments() {
    return this.checkInvestmentsResponse;
  }

  public set productResponse(data: ICheckingAccountsResponse) {
    this.productsResponse  = data;
  }

  public get productResponse() {
    return this.productsResponse;
  }

  public set firstAccountConsult(data: boolean) {
    this.firstAccountConsultValue = data;
  }

  public get firstAccountConsult() {
    return this.firstAccountConsultValue;
  }

  public set isOtherOperation(data: boolean) {
    this.isOtherOperationValue = data;
  }

  public get isOtherOperation() {
    return this.isOtherOperationValue;
  }

  public set isCatchOnTabs(data: boolean) {
    this._isCatchOnTabs = data;
  }

  public get isCatchOnTabs() {
    return this._isCatchOnTabs;
  }
}
