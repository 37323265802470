import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { ISimpleValue } from '../interface/ISimpleValue';

@Injectable({
  providedIn: 'root'
})

export class InvestmentService {
  private readonly _status: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private _hasInvestment: boolean = false;
  private _goBackTab:boolean = false;

  constructor() {}

  get hasInvestment(){
    return this._hasInvestment;
  }

  private set hasInvestment(data:boolean){
    this._hasInvestment = data;
  }

  setHasInvestment(data:boolean){
    this._hasInvestment = data;
  }

  get goBackTab(){
    return this._goBackTab;
  }

  private set goBackTab(data:boolean){
    this._goBackTab = data;
  }

  setGoBackTab(data:boolean){
    this._goBackTab = data;
  }

  get status$(): Observable<ISimpleValue> {
    return this._status.asObservable();
  }

  get status(): ISimpleValue {
    return this._status.getValue();
  }

  set status(data: ISimpleValue) {
    this._status.next(data);
  }
}
