import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SubscriptionLike, combineLatestWith, filter, map } from 'rxjs';
import { Alert } from 'src/app/interface/Alert';
import { ClarificationList, FacepassList, HistoryList, LoyaltyPointsList, PortabilityList, ProgrammedOperationsList, SearchList } from 'src/app/interface/ListSiderbar';
import { AlertService } from 'src/app/services/alert.service';
import { FacePassLoginService } from 'src/app/services/face-pass-login.service';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { NotifyRouteService } from 'src/app/services/notify-route.service';
import { SidebarResponsiveService } from 'src/app/services/sidebar-responsive.service';
import { StorageService } from 'src/app/services/storage.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { SidebarService } from 'src/app/shared/sidebar.service';
import { Char, ClassHTML, Constants, HtmlEvent, InputTypes, Numbers, Regex } from 'src/core/constants/Constants';
import { Keys } from 'src/core/constants/Keys';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('alertContainer') alertContainer: ElementRef<HTMLInputElement>;
  @ViewChild('searchInput') searchInput: ElementRef;
  isCustomAlert: boolean = false;
  sideBar: boolean = false;
  isDesktop: boolean = true;
  alert: Alert = {
    type: Strings.EMPTY,
    class: ClassHTML.AlertClass,
    iconHeader: Resources.SHAPE_WARNING,
    message: Strings.ALERTS.LoginAlert,
    icon: Resources.CANCEL
  };
  optionsSearch: typeof SearchList = SearchList;
  search: string = Resources.SEARCH;
  referenceLength: number = Constants.REFERENCE_LENGTH;
  placeholder: string = Constants.SEARCH_LABEL;
  showSearches: boolean = false;
  regexSearch: RegExp = Regex.OnlyText;
  withoutResult: string = Strings.WITHOUT_RESULTS;
  loyaltyServiceSL: SubscriptionLike;
  completeOptions: typeof SearchList;

  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(
      this.notifyRouteService.switchRouteObservable,
      this.alertService.isClose$,
      this.sidebarService.stateObservable
    ),
    map(([breakpoints, switchRoute, alertClose, isSidebarClosed]) => {
      this.isDesktop = breakpoints.desktop;
      if (switchRoute && !this.isCustomAlert) {
        this.isCustomAlert = true;
        this.alertService.alert = this.alert;
      }
      if (this.alertContainer && breakpoints.desktop) {
        this.alertContainer.nativeElement.scrollIntoView();
      } else if (this.alertContainer) {
        window.scrollTo(Numbers.Zero, Numbers.Zero);
      }
      return { breakpoints, switchRoute: switchRoute?.value, alertClose, isSidebarClosed };
    })
  );

  logo: string = Resources.LOGO;
  toggleMobile: string = Resources.ALIGN_HORIZONTAL_LEFT_DARK;
  toggleDesktop: string = Resources.ALIGN_HORIZONTAL_LEFT_LIGH;
  selectedOptionIndex = -1;

  constructor(
    private readonly router: Router,
    private readonly notifyRouteService: NotifyRouteService,
    private readonly alertService: AlertService,
    private readonly sidebarService: SidebarService,
    private readonly responsiveService: ResponsiveService,
    private readonly sidebarResponsiveService: SidebarResponsiveService,
    private readonly loyaltyService: LoyaltyService,
    private readonly storageService: StorageService,
    private readonly facePassLoginService: FacePassLoginService
  ) { }

  ngOnInit(): void {
    const root = document.documentElement;
    root.style.setProperty(ModalConstants.OFFSSET.Name, ModalConstants.OFFSSET.SidebarOpen);
    this.loyaltyServiceSL = this.loyaltyService.isAvailable$.subscribe((isAvailable) => {
      if (isAvailable) {
        this.optionsSearch = this.optionsSearch.concat(LoyaltyPointsList);
      }
    });
    this.optionsSearch = this.facePassLoginService.isFacePassLogin.value ? this.optionsSearch.concat(FacepassList) : this.optionsSearch;
    this.optionsSearch = this.storageService.getPortabilityOption() ? this.optionsSearch.concat(PortabilityList) : this.optionsSearch;
    this.optionsSearch = !environment.hiddenComponents.history? this.optionsSearch.concat(HistoryList): this.optionsSearch;
    this.optionsSearch = !environment.hiddenComponents.programmedOperations? this.optionsSearch.concat(ProgrammedOperationsList) : this.optionsSearch;
    this.optionsSearch = !environment.hiddenComponents.clarifications ? this.optionsSearch.concat(ClarificationList): this.optionsSearch;
    this.completeOptions = this.optionsSearch;
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        const actualRoute = (event as NavigationEnd).url;
        this.notifyRouteService.switchRouteData = {
          value:
            actualRoute.includes(PATH.Login) || actualRoute === Char.Slash,
        };
      });
      document.addEventListener(HtmlEvent.MouseDown, (event) =>this.optionListDisplay(event, HtmlEvent.MouseUp));
  }

  toggleMenu() {
    if (this.isDesktop) {
      document.body.classList.toggle(ClassHTML.SidebarToggled);
      this.sideBar = !this.sideBar;
      this.sidebarService.stateData = this.sideBar;
      const root = document.documentElement;
      root.style.setProperty(ModalConstants.OFFSSET.Name, this.sideBar ? ModalConstants.OFFSSET.SidebarClose : ModalConstants.OFFSSET.SidebarOpen);
    } else {
      this.sidebarResponsiveService.stateData = !this.sidebarResponsiveService.subjectVal;
    }
  }

  navigate(dataSearch: string){
    if(dataSearch){
      dataSearch = Utils.removeAccents(dataSearch).gfiCapitalize(Strings.EMPTY);
      const data = this.completeOptions.find((element) => Utils.removeAccents(element.option).gfiCapitalize(Strings.EMPTY) === dataSearch);
      if(data.path && this.router.url !== data.path){
        this.router.navigate([data.path]);
        this.sidebarService.clearData(this.router.url);
      }
      this.showSearches= false;
      this.searchInput.nativeElement.value = Strings.EMPTY;
    }
  }

  filterOperation(data: string){
    if(data){
      data = Utils.removeAccents(data);
      this.optionsSearch = this.completeOptions.filter((val) => Utils.removeAccents(val.option.toLowerCase()).includes(data.toLowerCase()) === true );
      this.showSearches = true;
    }else{
      this.showSearches= false;
    }
    this.selectedOptionIndex = -1;
  }

  setOption(dataSearch: string) {
    this.searchInput.nativeElement.value = dataSearch;
    this.showSearches = false;
    this.navigate(dataSearch);
  }

  ngOnDestroy(){
    this.loyaltyServiceSL?.unsubscribe();
    document.removeEventListener(HtmlEvent.MouseDown, this.optionListDisplay);
  }

  optionListDisplay(event?: Event, typeEvent?: string) {
    const container = document.getElementById(InputTypes.searchContainer);
    const optionsList = document.getElementById(InputTypes.listSearch);
    if (optionsList) {
      optionsList.style.display = container.contains(event.target as HTMLElement) ? InputTypes.Block : InputTypes.None;
      if (optionsList.style.display === InputTypes.None && typeEvent === HtmlEvent.MouseUp) {
        const input = document.getElementById(InputTypes.searchInput) as HTMLInputElement;
        input.value = Strings.EMPTY;
      }
    }
  }

  handleKeydown(event: KeyboardEvent) {
    switch (event.key) {
      case Keys.KEY_DOWN:
        this.setSelectedOptionIndex(this.selectedOptionIndex + 1);
        break;
      case Keys.KEY_UP:
        this.setSelectedOptionIndex(this.selectedOptionIndex - 1);
        break;
      case Keys.KEY_ENTER:
        if (this.selectedOptionIndex >= 0 && this.selectedOptionIndex < this.optionsSearch.length) {
          this.setOption(this.optionsSearch[this.selectedOptionIndex].option);
        }
        break;
    }
  }

  setSelectedOptionIndex(index: number) {
    if (index >= -1 && index < this.optionsSearch.length) {
      this.selectedOptionIndex = index;
    }
  }
}
