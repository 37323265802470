<ng-container *ngIf="vm$ | async as vm">
  <content-wrapper
    [contentMargin]="contentMargin"
    [useFlex]="!vm.breakpoints.mobile"
  >
    <div *ngIf="typeAccount" content class="width-content">
      <ng-container *ngIf="!vm.breakpoints.mobile">
        <app-header-transfer-card
          [title]="title"
          [subtitle]="subtitle"
          [urlLocation]="homePath"
          [returnFn]="return"
        ></app-header-transfer-card>
      </ng-container>
      <ng-container *ngIf="vm.breakpoints.mobile">
        <app-header-transfer-card
          [title]="title"
          subtitle=""
          [urlLocation]="homePath"
          [returnFn]="return"
        ></app-header-transfer-card>
      </ng-container>
      <div
        id="main-content"
        [ngClass]="vm.breakpoints.mobile ? '' : 'content-margin'"
      >
        <div>
          <app-enter-amount label="¿Cuánto vas a retirar?"
            [minAmount]="account?.saldos?.disponible !== 0 ? minAmount : null"
            [maxAmount]="account?.saldos?.disponible"
            [noBalance]="true"
            [noBalanceMessage]="noBalanceMessage">
          </app-enter-amount>
          <div class="detail-m mt-2">
            {{waitingTime}}
          </div>
          <card class="card-height" label="Cuenta origen">
            <div card-content class="card-content">
              <ng-container *ngIf="vm.breakpoints.desktop">
                <div class="align-self-center">
                  <img
                    width="56"
                    height="38"
                    [src]="account.directorioImagen"
                    alt="Imagen Cuenta"
                  />
                </div>
              </ng-container>
              <div
                class="d-flex flex-column detail-s align-self-center default-color"
              >
                <span>{{account.tipoCuenta}}</span>
                <span>{{numberAccount | maskAccount: 4}}</span>
              </div>
              <div class="d-flex flex-column header-xs align-self-center">
                <span class="default-color align-self-end">{{account?.saldos?.disponible | currency}}</span>
                <span class="detail-s align-self-end"> Disponible </span>
              </div>
            </div>
          </card>
        </div>
        <div>
          <div class="header-xs default-color my-3">A tu cuenta</div>
          <app-card-account [card]="card"></app-card-account>
          <div [ngClass]="vm.breakpoints.desktop ? 'mt-2' : ''">
            <app-enter-date
              head="Fecha de depósito"
              headClasses="mt-3 mb-2 pb-1"
              labelInput="Fecha"
              [minDate]="minDate"
              [maxDate]="maxDate"
              [inputState]="false"
              [disabled]="false"
              [customDate]="date"
              (outputDate)="dateEvent = $event"
            ></app-enter-date>
          </div>
        </div>
      </div>
      <div
        class="btn-container d-flex justify-content-center"
        [ngClass]="vm.breakpoints.mobile ? buttonsSpacing.mobile : buttonsSpacing.desktop"
      >
        <button
          class="colored-btn btnGeneric"
          (click)="continue()"
          [ngClass]="vm.breakpoints.mobile ? 'button-s' : 'button-m'"
        >
          Continuar
          <img [src]="chevronRightIcon | cdnImage" alt=">" width="auto" height="auto"/>
        </button>
      </div>
    </div>
  </content-wrapper>
</ng-container>
