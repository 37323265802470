import { Injectable } from '@angular/core';
import { Constants, Source } from 'src/core/constants/Constants';
import { Utils } from 'src/core/utils/utils';
import { ModifyOthersBanks } from 'src/app/interface/dto/ModifyOthersBanksRequest';
import { ModifyInternationalAgendaResponse } from 'src/app/interface/dto/ModifyInternationalAgendasResponse';
import { PATH } from 'src/core/constants/Path';
import { ModifyThirdAgendaRequest } from 'src/app/interface/dto/IModifyThirdAgendaRequest';
import { IModifyThirdAgendaResponse } from 'src/app/interface/dto/IModifyThirdAgendaResponse';
import { Strings } from 'src/core/constants/Strings';
import { StorageService } from 'src/app/services/storage.service';
import { FacePassLoginService } from 'src/app/services/face-pass-login.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { CardAddresseeService } from 'src/app/services/card-addressee.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ModifyAgendaResponse } from 'src/app/interface/dto/ModifyServicesAgendasResponse';
import { ModifyAgendaRequest } from 'src/app/interface/dto/ModifyServicesAgendasRequest';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { SentinelService } from 'src/app/services/sentinel.service';
import { IServicePayId } from 'src/app/interface/IServicePayId';
import { ModifyDebitAccountAliasResponse } from 'src/app/interface/dto/ModifyDebitAccountAliasResponse';
import { ModifyDebitAccountAliasRequest } from 'src/app/interface/dto/ModifyDebitAccountAliasRequest';
import { ModifyCreditCardAliasRequest } from 'src/app/interface/dto/ModifyCreditCardAliasRequest';
import { ModifyCreditCardAliasResponse } from 'src/app/interface/dto/ModifyCreditCardAliasResponse';


@Injectable({
  providedIn: 'root'
})
export class ModifyDailyLimitService {

  private readonly servicesPath: string = PATH.Services;

  constructor(
    private readonly storageService: StorageService,
    private readonly facePassLogin: FacePassLoginService,
    private readonly baseService: IBaseService,
    private readonly cardAddresseeService: CardAddresseeService,
    private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly modalService: ModalService,
    private readonly stateServicePayIdService: StateServicePayIdService,
    private readonly sentinelService: SentinelService
  ) { }

  async otherBanks(newLimit: string, otpModify: string) {
    try {
      const sentinelIp = this.sentinelService.sentinel.ip;
      const geolocation = this.storageService.getGeolocation();
      const addressee = this.cardAddresseeService.cardAddressee;
      const request = new ModifyOthersBanks({
        idSesion: this.storageService.getSession(),
        alias: addressee.nombre_alias,
        limiteDiario: Utils.transformAmount(newLimit.toString()),
        email: addressee.email,
        idAgenda: Number(addressee.idAgenda),
        titular: addressee.nombre,
        tipoAcceso: this.facePassLogin.isFacePassLogin.value ? Constants.ACCESS_FACEPASS_TYPE : Constants.ACCESS_TOKEN_TYPE,
        canal: Constants.ACCESS_METHOD_PORTAL,
        ip: sentinelIp,
        Token: {
          OTP: otpModify
        },
        nombreAplicacion: Constants.BANK,
        Latitud: geolocation.latitude,
        Longitud: geolocation.longitude,
        IsAditional: false,
        NumeroCuenta: addressee.clabe,
        MedioAcceso: Constants.ACCESS_METHOD_PORTAL
      });
      await this.baseService.genericPost<ModifyInternationalAgendaResponse>(request, { isSOA: true });
      this.cardAddresseeService.cardAddressee.limite_diario = Utils.transformAmount(newLimit);
      this.router.navigate([PATH.ConfirmExpressTransfer]);
    } catch (error) {
      this.modalService.close();
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT
      }, {
        onSuccess: () => this.modalService.close(),
        onClose: () => this.modalService.close()
      });
    }
  }

  async thirdParty(newLimit: string, otpModify: string) {
    try {
      const sentinelIp = this.sentinelService.sentinel.ip;
      const geolocation = this.storageService.getGeolocation();
      const addressee = this.cardAddresseeService.cardAddressee;
      const request = new ModifyThirdAgendaRequest({
        idAgenda: Number(addressee.idAgenda),
        limite: Number(addressee.limite_diario),
        nuevoLimite: Number(Utils.transformAmount(newLimit)),
        alias: addressee.nombre_alias,
        eMail: addressee.email,
        idSesion: this.storageService.getSession(),
        numeroCuenta: addressee.clabe,
        Token: {
          OTP: otpModify
        },
        tipoAcceso: Constants.ACCESS_TOKEN_TYPE,
        canal: Constants.MOBILE_APP,
        ip: sentinelIp,
        nombreAplicacion: Constants.BANK,
        Latitud: geolocation.latitude.toString(),
        Longitud: geolocation.longitude.toString(),
        IsAditional: false,
        MedioAcceso: Constants.ACCESS_METHOD_PORTAL
      });
      await this.baseService.genericPost<IModifyThirdAgendaResponse>(request, { isSOA: true });
      this.cardAddresseeService.cardAddressee.limite_diario = Utils.transformAmount(newLimit);
      this.router.navigate([PATH.ConfirmExpressTransfer]);
    } catch (error) {
      this.modalService.close();
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT
      }, {
        onSuccess: () => this.modalService.close(),
        onClose: () => this.modalService.close()
      });
    }
  }

  async services(newLimit: number, otpModify: string, idService: string, service: IServicePayId) {
    try {
      const sentinelIp = this.sentinelService.sentinel.ip;
      const geolocation = this.storageService.getGeolocation();
      const request = new ModifyAgendaRequest({
        idSesion: this.storageService.getSession(),
        idAgenda: service.params.idAgenda,
        codigoServicio: service.id,
        alias: service.params.alias,
        nuevoLimiteDiario: newLimit,
        Token: {
          OTP: otpModify
        },
        tipoAcceso: Constants.ACCESS_TOKEN_TYPE,
        canal: Constants.MOBILE_APP,
        ip: sentinelIp,
        nombreAplicacion: Constants.BANK,
        Longitud: geolocation.longitude,
        Latitud: geolocation.latitude,
        IsAditional: false,
        NumeroCuenta: service.params.reference,
        email: Strings.EMPTY,
        MedioAcceso: Constants.ACCESS_METHOD_PORTAL
      });
      await this.baseService.genericPost<ModifyAgendaResponse>(request, { isSOA: true });
      this.stateServicePayIdService.service.params.dailyLimit = newLimit;
      this.router.navigate([PATH.ConfirmAgendableService], { state: { service: idService } });
    } catch (error) {
      this.modalService.close();
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT
      }, {
        onSuccess: () => this.router.navigate([this.servicesPath]),
        onClose: () => this.router.navigate([this.servicesPath])
      });
    }
  }

  async modifyDebitAccountAlias(alias: string, account: number): Promise<ModifyDebitAccountAliasResponse> {
    const request = new ModifyDebitAccountAliasRequest({
      Alias: alias,
      CuentaProducto:account,
      Sesion: this.storageService.getSession()
    });
      return this.baseService.genericPost<ModifyDebitAccountAliasResponse>(request, { isSOA:true});
  }

  async modifyCreditCardAlias(alias: string,  cardNumber: string): Promise<ModifyCreditCardAliasResponse>{
    const request = new ModifyCreditCardAliasRequest({
      CardNumber: cardNumber,
      NickName: alias,
      Source: Source.Infinitus
    });
      return this.baseService.genericPost<ModifyCreditCardAliasResponse>(request,  {
        isSOA: true, urlOptions: [this.storageService.getSession()]
      });
  }
}
