import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { AccountCardService } from 'src/app/services/account-card.service';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { InsurancePolicies } from 'src/app/services/insurance-policies-status.service';
import { ServiceTransferIdService } from 'src/app/services/service-transfer-id.service';
import { ModalService } from 'src/app/shared/modal.service';
import { NotifyClearFormService } from 'src/app/services/notify-clear-form.service';
import { CardAddresseeService } from 'src/app/services/card-addressee.service';
import { ExpressTransferService } from 'src/app/services/express-transfer.service';
import { Utils } from 'src/core/utils/utils';
import { AppBridge } from 'src/core/utils/AppBridge';
import { AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { Router } from '@angular/router';
import { AppNavigation } from 'src/app/models/AppNavigation';
import { ServicePaymentTypeService } from 'src/app/services/service-payment-type.service';
import { GmmInfoService } from 'src/app/services/gmm-info.service';
import { NotifyDateService } from 'src/app/services/notify-date.service';
import { Strings } from 'src/core/constants/Strings';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { Constants, Numbers, Tab } from 'src/core/constants/Constants';
import { PortabilityService } from 'src/app/services/portability.service';
import { ExpressTransferExtraDataService } from 'src/app/services/express-transfer-extra-data.service';
import { DataDeductibleService } from 'src/app/services/data-deductible.service';
import { InsurancesStatusService } from 'src/app/services/insurances-status.service';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import { WebCardLayoutService } from 'src/app/services/web-card-layout.service';
import { NotifyRouteService } from 'src/app/services/notify-route.service';
import { VoluntarySavingsContributionsService } from 'src/app/services/voluntary-savings-contributions.service';
import { ServiceOptionsBankService } from 'src/app/services/service-options-bank.service';
import { ScheduledTransferService } from 'src/app/services/scheduled-transfer.service';
import { SendAnotherDateService } from 'src/app/services/send-another-date.service';
import { AddCreditService } from 'src/app/services/add-credit.service';
import { LocalTaxesService } from 'src/app/services/local-taxes.service';
import { AppUtils } from 'src/core/utils/AppUtils';
import { InvestmentRegisterService } from 'src/app/services/investment-register.service';
import { ConfigurationApp } from 'src/app/services/servicesApp/configuration-app.service';

@Component({
  selector: 'app-error-exception',
  templateUrl: './error-exception.component.html',
  styleUrls: ['./error-exception.component.css']
})
export class ErrorExceptionComponent implements OnInit {
  @Input() showButtons: boolean;
  @Input() mainPage: boolean;
  @Input() errorMessage: string;
  @Input() otherOperation: string;
  @Input() tab: Tab;
  @Input() homePage: string = PATH.Home;
  @Input() titleError: string = Strings.ERROR_SERVICE_GENERIC;
  @Input() classSize:string = Constants.CLASS_ERROR_EXCEPTION_COMPONENT;
  @Input() showIcon: boolean = true;
  @Input() autoWidth: boolean = false;
  @Input() showOtherOperation: boolean = true;
  @Output() navigationOtherOperation =  new EventEmitter<boolean>();
  @Input() closeModal: boolean = true;
  @Input() padding: boolean = true;
  @Input() isScrollTop: boolean = true;
  @Input() cleanAcountService:boolean=true;
  @Input() paddingTopTitle: boolean = true;

  chevronRightIcon: string = Resources.SHAPE_CONTINUE;
  iconClose : string = Resources.CLOSE_ICON_RED;

  constructor(
    private readonly statePayIdService: StateServicePayIdService,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly insurancePolicies: InsurancePolicies,
    private readonly serviceTransferIdService: ServiceTransferIdService,
    private readonly modalService: ModalService,
    private readonly notifyClearFormService: NotifyClearFormService,
    private readonly cardAddresseeService: CardAddresseeService,
    private readonly expressTransferService: ExpressTransferService,
    private readonly router: Router,
    private readonly gmmInfoService : GmmInfoService,
    private readonly servicePaymentTypeService: ServicePaymentTypeService,
    private readonly notifyDateService: NotifyDateService,
    private readonly portabilityService: PortabilityService,
    private readonly expressTransferExtraDataService: ExpressTransferExtraDataService,
    private readonly dataDeductibleService : DataDeductibleService,
    private readonly cardAccountService: AccountCardService,
    private readonly insurancesStatusService: InsurancesStatusService,
    private readonly dynamicFormService: DynamicFormService,
    private readonly formTermService: WebCardLayoutService,
    private readonly notifyRouteService: NotifyRouteService,
    private readonly voluntarySavingsContributionsService: VoluntarySavingsContributionsService,
    private readonly serviceOptionBank: ServiceOptionsBankService,
    private readonly scheduledTransferService: ScheduledTransferService,
    private readonly sendAnotherDate: SendAnotherDateService,
    private readonly localTaxesService: LocalTaxesService,
    private readonly addCreditService: AddCreditService,
    private readonly configurationApp: ConfigurationApp,
    private readonly investmentRegisterService: InvestmentRegisterService
  ) { }

  ngOnInit(): void {
    this.insurancePolicies.clearSelectedPolicies();
  }

  get isAppExternal() {
    return AppUtils.platform.isApp && this.configurationApp.configurationApp?.isExternal;
  }

  async clearForm(){
    this.localTaxesService.deferredPayment = Strings.EMPTY;
    this.localTaxesService.getIFMPromotionCDMXResponse = null;
    this.insurancePolicies.currencySymbol = Strings.EMPTY;
    this.insurancePolicies.newPaymentInsurance?.reset();
    this.insurancesStatusService.cleanData();
    this.notifyAmountService.amount = { amount : Strings.EMPTY };
    this.serviceTransferIdService.clearStateTransferId();
    this.notifyClearFormService.clearFormData = { value: true };
    this.expressTransferService.clearExpressTransferData();
    this.servicePaymentTypeService.clearPaymentType();
    this.notifyDateService.dateStructData = undefined;
    this.portabilityService.clearPortabilityForm();
    this.gmmInfoService.clearGmmInfoService();
    this.expressTransferExtraDataService.clearData();
    this.dataDeductibleService.claimNumber = { claimNumber: Strings.EMPTY };
    this.notifyAmountService.internationalAmount = {internationalAmount: Numbers.Zero};
    this.voluntarySavingsContributionsService.clearData();
    this.voluntarySavingsContributionsService.clearDataAnotherOperation();
    this.cardAddresseeService.cardProcess = false;
    this.serviceOptionBank.optionBankServiceData = { purposePayment: Strings.EMPTY };
    this.cardAddresseeService.transferType = Numbers.Zero;
    this.addCreditService.clearData();
    this.investmentRegisterService.investmentFormData = [];
    this.investmentRegisterService.clearInvestmentObservable();
    this.investmentRegisterService.clearInvestmentRegister();
    if(this.otherOperation !== PATH.NotAgendableServices){
      this.statePayIdService.clearService();
    }
    if(this.otherOperation !== PATH.InvestmentFundsBuy  && this.otherOperation !== PATH.InvestmentFundsSale  && this.otherOperation !== PATH.Cancellations){
     if(this.cleanAcountService){
        this.cardAccountService.clearCardAccount();
     }
      this.cardAddresseeService.clearCardAddressee();
      this.scheduledTransferService.isScheduledProcess = false;
      this.sendAnotherDate.isOtherDate.value = false;
      this.cardAddresseeService.clearData();
    }
    if (this.navigationOtherOperation.observed) {
      this.navigationOtherOperation.emit(true);
    }else{
      if (this.otherOperation === PATH.Home) {
        Utils.navigationError(this.router, {tab:this.tab,notifyRouteService: this.notifyRouteService});
      } else {
        this.router.navigate([this.otherOperation]);
      }
    }
  }

  ngOnDestroy() {
    if(this.closeModal){
      this.modalService.close();
    }
  }

  async goPage(){
    if(AppUtils.platform.isApp && AppUtils.platform.isXamarin){
      const navigationParams: AppNavigation = {
        Data: AppScreen.Home
      };
      await AppBridge.invoke<string>(AppMethod.SendNativeScreen, navigationParams);
    } else {
      if (this.tab === Tab.Afore || this.tab === Tab.Investment || this.tab === Tab.CreditsPayment || this.tab === Tab.Insurance || Tab.CreditCard) {
        this.tab = null;
      }
      if (this.tab) {
        this.notifyRouteService.navigateToTab(this.tab);
      } else {
        this.router.navigate([this.homePage]);
      }
      this.localTaxesService.deferredPayment = Strings.EMPTY;
      this.localTaxesService.getIFMPromotionCDMXResponse = null;
      this.notifyClearFormService.clearObservablesGeneral();
      this.cardAddresseeService.clearCardAddressee();
      this.statePayIdService.clearService();
      this.notifyClearFormService.clearObservablesGeneral();
      this.notifyAmountService.internationalAmount = {internationalAmount: Numbers.Zero};
      this.expressTransferExtraDataService.clearData();
      this.dataDeductibleService.claimNumber = { claimNumber: Strings.EMPTY };
      this.insurancesStatusService.cleanData();
      this.insurancePolicies.currencySymbol = Strings.EMPTY;
      this.dynamicFormService.clearForm();
      this.formTermService.formTermsBs.next(null);
      this.voluntarySavingsContributionsService.clearData();
      this.voluntarySavingsContributionsService.clearDataAnotherOperation();
      this.cardAddresseeService.cardProcess = false;
      this.cardAddresseeService.transferType = Numbers.Zero;
      this.serviceOptionBank.optionBankServiceData = { purposePayment: Strings.EMPTY };
      this.addCreditService.clearData();
    }
  }
}
