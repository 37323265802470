export class TransferStrings {
    static readonly SELECT_SCHEDULE = 'Selecciona la agenda';
    static readonly TRANSFER_MONEY_LOWER_CAPITAL_OPTION = 'Transferir dinero';
    static readonly DETAIL_MOVEMENTS = `Usted puede obtener un comprobante de un pago realizado a través del SPEI,
      expedido por la institución receptora del pago, y que contiene información acerca de la transferencia realizada.
      El CEP contiene el número de serie del certificado de seguridad, la cadena original y un sello digital que permite
       tanto validar su autenticidad como darle certeza al usuario acerca de su pago. Lo podrá descargar tanto en formato de impresión (PDF) como de archivo de datos (XML).`;
    static readonly TITLE_MODAL = 'Modificar Límite';
    static readonly LABEL_INPUT_MODIFY = 'Límite de cuenta eje';
    static readonly PERIODIC_TRANSFER_SELECTS_OPTIONS = {
      DurationValuesObj: [
        {duration:'Semana',value:'D', adjective:'Semanal',realDays:7,descriptionPlural:'Semanas'},
        {duration:'Quincena',value:'Q',adjective:'Quincenal',realDays:15,descriptionPlural:'Quincenas'},
        {duration:'Mes',value:'M',adjective:'Mensual',realDays:30,descriptionPlural:'Meses'},
        {duration:'Año',value:'A',adjective:'Anual',realDays:365,descriptionPlural:'Años'}
      ]
    };
    static readonly WEEK_ADJECTIVE = 'Semanal';
    static readonly ID_END_DATE = 'endDate';
    static readonly ID_INIT_DATE = 'initDate';
    static readonly INIT_DATE_LABEL = 'Fecha inicial';
    static readonly FINAL_DATE_LABEL = 'Fecha final';
    static readonly BACKDROP = 'modal-backdrop';
    static readonly BACKDROP_TEST = 'backdrop-test';
    static readonly TYPE_SCHEDULE_TRANSFER = ['spca','tef'];
    static readonly MODAL_DETAIL_MOVEMENTS = {
      ReferenceNumber: 12345678
    };
    static readonly TRANSACTION_TYPE_FUTURE = 'DoTransferLater';
    static readonly VALUE_DATA = 'data';
    static readonly ERROR_MSG = 'La fecha inicial no puede ser mayor a la fecha final';
    static readonly ERROR_FINAL_DATE = 'La fecha final debe estar dentro del rango permitido de tres meses';
    static readonly ERROR_INITIAL_DATE = 'La fecha inicial debe ser mayor a la fecha actual';
    static readonly ERROR_VALID_DATE = 'Selecciona una fecha válida';
    static readonly OPERATION = 'transferHistory';
    static readonly ACCOUNT_MODIFY = 'account-modify';
    static readonly TRANSFER_ERROR_TOKEN = 'label-error-a-transfer';
    static readonly STYLE_CARD = 'card-account-transfer';
    static readonly FUTURE_TRANSFER = 'futureTransfer';
    static readonly TYPE_TRANSFER ={
      Tef: 'TEF aplica 24 hrs hábiles posteriores',
      Spei:'SPEI aplica el mismo día'
    };

    static readonly SCROLL = 'scroll';
    static readonly NAME_PAYMENT_RECEIPT_SCHEDULED = 'Comprobante_transferencia_programada';
    static readonly ACCOUNTS_WITH_TRANSACTION_FEE  = ['BASICA','CHEQ4100','CTADWM','DORADA','EFE','EFEAF','EFECB',
    'EFED','EFEINB','EFEN','INBURCAN','NWM','PAHORRA','SANBORNS','WALMART'];
    static readonly NAME_FILE_BROKERAGE_HOUSE = 'Comprobante_transferencia_casa_de_bolsa';
  }
