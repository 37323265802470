import { Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatestWith, map } from 'rxjs';
import { ModalOptions } from 'src/app/interface/modal-options';
import { SERVICES_CONTRACT_OPTIONS } from 'src/app/models/ServicesContractOptions';
import { PayrollDataService } from 'src/app/services/payroll-data.service';
import { IServicesContract } from 'src/app/interface/ServiceContract';
import { CardModalComponent } from 'src/app/shared/components/card-modal/card-modal.component';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { SidebarService } from 'src/app/shared/sidebar.service';
import { Constants, Position } from 'src/core/constants/Constants';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { ModalPwaPayrollCreditComponent } from 'src/app/component/modal-pwa-payroll-credit/modal-pwa-payroll-credit.component';
import { LoaderService } from 'src/app/services/loader.service';
import { PATH } from 'src/core/constants/Path';
import { environment } from 'src/environments/environment';
import { Numbers } from 'src/core/constants/Numbers';


@Component({
  selector: 'app-services-contract',
  templateUrl: './services-contract.component.html',
  styleUrls: ['./services-contract.component.css']
})
export class ServicesContractComponent implements OnInit {

  vm$ = this.sidebarService.stateObservable.pipe(
    combineLatestWith(
      this.responsiveService.observe),
    map(([sidebar, breakpoints]) => ({sidebar, breakpoints}))
  );

  isDeviceMT: boolean = false;
  dataService: IServicesContract = SERVICES_CONTRACT_OPTIONS;
  accountOperations ={ transactionType: Strings.EMPTY};

  constructor(private readonly sidebarService: SidebarService,
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly payrollDataService: PayrollDataService,
    private readonly router: Router,
    private readonly loaderService: LoaderService) { }

  async ngOnInit() {
    if(environment.hiddenComponents.expressLifePwa){
      this.dataService = {
        dataContract: this.dataService.dataContract.slice(Position.Zero, this.dataService.dataContract.length-Numbers.One),
        dataContractMobile: this.dataService.dataContractMobile.slice(Position.Zero, this.dataService.dataContract.length-Numbers.One)
      };
    }
    this.isTabletMobile();
    if (!this.payrollDataService.payrollAdvanceData.payrollAccountExist && !this.payrollDataService.payrollCreditData.payrollAccountExist) {
      Utils.navigationError(this.router);
    } else {
      this.validationsPayrollCard();
    }
  }

  validationsPayrollCard() {
    if ((this.payrollDataService.payrollCreditData.payrollExist || !this.payrollDataService.payrollCreditData.payrollAccountExist)
      && (!this.payrollDataService.payrollAdvanceData.payrollExist && this.payrollDataService.payrollAdvanceData.payrollAccountExist)) {
      this.viewDataCard(Position.One, this.customEndPayrollCard(true));
    }
    if ((this.payrollDataService.payrollAdvanceData.payrollExist || !this.payrollDataService.payrollAdvanceData.payrollAccountExist)
      && (!this.payrollDataService.payrollCreditData.payrollExist && this.payrollDataService.payrollCreditData.payrollAccountExist)) {
      this.viewDataCard(Position.Zero, this.customEndPayrollCard(false));
    } 
  }

  customEndPayrollCard(path:boolean){
    let limit:number;
    if(path){
      limit = environment.hiddenComponents.expressLifePwa ? Position.Two: Position.Three;
    }else{
     limit =  environment.hiddenComponents.expressLifePwa ? Position.One: Position.Two;
    }
    return limit;
  }

  isTabletMobile() {
    const agent = window.navigator.userAgent.toLowerCase();
    if ((window.screen.width <= Constants.RESOLUTIONS.IpadMinWidth && agent.match(Constants.USER_AGENT.Mac)) || (agent.match(Constants.USER_AGENT.MobileApple))) {
      this.isDeviceMT = true;
    } else {
      this.isDeviceMT = false;
    }
  }

  viewDataCard(firstPosition: number, secondPosition: number) {
    this.dataService = {
      dataContract: this.dataService.dataContract.slice(firstPosition, secondPosition),
      dataContractMobile: this.dataService.dataContractMobile.slice(firstPosition, secondPosition)
    };
  }

  showCardModalComponent(content: TemplateRef<CardModalComponent>, id: string) {
    const modalOptions: ModalOptions = {
      ariaLabelledBy: Constants.MODAL_OPTIONS.AriaLabel,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal,
      windowClass: Constants.MODAL_OPTIONS.NoBorder,
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeMd
    };
    this.modalService.open(content, modalOptions);
    this.accountOperations = {transactionType: id};
  }

  @HostListener('window:popstate')
  goBack() {
    this.modalService.close();
  }

  savedAccountEmit(){
    this.loaderService.showLoader();
    const modalOptions: ModalOptions = {
      centered: true,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal
    };
    this.modalService.open(ModalPwaPayrollCreditComponent, modalOptions, this.accountOperations);
  }

  goPage() {
      this.router.navigate([PATH.InsuranceExpress]);
  }
}
