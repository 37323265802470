import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatestWith, map, tap } from 'rxjs';
import { ModifyThirdAgendaRequest } from 'src/app/interface/dto/IModifyThirdAgendaRequest';
import { IModifyThirdAgendaResponse } from 'src/app/interface/dto/IModifyThirdAgendaResponse';
import { ModifyInternationalAgendaRequest } from 'src/app/interface/dto/ModifyInternationalAgendasRequest';
import { ModifyInternationalAgendaResponse } from 'src/app/interface/dto/ModifyInternationalAgendasResponse';
import { ModifyOthersBanks } from 'src/app/interface/dto/ModifyOthersBanksRequest';
import { ModifyAgendaRequest } from 'src/app/interface/dto/ModifyServicesAgendasRequest';
import { ModifyAgendaResponse } from 'src/app/interface/dto/ModifyServicesAgendasResponse';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalDeleteAddresseeComponent } from 'src/app/services-module/components/modal-delete-addressee/modal-delete-addressee.component';
import { AlertService } from 'src/app/services/alert.service';
import { CardAddresseeService } from 'src/app/services/card-addressee.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { AccountModifyService } from 'src/app/services/info-accounts-edit.service';
import { SentinelService } from 'src/app/services/sentinel.service';
import { ServiceCardService } from 'src/app/services/service-card.service';
import { StorageService } from 'src/app/services/storage.service';
import { ModalService } from 'src/app/shared/modal.service';
import { AgendaType, ClassHTML, Constants, Format, InputTypes, Numbers, Position } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { FormModifyAccountComponent } from 'src/app/component/form-modify-account/form-modify-account.component';
import { Resources } from 'src/core/constants/Resources';
import { ModifyNonAgendableRequest } from 'src/app/interface/dto/ModifyServicesNonAgendableRequest';
import { ModifyNonAgendableResponse } from 'src/app/interface/dto/ModifyServicesNonAgendableResponse';
import { FacePassLoginService } from 'src/app/services/face-pass-login.service';
import { ServiceService } from 'src/app/services/services.service';
import { ServiceConstants } from 'src/core/constants/ServiceConstants';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { EnterAmountErrorService } from 'src/app/services/enter-amount-error.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { Router } from '@angular/router';
import { PATH } from 'src/core/constants/Path';
import { AccessType } from 'src/core/constants/AccessType';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';
import { HTMLConstants } from 'src/core/constants/HTMLConstants';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ModalUtils } from 'src/core/utils/ModalUtils';
import { ModalEndProcessAlertComponent } from 'src/app/shared/components/modal-end-process-alert/modal-end-process-alert.component';
import { TransferConstants } from 'src/core/constants/TransferConstants';
import { ConfigurationApp } from 'src/app/services/servicesApp/configuration-app.service';
import { TransferStrings } from 'src/core/constants/TransferStrings';
@Component({
  selector: 'app-modal-modify-account',
  templateUrl: './modal-modify-account.component.html',
  styleUrls: ['./modal-modify-account.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalModifyAccountComponent implements OnInit {
  @ViewChild(Constants.MODAL_OPTIONS.CancelAccount, { static: true }) cancelAccount: ElementRef;
  idModal: string = Constants.MODAL_OPTIONS.CancelAccount;
  headerModal: string = Strings.MODAL_MODIFY_ACCOUNT.Header;
  labelMain: string = Strings.MODAL_MODIFY_ACCOUNT.Label;
  continue: string = Strings.MODAL_MODIFY_ACCOUNT.Continue;
  @ViewChild(Strings.MODAL_MODIFY_ACCOUNT.ConfirmId, { static: true }) confirmCancelAccount: ElementRef;
  idModalConfirm: string = Strings.MODAL_MODIFY_ACCOUNT.ConfirmId;
  labelHeaderConfirm: string = Strings.MODAL_MODIFY_ACCOUNT.ConfirmHeader;
  labelMainConfirm: string = Strings.MODAL_MODIFY_ACCOUNT.ConfirmLabel;
  continueConfirm: string = Strings.MSG_POPUP_ACCEPT;
  @ViewChild('addresseeModal', { static: true }) addresseeModal: ElementRef;
  @ViewChild('token', { static: true }) token: ElementRef;
  @ViewChild(FormModifyAccountComponent) FormModify: FormModifyAccountComponent;
  modalInstance: NgbModalRef;
  otpModify: string;
  codeType?: number;
  closeToken: NgbModalRef;
  send$: BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);
  redirectToHome: boolean = true;
  isButtonDisabled: boolean = true;
  modalData: string = Strings.EMPTY;
  modifyType: string = Strings.EMPTY;

  vm$ = this.accountModifyService.account$.pipe(
    combineLatestWith(this.send$),
    tap(([accountInformation]) => {
      this.codeType = accountInformation.type;
    }),
    map(([, send]) => ({
      send
    }))
  );

  closeIcon: string = Resources.CLOSE_CIRCLE_BLUE;
  chevronRightIcon: string = Resources.SHAPE_CONTINUE;
  isFacepassActive: boolean = this.facePassLogin.isFacePassLogin.value;
  accessType: string = this.isFacepassActive ? Constants.ACCESS_FACEPASS_TYPE : Constants.ACCESS_TOKEN_TYPE;
  nonAgendableType: number = ServiceConstants.NON_AGENDABLE_TYPE;
  agendableType: number = ServiceConstants.AGENDABLE_TYPE;
  headerToken: string = Strings.SECURITY_CODE.TitleToken;
  tokenContainerClass: string = ServiceConstants.TOKEN_CONTAINER_CLASS;
  isAppExternal: boolean = this.configurationApp.isAppExternal;
  isTransferButtonDisabled: boolean = false;

  modalModifyAccount = {component: ModalModifyAccountComponent, options:{
    centered: true,
    modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalModifyAccount
  },typeModal: Constants.MODAL_OPTIONS.FormModify
};

  constructor(
    private readonly modalService: ModalService,
    private readonly alertService: AlertService,
    private readonly storageService: StorageService,
    private readonly baseService: IBaseService,
    private readonly configurationApp: ConfigurationApp,
    private readonly accountModifyService: AccountModifyService,
    private readonly serviceCardService: ServiceCardService,
    private readonly cardAddresseeService: CardAddresseeService,
    private readonly sentinelService: SentinelService,
    private readonly stateServicePayIdService: StateServicePayIdService,
    private readonly enterAmountErrorService: EnterAmountErrorService,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly router: Router,
    private readonly facePassLogin: FacePassLoginService,
    private readonly servicesService: ServiceService
  ) { }


  ngOnInit(): void {
    this.send$.next(this.modalData);
    this.modifyType = this.accountModifyService.getModifyType;
    this.cardAddresseeService.goTransfer = false;
    this.isTransferButtonDisabled = this.modifyType === TransferStrings.ACCOUNT_MODIFY;
  }

  updateModify() {
    return this.FormModify.updateInformation();
  }

  async otherOperationModal(){
    this.modalService?.close();
    if(this.codeType === AgendaType.AccountAddress){
      await this.serviceCardService.getInitialData();
    }
    else {
      this.close();
    }
  }

  changeStyleConfirm(idStyle: string) {
    this.send$.next(idStyle);
    if (idStyle === Constants.MODAL_OPTIONS.idStyleModalModifyConfirm) {
      this.removeClassModal(ClassHTML.ClassModalModifyAccount, ClassHTML.ClassModalModifyConfirm);
    }
    if (idStyle === Constants.MODAL_OPTIONS.idStyleModalModify) {
      this.removeClassModal(ClassHTML.ClassModalModifyConfirm, ClassHTML.ClassModalModifyAccount);
    }
    this.modalService.setYesToken(true);
  }

  removeClassModal(classRemove: string, classAdd: string) {
    const classModalConfirm = document.getElementsByClassName(classRemove);
    classModalConfirm[Position.Zero].classList.add(classAdd);
    classModalConfirm[Position.Zero].classList.remove(classRemove);
  }
  deleteAgenda() {
    this.modalInstance.close();
    const modalOptions: ModalOptions = {
      modalDialogClass: ModalConstants.MODAL_OPTIONS.DeleteAgenda,
      size: Constants.MODAL_OPTIONS.SizeXl
    };
    this.modalService.open(
      ModalDeleteAddresseeComponent,
      modalOptions, { formInstance: this.modalInstance }
    );
  }

  showModal(){
    const modalElement = document.getElementById(HTMLConstants.ID_MODAL.ModalContainerAddressee);
    const modalAncestor = modalElement?.parentElement?.parentElement;
    modalAncestor?.classList?.remove(Constants.MODAL_OPTIONS.HiddenContainer);
    if (modalElement && modalElement?.style) {
      modalElement.style.display = InputTypes.Block;
    }
    this.modalService.close();
  }

  public async modifyInternationalAgenda() {
    const tokenType = this.isFacepassActive ? AccessType.Facepass : AccessType.Password;
    const addresseeToModify = this.accountModifyService.account;
    const request = new ModifyInternationalAgendaRequest({
      IdSession: this.storageService.getSession(),
      IdAgenda: Number(addresseeToModify.idAgent),
      TipoAgenda: addresseeToModify.agendaType,
      TipoBeneficiario: addresseeToModify.recipientType,
      TipoToken: tokenType,
      Contrato: Strings.EMPTY,
      Email: addresseeToModify.email,
      Email2: Strings.EMPTY,
      Email3: Strings.EMPTY,
      Alias: addresseeToModify.alias,
      LimiteDiario: Utils.transformAmount(addresseeToModify.maximumLimit),
      TipoAcceso: Constants.ACCESS_TOKEN_TYPE,
      Token: {
        OTP: this.otpModify,
        HAC: Strings.EMPTY,
        NumeroDeSerie: Strings.EMPTY,
        Token: Strings.EMPTY,
        NombreAplicacion: Strings.EMPTY,
        Traza: Strings.EMPTY
      },
      MedioAcceso: Constants.ACCESS_METHOD_PORTAL,
      IpCliente: this.sentinelService.sentinel.ip
    });
    try {
      const response =
        await this.baseService.genericPost<ModifyInternationalAgendaResponse>(request, { isSOA: true, });
        await this.cardAddresseeService.getInternationals();
        const selectedAddressee = this.cardAddresseeService.selectedCardAddressee;
        if (selectedAddressee.idAgenda === addresseeToModify.idAgent) {
          selectedAddressee.limite_diario = request.data.LimiteDiario;
          selectedAddressee.nombre_alias = request.data.Alias;
          selectedAddressee.email = request.data.Email;
          this.cardAddresseeService.cardAddressee = selectedAddressee;
        }

        this.accountModifyService.account.invoice = response.CodigoRetorno;
        this.send$.next(Constants.MODAL_OPTIONS.CompleteModify);
        this.openModal();
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.modalService.close();
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      }, {
        onSuccess: () => this.modalService.close(),
        onClose: () => this.modalService.close()
      });
    }
  }
  public async modifyThirdAgenda(){
    try {
      const addresseeToModify = this.accountModifyService.account;
      const geolocation = this.storageService.getGeolocation();
      const request = new ModifyThirdAgendaRequest ({
        idAgenda: Number(addresseeToModify.idAgent),
        limite: Number(addresseeToModify.limitOld),
        nuevoLimite: Number(Utils.transformAmount(addresseeToModify.maximumLimit)),
        alias: addresseeToModify.alias,
        eMail: addresseeToModify.email,
        idSesion: this.storageService.getSession(),
        numeroCuenta: addresseeToModify.account,
        Token: {
          OTP: this.otpModify
        },
        tipoAcceso: Constants.ACCESS_TOKEN_TYPE,
        canal: Constants.MOBILE_APP,
        ip: this.sentinelService.sentinel.ip,
        nombreAplicacion: Constants.BANK,
        Latitud: geolocation.latitude.toString(),
        Longitud: geolocation.longitude.toString(),
        IsAditional: false,
        MedioAcceso: Constants.ACCESS_METHOD_PORTAL
      });
      await this.baseService.genericPost<IModifyThirdAgendaResponse>(request, { isSOA: true });
      await this.cardAddresseeService.getThirdPartyAccounts();
      const selectedAddressee = this.cardAddresseeService.selectedCardAddressee;
      const amount = parseFloat(Utils.transformAmount(this.notifyAmountService.amount.amount));
      if (selectedAddressee.idAgenda === addresseeToModify.idAgent) {
        selectedAddressee.limite_diario = request.data.nuevoLimite.toString();
        selectedAddressee.nombre_alias = request.data.alias;
        selectedAddressee.email = request.data.eMail;
        this.cardAddresseeService.cardAddressee = selectedAddressee;
        const dailyLimit = parseFloat(selectedAddressee.limite_diario);
        if(amount > dailyLimit){
          this.enterAmountErrorService.amountErrorData = {
            isError: true, errorMsg: Strings.MAX_AMOUNT_ERROR_MSG(Utils.formatValue(dailyLimit.toString(), Format.Currency))};
        } else {
          this.enterAmountErrorService.amountErrorData = { isError: false, errorMsg: Strings.EMPTY };
        }
      }
      this.send$.next(Constants.MODAL_OPTIONS.CompleteModify);
      this.openModal();
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.modalService.close();
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      }, {
        onSuccess: () => this.modalService.close(),
        onClose: () => this.modalService.close()
      });
    }
  }

  private async modifyAgent() {
    try {
      const agendaToModify = this.accountModifyService.account;
      const geolocation = this.storageService.getGeolocation();
      const request = new ModifyAgendaRequest({
          idSesion: this.storageService.getSession(),
          idAgenda: agendaToModify.idAgent,
          codigoServicio: agendaToModify.codeServices,
          alias: agendaToModify.alias,
          nuevoLimiteDiario: parseFloat(Utils.transformAmount(agendaToModify.maximumLimit.toString())),
          Token: {
            OTP: this.otpModify
          },
          tipoAcceso: Constants.ACCESS_TOKEN_TYPE,
          canal: Constants.MOBILE_APP,
          ip: this.sentinelService.sentinel.ip,
          nombreAplicacion: Constants.BANK,
          Longitud: geolocation.longitude,
          Latitud: geolocation.latitude,
          IsAditional: false,
          NumeroCuenta: agendaToModify.account,
          email: Strings.EMPTY,
          MedioAcceso: Constants.ACCESS_METHOD_PORTAL
        });
      const response = await this.baseService.genericPost<ModifyAgendaResponse>(request, { isSOA: true });
      const agendaData = this.stateServicePayIdService.service;
      const amount = parseFloat(Utils.transformAmount(this.notifyAmountService.amount.amount));
      const dailyLimit = parseFloat(agendaToModify.maximumLimit);
      agendaData.params.dailyLimit = response.nuevoLimiteDiario;
      agendaData.params.alias = response.alias;
      agendaToModify.maximumLimit = response.nuevoLimiteDiario.toString();
      agendaToModify.alias = response.alias;
      this.stateServicePayIdService.service = agendaData;
      if(amount > dailyLimit){
        this.enterAmountErrorService.amountErrorData = {
          isError: true, errorMsg: Strings.MAX_AMOUNT_ERROR_MSG(Utils.formatValue(dailyLimit.toString(), Format.Currency))};
      } else {
        this.enterAmountErrorService.amountErrorData = { isError: false, errorMsg: Strings.EMPTY };
      }
        this.accountModifyService.account = {
          ...this.accountModifyService.account,
          alias: response.alias,
          maximumLimit: response.nuevoLimiteDiario.toString()
        };

        this.send$.next(Constants.MODAL_OPTIONS.CompleteModify);
        this.openModal();
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.modalService.close();
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      }, {
        onSuccess: () => this.modalService.close(),
        onClose: () => this.modalService.close()
      });
    }
  }


  private async modifyAgentNonAgendableService() {
    try {
      const dataCard = this.accountModifyService.account;
      const serviceCode = dataCard.codeServices;
      const geolocation = this.storageService.getGeolocation();
      const request = new ModifyNonAgendableRequest({
        sesion: this.storageService.getSession(),
        nuevoLimiteDiario: parseFloat(Utils.transformAmount(dataCard.maximumLimit.toString())),
        codigoServicio: serviceCode,
        nombreAplicacion: Constants.BANK,
        token: null,
        traza: null,
        tipoAcceso: this.accessType,
        canal: Constants.MOBILE_APP,
        ip: this.sentinelService.sentinel.ip,
        Token: {
          OTP: this.otpModify,
          HAC: null,
          SerialNumber: null
        },
        Latitud: geolocation.latitude,
        Longitud: geolocation.longitude,
        IdAdicional: null,
        IdPersonaTitular: this.storageService.getUser().personId
      });
      await this.baseService.genericPost<ModifyNonAgendableResponse>(request, { isSOA: true });
      await this.servicesService.getNonScheduledServices();

      this.accountModifyService.account = {
        ...this.accountModifyService.account,
        alias: dataCard.alias,
        maximumLimit: parseFloat(dataCard.maximumLimit).toString()
      };

      this.send$.next(Constants.MODAL_OPTIONS.CompleteModify);
      this.openModal();
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.modalService.close();
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      }, {
        onSuccess: () => {
          this.modalService.close();
        }
      });
    }
  }

  private async modifyOtherBanks() {
    try {
      const dataCard = this.accountModifyService.account;
      const geolocation = this.storageService.getGeolocation();
      const request = new ModifyOthersBanks({
        idSesion: this.storageService.getSession(),
        idAgenda: Number(dataCard.idAgent),
        email: dataCard.email,
        alias: dataCard.alias,
        limiteDiario: Utils.transformAmount(dataCard.maximumLimit),
        tipoAcceso: Constants.ACCESS_TOKEN_TYPE,
        Token: {
          OTP: this.otpModify
        },
        canal: Constants.ACCESS_METHOD_PORTAL,
        ip:  this.sentinelService.sentinel.ip,
        IsAditional:  false,
        Latitud: geolocation.latitude,
        Longitud:  geolocation.longitude,
        nombreAplicacion:  Constants.BANK,
        NumeroCuenta:  dataCard.account,
        titular: this.accountModifyService.account.codeServices === ServiceConstants.CREDIT_OTHER_BANKS_ID ? dataCard.holder: dataCard.name,
        MedioAcceso: Constants.ACCESS_METHOD_PORTAL
      });
      const response =
        await this.baseService.genericPost<ModifyInternationalAgendaResponse>(request, { isSOA: true, });
        await this.cardAddresseeService.getTransferAddressees();
        const selectedAddressee = this.cardAddresseeService.selectedCardAddressee;
        const amount = parseFloat(Utils.transformAmount(this.notifyAmountService.amount.amount));
        if (selectedAddressee.idAgenda === dataCard.idAgent) {
          selectedAddressee.limite_diario = request.data.limiteDiario;
          selectedAddressee.nombre_alias = request.data.alias;
          selectedAddressee.email = request.data.email;
          this.cardAddresseeService.cardAddressee = selectedAddressee;
          const dailyLimit = parseFloat(selectedAddressee.limite_diario);
          if(amount > dailyLimit){
            this.enterAmountErrorService.amountErrorData = {
              isError: true, errorMsg: Strings.MAX_AMOUNT_ERROR_MSG(Utils.formatValue(dailyLimit.toString(), Format.Currency))};
          } else {
            this.enterAmountErrorService.amountErrorData = { isError: false, errorMsg: Strings.EMPTY };
          }
        }
        this.accountModifyService.account.invoice = response.CodigoRetorno;
        this.openModal();
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.modalService.close();
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false

      }, {
        onSuccess: () => this.modalService.close(),
        onClose: () => this.modalService.close()
      });
    }
  }


  closeModal($event: string) {
    const data = $event;
    if (data === Constants.MODAL_OPTIONS.LabelConfirm) {
      this.modalService.close();
      this.modalService.open(this.confirmCancelAccount, { windowClass: Constants.MODAL_OPTIONS.FrameGeneralException });
    } else if (data === Constants.MODAL_OPTIONS.CloseModal) {
      if(this.redirectToHome){
        this.router.navigate([PATH.Home]);
      }
      this.modalService.close();
    } else if (data === Constants.MODAL_OPTIONS.CancelAccount) {
      const modalOptions: ModalOptions = {
        ariaLabelledBy: Constants.MODAL_OPTIONS.AriaLabel,
        windowClass: ModalConstants.MODAL_OPTIONS.WindowsClassCardModal,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.WindowsClassCardModalWide,
        size: Constants.MODAL_OPTIONS.SizeLg,
        centered: true
      };
      this.modalService.close();
      this.modalService.open(this.addresseeModal, modalOptions);
    }
    else if (data === ServiceConstants.NAVIGATE_HOME) {
      this.modalService.close();
      Utils.navigationError(this.router);
    }else{
      this.modalService.close();
    }
    this.accountModifyService.clearModifyType();
  }

  async getOtpModify(dataForm: string) {
    this.otpModify = dataForm[Constants.OTP_POSITION];
    if (this.otpModify) {
      this.closeToken.close();
    }

    switch (this.codeType) {
      case AgendaType.OthersBanks:
        await this.modifyOtherBanks();
        break;
      case AgendaType.ThirdParties:
        await this.modifyThirdAgenda();
        break;
      case AgendaType.International:
        await this.modifyInternationalAgenda();
        break;
      case AgendaType.AccountAddress:
        if (this.accountModifyService.account.codeServices === ServiceConstants.CREDIT_OTHER_BANKS_ID) {
          await this.modifyOtherBanks();
        } else {
        await this.modifyAgent();
        }
        break;
      case AgendaType.NonAgendableService:
        await this.modifyAgentNonAgendableService();
        break;
      default:
        break;
    }
  }

  async confirmContinue() {
    this.modalInstance.close();
    const limitNew = Number(this.accountModifyService.account.maximumLimit);
    const limitOld = Number(this.accountModifyService.account.limitOld);
    if (limitNew > limitOld || (this.codeType !== AgendaType.ThirdParties && this.codeType !== AgendaType.OthersBanks
      && this.codeType !== AgendaType.International && this.codeType !== AgendaType.NonAgendableService
      && this.codeType !== AgendaType.AccountAddress)) {
      const modalOptions: ModalOptions = {
        modalDialogClass: ModalConstants.MODAL_OPTIONS.DeleteOtp,
        size: Constants.MODAL_OPTIONS.SizeSm,
        centered: true
      };
      this.closeToken = this.modalService.open(this.token, modalOptions);
    }
    else {
      await this.getOtpModify(Strings.EMPTY);
    }
    this.accountModifyService.modifyAccout = {value: false};
  }

  openTokenModal() {
    const modalOptions: ModalOptions = {
      modalDialogClass: ModalConstants.MODAL_OPTIONS.DeleteOtp,
      size: Constants.MODAL_OPTIONS.SizeMd,
      centered: true
    };
    this.closeToken = this.modalService.open(this.token, modalOptions);
    const container = document.getElementById(HTMLConstants.ID_MODAL.ModalContainerModify);
    NavigationUtils.navigateAndEnsureSingleModalDisplay(container, this.closeToken, true);
  }

  openModal() {
    const modalOptions: ModalOptions = {
      centered: true,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ClassModalModifySuccess
    };
    const modalRef = this.modalService.open(ModalModifyAccountComponent, modalOptions, Constants.MODAL_OPTIONS.CompleteModify);
    const container = document.getElementById(HTMLConstants.ID_MODAL.ModalContainerAddressee);
    NavigationUtils.navigateAndEnsureSingleModalDisplay(container, modalRef, true);
  }

  close(option?: string){
    if (option === Numbers.One.toString()) {
      const options: ModalOptions = {
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeSm,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAlertEndProcess
      };
      ModalUtils.showModal(this.modalService, ModalEndProcessAlertComponent, options, TransferConstants.MODAL_CONTENT,TransferConstants.MODIFY_ACCOUNT);
    } else {
      this.modalInstance?.dismiss();
      this.accountModifyService.modifyAccout = {value: false};
      this.accountModifyService.clearModifyType();
    }
  }

  goTransfer(){
    this.accountModifyService.clearModifyType();
    this.cardAddresseeService.goTransfer = true;
  }

}
