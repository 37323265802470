<ng-container *ngIf="vm$ | async as vm">
  <div id="modify-account">
    <div class="d-flex justify-content-end pt-4 pe-4" id="ModalContainerModify"
      *ngIf="(modifyType !== 'account-modify' || vm.send == 'confirm-modify') &&
      this.codeType !== nonAgendableType && this.codeType !== agendableType">
      <img role="button" class="close" aria-label="Close"
      (click)="close((modifyType === 'account-modify' ? '1' : ''))"
      (keydown.enter)="$event.preventDefault()"
      [src]="closeIcon | cdnImage" alt="Close" width="auto" height="auto">
    </div>
      <div class="container pt-4" [ngClass]="{'width-content':vm.send=='form-modify',
      'width-content-form':vm.send!='form-modify'}">
        <div class="row">
          <div class="col-12">
            <div *ngIf="vm.send == 'confirm-modify'">
              <app-confirm-modify-account></app-confirm-modify-account>
            </div>
            <div *ngIf="vm.send == 'form-modify'">
              <app-form-modify-account (isButtonDisabled)="isButtonDisabled = $event"></app-form-modify-account>
            </div>
            <div *ngIf="vm.send == 'complete-modify'">
              <app-complete-modify-account></app-complete-modify-account>
            </div>
          </div>
        </div>
      <div class="mb-4 mt-3">
          <div class="justify-content-center btns " *ngIf="vm.send == 'form-modify'">
            <button class="btn-send" (click)="updateModify() && changeStyleConfirm('confirm-modify')"
              (keydown.enter)="$event.preventDefault()"
              [disabled]="isButtonDisabled" [class.disabled-btn]="isButtonDisabled">
              Continuar <img [src]="chevronRightIcon | cdnImage" alt=">" width="auto" height="auto" /></button>
            <button
              *ngIf="this.codeType !== nonAgendableType && this.codeType !== agendableType"
              class="btn-cancel" (click)="close()" (keydown.enter)="$event.preventDefault()">
              Cancelar</button>
              <button  *ngIf="this.codeType === nonAgendableType || this.codeType === agendableType"
                class="btn btn-cancel" (click)="closeModal(null)" (keydown.enter)="$event.preventDefault()">
                Cancelar
              </button>
          </div>
          <div class="justify-content-center btns " *ngIf="vm.send == 'confirm-modify'">
            <button class="btn-send gap-3 wrap" (click)="confirmContinue()" (keydown.enter)="$event.preventDefault()">
              Confirmar y continuar <img [src]="chevronRightIcon | cdnImage" alt=">" width="auto" height="auto"/>
            </button>
            <button class="btn-cancel" (click)="changeStyleConfirm('form-modify')"
            (keydown.enter)="$event.preventDefault()">
              Modificar</button>
          </div>
          <div class="justify-content-center btns"
          *ngIf="vm.send == 'complete-modify'">
            <button class="btn-send" [hidden]="isAppExternal" (keydown.enter)="$event.preventDefault()"
            *ngIf="!isTransferButtonDisabled; else showButtonTransfer" (click)="closeModal(codeType
            == nonAgendableType? 'navigateHome' : 'CerrarModal')">
            Listo
            </button>
            <button class="btn-cancel" (click)="otherOperationModal()" (keydown.enter)="$event.preventDefault()">
              Otra operación</button>
            <ng-template #showButtonTransfer>
              <button class="btn-send" (click)="goTransfer()">
                Transferir
              </button>
            </ng-template>
          </div>
        </div>
    </div>
  </div>
</ng-container>

<ng-template #cancelAccount>
  <app-modal-exceptions [idModal]="idModal" [headerModal]="headerModal" [labelMain]="labelMain" [continue]="continue"
     (modalCall)="closeModal($event)"></app-modal-exceptions>
</ng-template>

<ng-template #confirmCancelAccount>
  <app-modal-exceptions [idModal]="idModalConfirm" [headerModal]="labelHeaderConfirm" [labelMain]="labelMainConfirm"
    [continue]="continueConfirm" (modalCall)="closeModal($event)"></app-modal-exceptions>
</ng-template>

<ng-template #addresseeModal let-modal>
  <app-card-modal-addressee [modal]="modal"></app-card-modal-addressee>
</ng-template>

<ng-template #token>
  <app-modal-form-code-security [tokenContainerClass]="tokenContainerClass" [headerToken]="headerToken"
    (showConfirmData)="getOtpModify($event)" [modalOpt]="modalModifyAccount" [dataForm]="[]" [isEndProcess]="true"
    [modalInstance]="closeToken"
    classInput="input-token-code-check-book number-token-code text-center form-control input-otp"
    btnClass="btn-form-code-security btn-form-code-security-modal btn-generic">
  </app-modal-form-code-security>
</ng-template>
