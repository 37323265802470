export class HTMLConstants{

  static readonly MODAL_OPTIONS = {
    HiddenModalUtils: 'hidden-modal',
    NgbModalWindow: 'ngb-modal-window',
    NgbModalBackdrop: 'ngb-modal-backdrop'
  };

   static readonly ID_MODAL = {
    ModalModifyAccounts : 'ModalModifyAccounts',
    ModalContainerAddressee : 'ModalContainerAdressee',
    ModalTokenSecurity : 'otp-card-replacement',
    ModalContainerModify: 'ModalContainerModify'
  };

}

export enum ClassHTML {
  WebkitBox = '-webkit-box',
  BoxOrientVertical = 'vertical',
  BreakAll = 'break-all',
  TabsChecks = 'tabsChecks',
  TabsSlider = 'tabsSlider',
  Content = 'content',
  None = 'none',
  CalendarImage = '--bg-calendar-image',
  DisplayNone = 'd-none',
  DisplayBlock = 'd-block',
  Block = 'block',
  PaddingThree = 'p-3',
  ButtonClassDefaultAlertModal= 'btn-w'
}

export enum HTMLElements {
  ResponsiveServiceChecksStyle = '(min-width: 1024px) and (max-width: 1200px)'
}

export enum EventType {
  Click = 'click',
  Focus = 'focus',
  Keydown = 'keydown',
  Change = 'Change'
}

export enum ClassHTMLTabs {
  ScrollProductsUlNoneSidebar = 'scroll-ul-products-none-sidebar'
}

export enum HostnameType {
  IPv4 = 'localhost',
  IPv6=  '[::1]'
}
