export class BureauAlertsConstants {
    static readonly DEBIT_BUREAU_ALERTS = [
        'CT',
        'CTS',
        'CTC',
        'CTRES',
        'BLACK',
        'EFE',
        'EFED',
        'EFECB',
        'EFEINB',
        'EFEAF'
    ];

    static readonly CREDIT_BUREAU_ALERTS = [
        'Inbursa Platinum',
        'Inbursa Black American Express'
    ];

    static readonly TYPE_BUREAU_ALERT = {
        TDC: 'TDC',
        TDD: 'TDD'
    };
    static readonly CARD_OPTIONS_DATA = 'data';
    static readonly BUREAU_ALERT_CREDIT = 'Credito';
    static readonly BUREAU_ALERT_DEBIT = 'Debito';
    static readonly MODAL_BUREAU_ALERT_OTP = 'modal-generic-class modal-sm';
}