export class ModalConstants {
  static readonly MODAL_OPTIONS = {
    ModalTaxDataVerification: 'modal-tax-data-verification',
    ModalTaxDataVerificationRfc: 'modal-tax-data-verification-rfc',
    Auto: 'auto',
    ModalSizeConfirmation: 'modal-size-confirmation centered-modal',
    ModalRequestedRenewal:'requested-renewal centered-modal',
    ModalRequestConfirmation:'request-confirmation',
    ModalWebCard: 'modal-web-card centered-modal',
    ModalClarification: 'modal-clarification no-border centered-modal',
    RedeemLoyaltyPoints: 'loyalty-reedem centered-modal',
    ExceptionPoints: 'exception-points centered-modal',
    ModalModifyAccountFixed: 'modal-modify-account fixed-in-tablet centered-modal',
    ModalModifyAccount: 'modal-modify-account centered-modal',
    OtherServices: 'other-services-modal centered-modal',
    SessionCloseModal: 'session-close-modal centered-modal',
    ModalAlertClass: 'modal-alert centered-modal',
    SelectLoyalty: 'select-loyalty centered-modal centered-modal-info',
    ModalMyCards:'modal-my-cards centered-modal',
    WindowsClassCardModal: 'card-modal-addressee',
    WindowsClassCardModalWide: 'centered-modal-wide',
    ModalAlertClassCT: 'modal-alert',
    DeleteAgenda: 'modal-delete-agenda centered-modal',
    ModalAdditionalCard: 'modal-additional-card centered-modal',
    MyCards: 'modal-my-cards centered-modal',
    ModalCancelationScheduledOperations: 'cancelation-scheduled-opertaions centered-modal',
    CancelPortability: 'cancel-portability centered-modal',
    DeleteOtp: 'modal-delete-agenda-otp centered-modal',
    ModalCancelPortability: 'modal-cancel-portability-otp centered-modal',
    ModalInsurance: 'modal-payment-service centered-modal',
    ModalCtAlertLimitClass: 'modal-alert-limit-ct centered-modal',
    ModalProfileChange: 'modal-profile-change centered-modal',
    ModalContentProfile: 'modal-content-profile centered-modal',
    ConsultDocumentation: 'consult-documentation centered-modal',
    ModalHelpToken: 'modal-help-token centered-modal centered-modal-info',
    ModalHelpSignaturePanel: 'modal-help-signature-panel centered-modal',
    ConfirmPayroll: 'modal-confirm-payroll',
    ModalPayroll: 'modal-conditions-credit',
    ModalReceipt: 'modal-receipt centered-modal',
    ModalSuaLoadFile: 'modal-sua-load-file centered-modal',
    ModalPaymentReceipt: 'modal-receipt border-none centered-modal',
    ModalAlertEndProcess: 'modal-alert-end-process centered-modal',
    GenericClass: 'modal-generic-class centered-modal',
    GenericWindowClass: 'modal-generic-class',
    OtpChecksConfirmation: 'otp-card-replacement-checkbook centered-modal',
    FormCodeSecurityRequest: 'modal-check-request centered-modal',
    ModalVoluntarySavingsType: 'modal-voluntary-savings-type centered-modal',
    MapModal: 'map-modal centered-modal',
    ModalConsultationAfore: 'modal-consultation-afore centered-modal',
    ModalFormClarification: 'modal-form-new-clarification centered-modal',
    ModalCompleteClarification: 'modal-complete-new-clarification centered-modal',
    ModalHelpSignaturePanelCfe: 'modal-help-signature-panel-cfe centered-modal',
    ModalDomiciliationRegister: 'modal-domiciliation-register centered-modal',
    ModalTermsAndConditions: 'modal-terms-and-conditions centered-modal',
    ModalDirectDebitCancelationClass: 'modal-domiciliation centered-modal',
    ModalCancelContinue:' modal-cancel-continue centered-modal',
    ModalCancel:' modal-cancel centered-modal',
    ModalCancelCard:'modal-cancel-card centered-modal',
    ModalBranchesAdminCards: 'map-modal-checks centered-modal',
    ModalOperationBlock:' modal-operation-block centered-modal',
    ModalHideBackdrop: 'modal-hide-backdrop',
    ModalReplaceRequest:' modal-replace-request centered-modal',
    DynamicCvvAlert:'dynamic-cvv-alert centered-modal',
    ModalSendEmail: 'modal-send-email centered-modal',
    ModalAddProduct: 'modal-add-product',
    ErrorAddProduct: 'modal-error-add-product',
    ModalPartialWithdrawals: 'modal-partial-withdrawals',
    ModalAccountInformation: 'modal-account-information centered-modal',
    WebCardInformation: 'web-card-modal centered-modal',
    LocalTaxes: 'modal-local-taxes centered-modal',
    ModalCreditPayroll: 'modal-credit-payroll',
    ModalHelpSerie: 'modal-help-serie centered-modal',
    ModalLeavePage: 'modal-leave-page centered-modal',
    ModalSentFiles: 'modal-sent-files centered-modal',
    ModalNotFile: 'modal-not-file centered-modal',
    AddLoyalty: 'add-loyalty centered-modal',
    TechnicalRequirements: 'technical-requirements centered-modal-wide',
    FormCodeSecurityActivation: 'modal-check-activation centered-modal',
    ChecksActivation: 'modal-confirmation centered-modal',
    ModalAdditionalProfile: 'modal-additional-profile centered-modal-additional-profile',
    ModalContentService: 'frame-no-service modal-content centered-modal',
    GmmModalHelp: 'modal-gmm-help centered-modal',
    CardModal: 'card-modal centered-modal',
    DetailMovements: 'detail-movements centered-modal',
    LoyaltyPromotional: 'loyalty-promotional centered-modal',
    OtpCardReplacement: 'otp-card-replacement centered-modal',
    SuaModalOptions: 'modal-sua no-border centered-modal',
    ModalDirectDebitModifyClass: 'modal-modification-domiciliation',
    IsCentered: 'centered-modal',
    HiddenModal: 'hidden-modal',
    ModalAlertCT: 'centered-modal modal-alert-fix',
    ModalRequerimentsComponent:'modal-requirements',
    ModalRetirementUnemploymentComponent : 'modal-retirement centerd-modal',
    ModalModalityAmountComponent:'modal-modality centered-modal',
    ModalAlertsComponent:'modal-alerts-success',
    CardModalComponent:'card-modal centered-modal',
    ModalUnemploymentWithdrawalRequestComponent:'modal-unemployment centered-modal',
    ModalPartialConfirmComponent:'modal-partial-confirm centered-modal',
    ModalPartialSuccesfulComponent:'modal-partial-succesful centered-modal',
    Error:'modal-error centered-modal',
    ModalExeptionInvestment: 'modal-exception-investment centered-modal',
    ModalHoursOperationInvestment: 'modal-hours-investment centered-modal',
    ModalFacepassManual: 'modal-facepass-manual centered-modal',
    ModalImgCheck: 'modal-img-check centered-modal centered-modal-wide',
    ModalPreviousPaymentSat: 'modal-previous-payment-sat',
    ModalExceptionSat: 'modal-exception-sat',
    ModalDetailPreviousPaymentSat: 'modal-detail-previous-payment-sat',
    ModalChecksConsultation: 'modal-checks-consultation centered-modal',
    ModalConfirmCheckbookDetails: 'modal-confirm-checkbook-details centered-modal',
    MapModalChecks: 'map-modal-checks centered-modal',
    ModalDownloadMovementsComponent: 'modal-movements',
    ModalDownloadMovements: 'modal-movements-download centered-modal',
    ModalVoluntarySavingsClass: 'modal-voluntary-savings-modifications',
    ModalCancelVoluntarySavingsCancel: 'modal-voluntary-savings-cancel centered-modal',
    ModalTransferHistory: 'modal-transfer-history centered-modal',
    modalModifyLimit: 'modal-modify-limit centered-modal',
    ModalDetailMovements: 'modal-detail-movements centered-modal',
    ClassModalModifySuccess: 'modal-modify-adr-confirm centered-modal',
    ModalServiceMovement: 'modal-service-movement centered-modal',
    ModalModifyLimit: 'modal-modify-limit centered-modal',
    ModalManage: 'modal-manage centered-modal',
    ModalRemoveCredit: 'modal-delete-credit centered-modal',
    ModalModifyAccountService: 'modal-modify-account-service centered-modal',
    ModalPaymentContributionsConfirmData: 'modal-payment-contributions-confirmation-data',
    ModalFederalTaxReceiptFilter: 'modal-federal-tax-receipt-filter-component',
    ModalCancelFederalTaxReceipt: 'modal-cancelfederal-tax-receipt',
    ModalCancelFederalTaxReceiptComplete: 'modal-cancelfederal-tax-receipt-complete',
    CardAlertComponent:'card-alert centered-modal',
    AllServices: 'all-services-modal',
    ModalAforeWithdrawalBegins: 'modala-afore-withdrawal-begins centered-modal',
    RequestedRenewal: 'requested-renewal',
    ModalConent: 'modal-content',
    ModalMap: 'modal-map',
    NgbModalBackdrop: 'ngb-modal-backdrop',
    ModalAlerts: 'modal-alerts',
    ModalRetirement: 'modal-retirement',
    RequestConfirmation: 'modal-request-confirmation',
    AforeRegistration: 'modal-afore-registration centered-modal',
    ModalVoluntaryWithdrawal: 'modal-voluntary-withdrawal centered-modal',
    ModalVoluntaryWithdrawalInfo: 'modal-voluntary-withdrawal-info centered-modal',
    ModalConfirmWithdrawal: 'modal-confirm-withdrawal centered-modal',
    OtpWithdrawalConfirmation: 'otp-card-replacement-withdrawal centered-modal',
    PwaModal: 'modal-payroll-credit-pwa centered-modal',
    ActivationCardModal: 'modal-activation-card centered-modal',
    ModalInvestmentClass: 'centered-modal',
    ModalHelpSignaturePanelActivationCard: 'modal-help-signature-panel-activation-card centered-modal',
    SmallSizeAlert: 'small-alert',
    ServicesModal: 'services-modal centered-modal',
    ModalAddInvestment: 'modal-add-investment centered-modal',
    ModalModifyGeneralInfo: 'centered-modal modal-edit-user-info',
    CustomizeIconModal: 'customize-icon-modal centered-modal'
  };


  static readonly SCREEN_NAVIGATION = {
    Selection: 'selection',
    Selected: 'selected'
  };

  static readonly OFFSSET = {
    Name: '--sidebar-offset',
    SidebarOpen: '18rem',
    SidebarClose: '80px'
  };

  static readonly ICON_APP_IMAGES = [
    { name: 'icon_app_blue.png' },
    { name: 'icon_app_blue_purple.png' },
    { name: 'icon_app_purple_pink.png' },
    { name: 'icon_app_green_blue.png' },
    { name: 'icon_app_light_blue_blue.png' },
    { name: 'icon_app_origin.png' },
  ];

  static readonly IGNORE_ELEMENT = 'ignore-element';
}

export enum ModalClasses {
  Modal = '.modal'
}
