import { Numbers } from 'src/core/constants/Numbers';

export class Resources {
    static readonly ALARM_EXPORT = 'alarm_export.svg';
    static readonly ALARM_EXPORT_ACTIVE = 'alarm_export_active.svg';/* alarm_export_contrast.svg */
    static readonly APP_GALERY = 'app_gallery_1.svg';
    static readonly APP_STORE = 'app_store_1.svg';
    static readonly ARROW_DOWN_GREY = 'arrow_down.svg';
    static readonly ATM = 'atm.svg';
    static readonly ATM_BLUE = 'atm_loyalty.svg';
    static readonly CloseCircle = 'cerrarcirculo.svg';
    static readonly ATM_WHITE = 'atm_white.svg';
    static readonly ALIGN_HORIZONTAL_LEFT_DARK = 'align_horizontal_left.svg'; /* Align-Horizontal-Left.svg */
    static readonly ALIGN_HORIZONTAL_LEFT_LIGH = 'menuIcon.svg';
    static readonly BANK_CARD_EJECT = 'bank-card-eject.svg';
    static readonly BANK_CARD_LOCKED = 'bank_card_locked.svg';
    static readonly BANK_CARD_LOCKED_WHITE = 'bank_card_locked_contrast.svg';
    static readonly BANK_CARD = 'bank-card.svg';
    static readonly BANK_CARD_BLACK = 'Bank-Card.png';
    static readonly BANK_CARD_WHITE = 'bank_card.svg';
    static readonly BANK_CARD_OPEN = 'BankCardOpen.svg';
    static readonly BANK_NOTE = 'banknote_02.svg';
    static readonly BANK_NOTE_BLACK = 'bank_note.svg';
    static readonly BANK_CARD_EJECT_WHITE = 'bank_card_eject_white.svg';
    static readonly BANK_CARD_EJECT_BLACK = 'bank_card_eject.svg';
    static readonly CARD_BLUE_BANK = 'bank_card_tp.svg';
    static readonly BARCODE = 'barcode.svg';
    static readonly BENFICIARY = 'beneficiario.svg';
    static readonly DOCUMENT_WHITE = 'box_left.svg';
    static readonly CALENDAR_WHITE = 'calendar-03.svg';
    static readonly CALENDAR_GREY = 'outlined-calendar-03.svg';
    static readonly CALENDAR_BLUE = 'calendar_03.svg';
    static readonly CALENDAR_BLACK = 'calendar_b.svg';
    static readonly CALENDAR_CONTRAST = 'calendar_contrast.svg';
    static readonly CANCEL_MINI = 'cancel_mini.svg';
    static readonly CANCEL = 'cancel.svg';
    static readonly CARD = 'card.svg'; /* debito.svg  Image.png tdc.svg*/
    static readonly CFE = 'cfe.svg';
    static readonly CHAT_DOTS = 'chat_dots_blue.svg';
    static readonly CHECK_MINI = 'check_mini.svg';
    static readonly GREEN_CHECK_FILL = 'check_tp.svg'; /* Check.png  shape_validation.svg*/
    static readonly CHECK = 'check.svg'; /* Checkcr-fr.svg */
    static readonly BTN_CHEVRON_LEFT = 'Chevron-Left-cr-fr.svg';
    static readonly BLUE_CHEVRON_LEFT = 'chevronleft.svg';
    static readonly BTN_CHEVRON_RIGHT = 'Chevron-Right-cr-fr.svg'; /* Chevron Right cr-fr.svg */
    static readonly CHEVRON_RIGHT_CR_FR = 'ChevronRightcr-fr.svg';
    static readonly CHEVRON_RIGHT_SQ_FR = 'ChevronRightsq-fr.svg';
    static readonly CHEVRON_RIGHT_COMPLETE_BLUE = 'chevron-right-cr-fr-blue.svg';
    static readonly CHEVRON_DOWN_COMPLETE_BLUE = 'chevron-down-cr-fr-blue.svg';
    static readonly CLOSE_WHITE_CIRCLE = 'close.svg';
    static readonly CLOSE_WHITE = 'close_w.svg';
    static readonly CODEFLEX = 'codeflex.png';
    static readonly CARD_CODE = 'codigo_carta.svg';
    static readonly EMITTER_CODE = 'codigo_emisor.svg';
    static readonly DOLLAR_WHITE = 'coin_dollar.svg';
    static readonly COINS2 = 'coins_02.svg';
    static readonly CONECT = 'conect.png';
    static readonly COINSURANCE = 'coseguro.svg';
    static readonly CLOSE_RED = 'cr-fr_CerrarColorRojo.svg';
    static readonly CLOSE_ICON_RED = 'cerrar_rojo.svg';
    static readonly PAYROLL_CREDIT_14 = 'creditoNomina-14.svg';
    static readonly PAYROLL_CREDIT_15 = 'creditoNomina-15.svg';
    static readonly CVV_HELP = 'cvv-help.svg';
    static readonly DEDUCTIBLE = 'deducible.svg';
    static readonly ARROW_DOWN_BLACK = 'Despliege.svg';
    static readonly ARROW_UP = 'despliegepreguntas2.svg';
    static readonly ARROW_DOWN = 'despliegepreguntas.svg';
    static readonly DIGITAL_CURRENCY = 'digital-currency.svg';
    static readonly LIGHT_BLUE_ARROW_DOWN = 'down.svg';
    static readonly EDIT_ADMIN = 'edit_admin.svg';
    static readonly EDIT_PASSWORD = 'edit-password.svg';
    static readonly EDIT = 'edit.svg';
    static readonly EXPORT = 'export.svg';
    static readonly EXPORT_WHITE = 'export_white.svg';
    static readonly EXPRESS = 'express.png';
    static readonly FACEPASS = 'facepass.svg';
    static readonly INBURSA_FAVICON = 'favicon_inbursa.svg';
    static readonly FILE_ALERT_B = 'File_Alert_B.SVG';
    static readonly FILE_ALERT_W = 'File_Alert_W.SVG';
    static readonly FILE_COPY_IN_LC = 'File-Copy-in-lc.svg';
    static readonly FILE_EARMARK_CODE = 'file-earmark-code.svg';
    static readonly FILE_EDIT_IN_LC = 'File-Edit-in-lc.svg';
    static readonly FILE_INFO = 'file_info.svg';
    static readonly FILE_TEXT = 'file_text.svg';
    static readonly FILE_CHECK = 'file_check_in_ic.svg';
    static readonly FILE_DOWNLOAD_IN_LC = 'file_downloadin_lc.svg'; /* File Download in-lc.svg  file_download_in_lc.svg  file _download.png*/
    static readonly FILE_INSURANCE = 'file_insurance.svg';
    static readonly FILE_VIEW_IN_LC = 'file_view_in_lc.svg';
    static readonly FILLED = 'filled.svg';
    static readonly MODAL_CLOSE_WHITE = 'frame_1041.svg';
    static readonly DEPOSIT_GREEN = 'Frame-1842.png';
    static readonly FRAME_1842 = 'frame_1842.svg';
    static readonly FRAME = 'frame.svg';
    static readonly GOB_CDMX = 'gob-cdmx.svg';
    static readonly GOB_MEXICO_STATE = 'gob-edomex.svg';
    static readonly GOOGLE_PLAY = 'google_play_1.svg';
    static readonly GRID_FOUR_02 = 'Grid-Four-02.svg';
    static readonly WITHDRAWAL_RED = 'group.svg';
    static readonly WARNING_ICON = 'ico-warning.png';
    static readonly CIRCLE_INFO_LIGHT_BLUE = 'icon_balance.svg';
    static readonly ICON_PENDING_INVALID = 'icon-pending-invalid.svg';
    static readonly INCON_PENDING = 'icon_pending.svg';
    static readonly IAMGE_CARD_ONE = 'image-card-one.svg';
    static readonly CT_PAT_INBURSA_LOGO = 'Imagelogoinbursactpatrimonial.svg';
    static readonly SECURITY_IMAGE = 'imagen-seguridad.svg';
    static readonly AMAZON = 'imagen.png';
    static readonly ICON = 'Imagen.svg';
    static readonly NOT_FOUND = 'not_found.svg';
    static readonly IMG_CARD = 'img_card1.png';
    static readonly NOT_FOUND_NAV = 'img_not_found_nav.png';
    static readonly IMPORT = 'import.svg';
    static readonly IMPORT_WHITE = 'import_white.svg';
    static readonly MOBILE_INBURSA = 'Inbursa_movil_cvs.svg';
    static readonly INBURSA = 'inbursa_original.svg';
    static readonly INFO_WHITE = 'info.svg';
    static readonly LIVERPOOL = 'liverpool.svg';
    static readonly LOCATION = 'location.svg';
    static readonly LOCK_OPEN_WHITE = 'lock_open.svg';
    static readonly LOCK_WHITE = 'lock.svg';
    static readonly CFE_LOGO = 'Logo-CFE.svg';
    static readonly MOBILE_INBURSA_LOGO = 'logo-inbursa-movil.png';
    static readonly LOGO_SIDEBAR = 'logo_sidebar.svg';
    static readonly LOGO = 'logo.svg';
    static readonly CFE_LOGO_1 = 'LogoCFE_image 1.svg';
    static readonly LOGOUT = 'logout.svg';
    static readonly MAIL = 'mail.png';
    static readonly DEFAULT_MAPS = 'maps-default.svg';
    static readonly PLUS_2 = 'mas.svg';
    static readonly MENU_ICON = 'menuIcon.svg';
    static readonly MINUS_SQ_FR = 'Minussq-fr.svg';
    static readonly MINUS = 'minus.svg';
    static readonly MOBILE = 'mobile.svg';
    static readonly MOBILE_WHITE = 'mobile_02.svg';
    static readonly NUMBER = 'numero_carta.svg';
    static readonly ONE_LINK_TO_INBURSA = 'onelinkto_inbursa.svg';
    static readonly ORO_W = 'oro_w.svg';
    static readonly OUTLINED_GROUP = 'outlined-group-1.svg';
    static readonly CARD_PANEL_DESKTOP = 'paneltarjeta_escritorio.svg';
    static readonly CARD_PANEL_MOBILE = 'paneltarjeta_movil.svg';
    static readonly CARD_CVV_DESKTOP = 'cvv_escritorioTablet.png';
    static readonly PROFILE = 'Perfil.svg';
    static readonly PHONE_WHITE = 'phone_voice.svg';
    static readonly PILLS_FORMS = 'pillsforms.svg';
    static readonly PILLS_FORMS_P = 'pillsforms_p.svg';
    static readonly PLUS_WHITE = 'plus_white_cr_fr.svg';
    static readonly PLUS_BLUE_CIRCLE = 'plus_white.svg';
    static readonly PLUS = 'plus.svg';
    static readonly PLUS_CR_FR_2 = 'plusCrFr.svg';
    static readonly PLUS_SQ_FR_BLUE = 'PlusSq-fr.svg';
    static readonly PLUS_SQ_FR_WHITE = 'plusSqFr.svg';
    static readonly PLUS_SQ_FR_TRANSPARENT = 'plus_sq_fr.svg';
    static readonly PRINTER = 'printer.png';
    static readonly QUESTION_MARK_LIGHT_BLUE = 'question_mark.svg'; /* ayuda-icon.svg  QuestionMarkcr-fr.svg  token.svg*/
    static readonly QUESTION_MARK_RED = 'question_mark_cr-fr_red.svg';
    static readonly QR_MODAL_ADDITIONAL_CARD = 'QR-ModalAdditionalCard.svg';
    static readonly REMOVE_SQ_FR = 'remove_sq_fr.svg';
    static readonly REMOVE_FL = 'removefl.svg';
    static readonly CLOSE_CIRCLE_BLUE = 'remove_operation.svg';/* remove_b.png */
    static readonly WHITE_ARROW_LEFT = 'road_portability.svg';
    static readonly ROAD_SIGN_RIGHT_WHITE = 'road-sign-right-white.svg';
    static readonly ROAD_SIGN_RIGHT = 'road_sign_right.svg';
    static readonly SEARCH_BICOLOR = 'search_bicolor.svg';
    static readonly SEARCH = 'search.svg';
    static readonly SEARCH_LIGHT_BLUE = 'search_light_blue.svg';
    static readonly CARD_INSURANCE = 'seguro_auto.svg';
    static readonly FILTER_BLUE = 'settings_adjust_b.svg'; /*'Settings Adjust hr-al.svg'*/
    static readonly FILTER_GREY = 'settings_adjust.svg';
    static readonly SHAPE = 'Shape.svg';
    static readonly SHAPE_A = 'ShapeA.svg';
    static readonly SHAPE_CLOSE = 'Shape_Close.svg';
    static readonly SHAPE_CONTINUE = 'shape_continue.svg'; /**chevron_right.svg chevron_right_tp.svg */
    static readonly SHAPE_VALIDATION = 'shape_validation.svg';
    static readonly SHARE = 'share.png';
    static readonly SHOPPING_BAG = 'shopping-bag.svg';
    static readonly SHOPPING_BAG_BLACK = 'shopping_bag.svg';
    static readonly SHOPPING_BASKET_WHITE = 'shopping_basket.svg';
    static readonly SHOPPING_TAG_WHITE = 'shopping_tag_w.svg';
    static readonly BLUE_SHOPPING_TAG = 'shopping_tag.svg';
    static readonly SHOW_DOCUMENT = 'show_table.svg';
    static readonly SHUTTER_STOCK = 'shutterstock.svg';
    static readonly CLAIM = 'siniestro.svg';
    static readonly CLOSE_SQUARE = 'sq-fr_CerrarCuadrado.svg';
    static readonly TABLET = 'tablet.svg';
    static readonly ICON_TIME_CVV = 'time-icon-cvv.png';
    static readonly WHITE_TOKEN = 'token_white.svg';
    static readonly TRASH = 'trash.svg';
    static readonly VECTOR = 'vector.svg';
    static readonly VIEW_TOKEN_3 = 'view_token3.png';
    static readonly BROWSER_WARNING = 'warning-browser.jpg';
    static readonly GREY_CIRCLE_USER = 'pillsforms_user.svg';
    static readonly FILE_COPY = 'file_copy.svg';
    static readonly FILE_COPY_WHITE = 'file_copy_white.svg';
    static readonly CHEVRON_RIGHT_BLUE = 'chevron_right_b.svg';
    static readonly CHEVRON_RIGHT_LIGHT_BLUE = 'chevron_blue.svg';
    static readonly SHAPE_PLUS_GRAY = 'shape_plus_b.svg';
    static readonly CIRCLE_BLUE_QUESTION = 'question_markcr_fr.svg';
    static readonly TRASH_CAN_WHITE = 'trash_can_white.svg';
    static readonly SHAPE_WARNING = 'shape_warning.svg';
    static readonly TOKEN = 'token.svg';
    static readonly BLUE_COLORED_CARD = 'shape_card.svg';
    static readonly EXCEPTION_FILLED = 'close-cr-fr.svg';
    static readonly BLUE_BELL = 'Bell.svg';
    static readonly LIGHT_BLUE_TRASH = 'trash_can_ligth.svg';
    static readonly CHECK_GREEN_FILL = 'Check_cr-fr_G.svg';
    static readonly CHECK_BLUE_FILL = 'Check_cr-fr_B.svg';
    static readonly WARNING_YELLOW_FILL = 'Warning.svg';
    static readonly GREEN_SHOP_TAG = 'Shopping_Tag.svg';
    static readonly CROSS_RED_FILL = 'Remove_cr-fr.svg';
    static readonly INFO_BLUE_FILL = 'Info_cr-fr.svg';
    static readonly GRID_FOUR_BLUE = 'grid_blue.svg';
    static readonly USER_BLUE = 'user_blue.svg';
    static readonly SQUARE_PLUS_LIGHT_BLUE = 'plus_b.svg';
    static readonly WHITE_MONITOR = 'Monitor.svg';
    static readonly CROSS_WHITE = 'close_sidebar.svg';
    static readonly GRID_FOUR_WHITE = 'grid_four.svg';
    static readonly FILE_EDIT_WHITE = 'file_edit.svg';
    static readonly SQUARE_WHITE_CROSS = 'remove.svg';
    static readonly SIDEBAR_PROMO = 'img_card.png';
    static readonly MAC = 'Mac_Client.svg';
    static readonly CHROME = 'icon _chrome_.svg';
    static readonly EDGE = 'icon _edge_.svg';
    static readonly SAFARI = 'icon _safari ios_.svg';
    static readonly FIREFOX = 'icon _firefox 57 70_.svg';
    static readonly ANDROID = 'Subtract.svg';
    static readonly OPERA = 'icon _opera_.svg';
    static readonly WINDOWS = 'Windows_XP.svg';
    static readonly MOVEMENT_IMG_OUT = 'out.svg';
    static readonly MOVEMENT_IMG_IN = 'in.svg';
    static readonly SHOP = 'Shop.svg';
    static readonly INSURANCE = 'HC_Seguros.png';
    static readonly PAYROLL_CREDIT = 'HC_CreditoNomina.png';
    static readonly PAYROLL_ADVANCE = 'adelanto_nomina.jpg';
    static readonly CREDIT_ACCOUNT = 'HC_TarjetasCredito.png';
    static readonly ICON_INSURANCE = 'seguro_2.svg';
    static readonly ICON_PAYROLL_CREDIT = 'Coins_01.svg';
    static readonly ICON_PAYROLL_ADVANCE = 'Calendar_Favourite_in-lc.svg';
    static readonly ICON_CREDIT_ACCOUNT = 'Bank_Card_Favourite_ou-lc.svg';
    static readonly SEARCH_B = 'search_b.svg';
    static readonly PLUS_CR_FR = 'plus_cr_fr.svg';
    static readonly COIN_DOLAR_B = 'coin_dollar_b.svg';
    static readonly BANK_CARD_PNG = 'bank_card.png';
    static readonly BANK_CARD_BLUE = 'bank_card_ac.svg';
    static readonly IMPLOC = 'IMPLOC.svg';
    static readonly MEDAL = 'medal.svg';
    static readonly MEDAL_GRAY = 'Medal.svg';
    static readonly PLUS_CR_FR_ACENT = 'plus-cr-fr.svg';
    static readonly TRASH_CAN = 'trash-can.svg';
    static readonly HELP_ICON = 'ayuda_icon.svg';
    static readonly TIME_CVV = 'time_cvv.png';
    static readonly REMOVE_CR_FR = 'remove-cr-fr.svg';
    static readonly CHECK_CR_FR = 'check-cr-fr.svg';
    static readonly CHEVRON_RIGHT_CR_B = 'chevron_rightcr_b.svg';
    static readonly CHEVRON_RIGHT_CONSTRAST = 'chevron_right_constrast.svg';
    static readonly MODAL_HELP_TOKEN1 = 'modal_ayuda_token_1.jpg';
    static readonly MODAL_HELP_TOKEN2 = 'modal_ayuda_token_2.jpg';
    static readonly GRID_FOUR_BLUE_FILL = 'grid-four-01.svg';
    static readonly GRID_TWO_FILL = 'grid-two-vr-al.svg';
    static readonly GRID_TWO = 'grid-two-vr-al-2.svg';
    static readonly MAPS_DEFAULT = 'maps_default.svg';
    static readonly IMPGEM = 'IMPGEM.svg';
    static readonly CLOSE_RED_MODAL = 'cerrar_rojo.svg';
    static readonly ADDITIONAL_CONTRIBUTION_WHITE = 'bank_card_insert_contrast.svg';
    static readonly ADDITIONAL_CONTRIBUTION_BLACK = 'bank_card_insert.svg';
    static readonly EXPORT_BLUE = 'export_b.svg';
    static readonly FILE_DOWNLOAD = 'File_Download.svg';
    static readonly FILE_LIST = 'File List.svg';
    static readonly COIN_INSERT = 'coin_insert.svg';
    static readonly COIN_INSERT_CONTRAST = 'coin_insert_contras.svg';
    static readonly CREDIT_BEREAU_INQUIRY = 'https://appassetsgfi.blob.core.windows.net/app-assets-test/helps/credito_nomina/terminos_condiciones_ACNom.html';
    static readonly EXCLAMATION_MARK_BLUE = 'exclamation_mark_blue.svg';
    static readonly EYE_BLUE = 'eye.svg';
    static readonly EYE_BLUE_OFF = 'eye_off.svg';
    static readonly QUESTION_MARK_LIGHT_BLUE_FILLED = 'question_mark_light_blue.svg';
    static readonly WEB_CARD_B = 'New_WEB-vta.png';
    static readonly QUESTION_MARK_CIRCLE_WHITE = 'question_mark_w.svg';
    static readonly PHONE_VOICE_WHITE = 'phone_voice_mobile.svg';
    static readonly HOME_MOBILE_WHITE = 'mobile_w.svg';
    static readonly LOCATION_WHITE = 'location_mobile.svg';
    static readonly LOCATION_MAP = 'location_icono.png';
    static readonly SAFE_WHITE = 'safe.svg';
    static readonly BANK_CARD_SEARCH = 'bank_card_search_r.svg';
    static readonly COIN_DOLLAR = 'coin_dollar_r.svg';
    static readonly FILE_CHART = 'file_chart_column.svg';
    static readonly FILE_SEARCH = 'file_search_r.svg';
    static readonly WALLET = 'wallet_r.svg';
    static readonly LOGOUT_RESPONSIVE = 'logout_r.svg';
    static readonly BANK_CARD_NAVY = 'bank-card-blue.svg';
    static readonly REFRESH = 'refresh_3.svg';
    static readonly EYE_LIGHT = 'eye_light.svg';
    static readonly EYE_OFF_LIGHT = 'eye_off_light.svg';
    static readonly IMPLOC_CREDIT_CARD_PAYMENT = 'icono_list.svg';
    static readonly IMPLOC_WHITE_CREDIT_CARD_PAYMENT = 'icon_list_white.svg';

    //INFO DEVICES TOKEN-FACEPASS
    static readonly JPEG_IMAGE: 'image/jpeg';
    static readonly PNG_IMAGE: 'image/png';
    static readonly USER_IMAGES = {
        Thumbnail: 'perfil.svg',
        ProfileCollection: [
          { value: '/assets/img/perfil/Frame_7236conejo.png' },
          { value: '/assets/img/perfil/Frame_7232pingüino.png' },
          { value: '/assets/img/perfil/Frame_7234flor.png' },
          { value: '/assets/img/perfil/Frame_7235hoja.png' },
          { value: '/assets/img/perfil/Frame_7232Gato.png'},
          { value: '/assets/img/perfil/Frame_7233Perro.png' },
          { value: '/assets/img/perfil/Frame_7234Caballo2.png' },
          { value: '/assets/img/perfil/Frame_7235Oso.png' },
          { value: '/assets/img/perfil/Frame_7232Pantera.png' },
          { value: '/assets/img/perfil/Frame_7233caballo.png' },
          { value: '/assets/img/perfil/Frame_7234Castillo.png' },
          { value: '/assets/img/perfil/Frame_7235Paris.png' }
            ],
        ErrorImage: this.NOT_FOUND,
        ErrorImageNav: this.NOT_FOUND_NAV
    };

    // Administración de tarjetas
    static readonly PATH_IMAGE_ADD = './assets/img/general-information/mas.svg';
    static readonly ROAD_SING_RIGHT_IMAGE = '/assets/img/home/Road-Sign-Right.svg';
    static readonly SHOPPING_BAG_IMAGE = '/assets/img/home/Shopping-Bag.svg';
    static readonly BANK_CARD_LOCKET_IMAGE = '/assets/img/home/Bank-Card-Locked.svg';
    static readonly CALENDAR_IMAGE = '/assets/img/home/Calendar-03.svg';
    static readonly ROAD_SING_RIGHT_WHITE_IMAGE = '/assets/img/img-payment-option/road-sign-right-white.svg';
    static readonly OPTION_SHOPPING_BAG_IMAGE = '/assets/img/img-payment-option/shopping-bag.svg';
    static readonly BANK_CARD_LOCKET = '/assets/img/img-payment-option/bank-card-locked.svg';
    static readonly OPTION_CALENDAR_IMAGE = '/assets/img/img-payment-option/calendar-03.svg';
    static readonly IMAGE_CARD = '/assets/img/home/card.svg';

    //Audios
    static readonly AUDIO_HOME = '/assets/audio/AUDIOLOGO_INBURSA.mp3';

    //Default image card
    static readonly DEFAULT_IMAGE_CARD = 'card.svg';
    static readonly NULL_IMAGE_URL = 'null';
    static readonly URL_ADMIN_CARDS = '/limites';
    static readonly LINE_UP= 'line_up.svg';
    static readonly LINE_UP_CONSTRAST = 'line-up-contrast.svg';
    static readonly COLUMN_UP = 'column-up.svg';
    static readonly COLUMN_UP_CONTRAST= 'column-up-contrast.svg';

    static readonly HeaderImagesCancelation = {
        QUESTION: this.CIRCLE_BLUE_QUESTION,
        CONFIRMED: this.GREEN_CHECK_FILL
    };

    static readonly LoaderInterceptor = {
        IMAGE_LOGIN: 'imagenes/seguridad/get',
        KEY_BANK: '/orq/claves/banco',
        LOGIN: '/accesos/orq/login',
        MOVEMENTS: '/movimientos/debito/consultar',
        PERIODS_CONSULT: '/estadoscuenta/periodos/consultar',
        IFM_PROMOTION_CDMX:'/consultasimpuestos/consultaActivaMSICDMX'
    };

    static getResourceConfig() {
      const sufix = Resources.PATH_CDN.split('/')[Numbers.Three];
      return { path: `/${sufix}`, sufix };
    }

    static getAssetPath(subPath: string, removePath: boolean = false) {
      const { path, sufix } = Resources.getResourceConfig();
      return `${(Resources.PATH_CDN).replace(removePath ? path : sufix, removePath ? '' : sufix)}${subPath}`;
    }

    //CDN Resources
    static PATH_CDN = '';
    static readonly CDN_URL = (value: number | string,folderImages:string='/images' ,folderPortal:string='portal') => 
    `${Resources.PATH_CDN}${folderImages}/${folderPortal}/${value}`;
    static readonly CDN_BANNER_URL = (value: string) => `${Resources.PATH_CDN}/noticias/${value}`;
    static readonly GET_FRAME_IMAGE = (value: string | number) => `<iframe width="100%" height="100%" src="${value}"></iframe>`;
    static readonly GET_URL_IMAGE = '/app-assets/accounts/';
    static readonly IMAGE_BALANCE_HELD = 'File_Locked_in-lc.svg';
    static readonly ASSETS_IMAGES_OSB = '/images/limites/';
    static readonly GENERIC_IMAGEN_CARD = '/app-assets/contact-cards/';
    static readonly ASSETS_CREDIT_CARDS = () => Resources.getAssetPath('/contact-cards/');
    static readonly ASSETS_GENERIC_CREDIT_CARD = () => Resources.getAssetPath('/contact-cards/CLASICA_STICKER_F.png');
    static readonly ASSETS_GENERIC_DEBIT_CARD = () => Resources.getAssetPath('/accounts/generic_card.svg');
    static readonly CDN_BRANCH = (value: string) => `${Resources.PATH_CDN}/images/localizanos/android/pines/${value}`;
    static readonly CDN_PORTABILITY_CONDITIONS = () => `${Resources.PATH_CDN}/helps/portabilidad-nomina/terminos-condiciones.pdf`;
    static readonly PIPE_NAMES = {
        SERVICE_IMAGE: 'serviceImage'
    };

    //Security Images
    static readonly CREATE_IMAGE = (image: string, type: string, code: string) => `${image}|${type}|${code}`;
    static readonly IMAGE_SECURITY_GET = (securityImage: string) => `background-image: url(${securityImage})`;
    static readonly wfContract = 'PDF/wcf_contrato.pdf';
    static readonly CANCELLATION = 'Question-Mark-cr-fr.svg';
    static readonly SUCCESS = 'checkcr_fr.svg';

    static readonly CDN_URL_VOLUNTARY = (value: string) => `${Resources.PATH_CDN}/images/portal/retiroAportacionesVoluntarias/${value}`;
    static readonly VOLUNTARY_CDN = {
      VOLUNTARY: 'aportaciones_voluntarias.html',
      COMPLEMENTARY: 'aportaciones_complementarias_retiro.html',
      LONG_TERM: 'aportaciones_largo_plazo.html',
      INVESTMENT: 'aportaciones_perspectiva_inversion.html'
    };
}
