import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from 'src/app/interface/modal-options';
import { PatrimonialStatusService } from 'src/app/services/patrimonial-status.service';
import { ModalModifyLimitComponent } from 'src/app/shared/components/modal-modify-limit/modal-modify-limit.component';
import { ModalService } from 'src/app/shared/modal.service';
import { Constants } from 'src/core/constants/Constants';
import { ModalConstants } from 'src/core/constants/ModalConstants';

@Component({
  selector: 'modal-alert-limit',
  templateUrl: './modal-alert-limit.component.html',
  styleUrls: ['./modal-alert-limit.component.css']
})
export class ModalAlertLimitComponent implements OnInit {
  modalInstance: NgbModalRef;

  constructor(
    private readonly modalService: ModalService,
    private readonly patrimonialService: PatrimonialStatusService
  ) {}

  ngOnInit(): void {}

  modifyLimit(){
    this.close();
    const options: ModalOptions = {
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAlertCT,
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeMd
    };
    this.patrimonialService.isCtLimitExceededTransaction = true;
    this.modalService.open(ModalModifyLimitComponent, options);
  }

  setFutureTransaction() {
    this.patrimonialService.isFutureTransaction = true;
    this.patrimonialService.isCtLimitExceededTransaction = false;
    this.close();
  }

  close() {
    this.modalInstance.close();
  }
}
