<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="vm.switchRoute != null">  
    <div #alertContainer *ngIf="vm.switchRoute; else HomeView">
      <div class="with-container" *ngIf="vm.alertClose && vm.breakpoints.tablet">
      </div>
      <ng-container *ngIf="!vm.alertClose">
        <div class="header-alert p-3">
          <app-alert></app-alert>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #HomeView>
    <div class="nav-container" [ngClass]="vm.isSidebarClosed ? 'nav-container-close' : 'nav-container-open'">
      <div class="container-fluid justify-content-center align-items-center">
        <nav class="navbar drop-shadow-m navbar-expand-lg navbar-light">
          <div class="container-fluid py-1">
            <div class="logo-inbursa-mobile" (click)="toggleMenu()">
              <img [src]="logo | cdnImage" alt="" width="auto" height="auto" />
            </div>
            <div class="toggle-header-icon items-align">
              <a class="btn" id="sidebar-toggle" (click)="toggleMenu()">
                <img [src]="
                    vm.breakpoints.desktop
                      ? (toggleDesktop | cdnImage)
                      : (toggleMobile | cdnImage)
                  " width="auto" height="auto" alt="Menu" />
              </a>
              <button class="navbar-toggler" role="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
            <div *ngIf="vm.breakpoints.desktop">
              <div class="input-group" id="searchContainer">
                <input id="searchInput" #searchInput name="buscar" [placeholder]="placeholder"
                  class="form-control" type="search" autocomplete="off" aria-label="Username"
                  aria-describedby="basic-addon1" [maxLength]="referenceLength"
                  (input)="filterOperation(searchInput.value)" (keyup.enter)="navigate(searchInput.value)"
                  cleanInput [cleanRegex]="regexSearch"
                  (keyup)="optionListDisplay($event, 'keyup'); handleKeydown($event)">
                <label for="searchInput" class="input-group-text radius-button">
                  <img [src]="search|cdnImage" width="24px" height="24px" alt="icono de buscar"
                    (click)="navigate(searchInput.value)" /></label>
                <div id="listSearch" class="card container-options moz-scroll" [hidden]="!showSearches"
                  [ngStyle]="{'height': ( optionsSearch.length > 6 ) ?'250px':'auto'}">
                  <div>
                    <div
                      *ngFor="let options of optionsSearch; let i = index"
                      class="mt-1 selected-option detail-m-highlight"
                      (click)="setOption(options.option)"
                      [class.selected]="i === selectedOptionIndex">
                      {{options.option}}
                    </div>
                  </div>
                  <div [innerHTML]="withoutResult" class="without-options detail-m-highlight mt-2"
                  *ngIf="optionsSearch.length <= 0"> </div>
                </div>
              </div>
            </div>
            <div class="d-flex col justify-content-end">
              <app-navbar-header-component></app-navbar-header-component>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </ng-template>
</ng-container>
