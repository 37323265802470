import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IAccountModifyInfo } from 'src/app/interface/IAccountModifyInfo';
import { Strings } from 'src/core/constants/Strings';
import { Numbers } from 'src/core/constants/Constants';
import { ISimpleValue } from 'src/app/interface/ISimpleValue';
@Injectable({
  providedIn: 'root'
})
export class AccountModifyService {
  private readonly emptyAccount = {
    alias: Strings.EMPTY,
    account: Strings.EMPTY,
    badge: Strings.EMPTY,
    email: Strings.EMPTY,
    idAgent: Numbers.Zero,
    limit: Strings.EMPTY,
    name: Strings.EMPTY,
    type: Numbers.Zero,
    codeType: Numbers.Zero,
    tokenType: Strings.EMPTY,
    recipientType: Strings.EMPTY,
    headline: Strings.EMPTY,
    bank: Strings.EMPTY,
    agendaType: Strings.EMPTY,
    invoice: Strings.EMPTY,
    limitOld: Strings.EMPTY
  };
  private readonly emptyData = {
    limit: Strings.EMPTY,
    alias: Strings.EMPTY,
    email: Strings.EMPTY
  };

  private readonly _account: BehaviorSubject<IAccountModifyInfo> = new BehaviorSubject<IAccountModifyInfo>(this.emptyAccount);
  private readonly _originData: BehaviorSubject<{limit: string, alias: string, email?: string}> =
  new BehaviorSubject<{limit: string, alias: string, email?: string}>(this.emptyData);
  private readonly _modifyAccount: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({value: false});
  private readonly _modifyType: BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);
  private readonly _data: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({value: false});


  get account$() {
    return this._account.asObservable();
  }
  get account() {
    return this._account.getValue();
  }

  set account(data: IAccountModifyInfo) {
    this._account.next(data);
  }

  get originData$() {
    return this._originData.asObservable();
  }
  get originData() {
    return this._originData.getValue();
  }

  set originData(data: {limit: string, alias: string, email?: string}) {
    this._originData.next(data);
  }

  get modifyAccout() {
    return this._modifyAccount.getValue();
  }

  set modifyAccout(data: ISimpleValue) {
    this._modifyAccount.next(data);
  }

  get data() {
    return this._data.getValue();
  }

  set data(data: ISimpleValue) {
    this._data.next(data);
  }

  clearAccountModify() {
    this.account = this.emptyAccount;
  }

  get getModifyType() {
    return this._modifyType.getValue();
  }

  set setModifyType(value: string) {
    this._modifyType.next(value);
  }

  clearModifyType() {
    this._modifyType.next(Strings.EMPTY);
  }
}
