import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, SubscriptionLike, combineLatestWith, map, tap } from 'rxjs';
import { AccountModel } from 'src/app/interface/AccountModel';
import { ICheckingAccountsResponse } from 'src/app/interface/ICheckingAccountsResponse';
import { GetCheckAccountsRequest } from 'src/app/interface/IGetCheckAccountsRequest';
import { ISearch } from 'src/app/interface/ISearch';
import { AccountCardService } from 'src/app/services/account-card.service';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { AforeDataService } from 'src/app/services/afore-data.service';
import { AforeStatusService } from 'src/app/services/afore-status.service';
import { AlertService } from 'src/app/services/alert.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { InsurancePolicies } from 'src/app/services/insurance-policies-status.service';
import { InsurancePolicyInfoStatusService } from 'src/app/services/insurance-policy-info-status.service';
import { InsurancesStatusService } from 'src/app/services/insurances-status.service';
import { InvestmentRegisterService } from 'src/app/services/investment-register.service';
import { InvestmentService } from 'src/app/services/investment.service';
import { NotifyServicePaymentService } from 'src/app/services/notify-service-payment.service';
import { SearchMovementsService } from 'src/app/services/search-movements.service';
import { SentinelService } from 'src/app/services/sentinel.service';
import { StorageService } from 'src/app/services/storage.service';
import { CreditCardInfoStatusService } from 'src/app/services/credit-card-info-status.service';
import { ConsultPDFCreditCardResponse } from 'src/app/interface/dto/ConsultPDFCreditCardResponse';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Char, Constants, ElementsHTML, FormatDate, Length, Numbers, Position } from 'src/core/constants/Constants';
import { IStatementsAccountPDFResponse } from 'src/app/interface/IStatementsAccountPDFResponse';
import { IAforePLResponse } from 'src/app/interface/IAforePLResponse';
import { PATH } from 'src/core/constants/Path';
import { StatementAccountService } from 'src/app/services/statement-account-info.service';
import { CreditCardsStatusService } from 'src/app/services/credit-cards-status.service';
import { Utils } from 'src/core/utils/utils';
import { environment } from 'src/environments/environment';
import { Strings } from 'src/core/constants/Strings';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { SidebarService } from 'src/app/shared/sidebar.service';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { InvestmentCompaniesInfoStatusService } from 'src/app/services/investment-companies-info-status.service';
import { CreditsStatusService } from 'src/app/services/credits-status.service';
import { DatePipe } from '@angular/common';
import { NgbDropdown, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SystemId } from 'src/core/constants/SystemId';
import { ProductUtils } from 'src/core/utils/ProductUtils';
import { IStatementsAccountPLResponse } from 'src/app/interface/IStatementsAccountPLResponse';
import { Resources } from 'src/core/constants/Resources';
import { ProductStrings } from 'src/core/constants/ProductStrings';
import { LoaderService } from 'src/app/services/loader.service';
import { StatementPeriods } from 'src/app/interface/TStatementPeriods';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { FileUtils } from 'src/core/utils/FileUtils';
import { ProductConstants } from 'src/core/constants/ProductConstants';
import { TransactionConstants } from 'src/core/constants/TransactionConstants';

@Component({
  selector: 'app-account-statement',
  templateUrl: './account-statement.component.html',
  styleUrls: ['./account-statement.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AccountStatementComponent implements OnInit, OnDestroy {
  @Input() isModal: boolean = false;
  @Output() showModal = new EventEmitter();
  modalData: string;
  frameClose: string = Resources.CLOSE_CIRCLE_BLUE;
  searchMovement$: Observable<ISearch>;
  monthsFilter: string = Strings.EMPTY;
  accountStatementPDFResponse: IStatementsAccountPDFResponse;
  selectedAccount: AccountModel;
  selectedAccountNumberCompare: string;
  accountStateInfo: SubscriptionLike;
  aforeInfo: IAforePLResponse;
  aforeInfo$: Observable<IAforePLResponse> = this.aforeDataService.afore$;
  dataReceipt: string = Strings.EMPTY;
  viewCardInsurance: boolean = false;
  aforeModule: boolean = false;
  investmentAccount: Array<AccountModel> = [];
  accountNumberObservable = new BehaviorSubject<{ CheckingAccountModel: Array<AccountModel> }>(null);
  selectedAccountNumber: string = Strings.EMPTY;
  descriptionAccount: string = Strings.EMPTY;
  currency: string = Strings.EMPTY;
  image: string = Strings.EMPTY;
  clabe: string = Strings.EMPTY;
  balance: string = Strings.EMPTY;
  idAccountProduct: number = Numbers.Zero;
  accountType: string = Strings.EMPTY;
  accountNumberData: ICheckingAccountsResponse;
  selectedCard: string = Strings.EMPTY;
  creditCardAccountNumber: string;
  creditCardNumberCompare: string = Strings.EMPTY;
  creditCardNumber: number = Numbers.Zero;
  isCreditCardValue: boolean = false;
  isCreditValue: boolean = false;
  creditNumber: string = Strings.EMPTY;
  creditNumberCompare: string = Strings.EMPTY;
  investmentSL: SubscriptionLike;
  statusCreditCard: SubscriptionLike;
  statusCredits: SubscriptionLike;
  consultPDFCreditCardResponse: ConsultPDFCreditCardResponse;
  isInvestmentCompanies: boolean = false;
  showNotifyInvestment: boolean;
  labelTitle: string = Strings.ACCOUNT_STATEMENT.LabelTitle;
  selectPeriod: string = Strings.EMPTY;
  withoutPeriodsMessage: BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);
  titleBox: string = Strings.ACCOUNT_STATEMENT.LabelBox;
  labelButton: string = Strings.ACCOUNT_STATEMENT.LabelButton;
  labelOption: string = Strings.ACCOUNT_STATEMENT.LabelOptionAccount;
  investmentCompaniesInfoSL: SubscriptionLike;
  investmentAccountNumber: string;
  idInvestmentAccount: SystemId;
  investmentAccountNumberCompare: string;
  isWalmartCreditCard: boolean = false;
  noMonths: BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);
  noYears: BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);
  periodYears: Array<string> = [];
  insurancesModal: NgbModalRef;

  @Output() dataFromSearch = new EventEmitter();
  @ViewChild('viewModal', { static: true }) viewModal: ElementRef;
  @Output() litsSelectedToSend = new EventEmitter();
  @Input() optionElement?: string = Strings.EMPTY;
  @ViewChild('myDropdownPeriods') periodsSelect: NgbDropdown;

  accountStatementPDF = this.statementAccountService.periods$.pipe(
    tap((periods) => {
      this.monthsFilter = Strings.EMPTY;
      if(periods.ListPeriodos.Periodo.length < Numbers.One) {
        this.btnDisabled = true;
      }
    }),
    map((data) => data && data.ListPeriodos && data.ListPeriodos.Periodo ? data : {
      ListPeriodos: {
        Periodo: []
      }
    }));

  vm$ = this.accountInfoService.account$.pipe(
    combineLatestWith(
      this.responsiveService.observe,
      this.responsiveService.customObserve(ElementsHTML.ResponsiveServiceStatementAccountStyle),
      this.insurancesStatusService.status$,
      this.insurancePolicyService.insurancePolicy$,
      this.accountNumberObservable.asObservable(),
      this.sidebarService.stateObservable,
      this.withoutPeriodsMessage,
      this.noMonths,
      this.noYears,
      this.investmentCompaniesInfoStatusService.investmentCompaniesInfo$,
      this.creditCardInfoStatusService.creditCard$,
      this.aforeDataService.afore$,
      this.creditsStatusService.credit$
    ),
    map(([account, breakpoints, customBreakpoint, insuranceEvent, dateInsurance, investment, sidebar,
      withoutPeriodsMessage,noMonths,noYears,investmentCompanies, creditCards, afore, credit]) => {
      const canLoad = account.numeroCuenta || creditCards.numeroCuenta || afore.cuenta.idCuenta || credit.NumeroCredito ||
        investment?.CheckingAccountModel?.length > Length.Empty  || investmentCompanies.cuenta;
      return {
        sidebar, account, breakpoints, customBreakpoint: customBreakpoint.matches,
        insuranceEvent: insuranceEvent.value, insurance: dateInsurance, investment, withoutPeriodsMessage,
        noMonths, noYears, investmentCompanies, creditCards, afore, credit, canLoad
      };
    })
  );
  modalInstance: NgbModalRef;
  btnDisabled: boolean = true;
  showmessageError: boolean = false;
  mobilePeriods: Array<StatementPeriods> = [];
  mobileMonthPeriods: Array<StatementPeriods>;
  selectedMobilePeriod: StatementPeriods = {year: Strings.EMPTY, month: Strings.EMPTY, idPeriod: Numbers.Zero};

  constructor(
    private readonly searchMovementsService: SearchMovementsService,
    private readonly statementAccountService: StatementAccountService,
    private readonly baseService: IBaseService,
    private readonly storageService: StorageService,
    private readonly insurancesStatusService: InsurancesStatusService,
    private readonly insurancePolicyService: InsurancePolicyInfoStatusService,
    private readonly stateAforeService: AforeStatusService,
    private readonly aforeDataService: AforeDataService,
    private readonly alertService: AlertService,
    private readonly modalService: ModalService,
    private readonly investmentService: InvestmentService,
    private readonly router: Router,
    private readonly stateServicePayIdService: StateServicePayIdService,
    private readonly responsiveService: ResponsiveService,
    private readonly notifyServicePayment: NotifyServicePaymentService,
    private readonly sentinelService: SentinelService,
    private readonly insurancePolicies: InsurancePolicies,
    private readonly investmentRegisterService: InvestmentRegisterService,
    private readonly accountCardService: AccountCardService,
    private readonly creditCardInfoStatusService: CreditCardInfoStatusService,
    private readonly creditCardsStatusService: CreditCardsStatusService,
    private readonly creditsStatusService: CreditsStatusService,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly sidebarService: SidebarService,
    private readonly investmentCompaniesInfoStatusService: InvestmentCompaniesInfoStatusService,
    private readonly accountInfoService: AccountInfoStatusService,
    private readonly aforeStatusService: AforeStatusService,
    private readonly loaderService:LoaderService) {
    this.searchMovement$ = searchMovementsService.filteredMovementObservable;
  }

  close() { this.modalService.close(); }
  
  @HostListener('window:popstate')
  closeModalAction() {
    this.modalInstance?.close();
  }
  
  async ngOnInit() {
    this.accountStateInfo = this.accountInfoService.account$.subscribe(account => {
      this.statementAccountService.empty();
      this.selectedAccount = account;
      this.isCreditCardValue = this.isInvestmentCompanies = false;
      this.isCreditValue = false;
      this.periodsSelect?.close();
      this.selectedAccountNumberCompare = Strings.EMPTY;
    });
    this.aforeInfo$.subscribe(aforeData => { this.aforeInfo = aforeData; });
    this.investmentSL = this.investmentService.status$.subscribe(
      async (showNotifyInvestment) => {
        this.investmentAccount = [];
        this.showNotifyInvestment = showNotifyInvestment.value;
        if (this.showNotifyInvestment && (this.optionElement === Strings.INVESTMENT.OptionElementOne 
          || this.modalData === Strings.INVESTMENT.OptionElementOne)) {
          if (environment.OsbMongoServices === Constants.MONGO_SERVICES_ENABLED) {
            await this.getCheckingAccounts();
          } else {
            this.accountInfoService.accountInvestment.debitCards.forEach(item =>
              this.investmentAccount.push(item));
            this.investmentAccount = this.investmentAccount.filter(ProductUtils.investmentAccountFilter);
            this.accountNumberObservable.next({ CheckingAccountModel: this.investmentAccount });
          }
          this.labelTitle = Strings.INVESTMENT.LabelTitle;
          this.titleBox = Strings.SELECT_ACCOUNT.From;
          this.labelButton = Strings.INVESTMENT.LabelTitle;
          this.labelOption = Strings.ACCOUNT_STATEMENT.LabelOptionAccount;
        } else if (this.showNotifyInvestment) {
          this.labelOption = Strings.ACCOUNT_STATEMENT.LabelOptionNotify;
        } else {
          this.labelTitle = Strings.ACCOUNT_STATEMENT.LabelTitle;
          this.titleBox = Strings.ACCOUNT_STATEMENT.LabelBox;
          this.labelButton = Strings.ACCOUNT_STATEMENT.LabelButton;
          this.labelOption = Strings.ACCOUNT_STATEMENT.LabelOptionPeriod;
        }
        this.statementAccountService.empty();
        this.periodsSelect?.close();
      });
    this.statusCreditCard = this.creditCardInfoStatusService.creditCard$.subscribe(card => {
      this.statementAccountService.empty();
      if (this.creditCardsStatusService.status.value) {
        this.isCreditValue = false;
        this.isInvestmentCompanies = false;
        this.isCreditCardValue = true;
        this.creditCardAccountNumber = card.numeroCuenta;
        this.creditCardNumber = card.numeroTarjeta;
        this.isWalmartCreditCard = TransactionConstants.WALMART_CREDIT_CARD_STATEMENT.includes(card.tipoCuenta);
        this.periodsSelect?.close();
        this.creditCardNumberCompare = Strings.EMPTY;
      }
    });
    this.stateAforeService.status$.subscribe(
      (isAfore) => {
        this.aforeModule = isAfore.value;
        this.periodsSelect?.close();
      });
    this.investmentCompaniesInfoStatusService.status$.subscribe(
      (isInvestmentCompanies) => {
        this.isInvestmentCompanies = isInvestmentCompanies.value;
        this.periodsSelect?.close();
        this.investmentAccountNumberCompare = Strings.EMPTY;
    });

    this.getInfoCredits();

    if (this.isModal){
      await this.getDataPeriods();
    }
  }

  async getDataPeriods() {
    try {
      this.showModal.emit(true);
      this.loaderService.showLoader();
      await this.typePeriods();
      this.transformPeriods(this.statementAccountService.periods);
    } catch (error) {
      this.noMonths.next(ProductStrings.MONTH_PERIODS_NOT_AVAILABLE);
      this.noYears.next(ProductStrings.YEAR_PERIODS_NOT_AVAILABLE);
      this.btnDisabled = true;
      this.showmessageError = true;
      Utils.printLogError(error);
    } finally {
      this.loaderService.hideLoader();
      this.showModal.emit(false);
    }
  }

  async typePeriods() {
    if (this.creditCardsStatusService.status.value) {
      await this.getDataCreditCard();
    }
    else if (this.creditsStatusService.status.value) {
      await this.getDataCredits();
    }
    else if(this.aforeStatusService.status.value) {
      await this.getAforePeriods();
    }
    else if (this.investmentCompaniesInfoStatusService.status.value) {
      await this.getDataInvestmentCompanies();
    }
    else{
      await this.getAccountsPeriods();
    }
  }

  transformPeriods(data: IStatementsAccountPLResponse) {
    if (data.ListPeriodos.Periodo) {
      data.ListPeriodos.Periodo.forEach(element => {
        const period = this.formatDate(element.finPeriodo);
        const [month, year] = period.split(Char.WhiteSpace);
        this.mobilePeriods.push({year,month,idPeriod: element.idPeriodo});
        if (this.mobilePeriods) {
          this.periodYears = this.mobilePeriods.map(periodMap => periodMap.year).filter((yearFilter, index, self) => self.indexOf(yearFilter) === index);
        }
      });
    }
  }

  mobileOnChange(year: string) {
    if (this.selectedMobilePeriod.year === year) {
      return;
    }
    this.monthsFilter = Strings.EMPTY;
    this.selectedMobilePeriod = {idPeriod: Numbers.Zero, month: Strings.EMPTY, year};
    this.mobileMonthPeriods =  this.mobilePeriods.filter(period => period.year === year);
    this.btnDisabled = false;
    this.showmessageError = true;
  }

  async getDataInvestmentCompanies() {
    const account = this.investmentCompaniesInfoStatusService.investmentCompaniesInfo;
    this.investmentAccountNumber = Utils.convertInvestmentAccount(account.idCuentaProducto, account.cuenta, true);
    if (this.investmentAccountNumber && this.investmentAccountNumber !== this.investmentAccountNumberCompare) {
      this.withoutPeriodsMessage.next(Strings.ACCOUNT_STATEMENT.InProcess);
      this.investmentAccountNumberCompare = this.investmentAccountNumber;
      this.idInvestmentAccount = ProductUtils.getInvestmentAccountType(account.codigoInversion);
      await this.getPeriods(this.investmentAccountNumber, this.idInvestmentAccount);
      this.isCreditCardValue = false;
      this.isInvestmentCompanies = true;
    }
  }

  async getDataCreditCard() {
    if (this.creditCardAccountNumber !== Strings.EMPTY && this.creditCardAccountNumber !== this.creditCardNumberCompare) {
      this.withoutPeriodsMessage.next(Strings.ACCOUNT_STATEMENT.InProcess);
      this.creditCardNumberCompare = this.creditCardAccountNumber;
      await this.getPeriods(this.creditCardAccountNumber, this.isWalmartCreditCard ? SystemId.WalmartCreditCard : SystemId.CreditCards);
    }
  }

  getInfoCredits() {
    this.statusCredits = this.creditsStatusService.credit$.subscribe(credit => {
      this.statementAccountService.empty();
      if (this.creditsStatusService.status.value) {
        this.isCreditCardValue = false;
        this.isCreditValue = true;
        this.creditNumber = credit.NumeroCredito;
        this.periodsSelect?.close();
        this.creditNumberCompare = Strings.EMPTY;
      }
    });
  }

  async getDataCredits() {
    if (this.creditNumber !== Strings.EMPTY && this.creditNumber !== this.creditNumberCompare) {
      this.withoutPeriodsMessage.next(Strings.ACCOUNT_STATEMENT.InProcess);
      this.creditNumberCompare = this.creditNumber;
      await this.statementAccountService.getPeriods(this.creditNumber, SystemId.Credits);
    }
  }

  ngOnDestroy(): void {
    this.accountStateInfo?.unsubscribe();
    this.statusCreditCard?.unsubscribe();
    this.investmentSL?.unsubscribe();
    this.investmentCompaniesInfoSL?.unsubscribe();
  }

  onChange(selected: string) {
    this.monthsFilter = selected;
    this.selectPeriod = this.statementAccountService.periods.ListPeriodos.Periodo.filter(period => period.idPeriodo === parseInt(selected))[Position.Zero].finPeriodo;
    this.btnDisabled = false;
  }

  public async periodsStatementsPDF() {
    try {
      let params = Utils.getAccountParams(this.selectedAccount, this.aforeInfo);
      params = {
        id: this.isCreditValue ? SystemId.Credits: this.isInvestmentCompanies ? this.idInvestmentAccount: params.id,
        account: this.isCreditValue ? this.creditNumber : this.isInvestmentCompanies ? this.investmentAccountNumber : params.account
      };
      this.accountStatementPDFResponse = await this.statementAccountService.getPeriodsPDF(params, this.sentinelService.sentinel.ip, this.monthsFilter);
       FileUtils.downloadPdf(this.accountStatementPDFResponse.ArrayByteVO, ProductConstants.NAME_PDF_ACCOUNT_STATEMENTS);
    } catch (error) {
      this.modalService.close();
      this.creditNumberCompare = Strings.EMPTY;
      this.selectedAccountNumberCompare = Strings.EMPTY;
      const errorDetails = Utils.getErrorMsg(error);
      let messageError = errorDetails.msg;
      if(Number(errorDetails.code) === Constants.EDC_NOT_AVAILABLE) {
        messageError = Strings.SERVICES.UnexpectedMsg;
      }
      this.alertError(messageError, errorDetails.code);
    }
  }

  getPeriodsPDF() {
    if (!this.showNotifyInvestment && !this.isCreditCardValue || this.aforeModule || this.isModal) {
      this.consultMonths();
    } else if (this.isCreditCardValue && !this.showNotifyInvestment && !this.aforeModule) {
      this.getPDFCreditCard();
    } else {
      this.notifyServicePayment.typeOperation = Strings.EMPTY;
      if (this.selectedCard) {
        this.clearDataInvestment();
        this.accountCardService.selectedAccountCard = {
          image: this.image,
          cardNumber: this.selectedAccountNumber,
          clabe: this.clabe,
          balance: this.balance,
          selected: true,
          productId: this.idAccountProduct,
          originAccount: this.selectedAccountNumber,
          cardType: this.accountType,
          currency: this.currency
        };
        this.accountCardService.selected = true;
        this.router.navigate([PATH.Investment]);
        this.investmentRegisterService.investmentFormData = [];
      }
    }
  }

  clearDataInvestment() {
    this.notifyAmountService.amount = { amount: Strings.EMPTY };
    this.accountCardService.clearCardAccount();
    this.investmentRegisterService.clearInvestmentRegister();
  }

  consultMonths() {
    if (isNaN(parseInt(this.monthsFilter))) { return; }
    if (this.creditCardsStatusService.status.value) {
      this.getPDFCreditCard();
      return;
    }
    this.periodsStatementsPDF();
    this.searchMovementsService.filteredMovementData = { concept: null, date: this.monthsFilter, amount: null };
  }

  modalInsurance() {
    this.insurancePolicies.newPaymentInsurance?.reset();
    this.accountCardService.clearCardAccount();
    this.stateServicePayIdService.clearService();
    this.insurancePolicies.clearSelectedPolicies();
    this.insurancesModal= this.modalService.open(this.viewModal, { modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalInsurance, centered: true });
    this.litsSelectedToSend.emit(Strings.EMPTY);
  }

  actionModal($event: string) {
    const data = $event;
    if (data !== Constants.MODAL_OPTIONS.CloseModal) {
      this.selectedCardInvestment($event);
    }
    else { this.dataReceipt = null; }
    this.insurancesModal.close();
  }

  selectedCardInvestment(data: string) {
    this.selectedCard = data;
    const result = this.investmentAccount.filter(productAccount => {
      return productAccount.idCuentaProducto.toString() === data;
    })[Position.Zero];
    this.selectedAccountNumber = result?.numeroCuenta;
    this.descriptionAccount = result?.descripcionCuenta;
    this.clabe = result?.cuentaClabe;
    this.balance = result?.saldos.total.toString();
    this.idAccountProduct = result?.idCuentaProducto;
    this.accountType = result?.tipoCuenta;
    this.image = result?.directorioImagen;
    this.currency = result?.currency;

    this.investmentRegisterService.accountNumber = {
      NumeroCuenta: this.selectedAccountNumber,
      IdCuentaProducto: parseInt(data),
      Descripcion: this.descriptionAccount,
      Saldo: {
        Moneda: this.currency
      }
    };
    this.selectedCard = this.selectedAccountNumber;
    this.litsSelectedToSend.emit(this.selectedCard);
    this.viewCardInsurance = true;
    this.btnDisabled = false;
  }

  async getCheckingAccounts() {
    const request = new GetCheckAccountsRequest({
      IdSession: this.storageService.getSession(),
      MedioAcceso: Constants.ACCESS_METHOD_PORTAL,
      IpCliente: this.sentinelService.sentinel.ip
    });
    try {
      this.accountNumberData = await this.baseService.genericPost<ICheckingAccountsResponse>(request, { isSOA: true });
      this.investmentAccount = [];
      this.accountNumberData.Debito.CuentaCheques.forEach(item =>
        this.investmentAccount.push(ProductUtils.convertToAccountModel(item)));
      this.investmentAccount = this.investmentAccount.filter(ProductUtils.investmentAccountFilter);
      this.accountNumberObservable.next({
        CheckingAccountModel: this.investmentAccount
      });
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.alertError(errorDetails.msg, errorDetails.code);
    }
  }

  private async getPeriods(accountNumber: string, systemId: string) {
    if (accountNumber !== Strings.EMPTY && accountNumber && accountNumber !== Constants.DEFAULT_ACCOUNT) {
      await this.statementAccountService.getPeriods(accountNumber, systemId);
    }
  }

  async getPDFCreditCard() {
    try {
      this.consultPDFCreditCardResponse = await this.statementAccountService.getCreditPeriodsPDF(
        this.creditCardNumber, this.monthsFilter, SystemId.CreditCards);
      FileUtils.downloadPdf(this.consultPDFCreditCardResponse.ArrayByteVO, ProductConstants.NAME_PDF_ACCOUNT_STATEMENTS);

    } catch (error) {
      this.modalService.close();
      this.creditCardNumberCompare = Strings.EMPTY;
      const errorDetails = Utils.getErrorMsg(error);
      let messageError = errorDetails.msg;
      if (Number(errorDetails.code) === Constants.EDC_NOT_AVAILABLE) {
        messageError = Strings.SERVICES.UnexpectedMsg;
      }
      else {
        messageError = Strings.ERROR_SERVICE_GENERIC;
      }
      this.alertError(messageError, errorDetails.code);
    }
  }

  async getAccountsPeriods() {
    this.noYears.next(Strings.ACCOUNT_STATEMENT.InProcess);
    this.noMonths.next(Strings.ACCOUNT_STATEMENT.InProcess);
    if (this.selectedAccount.numeroCuenta !== Strings.EMPTY && this.selectedAccount.numeroCuenta !== this.selectedAccountNumberCompare) {
      const params = Utils.getAccountParams(this.selectedAccount);
      this.withoutPeriodsMessage.next(Strings.ACCOUNT_STATEMENT.InProcess);
      this.selectedAccountNumberCompare = this.selectedAccount.numeroCuenta;
      await this.statementAccountService.getPeriods(params.account, params.id);
    }
  }

  async consultPeriods() {
    try {
      if (this.creditCardsStatusService.status.value) {
        await this.getDataCreditCard();
      }
      else if (this.investmentCompaniesInfoStatusService.status.value) {
        await this.getDataInvestmentCompanies();
      }
      else if (this.creditsStatusService.status.value) {
        await this.getDataCredits();
      }
      else if(this.aforeStatusService.status.value) {
        await this.getAforePeriods();
      }
      else {
        await this.getAccountsPeriods();
      }
    } catch (error) {
      this.withoutPeriodsMessage.next(Strings.ACCOUNT_STATEMENT.WithoutPeriodsMessage);
      Utils.printLogError(error);
    }
  }

  alertError(errorMessage: string, code: number) {
    this.periodsSelect?.close();
    this.alertService.showPopupAlert({
      header: Strings.ERROR_SERVICE_GENERIC,
      message: Utils.SERVICE_ERROR_MSG(errorMessage, code),
      btnLabel: Strings.MSG_POPUP_ACCEPT,
      imgHead: Resources.CLOSE_ICON_RED,
      btnExit:false
    });
  }

  formatDate(date: string) {
    const [day, month, year] = date.split(Char.Slash);
    const datePipe = new DatePipe(Constants.LOCALE_ES_MX);
    const [m, y] = datePipe.transform(
      new Date(`${month}/${day}/${year}`),
      FormatDate.FullMonthYearSpace
    ).split(Char.WhiteSpace);

    return `${m.charAt(Numbers.Zero).toUpperCase() + m.slice(Numbers.One)} ${y}`;
  }

  private async getAforePeriods() {
    if (this.aforeInfo.cuenta.idCuenta) {
      this.withoutPeriodsMessage.next(Strings.ACCOUNT_STATEMENT.InProcess);
      await this.statementAccountService.getPeriods(this.aforeInfo.cuenta.idCuenta, SystemId.Afore);
    }
  }
}
