import { Injectable } from '@angular/core';
import { ModalMyCardsComponent } from 'src/app/control-card/components/modal-my-cards/modal-my-cards.component';
import { MapService } from 'src/app/services/map.service';
import { ModalMyCardsExtensionThreeService } from 'src/app/services/modal-my-cards-three.extension.service';
import { AdditionalConstants } from 'src/core/constants/AdditionalConstants';
import { CardType, ClassHTML, Constants, Numbers, ScrollOptions, Source } from 'src/core/constants/Constants';
import { ClassOperationBlock, FormId } from 'src/core/constants/ControlCardConstants';
import { ControlCardStrings } from 'src/core/constants/ControlCardStrings';
import { HTMLConstants, ClassHTML as HTMLConstantsClassHTML } from 'src/core/constants/HTMLConstants';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
@Injectable({
  providedIn: 'root'
})

export class ModalMyCardsService {

  readonly minLength = Constants.MAX_LENGTH_CVV;

  constructor(
    readonly componentExtensionThree: ModalMyCardsExtensionThreeService,
    readonly mapService: MapService
  ) { }

  async initComponent(context: ModalMyCardsComponent): Promise<void> {
    this.backdropCustom();
    this.componentExtensionThree.reset();
    context.isContinueLockValid = false;
    context.cardInfoType = Strings.EMPTY;
    context.cardNumberInfo$.subscribe(async (data) => { context.cardNumberInfo = data; });
    context.sourceInfo = context.cardNumberInfo.CardDataInfo.SourceCard;
    await context.getDataCards();
    context.adminCardForm = context.initForm();
    context.cvvCardForm = context.initFormCvv();
    context.mapForm = context.initMapForm();
    context.selectedLockTab = true;
    context.maximumCommerceAmount = context.cardNumberInfo.CardDataInfo.SourceCard === Source.Mf2 
    ? AdditionalConstants.ADDITIONAL_VALIDATIONS.commerceDebitAmount : Constants.MAXIMUM_COMMERCE_AMOUNT;
    
    if (context.modifyReception) {
      context.activeOption = false;
      context.blockade = false;
      context.activeCvv = false;
      context.hideButton = true;
      context.modify = false;
      context.cvv = false;
      context.cancel = false;
      context.additional = true;
      context.status = false;
      context.buttonModalCard = Strings.CONTINUE_BUTTON;
      context.selectedLockTab = false;
      context.replacement = false;
      context.renovation = false;
      context.reception = true;
      context.showSucursal = true;
      context.notifyDate.isExternalErrorData = false;
      context.reasonValue = context.recoverOption(ControlCardStrings.LABELS_MANAGE_CARDS.Additional).text;
    } else if (context.modifyCVVReopen) {
      context.blockade = false;
      context.cardCvv = Strings.EMPTY;
      context.dinamycCVV = true;
      context.selectedCvvTab = true;
      context.selectedLockTab = false;
      context.selectedModifyTab = false;
      context.selectCVV = Strings.ACTIVE;
      context.selectLock = Strings.EMPTY;
      context.selectModify = Strings.EMPTY;
      context.selectCancel = Strings.EMPTY;
      context.modify = false;
      context.activeCvv = true;
      context.cvv = true;
      context.cancel = false;
      context.buttonModalCard = Strings.REGISTER_BUTTON;
      context.hideButton = true;
      context.additional = false;
      context.status = false;
      context.processCvv();
      context.hideCvvForm = true;
      context.selectedCvvContinueTab = false;
      context.selectedCvvTab = true;
      context.activeOption = false;
      context.hasCVV = true;
    } else if (context.cancelReopen) {
      context.blockade = false;
      context.selectCancel = Strings.ACTIVE;
      context.selectLock = Strings.EMPTY;
      context.selectModify = Strings.EMPTY;
      context.selectCVV = Strings.EMPTY;
      context.modify = false;
      context.cvv = false;
      context.cancel = true;
      context.buttonModalCard = Strings.CONTINUE_BUTTON;
      context.timeCvv = false;
      context.hideCvvForm = true;
      context.activeCvv = true;
      context.hideButton = false;
      context.showDcvv = true;
      context.additional = false;
      context.status = false;
      context.replacement = true;
      context.renovation = false;
      context.reception = false;
      context.showSucursal = false;
      context.titleModalBranch = ControlCardStrings.TITLE_REPLACEMENT;
      context.dinamycCVV = false;
      context.selectedCvvContinueTab = false;
      context.activeOption = false;
      context.reasonValue = context.recoverOption(Strings.LABELS_MANAGE_CARDS.CancelCard).text;
    } else {
      context.notifyDate.dateStructData = null;
      ControlCardStrings.OPERATION_OPTIONS.OPTIONS.forEach(element => {
        if (element.selected) {
          context.reasonValue = element.text;
        }
      });
    }
    this.showOptionsSelect(context);
    context.showDropDown = true;
  }

  hiddenModal(show: boolean): void {
    document.querySelector(ClassOperationBlock.MODAL_MY_CARDS).classList?.toggle(ClassOperationBlock.CLASS_D_NONE, show);
    document.querySelector(ClassOperationBlock.MODAL_BACKDROP).classList?.toggle(ClassOperationBlock.CLASS_D_NONE, show);
  }

  focusError(): void {
    document
      .getElementById(FormId.TopHeader)
      .scrollIntoView({ behavior: ScrollOptions.Smooth });
  }

  close(context: ModalMyCardsComponent): void {
    context.modalService.close();
    window.clearInterval(context.cvvTimer);
    this.mapService.showMap = true;
  }

  ngOnDestroy(context: ModalMyCardsComponent): void {
    context.notifyDate.isExternalErrorData = false;
    window.clearInterval(context.cvvTimer);
    context.modalService.close();
    context.unsubscription?.unsubscribe();
    Utils.resetScroll();
  }

  backdropCustom() {
    const modalBackdrop = document.getElementsByTagName(HTMLConstants.MODAL_OPTIONS.NgbModalBackdrop);
    modalBackdrop[Numbers.Zero].classList.add(HTMLConstantsClassHTML.DisplayBlock);
  }

  getCardType(source: string): string {
    return source === Source.Mf2 ? CardType.Debit : CardType.Credit;
  }

  isCreditCard(source: string): boolean {
    return source === Source.Infinitus;
  }

  setMaxContent(context?: ModalMyCardsComponent) {
    setTimeout(() => {
      context.modalContainer.nativeElement.style.maxHeight = ClassHTML.MaxContent;
    }, Numbers.OneHundred);
  }

  showOptionsSelect(context?: ModalMyCardsComponent) {
    const cardType = context.getDataCardsInfo.Card.CardType;
    context.reasonOptions = context.reasonOptions
      .filter(x => !this.setOptionsSelect(cardType)
      .includes(x.value));
  }

  setOptionsSelect(CardTypeSelected: string): string[] {
    let excluded = ControlCardStrings.OPERATION_OPTIONS.EXCLUDED_OPTIONS
      .filter(x => x.type === CardTypeSelected)
      .map(x => x.values)[Numbers.Zero];
    if (!excluded) {
      excluded = [];
    }
    return excluded;
  }

}
