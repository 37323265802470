import { Component, Input, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, Output, EventEmitter, ViewChildren, QueryList, HostListener } from '@angular/core';
import { CardAddresseeArray, IAddresseeAccount } from 'src/app/interface/IAddresseeAccount';
import { CardAddresseeService } from 'src/app/services/card-addressee.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { BehaviorSubject, SubscriptionLike, combineLatestWith, map, switchMap, tap } from 'rxjs';
import { Constants, AgendaType, CoinType, AddresseTabs, Numbers, Char, Regex, Length, AccountType } from 'src/core/constants/Constants';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { ModalService } from 'src/app/shared/modal.service';
import { AlertService } from 'src/app/services/alert.service';
import { ServiceTransferIdService } from 'src/app/services/service-transfer-id.service';
import { Router } from '@angular/router';
import { NgbDropdown, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { StorageService } from 'src/app/services/storage.service';
import { Utils } from 'src/core/utils/utils';
import { AccountCardService } from 'src/app/services/account-card.service';
import { ExchangeRateService } from 'src/app/services/exchange-rate.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { ServiceOptionsBankService } from 'src/app/services/service-options-bank.service';
import { Strings } from 'src/core/constants/Strings';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { LegendAddresseeEvent } from 'src/app/interface/ICardAddresseeEvent';
import { EnterAmountErrorService } from 'src/app/services/enter-amount-error.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ConsultCurrencyExchangeRequest } from 'src/app/interface/dto/ConsultCurrencyExchangeRequest';
import { ConsultCurrencyExchangeResponse } from 'src/app/interface/dto/ConsultCurrencyExchangeResponse';
import { FacePassLoginService } from 'src/app/services/face-pass-login.service';
import { SidebarService } from 'src/app/shared/sidebar.service';
import { TransfersResources } from 'src/core/constants/TransferResources';
import { CustomResponsiveService } from 'src/app/shared/custom-responsive.service';
import { ExpressTransferService } from 'src/app/services/express-transfer.service';
import { KeyAgendaType } from 'src/core/constants/TransferConstants';
import { AddressConstants } from 'src/core/constants/AddressConstants';
import { AddresseStrings } from 'src/core/constants/AddresseStrings';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-card-modal-addressee',
  templateUrl: './card-modal-addressee.component.html',
  styleUrls: ['./card-modal-addressee.component.css'],
  providers: [PaginationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardModalAddresseeComponent implements OnInit {
  newAddresseeRoute: string = PATH.NewAddressee;
  modalCloseWhiteImage: string = Resources.MODAL_CLOSE_WHITE;
  closeWhiteImage: string = Resources.MODAL_CLOSE_WHITE;
  modalCloseImage: string = Resources.CLOSE_CIRCLE_BLUE;
  searchImage: string = Resources.SEARCH;
  searchImageMobile: string = Resources.SEARCH_LIGHT_BLUE;
  shapePlusImage: string = Resources.SHAPE_PLUS_GRAY;
  chevronRightImage: string = Resources.BTN_CHEVRON_RIGHT;
  @Input() modal!: NgbModalRef;
  @Output() selectedAccount: EventEmitter<string> = new EventEmitter();
  @Output() cardsFull: EventEmitter<boolean> = new EventEmitter();
  addresseeSelected: IAddresseeAccount = {
    profile_image: Strings.EMPTY,
    nombre: Strings.EMPTY,
    nombre_alias: Strings.EMPTY,
    banco: Strings.EMPTY,
    clabe: Strings.EMPTY,
    limite_diario: Strings.EMPTY,
    seleccionado: false,
    idAgenda: Numbers.Zero,
    tiempoEspera: Numbers.Zero,
    estaBloqueada: Strings.EMPTY,
    tipoCuenta: Strings.EMPTY,
    isOwnTransfer: false,
    badge: Strings.EMPTY,
    type: Numbers.Zero
  };
  addresseeAcount: IAddresseeAccount[] = [];
  errorMessage: string = Strings.EMPTY;
  isOwnTransfer: boolean = false;
  exchange: string;
  value: number;
  resultMXP: number;
  resultUSD: number;
  currency: string;
  badge: string;
  badgeAddressee: string;
  tabs: string[] = AddresseStrings.TABS;
  tabsToShow: boolean[] = [true, true, true, true, true];
  isDisabled: boolean = false;
  selectedTabIndex: number = Numbers.Zero;
  addresseeId: number | null = null;
  tab$: BehaviorSubject<number> = new BehaviorSubject<number>(Numbers.Zero);
  isDesktop: boolean = false;
  isMobile: boolean = false;
  transfers: string = PATH.TransferData;
  chevronRight = TransfersResources.CHEVRON_RIGHT_BLACK;
  regex: RegExp = Regex.OnlyText;
  filter: boolean = false;
  sidebarActive: boolean = false;
  selectedTab: number = Numbers.Zero;
  isSelectedTab: boolean = true;
  hiddenCards: boolean = true;
  private readonly PAGE_SIZE = {
    ExtraLarge:  environment.ElementsPerPage.Agendas.extraLarge,
    Large: environment.ElementsPerPage.Agendas.large,
    Medium: environment.ElementsPerPage.Agendas.medium,
    Small: environment.ElementsPerPage.Agendas.small
  } as const;
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;

  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(
      this.facePassLogin.isFacePassLogin$,
      this.sidebarService.stateObservable
    ),
    map(([breakpoints, isFacepassLogin, sidebar]) => {
      this.isDesktop = breakpoints.desktop;
      this.isMobile = breakpoints.mobile;
      return ({ breakpoints, isFacepassLogin: isFacepassLogin.value, sidebar });
    })
  );

  cards$ = this.cardAddresseeService.data$.pipe(
    combineLatestWith(
      this.customResponsiveService.observe,
      this.tab$
    ),
    tap(([{ recipients }, breakpoints, _]) =>
      this.paginationService.setInitialData(
        this.getSeletectedTabData(recipients),
        (breakpoints.extraMobile || breakpoints.mobile) ? this.PAGE_SIZE.Small :
        (breakpoints.tablet || breakpoints.desktop) ? this.PAGE_SIZE.Medium :
        breakpoints.desktopLarge ? this.PAGE_SIZE.Large : this.PAGE_SIZE.ExtraLarge
      )
    ),
    switchMap(([initial]) =>
      this.paginationService.pagination.pipe(
        map((pagination) => {
          this.cardsFull.emit((pagination.data.length === Numbers.Zero && initial.errorMsg !== Strings.EMPTY) || (pagination.data.length > Numbers.Zero));
          return {
            page: pagination.data,
            error: initial.errorMsg,
            errorDischarge: initial.errorMsgDischarge,
            errorServiceArray: initial.errorService,
            legendArray: initial.legend
          };
        })
      )
    )
  );

  newAddresseePath: string = PATH.NewAddressee;
  isLoading$ = this.loaderService.isLoading;
  withoutResultsMessage: string = Strings.WITHOUT_RESULTS;
  goTransferServiceSL: SubscriptionLike;
  @ViewChild('Header') private readonly header: ElementRef;
  @ViewChild('Footer') private readonly footer: ElementRef;

  detailsRecipient($event: IAddresseeAccount) {
    this.addresseeSelected = $event;
    this.modifyCardAddresseeArray($event);
  }

  constructor(
    private readonly cardAddresseeService: CardAddresseeService,
    private readonly paginationService: PaginationService,
    private readonly accountCardService: AccountCardService,
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly serviceTransferId: ServiceTransferIdService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly baseService: IBaseService,
    private readonly storage: StorageService,
    private readonly exchangeRate: ExchangeRateService,
    private readonly notifyAmount: NotifyAmountService,
    private readonly optionBankService: ServiceOptionsBankService,
    private readonly loaderService: LoaderService,
    private readonly amountErrorService: EnterAmountErrorService,
    private readonly facePassLogin: FacePassLoginService,
    private readonly sidebarService: SidebarService,
    private readonly customResponsiveService: CustomResponsiveService,
    private readonly expressTransferService: ExpressTransferService) { }

  ngOnInit() {
    this.exchangeRate.sigantureCurrency = null;
    this.consultCards();
    const accountType = this.accountCardService.accountCard?.accountType;
    if(accountType && accountType === AccountType.WEB){
      this.selectedTabIndex = Numbers.One;
      this.tabs = AddresseStrings.TABS.slice(Numbers.One,Numbers.Two);
      this.isDisabled = true;
    } else if (this.cardAddresseeService.cardProcess && this.cardAddresseeService.transferType !== Numbers.Zero) {
      this.selectedTabIndex = this.cardAddresseeService.transferType;
      this.cardAddresseeService.cardProcess = false;
    } else {
      this.tabs = AddresseStrings.TABS;
      this.isDisabled = false;
    }
    this.selectTab(this.selectedTabIndex, true);
    this.goTransferServiceSL = this.cardAddresseeService.goTransfer$.subscribe(goTransfer => {
      if (goTransfer) {
        const indexAddressee = this.getSeletectedTabData(this.cardAddresseeService.data.recipients).findIndex(elemento => elemento.idAgenda === this.addresseeSelected.idAgenda);
        this.addresseeSelected = this.getSeletectedTabData(this.cardAddresseeService.data.recipients)[indexAddressee];
        this.cardAddresseeService.goTransfer = false;
        this.selectedAddressee(indexAddressee);
        this.selectedAddresseeCard();
      }
    });
  }

  async consultCards() {
    await this.cardAddresseeService.getInitialData();
    this.showTabs();
    this.hiddenCards = false;
  }

  resetTabsToShow() {
    this.tabsToShow.fill(true);
    this.showTabs();
    this.selectTab(this.selectedTab);
  }

  hideTabs(recipients: CardAddresseeArray){
    this.tabsToShow = [
      recipients.otherBanks.length > Numbers.Zero,
      recipients.ownAccounts.length > Numbers.Zero,
      recipients.thirdParty.length > Numbers.Zero,
      recipients.internationals.length > Numbers.Zero,
      recipients.brokerageHouse.length > Numbers.Zero
    ];
    this.isSelectedTab = this.tabsToShow.some(value => value);
  }

  showTabs(){
    this.tabsToShow[AddressConstants.TAB_INDEX_BROKERAGE_HOUSE] = this.cardAddresseeService.cardAddresseesList.brokerageHouse.length > Numbers.Zero;
  }

  navigateToTabWithResults(recipients: CardAddresseeArray){
    if(recipients.otherBanks.length > Numbers.Zero){
      this.selectTab(AgendaType.OthersBanks);
      return;
    }
    if(recipients.ownAccounts.length > Numbers.Zero){
      this.selectTab(AgendaType.OwnAccounts);
      return;
    }
    if(recipients.thirdParty.length > Numbers.Zero){
      this.selectTab(AgendaType.ThirdParties);
      return;
    }
    if(recipients.internationals.length > Numbers.Zero){
      this.selectTab(AgendaType.International);
      return;
    }
    if(recipients.brokerageHouse.length > Numbers.Zero){
      this.selectTab( AddressConstants.TAB_INDEX_BROKERAGE_HOUSE);
      return;
    }
  }

  selectTab(index: number | string, isTab: boolean = false) {
    this.selectedTabIndex = typeof index === 'string' ? parseInt(index) : index;
    this.selectedTab = isTab ? this.selectedTabIndex : this.selectedTab;
    this.tab$.next(this.selectedTabIndex);
    this.dropdowns?.forEach(x => x.close());
  }

  getSeletectedTabData(recipients: CardAddresseeArray) {
    if (this.selectedTabIndex === AgendaType.OthersBanks) return recipients.otherBanks;
    if (this.selectedTabIndex === AgendaType.OwnAccounts) return this.filterOwnAccounts(recipients.ownAccounts);
    if (this.selectedTabIndex === AgendaType.ThirdParties) return recipients.thirdParty;
    if (this.selectedTabIndex === AgendaType.International) return recipients.internationals;
    if (this.selectedTabIndex === AddressConstants.TAB_INDEX_BROKERAGE_HOUSE) return recipients.brokerageHouse;
    return recipients.otherBanks;
  }

  showLegend(legendAddresseeEvent: LegendAddresseeEvent) {
    if (legendAddresseeEvent.otherBanks && this.selectedTabIndex === AgendaType.OthersBanks) return true;
    if (legendAddresseeEvent.ownAccounts && this.selectedTabIndex === AgendaType.OwnAccounts) return true;
    if (legendAddresseeEvent.thirdParty && this.selectedTabIndex === AgendaType.ThirdParties) return true;
    if (legendAddresseeEvent.internationals && this.selectedTabIndex === AgendaType.International) return true;
    else if(this.getHasBrokerageHouse(legendAddresseeEvent)) return true;
    return false;
  }

  getHasBrokerageHouse(legendAddresseeEvent: LegendAddresseeEvent){
    return legendAddresseeEvent.brokerageHouse && this.selectedTabIndex === AddressConstants.TAB_INDEX_BROKERAGE_HOUSE;
  }

  selectedAddressee(index: number) {
    this.addresseeId = index;
  }

  get cardAddresseeArray(): CardAddresseeArray {
    return this.cardAddresseeService.cardAddresseesList;
  }

  set cardAddresseeArray(array: CardAddresseeArray) {
    this.cardAddresseeService.cardAddresseesList = array;
  }

  getKey() {
    if (this.selectedTabIndex === AgendaType.OthersBanks) return KeyAgendaType.OthersBanks;
    if (this.selectedTabIndex === AgendaType.OwnAccounts) return KeyAgendaType.OwnAccounts;
    if (this.selectedTabIndex === AgendaType.ThirdParties) return KeyAgendaType.ThirdParty;
    if (this.selectedTabIndex === AgendaType.International) return KeyAgendaType.Internationals;
    return KeyAgendaType.OthersBanks;
  }

  modifyCardAddresseeArray(addressee: IAddresseeAccount) {
    this.cardAddresseeArray[this.getKey()].forEach(element => {
      if (element.clabe === addressee.clabe) {
        element.seleccionado = true;
      } else {
        element.seleccionado = false;
      }
    });
  }

  amountOwnTransfer(){
    if(this.addresseeSelected.isOwnTransfer){
      this.addresseeSelected.limite_diario = null;
    }
  }

  selectedAddresseeCard() {
    if (this.addresseeSelected.tiempoEspera > Constants.WAIT_TIME_ZERO) {
      let errorDetails = Strings.MSG_TIMEOUT_AGENDA.replace(Regex.Time, this.addresseeSelected.tiempoEspera.toString());
      if (this.addresseeSelected.type === AgendaType.ThirdParties) {
        errorDetails = Strings.MSG_TIMEOUT_AGENDA_THIRD_PARTIES(this.addresseeSelected.tiempoEspera);
      }
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails, Constants.SIXTY_MINUTE_CODE),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT
      });
      this.cardAddresseeService.selected = false;
      return;
    } else if (this.addresseeSelected.clabe !== Strings.EMPTY) {
      this.addresseeSelected.isOwnTransfer = this.selectedTabIndex === AgendaType.OwnAccounts;
      this.amountOwnTransfer();
      this.cardAddresseeService.selectedCardAddressee = this.addresseeSelected;
      this.cardAddresseeService.cardAddressee = this.addresseeSelected;
      this.cardAddresseeService.selected = true;
    }
    if (this.selectedTabIndex === AddresseTabs.International) {
      this.cardAddresseeService.transferType = AddresseTabs.International;
      const typeTransfer = Constants.INTERNATIONAL;
      this.serviceTransferId.stateTransferIdData = {
        id: typeTransfer
      };
      this.router.navigate([PATH.InternationalTransfers]);
      this.currency = this.accountCardService.accountCard.currency;
      this.badge = this.cardAddresseeService.selectedCardAddressee.badge;
      this.notifyAmount.amount = { amount: Strings.EMPTY };
      this.notifyAmount.internationalAmount = {internationalAmount: Numbers.Zero };
      this.optionBankService.optionBankServiceData = { purposePayment: Strings.EMPTY };
      this.amountErrorService.amountErrorData = { isError: false, errorMsg: null };
      this.notifyAmount.internationalAmount = {internationalAmount: Numbers.Zero};
      this.cardAddresseeService.cardAddressee$.subscribe(data => {
        this.badgeAddressee = data.badge;
      });
      if (this.currency === CoinType.USD && this.badge === CoinType.USD ||
        this.currency === CoinType.EUR && this.badge === CoinType.EUR) {
        this.exchangeRate.exchange = { exchangeRateMXP: Constants.EXCHANGE_RATE_EMPTY };
      } else {
        this.getTypeCurrencyExchange();
      }
    } else {
      if (this.selectedTabIndex === AddresseTabs.OwnAccounts) {
        this.serviceTransferId.clearStateTransferId();
      }
      this.cardAddresseeService.transferType = this.selectedTabIndex;
      this.router.navigate([PATH.TransferData]);
      this.expressTransferService.isExpressTransfer = false;
    }
    this.selectedAccount.emit(Strings.EMPTY);
  }

  closeModal() {
    this.modalService.close();
  }

  resetData() {
    this.cardAddresseeService.resetData();
    this.resetTabsToShow();
    this.filter = false;
  }

  setSearch(value: string) {
    this.filter = value !== Strings.EMPTY;
    this.cardAddresseeService.filter(value);
    this.hideTabs(this.cardAddresseeArray);
    this.paginationService.setInitialData(
      this.getSeletectedTabData(this.cardAddresseeArray),
      this.isDesktop ? this.PAGE_SIZE.Large : this.PAGE_SIZE.Small
    );
    this.paginationService.search(this.search.bind(this), value);
  }

  search(data: IAddresseeAccount[], value: string) {
    value = Utils.normalizeString(value.toLowerCase());
    if (value === Strings.EMPTY) {
      setTimeout(() => {
        this.resetTabsToShow();
      }, Numbers.TwentySix);
      return data;
    }
    const filteredData = data.filter(addresse =>
      (Utils.normalizeString(addresse.banco?.toLowerCase())?.includes(value)) ||
      (Utils.normalizeString(addresse.nombre?.toLowerCase())?.includes(value)) ||
      (addresse.clabe?.includes(value)) ||
      (addresse.limite_diario?.includes(value)) ||
      (Utils.normalizeString(addresse.nombre_alias?.toLowerCase())?.includes(value)) ||
      (addresse.contrato?.includes(value))
    );

    if(!filteredData.length){
      setTimeout(() => {
        this.navigateToTabWithResults(this.cardAddresseeArray);
      }, Numbers.TwentySix);
    }

    return filteredData;
  }

  ngOnDestroy() {
    this.modalService.close();
    this.goTransferServiceSL?.unsubscribe();
  }

  async getTypeCurrencyExchange() {
    const request = new ConsultCurrencyExchangeRequest({
      Fecha: Utils.formatDate().gfiReplaceAll(Char.Slash, Char.MiddleDash),
      IdSession: this.storage.getSession(),
      Divisa:this.badgeAddressee,
      MedioAcceso: Constants.ACCESS_METHOD_PORTAL
    });
    try {
      const response = await this.baseService.genericPost<ConsultCurrencyExchangeResponse>(request, { isSOA: true, });
      this.exchangeRate.sigantureCurrency = response.Firma;
      if (this.badgeAddressee === CoinType.USD) {
        const updateObsevable = this.cardAddresseeService.selectedCardAddressee;
        this.cardAddresseeService.selectedCardAddressee = {
          profile_image: updateObsevable.profile_image,
          nombre: updateObsevable.nombre,
          nombre_alias: updateObsevable.nombre_alias,
          banco: updateObsevable.banco,
          clabe: updateObsevable.clabe,
          badge: updateObsevable.badge,
          seleccionado: updateObsevable.seleccionado,
          limite_diario: updateObsevable.limite_diario,
          email: updateObsevable.email,
          accountNumber: updateObsevable.accountNumber,
          agendaType: updateObsevable.agendaType,
          idAgenda: updateObsevable.idAgenda,
          tokenType: updateObsevable.tokenType,
          beneficiaryType: updateObsevable.beneficiaryType,
          headline: updateObsevable.headline,
          type: updateObsevable.type,
          personId: updateObsevable.personId
        };
        const valueUSD = response.TiposCambio.filter(exchangeUSD => exchangeUSD.Alias === Constants.MXP_EXCHANGE_RATE);
        if (valueUSD && valueUSD.length > Length.Empty) {
          valueUSD.forEach(value => {
            this.exchangeRate.exchange = { exchangeRateMXP: value.VentaVIP };
          });
        }
      } else if (this.badgeAddressee === CoinType.EUR) {
        const updateObsevable = this.cardAddresseeService.selectedCardAddressee;
        this.cardAddresseeService.selectedCardAddressee = {
          profile_image: updateObsevable.profile_image,
          nombre: updateObsevable.nombre,
          nombre_alias: updateObsevable.nombre_alias,
          banco: updateObsevable.banco,
          clabe: updateObsevable.clabe,
          badge: updateObsevable.badge,
          seleccionado: updateObsevable.seleccionado,
          limite_diario: updateObsevable.limite_diario,
          email: updateObsevable.email,
          accountNumber: updateObsevable.accountNumber,
          agendaType: updateObsevable.agendaType,
          idAgenda: updateObsevable.idAgenda,
          tokenType: updateObsevable.tokenType,
          beneficiaryType: updateObsevable.beneficiaryType,
          headline: updateObsevable.headline,
          type: updateObsevable.type,
          personId: updateObsevable.personId
        };
        const valueMXP = response.TiposCambio.filter(exchangeMXP => exchangeMXP.Alias === Constants.MXP_EXCHANGE_RATE);
        if (valueMXP && valueMXP.length > Length.Empty) {
          valueMXP.forEach(value => {
            this.resultMXP = value.VentaVIP;
          });
        }
        const valueUSD = response.TiposCambio.filter(exchangeUSD => exchangeUSD.Alias === Constants.EUR_EXCHANGE_RATE);
        if (valueUSD && valueUSD.length > Length.Empty) {
          valueUSD.forEach(value => {
            this.resultUSD = value.VentaVIP;
          });
        }
        this.exchangeRate.exchange = {
          exchangeRateMXP: this.resultMXP,
          exchangeRateUSD: this.resultUSD
        };
      }
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        imgHead: Resources.CLOSE_ICON_RED,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        btnExit: false
      }, {
        onSuccess: () => {this.router.navigate([PATH.Transfers]);}
      });
    }
  }

  isTransferExpress() {
    this.expressTransferService.isExpressTransfer = true;
    this.cardAddresseeService.clearCardAddressee();
    this.router.navigate([PATH.TransferData]);
  }

  filterOwnAccounts(recipients: IAddresseeAccount[]){
    return recipients?.filter(account => ( account.idCuentaProductoDestino !== this.accountCardService.selectedAccountCard.productId ));
  }
  @HostListener('window:resize', ['$event'])
  mobileResize(){
    if(this.isMobile){
      this.resizeCards();
    }
  }
  private resizeCards() {
    const cardHeight = AddressConstants.CARD_HEIGHT;
    const modalContainerHeight = window.innerHeight;
    if(this.header.nativeElement.offsetHeight && this.footer.nativeElement.offsetHeight){
      const extraSpace = (Number(this.header.nativeElement.offsetHeight) + Number(AddressConstants.EXTRA_SPACE_MOBILE));
      const availableHeight = modalContainerHeight - extraSpace - this.footer.nativeElement.offsetHeight;
      let elementsPerPage = (Math.floor(availableHeight / cardHeight));
      elementsPerPage = elementsPerPage > this.PAGE_SIZE.Small ? elementsPerPage : this.PAGE_SIZE.Small;
      this.paginationService.setPageSize(elementsPerPage);
    }else{
      this.paginationService.setPageSize(this.PAGE_SIZE.Small);
    }
  }
}
