<ng-container *ngIf="vm$ | async as vm">
  <div
    [ngClass]="vm.breakpoints.mobile ? 'card-addressee-mobile' : !styleCard ? 'card-addressee'
    : styleCard && addressee.type === 3 ? 'card-addressee-transfers' : 'card-addressee'"
    (click)="selectedAddressee(addressee)" id="addresse-{{id}}"
    [class.w-100]="styleCard"
    [class.h-100]="styleCard"
    [class.card-addressee-capture]="vm.breakpoints.mobile && styleCard">
    <div [ngClass]="hideOptions ? 'card-content-display' : 'card-content-display-hide'">
      <div class="card-layout-data">
        <header>
          <div class="d-flex gap-3 align-content-start">
            <div class="d-flex border-icon-profile">
              <img [src]="addressee.profile_image | cdnImage" alt="profile-image" class="icon-card-addressee"
                width="auto" height="auto">
            </div>
            <div class="flex-fill">
              <text-ellipsis [text]="addressee.nombre" classes="header-xs default-color"
                [styles]="styles"></text-ellipsis>
              <br *ngIf="addressee.type === brokerageHouseType">
              <text-ellipsis [text]="addressee.nombre_alias" classes="detail-m default-color"
                [styles]="styles"></text-ellipsis>
            </div>
          </div>
        </header>
        <main [ngClass]="(addressee.type!==1 && addressee.type !== ownAccountType) ||
         addressee.tipoCuenta.toLowerCase().includes(patrimonialDescription) ? 'class-body'
        : 'body-cp'">
          <div class="d-felx flex-column">
            <div class="d-flex flex-column account-information">
              <ng-container *ngIf="addressee.banco?.length>27">
                <text-ellipsis [text]="addressee.banco" classes="text-secondary-800 secondary-card"
                  [styles]="styles"></text-ellipsis>
              </ng-container>
              <div *ngIf="addressee.banco?.length<=27" class="detail-m">{{addressee.banco}}</div>
              <div *ngIf="addressee.clabe" class="detail-m clabe-max-width text-break"
              [class.text-style]="addressee.clabe?.length > 18">
                CLABE: {{ addressee.clabe | numberFormat :4 }}
              </div>
              <div *ngIf="addressee.type == brokerageHouseType" class="detail-m clabe-max-width text-break"
                ngbTooltip="{{addressee.contrato}}">
                Número de contrato: {{addressee.contrato | ellipsisText : vm.breakpoints.mobile ? 9 : 11}}
              </div>
              <div *ngIf="addressee.type!==ownAccountType && addressee.type !== brokerageHouseType" class="detail-m">
                Límite diario: {{ addressee.limite_diario | currency }}
              </div>
              <div *ngIf="addressee.type==ownAccountType &&
                addressee.tipoCuenta.toLowerCase().includes(patrimonialDescription)" class="secondary-card ">
                <ng-container *ngIf="addressee.limite_diario!=undefined||addressee.limite_diario!=null">
                  Límite diario: {{addressee.limite_diario | currency }}
                </ng-container>
              </div>
              <ng-container *ngIf="beta">
                <div *ngIf="hideOptions" class="d-flex gap-2">
                  <img [src]="fileViewBlue | cdnImage" alt="file-view" class="card-file-view" width="auto"
                    height="auto">
                  <a class="cursor-pointer detail-s-highlight-hypertext-addresse text-center"
                    (click)="showModalTransferHistory()">
                    Ver Historial
                  </a>
                </div>
              </ng-container>
            </div>
          </div>
        </main>
        <footer class="d-flex" [class.w-100]="vm.breakpointsCard.extraMobile">
          <div *ngIf="hideOptions" class="card-buttons gap-3" [class.w-100]="vm.breakpointsCard.extraMobile">
            <button *ngIf="addressee.type!==1 && addressee.type !== brokerageHouseType"
             class="options-card-btn" (click)="deleteSchedule()"
            [class.w-50]="vm.breakpointsCard.extraMobile">
              <img class="icon-card-options" width="auto" height="auto" [src]="trash | cdnImage" alt="delete-icon" />
              <span class="typography-options">
                Eliminar</span>
            </button>
            <button *ngIf="addressee.type !== ownAccountType && addressee.type !== brokerageHouseType"
              class="options-card-btn"
              (click)="showModalModifyAccount()" [class.w-50]="vm.breakpointsCard.extraMobile">
              <img class="icon-card-options" width="auto" height="auto" [src]="edit | cdnImage" alt="Conoce más" />
              <span class="typography-options">
                Editar
              </span>
            </button>
            <button *ngIf="addressee.type== ownAccountType &&
            addressee.tipoCuenta.toLowerCase().includes(patrimonialDescription) && addressee.limite_diario !== null"
            class="options-card-btn"
              (click)="showModalModifyLimit()" [class.w-50]="vm.breakpointsCard.extraMobile">
              <img class="icon-card-options" width="auto" height="auto" [src]="edit | cdnImage" alt="Conoce más" />
              <span class="typography-options">
                Editar
              </span>
            </button>
          </div>
        </footer>
      </div>
      <div *ngIf="hideOptions" class="align-self-center">
        <img [src]="chevronBlue | cdnImage" alt="chevron-blue" class="icon-chevron-right cursor-pointer" width="auto"
          height="auto" (click)="selectedAddressee(addressee); addresseefunction()">
      </div>
    </div>
  </div>
</ng-container>
<ng-template #AlertEdit>
  <app-modal-modify-limit (modalCall)="closeModal()" [accountSelected]="titleModal">
  </app-modal-modify-limit>
</ng-template>
