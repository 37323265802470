import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatestWith, map, tap } from 'rxjs';
import { AccountCardService } from 'src/app/services/account-card.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { NotifyDateService } from 'src/app/services/notify-date.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { AccountType, Constants, ElementsHTML, Numbers, Position } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { CodeflexService } from 'src/app/services/codeflex.service';
import { AccountModel } from 'src/app/interface/AccountModel';
import { IAmount } from 'src/app/interface/IAmount';
import { GetWithdrawalsResponse } from 'src/app/interface/dto/GetWithdrawalsResponse';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { MaskAccountPipe } from 'src/app/pipes/mask-account.pipe';
import { AlertService } from 'src/app/services/alert.service';
import { ButtonsSpacing } from 'src/app/models/ButtonsSpacing';
import { AccountsStatusService } from 'src/app/services/accounts-status.service';
import { CodeflexConstants, CodeflexElementsHTML, CodeflexFormatDate } from 'src/core/constants/CodeflexConstants';
import { DateUtils } from 'src/core/utils/dateUtils';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';

@Component({
  selector: 'app-operation-success',
  templateUrl: './operation-success.component.html',
  styleUrls: ['./operation-success.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OperationSuccessComponent implements OnInit{
  contentMargin: string = ElementsHTML.ContentMargin;
  check: string = Resources.GREEN_CHECK_FILL;

  hoursDate: string = formatDate(
    new Date(),
    CodeflexFormatDate.HoursMinutesSeconds,
    Constants.LOCALE_ES_MX
  );

  account: AccountModel;
  mainAccount: AccountModel;
  amount: IAmount;
  message: string = Strings.OPERATION_SUCCESSFUL;
  folio: string = Strings.EMPTY;
  operationDate: string = Strings.EMPTY;
  response: GenericResponse<GetWithdrawalsResponse>;
  errorMsg: string = Strings.EMPTY;
  otherOperation = PATH.Codeflex;
  numberAccount: string = Strings.EMPTY;
  buttonsSpacing: ButtonsSpacing = {
    mobile:  CodeflexElementsHTML.ButtonsSpacingMobile,
    desktop: CodeflexElementsHTML.ButtonsSpacingDesktop
  };
  captureImage: { name: string, element: HTMLElement };
  shareCapture: { name: string, element: HTMLElement };
  imageName: string = Strings.EMPTY;

  vm$ = this.responsiveService.observe.pipe(
    tap((breakpoints) =>
      breakpoints.mobile
        ? (this.contentMargin = ElementsHTML.ContentMargin)
        : (this.contentMargin = ElementsHTML.ContentMarginBreakpoints)
    ),
    combineLatestWith(
      this.accountCardService.accountCard$,
      this.notifyDateService.dateNgbStruct,
      this.accountInfoService.account$,
      this.accountCardService.arrayAccountCard$,
      this.notifyAmountService.amount$,
    ),
    map(([breakpoints, selectedCard, account, accounts, amount]) => ({
      breakpoints,
      selectedCard,
      date : this.notifyDateService.dateStruct.value ? this.notifyDateService.dateFormat : Strings.EMPTY,
      account,
      accounts,
      amount
    }))
  );

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly accountCardService: AccountCardService,
    private readonly notifyDateService: NotifyDateService,
    private readonly router: Router,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly modalService: ModalService,
    private readonly accountInfoService: AccountInfoStatusService,
    private readonly codeFlexService: CodeflexService,
    private readonly changeDetectorRef : ChangeDetectorRef,
    private readonly alertService: AlertService,
    private readonly accountsStatusService: AccountsStatusService
  ) {}

  async ngOnInit() {
    NavigationUtils.fixNavigation(this.changeDetectorRef,[PATH.Codeflex, PATH.CodeflexCompletedWithdrawal]);
    try{
      if(this.notifyAmountService.amount.amount===Strings.EMPTY){
        this.home();
      }else{
        this.account = this.accountInfoService.account;
        this.numberAccount = new MaskAccountPipe().transform(this.account.numeroCuenta, Numbers.Four);
        this.mainAccount = this.accountCardService.arrayAccountCard.filter(item => !item.tipoCuenta.toLowerCase().includes(CodeflexConstants.CODEFLEX_ROOT))[Position.Zero];
        this.amount = this.notifyAmountService.amount;
        await this.getDataModal();
      }
      const date = new Date();
      this.imageName = formatDate(date,CodeflexFormatDate.YearMonthDayTime, Constants.LOCALE_ES_MX);
      this.shareCapture = {
        name: this.imageName,
        element: document.getElementById(CodeflexElementsHTML.SuccessfulOperation)
      };
      this.captureImage = {
        name: this.imageName,
        element: document.getElementById(CodeflexElementsHTML.SuccessfulOperation)
      };
      this.changeDetectorRef.detectChanges();
    }catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg,errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT
      },{
        onClose:() => {
          this.home();
        },
        onSuccess:() => {
          this.home();
        }
      });
    }
  }

  home() {
    this.accountsStatusService.status = {value: false, isProductSelected: Strings.EMPTY, id: Numbers.Zero};
    this.router.navigate([PATH.Home]);
  }

  @HostListener('window:popstate')
  otherOperationButton() {
    this.notifyAmountService.amount = { amount: Strings.EMPTY };
    this.router.navigate([PATH.Codeflex]);
  }

  async getDataModal() {

    try {
      let days: number;
      if (this.account.tipoCuenta.includes(AccountType.CODEFLEX91)) {
        days = CodeflexConstants.CODEFLEX_DAYS + CodeflexConstants.CODEFLEX91_DAYS;
      } else {
        const dateEntered = this.notifyDateService.dateStructData;
        const dateUser = new Date(dateEntered.year, dateEntered.month - Constants.MONTH.ONE, dateEntered.day);
        const dateCurrently = new Date();
        const millisecondsDifference = DateUtils.differenceBetweenDates(dateUser, dateCurrently);
        days = DateUtils.millisecondsToDays(millisecondsDifference);
      }

      this.response = await this.codeFlexService.getWithdrawals(this.account.idCuentaProducto,
        this.mainAccount.idCuentaProducto, this.amount.amount, days);
      this.response.mensaje = Strings.OPERATION_SUCCESSFUL;

      const adjustDate = new Date(this.response.datos.fechaOperacion);
      adjustDate.setDate(adjustDate.getDate() + Numbers.One);

      this.response.datos.fechaOperacion = Utils.formatDate(adjustDate);
      this.folio = this.response.datos.folio.toString();
      this.operationDate = this.response.datos.fechaOperacion;
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.errorMsg = Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code);
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.modalService.close();
    this.notifyDateService.dateStructData = undefined;
  }

}
