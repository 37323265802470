import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SubscriptionLike, combineLatestWith, map } from 'rxjs';
import { IAccountModifyInfo } from 'src/app/interface/IAccountModifyInfo';
import { AccountModifyService } from 'src/app/services/info-accounts-edit.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { AgendaType, Char, Constants, Numbers, Position, Regex } from 'src/core/constants/Constants';
import { ServiceConstants } from 'src/core/constants/ServiceConstants';
import { Strings } from 'src/core/constants/Strings';
import { ProductUtils } from 'src/core/utils/ProductUtils';
import { Utils } from 'src/core/utils/utils';


@Component({
  selector: 'app-form-modify-account',
  templateUrl: './form-modify-account.component.html',
  styleUrls: ['./form-modify-account.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormModifyAccountComponent implements OnInit {
  @Output() isButtonDisabled = new EventEmitter<boolean>();
  formControl!: FormGroup;
  codeType: AgendaType;
  accountInfoSL: SubscriptionLike;
  limitData: string;
  disableAccountNumber: boolean = false;
  accountTypeText: string;
  agendaInternationalID: number = AgendaType.International;
  agendaOthersBanksID: number = AgendaType.OthersBanks;
  agendaThirdPartiesID: number = AgendaType.ThirdParties;
  serviceAgendaCode: number = AgendaType.AccountAddress;
  nonAgendableService: number = AgendaType.NonAgendableService;
  formControls = Constants.FORM_CONTROLS;
  decimalsLimit: number = Constants.TOTAL_DECIMALS;
  decimals = Constants.DECIMALS_AMOUNT;
  maxLength = ServiceConstants.MAXLENGTH_LIMIT_SERVICES;
  dailyLimit: number = Constants.DAILY_LENGTH_LIMIT;
  aliasLength: number = Constants.MAX_ALIAS_LENGTH;
  leadingSpacesLettersAccents: RegExp = ServiceConstants.LEADING_SPACES_LETTERS_ACCENTS;
  inputAlias: string = Strings.EMPTY;
  shouldHideLabel: boolean = false;
  shouldHideAliasLabel: boolean = false;
  regexAmount: RegExp = Regex.DifferentFromDecimals;
  originLimit: string = Strings.EMPTY;
  originAlias: string = Strings.EMPTY;
  originEmail: string = Strings.EMPTY;
  showLabelAlias: boolean;
  showLabelLimit: boolean;
  showLabelEmail: boolean;
  regexEmail: RegExp =  Regex.DenyValidateEmail;
  accountLabel = Strings.SERVICES_INPUT_TEXT;

  vm$ = this.accountModifyService.account$.pipe(
    combineLatestWith(this.responsiveService.observe),
    map(([accountInformation, breakpoints]) => ({
      accountInformation, breakpoints
    }))
  );

  constructor(private readonly formBuilder: FormBuilder,
    private readonly currencyPipe: CurrencyPipe,
    private readonly accountModifyService: AccountModifyService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly responsiveService: ResponsiveService) { }

  ngOnInit(): void {
    this.formControl = this.createForms();
    this.originLimit = this.accountModifyService.originData.limit ?? Strings.EMPTY;
    this.originAlias = this.accountModifyService.originData.alias?.trimEnd()?? Strings.EMPTY;
    this.originEmail = this.accountModifyService.originData.email ?? Strings.EMPTY;
    this.accountInfoSL = this.accountModifyService.account$.subscribe(response => {
      this.onChangeLine(response);
      this.codeType = response.type;
    });
    this.agendaTypeValidation();
    this.validateText();
    this.accountLabel = ProductUtils.getAccountLabel(this.codeType, this.serviceAgendaCode,
      this.accountModifyService.account.codeServices, Strings.SERVICES_INPUT_TEXT);
  }
  agendaTypeValidation() {
    this.disableAccountNumber = true;
    this.formControl.controls[this.formControls.MaximumLimit].setValidators(Validators.compose([this.validMaximumLimit()]));
  }

  validationForm(control: string) {
    switch (control) {
      case this.formControls.MaximumLimit:
        return this.formControl.controls[control]?.errors;
      case this.formControls.Alias:
        return this.formControl.controls[control]?.errors && this.formControl.controls[this.formControls.Alias].touched;
      case this.formControls.Email:
        const dataControl = this.formControl.controls[control];
        return this.validateEmail(dataControl);
      default:
        return (
          this.formControl.controls[control]?.errors && this.formControl.controls[control].touched
          &&
          this.formControl.controls[control].value
        );
    }
  }

  validateEmail(data: AbstractControl) {
    if (this.codeType === AgendaType.International || this.codeType === AgendaType.OthersBanks || AgendaType.ThirdParties) {
      const RegexEmail = new RegExp(Regex.EmailPattern);
      const validEmail = RegexEmail.test(data.value);
      if (data.value && !validEmail) {
        data.setErrors({ email : true });
      }
      return (data.errors && data.touched);
    } else {
      return null;
    }
  }

  validateLimits() {
    return (this.formControl.controls[this.formControls.MaximumLimit]?.errors?.[Constants.FORM_CONTROLS.ValidAmount] ?? false)
      && this.formControl.controls[this.formControls.MaximumLimit]?.touched;
  }

  validMaximumLimit(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const parts = control.value.split(Char.Dot);
      let text: string = control.value;
      if (parts.length === Constants.TOTAL_DECIMALS) {
        const decimalParts = parts[Position.One].slice(Position.Zero, Position.Two);
        text = `${parts[Constants.SERVICE_PAYMENT_ZERO]}${Char.Slash}${decimalParts}`;
      }
      const auxValue = text.toString().replace(Regex.EvaluateAmount, Strings.EMPTY);
      const value = Utils.transformAmount(auxValue);
      if (Number(value) > Constants.AMOUNT_ZERO) {
        return null;
      } else {
        return { validAmount: true };
      }
    };
  }

  validateText() {
    if (this.codeType === AgendaType.AccountAddress || Number(this.codeType) === AgendaType.ThirdParties) {
      this.accountTypeText = Strings.SERVICES_INPUT_TEXT;
    } else {
      this.accountTypeText = Strings.TRANSFERS_INPUT_TEXT;
    }
  }

  emailTrimInput() {
    const emailTrim = this.formControl.controls[this.formControls.Email].value.trim();
    this.formControl.patchValue({
      email: emailTrim
    });
  }

  createForms() {
    return this.formBuilder.group({
      alias: [Strings.EMPTY],
      maximumLimit: [Strings.EMPTY],
      cardNumber: [Strings.EMPTY],
      serviceName: [Strings.EMPTY],
      badge: [Strings.EMPTY],
      email: [Strings.EMPTY, [Validators.email]]
    });
  }

  async onChangeLine(dataCard: IAccountModifyInfo) {
    if (this.formControl != null) {
      this.formControl.controls[this.formControls.Alias]?.setValue(dataCard.alias);
      this.limitData = this.currencyPipe.transform(parseFloat(dataCard.maximumLimit));
      this.formControl.controls[this.formControls.MaximumLimit]?.setValue(this.limitData);
      this.formControl.controls[this.formControls.Email]?.setValue(dataCard.email);
      this.formControl.controls[this.formControls.Badge]?.setValue(dataCard.badge);
    }
  }

  formatMaximumLimit(limit: string) {
    const getMaximumLimit = this.formControl.get(limit);
    const stringWithoutComma = (getMaximumLimit.value).replace(Regex.MatchComma, Strings.EMPTY);
    const format = this.currencyPipe.transform(stringWithoutComma, Char.CurrencySymbol);
    getMaximumLimit.setValue(format);
  }

  isServicesAgenda() {
    if (this.codeType === AgendaType.AccountAddress || this.codeType === AgendaType.NonAgendableService) {
      return true;
    }
    else {
      return false;
    }
  }

  updateInformation() {
    this.formControl.markAllAsTouched();
    if (this.formControl.valid) {
      this.accountModifyService.modifyAccout = { value: true };
      const currentAccount = this.accountModifyService.account;
      this.accountModifyService.account = {
        ...currentAccount,
        maximumLimit: Utils.transformAmount(this.formControl.controls[this.formControls.MaximumLimit].value),
        alias: this.formControl.controls[this.formControls.Alias].value,
        email: this.formControl.controls[this.formControls.Email].value
      };
      return true;
    } else {
      this.changeDetector.detectChanges();
      return false;
    }
  }

  onFocusValidateInputModified() {
    const amount = this.formControl.controls[this.formControls.MaximumLimit].value;
    if (amount != null && Number(Utils.transformAmount(amount.toString())) === Constants.ZERO_CURRENCY) {
      this.formControl.controls[this.formControls.MaximumLimit].patchValue(Strings.EMPTY);
    }
    this.showLabelAlias = false;
    this.showLabelLimit = false;
    this.showLabelEmail = false;
  }

  validateInputZero() {
    if (!this.formControl.controls[this.formControls.MaximumLimit].value) {
      this.formControl.controls[this.formControls.MaximumLimit].patchValue(Constants.ZERO_VALUE_$);
    }
  }

  validateInputModified(isTyping: boolean, isAlias: boolean, isLimit: boolean, isEmail: boolean) {
    let newAlias = this.formControl.controls[this.formControls.Alias].value ?? Strings.EMPTY;
    newAlias = this.trimEndOfString(newAlias);
    let newLimit = this.formControl.controls[this.formControls.MaximumLimit].value ?? Strings.EMPTY;
    const newEmail = this.formControl.controls[this.formControls.Email].value ?? Strings.EMPTY;
    newLimit = Utils.transformAmount(newLimit);
    newLimit = parseFloat(newLimit).toFixed(Numbers.Two).toString();
    this.originLimit = parseFloat(this.originLimit).toFixed(Numbers.Two);
    const isAliasChanged = this.originAlias !== newAlias;
    const isLimitChanged = this.originLimit !== newLimit;
    const isEmailChanged = this.originEmail !== newEmail;
    if (isTyping && isAlias) {
      this.showLabelAlias = !isAliasChanged;
    }
    if (isTyping && isLimit) {
      this.showLabelLimit = !isLimitChanged;
    }
    if (isTyping && isEmail) {

      this.showLabelEmail = !isEmailChanged;
    }
    this.isButtonDisabled.emit(!(isAliasChanged || isLimitChanged || isEmailChanged));
    const emailControl = this.formControl.controls[this.formControls.Email];
    const isEmailInvalid = this.validateEmail(emailControl);
    if (isEmailInvalid) {
      this.isButtonDisabled.emit(true);
    }
  }

  trimEndOfString(inputString: string): string {
    return inputString.trimEnd();
  }

  ngOnDestroy(): void {
    this.accountInfoSL?.unsubscribe();
  }
}
