import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { map, tap } from 'rxjs';
import { AccountModel } from 'src/app/interface/AccountModel';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { MaskAccountPipe } from 'src/app/pipes/mask-account.pipe';
import { AccountCardService } from 'src/app/services/account-card.service';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { AlertService } from 'src/app/services/alert.service';
import { EnterAmountErrorService } from 'src/app/services/enter-amount-error.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { NotifyDateService } from 'src/app/services/notify-date.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { AccountType, Char, ElementsHTML, Numbers, Position } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { Resources } from 'src/core/constants/Resources';
import { ButtonsSpacing } from 'src/app/models/ButtonsSpacing';
import { AccountsStatusService } from 'src/app/services/accounts-status.service';
import { ProductsService } from 'src/app/services/products.service';
import { CodeflexElementsHTML, CodeflexConstants } from 'src/core/constants/CodeflexConstants';
import { CodeflexStrings } from 'src/core/constants/CodeflexStrings';
import { CodeflexService } from 'src/app/services/codeflex.service';
import { StringUtils } from 'src/core/utils/stringUtils';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';
import { ProductConstants } from 'src/core/constants/ProductConstants';

@Component({
  selector: 'app-codeflex-tie',
  templateUrl: './codeflex-tie.component.html',
  styleUrls: ['./codeflex-tie.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeflexTieComponent implements OnInit {
  vm$ = this.responsiveService.observe.pipe(
    tap((breakpoints) => {
      if (breakpoints.mobile) this.contentMargin = ElementsHTML.ContentMarginBreakpoints;
      if (breakpoints.tablet) this.contentMargin = ElementsHTML.ContentMarginCodeflexTablet;
      if (breakpoints.desktop) this.contentMargin = ElementsHTML.ContentMarginBreakpoints;
    }),
    map((breakpoints) => ({ breakpoints }))
  );

  contentMargin: ElementsHTML = ElementsHTML.ContentMargin;
  title: string = CodeflexStrings.CODEFLEX.Title;
  subtitle: string = Strings.CODEFLEX.Subtitle;
  account: AccountModel;
  customDate : Date;
  dateEvent: string = Strings.EMPTY;
  minDate: NgbDateStruct = CodeflexService.minDateCodeflex();
  maxDate: NgbDateStruct = CodeflexService.maxDateCodeflex();
  minAmount: number = Numbers.One;
  numberAccount : string = Strings.EMPTY;
  typeAccount: string = Strings.EMPTY;
  chevronRightIcon: string = Resources.SHAPE_CONTINUE;
  buttonsSpacing: ButtonsSpacing = {
    mobile:  CodeflexElementsHTML.ButtonsSpacingMobile,
    desktop: CodeflexElementsHTML.ButtonsSpacingDesktop
  };
  card: ICardAccount = {
    image: Strings.EMPTY,
    cardNumber: Strings.EMPTY,
    clabe: Strings.EMPTY,
    balance: Strings.EMPTY,
    selected: false,
    cardType: Strings.EMPTY
  };

  homePath: string = PATH.Home;
  date: NgbDateStruct = null;
  waitingTime: string = CodeflexStrings.WAITING_TIME(CodeflexConstants.CODEFLEX_DAYS_31);
  noBalanceMessage: string = CodeflexStrings.NO_BALANCE_AVAILABLE;

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly router: Router,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly notifyDateService: NotifyDateService,
    private readonly enterAmountErrorService: EnterAmountErrorService,
    private readonly accountCardService: AccountCardService,
    private readonly modalService: ModalService,
    private readonly accountInfoService: AccountInfoStatusService,
    private readonly alertService: AlertService,
    private readonly productService : ProductsService,
    private readonly accountsStatusService: AccountsStatusService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  async ngOnInit(): Promise<void> {
    ProductConstants.ORIGIN_CONSULT_DATA = ProductConstants.ORIGIN_CONSULT.Button;
    try{
      this.enterAmountErrorService.amountErrorData = { isError: false, errorMsg: Strings.EMPTY };
      if(this.accountInfoService.account.numeroCuenta===Strings.EMPTY){
        this.router.navigate([PATH.Home]);
      }else{
        this.account = this.accountInfoService.account;
        this.typeAccount = this.account.tipoCuenta;

        if(this.account.tipoCuenta.includes(AccountType.CODEFLEX91)){
          this.waitingTime = CodeflexStrings.WAITING_TIME(CodeflexConstants.CODEFLEX91_DAYS);
          this.customDate = new Date();
          this.customDate.setDate(this.customDate.getDate() + CodeflexConstants.CODEFLEX_DAYS + CodeflexConstants.CODEFLEX91_DAYS);
          this.notifyDateService.dateStructData =  {
            year: this.customDate.getFullYear(),
            month: this.customDate.getMonth() + Numbers.One,
            day: this.customDate.getDate()
          };
          this.minDate = this.notifyDateService.dateStructData;
        } else if (this.notifyDateService.dateStruct.value === (null || undefined)) {
          this.customDate = new Date();
          this.customDate.setDate(this.customDate.getDate() + CodeflexConstants.CODEFLEX_DAYS);
          this.customDate.setHours(Numbers.Zero, Numbers.Zero, Numbers.Zero, Numbers.Zero);
          this.notifyDateService.dateStructData =  {
            year: this.customDate.getFullYear(),
            month: this.customDate.getMonth() + Numbers.One,
            day: this.customDate.getDate()
          };
        }

        this.date = this.notifyDateService.dateStructData;

        this.title += this.account.descripcionCuenta;
        this.numberAccount = new MaskAccountPipe().transform(this.account.numeroCuenta, Numbers.Four);
        this.accountCardService.arrayAccountCard = (await this.productService.getAccounts({reload: false})).filter(item => item.numeroCuenta === this.account.numeroCuenta);
        const mainAccount = this.accountCardService.arrayAccountCard.filter(item => !item.tipoCuenta.toLowerCase().includes(CodeflexConstants.CODEFLEX_ROOT))[Position.Zero];

        this.card = {
          image      : mainAccount.directorioImagen,
          cardNumber : mainAccount.numeroCuenta,
          clabe      : StringUtils.formatClabe(mainAccount.cuentaClabe),
          balance    : mainAccount.saldos.disponible.toString(),
          selected   : false,
          cardType   : mainAccount.descripcionCuenta
        };
        this.accountsStatusService.status = {value: true, isProductSelected: this.account.tipoCuenta, id: this.account.idCuentaProducto};
      }

    }catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg,errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT
      },{
        onClose:() => {
          this.resetStatus();
          this.router.navigate([PATH.Home]);
        },
        onSuccess:() => {
          this.resetStatus();
          this.router.navigate([PATH.Home]);
        }
      });
    }
    NavigationUtils.fixNavigation(this.changeDetector,[PATH.Codeflex]);
  }

  resetStatus(){
    this.notifyDateService.dateStructData = undefined;
    this.accountsStatusService.status = {value: false, isProductSelected: Strings.EMPTY, id: Numbers.Zero};
  }

  continue() {
    let amountError: boolean = false;
    let dateError: boolean = false;
    const amount = this.notifyAmountService.amount.amount;
    amountError = this.validateAmount(amount);

    if (this.dateEvent !== Strings.EMPTY && !this.account.tipoCuenta.includes(AccountType.CODEFLEX91)) {
        const [day, month, year] = this.dateEvent.split(Char.Slash);
        this.notifyDateService.dateStructData = {
          day: parseInt(day),
          month: parseInt(month),
          year:parseInt(year)
        };
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + CodeflexConstants.CODEFLEX_DAYS);
        currentDate.setHours(Numbers.Zero, Numbers.Zero, Numbers.Zero, Numbers.Zero);

        const notifyDate = new Date(this.notifyDateService.dateStruct.value.year,
          this.notifyDateService.dateStruct.value.month - Numbers.One, this.notifyDateService.dateStruct.value.day);
        notifyDate.setHours(Numbers.Zero, Numbers.Zero, Numbers.Zero, Numbers.Zero);
        const MaxDate = new Date(this.maxDate.year, this.maxDate.month - Numbers.One, this.maxDate.day);

        if (notifyDate < currentDate || notifyDate > MaxDate) {
          return;
        }
          } else if (this.account.tipoCuenta.includes(AccountType.CODEFLEX91)) {
          this.notifyDateService.isErrorData = false;
          this.notifyDateService.dateStructData =  {
            year: this.customDate.getFullYear(),
            month: this.customDate.getMonth() + Numbers.One,
            day: this.customDate.getDate()
          };
    }

    dateError = !this.notifyDateService.dateStruct.value;
    this.notifyDateService.isErrorData = dateError;

    if (amountError || dateError) return;

    this.router.navigate([PATH.CodeflexConfirmWithdrawal]);
  }

  validateAmount(amount: string): boolean {
    const numericAmount = Number(Utils.transformAmount(amount));
    if(this.enterAmountErrorService.currentValue.isError){
      return true;
    }
    if (this.account.saldos.disponible === Numbers.Zero){
      this.enterAmountErrorService.amountErrorData = { isError: true, errorMsg: CodeflexStrings.NO_BALANCE_AVAILABLE };
      return true;
    }
    if (numericAmount === Numbers.Zero) {
      this.enterAmountErrorService.amountErrorData = { isError: true, errorMsg: Strings.GENERIC_ERRORS.Required };
      return true;
    }
    return false;
  }

  return() {
    this.notifyDateService.dateStructData = undefined;
    this.router.navigate([PATH.Home]);
  }

  ngOnDestroy() {
    this.modalService.close();
    ProductConstants.ORIGIN_CONSULT_DATA = ProductConstants.ORIGIN_CONSULT.BackHome;
  }
}
