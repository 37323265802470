<div *ngIf="vm$ | async as vs" id="payroll-credit-modal" class="modal-payroll-pwa">
  <div class="col-12 d-flex direction-icon align-btn-close">
    <div *ngIf="!isProcessSuccessPwa">
      <img [hidden]="(!iFrameLoadingInit || !iFrameLoad)"
      role="button" aria-label="Close" width="auto" height="auto" [src]="closeIcon | cdnImage" alt="Cerrar"
      class="close" (click)="onlyClose()">
    </div>
  </div>
  <div class="d-flex  col-12 direction-icon">
    <iframe [hidden]="(!iFrameLoadingInit || !iFrameLoad)" #frame [ngClass]="iFrameClass" title=""
      [src]="urlIframe | safe" >
    </iframe>
    <app-error-exception [isScrollTop]="false" [hidden]="!(!iFrameLoadingInit || !iFrameLoad)"
      [cleanAcountService]="false" [homePage]="homePage" [showOtherOperation]="false"
      (navigationOtherOperation)="closeOption($event)" [titleError]="errorTitleMessagePWA"
      [showButtons]="false" [errorMessage]="errorMessagePWA"></app-error-exception>
  </div>
  <div *ngIf="(!iFrameLoadingInit || !iFrameLoad)"
    class="d-flex col-12 justify-content-center align-items-center mobile-contain mb-4">
    <button class="button-m main btn-generic colored-btn" (click)="closeOption($event)">
      Aceptar
    </button>
  </div>
</div>

<ng-template #modalToken>
  <app-modal-help-token  (onCaptureMessage)="closedModal()" [modalId]="modalHelpToken"></app-modal-help-token>
</ng-template>
