import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Constants, AgendaType, Numbers, InputTypes } from 'src/core/constants/Constants';
import { Utils } from 'src/core/utils/utils';
import { CancelAgendaRequest } from 'src/app/interface/dto/CancelServicesAgendasRequest';
import { CancelAgendaResponse } from 'src/app/interface/dto/CancelServicesAgendasResponse';
import { CancelInternationalAgendaRequest } from 'src/app/interface/dto/CancelInternationalAgendasRequest';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalService } from 'src/app/shared/modal.service';
import { AlertService } from 'src/app/services/alert.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { AccountModifyService } from 'src/app/services/info-accounts-edit.service';
import { StorageService } from 'src/app/services/storage.service';
import { CancelInternationalAgendaResponse } from 'src/app/interface/dto/CancelInternationalAgendasResponse';
import { ServiceCardService } from 'src/app/services/service-card.service';
import { CardAddresseeService } from 'src/app/services/card-addressee.service';
import { CancelOthersBanksRequest } from 'src/app/interface/ICancelOthersBanksRequest';
import { CancelOthersBanksResponse } from 'src/app/interface/ICancelOthersBanksResponse';
import { CancelThirdAgendaRequest } from 'src/app/interface/dto/ICancelThirdAgendaRequest';
import { ICancelThirdAgendaResponse } from 'src/app/interface/dto/ICancelThirdAgendaResponse';
import { DataDeleteAgenda } from 'src/app/services/data-delete-agenda';
import { IAccountModifyInfo } from 'src/app/interface/IAccountModifyInfo';
import { Strings } from 'src/core/constants/Strings';
import { Resources } from 'src/core/constants/Resources';
import { PATH } from 'src/core/constants/Path';
import { EllipsisTextPipe } from 'src/app/pipes/ellipsis-text.pipe';
import { ServiceConstants } from 'src/core/constants/ServiceConstants';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ModalModifyAccountComponent } from 'src/app/component/modal-modify-account/modal-modify-account.component';
import { HTMLConstants } from 'src/core/constants/HTMLConstants';
import { SubscriptionLike } from 'rxjs';
@Component({
  selector: 'app-modal-delete-addressee',
  templateUrl: './modal-delete-addressee.component.html',
  styleUrls: ['./modal-delete-addressee.component.css'],
})
export class ModalDeleteAddresseeComponent implements OnInit, OnDestroy {
  @ViewChild('cancelAccount', { static: true }) cancelAccount: ElementRef;
  @ViewChild('addresseeModal', { static: true }) addresseeModal: ElementRef;
  showModalComplete:boolean = false;
  modalInstance: NgbModalRef;
  otpDelete: string = Strings.EMPTY;
  codeType: number = Numbers.Zero;
  closeTokenModal: NgbModalRef;
  labelMain:string = Strings.EMPTY;
  labelMainConfirm: string = Strings.EMPTY;
  closeModal: boolean = true;
  modalData: {formInstance: NgbModalRef, modalFormInstance?: boolean, modifiyModal?: boolean};
  dataAccount: IAccountModifyInfo;
  questionMarkCrIcon: string = Resources.CIRCLE_BLUE_QUESTION;
  checkFillIcon: string = Resources.GREEN_CHECK_FILL;
  headerToken: string = Strings.SECURITY_CODE.TitleToken;
  tokenContainerClass: string = ServiceConstants.TOKEN_CONTAINER_CLASS;
  accountSL: SubscriptionLike;
  statusSL: SubscriptionLike;
  nameAccount: string = Strings.EMPTY;
  nameAccountEllipsis: string = Strings.EMPTY;

  constructor(
    private readonly modalService: ModalService,
    private readonly storage: StorageService,
    private readonly baseService: IBaseService,
    private readonly accountModifyService: AccountModifyService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly serviceCardService: ServiceCardService,
    private readonly cardAddresseeService: CardAddresseeService,
    private readonly dataDeleteAgenda: DataDeleteAgenda
  ) {}


  async ngOnInit() {
    this.accountSL = this.accountModifyService.account$.subscribe((data) => {
      this.codeType = data.type;
      this.dataAccount = data;
    });
    this.statusSL = this.dataDeleteAgenda.status$.subscribe((data) => {
       this.labelMainConfirm = data.message;
       if(this.labelMainConfirm){
        this.closeModal= true;
        this.showModalComplete = true;
      }else{
        this.closeModal= false;
        this.showModalComplete = false;
      }
    });
    this.getDataInformation(this.dataAccount);
  }

  ngOnDestroy(): void {
    this.accountSL?.unsubscribe();
    this.statusSL?.unsubscribe();
  }

  showModal() {
    const modalElement = document.getElementById(HTMLConstants.ID_MODAL.ModalContainerAddressee);
    const modalAncestor = modalElement?.parentElement?.parentElement;
    modalAncestor?.classList?.remove(Constants.MODAL_OPTIONS.HiddenContainer);
    if (modalElement && modalElement?.style) {
      modalElement.style.display = InputTypes.Block;
    }
    this.closeModal = true;
    this.modalService.close();
  }

  agendaCancel() {
    this.modalService.setYesToken(true);
    this.modalInstance?.dismiss();
    const modalOptions: ModalOptions = {
      modalDialogClass: ModalConstants.MODAL_OPTIONS.DeleteOtp,
      size: Constants.MODAL_OPTIONS.SizeMd,
      centered: true
    };
    this.closeTokenModal = this.modalService.open(
      this.cancelAccount,
      modalOptions
    );
    this.closeModal= true;
    this.modalInstance?.dismiss();
  }

  close(){
    this.modalInstance.dismiss();
    const modalOptions: ModalOptions = {
      centered: true,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalModifyAccount
    };
    if (this.modalData.modifiyModal) {
      this.modalService.open(ModalModifyAccountComponent, modalOptions);
    }
  }

  async getOtpDelete(dataForm: string) {
    this.closeModal = true;
    this.closeTokenModal.close();
    this.otpDelete = dataForm[Constants.OTP_POSITION];
    try {
      switch (this.codeType) {
        case AgendaType.OthersBanks:
          await this.cancelOthersBanks();
          break;
        case AgendaType.ThirdParties:
          await this.cancelThirdAgenda();
          break;
          case AgendaType.International:
          await this.cancelInternationalAgenda();
          break;
        case AgendaType.AccountAddress:
          await this.deleteServiceAgenda();
          break;
        default:
          break;
      }
      this.showModalComplete = true;
    } catch (error) {
      this.modalService.close();
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      }, {
        onSuccess: () => {
          this.modalService.close();
        }
      });
    }
  }

  getDataInformation(data :IAccountModifyInfo ) {
    const headline = data.headline;
    const name = data.name;
    const account = data.account;
    const bank = data.bank;
    let nameAccountEllipsisTemp = headline ? headline: name;
    if(!nameAccountEllipsisTemp){
      nameAccountEllipsisTemp = Strings.EMPTY;
    }
    this.nameAccount = nameAccountEllipsisTemp;
    this.nameAccountEllipsis = new EllipsisTextPipe().transform(nameAccountEllipsisTemp, Numbers.Fifteen);
    const withCardNumber = Strings.WITH_CARD_NUMBER;
    const withAccountNumber = Strings.WITH_ACCOUNT_NUMBER;
    switch (this.codeType) {
      case AgendaType.ThirdParties:
        this.labelMain = `${withCardNumber} ${bank} ${account}`;
        break;
      case AgendaType.AccountAddress:
        this.labelMain = `${withAccountNumber} ${account}`;
        break;
      default:
        this.labelMain = `${withCardNumber} ${account}`;
        break;
    }
  }

  dismissModals() {
    this.dataDeleteAgenda.status = {message: Strings.EMPTY};
    if(this.router.url === PATH.SchedulableServices){
      this.router.navigate([PATH.Services]);
    }
    this.modalInstance?.close();
    this.modalService.close();
  }

  dataComplete(headline: string, account: string) {
    if(!headline){
      headline = Strings.EMPTY;
    }
    const headlineDelete = new EllipsisTextPipe().transform(headline, Numbers.Fifteen);
    this.labelMainConfirm = `${Strings.MSG_ACCOUNT_DELETE_DESCRIPTION} ${headlineDelete} ${Strings.WITH_ACCOUNT_NUMBER} ${account}`;
    this.dataDeleteAgenda.status = { message: this.labelMainConfirm };
    const modalOptions: ModalOptions = {
      modalDialogClass: ModalConstants.MODAL_OPTIONS.DeleteAgenda,
      size: Constants.MODAL_OPTIONS.SizeXl,
      centered: true
    };
    this.modalService.open(ModalDeleteAddresseeComponent, modalOptions);
  }

  public async cancelOthersBanks() {
    const request = new CancelOthersBanksRequest({
      idSesion: this.storage.getSession(),
      idAgenda: this.accountModifyService.account.idAgent,
      nombreAplicacion: Constants.BANK,
      tipoAcceso: Constants.ACCESS_TOKEN_TYPE,
      TipoAgenda: Constants.NATIONAL_AGENDAS,
      Token: {
        OTP: this.otpDelete,
      }
    });
    const response = await this.baseService.genericPost<CancelOthersBanksResponse>(request, { isSOA: true });
    if (parseInt(String(response.error.no)) === Constants.MSG_SERVICE_RESPONSE_SUCCESSFUL
        || parseInt(String(response.error.No)) === Constants.MSG_SERVICE_RESPONSE_SUCCESSFUL ) {
      await this.cardAddresseeService.getInitialData();
      const selectedAddressee = this.cardAddresseeService.selectedCardAddressee;
      const addresseeToCancel = this.accountModifyService.account;
      if (selectedAddressee.idAgenda === addresseeToCancel.idAgent) {
        this.cardAddresseeService.clearCardAddressee();
      }
      const headline = this.accountModifyService.account.name;
      const account = this.accountModifyService.account.account;
      this.dataComplete(headline, account);
      this.cardAddresseeService.removeAddressee(this.accountModifyService.account.idAgent);
    }
  }

  public async cancelInternationalAgenda() {
    const request = new CancelInternationalAgendaRequest({
      IdSession: this.storage.getSession(),
      IdAgenda: this.accountModifyService.account.idAgent.toString(),
      TipoAgenda: this.accountModifyService.account.agendaType,
      NumeroCuenta: null,
      TipoAcceso: Constants.ACCESS_TOKEN_TYPE,
      Token: {
        OTP: this.otpDelete,
        HAC: null,
        NumeroDeSerie: null
      }
    });
    const response = await this.baseService.genericPut<CancelInternationalAgendaResponse>(request,{ isSOA: true });
    if (parseInt(String(response.Error.No)) === Constants.MSG_SERVICE_RESPONSE_SUCCESSFUL
        || parseInt(String(response.Error.no)) === Constants.MSG_SERVICE_RESPONSE_SUCCESSFUL) {
      await this.cardAddresseeService.getInternationals();
      const selectedAddressee = this.cardAddresseeService.selectedCardAddressee;
      const addresseeToModify = this.accountModifyService.account;
      if (selectedAddressee.idAgenda === addresseeToModify.idAgent) {
        this.cardAddresseeService.clearCardAddressee();
      }
      const headline = this.accountModifyService.account.headline;
      const account = this.accountModifyService.account.account;
      this.dataComplete(headline, account);
      this.cardAddresseeService.removeAddressee(this.accountModifyService.account.idAgent);
    }
  }

  public async cancelThirdAgenda() {
    const request = new CancelThirdAgendaRequest({
      idAgenda: this.accountModifyService.account.idAgent,
      idSesion: this.storage.getSession(),
      tipoAgenda: Constants.THIRD_AGENDAS,
      Token: {
        OTP: this.otpDelete
      },
      tipoAcceso: Constants.ACCESS_TOKEN_TYPE,
      nombreAplicacion: Constants.BANK
    });
    await this.baseService.genericPost<ICancelThirdAgendaResponse>(request, { isSOA: true });
    await this.cardAddresseeService.getThirdPartyAccounts();
    const selectedAddressee = this.cardAddresseeService.selectedCardAddressee;
    const addresseeToModify = this.accountModifyService.account;
    if (selectedAddressee.idAgenda === addresseeToModify.idAgent) {
      this.cardAddresseeService.clearCardAddressee();
    }
    const headline = this.accountModifyService.account.name ? this.accountModifyService.account.name : this.accountModifyService.account.headline;
    const account = this.accountModifyService.account.account;
    this.dataComplete(headline, account);
    this.cardAddresseeService.removeAddressee(this.accountModifyService.account.idAgent);
  }

  public async deleteServiceAgenda() {
    try {
      const dataCard = this.accountModifyService.account;
      const request = new CancelAgendaRequest({
        idSesion: this.storage.getSession(),
        idAgenda: dataCard.idAgent,
        tipoAgenda: dataCard.agendaType,
        Token: {
          OTP: this.otpDelete,
        },
        tipoAcceso: Constants.ACCESS_TOKEN_TYPE,
        nombreAplicacion: Constants.BANK
      });
      const response = await this.baseService.genericPost<CancelAgendaResponse>(request,{ isSOA: true });
      if (response && response.error && response.error.no
          && (parseInt(String(response.error.no)) === Constants.MSG_SERVICE_RESPONSE_SUCCESSFUL
              || parseInt(String(response.error.No)) === Constants.MSG_SERVICE_RESPONSE_SUCCESSFUL)) {
        await this.serviceCardService.getInitialData();
        const name = this.accountModifyService.account.name;
        const account = this.accountModifyService.account.account;
        this.dataComplete(name,account);
      }
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert(
        {
          message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
          header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
          imgHead: Resources.CLOSE_ICON_RED,
          btnExit: false
        },
        {
          onSuccess: () => {
          this.router.navigate([PATH.Services]);
          this.modalService.close();
          }
        }
      );
    }
  }
}
