import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, combineLatestWith, EMPTY, from, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionManagerService } from 'src/app/services/session-manager.service';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalLogoutComponent } from 'src/app/shared/components/modal-logout/modal-logout.component';
import { ModalService } from 'src/app/shared/modal.service';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { Constants, Numbers } from 'src/core/constants/Constants';
import { Utils } from 'src/core/utils/utils';
import { Resources } from 'src/core/constants/Resources';
import { StorageService } from 'src/app/services/storage.service';
import { SidebarResponsiveService } from 'src/app/services/sidebar-responsive.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { StateProfileService } from 'src/app/services/save-profile.service';
import { PATH } from 'src/core/constants/Path';

@Component({
  selector: 'app-navbar-header-component',
  templateUrl: './navbar-header-component.component.html',
  styleUrls: ['./navbar-header-component.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarHeaderComponentComponent {
  $profile = this.stateProfileService.profile$.pipe(
    combineLatestWith(
      from(this.stateProfileService.reloadImages({ thumbnail: true }))
    ),
    map(([profile]) => this.getProfileImage(profile?.thumbnail))
  );
  products = Numbers.Five;

  vm$ = of(this.storageService.getUser()).pipe(
    catchError(err => {
      Utils.printLogError(err);
      return EMPTY;
    }),
    map(user => {
      if (!user) return user;
      const u = user;
      u.name = user?.name?.gfiCapitalize();
      return u;
    }),
    combineLatestWith(
      this.responsiveService.observe,
      of(this.products)
    ),
    map(([user, breakpoints, products]) => ({
      user,
      breakpoints,
      products
    }))
  );

  shoppingTagIcon: string = Resources.BLUE_SHOPPING_TAG;
  gridBlueIcon: string = Resources.GRID_FOUR_BLUE;
  userBlueIcon: string = Resources.USER_BLUE;
  chatDotsIcon: string = Resources.CHAT_DOTS;
  historyIcon: string = Resources.FILE_CHECK;
  plusBlueIcon: string = Resources.SQUARE_PLUS_LIGHT_BLUE;
  downIcon: string = Resources.LIGHT_BLUE_ARROW_DOWN;
  logoutIcon: string = Resources.LOGOUT_RESPONSIVE;

  additionalPath: string = PATH.Additional;
  beta: boolean = environment.beta;
  hiddenHistory: boolean= environment.hiddenComponents.history;
  visibilityAccountBalance: boolean = environment.hiddenComponents.visibilityAccountBalance;
  constructor(
    private readonly storageService: StorageService,
    private readonly responsiveService: ResponsiveService,
    private readonly router: Router,
    private readonly sidebarResponsiveService: SidebarResponsiveService,
    private readonly stateProfileService: StateProfileService,
    private readonly modalService: ModalService,
    private readonly sessionManager: SessionManagerService,
    private readonly accountInfoService: AccountInfoStatusService
  ) { }

  goGeneralInformation() {
    this.router.navigate([PATH.GeneralInformation]);
  }

  goFrequentQuestions() {
    this.router.navigate([PATH.FrequentlyQuestions]);
  }

  redirectToAccessHistory() {
    this.router.navigate([PATH.AccessHistory]);
  }

  goBeneficiary() {
    this.router.navigate([PATH.Beneficiaries]);
  }

  goAdditional(){
    this.router.navigate([PATH.Additional]);
  }
  closeSidebar() {
    this.sidebarResponsiveService.stateData = false;
  }

  private getProfileImage(image: string) {
    image = !image ? Resources.CDN_URL(Resources.USER_IMAGES.Thumbnail) :
      Constants.IMAGE_DATA === image ? Resources.CDN_URL(Resources.USER_IMAGES.ErrorImageNav) :
        Utils.getImageSource(image);

    return { image };
  }

  closeSession() {
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeMd,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.SessionCloseModal
    };
    const ref  = this.modalService.open(ModalLogoutComponent, modalOptions);
    ref.componentInstance.inactivity = false;
    ref.closed.subscribe( async answer => {
      if (answer) {
        await this.sessionManager.closeSession({ redirect: true });
        this.accountInfoService.clearAccount();
      }
    });
  }

  get userId(){
    return this.storageService.getUser()?.personId;
  }
}
