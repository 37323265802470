import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { StorageService } from 'src/app/services/storage.service';
import { PaymentAgendableServicesResponse } from 'src/app/interface/dto/PaymentAgendableServicesResponse';
import { PaymentAgendableServicesRequest } from 'src/app/interface/dto/PaymentAgendableServicesRequest';
import { Utils } from 'src/core/utils/utils';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { Strings } from 'src/core/constants/Strings';
import { Constants, Numbers } from 'src/core/constants/Constants';
import { HttpStatusCode } from '@angular/common/http';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { ServicePaymentTypeService } from 'src/app/services/service-payment-type.service';
import { CreditCardInfoStatusService } from 'src/app/services/credit-card-info-status.service';
import { PATH } from 'src/core/constants/Path';
import { IGetAddresseeResponse } from 'src/app/interface/IGetAddresseeResponse';
import { GetAddresseeRequest } from 'src/app/interface/IGetAddresseeRequest';
import { CancelOthersBanksRequest } from 'src/app/interface/ICancelOthersBanksRequest';
import { CancelOthersBanksResponse } from 'src/app/interface/ICancelOthersBanksResponse';
import { AddCreditOtherBankRequest } from 'src/app/interface/dto/AddCreditOtherBankRequest';
import { AddCreditOtherBankResponse } from 'src/app/interface/dto/AddCreditOtherBankResponse';
import { NationalTransferRequest } from 'src/app/interface/dto/NationalTransferRequest';
import { NationalTransferResponse } from 'src/app/interface/dto/NationalTransferResponse';
import { GetCatalogAvailableBanksResponse } from 'src/app/interface/dto/GetCatalogAvailableBanksResponse';
import { ServiceConstants } from 'src/core/constants/ServiceConstants';
import { Resources } from 'src/core/constants/Resources';
import { OptionsBanks } from 'src/app/services/type-credit-other-banks';
import { GetCatalogAvailableBanksRequest } from '../interface/dto/GetCatalogAvailableBanksRequest';

export interface ISelectedService {
  id: string,
  name: string,
  imgRoute: string
}
export interface CreditRemoved {
  typeCredit: string
  bankAccount: string
  bank?: string
}

@Injectable({
  providedIn: 'root',
})
export class ServiceCardService {
  private readonly _isShowAlert: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly deleteCredit$: BehaviorSubject<CreditRemoved> = new BehaviorSubject<CreditRemoved>({
    typeCredit: Strings.EMPTY,
    bankAccount: Strings.EMPTY,
    bank: Strings.EMPTY
  });
  private readonly _hasServicesResult: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _data: BehaviorSubject<PaymentAgendableServicesResponse> =
    new BehaviorSubject<PaymentAgendableServicesResponse>({
      serviciosAgendables: []
    });

    private readonly _allServices: BehaviorSubject<{
    serviceScheduled: Array<Omit<ISelectedService, 'imgRoute'>>
  }> = new BehaviorSubject<{
    serviceScheduled: Array<Omit<ISelectedService, 'imgRoute'>>
  }>({
    serviceScheduled: [
      {
        id: Constants.AGENDABLE_SERVICE_CODE.Telmex,
        name: Strings.PROPIETARY.TelmexValue,
      },
      {
        id: Constants.SERVICE_CODE.Carnet,
        name: Constants.SERVICE_CODE.Carnet,
      },
      {
        id: Constants.AGENDABLE_SERVICE_CODE.Sky,
        name: Constants.AGENDABLE_SERVICE_CODE.Sky,
      },
      {
        id: Constants.AGENDABLE_SERVICE_CODE.Amex,
        name: Strings.PROPIETARY.AmexValue,
      },
      {
        id: Constants.AGENDABLE_SERVICE_CODE.Mixup,
        name: Constants.AGENDABLE_SERVICE_CODE.Mixup,
      },
      {
        id: Constants.AGENDABLE_SERVICE_CODE.Sanborns,
        name: Strings.PROPIETARY.SanbornsValue,
      },
      {
        id: Constants.AGENDABLE_SERVICE_CODE.Amitelc,
        name: Strings.PROPIETARY.AmitelcValue,
      },
      {
        id: Constants.AGENDABLE_SERVICE_CODE.Contelc,
        name: Strings.PROPIETARY.ContelcValue,
      },
      {
        id: Constants.AGENDABLE_SERVICE_CODE.Tcinb,
        name: Strings.PROPIETARY.InbursaCardValue,
      },
      {
        id: Constants.AGENDABLE_SERVICE_CODE.Pagliver,
        name: Strings.PROPIETARY.LiverpoolValue,
      },
      {
        id: Constants.AGENDABLE_SERVICE_CODE.Pagoph,
        name: Strings.PROPIETARY.PalacioDeHierroValue,
      },
      {
        id: Constants.AGENDABLE_SERVICE_CODE.Iave,
        name: Strings.ADD_SERVICE.TagValue,
      },
    ]
  });

  private readonly _selectedService: BehaviorSubject<ISelectedService> = new BehaviorSubject<ISelectedService>({
    id: Strings.EMPTY,
    name: Strings.EMPTY,
    imgRoute: Strings.EMPTY
  });

  constructor(
    private readonly baseService: IBaseService,
    private readonly storageService: StorageService,
    private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly creditCardInfoService: CreditCardInfoStatusService,
    private readonly stateServicePayIdService: StateServicePayIdService,
    private readonly servicePaymentTypeService: ServicePaymentTypeService,
    private readonly storage: StorageService,
  ) {}

  private readonly _hiddenResultScheduledServices: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get hiddenResultScheduledServices$() {
    return this._hiddenResultScheduledServices.asObservable();
  }
  get hiddenResultScheduledServices(): boolean {
    return this._hiddenResultScheduledServices.getValue();
  }
  set hiddenResultScheduledServices(data: boolean) {
    this._hiddenResultScheduledServices.next(data);
  }

  private async payService() {
    try {
      const request: PaymentAgendableServicesRequest =
        new PaymentAgendableServicesRequest({
          idSesion: this.storageService.getSession()
        });
      const response = await this.baseService.genericPost<PaymentAgendableServicesResponse>(request, { isSOA: true });
      if(response.error?.no) {
        if (response.error.no.toString() === HttpStatusCode.NoContent.toString()) {
          response.serviciosAgendables = [];
        } else if (response.error.no.toString() !== Numbers.Zero.toString()) {
          throw new BusinessError(String(response.error.no));
        }
      }
      response.serviciosAgendables = response.serviciosAgendables.filter(service => service.codigoServicio !== Constants.SERVICE_CODE.ExternalTrade);
      await this.getCreditOtherBanks(response);
      this.responseData = response;
      this.responseData.serviciosAgendables.sort((a, b) => a.descripcionServicio.localeCompare(b.descripcionServicio));
      this.hiddenResultScheduledServices = false;
    } catch (error) {
      this.hiddenResultScheduledServices = true;
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        imgHead: Resources.CLOSE_ICON_RED,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        btnExit: false
      }, {
        onSuccess: () => Utils.navigationError(this.router)
      });
    }
  }

  async getCreditOtherBanks(responseData: PaymentAgendableServicesResponse){
    const credits = await this.creditOtherBanks();
    if (credits && credits?.cuentas?.length > Numbers.Zero){
      credits.cuentas.forEach(credit => (
        responseData.serviciosAgendables.push({
        descripcionServicio: credit.descripcionTipoCuentaNumero,
        codigoServicio: ServiceConstants.CREDIT_OTHER_BANKS_ID,
        aliasAgenda: credit.alias,
        esModificable: credit.esModificable,
        tiempoEspera: credit.tiempoEspera,
        esOperable: credit.esOperable,
        descripcionReferencia: credit.numeroCuenta,
        nombreBanco: credit.banco,
        tipoToken: Strings.EMPTY,
        email3: Strings.EMPTY,
        estatusAgenda: Strings.EMPTY,
        email2: Strings.EMPTY,
        limiteDiarioAcumulado: credit.limiteDiarioAcumulado,
        limiteDiario: credit.limiteDiario,
        fechaCaptura: credit.fechaCaptura,
        idAgenda: credit.idAgenda,
        fechaUltimaModReal: credit.fechaUltimaModReal,
        telefono: Strings.EMPTY,
        fechaPrimeraTransaccion: Strings.EMPTY,
        email: Strings.EMPTY,
        fechaUltimaModificacion: Strings.EMPTY,
        testImage: Strings.EMPTY,
        holder: credit.titular,
        codeBank: credit.codigoBanco
      })));
    }
  }

  get isShowAlert$() {
    return this._isShowAlert.asObservable();
  }

  get isShowAlert (){
    return this._isShowAlert.getValue();
  }

  set isShowAlert(data: boolean){
    this._isShowAlert.next(data);
  }

  get creditSelected() {
    return this.deleteCredit$.asObservable();
  }
  get creditSelected$() {
    return this.deleteCredit$.getValue();
  }

  set creditSelectedSet(data: CreditRemoved) {
    this.deleteCredit$.next(data);
  }

  get data() {
    return this._data.asObservable();
  }

  set responseData(data: PaymentAgendableServicesResponse) {
    this._data.next(data);
  }

  get responseData(): PaymentAgendableServicesResponse {
    return this._data.getValue();
  }

  get scheduledServices() {
    return this._allServices.asObservable();
  }

  public async getInitialData() {
    await this.payService();
    this.hasServicesResult = true;
  }

  set selectedService(data: ISelectedService ) {
    this._selectedService.next(data);
  }

  get selectedService() {
    return this._selectedService.getValue();
  }

  get selectedService$() {
    return this._selectedService.asObservable();
  }

  get hasServicesResult$() {
    return this._hasServicesResult.asObservable();
  }

  get hasServicesResult(){
    return this._hasServicesResult.getValue();
  }

  set hasServicesResult(data: boolean) {
    this._hasServicesResult.next(data);
  }

  clearServiceCard(){
    this._selectedService.next({id: Strings.EMPTY,
    name: Strings.EMPTY,
    imgRoute: Strings.EMPTY});
  }

  /**
   * Limpia el tipo de pago existente y establece un nuevo servicio con los detalles de la tarjeta de crédito
   * Navega al módulo de pago de servicios agendables desde el botón Pagar TDC en la sección de información
   * en el Home de Tarjetas de crédito.
   * @param isResponsive Un booleano que señala si la función navegará al módulo de Servicios Agendables si
   * el entorno actual no es en dispositivos móviles.
  */
  goToPayCreditCard(isResponsive: boolean) {
    this.servicePaymentTypeService.clearPaymentType();
    this.stateServicePayIdService.service = {
      id: Constants.AGENDABLE_SERVICE_CODE.Tcinb,
      params: {
        name: `${Strings.PROPIETARY.CardValue} ${this.creditCardInfoService.creditCard.tipoCuenta}`,
        dailyLimit: Constants.MAXIMUM_OPERATION_AMOUNT,
        cumulativeDailyLimit: Numbers.Zero,
        alias: this.creditCardInfoService.creditCard.alias,
        reference: this.creditCardInfoService.creditCard.numeroTarjeta.toString(),
        idAgenda: parseInt(this.creditCardInfoService.creditCard.numeroCuenta, Numbers.Ten),
        esTDC: true,
        continuaSinAgenda: Constants.IS_AGENDA,
        phoneNumber: Strings.EMPTY,
      }
    };
    if (!isResponsive) {
      this.router.navigate([PATH.SchedulableServices]);
    }
  }

  async creditOtherBanks(): Promise<IGetAddresseeResponse> {
    let response: IGetAddresseeResponse;
    const request: GetAddresseeRequest = new GetAddresseeRequest({
      idSesion: this.storageService.getSession(),
      origen: Constants.ACCESS_METHOD_PORTAL,
      agenda: ServiceConstants.TYPE_SERVICE
    });
    try {
      response = await this.baseService.genericPost<IGetAddresseeResponse>(request, { isSOA: true });

      this.isShowAlert=false;
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.isShowAlert = errorDetails.code.toString()!== Constants.AGENDAS_TRANSFER_CODE;
    }
    return response;
  }

  async deleteService(idService: number, typeAddress: string, otp: string) {
    const request = new CancelOthersBanksRequest({
      idSesion: this.storage.getSession(),
      idAgenda: idService,
      nombreAplicacion: Constants.ACCESS_METHOD_PORTAL,
      tipoAcceso: Constants.ACCESS_TOKEN_TYPE,
      TipoAgenda: typeAddress,
      Token: {
        OTP: otp
      }
    });
    const response = await this.baseService.genericPost<CancelOthersBanksResponse>(request, { isSOA: true });
    if (response?.error?.no.toString() !== Constants.MSG_SERVICE_RESPONSE_SUCCESSFUL.toString()) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response;
  }

  async addCreditOtherBank(data: AddCreditOtherBankRequest) {
    const response = await this.baseService.genericPost<AddCreditOtherBankResponse>(data, { isSOA: true });
    if (!response?.idAgenda) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response;
  }

  async paymentCreditNationalTransfer(data: NationalTransferRequest) {
    const nationalTransferRequest = data;
    const response = await this.baseService.genericPost<NationalTransferResponse>(nationalTransferRequest, { isSOA: true });
    if (!response?.idAutorizacion) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
    }
    return response;
  }

  async getCatalogAvailableBanks(data: GetCatalogAvailableBanksRequest): Promise<Array<OptionsBanks>> {
    const response = await this.baseService.genericPost<GetCatalogAvailableBanksResponse>(data, { isSOA: true });
    response.bancosAgendas.sort((a, b) => a.nombreBanco?.localeCompare(b.nombreBanco));
    return response.bancosAgendas.map(item => ({
      value: item.codigoBanco, label: item.nombreBanco
    }));
  }
}
