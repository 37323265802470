export class AdditionalConstants {

    static readonly MAXIMUM_LENGTH_EMAIL_ADDITIONAL = 100;
    static readonly MAXIMUM_LENGTH_ADDITIONAL_INFORMATION = 50;
    static readonly MALE = 'Masculino';
    static readonly FEMALE = 'Femenino';
    static readonly UPDATE_ADDITIONAL = 'UPDATE';
    static readonly MIN_DATE_ADDITIONAL = { year: 1923, month: 1, day: 1 };
    static readonly ERASE_ADDITIONAL = 'ERASE';
    static readonly MAX_DATE_ADDITIONAL = { year: 2008, month: 12, day: 31 };
    static readonly MAXIMUM_LENGTH_AMOUNT_ADDITIONAL = 6;
    static readonly NEXT_ELEMENT = (index: number) => `.swiper-btn-next-${index}`;
    static readonly PREVIOUS_ELEMENT = (index: number) => `.swiper-btn-prev-${index}`;
    static readonly NAME_CONTROL = 'name';
    static readonly NAME_CONTROL_DATE = 'dateId';
    static readonly MAXIMUM_DIGITS = 'maxDigits';

    static readonly RESOLUTIONS = {
        DesktopWidth: 1280
    };

    static readonly FORM_CONTROLS = {
        Email: 'email',
        TelephoneNumber: 'telephoneNumber',
        FirstName: 'firstName',
        LastName: 'lastName',
        SecondLastName: 'secondLastName',
        BirthDate: 'birthDate',
        AtmAmount: 'atmAmount',
        AtmMovements: 'atmMovements',
        CommerceAmount: 'commerceAmount',
        CommerceMovements: 'commerceMovements',
        OnlineAmount: 'onlineAmount'
    };

    static readonly SLIDER_STYLE_ADDITIONAL = [
        ` @media screen and (min-width: 1024px) {
      .swiper-pagination-bullets {
        display: none !important;
      }
    }`,
        ` .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal,
      .swiper-pagination-custom, .swiper-pagination-fraction {
        top: 90%;
      }`
    ];

    static readonly SLIDER_STYLE = [
      ` .swiper-horizontal > .swiper-pagination-bullets,
        .swiper-pagination-bullets.swiper-pagination-horizontal,
        .swiper-pagination-custom, .swiper-pagination-fraction {
          top: 88%;
        }`];

    static readonly ADDITIONAL_VALIDATIONS = {
        atmDebitAmount: 25000,
        atmCreditAmount: 5000,
        atmMinimumAmount: 50,
        onlineDebitAmount: 500000,
        onlineCreditAmount: 20000,
        commerceDebitAmount: 100000,
        commerceCreditAmount: 20000
    };

    static readonly QR_ADDITIONAL = {
        canvasQR: 'imageQr',
        textQr: 'textQr',
        textCanvas: 'Inbursa',
        canvasWidth: 440,
        canvasHeight: 300,
        canvasFont: '13px Rubik',
        canvasFillStyle: '#000957',
        positionYOneText: 250,
        positionYTwoText: 270,
        positionXQr: 105,
        positionYQr: 10,
        textAlign: 'center',
        background: 'white'
    };

    static readonly CLASS_SCREENSHOT = 'successful-operation';
}

export const AdditionalRegex = {
    CleanSpaces: /(\s{2,})/g,
    RFC: /^[A-ZÑ&]{3,4}\d{6}[A-Z0-9]{3}$/i,
    NumbersLettersOneSpace: /^\s+|\s(?=\s)|[^a-zA-Z0-9ñÑ\s]+/g
};


export enum CardStatus {
    Active = 'Activo',
    Pending = 'Pendiente',
    Rejected = 'Rechazado',
    Expired = 'Expirado'
}
