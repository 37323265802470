import { Injectable } from '@angular/core';
import { GetPendingWithdrawalsRequest } from 'src/app/interface/dto/GetPendingWithdrawalsRequest';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { GetPendingWithdrawalsResponse } from 'src/app/interface/dto/GetPendingWithdrawalsResponse';
import { GetCancellationWithdrawalsRequest } from 'src/app/interface/dto/GetCancellationWithdrawalsRequest';
import { GetCancellationWithdrawalsResponse } from 'src/app/interface/dto/GetCancellationWithdrawalsResponse';
import { Utils } from 'src/core/utils/utils';
import { GetWithdrawalsRequest } from 'src/app/interface/dto/GetWithdrawalsRequest';
import { GetWithdrawalsResponse } from 'src/app/interface/dto/GetWithdrawalsResponse';
import { Constants, Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { Withdrawal } from 'src/app/models/Withdrawal';
import { MaskAccountPipe } from 'src/app/pipes/mask-account.pipe';
import { CodeflexConstants } from 'src/core/constants/CodeflexConstants';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class CodeflexService {

  mainAccountType: string = Strings.EMPTY;
  mainAccountNumber: string = Strings.EMPTY;

  constructor(
    private readonly baseService: BaseServiceService
  ) { }

  async getPendingWithdrawals(productAccount: number, originMainAccountType: string, originMainAccountNumber: string): Promise<Withdrawal[]>{

    this.mainAccountType = originMainAccountType;
    this.mainAccountNumber = originMainAccountNumber;

    const request = new GetPendingWithdrawalsRequest({
      idCuentaProducto : productAccount
    });

    const response = await this.baseService.genericPost<GenericResponse<GetPendingWithdrawalsResponse[]>>(request, {isSecure:true, addSession:true});

    return  response ? this.formatResponse(response.datos) : null;
  }

  formatResponse(input: GetPendingWithdrawalsResponse[]): Withdrawal[]{
    const result: Array<Withdrawal> = [];

      for(const pendingWithdrawal of input){
        const item : Withdrawal = {
          account : CodeflexConstants.CODEFLEX_ACCOUNT(this.mainAccountType, new MaskAccountPipe().transform(this.mainAccountNumber, Numbers.Four)),
          amount : pendingWithdrawal.monto,
          date : pendingWithdrawal.fechaOperacion,
          reference : pendingWithdrawal.folio,
          selected : false
        };
        result.push(item);
      }
    return result;
  }

  getCancellationWithdrawals(folioNumber: number){
    const request = new GetCancellationWithdrawalsRequest({
      folio : folioNumber,
    });
    return this.baseService.genericPost<GenericResponse<GetCancellationWithdrawalsResponse>>(request, {isSecure:true, addSession:true});
  }

  getWithdrawals(source: number, destination: number, amount: string, days: number){
    const request = new GetWithdrawalsRequest({
      idCuentaProductoOrigen : source,
      idCuentaProductoDestino : destination,
      monto : parseFloat(Utils.transformAmount(amount)),
      dias : days + Numbers.One
    });

    return this.baseService.genericPost<GenericResponse<GetWithdrawalsResponse>>(request, {isSecure:true, addSession:true});
  }

  static minDateCodeflex() : NgbDateStruct{
    const minDate = new Date();
    minDate.setDate(minDate.getDate() + CodeflexConstants.CODEFLEX_DAYS);

    return {year: minDate.getFullYear(), month: minDate.getMonth() + Numbers.One, day: minDate.getDate()
    };
  }

  static maxDateCodeflex() : NgbDateStruct{
    const maxDate = new Date(CodeflexConstants.CODEFLEX_YEAR, CodeflexConstants.CODEFLEX_MONTH, CodeflexConstants.CODEFLEX_DAYS_31);
    return {year: maxDate.getFullYear(), month: maxDate.getMonth() + Numbers.One, day: maxDate.getDate()
    };
  }

}
