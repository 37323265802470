import { Validators } from '@angular/forms';

export enum FormValidations {
  Required = 'required',
  Max = 'max',
  Min = 'min',
  Pattern = 'pattern',
  MinLength = 'minlength',
  MaxLength = 'maxlength'
}

export const Validations = {
  'required': {
    name: FormValidations.Required,
    validator: Validators.required
  }
};

export const State ={
  checked: 'checked'
};

export const FormRegex = {
  ValidateRFCWithClue: /^([A-ZÑ&]{3,4})?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))$/,
  SearchValidText: /[^a-zA-Z0-9áéíóúüÁÉÍÓÚñÑÜñ\s]+/g,
  Accent: /(´)(´+)/g,
  ReverseAccent: /(`)(`+)/g,
  LettersWithAccents: /[a-záéíóú]/i,
  DoubleAccent: /´´/g,
  DoubleReverseAccent: /``/g,
  PercentText: /(100|[1-9]?\d)$/,
  DifferentFromAlphanumeric: /[^a-zA-Z0-9 áéíóúÁÉÍÓÚñÑàèìòùÀÈÌÒÙ,._&:;@!$?/'’-]+/g,
  ValidateRFCWithOrWithoutHomoclave: new RegExp(/^([A-ZÑ&]{3,4})?/.source +
    /(\d{2}(?:(?:0[13578]|1[02])(?:0[1-9]|[12]\d|3[01])|(?:0[469]|11)(?:0[1-9]|[12]\d|30)|02(?:0[1-9]|1\d|2[0-8])|(?:02(?:29)))[A-Z0-9]{3})$/.source +
    /|^([A-ZÑ&]{3,4})?/.source +
    /(\d{2}(?:(?:0[13578]|1[02])(?:0[1-9]|[12]\d|3[01])|(?:0[469]|11)(?:0[1-9]|[12]\d|30)|02(?:0[1-9]|1\d|2[0-8])|(?:02(?:29))))$/.source),
  ValidateNoGenericRFCWithoutHomoclave: /^(?!(XAXX|XEXX)\d{6}|(XAX|XEX)\d{6}).+$/,
  searchPaymentServicesRegex: /[a-zA-Z0-9\s]/,
  DenyOnlyCharactersNumbers: /[^a-zA-ZÑñáéíóúüÁÉÍÓÚ0-9.,\s]+/g,
  Empty: /\s/g,
  EmailPatternDomiciliation: /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
  AlphaNumeric: /[^a-zA-Z0-9áÁ ]/g,
  ValidFormatDate: /^(\d{2})\/(\d{2})\/(\d{4})$/,
  ValidateRFC: /^(?!X[AE]X{2}010101000)\b([A-Z&Ñ]{3,4}\d{6}[A-Z0-9]{3})$/,
  SearchValidTextWhitHastag: /[^a-zA-Z0-9áéíóúüÁÉÍÓÚñÑÜñ.,#´\s]+/g,
  DenyOnlyLettersAccentsNumbers: /[^a-záéíóúA-ZÁÉÍÓÚñÑ0-9\s]+/g,
  DenyValidateEmailWithComma: /[^a-zA-Z0-9,._@-]+/g,
  RemoveExtraCharactersToQuantityString: /[/$a-zA-ZáéíóúüÁÉÍÓÚñÑÜ\s]+/g,
  OnlyLettersAndSpaces: /^\s+|\s(?=\s)|[^a-zA-ZñÑ\s]+/g,
  OnlyCommaAmount: /\$|\.00/g,
  DifferentLettersAndNumbers: /[^a-zA-Z0-9]+/g,
  DenyEmojis: /[\p{Emoji}]/gu,
  removeTrailingZerosRegex: /\.00$/,
  Locations: /[^a-zA-Z0-9áéíóúüÁÉÍÓÚñÑÜñ.,#´\s]+/g,
  CFENumberServiceValidator: /^(.{12}|.{27})$/,
  ValidDate: /[^0-9\/]|(\/(?=\/))/g,
  ValidateRFCWithousClue: /^(?!X[AE]X{2}010101)\b([A-Z&Ñ]{3,4}\d{6}[A-Z0-9]{3})$/,
  NumberWithOneHyphen: /[^0-9-]+/g,
  Ipad: /^((?!chrome|android).)*safari/i,
  NormalizeString: /[áàäâãéèëêíìïîóòöôõúùüûñ]/g
};

export class FormConstants {
  static readonly FORM_CONTROLS = {
    NumberTelephone: 'numberTelephone',
    NumberService: 'numberService',
    headline: 'headline',
    EmailDomiciliation: 'email',
    SelectServices: 'selectServices',
    cardNumber: 'cardNumber',
    expirationDate: 'expirationDate',
    selectPayment: 'selectPayment',
    selectCreditCard: 'selectCreditCard',
    selectOwnCardToPay: 'selectOwnCardToPay',
    domiciliationDurationSelection: 'domiciliationDurationSelection',
    numTDC: 'numTDC'
  };
}
