import { Numbers } from 'src/core/constants/Constants';

export class CreditConstants {

  static readonly BANK_CREDITS = 'CRED';
  static readonly FORMAT_RFC = 'AAAA-######-AAA';
  static readonly LABEL_FORMAT_RFC = 'El formato RFC es el siguiente:';
  static readonly SET_TIME = 500;
  static readonly MAX_DATE_PAYMENT_CREDIT = { year: new Date().getFullYear(), month: new Date().getMonth() + Numbers.Twelve, day: new Date().getDate() };
  static readonly TIME_TO_APPLY = 24;
  static readonly TYPE_PERSONEL = 'credito personal';
  static readonly TYPE_CAR = 'credito automotriz';
  static readonly TYPE_MORTGAGE = 'credito hipotecario';
  static readonly CREDIT_LENGTH = {
    Personnel: 20,
    PersonnelMask: 24,
    Other: 16,
    OtherMask: 19
  };

  static readonly FORM_CONTROLS = {
    rfc: 'rfc',
    headline: 'headline',
    otherBanks: 'bank',
    modifyLimit: 'modifyLimit'
  };

  static readonly INPUT_TYPES = {
    numeric: 'numeric'
  };

  static readonly TYPE_OPERATIONS = {
    credit: 'newCredit',
    paymentCredit: 'paymentCredit'
  };

  static readonly AGENDABLE_SERVICE_CODE = {
    creditOthersBanks: 'CREDIT'
  };

  static readonly SERVICE_CODE = ['11', '12', '13'];
}

export enum ElementsHTML {
  ResponsiveServiceStyle = '(min-width: 1600px)',
  LargeHomePaymentService = '(min-width: 1365px)',
  MediumCredit = '(min-width: 768px) and (max-width: 950px)',
  CreditNumberInput = 'credit-number'
}

export enum InputTypesCredit {
  textOther = 'text',
  selectTwoOption = 'selectTwoOption'
}
