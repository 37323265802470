<ng-container *ngIf="switchRoute$ | async as switchRoute">
  <ng-container *ngIf="switchRoute !== null">
    <ng-container *ngIf="switchRoute.value; else HomeView">
      <ng-container *ngIf="breakpoints$ | async as breakpoints">
        <div
          *ngIf="breakpoints.desktop; else MobileTablet"
          class="sidebar h-100 d-flex flex-column justify-content-between"
        >
          <div (click)="clearQR()" (keydown.enter)="clearQR()" class="logo-inbursa align-self-center">
            <a class="mt-3 version" [routerLink]="loginPath">
              <img
                [src]="inbursaOriginal | cdnImage"
                alt="Logo Inbursa"
                width="auto"
                height="auto"
              />
              <p class="date-label detail-m col-12">
                {{ environment }} {{ versionNumber }}
              </p>
            </a>
          </div>

          <div class="options">
            <a class="option-sidebar" [href]="link" target="_blank">
              <img
                [src]="mobile | cdnImage"
                alt="Icono Menu"
                width="auto"
                height="auto"
              />
              <span class="text body-s">Conoce Inbursa Móvil</span>
            </a>
            <a class="option-sidebar">
              <img
                [src]="phoneVoice | cdnImage"
                alt="Icono Menu"
                width="auto"
                height="auto"
              />
              <ng-container *ngIf="breakpoints.desktop">
                <span
                  class="cursor-default text body-s"
                  >800 90 90000</span>
              </ng-container>
              <ng-container *ngIf="!breakpoints.desktop">
                <a
                  href="tel:800 90 90000"
                  class="text-decoration-none text body-s"
                  >800 90 90000</a
                >
              </ng-container>
            </a>
            <a class="option-sidebar" [href]="linkSecurityTips" target="_blank">
              <img
                [src]="lock | cdnImage"
                alt="Icono Menu"
                width="auto"
                height="auto"
              />
              <span class="text body-s">Tips de Seguridad</span>
            </a>
            <a
              class="option-sidebar cursor-pointer"
              (click)="onShowRequirements()"
              (keydown.enter)="onShowRequirements()"
              target="_blank"
              ><img
                [src]="monitorIcon | cdnImage"
                alt="Icono Tecnicos"
                width="24px"
                height="24px"
              />
              <span class="text body-s">Requerimientos mínimos técnicos</span>
            </a>
            <a
              class="option-sidebar cursor-pointer"
              (click)="showGeolocationManual(breakpoints)"
              (keydown.enter)="showGeolocationManual(breakpoints)"
              ><img
                [src]="locationIcon | cdnImage"
                alt="Manual de Geolocalización"
                width="24px"
                height="24px"
              />
              <span class="text body-s">Manual de Geolocalización</span>
            </a>
          </div>
        </div>
        <ng-template #MobileTablet>
          <div
            class="d-flex justify-content-center align-items-center w-100 header-card-login-responsive"
          >
            <img
              [src]="inbursaOriginal | cdnImage"
              class="mt-4"
              [routerLink]="homePath"
              alt="Logo Inbursa"
              width="auto"
              height="auto"
            />
            <p class="date-label col-12">{{ environment }} {{ versionNumber }}</p>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #HomeView>
  <ng-container *ngIf="vm$ | async as vm">
    <div #Sidebar class="border-end primary-inbursa moz-hide-scroll" *ngIf="vm.breakpoints.desktop || vm.show"
      [attr.id]="!vm.breakpoints.desktop ? '' : 'sidebar-wrapper'"
      [class.sidebar-mobile]="vm.show && !vm.breakpoints.desktop"
      (click)="resetPatrimonialFutureTransaction()"
      (keydown.enter)="resetPatrimonialFutureTransaction()"
      (touchstart)="resetPatrimonialFutureTransaction()"
    >
      <div>
        <div class="sidebar-mobile-close" *ngIf="vm.show" (click)="close()" (keydown.enter)="close()">
          <img
            [src]="closeIcon | cdnImage"
            alt="Cerrar sidebar"
            width="auto"
            height="auto"
          />
        </div>
        <div class="sidebar-heading primary-inbursa">
          <div class="col logo-inbursa-home">
            <a class="mt-3 cursor-pointer" (click)="goHome()" (keydown.enter)="goHome()">
              <img
                [src]="inbursaOriginal | cdnImage"
                alt="Inbursa"
                width="auto"
                height="auto"
              />
            </a>
            <p class="date-label col-12">{{ environment }} {{ versionNumber }}</p>
          </div>
          <img
            class="logo-inbursa-sidebar cursor-pointer"
            (click)="goHome()"
            (keydown.enter)="goHome()"
            [src]="logoSidebar | cdnImage"
            alt=""
            width="auto"
            height="auto"
          />
        </div>
        <div class="list-group list-group-flush" (click)="close()" (keydown.enter)="close()">
          <ng-container (click)="reset()" (keydown.enter)="reset()">
            <a
              class="list-group-primary-item p-3"
              (click)="getPath(); clearframe()"
              (keydown.enter)="getPath(); clearframe()"
              routerLinkActive="active-link"
              [routerLink]="homePath"
              (mouseenter)="validateShow(true, 'Mis productos', vm.sideBar)"
              (mouseleave)="validateShow(false, '', false)"
            >
              <img
                class="icons-aside"
                [src]="gridFourIcon | cdnImage"
                alt="mis productos"
                width="auto"
                height="auto"
                [ngbTooltip]="tooltipText"
                tooltipClass="tooltip-sidebar"
                delay="0"
              />
              <span>Mis productos</span>
            </a>
            <a
              class="list-group-primary-item p-3 cursor-pointer"
              (click)="clearForm()"
              (keydown.enter)="clearForm()"
              [routerLink]="transfersPath"
              routerLinkActive="active-link"
              (mouseenter)="validateShow(true, 'Traspasos', vm.sideBar)"
              (mouseleave)="validateShow(false, '', false)"
              ><img
                class="icons-aside"
                [src]="coinDollar | cdnImage"
                alt="mis productos"
                width="auto"
                height="auto"
                [ngbTooltip]="tooltipText"
                tooltipClass="tooltip-sidebar"
              />
              <span>Traspasos </span>
            </a>
              <a
                *ngIf="vm.showPortability"
                class="list-group-primary-item p-3 cursor-pointer"
                (click)="clearForm()"
                (keydown.enter)="clearForm()"
                routerLinkActive="active-link"
                [routerLink]="addPortabilityPath"
                (mouseenter)="validateShow(true, 'Portabilidad', vm.sideBar)"
                (mouseleave)="validateShow(false, '', false)"
                ><img
                  class="icons-aside"
                  [src]="roadPortability | cdnImage"
                  alt="portabilidad"
                  width="auto"
                  height="auto"
                  [ngbTooltip]="tooltipText"
                  tooltipClass="tooltip-sidebar"/>
                <span>Portabilidad </span>
              </a>
            <a
              class="list-group-primary-item p-3 cursor-pointer"
              (click)="clearForm()"
              (keydown.enter)="clearForm()"
              [routerLink]="servicesPath"
              routerLinkActive="active-link"
              (mouseenter)="validateShow(true, 'Pago de servicios', vm.sideBar)"
              (mouseleave)="validateShow(false, '', false)"
              ><img
                class="icons-aside"
                [src]="shoppingBasket | cdnImage"
                alt="mis productos"
                width="auto"
                height="auto"
                [ngbTooltip]="tooltipText"
                tooltipClass="tooltip-sidebar"
              />
              <span>Pago de servicios</span>
            </a>
            <a
              class="list-group-primary-item p-3"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{ exact: true }"
              [routerLink]="controlPath"
              (click)="clearForm()"
              (keydown.enter)="clearForm()"
              (mouseenter)="
                validateShow(true, 'Control de tarjetas', vm.sideBar)
              "
              (mouseleave)="validateShow(false, '', false)"
              ><img
                class="icons-aside"
                [src]="lockOpen | cdnImage"
                alt="mis productos"
                width="auto"
                height="auto"
                [ngbTooltip]="tooltipText"
                tooltipClass="tooltip-sidebar"
              />
              <span>Control de tarjetas</span>
            </a>
            <ng-container *ngIf="!hiddenProgrammedOperations">
              <a
                class="list-group-primary-item p-3 cursor-pointer two-rows"
                  [ngClass]="
                  !vm.breakpoints.desktop ? ' d-flex align-items-center' : ''
                    "
                  (click)="clearForm()"
                  (keydown.enter)="clearForm()"
                  [routerLink]="programmedOperationsPath"
                  routerLinkActive="active-link"
                  (mouseenter)="
                    validateShow(true, 'Operaciones programadas', vm.sideBar)
                    "
                  (mouseleave)="validateShow(false, '', false)"
                >
                <img
                  [src]="boxLeft | cdnImage"
                  alt="Operaciones"
                  width="auto"
                  height="auto"
                  [ngbTooltip]="tooltipText"
                  tooltipClass="tooltip-sidebar"
                />
                <span>Operaciones programadas</span>
              </a>
            </ng-container>
            <ng-container *ngIf="( ((!vm.payrollAdvanceData.payrollExist && vm.payrollAdvanceData.payrollAccountExist)
            || (!vm.payrollCreditData.payrollExist && vm.payrollCreditData.payrollAccountExist)) && showHiring)
            || expressPwa">
              <a
                class="list-group-primary-item p-3 cursor-pointer  two-rows align-items-center"
                [ngClass]="!vm.breakpoints.desktop ? ' d-flex align-items-center' : ''"
                (click)="frameHeight(); clearFormConrtract()"
                (keydown.enter)="frameHeight(); clearFormConrtract()"
                [routerLink]="hiringPath" routerLinkActive="active-link"
                (mouseenter)="validateShow(true, 'Contratación', vm.sideBar)"
                (mouseleave)="validateShow(false, '', false)">
                <img [src]="imgShop | cdnImage" alt="Contratación" width="auto" height="auto" [ngbTooltip]="tooltipText"
                  tooltipClass="tooltip-sidebar" />
                <span>Contratación </span>
              </a>
            </ng-container>
              <ng-container *ngIf="vm.showLoyalty || isApp">
                <a class="list-group-primary-item p-3 cursor-pointer"
                  routerLinkActive="active-link"
                  (click)="clearframe()"
                  (keydown.enter)="clearframe()"
                  [routerLink]="loyaltyPointsPath"
                  (mouseenter)="validateShow(true, 'Solo para ti', vm.sideBar)"
                  (mouseleave)="validateShow(false, '', false)"
                  ><img
                    class="icons-aside"
                    [src]="shoppingTagw | cdnImage"
                    alt="mis productos"
                    width="auto"
                    height="auto"
                    [ngbTooltip]="tooltipText"
                    tooltipClass="tooltip-sidebar"
                  />
                  <span>Solo para ti</span>
                </a>
              </ng-container>
              <ng-container *ngIf="!hiddenClarifications">
                <a
                class="list-group-primary-item p-3 cursor-pointer"
                [routerLink]="clarificationPath"
                routerLinkActive="active-link"
                (click)="clearframe()"
                (keydown.enter)="clearframe()"
                (mouseenter)="validateShow(true, 'Aclaraciones', vm.sideBar)"
                (mouseleave)="validateShow(false, '', false)"
                ><img
                  class="icons-aside"
                  [src]="fileEditIcon | cdnImage"
                  alt="mis productos"
                  width="auto"
                  height="auto"
                  [ngbTooltip]="tooltipText"
                  tooltipClass="tooltip-sidebar"
                  />
                  <span>Aclaraciones </span>
                </a>
              </ng-container>
              <ng-container *ngIf="vm.homeDimo">
                <a class="list-group-primary-item p-3 cursor-pointer"
                (click)="publicBridge(dimoValidation)"
                (keydown.enter)="publicBridge(dimoValidation)"
                  routerLinkActive="active-link"><img class="icons-aside" [src]="coinDollar | cdnImage"
                    alt="{{dimoStringLabel}}" width="auto" height="auto" [ngbTooltip]="tooltipText"
                    tooltipClass="tooltip-sidebar" />
                  <span>{{dimoStringLabel}}</span>
                </a>
              </ng-container>
              <ng-container *ngIf="vm.homeCodi">
                <a class="list-group-primary-item p-3 cursor-pointer"
                (click)="publicBridge(codiSelectCardPage)"
                (keydown.enter)="publicBridge(codiSelectCardPage)"
                  routerLinkActive="active-link"><img class="icons-aside" [src]="coinDollar | cdnImage"
                    alt="{{codiStringLabel}}" width="auto" height="auto" [ngbTooltip]="tooltipText"
                    tooltipClass="tooltip-sidebar" />
                  <span>{{codiStringLabel}}</span>
                </a>
              </ng-container>
            <a
              class="list-group-primary-item p-3 cursor-pointer"
              (click)="onCloseSession()"
              (keydown.enter)="onCloseSession()"
              (mouseenter)="validateShow(true, 'Cerrar sesión', vm.sideBar)"
              (mouseleave)="validateShow(false, '', false)"
              ><img
                class="icons-aside"
                [src]="logout | cdnImage"
                alt="mis productos"
                width="auto"
                height="auto"
                [ngbTooltip]="tooltipText"
                tooltipClass="tooltip-sidebar"
              />
              <span>Cerrar sesión</span>
            </a>
          </ng-container>
        </div>
        <div class="container-card" *ngIf="vm.breakpoints.desktop">
          <div class="card card-promo mt-0" [ngClass]="showCardPromotional" *ngIf="!isContract">
            <button class="btn-img" (click)="hidePromotionalCard()" (keydown.enter)="hidePromotionalCard()">
              <img
                alt="removeIcon"
                [src]="removeIcon | cdnImage"
                width="auto"
                height="auto"
              />
            </button>
            <img
              class="self-card-image"
              [src]="imgCardPayroll | cdnImage"
              alt="Trae tu nómina con nosotros"
              width="auto"
              height="auto"
            />
            <div class="card-body">
              <p class="header-xs">Transferencias internacionales</p>
              <p class="body-s">
                Ahora puedes realizar estas operaciones en la sección de
                transferencias
              </p>
              <a [routerLink]="transfersPath" (click)="clearForm()" (keydown.enter)="clearForm()"
                class="btn btn-primary">Ir a transferencias</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
