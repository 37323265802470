
import { Constants, Length, Numbers } from 'src/core/constants/Constants';
import { Utils } from 'src/core/utils/utils';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppBridge } from 'src/core/utils/AppBridge';
import { AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { TSessionManagerConfig } from 'src/app/interface/SessionManagerConfig';
import { SessionRequest } from 'src/app/interface/SessionUser.Request';
import { SessionResponse } from 'src/app/interface/SessionUser.Response';
import { LogoutRequest } from 'src/app/interface/dto/LogoutRequest';
import { LogoutResponse } from 'src/app/interface/dto/LogoutResponse';
import { ModalOptions } from 'src/app/interface/modal-options';
import { AppNavigation } from 'src/app/models/AppNavigation';
import { ModalLogoutComponent } from 'src/app/shared/components/modal-logout/modal-logout.component';
import { ModalService } from 'src/app/shared/modal.service';
import { AlertService } from 'src/app/services/alert.service';
import { ConfigService } from 'src/app/services/config.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotifyClearFormService } from 'src/app/services/notify-clear-form.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppUtils } from 'src/core/utils/AppUtils';

@Injectable({
  providedIn: 'root'
})
export class SessionManagerService {
  private globalTimeoutTimer: number;
  private inactivityTimeoutTimer: number;
  private modalSession: NgbModalRef;
  private globalTimeout: number = Numbers.Zero;
  private inactivityTimeout: number = Numbers.Zero;
  private startTrackingDate: number = Numbers.Zero;
  private refreshTrackingDate: number = Numbers.Zero;
  private isInactivityAlertVisible: boolean = false;
  private readonly loginRoutes: Array<string> = [PATH.LoginToken, PATH.ValidateToken, PATH.LoginPassword, PATH.Login];
  modalInstance: NgbModalRef;

  constructor(
    private readonly storage: StorageService,
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    private readonly configService: ConfigService,
    private readonly modalService: ModalService,
    private readonly notifyClearFormService: NotifyClearFormService,
    private readonly loaderService: LoaderService,
    private readonly alertService: AlertService) {
    }

  /**
   * Inicia seguimiento de sesión
   */
  trackSession() {
    this.startGlobal();
    this.startInactivity();
  }

  /**
   * Cierra sesión
   * @param config Configuración de cierre de sesión
   */
  async closeSession(config?: TSessionManagerConfig) {
    window.clearInterval(this.globalTimeoutTimer);
    window.clearInterval(this.inactivityTimeoutTimer);
    this.modalSession?.dismiss();
    this.storage.removeSessionManager();
    this.globalTimeout = Numbers.Zero;
    this.inactivityTimeout =  Numbers.Zero;
    this.startTrackingDate =  Numbers.Zero;
    this.refreshTrackingDate =  Numbers.Zero;

    const session = this.storage.getSession();
    if (session) {
      const request = new LogoutRequest(session);

      this.storage.removeAll();

      try {
        const response = await this.postService<LogoutResponse>(this.configService.getConfig().Logout, request);
        if (response?.cierraResultado?.Error?.No !== null && response.cierraResultado.Error.No !== Length.Empty) {
          const errorDetails = Utils.getErrorMsg(response?.cierraResultado?.Error);
          throw new BusinessError(errorDetails.msg, errorDetails.code);
        }
      } catch (error) {
        Utils.printLogError(error);
        this.storage.saveSession(session, null);

        if (config?.returnError) {
          throw error;
        }
      }
      this.notifyClearFormService.clearObservablesGeneral();
    }

    if (config?.redirect) {
      this.navigate(config);
    }
  }

  private async navigate(config?: TSessionManagerConfig) {
    const navigationParameters: { state?: {} } = {};
    if (config?.message) {
      this.alertService.alertMessage = config.message;
      navigationParameters.state = { alert: config.message };
    }

    if (AppUtils.platform.isApp) {
      const navigationParams: AppNavigation = {
        Data: AppScreen.Login
      };

      await AppBridge.invoke<string>(AppMethod.SendNativeScreen, navigationParams);
    }
    else {
      this.router.navigate([PATH.Login], navigationParameters);
    }
  }

  /**
   * Renueva sesión
   */
  restartInactivityTracking() {
    if(this.inactivityTimeout > Numbers.Zero){
      this.startInactivity();
    }
    if(AppUtils.platform.isApp){
      AppBridge.invoke<string>(AppMethod.UpdateSession);
    }
  }

  /**
   * Evalúa sesión al recargar aplicación
   */
  onloadHandler() {
    const value = this.storage.getSessionManager();
    if (value && value.currentGlobal && value.currentInactivity) {
      this.trackSession();
      this.globalTimeout = value.currentGlobal;
      this.inactivityTimeout = value.currentInactivity;
      this.startTrackingDate = value.globalDate;
      this.refreshTrackingDate = value.inactivityDate;
      this.storage.removeSessionManager();
    }
  }

  /**
   * Evalúa sesión al salir de la aplicación
   */
  unloadHandler() {
    this.storage.saveSessionManager({
      currentGlobal: this.globalTimeout,
      currentInactivity: this.inactivityTimeout,
      globalDate: this.startTrackingDate,
      inactivityDate: this.refreshTrackingDate
    });
  }

  //#region  PRIVATE METHODS

  /**
   * Inicia rastreo de sesión por inactividad
   */
  private startInactivity() {
    window.clearInterval(this.inactivityTimeoutTimer);
    this.inactivityTimeout = Constants.SESSION_TIMER;
    this.refreshTrackingDate = new Date().getTime();
    this.isInactivityAlertVisible = false;

    if (!this.loaderService.isLoadingSpinner) {
      this.modalInstance?.close();
    }

    this.inactivityTimeoutTimer = window.setInterval(async () => {
      const timeElapsed = new Date().getTime() - this.refreshTrackingDate;
      this.inactivityTimeout += Constants.SESSION_TIMER;

      if (!this.isInactivityAlertVisible && (this.inactivityTimeout === Constants.SESSION_INACTIVITY_ALERT_TIMEOUT ||
        (timeElapsed > Constants.SESSION_INACTIVITY_ALERT_TIMEOUT && timeElapsed < Constants.SESSION_INACTIVITY_TIMEOUT))) {
        this.onInactivityAction();
      }

      if (this.inactivityTimeout >= Constants.SESSION_INACTIVITY_TIMEOUT || timeElapsed >= Constants.SESSION_INACTIVITY_TIMEOUT) {
        await this.closeSession({ redirect: true, message: Strings.MSG_SESSION_INACTIVITY_TIMEOUT });
        this.loaderService.resetRequest = true;
        this.notifyClearFormService.clearObservablesGeneral();
      }
    }, Constants.SESSION_TIMER);
  }

 async onUserResponseSession(answer: string) {
    if (answer) {
      await this.closeSession({ redirect: true });
    } else {
      await this.refreshSession();
    }
  }

  private async refreshSession() {
    const session = this.storage.getSession();
    const request = new SessionRequest(session);
    try {
      await this.postService<SessionResponse>(this.configService.getConfig().ReactiveSession, request);
      this.inactivityTimeout = Constants.SESSION_TIMER;
      this.refreshTrackingDate = new Date().getTime();
      this.isInactivityAlertVisible = false;
    } catch (error) {
      Utils.printLogError(error);
      await this.closeSession({ redirect: true });
    }
  }

  /**
   * Inicia rastreo de sesión global
   */
  private startGlobal() {
    window.clearInterval(this.globalTimeoutTimer);
    this.globalTimeout = Constants.SESSION_TIMER;
    this.startTrackingDate = new Date().getTime();

    this.globalTimeoutTimer = window.setInterval(async () => {
      const timeElapsed = new Date().getTime() - this.startTrackingDate;
      this.globalTimeout += Constants.SESSION_TIMER;

      if (this.globalTimeout >= Constants.SESSION_TIMEOUT || timeElapsed >= Constants.SESSION_TIMEOUT) {
        await this.closeSession({ redirect: true, message: Strings.MSG_SESSION_TIMEOUT });
        this.notifyClearFormService.clearObservablesGeneral();
      }
    }, Constants.SESSION_TIMER);
  }

  /**
   * Visualiza modal o cierra sesión al finalizar tiempo de inactividad
   */
  private onInactivityAction() {
    if (this.loginRoutes.includes(this.router.url)) {
      this.closeSession({ redirect: true, message: Strings.MSG_SESSION_INACTIVITY_TIMEOUT });
    }
    else {
      this.isInactivityAlertVisible = true;
      if (this.loaderService.isLoadingSpinner){
        this.modalInstance = this.alertService.showPopupAlert({
            message: Strings.MESSAGE_UPLOAD_INFORMATION,
            header: Strings.HEADER_UPLOAD_INFORMATION,
            btnExit: false,
            hiddenBtnAccept: true
        });
        if (document.getElementById('spinner')){
          document.getElementById('spinner').style.zIndex = Constants.SPINNER_Z_INDEX_BEHIND;
        }
      } else {
        const modalOptions: ModalOptions = {
          centered: true,
          size: Constants.MODAL_OPTIONS.SizeMd,
          modalDialogClass: ModalConstants.MODAL_OPTIONS.SessionCloseModal,
        };

        this.modalSession = this.modalService.open(ModalLogoutComponent, modalOptions);
        this.modalSession.closed.subscribe(answer => this.onUserResponseSession(answer));
      }
    }
  }

  /**
   * Consumo de REST service
   * @param url Url de servicio
   * @param request Datos requeridos por servicio
   */
  private postService<T>(url: string, request: LogoutRequest | SessionRequest): Promise<T> {
    const headers = {
      headers: new HttpHeaders().set(Constants.HEADER_USER_ID, Strings.EMPTY).set(Constants.HEADER_DESTINATION_ID, Strings.EMPTY).set(Constants.HEADER_CONSUMER_ID, Strings.EMPTY)
    };

    return new Promise(async (res, err) => {
      this.httpClient.post<T>(url, request, headers)
        .subscribe((data) => res(data),
        (error) => {
            Utils.printLogError(error);
            err(error);
          });
    });
  }
  //#endregion PRIVATE METHODS
}
