import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Input, Output, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { IAddresseeAccount } from 'src/app/interface/IAddresseeAccount';
import { ButtonProperties } from 'src/app/interface/IButtonProperties';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { DataDeductible } from 'src/app/interface/IDeductibleResponse';
import { IExpressTransferData } from 'src/app/interface/IExpressTransferData';
import { IExpressTransferExtraData } from 'src/app/interface/IExpressTransferExtraData';
import { ISimpleValue } from 'src/app/interface/ISimpleValue';
import { TransferData } from 'src/app/interface/TransferData';
import { PatrimonialDataModel } from 'src/app/models/PatrimonialDataModel';
import { AccountCardService } from 'src/app/services/account-card.service';
import { CardAddresseeService } from 'src/app/services/card-addressee.service';
import { DataDeductibleService } from 'src/app/services/data-deductible.service';
import { ExchangeRateService } from 'src/app/services/exchange-rate.service';
import { ExpressTransferExtraDataService } from 'src/app/services/express-transfer-extra-data.service';
import { ExpressTransferService } from 'src/app/services/express-transfer.service';
import { FacePassLoginService } from 'src/app/services/face-pass-login.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { NotifyClearFormService } from 'src/app/services/notify-clear-form.service';
import { NotifyDateService } from 'src/app/services/notify-date.service';
import { ServiceCreditPaymentService } from 'src/app/services/service-credit-payment.service';
import { ServiceOptionsBankService } from 'src/app/services/service-options-bank.service';
import { ServicePaymentTypeService } from 'src/app/services/service-payment-type.service';
import { ServiceReferenceNumberService } from 'src/app/services/service-reference-number.service';
import { ServiceTransferIdService } from 'src/app/services/service-transfer-id.service';
import { ServiceWarrantyTransferInternationalService } from 'src/app/services/service-warranty-transfer-international.service';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Utils } from 'src/core/utils/utils';
import { AddresseTabs, Char, ClassHTML, CoinType, Constants, FormatDate, Language, Length, Numbers, Position} from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { PatrimonialStatusService } from 'src/app/services/patrimonial-status.service';
import { DataPaymentOther, InsurancePolicies } from 'src/app/services/insurance-policies-status.service';
import { Resources } from 'src/core/constants/Resources';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';
import { NotifyRouteService } from 'src/app/services/notify-route.service';
import { InvestmentRegisterService } from 'src/app/services/investment-register.service';
import { ScheduledTransferData, ScheduledTransferService } from 'src/app/services/scheduled-transfer.service';
import { SendAnotherDateService } from 'src/app/services/send-another-date.service';
import { AddressConstants } from 'src/core/constants/AddressConstants';
import { AppUtils } from 'src/core/utils/AppUtils';
@Component({
  selector: 'app-main-confirm-data',
  templateUrl: './main-confirm-data.component.html',
  styleUrls: ['./main-confirm-data.component.css']
})
export class MainConfirmDataComponent implements OnInit {
  @Input() dataTimeApply: number;
  @Input() dataCommission: number;
  @Input() carDec: string = Strings.EMPTY;
  @Input() labelServiceToPay: string;
  @Input() labelPolicy: string;
  @Input() listInsurance: Array<{ id: string, policy: string, receipt: string, total: string, status: string, paymentDate: string, isSelect: boolean }>;
  @Input() amountInsurance: string | number;
  @Input() idInsurance: string;
  @Input() dataToConfirm: TransferData[];
  @Input() dataConfirmPatrimonial: PatrimonialDataModel;
  @Input() specialFont: string = Strings.EMPTY;
  @Output() goToFunction? = new EventEmitter();
  completePayment: boolean = false;
  numberPayment : string = Strings.EMPTY;
  deductibleInformation: DataDeductible;
  expressTransferExtraData$: Observable<IExpressTransferExtraData>;
  amount!: string;
  cardAddresseeSelected$: Observable<IAddresseeAccount>;
  isFacePassLogin: ISimpleValue;
  expressTransferData!: IExpressTransferData;
  today!: string;
  selectedCard!: ICardAccount;
  selectedCardOtherBank!: ICardAccount;
  selectedAddressCard!: IAddresseeAccount;
  isThirdTransfer: boolean = false;
  labelAmount: string = Strings.TRANSFER.Quantity;
  labelOperationInformation: string = Strings.TRANSFER.Body;
  dataLabelDeductible: typeof Strings.DEDUCTIBLE = null;
  labelAccount: string = Strings.SELECT_ACCOUNT.From;
  otherLabelAccount: string = Strings.SELECT_ACCOUNT.FromYourAccount;
  title: string = Strings.SERVICES.ServiceToPay;
  dataPolice: string = Strings.CONFIRM_DATA.Policy;
  viewData: boolean = false;
  dataDeductible: Array<{ detail: string, value: string }> = null;
  timeToApply: number = Constants.TIME_TO_APPLY;
  toPayDeductible: boolean = true;
  dataLast: boolean = false;
  viewFirst: boolean = true;
  cardTransferInternational!: string;
  account: string;
  showCreditPayment: boolean = false;
  creditPayment!: string;
  referenceNumber!: string;
  type!: string;
  idService: string;
  isCreditPaymentCompleted:boolean = false;
  isCreditPayment:boolean = false;
  viewInsurance:boolean = false;
  operationDate: string;
  conditions: boolean = true;
  isInternationalTransfer: boolean = false;
  isPatrimonialTransfer: boolean = false;
  purposePayment: string = Strings.EMPTY;
  warranty: string;
  width: number = Numbers.Zero;
  viewInvestment: boolean = false;
  viewDeductible: boolean = false;
  badge: string;
  exchangeRate: number = Numbers.Zero;
  amountInternational: number = Numbers.Zero;
  bankCard: string = Resources.BANK_CARD;
  pillsForms: string = Resources.GREY_CIRCLE_USER;
  shapeContinue: string = Resources.SHAPE_CONTINUE;
  card: string = Resources.CARD;
  rootCardTransfer: string = Strings.EMPTY;
  rootAccountTransfer: string = Strings.EMPTY;
  rootImgTransfer: string = Strings.EMPTY;
  toCardTransfer: string = Strings.EMPTY;
  toAccountTransfer: string = Strings.EMPTY;
  toImgTransfer: string = Strings.EMPTY;
  sendPatrimonial: {}[] = new Array<{}>();
  vm$ = this.responsiveService.observe.pipe(
    map(breakpoints => ({ breakpoints }))
  );
  btnConfirm: string = Strings.BUTTONS.ConfirmAndContinue;
  btnModify: string = Strings.BUTTONS.Modify;
  continueProperties: ButtonProperties = {
    classes: Strings.ADDITIONAL_CONTRIBUTIONS.PropertiesBtn.classes,
    doAction: () => {
      this.nextPage();
    }
  };
  modifyProperties: ButtonProperties = {
    classes: Strings.ADDITIONAL_CONTRIBUTIONS.PropertiesBtn.classes,
    doAction: () => {
      this.navigateBack();
    }
  };
paymentCommission: number = Numbers.Zero;
  completeProcess: boolean = false;
  paymentOtherBank: boolean = false;
  iconCard: string = Resources.CARD_BLUE_BANK;
  monthsMSI: string;
  nameHolder: string;
  dataPaymentOtherBank: DataPaymentOther;
  showTerm: boolean = false;
  completeInsurance: boolean = true;
  labelHolder: string = Constants.PAYMENT_POLICIES_MSI.LabelHolder;
  labelEmail: string = Constants.PAYMENT_POLICIES_MSI.LabelEmail;
  labelAddress: string = Constants.PAYMENT_POLICIES_MSI.LabelAddress;
  labelZipCode: string = Constants.PAYMENT_POLICIES_MSI.LabelZipCode;
  cardOtherBank: number;
  cardSelected: string;
  buttonClicked: boolean = false;
  continueIcon: string = Resources.BTN_CHEVRON_RIGHT;
  @Output() modifyAction = new EventEmitter<void>;
  isExpressTransfer$: Observable<boolean> = this.expressTransferService.isExpressTransfer$;
  expressTransfer$: Observable<IExpressTransferData> = this.expressTransferService.expressTransferObservable;
  isPatrimonialFutureTransaction: boolean = this.patrimonialService.isFutureTransaction;
  policyPayment: boolean = false;
  badgePolicy: string = Strings.EMPTY;
  scheduledTransferData$: Observable<ScheduledTransferData> = this.scheduledTransferService.scheduledTransfer$;
  dateFutureTransfer$ = this.scheduledTransferService.dateFutureTransfer$;
  isDateFutureTransfer$ = this.scheduledTransferService.isFutureTransfer$;
  isBrokerageHouseTransfer: boolean = false;
  styles: Partial<CSSStyleDeclaration> = {
    display: ClassHTML.WebkitBox,
    webkitBoxOrient: ClassHTML.BoxOrientVertical,
    overflowWrap : ClassHTML.AnyWhere
  };
  isApp: boolean = false;
  constructor(
    readonly expressTransferExtraService: ExpressTransferExtraDataService, private readonly expressTransferService: ExpressTransferService,
    private readonly _router: Router, private readonly notifyAmountService: NotifyAmountService,
    private readonly facePassLoginService: FacePassLoginService, private readonly paymentTypeService: ServicePaymentTypeService,
    private readonly referenceNumberService: ServiceReferenceNumberService, private readonly creditPaymentService: ServiceCreditPaymentService,
    private readonly stateServicePayIdService: StateServicePayIdService, private readonly notifyClearFormService: NotifyClearFormService,
    private readonly transferIdService: ServiceTransferIdService, private readonly optionBankService: ServiceOptionsBankService,
    private readonly warrantyInternationalService: ServiceWarrantyTransferInternationalService, private readonly responsiveService: ResponsiveService,
    private readonly exchangeRateService: ExchangeRateService, private readonly notifyDateService: NotifyDateService, public accountCardService: AccountCardService,
    public cardAddresseeService: CardAddresseeService,
    private readonly insurancePoliciesService: InsurancePolicies,
    private readonly deductibleService : DataDeductibleService,
    private readonly patrimonialService: PatrimonialStatusService,
    private readonly investmentRegisterService: InvestmentRegisterService,
    private readonly notifyRouteService: NotifyRouteService,
    private readonly scheduledTransferService: ScheduledTransferService,
    private readonly sendAnotherDateService: SendAnotherDateService) {
    this.expressTransferExtraData$ = expressTransferExtraService.expressTransferExtraDataObservable;
    this.cardAddresseeSelected$ = cardAddresseeService.cardAddressee$;
    this.cardTransferInternational = this.accountCardService.accountCard.cardNumber;
    this.account =  this.accountCardService.accountCard.cardNumber;
  }
    ngOnInit(): void {
      this.validateInternationalTransfers();
      this.initForm();
      this.cardAddresseeSelected$.subscribe(data => {
        this.selectedAddressCard = {
          profile_image: data.profile_image,
          nombre: data.nombre,
          nombre_alias: data.nombre_alias,
          banco: data.banco,
          clabe: data.clabe,
          limite_diario: data.limite_diario,
          idCuentaProductoDestino: data.idCuentaProductoDestino,
          cuentaDestino: data.cuentaDestino,
          seleccionado: data.seleccionado,
          type: data.type,
          contrato: data.contrato
        };
        this.isThirdTransfer = data.banco === Constants.INBURSA_BANK;
        this.isBrokerageHouseTransfer = data.type === AddressConstants.TAB_INDEX_BROKERAGE_HOUSE;
      });
      this.facePassLoginService.isFacePassLogin$.subscribe(value => this.isFacePassLogin = value);
      if(this.carDec !== Strings.EMPTY){
        this.scheduledServicesProcess(this.carDec);}
      this.stateServicePayIdService.service$.subscribe(data => {
        this.idService = data.id;
        if (this.idService === Constants.SERVICE_CODE.CreditPayment) {
          this.showCreditPayment = true;
          this.viewFirst = false;
          this.viewData = false;
          this.isCreditPayment = true;
          this.timeToApply = Constants.TIME_TO_APPLY;
          if (this._router.url === PATH.CompleteCreditPayment) {
            this.isCreditPaymentCompleted = true;
            this.toPayDeductible = false;
          }
        }       
      });
      this.transferIdService.stateTransferIdObservable.subscribe(dataTransfer => {
        if (dataTransfer.id === Constants.SERVICE_CODE.International) {
          this.isInternationalTransfer = true;
        }
      });
      if (this.carDec === Constants.INSURANCE_COMPLETE || this.carDec === Constants.INSURANCE_ID) {
        this.paymentInsurancesIFM();
      }
      this.getBadge();
    }

  getBadge() {
    if (this.insurancePoliciesService.currencyStatus) {
      this.policyPayment = true;
      this.exchangeRate = Number(this.insurancePoliciesService.currencyValue);
      this.amountInternational = Number(this.insurancePoliciesService.currencyMXN);
    }
    else{
      this.policyPayment = false;
    }
  }

  get exchangeRateFixed() {
    return this.policyPayment || this.badge === CoinType.USD || !this.exchangeRateService.exchange.exchangeRateUSD?
    this.exchangeRate: (this.exchangeRate * this.exchangeRateService.exchange.exchangeRateUSD);
  }

  paymentInsurancesIFM() {
    this.insurancePoliciesService.newPaymentInsurance$.subscribe(payment => {
      if (payment !== null && payment !== undefined) {
        if (payment.contains(Constants.PAYMENT_POLICIES_MSI.LabelCVV.toLowerCase())) {
          this.paymentOtherBank = true;
          this.dataPaymentOtherBank = {
            name: payment.controls[Constants.PAYMENT_POLICIES_MSI.NameHolder].value,
            email: payment.controls[Constants.PAYMENT_POLICIES_MSI.NameEmail].value,
            phone: payment.controls[Constants.PAYMENT_POLICIES_MSI.NamePhone].value,
            address: payment.controls[Constants.PAYMENT_POLICIES_MSI.NameAddress].value,
            code: payment.controls[Constants.PAYMENT_POLICIES_MSI.NameZipeCode].value
          };
          this.cardOtherBank = payment.controls[Constants.PAYMENT_POLICIES_MSI.NameNumber].value;
          this.cardSelected = payment.controls[Constants.PAYMENT_POLICIES_MSI.NameType].value;
        } else {
          this.paymentOtherBank = false;
          if (payment.controls[Constants.PAYMENT_POLICIES_MSI.NameMonths].value !== Strings.EMPTY &&
            payment.controls[Constants.PAYMENT_POLICIES_MSI.NameMonths].value !==Numbers.Zero) {
            this.monthsMSI = payment.controls[Constants.PAYMENT_POLICIES_MSI.NameMonths].value;
            this.showTerm = true;
          } else {
            this.showTerm = false;
          }
        }
      } else {
        this._router.navigate([PATH.Home]);
      }
    });
  }

  scheduledServicesProcess(service: string) {
    switch (service) {
      case Constants.ID_DEDUCTIBLE_PAY:
      case Strings.CONFIRM_DATA.DeductiblePaymentMade:
        this.validateDeductiblePayment();
        break;
      case Constants.INSURANCE_ID:
      case Constants.INSURANCE_COMPLETE:
        this.validateInsurancePayment();
        this.completeInsurance = true;
        break;
      case Constants.INVESTMENT:
      case Strings.CONFIRM_DATA.CompleteInvestment:
        this.validateInvestments();
        break;
      case Constants.PATRIMONIAL:
        this.isPatrimonialTransfer = true;
        this.isInternationalTransfer = false;
        this.labelAmount = Strings.TRANSFER.Quantity;
        this.rootCardTransfer = this.dataConfirmPatrimonial.rootCard;
        this.rootAccountTransfer = this.dataConfirmPatrimonial.rootAccount;
        this.rootImgTransfer = this.dataConfirmPatrimonial.rootImage;
        this.toCardTransfer = this.dataConfirmPatrimonial.toCard;
        this.toAccountTransfer = this.dataConfirmPatrimonial.toAccount;
        this.toImgTransfer = this.dataConfirmPatrimonial.toImage;
        break;
      default:
    }
    this.stateServicePayIdService.service$.subscribe(value => {
      this.idService = value.id;
      if (this.idService === Constants.SERVICE_CODE.CreditPayment) {
        this.showCreditPayment = true;
        this.viewFirst = false;
        this.viewData = false;
        this.isCreditPayment = true;
        this.timeToApply = Constants.TIME_TO_APPLY;
        if (this._router.url === PATH.CompleteCreditPayment) {
          this.isCreditPaymentCompleted = true;
          this.toPayDeductible = false;
        }
      }
    });
    this.transferIdService.stateTransferIdObservable.subscribe(data => {
      if (data.id === Constants.SERVICE_CODE.International) {
        this.isInternationalTransfer = true;
      }
    });
  }
  validateDeductiblePayment(){
    this.labelAccount = Strings.SELECT_ACCOUNT.FromYourAccount;
    this.labelServiceToPay = Strings.DEDUCTIBLE.LabelServiceToPay;
    this.timeToApply = this.dataTimeApply;
    this.paymentCommission = this.dataCommission;
    this.dataLabelDeductible = Strings.DEDUCTIBLE;
    this.viewDeductible = this.deductibleService.confirmProcess.value;
    this.completePayment = this.deductibleService.completeProcess.value;
    this.numberPayment = this.deductibleService.claimNumber.claimNumber;
    this.deductibleInformation = this.deductibleService.deductibleInfo;
  }

  private validateInsurancePayment() {
    this.labelAccount = Strings.SELECT_ACCOUNT.FromYourAccount;
    this.operationDate = new Date().toLocaleDateString(Language.UnitedKingdom);
    this.labelOperationInformation = Strings.CONFIRM_DATA.Receipts;
    this.labelPolicy = this.listInsurance[Position.Zero].policy;
    this.timeToApply = this.dataTimeApply;
    this.paymentCommission = this.dataCommission;
    this.labelServiceToPay = Strings.PAYMENT_INSURANCE_POLICIES.LabelMSI;
    this.viewData = true;
    this.viewFirst = false;
    this.viewInsurance = true;
    this.badgePolicy = this.insurancePoliciesService.currencySymbol;
    this.dataLast = true;
    if (this.carDec === Constants.INSURANCE_ID) {
      this.labelAccount = Strings.SELECT_ACCOUNT.FromYourAccount;
    }
    else {
      this.toPayDeductible = false;
      this.conditions = false;
    }
  }
  private validateInvestments() {
    this.viewData = true;
    this.title = Strings.INVESTMENT.InvestmentType;
    this.labelServiceToPay = this.dataToConfirm[Position.Zero]?.selectedType;
    this.dataPolice = Strings.INVESTMENT.Term;
    this.labelPolicy = this.dataToConfirm[Position.Zero]?.selectedDate.concat(Char.WhiteSpace, Strings.DAYS_INVESTMENT);
    this.labelAccount = Strings.SELECT_ACCOUNT.From;
    this.dataLast = true;
    this.viewInvestment = true;
    this.viewFirst = false;
    this.paymentCommission = this.dataCommission;
    this.labelOperationInformation = Strings.INVESTMENT.OperationLabel;
    if(this.carDec === Strings.CONFIRM_DATA.CompleteInvestment) {this.toPayDeductible = false;}
  }
  private validateInternationalTransfers() {
    if (this._router.url === PATH.ConfirmInternationalTransfer) {
      this.isInternationalTransfer = true;
      this.badge = this.cardAddresseeService.selectedCardAddressee.badge;
      this.exchangeRate = this.exchangeRateService.exchange.exchangeRateMXP;
      this.amountInternational = this.notifyAmountService.internationalAmount.internationalAmount;
      this.purposePayment = this.optionBankService.optionBankServiceBS.getValue().purposePayment;
      this.warranty = this.warrantyInternationalService.warrantyTransferInternationalBS.getValue().warranty ? Constants.ENABLED_WARRANTY: Constants.DISABLED_WARRANTY;
    }
  }
  private initForm() {
    this.today = !!this.notifyDateService.dateStruct.value ? this.notifyDateService.dateFormat :
      formatDate(new Date(), FormatDate.BasicFormat, Constants.LOCALE_ES_MX);
    this.selectedCard = this.accountCardService.accountCard;
    this.expressTransferData = this.expressTransferService.expressTransferData;
    this.expressTransferData.clabe = Utils.removeSpaces(this.expressTransferData.clabe);
    this.isApp = AppUtils.platform.isApp;
    this.navigate(this.notifyAmountService.amount.amount);
    this.creditPayment = this.creditPaymentService.serviceCreditPaymentBS.getValue().creditPayment;
    this.referenceNumber = this.referenceNumberService.serviceReferenceNumberBS.getValue().referenceNumber;
    this.type = this.paymentTypeService.paymentTypeService.type;
  }
  navigate(amount: string) {
    this.amount = amount;
    this.width = Utils.calculateAmountWidth(amount.length, this.width);
    if (this.carDec === Strings.EMPTY && amount.length === Length.Empty && (this._router.url === PATH.ConfirmInternationalTransfer ||
      this._router.url === PATH.ConfirmExpressTransfer)) {
      this._router.navigate([PATH.Transfers]);
    } else if (this.carDec === Strings.EMPTY && amount.length === Length.Empty && this._router.url === PATH.ConfirmCreditPayment) {
      this._router.navigate([PATH.Home]);
    } else if (amount.length === Length.Empty && this._router.url === PATH.ConfirmPolicyPayment) {
      this._router.navigate([PATH.PaymentPolicies]);
    }
  }
  navigateBack() {
    if (this.modifyAction.observed) {
      this.modifyAction.emit();
    } else {
      this.notifyDateService.dateStructData = undefined;
      if (this.isPatrimonialTransfer) {
        this.patrimonialService.isFutureTransaction = false;
        this._router.navigate([PATH.TransferCTPatrimonial], { state: { amount: this.dataConfirmPatrimonial.amount, dataConfirmPatrimonial: this.dataConfirmPatrimonial } });
      } else if (this.isCreditPayment) {
        this._router.navigate([PATH.CreditPayment]);
      } else {
        this.route();
      }
    }
  }
  route() {
    if (this.carDec !== Strings.EMPTY) {
      switch (this.carDec) {
        case Constants.ID_DEDUCTIBLE_PAY:
          this.buttonClicked= true;
          this._router.navigate([PATH.NotAgendableServices]);
          break;
        case Constants.INSURANCE_ID:
          this._router.navigate([PATH.PaymentPolicies]);
          break;
        case Constants.INVESTMENT:
          this._router.navigate([PATH.Investment],
            {
              state: {
                dataConfirm: this.dataToConfirm
              }
            });
          break;
        default:
      }
    } else {
      this.scheduledTransferService.isClearScheduled = false;
      this.scheduledTransferService.isSelectedDate = false;
      this._router.navigate([PATH.TransferData]); }
  }
  nextPage() {
    this.completeProcess = true;
    const amount = parseFloat((this.amount.replace(Char.CurrencySymbol, Strings.EMPTY)).replace(Char.Comma, Strings.EMPTY));
    if (this.isCreditPayment) {
      this.creditPaymentNavigation(amount);
    } else {
      if (this.carDec !== Strings.EMPTY) {
        this.navigateNextService(amount);
      } else {
        this.validateShowTokenFacepass(amount);
      }
    }
  }
  validateShowTokenFacepass(amount:number){
    if(this.isScheduledTransfer() || this.scheduledTransferService.isFutureTransfer){
      this._router.navigate([this.scheduledTransferService.showTokenScheduledOwnTransfer(
        this.isFacePassLogin.value, amount) ? PATH.SecurityCode : PATH.TransferComplete]);
    }else if (this.cardAddresseeService.selectedCardAddressee.isOwnTransfer || (this.isFacePassLogin.value && amount < Constants.MAXIMUM_AMOUNT_TRANSFER_EXPRESS_FACEPASS)) {
      this._router.navigate([PATH.TransferComplete]);
    } else {
      const transferType = this.cardAddresseeService.transferType;
      this._router.navigate([PATH.SecurityCode], {
        state: {
          [Constants.NAVIGATION_PARAMETERS.IsInternationalTransfer]: transferType === AddresseTabs.International,
          [Constants.NAVIGATION_PARAMETERS.IsTransfer]: true
        }
      });
    }
  }
  navigateNextService(amount: number){
    switch (this.carDec) {
      case Constants.ID_DEDUCTIBLE_PAY:
        this.buttonClicked = true;
        this.goToFunction.emit();
        break;
      case Constants.INSURANCE_ID:
        this.selectedCardOtherBank = {
          image: this.iconCard,
          cardNumber: Constants.PAYMENT_POLICIES_MSI.IdAccountInsurances,
          clabe: Strings.EMPTY,
          balance: Strings.EMPTY,
          selected: true,
          productId: Constants.PAYMENT_POLICIES_MSI.IdProduct,
          accountNumber: Constants.PAYMENT_POLICIES_MSI.IdAccountInsurances,
          cardType: this.cardSelected
        };
        if (this.paymentOtherBank) {
          this.accountCardService.accountCard = this.selectedCardOtherBank;
        }
        const amountData = this.insurancePoliciesService.currencyStatus ?
          Number(this.insurancePoliciesService.currencyMXN) : amount;
        if (this.isFacePassLogin.value && amountData <= Constants.MAXIMUM_AMOUNT_TRANSFER_EXPRESS_FACEPASS) {
          this._router.navigate([PATH.PaymentCompleted], {
            state: {
              service: Constants.SERVICE_ID.InsurancePayment,
              listInsurance: this.listInsurance,
              dataAmount: this.amountInsurance
            }
          });
        } else {
          this._router.navigate([PATH.SecurityCode], {
            state: {
              service: this.idInsurance,
                title: Strings.SECURITY_CODE.TitleToken,
                subtitle: Strings.PAGE_HEADER.Subtitle,
                listSelectInsurance: this.listInsurance,
                amount: this.amountInsurance
            }
          });
        } break;
      case Constants.INVESTMENT:
        this.investmentRegisterService.investmentFormData = this.dataToConfirm;
        this._router.navigate([PATH.SecurityCode], {
          state: {
            service: Constants.SERVICE_ID.Investment,
            dataInvestment: this.dataToConfirm,
            amountInvestment: this.amount
          }
        }); break;
      case Constants.PATRIMONIAL:
        this.sendPatrimonial.push({
          amount: this.amount,
          rootCardTransfer: this.dataConfirmPatrimonial.rootCard,
          rootAccountTransfer: this.dataConfirmPatrimonial.rootAccount,
          rootImgTransfer: this.dataConfirmPatrimonial.rootImage,
          toCardTransfer: this.dataConfirmPatrimonial.toCard,
          toAccountTransfer: this.dataConfirmPatrimonial.toAccount,
          toImgTransfer: this.dataConfirmPatrimonial.toImage,
        });
        this._router.navigate([PATH.CompleteTransferPatrimonial], {
          state: {
            sendPatrimonial: this.sendPatrimonial
          }
        });
        break;
      default:
    }
  }
  private creditPaymentNavigation(amount: number) {
    if (this.isFacePassLogin.value === true && amount <= Constants.MAXIMUM_AMOUNT_TRANSFER_EXPRESS_FACEPASS) {
      this._router.navigate([PATH.CompleteCreditPayment]);
    } else { this._router.navigate([PATH.SecurityCode]); }
  }
  async nextPageConfirm() {
    NavigationUtils.pageTransitionDefaults(true, this.notifyClearFormService, this.idService,
      this._router, this.stateServicePayIdService, this.accountCardService,this.notifyRouteService);
  }
  async otherOperation() {
    NavigationUtils.pageTransitionDefaults(false, this.notifyClearFormService, this.idService,
      this._router, this.stateServicePayIdService, this.accountCardService,this.notifyRouteService);
  }

  validateExpressThirdTrasfer(bank: string) {
    return bank.toLowerCase().includes(Constants.INBURSA_BANK.toLowerCase());
  }
  isScheduledTransfer(){
    return this.scheduledTransferService.isScheduledProcess;
  }
  isAnotherDateTransfer(){
    return this.sendAnotherDateService.isOtherDate.value;
  }
  @HostListener('window:popstate')
  onBack() {
    this.scheduledTransferService.isClearScheduled = false;
  }
}
