<ng-container *ngIf="vm$ | async as  vm">
  <content-wrapper [contentMargin]="contentMargin" [useFlex]="!vm.breakpoints.mobile">
    <div content class="container-web-card-data">
      <ng-container *ngIf="webcardDataForm">
          <app-header-transfer-card [title]="title" [subtitle]="subtitle"
                                    [urlLocation]="pathHome">
          </app-header-transfer-card>
          <label class="header-xs" [ngClass]="vm.breakpoints.tablet ? 'mt-4' : 'mt-3' ">{{sourceAccount}}</label>
          <form [formGroup]="webcardDataForm" id="web-card-data-form">
            <basic-grid-template>
              <div id="first-col" class="pt-1">
                <app-card-account
                        *ngIf="vm.accountCard"
                        [card]="vm.accountCard"
                        [boxShadow]="true"
                        [useCdnPipe]="true"
                        [hideKey]="true"
                >
                </app-card-account>
                <label class="header-xs mb-3"
                      [ngClass]="vm.breakpoints.mobile ? 'mt-3' : 'mt-4'">
                  {{applicantInformation}}</label>
                <app-card-frame>
                  <div *ngFor="let input of inputConfig | slice:0:4">
                    <app-input-template [input]="input" [form]="webcardDataForm">
                      <ng-container *ngIf="inputTypes.includes(input.type)">
                        <input class="form-control inputs body-s" [type]="input.type" [formControlName]="input.name"
                              autocomplete="new-password" [id]="input.name"
                              [placeholder]="input.placeholder" [readonly]="input.readonly"
                              [maxlength]="input.maxlength"
                              [minlength]="input.minlength" [attr.inputMode]="input.inputMode"
                              (input)="validateString($event, input.name)"
                              (blur)="removeSpecialCharacters(input.name)"
                              cleanInput [cleanRegex]="denyOnlyLettersNoAccentsNoSpace"
                              onpaste="return false"
                              #inputs
                        />
                      </ng-container>
                      <ng-container *ngIf="input.type === 'select'">
                        <select class="form-control inputs body-s form-select" [formControlName]="input.name" #inputs
                                id="{{input.name}}" (blur)="onBlur(input.name)">
                          <option  value="" disabled selected hidden>{{messageSelect}}</option>
                          <option *ngFor="let option of input.options" [value]="option.value">
                            {{option.label}}
                          </option>
                        </select>
                      </ng-container>
                    </app-input-template>
                  </div>
                  <ng-container *ngIf="vm.breakpoints.mobile">
                    <div *ngFor="let input of inputConfig | slice:4:6">
                      <app-input-template [input]="input" [form]="webcardDataForm">
                        <ng-container *ngIf="inputTypes.includes(input.type)">
                            <input class="form-control inputs body-s" [type]="input.type"
                                  [formControlName]="input.name"
                                  autocomplete="off" [style]="input.style"
                                  [placeholder]="input.placeholder" [readonly]="input.readonly"
                                  [maxlength]="input.maxlength"
                                  (input)="onlyNumbers($event,input.name, webcardDataForm)"
                                  [minlength]="input.minlength" [attr.inputMode]="input.inputMode"
                                  (blur)="inputValue($event, input.name)"
                                  #inputs [id]="input.name"
                            />
                        </ng-container>
                        <ng-container *ngIf="input.type === 'select'">
                          <select class="form-control inputs body-s form-select" [formControlName]="input.name" #inputs
                                  (change)="input.fnGeneric($event)" id="{{input.name}}" (blur)="onBlur(input.name)">
                            <option  value="" disabled [style.display]="'none'"
                            *ngIf="!inputs.value">{{messageSelect}}</option>
                            <option *ngFor="let option of input.options" [value]="option.value">
                              {{option.label}}
                            </option>
                          </select>
                        </ng-container>
                      </app-input-template>
                    </div>
                  </ng-container>
                </app-card-frame>
                <ng-container *ngIf="vm.breakpoints.mobile">
                  <ng-container *ngTemplateOutlet="TermsConditions"></ng-container>
                </ng-container>
              </div>
              <div id="second-column" class="pt-1" *ngIf="!vm.breakpoints.mobile">
                <app-card-frame [customClass]="customClass">
                  <div *ngFor="let input of inputConfig | slice:4:6">
                    <app-input-template [input]="input" [form]="webcardDataForm">
                      <ng-container *ngIf="inputTypes.includes(input.type)">
                          <input class="form-control inputs body-s" [type]="input.type"
                                [formControlName]="input.name"
                                autocomplete="off" [style]="input.style"
                                [placeholder]="input.placeholder" [readonly]="input.readonly"
                                [maxlength]="input.maxlength"
                                (input)="onlyNumbers($event,input.name, webcardDataForm)"
                                [minlength]="input.minlength" [attr.inputMode]="input.inputMode"
                                (blur)="inputValue($event, input.name)"
                                #inputs [id]="input.name"
                          />
                      </ng-container>
                      <ng-container *ngIf="input.type === 'select'">
                        <select class="form-control inputs body-s form-select" [formControlName]="input.name"
                          #inputs (change)="input?.fnGeneric($event)" id="{{input.name}}" (blur)="onBlur(input.name)">
                          <option value="" disabled [style.display]="'none'"
                          *ngIf="!inputs.value">{{messageSelect}}</option>
                          <option *ngFor="let option of input.options" [value]="option.value">
                            {{option.label}}
                          </option>
                        </select>
                      </ng-container>
                    </app-input-template>
                  </div>
                </app-card-frame>
                <ng-container *ngTemplateOutlet="TermsConditions"></ng-container>
              </div>
              <div class="btn-container">
                <app-template-primary-button [properties]="continueProperties" [showIcon]="true">
                  {{continueButton}}
                </app-template-primary-button>
              </div>
            </basic-grid-template>
          </form>
      </ng-container>
    </div>
  </content-wrapper>

  <ng-template #TermsConditions>
    <div id="termsNconditions" class="mb-3" *ngIf="formTerms">
      <div id="checkContainer" class="mt-3">
        <label class="container1" id="c1" for="check">
          <form [formGroup]="formTerms">
            <input
                    id="check"
                    type="checkbox"
                    formControlName="terms"
            />
            <span class="checkmark"></span>
          </form>
        </label>
        <div class="detail-s-highlight-hypertext cursor-pointer declare"(click)="modalTermsAndConditions()">
          {{declaration}}
        </div>
      </div>
      <div *ngIf="formTerms.get(webCardTerms).touched && formTerms.get(webCardTerms).hasError('required')"
      class="mt-2 ms-2">
        <span class="detail-m error">
          {{validationMessage}}
        </span>
      </div>
    </div>
  </ng-template>
</ng-container>
