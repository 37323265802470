import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, TemplateRef, ViewChild, WritableSignal, signal } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbDropdown, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, SubscriptionLike, map } from 'rxjs';
import { ModalBranchesComponent } from 'src/app/component/modal-branches/modal-branches.component';
import { ModalRequestConfirmationComponent } from 'src/app/control-card/components/modal-request-confirmation/modal-request-confirmation.component';
import { OperationBlockComponent } from 'src/app/control-card/components/operation-block/operation-block.component';
import { InputConfig } from 'src/app/interface/IInputConfig';
import { LockCardValueModel } from 'src/app/interface/LockCardValueModel';
import { GetDataCardResponse } from 'src/app/interface/dto/GetDataCardResponse';
import { GetLocksAndLimitsResponse } from 'src/app/interface/dto/GetLocksAndLimitsResponse';
import { ICardNumberInfo } from 'src/app/interface/dto/ICardNumberInfo';
import { DynamicCvvResponse } from 'src/app/interface/dto/IDynamicCvvResponse';
import { PinModificationResponse } from 'src/app/interface/dto/IPinModificationResponse';
import { RegisterCvvResponse } from 'src/app/interface/dto/IRegisterCvvResponse';
import { UpdateLockLimitsCardResponse } from 'src/app/interface/dto/UpdateLockLimitsCardResponse';
import { OptionsModalToken } from 'src/app/interface/modal-options';
import { AlertService } from 'src/app/services/alert.service';
import { CardsAdminService } from 'src/app/services/cards-admin.service';
import { ControlCardService } from 'src/app/services/control-card.service';
import { Directions } from 'src/app/services/data-checkbook.service';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import { NotifyDateService } from 'src/app/services/notify-date.service';
import { StateCardNumberInfoService } from 'src/app/services/state-card-number-info.service';
import { ModalAlertsComponent } from 'src/app/shared/components/modal-alerts/modal-alerts.component';
import { ModalEndProcessAlertComponent } from 'src/app/shared/components/modal-end-process-alert/modal-end-process-alert.component';
import { ModalMapComponent } from 'src/app/shared/components/modal-map/modal-map.component';
import { ModalService } from 'src/app/shared/modal.service';
import { BreakpointState, ResponsiveService } from 'src/app/shared/responsive.service';
import { CardType, Constants, DebitCardType, Numbers, Position, Regex } from 'src/core/constants/Constants';
import { ControlCardConstants } from 'src/core/constants/ControlCardConstants';
import { ControlCardResources } from 'src/core/constants/ControlCardResources';
import { ControlCardStrings } from 'src/core/constants/ControlCardStrings';
import { FormRegex } from 'src/core/constants/FormConstants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { environment } from 'src/environments/environment';
import { ModalMyCardSelectOption } from 'src/app/interface/ModalMyCardSelectOption';
import { ModalMyCardsExtensionService } from 'src/app/services/modal-my-cards.extension.service';
import { ModalMyCardsExtensionTwoService } from 'src/app/services/modal-my-cards.extension-two.service';
import { ModalMyCardsExtensionThreeService } from 'src/app/services/modal-my-cards-three.extension.service';
import { Keys } from 'src/core/constants/Keys';
import { ModalMyCardsService } from 'src/app/control-card/components/modal-my-cards/service/modal-my-cards.service';
import { ModalMyCardsLimitsService } from 'src/app/control-card/components/modal-my-cards/service/modal-my-cards-limits.service';
import { ModalMyCardsFormsService } from 'src/app/control-card/components/modal-my-cards/service/modal-my-cards-forms.service';
import { ModalMyCardsNipService } from 'src/app/control-card/components/modal-my-cards/service/modal-my-cards-nip.service';
import { ModalMyCardsCvvService } from 'src/app/control-card/components/modal-my-cards/service/modal-my-cards-cvv.service';
import { MapService } from 'src/app/services/map.service';
import { ModalMyCardsTabsService } from 'src/app/control-card/components/modal-my-cards/service/modal-my-cards-tabs.service';
import { IAlertInfo } from 'src/app/models/AlertInfo';
import { Regex as RegexConstants } from 'src/core/constants/RegexConstants';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';
import { PATH } from 'src/core/constants/Path';
@Component({
  selector: 'app-modal-my-cards',
  templateUrl: './modal-my-cards.component.html',
  styleUrls: ['./modal-my-cards.component.css'],
})
export class ModalMyCardsComponent implements OnInit, OnDestroy {
  @ViewChild('MyDropdownPeriods') optionCard: NgbDropdown;
  @ViewChild('CancelCard', { static: true }) cancelCard: ElementRef;
  @ViewChild('Otp') otpModal: ElementRef<HTMLInputElement>;
  @ViewChild('ModalCards') modalCards: ElementRef<HTMLInputElement>;
  @ViewChild('ModalCVV') modalCVV: ElementRef<HTMLInputElement>;
  @ViewChild('ModalMapFooter') modalMapFooter: TemplateRef<HTMLElement>;
  @ViewChild('Operation') operation: OperationBlockComponent;
  @ViewChild('ModalMapSelect') modalMapSelect: TemplateRef<HTMLElement>;
  @ViewChild('ModalCVVChange') modalCVVChange: ElementRef<HTMLInputElement>;
  @ViewChild('ModalAlertCVV') modalAlertCVV: ElementRef<HTMLInputElement>;
  @ViewChild(OperationBlockComponent) operationBlock: OperationBlockComponent;
  @ViewChild('Map') map: ModalMapComponent;
  @Input('modal') modal!: NgbModalRef;
  selectLock: string = Strings.ACTIVE;
  selectModify: string = Strings.EMPTY;
  selectCVV: string = Strings.EMPTY;
  selectCancel: string = Strings.EMPTY;
  blockade: boolean = true;
  modify: boolean = false;
  cvv: boolean = false;
  cancel: boolean = false;
  additional: boolean = false;
  atmInformation: number;
  commerceInformation: number;
  percentInfo: number = Numbers.Ten;
  onlineInformation: number;
  foreinInformation: number = ControlCardConstants.START_PERCENT;
  atmValue: number;
  commerceValue: number;
  foreignValue: number = Constants.AMOUNT_ZERO;
  codiValue: number = Constants.AMOUNT_ZERO;
  phoneValue: number = Constants.AMOUNT_ZERO;
  getDataCardsInfo: GetDataCardResponse;
  getLocksLimitsInfo: GetLocksAndLimitsResponse;
  cardNumberInfo: ICardNumberInfo;
  cardNumberInfo$: Observable<ICardNumberInfo> = this.cardNumberInfoService.cardNumberInfo$;
  nipValue: string;
  confirmedPinValue: string;
  adminCardForm: FormGroup;
  cancelForm: FormGroup;
  mapForm: FormGroup;
  showRequiredForm: boolean = false;
  sourceInfo: string;
  timeCvv: boolean = false;
  updateLockLimitsCardInfo: UpdateLockLimitsCardResponse;
  nipModificationResponse: PinModificationResponse;
  registerCvvResponse: RegisterCvvResponse;
  dynamicCvvResponse: DynamicCvvResponse;
  buttonModalCard: string = Strings.CONTINUE_BUTTON;
  isContinueLockValid: boolean = false;
  isContinueCvvValid: boolean = false;
  lockLimitsCardValues: LockCardValueModel[] = [];
  idModal: string = Constants.MODAL_OPTIONS.CancelCard;
  headerModal: string = Strings.MODAL_MESSAGES.HeaderMyCards;
  labelMain: string = Strings.MODAL_MESSAGES.MyCardsID;
  continue: string = Strings.MSG_POPUP_ACCEPT;
  minutes: number = Constants.MINUTES_DYNAMIC_CVV;
  seconds: number = Constants.SECONDS_DYNAMIC_CVV;
  cvvTimer: number;
  cvvCardForm: FormGroup;
  pictureCard: string = Strings.EMPTY;
  otpValue: string = Strings.EMPTY;
  getPinValue: string = Strings.EMPTY;
  finish: boolean = false;
  closeOtpModal: NgbModalRef;
  errorConfirmNip: boolean = false;
  readonly minLength: number = Constants.MAX_LENGTH_CVV;
  regexNumberTarget: RegExp = RegexConstants.DecimalsDot;
  regexTextTarget: RegExp = Regex.OnlyText;
  regexRFCTarget:RegExp = FormRegex.ValidateRFC;
  regexLettersTarget: RegExp = Regex.NotNumbersLetters;
  cardCvv: string = Strings.EMPTY;
  hideCvvForm: boolean = true;
  lblDcvv: string = Strings.EMPTY;
  activeCvv: boolean = true;
  showDcvv: boolean = true;
  hideButton: boolean = true;
  locksAtm: boolean = false;
  locksCommerce: boolean = false;
  locksInt: boolean = false;
  locksCodi: boolean = false;
  locksOpi: boolean = false;
  selectedLockTab: boolean = false;
  selectedModifyTab: boolean = false;
  selectedCvvTab: boolean = false;
  selectedCvvContinueTab: boolean = false;
  minimumAtmAmount: number = Constants.MINIMUM_ATM_AMOUNT;
  minimumCommerceAmount: number = Constants.MINIMUM_COMMERCE_AMOUNT;
  minimumOnlineAmount: number = Constants.MINIMUM_ONLINE_AMOUNT;
  minimumCodiAmount: number = ControlCardConstants.MINIMUM_CODI_AMOUNT;
  minimumForeingAmount: number = ControlCardConstants.MINIMUM_FOREING_AMOUNT;
  maximumAtmAmount: number = Constants.MAXIMUM_ATM_AMOUNT;
  maximumCommerceAmount: number = Numbers.Zero;
  maximumOnlineAmount: number = Constants.MAXIMUM_COMMERCE_AMOUNT;
  maximumCodiAmount: number = ControlCardConstants.MAXIMUM_CODI_AMOUNT;
  maximumForeingAmount: number = ControlCardConstants.MAXIMUM_FOREING_AMOUNT;
  movementsZero: number = Constants.MOVEMENTS_ZERO;
  groceryVoucherCard: string = DebitCardType.GroceryVoucherCard;
  walmartGroceryVoucherCard: string = DebitCardType.WalmartGroceryVoucherCard;
  webCard: string = DebitCardType.WebCard;
  percent: number;
  status: boolean = false;
  hideData: boolean = false;
  type: string = Strings.EMPTY;
  errorComplete: string = Strings.DEFAULT_VALIDATIONS_MESSAGES_FORM.required;
  errorSelectComplete: string = ControlCardStrings.OPTION_ERROR;
  errorRFC: string = Strings.DEFAULT_VALIDATIONS_MESSAGES_FORM.required;
  title: [] = [];
  getDateData: string = Strings.EMPTY;
  dinamycCVV: boolean = false;
  directions: WritableSignal<Array<Directions>> = this.mapService.directionCopySignal;
  subtitle: string = ControlCardStrings.MAP_SUBTITLE;
  clickedButton: boolean = false;
  reasonCancel: boolean = true;
  lblContinue: string = ControlCardStrings.CONTINUE;
  cardInfoType: string = Strings.EMPTY;
  sourceDataInfo: string = Strings.EMPTY;
  replacement: boolean = false;
  renovation: boolean = false;
  reception: boolean = false;
  showSucursal: boolean = false;
  titleModalBranch: string = Strings.EMPTY;
  isMovilDevice: boolean = false;
  activeOption: boolean = true;
  debitType: string = CardType.Debit;
  selectOption: string;
  unsubscription: SubscriptionLike;
  hasError: boolean = false;
  amountValue: boolean = true;
  amountMaxValue: boolean = true;
  showCodi: boolean = false;
  vm$: Observable<{breakpoints: BreakpointState}> = this.responsiveService.observe.pipe(map((breakpoints) => { return { breakpoints }; }));
  chevronRight: string = Resources.SHAPE_CONTINUE;
  closeCircleBlue: string = Resources.CLOSE_CIRCLE_BLUE;
  blankcardFav: string = ControlCardResources.BLANKCARD_FAV;
  helpIcon: string = Resources.HELP_ICON;
  helpRedIcon: string = Resources.QUESTION_MARK_RED;
  clockIcon: string = ControlCardResources.CLOCK25;
  clockArray: string[] = [ControlCardResources.CLOCK25, ControlCardResources.CLOCK50, ControlCardResources.CLOCK75, ControlCardResources.CLOCK100];
  messageCvv: IAlertInfo = ControlCardConstants.MESSAGE_CVVDINFO;
  messageChanges: IAlertInfo = ControlCardConstants.MESSAGE_CVV_CHANGE_INFO;
  codiInformation: number = ControlCardConstants.START_PERCENT;
  timeCvvSvg: string = Resources.TIME_CVV;
  @Input() modifyReception: boolean = false;
  @Input() modifyCVVReopen: boolean = false;
  @Input() cancelReopen: boolean = false;
  hasCVV: boolean = false;
  birthDate: WritableSignal<string> = signal<string>(Strings.EMPTY);
  zeroAtm: boolean = false;
  zeroPos: boolean = false;
  zeroInt: boolean = false;
  zeroCodi: boolean = false;
  zeroForeing: boolean = false;
  beta: boolean = environment.hiddenComponents.controlCardBeta;
  continueSave: boolean = false;
  showErrorLoad: boolean = false;
  inputLenght: number = ControlCardConstants.MAXIMUM_IMPUT_LENGHT;
  validateCharacters: RegExp = Regex.NotNumbersLetters;
  inputConfig: InputConfig[] = this.controlCardService.optionConfig;
  cancelInputConfig: InputConfig[] = this.controlCardService.cancelOptionConfig;
  additionalInputConfig: InputConfig[] = this.controlCardService.additionalOtionConfig;
  branchInputConfig: InputConfig[] = this.controlCardService.branchConfig;
  optionform: FormGroup = this.formService.toFormGroup(this.inputConfig);
  reasonValue: string = ControlCardStrings.OPERATION_OPTIONS.OPTIONS[Position.Zero].text;
  cancelValue: string = Strings.EMPTY;
  productCardsAmex: string[] = environment.amexCards;
  reasonOptions: {text: string; value: string; selected: boolean; disabled: boolean;beta: boolean;}[] = ControlCardStrings.OPERATION_OPTIONS.OPTIONS;
  cancelOptions: {label: string; value: string; }[] = ControlCardStrings.CANCEL_OPTIONS;
  contributionTypeError: WritableSignal<boolean> = signal<boolean>(false);
  contributionType: WritableSignal<string> = signal<string>(Strings.EMPTY);
  optionsModalToken: OptionsModalToken;
  localPage: number = Numbers.Zero;
  errorInDate: boolean = false;
  state: boolean = false;
  cvvstate: boolean = false;
  modifystate: boolean = false;
  cancelstate: boolean = false;
  showDropDown: boolean = false;
  extractLabel = Utils.extractLabel;
  @ViewChild('Content') header!: ElementRef;
  @ViewChild('Footer') footer!: ElementRef;
  @ViewChild('ModalContainer') modalContainer: ElementRef;
  minHeight: number = Numbers.Zero;
  lastValueAmount: number = Numbers.Zero;
  onFocusAmountAtm: boolean = false;
  onFocusAmountCommerce: boolean = false;
  onFocusAmountOnline: boolean = false;
  onFocusAmountForein: boolean = false;
  onFocusAmountMonthly: boolean = false;
  flagNotChanges: boolean = true;
  flagNotChangesMovements: boolean = true;
  arrayValuesLimits: {} = { Limits: { Amounts: {}, Movements: {} } };
  modalInstance: NgbModalRef;
  modalAlertTitle: string = Strings.POP_UP_MESSAGE.CardAdmin;
  modalAlertBtn: string = Strings.MSG_POPUP_ACCEPT;
  showBranches: boolean = false;
  isAditional: boolean = false;
  constructor(readonly modalService: ModalService, readonly cardNumberInfoService: StateCardNumberInfoService,
    readonly formBuilder: FormBuilder, readonly alertService: AlertService,
    readonly responsiveService: ResponsiveService, readonly cardsAdminService: CardsAdminService,
    public readonly notifyDate: NotifyDateService, readonly formService: DynamicFormService,
    readonly controlCardService: ControlCardService, readonly componentExtension: ModalMyCardsExtensionService,
    readonly componentExtensionTwo: ModalMyCardsExtensionTwoService, readonly componentExtensionThree: ModalMyCardsExtensionThreeService,
    public modalMyCardsService: ModalMyCardsService, public modalMyCardsLimitsService: ModalMyCardsLimitsService,
    public modalMyCardsFormsService: ModalMyCardsFormsService, public modalMyCardsNipService: ModalMyCardsNipService,
    public modalMyCardsCvvService: ModalMyCardsCvvService, private readonly mapService: MapService, public modalMyCardsTabsService: ModalMyCardsTabsService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  ngOnDestroy(): void { this.modalMyCardsService.ngOnDestroy(this); }
  setMaxContent() { this.modalMyCardsService.setMaxContent(this); }
  setContributionType(contributionType: string) { this.componentExtension.setContributionType(contributionType, this); }
  changeReason(event: ModalMyCardSelectOption) {
    this.componentExtension.changeReason(event, this);
    this.optionCard.close();
  }
  async ngOnInit() {
    NavigationUtils.fixNavigation(this.changeDetector, [PATH.Control, PATH.Control]);
    this.hiddenModal(true);
    await this.componentExtensionTwo.initComponent(this);
    this.cvvBlockAmex();
  }

  cvvBlockAmex(){
    if(this.productCardsAmex.some(card => card === this.cardNumberInfoService?.cardNumberInfo?.CardDataInfo?.cardType)){
      this.reasonOptions = this.reasonOptions.filter(options => options.text !== ControlCardStrings.MSG_POPUP_TITLE_CVVD);
    }
  }
  handleSelect($event: Event, inputName) { this.componentExtensionThree.handleSelect($event, inputName); }
  focusNip($event: Event, name: string) { this.componentExtensionThree.onlyNumbers($event, name, this.adminCardForm); }
  changeSelect(inputName: string) { this.cancelForm.get(inputName).markAsTouched(); }
  changeValue(option: { value: string; label: string; }, name: string) { this.cancelForm.controls[name].setValue(option.value); }
  changeBranchSelect(inputName: string) { this.mapForm.get(inputName).markAsTouched(); }
  changeBranchValue(option: { value: string; label: string; }, name: string) { this.mapForm.controls[name].setValue(option.value); }
  recoverOption(option: string) { return ControlCardStrings.OPERATION_OPTIONS.OPTIONS.find(({ value }) => value === option); }
  initForm(): FormGroup { return this.modalMyCardsFormsService.initForm(); }
  initFormCvv(): FormGroup { return this.modalMyCardsFormsService.initFormCvv(); }
  initMapForm(): FormGroup { return this.modalMyCardsFormsService.initMapForm(); }
  lockOperation(locksValues: Array<LockCardValueModel>) { this.componentExtension.lockOperation(locksValues, this); }
  validateContinueLock(value: number) {
    if (this.validateZeroMovements(value)) {
      this.isContinueLockValid = false;
      return;
    }
    this.isContinueLockValid = true;
  }
  async saveChange(option?: string) {
    this.validateAdminCard();
    this.validateInputsAmount();
    this.validateInputsLimit();
    this.validateZeroMovementsData();
    this.validateAmounts();
    this.validateMaxAmounts();
    this.validOption(option);
  }
  validOption(option: string) { this.componentExtension.validOption(option, this); }
  async updateLimits() { await this.modalMyCardsLimitsService.updateLimits(this); }
  validateAdminCard() { this.componentExtension.validateAdminCard(this); }
  validateZeroMovementsData() { this.componentExtension.validateZeroMovementsData(this); }
  validateErrors() { this.componentExtension.validateErrors(this); }
  validateInputsAmount() { this.componentExtension.validateInputsAmount(this); }
  validateAmounts() { this.componentExtension.validateAmounts(this); }
  validateMaxAmounts() { this.componentExtension.validateMaxAmounts(this); }
  continueValidateInputsAmount() { this.componentExtension.continueValidateInputsAmount(this); }
  validateInputsLimit() { this.componentExtension.validateInputsLimit(this); }
  resetInputsDeactivate(lockValue: boolean, lockName: string) { this.componentExtension.resetInputsDeactivate(lockValue, lockName, this); }
  closeModal($event: string) { this.componentExtension.closeModal($event, this); }
  modalHelpCvv() { this.modalMyCardsCvvService.modalHelpCvv(this); }
  selectCvvTab(register: boolean, getCvv: boolean) { this.modalMyCardsCvvService.selectCvvTab(register, getCvv, this); }
  checkInterval(register: boolean, getCvv: boolean) { this.componentExtension.checkInterval(register, getCvv, this); }
  selectCancelTab() { this.modalMyCardsTabsService.selectCancelTab(this); }
  tabsFunction(event: string) { this.modalMyCardsTabsService.tabsFunction(event, this); }
  public close() { this.modalMyCardsService.close(this);
    NavigationUtils.fixNavigation(this.changeDetector, [PATH.Home, PATH.Control]);}
  blurFormat(event: Event) { this.componentExtensionThree.onBlur(event); }
  getInputOnlineValue(val: Event) { this.componentExtensionTwo.getInputOnlineValue(val, this); }
  validateZeroMovements(val: number) { return val === Numbers.Zero; }
  getInputAtmValue(val?: Event) { this.componentExtensionTwo.getInputAtmValue(this, val); }
  getInputMonthlyValue(val?: Event) { this.componentExtensionTwo.getInputMonthlyValue(this, val); }
  getInputMovementsAtmValue(val: Event) { this.componentExtensionTwo.getInputMovementsAtmValue(val, this); }
  getInputCommerceValue(val: Event) { this.componentExtensionTwo.getInputCommerceValue(val, this); }
  getInputMovementsCommerce(val: Event) { this.componentExtensionTwo.getInputMovementsCommerce(val, this); }
  getInputMovementsOnline(val: Event) { this.componentExtensionTwo.getInputMovementsOnline(val, this); }
  getInputMovementsForeing(val: Event) { this.componentExtensionTwo.getInputMovementsForeing(val, this); }
  getInputMovementsCodi(val: Event) { this.componentExtensionTwo.getInputMovementsCodi(val, this); }
  getInputCodiValue(val: Event) { this.componentExtensionTwo.getInputCodiValue(val, this); }
  getInputForeignValue(val: Event) { this.componentExtensionTwo.getInputForeignValue(val, this); }
  validateInputNumber(event: Event) { this.componentExtensionTwo.validateInputNumber(event, this); }
  async getOtp($event: Event) {
    this.otpValue = $event[Constants.OTP_POSITION];
    if (this.otpValue && this.selectedCvvTab) { this.registerCvvService(this.cardCvv); }
    if (this.otpValue && this.selectedModifyTab) { this.nipModificationService(); }
  }
  validateNip(nip: string, confirmPin: string, $event?: Event, name?: string) {this.modalMyCardsNipService.validateNip(nip, confirmPin, $event, name, this);}
  async getPin() { this.modalMyCardsNipService.getPin(this);}
  async getEmitLockOpBlock(params: { look: boolean, messageAlert?: string, textLock?: string }) {
    if (params.look) {
      this.finish =true;
      this.alertService.showPopupAlert({
        message: `${params.messageAlert} ${params.textLock}`,
        header: params.textLock,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: !params.look ? Resources.CLOSE_ICON_RED : Strings.EMPTY,
        btnExit: false,
        textCenter:true
      }, {
        onSuccess: async () => {
          if(params.textLock !== ControlCardConstants.MONTHLY_TEXT){
            await this.getDataCards();
          }else{
            await this.getLocksLimits();
          }
          this.operationBlock.openModalMyCards();
        }
      });
      this.cleanZeroFlags();
    }
    this.hiddenModal(params.look);
  }
  cleanZeroFlags() {
    this.zeroAtm = false;
    this.zeroPos = false;
    this.zeroInt = false;
  }
  getRegisterCvv() { this.modalMyCardsCvvService.getRegisterCvv(this); }
  getDynamicCvvService() { this.modalMyCardsCvvService.getDynamicCvvService(this); }
  processCvv() { this.modalMyCardsCvvService.processCvv(this); }
  async getDataCards() { await this.modalMyCardsLimitsService.getDataCards(this); }
  async getLocksLimits() { await this.modalMyCardsLimitsService.getLocksLimits(this); }
  async updateLockLimitsCard() { await this.modalMyCardsLimitsService.updateLockLimitsCard(this); }
  hiddenModal(show: boolean) { this.modalMyCardsService.hiddenModal(show); }
  async nipModificationService() { await this.modalMyCardsNipService.nipModificationService(this); }
  async registerCvvService(cvvValue?: string) { await this.modalMyCardsCvvService.registerCvvService(cvvValue, this); }
  async dynamicCvvService() { await this.modalMyCardsCvvService.dynamicCvvService(this); }
  onKeyPress(event: KeyboardEvent) { return this.componentExtensionThree.onKeyPress(event); }
  getCardType(source: string) { return this.modalMyCardsService.getCardType(source); }
  renovationCard(content: TemplateRef<ModalBranchesComponent>, type: string) { this.componentExtensionTwo.renovationCard(content, type, this); }
  cardCancellationModal(content?: TemplateRef<ModalAlertsComponent>) { this.componentExtensionTwo.cardCancellationModal(this, content); }
  cardQuestionModal(content: TemplateRef<ModalEndProcessAlertComponent>) { this.componentExtensionTwo.cardQuestionModal(content, this); }
  cardCVVDinamycModal(content: TemplateRef<ModalEndProcessAlertComponent>) { this.componentExtensionTwo.cardCVVDinamycModal(content, this); }
  aceptCancel(isAcepted: Event, modalOption?: TemplateRef<ModalAlertsComponent>) { if (isAcepted) { this.cardCancellationModal(modalOption); } }
  continueRenovation(content: TemplateRef<ModalBranchesComponent>) { this.componentExtensionTwo.continueRenovation(content, this); }
  closeAlert() { this.componentExtensionTwo.closeAlert(this); }
  closeCVVDTab() { this.modalService.close(); }
  closeAlertChange() { this.modalService.modalInstance.close(); }
  continueProcessCard(content: TemplateRef<ModalRequestConfirmationComponent>) { this.componentExtensionTwo.continueProcessCard(content, this); }
  async getCardInfoType() { if (this.getDataCardsInfo?.Card !== undefined) { this.cardInfoType = this.getDataCardsInfo?.Card?.CardType; } }
  showicon($event?: Event, nameField?: string) { this.modalMyCardsCvvService.showicon($event, nameField, this); }
  validateRequired() { this.modalMyCardsCvvService.validateRequired(this); }
  preventSpaceKey(event: KeyboardEvent) { if (event.code === Keys.KEY_SPACE) { event.preventDefault(); } }
  renovationOption(type: string) { this.componentExtensionTwo.renovationOption(type, this); }
  continuetoRenovation() { this.componentExtensionTwo.continuetoRenovation(this); }
  continuetoCancel() { this.componentExtensionTwo.continuetoRenovation(this); }
  receiveDataFromSearch(searchFilter: string) { this.componentExtensionTwo.receiveDataFromSearch(searchFilter, this); }
  focusError() { document.getElementById('top-header').scrollIntoView({ behavior: 'smooth' }); }
  modifyCvvButtonState() { return this.componentExtensionThree.modifyCvvButtonState(this); }
  locksAndLimitsButtonState() { return this.componentExtensionThree.locksAndLimitsButtonState(this); }
  movementBlur(event: Event) { this.componentExtensionThree.getInput(event); }
  removeEspecialCharacters(event: Event, controlName: string, form: FormGroup, maxLenght?: number) {
    this.componentExtensionThree.removeCharacters(event, controlName, form, Regex.OnlyNumbers, this, maxLenght);
  }
  validateChangeValueAmount(typeLimit: string, event: Event) { this.componentExtensionThree.validateChangeValueAmount(typeLimit, event, this); }
  validateChangeMovements(typeMovement: string, event: Event) { this.componentExtensionThree.validateChangeMovements(typeMovement, event, this); }
  setLocation(event: number) { this.mapService.setLocation(event); }
  @HostListener('window:popstate')
  onBack(){
    this.modalInstance.close();
  }
}
