import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { combineLatestWith, map } from 'rxjs';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { SidebarResponsiveService } from 'src/app/services/sidebar-responsive.service';
import { NotificationInfoService } from 'src/app/services/notification-info.service';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalOptions } from 'src/app/interface/modal-options';
import { Constants, ElementsHTML, Length, Numbers } from 'src/core/constants/Constants';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Utils } from 'src/core/utils/utils';
import { NotificationModel } from 'src/app/interface/NotificationModel';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ProductStrings } from 'src/core/constants/ProductStrings';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit {
  bellIcon: string = Resources.BLUE_BELL;
  trashIcon: string = Resources.LIGHT_BLUE_TRASH;
  messageNotifications: string = Strings.EMPTY;
  messageNew: string = Strings.EMPTY;
  titleError: string = Strings.EMPTY;
  @ViewChild(ProductStrings.MODAL_DELETE_NOTIFICATION.Id, { static: true }) deleteNotificationModal: ElementRef;
  @ViewChild(ProductStrings.MODAL_DELETE_NOTIFICATION.Id, { static: true }) errorGetNotifications: ElementRef;
  @ViewChild(Constants.ID_DROPDOWN_NOTIFICATIONS) notificationWindow: NgbDropdown;
  idModal: string = ProductStrings.MODAL_DELETE_NOTIFICATION.Id;
  headerModal: string = ProductStrings.MODAL_DELETE_NOTIFICATION.Header;
  continue: string = ProductStrings.MODAL_DELETE_NOTIFICATION.Continue;
  notificationsFiltered: Array<NotificationModel> = new Array<NotificationModel>();
  updateNotifications: boolean = true;

  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(
      this.sidebarResponsiveService.stateObservable,
      this.notificationInfoService.totalNotifications$,
      this.notificationInfoService.notificationsInformation$,
      this.notificationInfoService.showNotifications$,
      this.notificationInfoService.isError$,
      this.notificationInfoService.errorNotifications$,
      this.notificationInfoService.showIconError$
    ),
    map(([breakpoints, show, notifications, infoNotifications, showNotifications, isError, errorNotifications, showIconError]) => {
      if(!breakpoints.mobile){
        document.body.style.overflowY = Strings.NOTIFICATIONS.MsgNotShowScroll;
      }
      return ({
        breakpoints,
        show,
        notifications,
        infoNotifications,
        showNotifications,
        isError,
        errorNotifications,
        showIconError
      });
    })
  );

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly sidebarResponsiveService: SidebarResponsiveService,
    private readonly notificationInfoService: NotificationInfoService,
    private readonly modalService: ModalService
  ) {
    this.messageNotifications = Strings.NOTIFICATIONS.MsgNotifications;
    this.messageNew = Strings.NOTIFICATIONS.MsgNewNotifications;
  }

  async ngOnInit() {
    await this.notificationInfoService.getTotalNotifications();
  }

  onNotificationClick(notification: NotificationModel): void {
    notification.isSelected = !notification.isSelected;
  }

  async deleteNotification() {
    await this.notificationInfoService.updateNotification(true);
  }

  showModalDeleteNotification(): void {
    const modalOptions: ModalOptions = {
      windowClass: ModalConstants.MODAL_OPTIONS.WindowsClassCardModal,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.WindowsClassCardModalWide,
      size: Constants.MODAL_OPTIONS.SizeSm,
      centered: true
    };
    this.notificationsFiltered = this.notificationInfoService.notificationsInformation?.filter(
      notification => notification.isSelected === true && notification.estatus === Constants.STATES_NOTIFICATIONS.Read);
    if (this.notificationsFiltered.length > Length.Empty) {
      this.modalService.open(this.deleteNotificationModal, modalOptions);
    }
  }

  async clearNotifications(event: boolean) {
    this.notificationInfoService.isError = false;
    this.notificationInfoService.showNotifications = false;
    if (event && this.updateNotifications) {
      await this.notificationInfoService.getTotalNotifications();
      await this.notificationInfoService.getNotificationsInformation();
      await this.notificationInfoService.updateNotification(false);
      this.cleanNotifications();
    }
    if(this.updateNotifications) {
      this.notificationInfoService.notificationsInformation?.forEach(element => {
        element.isSelected =  false;
      });
    }
  }

  cleanNotifications() {
    this.notificationInfoService.notificationsInformation?.forEach(element => {
      element.estatus =  Constants.STATES_NOTIFICATIONS.Read;
    });
  }

  toggleFunction(isOpen: boolean): void {
    if (isOpen) {
      document.body.style.overflowY = ElementsHTML.Hidden;
    } else {
      document.body.style.overflowY = Strings.NOTIFICATIONS.MsgNotShowScroll;
    }
  }

  async closeModal(event: string) {
    if (event === Constants.MODAL_OPTIONS.CancelAccount) {
      this.modalService.close();
      this.updateNotifications = false;
    } else if (event === Constants.MODAL_OPTIONS.LabelConfirm) {
      this.modalService.close();
      this.updateNotifications = true;
      await this.deleteNotification();
    }
    Utils.wait(Numbers.One).then(() => {
      this.notificationWindow.open();
      this.updateNotifications = true;
    });
  }
}
