import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IExpressTransferExtraData } from 'src/app/interface/IExpressTransferExtraData';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
  providedIn: 'root'
})
export class ExpressTransferExtraDataService {
  private readonly expressTransferExtraData: BehaviorSubject<IExpressTransferExtraData> = new BehaviorSubject<IExpressTransferExtraData>({
    tipo: Strings.EMPTY,
    concepto: Strings.EMPTY,
    referencia: Strings.EMPTY,
    otp: Strings.EMPTY
});

  constructor() { }

  get expressTransferExtraDataObservable() {
    return this.expressTransferExtraData.asObservable();
  }

  get expressTransferExtraDataData(): IExpressTransferExtraData {
    return this.expressTransferExtraData.getValue();
  }

  set expressTransferExtraDataData(data: IExpressTransferExtraData) {
    this.expressTransferExtraData.next(data);
  }

  clearData(){
    this.expressTransferExtraDataData = {
      tipo: Strings.EMPTY,
      concepto: Strings.EMPTY,
      referencia: Strings.EMPTY,
      otp: Strings.EMPTY
    };
  }
}
