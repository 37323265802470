import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NotifyClearFormService } from 'src/app/services/notify-clear-form.service';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalLocalServicesComponent } from 'src/app/no-schedulable-services/components/modal-local-services/modal-local-services.component';
import { ModalService } from 'src/app/shared/modal.service';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { CreditsStatusService } from 'src/app/services/credits-status.service';
import { ServicePaymentTypeService } from 'src/app/services/service-payment-type.service';
import { ServiceTransferIdService } from 'src/app/services/service-transfer-id.service';
import { ServiceAccountInterService } from 'src/app/services/service-account-inter.service';
import { ServiceOptionsBankService } from 'src/app/services/service-options-bank.service';
import { InsurancePolicies } from 'src/app/services/insurance-policies-status.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { AccountCardService } from 'src/app/services/account-card.service';
import { NotifyServicePaymentService } from 'src/app/services/notify-service-payment.service';
import { GmmInfoService } from 'src/app/services/gmm-info.service';
import { ServiceCardService } from 'src/app/services/service-card.service';
import { SubscriptionLike, map } from 'rxjs';
import { CreditCardsStatusService } from 'src/app/services/credit-cards-status.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Constants, Length } from 'src/core/constants/Constants';
import { NotifyDateService } from 'src/app/services/notify-date.service';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { Resources } from 'src/core/constants/Resources';
import { PortabilityService } from 'src/app/services/portability.service';
import { FundsInvestmentService } from 'src/app/services/funds-investment.service';
import { CardAddresseeService } from 'src/app/services/card-addressee.service';
import { NotifyRouteService } from 'src/app/services/notify-route.service';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { AppUtils } from 'src/core/utils/AppUtils';
import { AppConstants, AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { AppBridge } from 'src/core/utils/AppBridge';
import { AppNavigation } from 'src/app/models/AppNavigation';
import { ConfigurationApp } from 'src/app/services/servicesApp/configuration-app.service';
import { Utils } from 'src/core/utils/utils';

@Component({
  selector: 'app-header-transfer-card',
  templateUrl: './header-transfer-card.component.html',
  styleUrls: ['./header-transfer-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HeaderTransferCardComponent implements OnInit, OnDestroy {
  static instance: HeaderTransferCardComponent;

  @Input() useIcon: boolean = false;
  @Input() iconSrc: string = Strings.EMPTY;
  @Input() iconAction: () => void;

  @Input() title: string = Strings.EMPTY;
  @Input() subtitle: string = Strings.EMPTY;
  @Input() urlLocation: string = Strings.EMPTY;
  @Input() showOptionalOnMobile: boolean = false;
  @Input() optional: string = Strings.EMPTY;
  @Input() option: string = Strings.EMPTY;
  @Input() paymentInsurance: string | Array<string>;
  @Input() dataAmountInsurance: string | Array<string>;
  @Input() dataInternational?: Array<string> = [];
  @Input() dataBIC?: Array<string>;
  @Output() onActionClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onOptionalAction = new EventEmitter<void>();
  @Input() dataToConfirm: {}[] = new Array<{}>();
  @Input() returnFn?: () => void;
  @Input() showSubtitle?: boolean = false;
  @Input() dataForm: Array<string | boolean> = null;
  idService: string;
  optionsInfoNext: number;
  optionsInfoCurrent: number;
  prepaidAmount: string;
  url: string = Strings.EMPTY;
  chevronLeft: string = Resources.BTN_CHEVRON_LEFT;
  mobileChevronLeft: string = Resources.BLUE_CHEVRON_LEFT;
  isFundsInv: boolean = false;
  isFundsInvestmentSL: SubscriptionLike;
  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints }))
  );

  constructor(
    private readonly router: Router,
    private readonly notifyClearFormService: NotifyClearFormService,
    private readonly modalService: ModalService,
    private readonly stateServicePayIdService: StateServicePayIdService,
    private readonly stateCreditsService: CreditsStatusService,
    private readonly servicePaymentTypeService: ServicePaymentTypeService,
    private readonly transferIdService: ServiceTransferIdService,
    private readonly accountInterService: ServiceAccountInterService,
    private readonly optionBankService: ServiceOptionsBankService,
    private readonly insurancePolicies: InsurancePolicies,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly accountCardService: AccountCardService,
    private readonly notifyServicePayment: NotifyServicePaymentService,
    private readonly gmmInfoService: GmmInfoService,
    private readonly notifyPortabilityService: PortabilityService,
    private readonly responsiveService: ResponsiveService,
    private readonly serviceCards: ServiceCardService,
    private readonly notifyDateService: NotifyDateService,
    private readonly creditCardsStatusService: CreditCardsStatusService,
    private readonly fundsInvestmentService: FundsInvestmentService,
    private readonly configurationApp: ConfigurationApp,
    public readonly cardAddresseeService: CardAddresseeService,
    readonly notifyRouteService: NotifyRouteService) {
    this.isFundsInvestmentSL = this.fundsInvestmentService.isFundsInvestment$.subscribe(
      (data) => {
        this.isFundsInv = data.value;
      }
    );
  }
  ngOnDestroy() {
    this.isFundsInvestmentSL?.unsubscribe();
  }

  ngOnInit(): void {
    this.url = this.router.url;
    HeaderTransferCardComponent.instance = this;
  }

  canBack(): boolean {
    if (AppUtils.platform.isApp && AppUtils.platform.isXamarin && AppConstants.NAVIGATION_APP.includes(this.url)) {
      return false;
    }
    return true;
  }
  get configApp() {
    return this.configurationApp.configurationApp;
  }

  goBack() {
    if (this.configApp.isExternal && AppConstants.NAVIGATION_APP.includes(this.url) && Utils.gfiToRoute(this.configApp?.target)===Utils.gfiToRoute(this.url)) {
      this.backApp();
      return true;
    } else if (this.validateApp()) {
      return true;
    } else if (!this.canBack()) {
      return false;
    } else if (this.returnFn) {
      this.returnFn?.();
      return true;
    } else if (this.onActionClick.observed) {
      this.onActionClick.emit();
      return true;
    } else {
      this.backOtersRouters();
      return true;
    }
  }

  backOtersRouters() {
    this.validateDataConfirm();
    this.idPortability();
    this.validateServices();
    if (this.urlLocation === PATH.ConfirmPolicyPayment) {
      this.router.navigate([this.urlLocation],
        {
          state: {
            dataSelect: this.paymentInsurance,
            dataAmount: this.dataAmountInsurance,
            id: Constants.SERVICE_ID.InsurancePayment
          }
        });
    }
    this.internationalTransferValidations();
    if (!this.dataInternational) {
      this.router.navigate([this.urlLocation]);
    }
    this.validateURL();
    if (this.dataBIC && this.urlLocation === PATH.NewAddressee) {
      this.router.navigate([this.urlLocation],
        {
          state: {
            dataBIC: this.dataBIC,
            dataInternational: this.dataInternational
          }
        });
    }
    if (this.serviceCards.selectedService?.name) {
      this.router.navigate([this.urlLocation], { state: { idOperation: Constants.OPERATION_ID } });
    }
    return true;
  }

  backApp() {
    const navigationParams: AppNavigation = {
      Data: (this.configApp?.goBack) ? this.configApp?.goBack : AppScreen.Login
    };
    AppBridge.invoke<string>(AppMethod.SendNativeScreen, navigationParams);
    return;
  }

  validateApp(): boolean {
    if (this.modalService.modalInstance && AppUtils.platform.isApp) {
      if (this.modalService.modalInstance.componentInstance?.onClose) {
        this.modalService.modalInstance.componentInstance.onClose();
      } else {
        this.modalService.close();
        this.modalService.modalInstance = null;
      }
      return true;
    }

    return false;
  }

  validateDataConfirm() {
    if (this.dataToConfirm) {
      this.router.navigate([this.urlLocation], { state: { dataConfirm: this.dataToConfirm } });
    } else {
      this.router.navigate([this.urlLocation]);
    }
  }

  validateURL() {
    const url = this.router.url;
    if (url !== PATH.Home) {
      this.creditCardsStatusService.status = { value: false };
    }

    if (url === PATH.ConfirmInvestment) {
      this.notifyServicePayment.typeOperation = Constants.INVESTMENT;
    }

    if ((url === PATH.ServicesConfirmPayment) && this.idService !== Constants.SERVICE_CODE.Psegnal
      && this.idService !== Constants.SERVICE_CODE.SuaPayment) {
      this.accountCardService.clearCardAccount();
    }
    if (url === PATH.ConfirmTransferCTPatrimonial) {
      this.notifyClearFormService.clearFormData = { value: false };
      this.router.navigate([PATH.TransferCTPatrimonial]);
    }
    this.clearInformation(url);

    if (this.idService !== Constants.SERVICE_CODE.MexicoState) {
      this.notifyDateService.dateStructData = undefined;
    }
    if (this.isFundsInv) {
      this.notifyAmountService.amount = { amount: Strings.EMPTY };
      this.fundsInvestmentService.clearSelected();
    }

    this.dataFormFunction();
    return true;
  }
  dataFormFunction() {
    if (this.dataForm) {
      if (this.urlLocation === PATH.NewAddressee) {
        this.dataForm[Constants.DATA_AGENDA_NATIONAL.ShowConfirmAgenda] = true;
      }
      this.dataForm[Constants.DATA_AGENDA_NATIONAL.ShowToken] = true;
      this.router.navigate([this.urlLocation],
        {
          state: {
            dataForm: this.dataForm
          }
        });
    }
  }

  clearInformation(url: string) {
    if (url === PATH.CreditPayment || url === PATH.PaymentPolicies) {
      this.router.navigate([PATH.Home]);
      this.stateServicePayIdService.clearService();
      this.accountCardService.clearCardAccount();
      this.insurancePolicies.clearSelectedPolicies();
      this.notifyAmountService.amount = { amount: Strings.EMPTY };
    }
  }
  idPortability() {
    this.notifyPortabilityService.portability = null;
  }

  internationalTransferValidations() {
    if (this.router.url === PATH.ConfirmInternationalTransfer) {
      this.urlLocation = PATH.InternationalTransfers;
      this.accountInterService.accountInterData = {
        type: Strings.EMPTY,
        taxIdentifier: Strings.EMPTY,
        transfer: Strings.EMPTY,
        isErrorType: false,
        isErrorTaxIdentifier: false,
        isErrorTransfer: false
      };
      this.optionBankService.optionBankServiceData = {
        infoBank: Strings.EMPTY,
        purposePayment: Strings.EMPTY,
        isValid: false
      };
    }

    if (this.router.url === PATH.InternationalTransfers) {
      this.transferIdService.stateTransferIdData = {
        id: Strings.EMPTY
      };
    }

    if (this.dataInternational && this.dataInternational.length > Length.Empty) {
      this.router.navigate([this.urlLocation],
        {
          state: {
            next: PATH.CompleteInternationalAgendaRegistration,
            dataInternational: this.dataInternational
          }
        });
    }

    if (this.serviceCards.selectedService?.name) {
      this.router.navigate([this.urlLocation], { state: { idOperation: Constants.OPERATION_ID } });
    }
  }

  validateServices() {
    this.stateServicePayIdService.service$.subscribe(data => {
      this.idService = data.id;
      if (this.idService === Constants.SERVICE_CODE.CreditPayment) {
        if (this.router.url === PATH.SecurityCode) {
          this.router.navigate([PATH.ConfirmCreditPayment]);
        } else {
          this.notifyClearFormService.clearFormData = { value: false };
          this.stateCreditsService.status = { value: false };
          this.optionsInfoNext = this.servicePaymentTypeService.paymentTypeService.options.nextPayment;
          this.optionsInfoCurrent = this.servicePaymentTypeService.paymentTypeService.options.currentBalance;
          this.prepaidAmount = this.servicePaymentTypeService.paymentTypeService.options.prepaidCreditAmount;
          this.servicePaymentTypeService.paymentTypeService = {
            type: this.servicePaymentTypeService.paymentTypeService.type,
            options: {
              nextPayment: this.optionsInfoNext,
              currentBalance: this.optionsInfoCurrent,
              prepaidCreditAmount: this.prepaidAmount
            },
            excessAmount: this.servicePaymentTypeService.paymentTypeService.excessAmount,
            isError: false
          };
          this.router.navigate([PATH.CreditPayment]);
        }
      } else if (this.idService === Constants.SERVICE_CODE.Paggm) {
        if (this.router.url === PATH.Services) {
          this.notifyClearFormService.clearFormData = { value: true };
          this.accountCardService.clearCardAccount();
          this.gmmInfoService.clearGmmInfoService();
        } else {
          this.notifyClearFormService.clearFormData = { value: false };
        }
      }
    });
  }

  executeAction() {
    if (this.onOptionalAction.observed) {
      this.onOptionalAction.emit();
    }
    else {
      const modalOptions: ModalOptions = {
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeLg,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.LocalTaxes
      };

      this.modalService.open(ModalLocalServicesComponent, modalOptions);
    }
  }
}
