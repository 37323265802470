<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="vm.breakpoints.mobile">
    <div >
      <img class="cursor-pointer img" [src]="mobileChevronLeft | cdnImage" alt="<" height="24" width="24"
        (click)="goBack()" (keydown.enter)="$event.preventDefault()" (keypress.space)="$event.preventDefault()">
    </div>
    <div class="pb-0 pt-2" [class.with-icon]="useIcon">
      <label for="title" class="default-color"
        [ngClass]="isFundsInv ? 'header-m p-mobile-tittle-fi':'header-l'"
        [ngClass]="optional ? 'header-m' : 'header-l'">{{title}}</label>
        <ng-container *ngTemplateOutlet="Icon"></ng-container>
    </div>
    <div class="pt-1 pb-1" *ngIf="showSubtitle">
      <label for="" class="subtitle-transfer-card" [ngClass]="!vm.breakpoints.mobile ? 'body-m' : 'body-s'">
        {{subtitle}}
      </label>
  </div>
  <ng-container *ngIf="showOptionalOnMobile">
    <ng-container *ngTemplateOutlet="OptionalLayout"></ng-container>
  </ng-container>
  </ng-container>
  <ng-container *ngIf="!vm.breakpoints.mobile">
    <div id="go-back">
      <button class="transfer-card-btn" (keydown.enter)="$event.preventDefault()" (click)="goBack()"
        (keypress.enter)="$event.preventDefault()" (keypress.space)="$event.preventDefault()" >
        <img [src]="chevronLeft | cdnImage" alt="<" height="24" width="24">
        <label for="return" class="shared-label-header button-s me-2">
          Regresar
        </label>
      </button>
    </div>
    <div class="pt-3 pb-0" [class.with-icon]="useIcon">
      <label for="title" class="default-color header-l">{{title}}</label>
      <ng-container *ngTemplateOutlet="Icon"></ng-container>
    </div>
    <div [class.pt-1]="vm.breakpoints.mobile" class="pb-1">
      <label for="" class="subtitle-transfer-card body-s">
        {{subtitle}}
      </label>
    </div>
    <ng-container *ngTemplateOutlet="OptionalLayout"></ng-container>
    <ng-container *ngIf="option !== ''">
      <div class="row pt-1">
        <label class=" header-xs">{{option}}</label>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #OptionalLayout>
    <ng-container *ngIf="showOptionalOnMobile && optional !== ''">
      <div class="row">
        <span class="detail-m">
          <a class="overflow-wrap-anywhere detail-m-highlight-hypertext cursor-pointer style-label-execute"
          (keydown.enter)="$event.preventDefault()" (click)="executeAction()">{{optional}}</a>
        </span>
      </div>
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #Icon>
  <img *ngIf="useIcon" (keydown.enter)="$event.preventDefault()" (click)="iconAction()" class="cursor-pointer"
  (keypress.enter)="$event.preventDefault()" (keypress.space)="$event.preventDefault()"
    [src]="iconSrc | cdnImage" alt="Ayuda" width="24"
    height="24" (keydown.enter)="$event.preventDefault()">
</ng-template>
