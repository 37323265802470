import { Constants, Numbers } from 'src/core/constants/Constants';

export class TransferConstants {

  static readonly SPEI_TRANSACTION_TYPE = 'REALIZADA';
  static readonly TRANSFER_APPLICATION_TIME = {
    Spei: '0',
    Tef: '24'
  };
  static readonly MIN_INITIAL_DATE_SCHEDULE_TRANSFER = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + Constants.MONTH.ONE,
    day: new Date().getDate() + Numbers.One
  };
  static readonly MAX_INITIAL_DATE_SCHEDULE_TRANSFER = {
    year: new Date().getFullYear() + Numbers.Ten,
    month: new Date().getMonth()  + Constants.MONTH.ONE,
    day: new Date().getDate()
  };
  static readonly MIN_FINAL_DATE_SCHEDULE_TRANSFER = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + Constants.MONTH.ONE,
    day: new Date().getDate() + Numbers.Two
  };
  static readonly MAX_FINAL_DATE_SCHEDULE_TRANSFER = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + Constants.MONTH.THREE,
    day: new Date().getDate() + 1
  };
  static readonly FORM_CONTROLS = {
    IdCalendarOne: 'datepickerone',
    IdCalendarTwo: 'datepickertwo',
    imgFinalDateFn:'imgFinalDateFn'
  };
  static readonly CARD_HEIGHT = 130;
  static readonly TWENTY_FOUR = 24;
  static readonly MODAL_CONTENT = 'modal-content';
  static readonly MODIFY_ACCOUNT = 'modify-account';
  static readonly INVALID_TRANSCRIPT= `${'Error al realizar la transferencia internacional:'}
  ${'Falta información requerida para la autoridad favor de acudir a su centro de Atención a Clientes más cercano, Característica declaratoria.'}`;
  static readonly INVALID_TRANSCRIPT_CODE = 777015;

  static ACCOUNT_TYPE = {
    0: 'Otros bancos',
    1: 'Cuentas propias',
    2: 'Terceros inbursa',
    3: 'Internacionales'
  };

  static readonly MAXIMUM_LENGTH_ACCOUNT_INTERNATIONAL = 22;
  static readonly MAXIMUM_FORM_LENGTH_CONCEPT = 50;
  static readonly MAXIMUM_LENGTH_FORM_REFERENCE = 9;
  static readonly MAX_LENGTH_INTERNATIONAL_PURPOSE = 120;
  static readonly MINIMUN_AMOUNT_BROKERAGE_HOUSE = 1000;
}

export enum TransferStatus {
  PENDING = 'Pendiente de aplicar'
}

export enum Browser {
  Safari = 'Safari',
  Chrome = 'Chrome'
}

export enum Time {
  TimeStamp = 250
}

export enum KeyAgendaType {
  OthersBanks = 'otherBanks',
  OwnAccounts = 'ownAccounts',
  ThirdParty = 'thirdParty',
  Internationals = 'internationals'
}
