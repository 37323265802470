import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from 'src/app/shared/loader.interceptor';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { ContentWrapperComponent } from 'src/app/shared/components/content-wrapper/content-wrapper.component';
import { ModalLogoutComponent } from 'src/app/shared/components/modal-logout/modal-logout.component';
import { QRCodeModule } from 'angularx-qrcode';
import { InfoCardComponent } from 'src/app/shared/components/info-card/info-card.component';
import { RouterModule } from '@angular/router';
import { FeeInfoComponent } from 'src/app/shared/components/fee-info/fee-info.component';
import { InfoLabelComponent } from 'src/app/shared/components/info-label/info-label.component';
import { PaginationComponent } from 'src/app/shared/components/pagination/pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAccordionModule, NgbDateParserFormatter, NgbDropdownModule, NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { EnterAmountComponent } from 'src/app/shared/components/enter-amount/enter-amount.component';
import { EnterDateComponent } from 'src/app/shared/components/enter-date/enter-date.component';
import { SelectAccountComponent } from 'src/app/shared/components/select-account/select-account.component';
import { CardAccountComponent } from 'src/app/shared/components/card-account/card-account.component';
import { CardModalComponent } from 'src/app/shared/components/card-modal/card-modal.component';
import { CardAddresseeComponent } from 'src/app/shared/components/card-addressee/card-addressee.component';
import { CardModalAddresseeComponent } from 'src/app/shared/components/card-modal-addressee/card-modal-addressee.component';
import { SharedButtonsComponent } from 'src/app/shared/components/shared-buttons/shared-buttons.component';
import { PaymentNumberComponent } from 'src/app/shared/components/payment-number/payment-number.component';
import { PaymentReferenceComponent } from 'src/app/shared/components/payment-reference/payment-reference.component';
import { PaymentTypeComponent } from 'src/app/shared/components/payment-type/payment-type.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AccountInterComponent } from 'src/app/shared/components/account-inter/account-inter.component';
import { OptionBankComponent } from 'src/app/shared/components/option-bank/option-bank.component';
import { TabsComponent } from 'src/app/shared/components/tabs/tabs.component';
import { TabComponent } from 'src/app/shared/components/tab/tab.component';
import { ShowAmountComponent } from 'src/app/shared/components/show-amount/show-amount.component';
import { ModalAlertsComponent } from 'src/app/shared/components/modal-alerts/modal-alerts.component';
import { CardComponent } from 'src/app/shared/components/card/card.component';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import { SelectedCardComponent } from 'src/app/shared/components/selected-card/selected-card.component';
import { CardFrameComponent } from 'src/app/shared/components/card-frame/card-frame.component';
import { InputTemplateComponent } from 'src/app/shared/components/input-template/input-template.component';
import { ToastComponent } from 'src/app/shared/components/toast/toast.component';
import { CrossButtonModalComponent } from 'src/app/shared/components/cross-button-modal/cross-button-modal.component';
import { SelectFilterComponent } from 'src/app/shared/components/select-filter/select-filter.component';
import { PaginationSearchComponent } from 'src/app/shared/components/pagination-search/pagination-search.component';
import { PromotionCardComponent } from 'src/app/shared/components/promotion-card/promotion-card.component';
import { RewardCardComponent } from 'src/app/shared/components/reward-card/reward-card.component';
import { NgbDateCustomParserFormatter } from 'src/app/shared/customDateFormat.service';
import { SearchComponentComponent } from 'src/app/shared/components/search-component/search-component.component';
import { HeaderTransferCardComponent } from 'src/app/shared/components/header-transfer-card/header-transfer-card.component';
import { HeaderProductsComponent } from 'src/app/shared/components/header-products/header-products.component';
import { AutofocusDirective } from 'src/app/directives/autofocus.directive';
import { FocusErrorDirective } from 'src/app/directives/focusError.directive';
import { OnlyLettersAccentDirective } from 'src/app/directives/only-letters-accent.directive';
import { OnlyLettersCharactersDirective } from 'src/app/directives/only-letters-characters.directive';
import { CleanInputDirective } from 'src/app/directives/clean-input.directive';
import { ModalLeavePageComponent } from 'src/app/shared/components/modal-leave-page/modal-leave-page.component';
import { OnlyNumbersDirective } from 'src/app/directives/only-numbers.directive';
import { NumericDirective } from 'src/app/directives/only-numeric.directive';
import { ScrollToDirective } from 'src/app/directives/scroll-to.directive';
import { ValidateInputRegexDirective } from 'src/app/directives/validate-input-regex.directive';
import { CdnImagePipe } from 'src/app/pipes/cdn-image.pipe';
import { DatePipes } from 'src/app/pipes/date.pipe';
import { EllipsisTextPipe } from 'src/app/pipes/ellipsis-text.pipe';
import { TemplateSecondaryButtonComponent } from 'src/app/shared/components/template-secondary-button/template-secondary-button.component';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { SplitDigits } from 'src/app/pipes/SplitDigits.pipe';
import { MaskAccountPipe } from 'src/app/pipes/mask-account.pipe';
import { QuantityFormatPipe } from 'src/app/pipes/quantity-format.pipe';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { ServiceImagePipe } from 'src/app/pipes/service-image.pipe';
import { DownloadAppComponentComponent } from 'src/app/shared/components/download-app-component/download-app-component.component';
import { FooterComponent } from 'src/app/shared/components/footer/footer.component';
import { HeaderCardLoginComponentComponent } from 'src/app/shared/components/header-card-login-component/header-card-login-component.component';
import { CardPoliciesComponent } from 'src/app/shared/components/card-policies/card-policies.component';
import { LocationModalComponent } from 'src/app/shared/components/location-modal/location-modal.component';
import { ModalExceptionsComponent } from 'src/app/shared/components/modal-exceptions/modal-exceptions.component';
import { ModalHelpTokenComponent } from 'src/app/shared/components/modal-help-token/modal-help-token.component';
import { ModalPaymentInsurancePoliciesComponent } from 'src/app/shared/components/modal-payment-insurance-policies/modal-payment-insurance-policies.component';
import { LoyaltyProgramImagePipe } from 'src/app/pipes/loyalty-program-image.pipe';
import { AlertComponent } from 'src/app/shared/components/alert/alert.component';
import { ShowInputValueComponent } from 'src/app/shared/components/show-input-value/show-input-value.component';
import { ModalModifyLimitComponent } from 'src/app/shared/components/modal-modify-limit/modal-modify-limit.component';
import { MayusDirective } from 'src/app/directives/mayus.directive';
import { CardMaskDirective } from 'src/app/directives/card-mask.directive';
import { BannerComponent } from 'src/app/shared/components/banner/banner.component';
import { SectionContainerComponent } from 'src/app/shared/components/section-container/section-container.component';
import { SelectAddresseeComponent } from 'src/app/shared/components/select-addressee/select-addressee.component';
import { SeriesTokenComponent } from 'src/app/component/series-token/series-token.component';
import { FormConfirmAddresseeComponent } from 'src/app/new-addressee/components/form-confirm-addressee/form-confirm-addressee.component';
import { NumberFormatPipe } from 'src/app/number-format.pipe';
import { ModalCloseComponent } from 'src/app/shared/components/modal-close/modal-close.component';
import { TextEllipsisComponent } from 'src/app/shared/components/text-ellipsis/text-ellipsis.component';
import { FormInvestmentComponent } from 'src/app/shared/components/form-investment/form-investment.component';
import { TemplatePrimaryButtonComponent } from 'src/app/shared/components/template-primary-button/template-primary-button.component';
import { MainConfirmDataComponent } from 'src/app/component/main-confirm-data/main-confirm-data.component';
import { ErrorExceptionComponent } from 'src/app/component/error-exception/error-exception.component';
import { ModalCreditBureauInquiryComponent } from 'src/app/component/modal-creditbereau-inquiry/modal-credit-bureau-inquiry.component';
import { FormCodeSecuritySerieComponent } from 'src/app/component/form-code-security-serie/form-code-security-serie.component';
import { ModalMapComponent } from 'src/app/shared/components/modal-map/modal-map.component';
import { CustomSelectComponent } from 'src/app/shared/components/custom-select/custom-select.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SelectAmountComponent } from 'src/app/shared/components/select-amount/select-amount.component';
import { ConfirmServicePaymentScheduleComponent } from 'src/app/schedulable-services/components/confirm-service-payment-schedule/confirm-service-payment-schedule.component';
import { SimpleCardAddresseeComponent } from 'src/app/transfers/components/simple-card-addressee/simple-card-addressee.component';
import { TransferCompletedComponent } from 'src/app/transfers/components/transfer-completed/transfer-completed.component';
import { FormCodeSecurityComponent } from 'src/app/component/form-code-security/form-code-security.component';
import { MainConfirmPaymentComponent } from 'src/app/shared/components/main-confirm-payment/main-confirm-payment.component';
import { ResetInputValueComponent } from 'src/app/shared/components/reset-input-value/reset-input-value.component';
import { FilterComponentComponent } from 'src/app/shared/components/filter-component/filter-component.component';
import { NotificationsComponent } from 'src/app/component/notifications/notifications.component';
import { ItemNotificationComponent } from 'src/app/component/item-notification/item-notification.component';
import { HeaderComponent } from 'src/app/component/header/header.component';
import { NavbarHeaderComponentComponent } from 'src/app/component/navbar-header-component/navbar-header-component.component';
import { OtpCardReplacementComponent } from 'src/app/shared/components/otp-card-replacement/otp-card-replacement.component';
import { BasicGridTemplateComponent } from 'src/app/shared/components/basic-grid-template/basic-grid-template.component';
import { CardBranchesComponent } from 'src/app/shared/components/card-branches/card-branches.component';
import { MapComponent } from 'src/app/component/map/map.component';
import { CompositionedInputDirective } from 'src/app/directives/compositioned-input.directive';
import { ModalTermsAndConditionsComponent } from 'src/app/shared/components/modal-terms-and-conditions/modal-terms-and-conditions.component';
import { CardDirectDebitQueryComponent } from 'src/app/shared/components/card-direct-debit-query/card-direct-debit-query.component';
import { ModalFormCodeSecurityComponent } from 'src/app/shared/components/modal-form-code-security/modal-form-code-security.component';
import { ModalEndProcessAlertComponent } from 'src/app/shared/components/modal-end-process-alert/modal-end-process-alert.component';
import { ModalRemoveCreditComponent } from 'src/app/shared/components/modal-remove-credit/modal-remove-credit.component';
import { UpperCaseDirective } from 'src/app/directives/uppercase.directive';
import { DropdownMaxHeightDirective } from 'src/app/directives/dropdown-max-height.directive';
import { DoubleDateComponent } from 'src/app/shared/components/double-date/double-date.component';
import { DateComponent } from 'src/app/shared/components/date/date.component';
import { ScheduleTransferDataComponent } from 'src/app/transfers/components/schedule-transfer-data/schedule-transfer-data.component';
import { ModalManageServicesComponent } from 'src/app/shared/components/modal-manage-services/modal-manage-services.component';
import { NoEmojiDirective } from 'src/app/directives/no-emoji.directive';
import { ModalLeavePageAppComponent } from 'src/app/shared/components/modal-leave-page-app/modal-leave-page-app.component';
import { ToggleAccountBalanceComponent } from 'src/app/component/toggle-account-balance/toggle-account-balance.component';
@NgModule({
  declarations: [
    LoaderComponent,
    ContentWrapperComponent,
    ModalLogoutComponent,
    InfoCardComponent,
    FeeInfoComponent,
    InfoLabelComponent,
    PaginationComponent,
    EnterAmountComponent,
    EnterDateComponent,
    SelectAccountComponent,
    CardAccountComponent,
    CardModalComponent,
    CardAddresseeComponent,
    CardModalAddresseeComponent,
    SharedButtonsComponent,
    MainConfirmDataComponent,
    PaymentNumberComponent,
    PaymentReferenceComponent,
    PaymentTypeComponent,
    ErrorExceptionComponent,
    AccountInterComponent,
    OptionBankComponent,
    TabsComponent,
    TabComponent,
    ShowAmountComponent,
    ModalCreditBureauInquiryComponent,
    CardComponent,
    ButtonComponent,
    SelectedCardComponent,
    ModalAlertsComponent,
    CardFrameComponent,
    InputTemplateComponent,
    ToastComponent,
    PaginationSearchComponent,
    PromotionCardComponent,
    RewardCardComponent,
    CrossButtonModalComponent,
    SelectFilterComponent,
    SearchComponentComponent,
    HeaderTransferCardComponent,
    HeaderProductsComponent,
    HeaderCardLoginComponentComponent,
    DownloadAppComponentComponent,
    FooterComponent,
    ModalExceptionsComponent,
    ModalPaymentInsurancePoliciesComponent,
    CardPoliciesComponent,
    LocationModalComponent,
    ModalHelpTokenComponent,
    ModalLeavePageComponent,
    ModalLeavePageAppComponent,
    AlertComponent,
    MainConfirmPaymentComponent,
    ModalModifyLimitComponent,
    SelectAddresseeComponent,
    SeriesTokenComponent,
    FormCodeSecuritySerieComponent,
    FormConfirmAddresseeComponent,
    ScrollToDirective,
    FocusErrorDirective,
    NumericDirective,
    ValidateInputRegexDirective,
    MayusDirective,
    CleanInputDirective,
    AutofocusDirective,
    OnlyLettersAccentDirective,
    OnlyNumbersDirective,
    OnlyLettersCharactersDirective,
    FilterPipe,
    DatePipes,
    CdnImagePipe,
    ServiceImagePipe,
    SafePipe,
    MaskAccountPipe,
    QuantityFormatPipe,
    LoyaltyProgramImagePipe,
    EllipsisTextPipe,
    ShowInputValueComponent,
    CardMaskDirective,
    ResetInputValueComponent,
    FilterComponentComponent,
    NumberFormatPipe,
    ModalCloseComponent,
    TextEllipsisComponent,
    BannerComponent,
    SectionContainerComponent,
    SplitDigits,
    TemplatePrimaryButtonComponent,
    TemplateSecondaryButtonComponent,
    FormInvestmentComponent,
    TemplatePrimaryButtonComponent,
    TemplateSecondaryButtonComponent,
    ModalMapComponent,
    CustomSelectComponent,
    BasicGridTemplateComponent,
    SelectAmountComponent,
    ConfirmServicePaymentScheduleComponent,
    TransferCompletedComponent,
    SimpleCardAddresseeComponent,
    FormCodeSecurityComponent,
    HeaderComponent,
    NavbarHeaderComponentComponent,
    NotificationsComponent,
    ItemNotificationComponent,
    OtpCardReplacementComponent,
    CardBranchesComponent,
    MapComponent,
    CompositionedInputDirective,
    ModalTermsAndConditionsComponent,
    CardDirectDebitQueryComponent,
    ModalFormCodeSecurityComponent,
    ModalEndProcessAlertComponent,
    ModalRemoveCreditComponent,
    UpperCaseDirective,
    UpperCaseDirective,
    DropdownMaxHeightDirective,
    DoubleDateComponent,
    DateComponent,
    ScheduleTransferDataComponent,
    ModalManageServicesComponent,
    NoEmojiDirective,
    ToggleAccountBalanceComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ClipboardModule,
    NgbDropdownModule,
    NgbAccordionModule,
    ImageCropperModule,
    GoogleMapsModule,
    NgbToastModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor, multi: true
    },
    {
      provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter
    }
  ],
  exports: [
    CommonModule,
    LoaderComponent,
    ContentWrapperComponent,
    QRCodeModule,
    InfoCardComponent,
    FeeInfoComponent,
    InfoLabelComponent,
    PaginationComponent,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ClipboardModule,
    EnterAmountComponent,
    EnterDateComponent,
    SelectAccountComponent,
    CardAccountComponent,
    CardModalComponent,
    CardAddresseeComponent,
    CardModalAddresseeComponent,
    SharedButtonsComponent,
    MainConfirmDataComponent,
    PaymentNumberComponent,
    PaymentReferenceComponent,
    PaymentTypeComponent,
    ErrorExceptionComponent,
    ImageCropperModule,
    AccountInterComponent,
    OptionBankComponent,
    TabsComponent,
    TabComponent,
    ShowAmountComponent,
    ModalCreditBureauInquiryComponent,
    CardComponent,
    ButtonComponent,
    SelectedCardComponent,
    ModalAlertsComponent,
    CardFrameComponent,
    InputTemplateComponent,
    CrossButtonModalComponent,
    NgbToastModule,
    ToastComponent,
    PaginationSearchComponent,
    PromotionCardComponent,
    RewardCardComponent,
    SelectFilterComponent,
    SearchComponentComponent,
    HeaderTransferCardComponent,
    HeaderProductsComponent,
    HeaderCardLoginComponentComponent,
    DownloadAppComponentComponent,
    FooterComponent,
    ModalExceptionsComponent,
    ModalPaymentInsurancePoliciesComponent,
    CardPoliciesComponent,
    LocationModalComponent,
    ModalHelpTokenComponent,
    ModalLeavePageComponent,
    ModalLeavePageAppComponent,
    AlertComponent,
    MainConfirmPaymentComponent,
    ModalModifyLimitComponent,
    SelectAddresseeComponent,
    SeriesTokenComponent,
    FormCodeSecuritySerieComponent,
    FormConfirmAddresseeComponent,
    ScrollToDirective,
    FocusErrorDirective,
    NumericDirective,
    ValidateInputRegexDirective,
    MayusDirective,
    CleanInputDirective,
    AutofocusDirective,
    OnlyLettersAccentDirective,
    OnlyNumbersDirective,
    OnlyLettersCharactersDirective,
    FilterPipe,
    DatePipes,
    CdnImagePipe,
    ServiceImagePipe,
    SafePipe,
    MaskAccountPipe,
    QuantityFormatPipe,
    LoyaltyProgramImagePipe,
    EllipsisTextPipe,
    ShowInputValueComponent,
    CardMaskDirective,
    ResetInputValueComponent,
    FilterComponentComponent,
    NumberFormatPipe,
    ModalCloseComponent,
    TextEllipsisComponent,
    BannerComponent,
    SectionContainerComponent,
    SplitDigits,
    TemplatePrimaryButtonComponent,
    TemplateSecondaryButtonComponent,
    FormInvestmentComponent,
    TemplatePrimaryButtonComponent,
    TemplateSecondaryButtonComponent,
    ModalMapComponent,
    CustomSelectComponent,
    GoogleMapsModule,
    BasicGridTemplateComponent,
    SelectAmountComponent,
    ConfirmServicePaymentScheduleComponent,
    TransferCompletedComponent,
    SimpleCardAddresseeComponent,
    FormCodeSecurityComponent,
    HeaderComponent,
    OtpCardReplacementComponent,
    CardBranchesComponent,
    MapComponent,
    CompositionedInputDirective,
    ModalTermsAndConditionsComponent,
    CardDirectDebitQueryComponent,
    ModalFormCodeSecurityComponent,
    ModalEndProcessAlertComponent,
    UpperCaseDirective,
    DropdownMaxHeightDirective,
    DoubleDateComponent,
    DateComponent,
    NoEmojiDirective
  ]
})
export class SharedModule { }
