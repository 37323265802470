<ng-container *ngIf="vm$ | async as vm">
  <div class="d-flex align-items-center">
    <div class="d-flex algin-items-center options">
      <ng-container *ngIf="beta">
        <div class="position-relative" [ngClass]="{'me-2':vm.breakpoints.mobile}">
          <img [src]="shoppingTagIcon | cdnImage" alt="Compras" role="button" width="auto" height="auto" />
          <div class="badge-header">
            <span class="detail-s">
              {{ vm.products }}
            </span>
          </div>
        </div>
      </ng-container>
      <div>
        <app-notifications></app-notifications>
      </div>
      <app-toggle-account-balance *ngIf="!visibilityAccountBalance"></app-toggle-account-balance>
      <div class="d-flex align-items-center me-2" *ngIf="vm.breakpoints.mobile" (click)="closeSidebar()"
      (keydown.enter)="closeSidebar()">
        <div ngbDropdown>
          <img *ngIf="$profile | async as user" [src]="user.image" class="user-image cursor-pointer" alt="user"
            width="auto" height="auto" ngbDropdownToggle id="dropdownMenu" />
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end dropdown-menu align-items-center py-0"
            aria-labelledby="dropdownMenu" [class.mt-0]="vm.breakpoints.mobile">
            <div class="div-user-id py-2 ps-3 pe-2">
              <span class="text-client-inb" id="client-inbursa">Cliente Inbursa <br>
                <span class="text-id-client">{{userId}}</span>
              </span>
            </div>
            <div ngbDropdownItem id="userMenuItem1" class="py-2 ps-3 pe-2 cursor-pointer color-hover"
              (click)="goGeneralInformation()" (keydown.enter)="goGeneralInformation()">
              <span class="detail-m-highlight">Información general y<br /> dispositivos</span>
            </div>
            <div ngbDropdownItem id="userMenuItem2" class="p-2 cursor-pointer color-hover" *ngIf="beta"
              (click)="redirectToAccessHistory()" (keydown.enter)="redirectToAccessHistory()">
              <img [src]="historyIcon | cdnImage" alt="Icon" class="mx-2" width="auto" height="auto" />
              <span class="detail-m-highlight">Historial de Acceso</span>
            </div>
            <div ngbDropdownItem id="userMenuItem3" class="p-2 cursor-pointer color-hover"
            (click)="goBeneficiary()" (keydown.enter)="goBeneficiary()">
              <img [src]="userBlueIcon | cdnImage" alt="Icon" class="mx-2" width="auto" height="auto" />
              <span class="detail-m-highlight">Beneficiarios </span>
            </div>
            <div ngbDropdownItem id="userMenuItem4" class="p-2 cursor-pointer color-hover"
              (click)="goFrequentQuestions()" (keydown.enter)="goFrequentQuestions()">
              <img [src]="chatDotsIcon | cdnImage" alt="Icon" class="mx-2" width="auto" height="auto" />
              <span class="detail-m-highlight">Preguntas frecuentes</span>
            </div>
            <div ngbDropdownItem id="userMenuItem5" class="p-2 cursor-pointer color-hover"
              (click)="goAdditional()" (keydown.enter)="goAdditional()">
              <img [src]="plusBlueIcon | cdnImage" alt="Icon" class="mx-2" width="auto" height="auto" />
              <span class="detail-m-highlight">Administrar adicionales</span>
            </div>
            <div ngbDropdownItem id="userMenuItem6" class="p-2 cursor-pointer color-hover"
            (click)="closeSession()" (keydown.enter)="closeSession()">
              <img [src]="logoutIcon | cdnImage" alt="Icon" class="mx-2" width="auto" height="auto" />
              <span class="detail-m-highlight">Cerrar sesión</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center gap-3 ps-4 pe-3" *ngIf="!vm.breakpoints.mobile">

      <div>
        <a><img *ngIf="$profile | async as user" [src]="user.image" class="user-image" alt="user" width="auto"
            height="auto" /></a>
      </div>

      <ng-container *ngIf="!vm.breakpoints.mobile">
        <span class="detail-m user-name">{{ vm.user?.name }}</span>
        <div ngbDropdown class="cursor-pointer">
          <a id="dropdownMenu" ngbDropdownToggle>
            <img [src]="downIcon | cdnImage" width="auto" height="auto" alt="" />
          </a>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end dropdown-menu align-items-center py-0"
            aria-labelledby="dropdownMenu">
            <div ngbDropdownItem id="userMenuItem" class="py-2 ps-3 pe-2 cursor-pointer"
              (click)="goGeneralInformation()" (keydown.enter)="goGeneralInformation()">
              <span class="detail-m-highlight">Información general<br /> y dispositivos</span>
            </div>
            <div ngbDropdownItem id="userMenuItem" class="p-2 cursor-pointer" *ngIf="!hiddenHistory"
              (click)="redirectToAccessHistory()" (keydown.enter)="redirectToAccessHistory()">
              <img [src]="historyIcon | cdnImage" alt="Icon" class="mx-2" width="auto" height="auto" />
              <span class="detail-m-highlight">Historial de Acceso</span>
            </div>
            <div ngbDropdownItem id="userMenuItem" class="p-2 cursor-pointer"
            (click)="goBeneficiary()" (keydown.enter)="goBeneficiary()">
              <img [src]="userBlueIcon | cdnImage" alt="Icon" class="mx-2" height="auto" width="auto" />
              <span class="detail-m-highlight">Beneficiarios </span>
            </div>
            <div ngbDropdownItem id="userMenuItem" class="p-2 cursor-pointer"
            (click)="goFrequentQuestions()" (keydown.enter)="goFrequentQuestions()">
              <img [src]="chatDotsIcon | cdnImage" alt="Icon" class="mx-2" width="auto" height="auto" />
              <span class="detail-m-highlight">Preguntas frecuentes</span>
            </div>
            <div ngbDropdownItem id="userMenuItem" class="p-2 cursor-pointer" (click)="goAdditional()"
            (keydown.enter)="goAdditional()">
              <img [src]="plusBlueIcon | cdnImage" alt="Icon" class="mx-2" height="auto" width="auto" />
              <span class="detail-m-highlight">Administrar adicionales</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
