<div class="d-flex flex-row-reverse pt-3 pe-3">
  <img
    height="24"
    width="24"
    class="cursor-pointer"
    (click)="close()"
    [src]="closeCircleBlue | cdnImage"
    alt="Close" />
</div>
<ng-container *ngIf="inactivity; else NoInactivity">
  <div
    class="d-flex justify-content-center align-items-start flex-column px-4 pt-4">
    <p class="header-m w-100 text-center">
      Tu sesión está por<br/>finalizar, para continuar<br/>operando haz clic en 'Continuar'
    </p>
  </div>
  <hr class="mx-2 mt-3 mb-4" />
  <div class="d-flex flex-column align-items-center">
    <button class="button-m colored-btn mb-2" (click)="actionSessionUser(false)">{{ btnLabelContinue }}</button>
    <button class="button-m outline-btn mt-1 mb-3" (click)="actionSessionUser(true)">{{ btnLabelGoOut }}</button>
  </div>
</ng-container>

<ng-template #NoInactivity>
  <div
    class="d-flex justify-content-center align-items-start flex-column px-4 pt-4">
    <p class="header-l w-100 text-center">
      Tu sesión con el portal<br />financiero Inbursa<br />
      terminará
    </p>
    <p class="body-m w-100 text-center">¿Deseas continuar?</p> 
  </div>
  <hr class="mx-2 mt-3 mb-4" />
  <div class="d-flex flex-column align-items-center">
    <button class="button-m colored-btn mb-2" (click)="actionSessionUser(true)">{{btnLabelCloseSession}}</button>
    <button class="button-m outline-btn mt-1 mb-3" (click)="actionSessionUser(false)">{{ btnLabelCancel }}</button>  
  </div>
</ng-template>

