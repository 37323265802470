import { Injectable } from '@angular/core';
import { ModalMyCardsComponent } from 'src/app/control-card/components/modal-my-cards/modal-my-cards.component';
import { ModalMyCardsService } from 'src/app/control-card/components/modal-my-cards/service/modal-my-cards.service';
import { ModalOptions } from 'src/app/interface/modal-options';
import { MapService } from 'src/app/services/map.service';
import { ModalMyCardsExtensionThreeService } from 'src/app/services/modal-my-cards-three.extension.service';
import { Constants } from 'src/core/constants/Constants';
import { InputNames } from 'src/core/constants/ControlCardConstants';
import { FormValidations } from 'src/core/constants/FormConstants';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { Numbers } from 'src/core/constants/Numbers';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class ModalMyCardsCvvService extends ModalMyCardsService {

  constructor(
    override readonly componentExtensionThree: ModalMyCardsExtensionThreeService,
    override readonly mapService: MapService) {
    super(componentExtensionThree, mapService);
  }

  async registerCvvService(cvvValue?: string, context?: ModalMyCardsComponent) {
    try {
      context.closeOtpModal.close();
      context.registerCvvResponse = await context.cardsAdminService
        .registerCvvService(cvvValue, context.getDataCardsInfo);

      if (context.registerCvvResponse.Error.No === Constants.MSG_SERVICE_RESPONSE_SUCCESSFUL) {
        const modalOptions: ModalOptions = {
          centered: true,
          size: Constants.MODAL_OPTIONS.SizeLg,
          modalDialogClass: ModalConstants.MODAL_OPTIONS.DynamicCvvAlert
        };
        context.modalService.open(context.modalCVV, modalOptions);
      }
    } catch (error) {
      context.modalService.close();
      context.cardCvv = Strings.EMPTY;
      const errorDetails = Utils.getErrorMsg(error);
      const alertInstance = context.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MSG_POPUP_TITLE, imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      },
        {
          onClose: () => alertInstance.close()
        });
    }
  }

  async dynamicCvvService(context?: ModalMyCardsComponent) {
    try {
      context.hideCvvForm = false;
      context.dynamicCvvResponse = await context.cardsAdminService
        .dynamicCvvService(context.getDataCardsInfo);

      if (context.dynamicCvvResponse.Error.No === Constants.MSG_SERVICE_RESPONSE_SUCCESSFUL) {
        context.selectCvvTab(null, true);
        context.showDcvv = false;
        context.lblDcvv = context.dynamicCvvResponse.DCVV;
      }
    } catch (error) {
      context.modalService.close();
      const errorDetails = Utils.getErrorMsg(error);
      const alertInstance = context.alertService.showPopupAlert(
        {
          header: Strings.MSG_POPUP_TITLE,
          message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
          imgHead: Resources.CLOSE_ICON_RED,
          btnExit: false
        },
        {
          onClose: () => { alertInstance.dismiss(); }
        });
    }
  }

  showicon($event?: Event, nameField?: string, context?: ModalMyCardsComponent) {
    context.focusNip($event, nameField);
    context.cvvCardForm.markAllAsTouched();

    context.helpIcon = context.cvvCardForm.get(InputNames.CvvMyCards).value.length >= Numbers.Zero
      && (context.cvvCardForm.get(InputNames.CvvMyCards)?.hasError(FormValidations.Required)
        || context.cvvCardForm.get(InputNames.CvvMyCards)?.hasError(FormValidations.MinLength))
      ? Resources.QUESTION_MARK_RED
      : Resources.HELP_ICON;
  }

  validateRequired(context?: ModalMyCardsComponent) {
    context.helpIcon = context.cvvCardForm.get(InputNames.CvvMyCards).touched
      && (context.cvvCardForm.get(InputNames.CvvMyCards)?.hasError(FormValidations.Required)
        || context.cvvCardForm.get(InputNames.CvvMyCards)?.hasError(FormValidations.MinLength))
      ? Resources.QUESTION_MARK_RED : Resources.HELP_ICON;
  }

  getRegisterCvv(context?: ModalMyCardsComponent) {
    this.hiddenModal(true);
    context.componentExtensionTwo.getRegisterCvv(context);
  }

  getDynamicCvvService(context?: ModalMyCardsComponent) {
    context.activeCvv = false;
    this.dynamicCvvService(context);
  }

  processCvv(context?: ModalMyCardsComponent) {
    if (context.getDataCardsInfo.Card.Registered) {
      this.getDynamicCvvService(context);
      context.hasCVV = true;
    } else {
      context.hasCVV = false;
    }
  }

  modalHelpCvv(context?: ModalMyCardsComponent) {
    this.hiddenModal(true);
    const modalOptions: ModalOptions = {
      centered: true,
      size: Strings.EMPTY,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.CardAlertComponent,
    };
    context.modalService.open(context.modalAlertCVV, modalOptions);
  }

  selectCvvTab(register: boolean, getCvv: boolean, context: ModalMyCardsComponent) {
    window.clearInterval(context.cvvTimer);
    let position = Numbers.One;
    context.timeCvv = true;
    context.minutes = Constants.MINUTES_DYNAMIC_CVV;
    context.seconds = Constants.SECONDS_DYNAMIC_CVV;
    context.cvvTimer = window.setInterval(() => {
      if (context.seconds >= Constants.CVV_TIME_EXPIRED) { context.seconds = context.seconds - Numbers.One; }
      if (context.minutes > Constants.CVV_TIME_EXPIRED && context.seconds < Constants.CVV_TIME_EXPIRED) {
        context.minutes = context.minutes - Numbers.One;
        context.seconds = Constants.SECONDS_INITIAL_DYNAMIC_CVV;
      }
      context.checkInterval(register, getCvv);
      context.clockIcon = context.clockArray[position];
      position = (position === context.clockArray.length - Numbers.One) ? Numbers.Zero : (position + Numbers.One);
    }, Constants.DYNAMIC_CVV_TIME);
  }
}
